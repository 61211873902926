const reportAbstractBase = assertion =>
  assertion.AssertionSchema?.properties?.ReportAbstract?.properties;
const reportSourcingBase = assertion =>
  assertion.AssertionSchema?.properties?.ReportSourcing?.properties;

const recognisers = {
  preparationTime: assertion =>
    !!assertion.AssertionSchema.properties?.PreparationTime,
  reportAbstract: {
    any: assertion => !!reportAbstractBase(assertion),
    firstName: assertion => !!reportAbstractBase(assertion)?.FirstName,
    middleNames: assertion => !!reportAbstractBase(assertion)?.MiddleNames,
    lastName: assertion => !!reportAbstractBase(assertion)?.LastName,
    titles: assertion => !!reportAbstractBase(assertion)?.Titles,
    specificTitle: title => assertion =>
      reportAbstractBase(assertion)?.Titles?.contains?.properties?.Text
        ?.const === title ||
      reportAbstractBase(assertion)?.Titles?.items?.properties?.Text?.not
        ?.const === title,
    specificNameSegment: (nameSegment, name) => assertion =>
      reportAbstractBase(assertion)?.[nameSegment]?.contains?.properties?.Values
        ?.contains?.properties?.Text?.const === name ||
      reportAbstractBase(assertion)?.[nameSegment]?.items?.properties?.Values
        ?.items?.properties?.Text?.not?.const === name,
    nameSuffix: assertion => !!reportAbstractBase(assertion)?.NameSuffix,
    dateOfBirth: assertion => !!reportAbstractBase(assertion)?.DateOfBirth,
    netWorth: assertion => !!reportAbstractBase(assertion)?.NetWorth,
    biography: assertion => !!reportAbstractBase(assertion)?.Biography,
    placeOfBirth: assertion => !!reportAbstractBase(assertion)?.PlaceOfBirth,
    age: assertion => !!reportAbstractBase(assertion)?.Age,
    nationality: assertion => !!reportAbstractBase(assertion)?.Nationality,
    specificNationality: nationality => assertion =>
      reportAbstractBase(assertion)?.Nationality?.contains?.properties?.Text
        ?.const === nationality ||
      reportAbstractBase(assertion)?.Nationality?.items?.properties.Text?.not
        ?.const === nationality,
    awards: assertion => !!reportAbstractBase(assertion)?.Awards,
    gender: assertion => !!reportAbstractBase(assertion)?.Gender,
    phoneNumbers: assertion => !!reportAbstractBase(assertion)?.PhoneNumbers,
    specificPhoneNumber: phoneNumber => assertion =>
      reportAbstractBase(assertion)?.PhoneNumbers?.contains?.properties?.Text
        ?.const === phoneNumber ||
      reportAbstractBase(assertion)?.PhoneNumbers?.items?.properties?.Text?.not
        ?.const === phoneNumber,
    emailAddresses: assertion =>
      !!reportAbstractBase(assertion)?.EmailAddresses,
    specificEmailAddress: email => assertion =>
      reportAbstractBase(assertion)?.EmailAddresses?.contains?.properties?.Text
        ?.const === email ||
      reportAbstractBase(assertion)?.EmailAddresses?.items?.properties?.Text
        ?.not?.const === email,
    significantWebsites: assertion =>
      !!reportAbstractBase(assertion)?.SignificantWebsites,
    specificWebsite: website => assertion =>
      reportAbstractBase(assertion)?.SignificantWebsites?.contains?.properties
        ?.Text?.const === website ||
      reportAbstractBase(assertion)?.SignificantWebsites?.items?.properties
        ?.Text?.not?.const === website,
    images: assertion => !!reportAbstractBase(assertion)?.Images,
    specificImage: imageSrc => assertion =>
      reportAbstractBase(assertion)?.Images?.contains?.properties?.Url
        ?.const === imageSrc ||
      reportAbstractBase(assertion)?.Images?.items?.properties?.Url?.not
        ?.const === imageSrc,
    imageFromSource: () => false, // TODO - implement
    onlineProfiles: profileType => assertion =>
      reportAbstractBase(assertion)?.OnlineProfilesWithUncertainty?.not
        ?.contains?.properties?.Data?.properties?.ProfileType?.const ===
        profileType ||
      reportAbstractBase(assertion)?.OnlineProfilesWithUncertainty?.contains
        ?.properties?.Data?.properties?.ProfileType?.const === profileType,
    specificOnlineProfile: (profileType, profileUrl) => assertion =>
      recognisers.reportAbstract.onlineProfiles(profileType) &&
      (reportAbstractBase(assertion)?.OnlineProfilesWithUncertainty?.not
        ?.contains?.properties?.Data?.properties?.Link?.const === profileUrl ||
        reportAbstractBase(assertion)?.OnlineProfilesWithUncertainty?.contains
          ?.properties?.Data?.properties?.Link?.const === profileUrl)
  },
  screeningSection: {
    subjectPersonListings: {
      peps: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.SubjectPersonListings?.Peps,
      sanctions: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.SubjectPersonListings?.Sanctions,
      watchLists: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.SubjectPersonListings?.WatchLists
    },
    possiblePersonListings: {
      peps: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.PossiblePersonListings?.Peps,
      sanctions: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.PossiblePersonListings?.Sanctions,
      watchLists: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.PossiblePersonListings?.WatchLists
    },
    disregardedPersonListings: {
      peps: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.DisregardedPersonListings?.Peps,
      sanctions: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.DisregardedPersonListings?.Sanctions,
      watchLists: assertion =>
        !!assertion.AssertionSchema?.properties?.ScreeningSection?.properties
          ?.DisregardedPersonListings?.WatchLists
    },
    specificScreeningResult:
      (listName, listType, confirmationType) => assertion =>
        assertion.AssertionSchema?.properties?.ScreeningSection?.properties?.[
          confirmationType
        ]?.properties?.[listType]?.properties?.Hits?.contains?.properties
          ?.ListName?.properties?.Original?.const === listName ||
        assertion.AssertionSchema?.properties?.ScreeningSection?.properties?.[
          confirmationType
        ]?.properties?.[listType]?.properties?.Hits?.items?.properties?.ListName
          ?.properties?.Original?.not?.const === listName
  },
  reportSourcing: {
    relevantSources: {
      source: assertion =>
        !!reportSourcingBase(assertion)?.RelevantSourceGroups,
      count: assertion => !!reportSourcingBase(assertion)?.RelevantSourceCount
    },
    nonRelevantSources: {
      source: assertion =>
        !!reportSourcingBase(assertion)?.NonRelevantSourceGroups,
      count: assertion =>
        !!reportSourcingBase(assertion)?.NotRelevantSourceCount
    },
    notProcessedSources: {
      source: assertion =>
        !!reportSourcingBase(assertion)?.NotProcessedSourceGroups,
      count: assertion =>
        !!reportSourcingBase(assertion)?.NotProcessedSourceCount
    },
    specificSource: (url, sourceType) => assertion =>
      assertion.AssertionSchema?.properties?.ReportSourcing?.properties?.[
        sourceType + "SourceGroups"
      ]?.contains?.properties?.SourceStacks?.contains?.properties?.Sources
        ?.contains?.properties?.Url?.properties?.FullUrl?.const === url ||
      assertion.AssertionSchema?.properties?.ReportSourcing?.properties?.[
        sourceType + "SourceGroups"
      ]?.items?.properties?.SourceStacks?.items?.properties?.Sources?.contains
        ?.properties?.Url?.properties?.FullUrl?.not?.const === url
  },
  professionalOverview: {
    specificOrgRecord: orgName => assertion =>
      assertion.AssertionSchema?.properties?.ProfessionalOverview?.properties
        ?.ListOfProfessionalInformation?.contains?.properties?.OrgName
        ?.const === orgName ||
      assertion.AssertionSchema?.properties?.ProfessionalOverview?.properties
        ?.ListOfProfessionalInformation?.items?.properties?.OrgName?.not
        ?.const === orgName
  }
};

export const {
  preparationTime,
  reportAbstract,
  screeningSection,
  reportSourcing,
  professionalOverview
} = recognisers;
