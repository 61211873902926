import React from "react";

import { ENTITY_TYPE } from "pages/define/utils";

import S from "./styles";

const RelationshipsHeader = ({ entities }) => {
  let numberRelatedPeople = 0;
  let numberRelatedOrgs = 0;

  entities?.forEach(entity => {
    if (entity.entityType?.toLowerCase() === ENTITY_TYPE.Person) {
      numberRelatedPeople++;
    } else {
      numberRelatedOrgs++;
    }
  });

  const orgString = numberRelatedOrgs === 1 ? "organisation" : "organisations";
  const personString = numberRelatedPeople === 1 ? "person" : "people";

  return (
    <S.RelationshipHeader>
      {numberRelatedPeople ? (
        <S.PersonRelationshipCount>
          <S.PersonIcon />
          {numberRelatedPeople} related {personString}
        </S.PersonRelationshipCount>
      ) : null}
      {numberRelatedOrgs ? (
        <div>
          <S.OrgIcon />
          {numberRelatedOrgs} related {orgString}
        </div>
      ) : null}
    </S.RelationshipHeader>
  );
};

export default RelationshipsHeader;
