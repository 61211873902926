import React, { useRef } from "react";

import { usePrintableReportState } from "util/hooks/usePrintableState.js";
import Tag from "components/atoms/Tag";
import { locationRiskText } from "util/locationRiskText";
import { ButtonKind } from "components/atoms/Button/types";
import { orange, red } from "styles/colors";
import { riskDirections } from "util/riskDirections";

import S from "./styles";

const TagsContainer = props => {
  const setSelectedTag =
    // eslint-disable-next-line react/destructuring-assignment
    props.setSelectedTag ||
    (t => console.error("setSelectedTag callback not set up", t));
  const { selectedTag, sourceId, tags, tagColor, selectDisabled } = props;
  const [isExpanded, setExpanded] = usePrintableReportState(
    `tags-container-${sourceId}-expanded`,
    false
  );
  const tagLimit = 4;
  const tagsHidden = isExpanded ? 0 : tags.length - tagLimit;
  const expandable = tagsHidden >= 1;
  const tagsShown = expandable && !isExpanded ? tags.slice(0, tagLimit) : tags;

  const expandButtonRef = useRef();

  return (
    <S.TagsContainer>
      {tagsShown.map(t => {
        const isSelected = selectedTag && t.id === selectedTag.id;

        const tagProps = {
          ...t,
          isSelected,
          isClickable: !selectDisabled,
          setSelectedTag,
          onClick: selectDisabled
            ? undefined
            : () =>
                isSelected ? setSelectedTag(undefined) : setSelectedTag(t),
          key: t.id,
          sourceId,
          hierarchy: t.category?.hierarchy,
          label: t.category?.label ?? t.category,
          color:
            tagColor ??
            (t.category.riskDirection === riskDirections.direct
              ? red.directRiskOutline
              : orange.indirectRisk),
          fillColor:
            tagColor ??
            (t.category.riskDirection === riskDirections.direct
              ? red.directRiskFill
              : orange.indirectRiskFill)
        };

        if (t.tagId === locationRiskText.siteRisk.label) {
          return (
            <S.SiteRiskTag
              {...tagProps}
              toolTip={
                <div>
                  <S.TooltipHeader>{t.label}</S.TooltipHeader>
                  <S.TooltipContent>
                    {locationRiskText.siteRisk.explainer}
                  </S.TooltipContent>
                </div>
              }
            />
          );
        }

        return <Tag {...tagProps} />;
      })}
      {tags.length > tagLimit ? (
        <S.RiskCountButton
          ref={expandButtonRef}
          onClick={e => {
            e.stopPropagation();
            setExpanded(prevState => !prevState);
            expandButtonRef.current.blur();
          }}
          kind={ButtonKind.secondary}
        >
          {isExpanded ? "Show less" : `+${tags.length - tagLimit}`}
        </S.RiskCountButton>
      ) : null}
    </S.TagsContainer>
  );
};

export default TagsContainer;
