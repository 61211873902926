import React, { ReactNode, useMemo, useCallback } from "react";

import Users from "api/users";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import { UserActionsContext } from "./context";
import { useHubAuthentication } from "../useHubAuthentication";
import { HubAuthenticationStatus } from "../useHubAuthentication/types";
import useUserManagementApi from "../useUserManagementApi";

const UserActionsProvider = ({ children }: { children: ReactNode }) => {
  const [{ fetching, error, success, errorMessage }, fetchDispatch] =
    useFetchReducer();

  const {
    state: { status: hubAuthenticationStatus }
  } = useHubAuthentication();

  const UsersApi = useUserManagementApi();

  const isHub =
    hubAuthenticationStatus === HubAuthenticationStatus.authenticated;

  const deleteUser = useCallback(
    async ({
      userId,
      transferUserId,
      dataLossAcknowledged
    }: {
      userId: string;
      transferUserId?: string;
      dataLossAcknowledged: boolean;
    }): Promise<boolean> => {
      fetchDispatch({ type: RequestActions.SendRequest });

      const { status, message } = await UsersApi.deleteUser(
        userId,
        dataLossAcknowledged,
        transferUserId
      );

      if (!status) {
        fetchDispatch({ type: RequestActions.SetError, errorMessage: message });
        return false;
      }

      fetchDispatch({ type: RequestActions.SetSuccess });
      return true;
    },
    [UsersApi, fetchDispatch]
  );

  const addToGroup = useCallback(
    async (userId: string, groupId: string): Promise<boolean> => {
      if (isHub) {
        return false;
      }

      fetchDispatch({ type: RequestActions.SendRequest });

      const api = UsersApi as Users; // This will only ever be users
      const { status, message } = await api.addToGroup(userId, groupId);

      if (!status) {
        fetchDispatch({ type: RequestActions.SetError, errorMessage: message });
        return false;
      }

      fetchDispatch({ type: RequestActions.SetSuccess });
      return true;
    },
    [UsersApi, fetchDispatch, isHub]
  );

  const resetError = useCallback(() => {
    fetchDispatch({ type: RequestActions.ResetError });
  }, [fetchDispatch]);

  const resetAll = useCallback(() => {
    fetchDispatch({ type: RequestActions.Reset });
  }, [fetchDispatch]);

  const providerValue = useMemo(
    () => ({
      deleteUser,
      addToGroup,
      resetError,
      resetAll,
      fetching,
      error,
      errorMessage,
      success
    }),
    [
      deleteUser,
      addToGroup,
      resetError,
      resetAll,
      fetching,
      error,
      errorMessage,
      success
    ]
  );

  return (
    <UserActionsContext.Provider value={providerValue}>
      {children}
    </UserActionsContext.Provider>
  );
};

export { UserActionsProvider };
