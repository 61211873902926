import React from "react";

import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

const LoadingSkeleton = () => {
  return (
    <>
      <Skeleton width={450} height={24} />
      <S.TitleCardSkeleton>
        <Skeleton variant="circular" width={48} height={48} />
        <S.TitleCardContentSkeleton>
          <Skeleton width={250} height={20} />
          <Skeleton width={200} height={18} />
        </S.TitleCardContentSkeleton>
      </S.TitleCardSkeleton>
    </>
  );
};

export default LoadingSkeleton;
