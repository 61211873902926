import { OrganisationUsage } from "api/organisations/types";

export type UsageState = LoadedUsage | LoadingUsage | LoadingUsageFailed;

export enum UsageLoadingStates {
  loading = "loading",
  loaded = "loaded",
  loadingFailed = "loadingFailed"
}

export type LoadedUsage = {
  loadingState: UsageLoadingStates.loaded;
} & OrganisationUsage;
export interface LoadingUsage {
  loadingState: UsageLoadingStates.loading;
}
export interface LoadingUsageFailed {
  loadingState: UsageLoadingStates.loadingFailed;
  error?: string;
}
