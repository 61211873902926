import { styled } from "@linaria/react";
import { Carousel } from "react-responsive-carousel";

const FallBackImage = styled.img`
  width: ${props => `${props.imageWidth}px`};
  height: ${props => `${props.imageHeight}px`};
  object-fit: cover;
  border-radius: 5px;
  display: flex;
  margin: auto;
`;

const CustomCarousel = styled(Carousel)`
  & > div {
    border-radius: 5px;
    width: ${props => `${props.imageWidth}px`}!important;
    height: ${props => `${props.imageHeight}px`}!important;
  }
`;

const SourceLinkPopoverContainer = styled.div`
  position: absolute;
  top: 50%;
`;

const S = {
  FallBackImage,
  CustomCarousel,
  SourceLinkPopoverContainer
};

export default S;
