import { styled } from "@linaria/react";
import ErrorBanner from "components/atoms/ErrorBanner";

const InputContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  padding-top: 1rem;
`;

const CustomErrorBanner = styled(ErrorBanner)`
  margin-top: 1rem;
`;

const S = {
  InputContainer,
  CustomErrorBanner
};

export default S;
