import { styled } from "@linaria/react";

import { ReactComponent as SparklesSvg } from "img/icons/sparkles.svg";
import { blue, grey, red, yellow } from "styles/colors";
import { family, size } from "styles/fonts";

export interface ContainerProps {
  hasRisk: boolean;
  active: boolean;
}

const IconContainer = styled.div`
  margin-bottom: 6px;
  width: 56px;
  height: 56px;

  > svg {
    width: 100%;
  }
`;

const Title = styled.h5`
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: ${family.interSemiBold};
  font-size: ${size.sm};
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.49px;
  margin-bottom: 0;
`;

const Text = styled.p`
  text-align: center;
  font-kerning: none;
  font-family: ${family.interRegular};
  font-size: ${size["2xs"]};
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0;
`;

const DetailsLink = styled.button`
  border: 0;
  outline: none;
  background: none;
  color: #007aff;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: ${family.interRegular};
  font-size: ${size["2xs"]};
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 180% */
  letter-spacing: -0.49px;
  text-decoration-line: underline;
  padding: 0;
  cursor: pointer;
`;

const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const DisclaimerStars = styled(SparklesSvg)`
  width: 9px;
  fill: ${yellow.amaStars};
`;

const DisclaimerText = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-family: ${family.interSemiBold};
  font-size: ${size["2xs"]};
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 160% */
  letter-spacing: -0.069px;
  color: ${grey.dark};
`;

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 102px;
  height: 126px;
  justify-content: center;
  background: ${({ active }) =>
    active ? "rgba(0, 122, 255, 0.10)" : "transparent"};
  border-radius: 12px;

  &:hover {
    cursor: pointer;
  }

  ${Title},
  ${Text} {
    color: ${({ hasRisk }) => (hasRisk ? red.directRiskFill : grey.mid)};
  }

  ${IconContainer} > svg {
    path {
      fill: ${({ hasRisk }) => (hasRisk ? red.directRiskFill : grey.mid)};
    }
  }
`;

const TooltipContainer = styled.div`
  width: 490px;
`;

const TooltopBody = styled.div`
  display: flex;
  padding: 24px 24px 12px 24px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const TooltopFooter = styled.div`
  padding: 12px 24px 12px 24px;
  background: ${grey.panel};
  border-radius: 0px 0px 12px 12px;
  margin-bottom: 0;
  color: ${blue.xapienDark};
  width: 100%;
  font-feature-settings: "liga" off, "clig" off;
  font-family: ${family.interRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.069px;

  > a {
    color: ${blue.link};
    text-decoration: underline;
  }
`;

const TooltipIcon = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 9.6px;
  background: rgba(244, 91, 91, 0.1);
  box-shadow: 0px 0px 1.2px 0.5px rgba(0, 0, 0, 0.25);

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TooltipTitle = styled.h6`
  color: ${red.directRisk};
  font-family: ${family.interSemiBold};
  font-size: ${size.lg};
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  margin-bottom: 0;
`;

const TooltipText = styled.p`
  font-family: ${family.interRegular};
  font-size: ${size.sm};
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0;

  > span {
    font-family: ${family.interSemiBold};
    font-weight: 700;
  }
`;

const S = {
  Container,
  IconContainer,
  Title,
  Text,
  DetailsLink,
  DisclaimerContainer,
  DisclaimerStars,
  DisclaimerText,
  TooltipContainer,
  TooltopBody,
  TooltopFooter,
  TooltipIcon,
  TooltipContent,
  TooltipTitle,
  TooltipText
};

export default S;
