import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import Api from "api/organisations";

const useRequestAccount = () => {
  const [{ error, fetching, errorMessage }, dispatch] = useFetchReducer();

  const OrgApi = new Api();

  const requestAccount = async (
    reportId: string,
    email: string,
    name: string
  ) => {
    dispatch({ type: RequestActions.SendRequest });

    const status = await OrgApi.sendAccountRequest(reportId, email, name);

    if (!status.status) {
      dispatch({
        type: RequestActions.SetError,
        errorMessage:
          status.message ??
          "There was an issue sending your request. Try again in a moment."
      });
      return;
    }

    dispatch({ type: RequestActions.SetSuccess });
  };

  return { requestAccount, error, fetching, errorMessage };
};

export default useRequestAccount;
