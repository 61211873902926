import React from "react";

import S from "./styles";

interface Props {
  text: string;
  count?: number;
}

const SectionHeader = ({ text, count = 0 }: Props) => {
  return (
    <S.SectionHeader>
      {text}
      <S.SectionCount>{count}</S.SectionCount>
    </S.SectionHeader>
  );
};

export default SectionHeader;
