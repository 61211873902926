import React, { useState, Dispatch, SetStateAction } from "react";

import useUserSettings from "util/hooks/useUserSettings";
import { useAuthentication } from "util/hooks/useAuthentication";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";

import ProfileMenu from "./ProfileMenu";

import S from "./styles";

const ProfilePopover = ({
  isManageOrganisationModalOpen,
  setIsContactAdminModalOpen,
  setIsManageOrganisationModalOpen
}: {
  isManageOrganisationModalOpen: boolean;
  setIsContactAdminModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsManageOrganisationModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const printModeEnabled = usePrintModeEnabled();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const {
    state: {
      isSettingsModalOpen,
      userDetails: { firstName }
    }
  } = useUserSettings();
  const {
    state: { status }
  } = useAuthentication();

  // Dismiss the profile menu when any modal is invoked from it
  if (
    (isSettingsModalOpen || isManageOrganisationModalOpen) &&
    isProfileMenuOpen
  ) {
    setIsProfileMenuOpen(false);
  }

  if (printModeEnabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  if (status !== AuthenticationStatus.authenticated) {
    return null;
  }

  return (
    <S.ProfilePopover
      interactive
      isOpenOverride={isProfileMenuOpen}
      onRequestClose={() => setIsProfileMenuOpen(false)}
      hideArrow
      alignment="bottom-start"
      content={
        isProfileMenuOpen ? (
          <ProfileMenu
            setIsManageOrganisationModalOpen={setIsManageOrganisationModalOpen}
            setIsContactAdminModalOpen={setIsContactAdminModalOpen}
            setIsProfileMenuOpen={setIsProfileMenuOpen}
          />
        ) : null
      }
    >
      <S.ProfileButton
        text={`Hi, ${firstName}`}
        onClick={() => setIsProfileMenuOpen(prev => !prev)}
        selected={isProfileMenuOpen}
        disabled={status !== AuthenticationStatus.authenticated}
      />
    </S.ProfilePopover>
  );
};

export default ProfilePopover;
