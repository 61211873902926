import React, { FC } from "react";
import Heading from "components/atoms/Heading";

import S from "./styles";

interface Props {
  count: number;
  title: string;
}

const CollectionCount: FC<Props> = ({ count, title }) => {
  return (
    <S.Container>
      <Heading level={4}>{count}</Heading>
      <Heading level={6}>{title}</Heading>
    </S.Container>
  );
};

export default CollectionCount;
