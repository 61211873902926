import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import Api from "api/reports";
import { Idam_Contracts_Enums_ReportRole } from "api/portal";

const useRequestEditAccess = () => {
  const [{ fetching, error, success, errorMessage }, dispatch] =
    useFetchReducer();
  const ReportsApi = new Api();

  const requestEditAccess = async (
    reportId: string,
    role: Idam_Contracts_Enums_ReportRole
  ) => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await ReportsApi.requestAccess(reportId, role);

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return;
    }

    dispatch({ type: RequestActions.SetSuccess });
  };

  const reset = () => {
    setTimeout(() => {
      dispatch({ type: RequestActions.Reset });
    }, 1000);
  };

  const resetError = () => {
    dispatch({ type: RequestActions.ResetError });
  };

  return {
    fetching,
    error,
    success,
    errorMessage,
    resetError,
    reset,
    requestEditAccess
  };
};

export default useRequestEditAccess;
