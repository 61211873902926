import { styled } from "@linaria/react";

import { motion } from "framer-motion";

import { standardColors, purple } from "styles/colors";
import { fontSize, family } from "styles/fonts";

import ButtonNew from "components/atoms/ButtonNew";
import Popover from "components/atoms/Popover";

const PrimaryAction = styled(ButtonNew)`
  color: ${purple.dark};
`;

const CloseLearnMoreButton = styled(ButtonNew)`
  padding: 8px 26px;

  background: ${purple.darker};
`;

const LearnMorePopover = styled(Popover)`
  margin-left: auto;
`;

const LearnMoreButton = styled(ButtonNew)`
  text-decoration: underline;

  &:focus,
  &:hover,
  &:active {
    background: transparent !important;
  }
`;

const LearnMoreContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const SecondaryAction = styled(ButtonNew)``;

const Footer = styled(motion.div)`
  position: sticky;
  top: 54px;
  margin-left: -540px;
  left: 50%;
  max-width: 1080px;
  z-index: 8;
  color: ${standardColors.white};
  box-shadow: 0px 4px 4px 0px #00000040;
  background: ${purple.darker};

  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
`;

const Title = styled.div`
  font-size: ${fontSize.lg};
  font-family: ${family.interSemiBold};

  span {
    opacity: 0.7;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const TitleMetaInfo = styled.div`
  font-family: ${family.interLight};
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
`;

const S = {
  IconContainer,
  PrimaryAction,
  SecondaryAction,
  Footer,
  Title,
  Actions,
  TitleMetaInfo,
  LearnMoreButton,
  LearnMorePopover,
  LearnMoreContainer,
  CloseLearnMoreButton
};

export default S;
