import React, { Fragment, useLayoutEffect, useRef } from "react";
import StatusCell from "pages/diagnostics/StatusCell";

const Overview = ({
  isOutage,
  currentStatusText,
  timeTaken,
  timestampCompleted,
  messages,
  statusColumns
}) => {
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const messagesString = JSON.stringify(messages);

  useLayoutEffect(
    () => {
      if (ref && ref.current && wrapperRef && wrapperRef.current) {
        if (ref.current.scrollHeight > wrapperRef.current.offsetHeight) {
          ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    },
    [messagesString] // messages is small, so not concerned about serialisation performance impact
  );

  const content = (
    <>
      {isOutage ? (
        <div className="diagnostics-overview-outage-message">
          There is currently a high level of demand and your report will take
          slightly longer to produce than usual.
        </div>
      ) : (
        ""
      )}
      <div className="report-status-summary">
        <div className="left-column">
          <div className="current-status">
            <div className="current-status-left">
              <p>Current status:</p>
              <h2>{currentStatusText}</h2>
            </div>
            <div className="current-status-right">
              {!timestampCompleted && <h2>{timeTaken}</h2>}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 4,
              overflow: "auto"
            }}
          >
            <div className="status-text-wrapper" ref={wrapperRef}>
              <div className="status-text" ref={ref}>
                {messages
                  .join("\r\n")
                  .split("\n")
                  .map(line => (
                    <Fragment key={line}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="right-column">
          {statusColumns.map(column => (
            <div
              className="inner-column"
              key={`statusColumn-${column.map(item => item?.stateName).join()}`}
            >
              {column.map(item => (
                <StatusCell key={item?.stateName} item={item} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return content;
};

export default Overview;
