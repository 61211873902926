import { Assertion, AssertionTemplate } from ".";

const blankPlaceholderTemplate = () =>
  new AssertionTemplate(
    (isValid, isMust, testCaseId, userId, userComment) => {
      return new Assertion(testCaseId, userId, userComment, isMust, {}, "");
    },
    () => {}
  );

export default blankPlaceholderTemplate;
