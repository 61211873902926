import { styled } from "@linaria/react";

import Button from "components/atoms/Button";

import { green, standardColors } from "styles/colors";

const Actions = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 1rem;
`;

const CloseButton = styled(Button)`
  color: ${green.xapienGreen} !important;
`;

const SubmitButton = styled(Button)`
  color: ${standardColors.white} !important;
`;

const S = {
  Actions,
  CloseButton,
  SubmitButton
};

export default S;
