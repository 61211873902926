import { styled } from "@linaria/react";

import { ReactComponent as PencilSvg } from "img/icons/pencil.svg";

import { standardColors } from "styles/colors";

const NotesContainer = styled.div`
  padding: 16px;
  background-color: ${standardColors.white};
`;

const PencilIcon = styled(PencilSvg)`
  margin-right: 6px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const S = {
  NotesContainer,
  PencilIcon,
  TitleContainer
};

export default S;
