import React from "react";
import { AvatarProps } from "@mui/material";

export enum AvatarSize {
  Tiny = "TINY",
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE"
}

export interface Props extends AvatarProps {
  avatarSize: AvatarSize;
  imgSrc?: string;
  children?: React.ReactNode;
  hasBorder?: boolean;
  background?: string;
  loading?: boolean;
}
