const SAYARI_CONTEXT_REGEX = /https:\/\/graph\.sayari\.com.*/;
const BVD_CONTEXT_REGEX = /https:\/\/orbis\.bvdinfo\.com.*/;

// TODO: Remove this once LegalEntity report type is added to the BE
export const isLegalEntityReport = (
  contexts: string | string[] | null | undefined
) => {
  if (!contexts) return false;
  if (typeof contexts === "string")
    return (
      !!contexts.match(SAYARI_CONTEXT_REGEX) ||
      !!contexts.match(BVD_CONTEXT_REGEX)
    );

  if (Array.isArray(contexts))
    return contexts.some(
      c => c.match(SAYARI_CONTEXT_REGEX) || c.match(BVD_CONTEXT_REGEX)
    );

  return false;
};
