import { stageUniqueName } from "static-config";

export const devel = "devel";
export const qa = "qa";
export const diDemo = "di-demo";

export const getStage = hostName =>
  hostName.startsWith("localhost") ? devel : stageUniqueName ?? devel;

export const stage = getStage(window.location.host);

export const isDevel = stage === devel;
export const isQa = stage === qa;

export const internalStages = new Set([devel, qa]);
