import React, { FC, SVGProps, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Send } from "react-feather";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";
import { useUserOrganisation } from "util/hooks/useUserOrganisation";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import { ReactComponent as AvatarPlaceholder } from "img/icons/avatar-placeholder.svg";

import useInviteUser from "./useInviteUser";

import S from "./styles";

enum Stage {
  initial = "initial",
  invitationSent = "invitation_sent"
}

const InviteConfirmationModal = () => {
  const [stage, setStage] = useState(Stage.initial);

  const [searchParams] = useSearchParams();
  const userName = searchParams.get("name");
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const { details } = useUserOrganisation();
  const { inviteUser, resetError, error, errorMessage, fetching } =
    useInviteUser();
  let orgName = "organisation's";

  if (details.loaded) {
    orgName = details.name;
  }

  const onExit = () => {
    navigate("/search");
  };

  const onSendInvite = async () => {
    const status = await inviteUser(email!);
    if (status) {
      setStage(Stage.invitationSent);
    }
  };

  const renderContent = () => {
    if (stage === Stage.invitationSent) {
      return (
        <>
          <S.ModalText>
            An email invitation to join the {orgName} account has been sent to
          </S.ModalText>
          <S.InviteCard>
            <AvatarPlaceholder />
            <div>{email}</div>
          </S.InviteCard>
          <ButtonNew
            text="Done"
            size={ButtonSize.Medium}
            type={ButtonType.Filled}
            onClick={onExit}
          />
        </>
      );
    }

    return (
      <>
        <S.ModalText>
          You are about to invite {userName} (<span>{email}</span>) to join the{" "}
          {orgName} account.
        </S.ModalText>
        <S.ModalActions>
          <ButtonNew
            text="Cancel"
            size={ButtonSize.Medium}
            type={ButtonType.Outlined}
            onClick={onExit}
          />
          <ButtonNew
            text="Invite"
            size={ButtonSize.Medium}
            type={ButtonType.Filled}
            IconTrailing={
              fetching
                ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                : Send
            }
            onClick={onSendInvite}
            disabled={fetching}
          />
        </S.ModalActions>
      </>
    );
  };

  return (
    <ModalContainer
      isOpen
      toggleOpen={() => {}}
      onExitClick={() => navigate("/search")}
      title={stage === Stage.invitationSent ? "Invite sent" : "Invite new user"}
    >
      {renderContent()}
      {error && <ErrorBanner text={errorMessage!} onClick={resetError} />}
    </ModalContainer>
  );
};

export default InviteConfirmationModal;
