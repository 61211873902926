import { styled } from "@linaria/react";
import { ALIGNMENT_OPTIONS } from "components/organisms/InsightReport/TimelineSection/constants";

export interface EventContainerProps {
  alignment: string;
  nodeSize: number;
}
const EventContainer = styled.div<EventContainerProps>`
  position: relative;
  text-align: ${props => props.alignment};
  align-items: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? "flex-end" : "flex-start"};
  margin-right: ${props => {
    return props.alignment === ALIGNMENT_OPTIONS.right ? props.nodeSize / 2 : 0;
  }}px;
  margin-left: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left ? props.nodeSize / 2 : 0}px;
  text-align: ${props =>
    props.alignment === ALIGNMENT_OPTIONS.left
      ? ALIGNMENT_OPTIONS.right
      : ALIGNMENT_OPTIONS.left};
  width: 100%;
  page-break-inside: avoid;
`;

const S = {
  EventContainer
};

export default S;
