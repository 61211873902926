export const getAgeFromDob = (dob, currentDate = new Date(Date.now())) => {
  const currentYear = currentDate.getFullYear();
  const dobYear = dob.getFullYear();

  let age = currentYear - dobYear;

  if (
    currentDate.getMonth() < dob.getMonth() ||
    (currentDate.getMonth() === dob.getMonth() &&
      currentDate.getDate() < dob.getDate())
  ) {
    age--;
  }

  return age;
};
