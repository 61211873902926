import React, { FC, useState } from "react";
import ModalContainer, { Variant } from "components/molecules/ModalContainer";
import ErrorBanner from "components/atoms/ErrorBanner";

import { useEnquiryId } from "util/hooks/useEnquiryId";
import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import InsightReportsApi from "api/insight-reports";

import { ButtonKind } from "components/atoms/Button/types";

import { usePersonaId } from "util/hooks/usePersonaId";
import S from "./styles";

interface Props {
  isOpen: boolean;
  onToggleOpen: () => void;
}

const RemoveFromReportModal: FC<Props> = ({ isOpen, onToggleOpen }) => {
  const reportId = useEnquiryId();
  const personaId = usePersonaId();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requestError, setRequestError] = useState<undefined | string>(
    undefined
  );

  const { dispatch } = useInsightReport();

  const onDismissError = () => {
    setRequestError(undefined);
  };

  const onCloseModal = () => {
    onToggleOpen();
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    const api = new InsightReportsApi();

    api
      .removeReport({ reportId, personaId })
      .then(result => {
        setIsSubmitting(false);
        if (result.status) {
          dispatch({
            type: InsightReportActions.removeReport
          });
          onToggleOpen();
        } else {
          setRequestError(result.message);
        }
      })
      .catch(e => {
        console.error(e);
        setIsSubmitting(false);

        setRequestError("Unexpected error occured");
      });
  };

  return (
    <ModalContainer
      title="Remove Insights from report"
      isOpen={isOpen}
      variant={Variant.LIGHT}
      toggleOpen={onCloseModal}
      onExitClick={onCloseModal}
    >
      <p>Are you sure you want to remove Insights from this report?</p>

      <S.Actions>
        {requestError && (
          <ErrorBanner text={requestError} onClick={onDismissError} />
        )}

        <S.CloseButton
          onClick={onCloseModal}
          kind={ButtonKind.secondary}
          disabled={isSubmitting}
        >
          Cancel
        </S.CloseButton>

        <S.SubmitButton
          onClick={onSubmit}
          kind={ButtonKind.primary}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Removing" : "Remove"}
        </S.SubmitButton>
      </S.Actions>
    </ModalContainer>
  );
};

export default RemoveFromReportModal;
