import { OrganisationDetails, OrganisationUser } from "api/organisations/types";

export interface UserOrganisationState {
  details: LoadedUserOrganisationDetails | LoadingUserOrganisationDetails;
  users: OrganisationUser[];
  lastSavedDetails?: OrganisationDetails;
  error?: string;
  saving: boolean;
  descriptionToSave?: string;
  saved: boolean;
}

export interface UserOrganisationHook {
  state: UserOrganisationState;
  dispatch: (action: UserOrganisationAction) => void;
}

export enum UserOrganisationActions {
  detailsLoaded = "detailsLoaded",
  detailsSaved = "detailsSaved",
  updateFailed = "updateFailed",
  dismissError = "dismissError",
  usageLoaded = "usageLoaded",
  resetOrganisationDetails = "resetOrganisationDetails",
  saveDescription = "saveDescription",
  resetSavedStatus = "resetSavedStatus"
}

export type UserOrganisationAction =
  | DetailsLoadedAction
  | UpdateFailedAction
  | DismissErrorAction
  | DetailsSavedAction
  | ResetOrganisationDetails
  | SaveDescriptionAction
  | ResetSavedStatus;

export interface DetailsLoadedAction {
  type: UserOrganisationActions.detailsLoaded;
  details: OrganisationDetails;
  users: OrganisationUser[];
}

export interface UpdateFailedAction {
  type: UserOrganisationActions.updateFailed;
  message: string;
}

export interface DismissErrorAction {
  type: UserOrganisationActions.dismissError;
}

export interface DetailsSavedAction {
  type: UserOrganisationActions.detailsSaved;
  description: string;
}

export interface SaveDescriptionAction {
  type: UserOrganisationActions.saveDescription;
  description: string;
}

export interface ResetOrganisationDetails {
  type: UserOrganisationActions.resetOrganisationDetails;
}

export interface ResetSavedStatus {
  type: UserOrganisationActions.resetSavedStatus;
}

export type LoadedUserOrganisationDetails = {
  loaded: true;
} & OrganisationDetails;

export type LoadingUserOrganisationDetails = {
  loaded: false;
};

export interface UserOrganisationContextContents {
  state: UserOrganisationState;
  dispatch: (action: UserOrganisationAction) => void;
}
