import { styled } from "@linaria/react";
import BaseHeading from "components/atoms/Heading";

import { fontSize } from "styles/fonts";
import { blue } from "styles/colors";

const Heading = styled(BaseHeading)`
  font-size: ${fontSize.md};
`;

const Paragraph = styled.p`
  font-size: ${fontSize.sm};
  text-align: center;
  margin: 0;

  a {
    color: ${blue.link};
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`;

const S = { Heading, Paragraph, CloseButton };

export default S;
