import React, { CSSProperties, MouseEvent, ReactNode, useEffect } from "react";
import { Collapse } from "reactstrap";

import { usePrintableReportState } from "util/hooks/usePrintableState";
import { UncertainDataFlag } from "components/atoms/UncertainDataFlag";
import TruncateLength from "util/TruncateLength";

import S from "./styles";

export const InfoTypes = Object.freeze({
  Email: "email",
  Phone: "phone",
  Website: "website",
  SocialMedia: "socialMedia",
  Custom: "custom"
});

interface FallbackDataItem {
  isUncertain?: boolean;
  text?: string;
}

type CustomDataDisplay<T> = {
  dataToDisplay: ReactNode;
  type: "custom";
  renderDataItem?: (item: T) => ReactNode;
};

type InfoTypeDataDisplay<T> = {
  dataToDisplay: T[];
  type: Omit<typeof InfoTypes[keyof typeof InfoTypes], "custom">;
  renderDataItem: (item: T) => ReactNode;
};

type DataDisplayProps<T> = CustomDataDisplay<T> | InfoTypeDataDisplay<T>;

type Props<T> = {
  title: string;
  defaultExpanded?: boolean;
  style?: CSSProperties;
  className?: string;
  headerClassName?: string;
  displayCount?: boolean;
  displayArrow?: boolean;
  dataCount?: number;
  hideCountOnCollapse?: boolean;
  isExpandedOverride?: boolean;
  maxHeight?: string;
  id?: string;
  stopTogglePropagation?: boolean;
  onToggle: (value: boolean) => void;
} & DataDisplayProps<T>;

const Accordion = <T extends FallbackDataItem>({
  dataToDisplay = [],
  title,
  type,
  defaultExpanded = false,
  style,
  className,
  headerClassName,
  displayCount = true,
  displayArrow = true,
  dataCount = 0,
  hideCountOnCollapse = true,
  isExpandedOverride,
  maxHeight,
  id,
  renderDataItem,
  stopTogglePropagation = false,
  onToggle = () => {}
}: Props<T>) => {
  const [displayContent, setDisplayContent] = usePrintableReportState(
    `extraInfo_${id}`,
    defaultExpanded
  );

  const count = Array.isArray(dataToDisplay) ? dataToDisplay.length : dataCount;

  useEffect(() => {
    // There was an issue with PDF export.
    // The "printable state" of PDF export that is sent to the backend had a lot of "extraInfo" keys
    // from this component being used for business associates.
    // The problem was that the state is suppied to the frontend (when being rendered for the PDF export)
    // via a query parameter. Unfortunately the state was getting to the point where it was too big for
    // CloudFront and we got a 413.
    // Now obviously we'll need to find a better way of piping the state through.
    //
    // BUT, for now here's a lower-risk fix:
    //
    // This pre-check is avoiding the unnecesary use of setDisplayContent in the initial render
    // of the report.
    // That wouldn't usually matter much beyond a minor performance aspect, but in this case it prevents the
    // printable state used for PDF export being unnecessarily filled up
    if (
      isExpandedOverride !== undefined &&
      isExpandedOverride !== displayContent
    ) {
      setDisplayContent(isExpandedOverride);
    }
  }, [displayContent, isExpandedOverride, setDisplayContent]);

  const renderFallBack = (itemData: T) => {
    if (itemData.isUncertain === undefined && itemData.text === undefined) {
      return null;
    }

    return (
      <>
        {itemData.isUncertain ? <UncertainDataFlag /> : ""}
        <TruncateLength>{itemData.text}</TruncateLength>
      </>
    );
  };

  const renderContent = (): ReactNode => {
    if (type === InfoTypes.Custom && !Array.isArray(dataToDisplay)) {
      return dataToDisplay;
    }

    if (Array.isArray(dataToDisplay)) {
      return (
        <S.ContentList maxHeight={maxHeight}>
          {dataToDisplay.map((item, index) => (
            <S.ContentItem key={`extraData_${index}`}>
              {renderDataItem ? renderDataItem(item) : renderFallBack(item)}
            </S.ContentItem>
          ))}
        </S.ContentList>
      );
    }

    return null;
  };

  const toggleDisplay = (e: MouseEvent) => {
    if (stopTogglePropagation) {
      e.stopPropagation();
    }
    onToggle(!displayContent);
    setDisplayContent(!displayContent);
  };

  return (
    <S.AccordionContainer style={style} className={className}>
      <S.AccordionHeader onClick={toggleDisplay} className={headerClassName}>
        <div>{title}</div>
        <S.CountAndToggleContainer>
          {(!displayContent || !hideCountOnCollapse) && displayCount ? (
            <S.DataCount>{count}</S.DataCount>
          ) : null}
          {displayArrow ? (
            <S.CollapseArrow className={displayContent ? "isExpanded" : ""} />
          ) : null}
        </S.CountAndToggleContainer>
      </S.AccordionHeader>
      <S.AccordionContent>
        <Collapse aria-label="accordion content" isOpen={displayContent}>
          {renderContent()}
        </Collapse>
      </S.AccordionContent>
    </S.AccordionContainer>
  );
};

export default Accordion;
