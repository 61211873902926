import React, { PropsWithChildren, useMemo, useState } from "react";
import { isPDX } from "static-config";
import InsightsFeatureContext, { InsightsFeatureContextValue } from "./context";

const TIMELINE_RISK_COLORS = "timeline-risk-colors";
const TIMELINE_RISK_TAGS = "timeline-risk-tags";

const defaultFeatures = isPDX ? [] : [TIMELINE_RISK_COLORS];

const InsightsFeatureProvider: React.FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const [enabledFeatures, setEnabledFeatures] =
    useState<string[]>(defaultFeatures);

  const handleToggle = (name: string) =>
    setEnabledFeatures(features => {
      if (features.includes(name)) {
        return features.filter(feature => feature !== name);
      }

      return [...features, name];
    });

  const value = useMemo<InsightsFeatureContextValue>(
    () => ({
      timelinesRiskTags: enabledFeatures.includes(TIMELINE_RISK_TAGS),
      timelinesRiskColors: enabledFeatures.includes(TIMELINE_RISK_COLORS),
      toggleTimelineRiskTags: () => handleToggle(TIMELINE_RISK_TAGS),
      toggleTimelineRiskColors: () => handleToggle(TIMELINE_RISK_COLORS)
    }),
    [enabledFeatures]
  );

  return (
    <InsightsFeatureContext.Provider value={value}>
      {children}
    </InsightsFeatureContext.Provider>
  );
};

export default InsightsFeatureProvider;
