export const orderByDateAndStatus = data => {
  return data?.sort((aData, bData) => {
    // If both dates are present
    if (aData.endDate?.year && bData.endDate?.year) {
      // If both are flagged as ongoing or neither is flagged as ongoing, then order by the difference
      if (
        (aData.isKnownToBeOngoing && bData.isKnownToBeOngoing) ||
        (!aData.isKnownToBeOngoing && !bData.isKnownToBeOngoing)
      ) {
        return (
          new Date(
            bData.endDate?.year,
            bData.endDate?.month ?? 1 - 1,
            bData.endDate?.day ?? 1
          ) -
          new Date(
            aData.endDate?.year,
            aData.endDate?.month ?? 1 - 1,
            aData.endDate?.day ?? 1
          )
        );
        // Else If one is flagged as ongoing, then order that first
      } else if (aData.isKnownToBeOngoing) {
        return -1;
      } else {
        return 1;
      }
    }

    // If the date is missing but is known to be onoing, then order that first
    if (!aData.endDate?.year && aData.isKnownToBeOngoing) {
      return -1;
    }

    if (!bData.endDate?.year && bData.isKnownToBeOngoing) {
      return 1;
    }

    // If one date is missing and neither have an ongoing status, then order the one that has the date first
    if (aData.endDate?.year) {
      return -1;
    }

    if (bData.endDate?.year) {
      return 1;
    }

    // If both dates are missing
    if (!aData.endDate?.year && !bData.endDate?.year) {
      // If they both have ongoing, then maintain original placement
      if (aData.isKnownToBeOngoing && bData.isKnownToBeOngoing) {
        return 0;
        // If one date has ongoing and the other doesn't, order this before
      } else if (aData.isKnownToBeOngoing) {
        return -1;
      } else if (bData.isKnownToBeOngoing) {
        return 1;
      }
      // If they both have non-ongoing, then maintain original placement
      return 0;
    }

    return 0;
  });
};
