import { useSearchParams } from "react-router-dom";
import { useReportSubjectEntityType } from "./useReportSubjectEntityType";
import { EntityType } from "./useReportSubjectEntityType/context";

export const useInsightsEnabled = () => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const personEnabled = !!searchParams.get("enable-insights");
  const orgEnabled = !!searchParams.get("enable-org-insights");
  const subjectType = useReportSubjectEntityType();
  if (subjectType === EntityType.Person) {
    return personEnabled;
  }
  if (subjectType === EntityType.Organisation) {
    return orgEnabled;
  }
  return false;
};
