export const getAssociateScreeningRiskSummary = ({
  totalAssociatedEntities,
  sanctionedAssociatesCount,
  watchlistAssociatesCount,
  pepAssociatesCount,
  specialInterestAssociatesCount,
  watchlistCountForSingleAssociate
}) => {
  const allScreeningRisks = {
    sanction: {
      count: sanctionedAssociatesCount,
      label: "sanctioned individuals",
      labelSingular: "sanctioned individual",
      altLabel: "sanctioned"
    },
    watchlist: {
      count: watchlistAssociatesCount,
      label: "individuals on watchlists",
      labelSingular: "individual on a watchlist",
      altLabel: "on watchlists",
      altLabelSingular: "on a watchlist"
    },
    pep: {
      count: pepAssociatesCount,
      label: "PEPs",
      labelSingular: "PEP",
      altLabel: "a PEP"
    },
    specialInterest: {
      count: specialInterestAssociatesCount,
      label: "people of Special Interest",
      labelSingular: "person of Special Interest",
      altLabel: "is of Special Interest"
    }
  };

  let builtString = "Your subject is related or closely associated to ";

  const screeningRisks = Object.values(allScreeningRisks)?.filter(
    ({ count }) => count > 0
  );

  if (screeningRisks.length === 0) {
    return false;
  } else if (screeningRisks.length === 1) {
    // Special case when there is only one identified screening risk type
    const { count, label, labelSingular } = screeningRisks[0];
    builtString += count + " " + (count === 1 ? labelSingular : label);
  } else if (screeningRisks.length > 1) {
    const screeningRiskStrings = screeningRisks.map(screeningRisk => {
      // Special case when there is only one associate with multiple identified screening risk types
      if (totalAssociatedEntities === 1) {
        // Note: this conditional works because "watchlist", the screening type that requires a different plural vs. singular form,
        // is the only screening type with an alternative label for the singular form
        return watchlistCountForSingleAssociate === 1 &&
          screeningRisk.altLabelSingular
          ? screeningRisk.altLabelSingular
          : screeningRisk.altLabel;
      }
      return screeningRisk.label;
    });

    // Grab last risk and remove from array to use to generate grammatically sound sentence
    const lastScreeningRisk = screeningRiskStrings.pop();
    const screeningRiskString = screeningRiskStrings.join(", ");

    builtString += `${totalAssociatedEntities} ${
      totalAssociatedEntities === 1 ? "individual" : "individuals"
    }`;

    // Special case when there is only one associate with multiple identified screening risk types
    if (totalAssociatedEntities === 1) {
      builtString += ` who is ${screeningRiskString} and ${lastScreeningRisk}`;
    } else {
      builtString += ` (including ${screeningRiskString} and ${lastScreeningRisk})`;
    }
  }
  return builtString;
};
