import { styled } from "@linaria/react";

import { ReactComponent as DowJonesLogoSvg } from "themes/projectdx/img/dj-full-logo.svg";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const SectionContent = styled.div`
  width: 100%;
  background-color: ${grey.panel};
  display: flex;
  flex-direction: column;

  & > button:not(:last-of-type) {
    margin-bottom: 2px;
  }
`;

const SectionBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  width: 100%;
  margin-bottom: 2px;
`;

const SectionBannerInfo = styled.p`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-bottom: 0;
`;

const SectionCount = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize["2xl"]};
`;

const Footer = styled.div`
  background-color: ${grey.light};
  padding: 8px 16px;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-top: 2px solid ${standardColors.white};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const DowJonesLogo = styled(DowJonesLogoSvg)`
  height: 18px;
`;

const Header = styled.div`
  background-color: ${grey.panel};
  padding: 0 16px;
`;

const List = styled.ul`
  padding-top: 5px;
  margin-left: -5px;
`;

const S = {
  SectionContent,
  SectionBanner,
  SectionBannerInfo,
  SectionCount,
  Footer,
  DowJonesLogo,
  Header,
  List
};

export default S;
