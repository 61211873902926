import React from "react";
import { inject, observer } from "mobx-react";

import NewReportButton from "pages/report/NewReportButton";
import { ReactComponent as Hamburger } from "img/icons/menu/reportPage/icon_section_nav.svg";
import { NAME_ONLY_SEARCH_ROUTE } from "util/nameOnlySearchRoute";

import S from "./styles";

const ReportSectionMenu = props => {
  const {
    menuOpen,
    setMenuOpen,
    menuItems,
    scrollToLink,
    isMenuHidden,
    defineStore
  } = props;
  const openMenu = () => {
    if (!menuOpen) setMenuOpen(true);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const newReport = () => {
    const url = defineStore.nameOnlySearch ? NAME_ONLY_SEARCH_ROUTE : "/";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const renderItems = items => {
    return items
      .filter(x => !!x)
      .map(item => {
        return (
          <S.ListItem
            onClick={({ title = item.title }) => {
              if (item.setExpanded) item.setExpanded(true);
              setTimeout(() => scrollToLink(title, menuItems), 500);
              closeMenu();
            }}
            key={`${item.titleindex}_${item.title}`}
          >
            <S.Title>{item.title}</S.Title>
          </S.ListItem>
        );
      });
  };

  return (
    <S.ReportControls>
      <S.ReportSectionMenu onMouseLeave={closeMenu} onMouseEnter={openMenu}>
        <S.ReportSectionButton>
          <Hamburger />
        </S.ReportSectionButton>
        <S.Menu open={isMenuHidden ? false : menuOpen}>
          <S.MenuList>
            {renderItems(menuItems)}
            <S.ListItem onClick={newReport} key="newReport">
              <S.Title>New report</S.Title>
              <S.NewReportButtonContainer>
                <NewReportButton />
              </S.NewReportButtonContainer>
            </S.ListItem>
          </S.MenuList>
        </S.Menu>
      </S.ReportSectionMenu>
    </S.ReportControls>
  );
};

export default inject("defineStore")(observer(ReportSectionMenu));
