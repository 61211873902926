// Deprecated, use fontSize export
export const size = {
  "2xs": "0.625rem", // 10px
  xs: "0.75rem", // 12px
  sm: "0.875rem", // 14px
  md: "1rem", // 16px
  lg: "1.125rem", // 18px
  "2lg": "1.25rem", // 20px
  xl: "1.5rem", // 24px
  "2xl": "2rem", // 32px
  "3xl": "3rem", // 48px
  "4xl": "4rem", // 64px
  "5xl": "6rem" // 96px
};

export const headingLetterSpacing = {
  "3xs": "-0.1px",
  "2xs": "-0.15px",
  xs: "-0.2px",
  sm: "-0.57px",
  md: "-0.57px",
  lg: "-0.48px",
  xl: "-0.69px",
  "2xl": "-0.69px",
  "3xl": "-0.9px",
  "4xl": "-1px",
  "5xl": "-2px"
};

export const fontSize = size;

export const lineHeight = {
  xs: 1,
  sm: 1.16666667,
  md: 1.33333333,
  lg: 1.44444444,
  xl: 1.5,
  "2xl": 1.6
};

const fallbackStack =
  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol";

export const family = {
  interRegular: `Inter-Regular, ${fallbackStack}`, // font weight = 500
  interLight: `Inter-Light, ${fallbackStack}`, // font weight = 400
  interSemiBold: `Inter-SemiBold, ${fallbackStack}`, // font weight = 600
  interDisplay: `InterDisplay-SemiBold, ${fallbackStack}`, // font weight = 500
  interDisplayMedium: `InterDisplay-Medium, ${fallbackStack}` // font weight = 600
};
