import React, { FC, SVGProps, useState } from "react";

import { ChevronLeft, Send } from "react-feather";
import { Spinner } from "reactstrap";

import Heading from "components/atoms/Heading";
import Input from "components/atoms/Input";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";

import useRequestAccount from "./useRequestAccount";

import S from "./styles";

interface Props {
  reportId: string;
  name: string;
  email: string;
  onNameChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  onRequestSent: () => void;
  onBackClick: () => void;
}

enum DetailsFormField {
  Email = "email",
  Name = "name"
}

interface DetailsFormError {
  type: DetailsFormField;
}

const UserDetails = ({
  reportId,
  name,
  email,
  onNameChange,
  onEmailChange,
  onRequestSent,
  onBackClick
}: Props) => {
  const [formErrors, setFormErrors] = useState<DetailsFormError[]>([]);

  const { requestAccount, fetching, error, errorMessage } = useRequestAccount();

  const validateFormInputs = () => {
    const errors: DetailsFormError[] = [];

    if (!name) {
      errors.push({ type: DetailsFormField.Name });
    }

    if (!email) {
      errors.push({ type: DetailsFormField.Email });
    }
    return errors;
  };

  const onSendAccountRequest = async () => {
    setFormErrors([]);

    const errors = validateFormInputs();

    if (errors.length) {
      setFormErrors(errors);
    } else {
      await requestAccount(reportId, email, name);
      onRequestSent();
    }
  };

  return (
    <>
      <S.BackButton onClick={onBackClick}>
        <ChevronLeft />
      </S.BackButton>
      <Heading level={4}>Enter your details</Heading>
      <S.FormFields>
        <Input
          placeholder="Name"
          value={name}
          onChange={value => onNameChange(value)}
        />
        {formErrors.some(e => e.type === DetailsFormField.Name) && (
          <ErrorBanner text="Name is required." />
        )}
        <Input
          placeholder="Email address"
          value={email}
          onChange={value => onEmailChange(value)}
        />
        {formErrors.some(e => e.type === DetailsFormField.Email) && (
          <ErrorBanner text="Email address is required." />
        )}
      </S.FormFields>
      <ButtonNew
        text="Send request"
        IconTrailing={
          fetching ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>) : Send
        }
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={onSendAccountRequest}
        disabled={fetching}
      />
      {error && <ErrorBanner text={errorMessage!} />}
    </>
  );
};

export default UserDetails;
