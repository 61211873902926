import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { ReactComponent as SanctionsSvgIcon } from "img/icons/sanctions-icon.svg";
import { ReactComponent as WatchlistsSvgIcon } from "img/icons/watchlists-icon.svg";
import { ReactComponent as PepsSvgIcon } from "img/icons/state-owned.svg";
import InfoSummaryRowComponent from "components/molecules/InfoSummaryRow";
import Button from "components/atoms/Button";

import { red, grey } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const riskIconStyles = {
  width: "64px",
  height: "64px",
  paddingBottom: "7px"
};

const getBucketColour = isConfirmedEntriesPresent => {
  if (isConfirmedEntriesPresent) {
    return red.directRiskOutline;
  }
  return grey.mid;
};

const SanctionsIcon = styled(SanctionsSvgIcon)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const WatchlistsIcon = styled(WatchlistsSvgIcon)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const PepsIcon = styled(PepsSvgIcon)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const ScreeningSummaryInfo = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 6px;
`;

const ListDetail = styled.div`
  font-size: ${fontSize.sm};

  background-color: ${grey.panel};
  border-radius: 6px;
  padding: 16px;
  padding-top: 12px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const EntityName = styled.div`
  color: ${grey.dark};
  font-family: ${family.interSemiBold};
`;

const Lists = styled.div`
  & > div:not(:last-of-type) {
    border-bottom: 1px solid ${grey.rule};
    padding-bottom: 8px;
  }
`;

const ListItem = styled.div`
  margin-top: 8px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const ListName = styled.span`
  display: inline-block;
`;

const ListDate = styled.div`
  color: ${grey.mid};
`;

const AdditionalLabel = styled.div`
  font-size: ${fontSize["2xs"]};
  color: ${({ IsInspectorOpen }) => (IsInspectorOpen ? grey.dark : grey.mid)};
  opacity: ${({ isOnDisplay }) => (isOnDisplay ? 1 : 0)};
  transition: color 0.2s, opacity 0.1s;
`;

const GraphicContainer = styled.div`
  text-align: center;
  padding-top: 4px;
  width: 108px;
  color: ${({ isConfirmedEntriesPresent }) =>
    getBucketColour(isConfirmedEntriesPresent)};

  &:hover {
    ${AdditionalLabel} {
      color: ${({ isConfirmedEntriesPresent }) =>
        isConfirmedEntriesPresent
          ? grey.dark
          : getBucketColour(isConfirmedEntriesPresent)};
    }
  }
`;

const GraphicHeader = styled.h1`
  margin: 0;
  font-size: ${fontSize.xl};
  font-family: ${family.interSemiBold};
  font-weight: inherit;
`;

const GraphicSubtext = styled.div`
  font-size: ${fontSize.sm};
  margin: 0;
  margin-top: 2px;
`;

const ScreeningGraphicSubtext = styled.span`
  text-transform: capitalize;
`;

const ListStatus = styled.span`
  color: ${grey.mid};
`;

const ListStatusLabel = styled.span`
  color: ${red.directRisk};
`;

const BulletPoint = styled.span`
  height: 4px;
  width: 4px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${grey.dark};
  margin-top: 3px;
  margin-bottom: 3px;
`;

const PersonDetails = styled.div`
  margin-bottom: 6px;
`;

const InfoSummaryRow = styled(InfoSummaryRowComponent)`
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

const SectionLinkButton = styled(Button)`
  border-bottom: 1px solid ${grey.mid} !important;
  color: ${grey.mid} !important;
  font-size: ${fontSize.sm} !important;
  line-height: 13px;
`;

const SectionLinkText = styled.span`
  white-space: nowrap;
`;

const AdditionalLabelExplainer = styled.div`
  padding-top: 8px;
`;

const TooltipExplainerSection = styled.div`
  margin-top: 16px;
`;

const RelationLabel = styled.span`
  color: ${grey.dark};
  font-family: ${family.interSemiBold};
`;

const SourceItemsContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

/** ClassNameOverrides */
const riskPill = css`
  text-transform: capitalize;
`;

const S = {
  SanctionsIcon,
  WatchlistsIcon,
  PepsIcon,
  ScreeningSummaryInfo,
  ListDetail,
  EntityName,
  Lists,
  ListDate,
  ListItem,
  ListName,
  GraphicContainer,
  GraphicHeader,
  GraphicSubtext,
  ScreeningGraphicSubtext,
  ListStatus,
  ListStatusLabel,
  BulletPoint,
  PersonDetails,
  InfoSummaryRow,
  SectionLinkButton,
  AdditionalLabel,
  SectionLinkText,
  AdditionalLabelExplainer,
  TooltipExplainerSection,
  RelationLabel,
  SourceItemsContainer
};

export const classNameOverrides = {
  riskPill
};

export default S;
