import type { Subsection as RawSubSection } from "api/insights";

export const getReorderedSubsections = ({
  reorderDirection,
  subsectionIdToMove,
  subsections
}: {
  reorderDirection: string;
  subsectionIdToMove: string;
  subsections: RawSubSection[];
}) => {
  const subsectionIndex = subsections.findIndex(
    subsection => subsection.id === subsectionIdToMove
  );

  // Not found, no change
  if (subsectionIndex === -1) return subsections;

  const getNewSubsectionIndex = () => {
    if (reorderDirection === "up" && subsectionIndex > 0) {
      return subsectionIndex - 1;
    }

    if (
      reorderDirection === "down" &&
      subsectionIndex < subsections.length - 1
    ) {
      return subsectionIndex + 1;
    }

    return subsectionIndex;
  };

  const newSubsectionIndex = getNewSubsectionIndex();

  // No change
  if (newSubsectionIndex === subsectionIndex) return subsections;

  const newSubsections = [...subsections];

  const subsectionToDisplace = newSubsections[subsectionIndex];

  newSubsections[subsectionIndex] = newSubsections[newSubsectionIndex];

  newSubsections[newSubsectionIndex] = subsectionToDisplace;
  return newSubsections;
};
