import { createContext, useContext } from "react";

export const PersonaIdContext = createContext<string | undefined>(undefined);
export const usePersonaId = (): string | undefined => {
  const id = useContext(PersonaIdContext);
  if (id && id.length) {
    return id;
  }
  return undefined;
};
