import { styled } from "@linaria/react";
import { fontSize, family } from "styles/fonts";
import { green } from "styles/colors";

const GeneratingText = styled.div`
  color: ${green.mid};
  font-size: ${fontSize.xs};
  font-family: ${family.interSemiBold};
  &::after {
    display: inline-block;
    animation: dotty steps(1, end) 1s infinite;
    content: "";
  }
  @keyframes dotty {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
    100% {
      content: "";
    }
  }
`;

const S = {
  GeneratingText
};

export default S;
