import Dagre from "@dagrejs/dagre";

import type { UboReportNode, UboReportEdge } from "api/ubo-reports";

// Creates a new directed graph
const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

export enum DagreRankDirection {
  topBottom = "TB",
  bottomTop = "BT",
  leftRight = "LR",
  rightLeft = "RL"
}

export const getLaidOutElements = (
  nodes: UboReportNode[],
  edges: UboReportEdge[],
  options: { direction: DagreRankDirection }
) => {
  g.setGraph({ rankdir: options.direction, nodesep: 5 });

  edges.forEach(edge => g.setEdge(edge.source, edge.target));
  nodes.forEach(node => g.setNode(node.id, node));

  Dagre.layout(g);

  return {
    nodes: nodes.map((node: any) => {
      const position = g.node(node.id);
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      const x = position.x - node.width / 2;
      const y = position.y - node.height / 2;

      return { ...node, position: { x, y } };
    }),
    edges
  };
};
