import React from "react";

import FlagExplainer from "themes/projectdx/ThemedScreening/FlagExplainer";

import S from "./styles";

const FlagPill = ({
  type,
  isActive,
  interactive = true,
  isCloseAssociate = false,
  style,
  className
}) => {
  let activeStatus;
  let uncertaintyStatus;

  const isEntityActive =
    typeof isActive === "boolean" ? isActive : isCloseAssociate; // Temporary: Close associate pills always red unless explicitly labelled as inactive

  if (!isEntityActive) {
    activeStatus = " – Inactive";
  }

  return (
    <S.Tooltip
      tooltip={<FlagExplainer type={type} isActive={isActive} />}
      position="right"
      disabled={!interactive}
    >
      <S.Pill
        key={type}
        isActive={isEntityActive}
        isInteractive={interactive}
        className={className}
        style={style}
      >
        {uncertaintyStatus}
        {type}
        {activeStatus}
      </S.Pill>
    </S.Tooltip>
  );
};

export default FlagPill;
