/* eslint-disable @typescript-eslint/return-await */
import { apm } from "@elastic/apm-rum";

import { OpenAPI } from "api/portal";

export async function getHeaders(authenticated = true) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (authenticated) {
    try {
      let jwt;

      if (typeof OpenAPI.TOKEN === "function") {
        jwt = await OpenAPI.TOKEN();
      } else {
        jwt = OpenAPI.TOKEN;
      }

      headers.append("Authorization", `Bearer ${jwt}`);
    } catch (e) {
      apm.captureError(e);
      console.warn(
        "Not authenticated - will try legacy HTTP request un-authenticated"
      );
    }
  }
  return headers;
}

async function _processResult(response) {
  if (response.status >= 200 && response.status <= 299) {
    const text = await response.text();
    return text ? JSON.parse(text) : undefined;
  }

  if (response.status === 400) {
    const text = await response.text();
    throw new Error({
      status: response.status,
      statusText: response.statusText,
      body: JSON.parse(text)
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      status: response.status,
      statusText: response.statusText
    };
  }
}

const result = {
  processResultRaw: async response => {
    return await _processResult(response);
  },

  post: async (route, payload) => {
    const config = {
      body: payload ? JSON.stringify(payload) : undefined,
      method: "POST",
      headers: await getHeaders(),
      mode: "cors",
      cache: "no-cache"
    };
    // Done because our @elastic RUM monitoring overrides all fetch requests to FETCH-PATCH (its a terrible package)
    // and this changes our requests so that they slot into thier badly made IF statment
    // checkout https://github.com/Digital-Insight-Technologies-Ltd/CustomerWebApp/pull/1588
    const request = new Request(route, config);
    const response = await fetch(request);
    return await _processResult(response);
  },

  put: async (route, payload) => {
    const config = {
      body: payload ? JSON.stringify(payload) : undefined,
      method: "PUT",
      headers: await getHeaders(),
      mode: "cors",
      cache: "no-cache"
    };
    // As above
    const request = new Request(route, config);
    const response = await fetch(request);
    return await _processResult(response);
  },

  get: async route => {
    const config = {
      method: "GET",
      headers: await getHeaders(),
      mode: "cors",
      cache: "no-cache"
    };
    // as above
    const request = new Request(route, config);
    const response = await fetch(request);
    return await _processResult(response);
  },

  delete: async route => {
    const config = {
      method: "DELETE",
      headers: await getHeaders(),
      mode: "cors",
      cache: "no-cache"
    };
    // as above
    const request = new Request(route, config);
    const response = await fetch(request);
    return await _processResult(response);
  }
};

export default result;
