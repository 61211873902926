import React, { FC, SVGProps, useState } from "react";

import { ChevronDown } from "react-feather";
import { TextField } from "@mui/material";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";

import type { Props as SearchInputProps } from "components/atoms/SearchInput";

import { blue, grey } from "styles/colors";

import S, { classNameOverrides } from "./styles";

export interface DropdownOption<T> {
  id: T;
  label: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
}

interface Props<T> extends SearchInputProps {
  dropdownOptions: DropdownOption<T>[];
  onDropdownOptionChange: (option: DropdownOption<T>) => void;
  selectedDropdownOption: DropdownOption<T>;
  loadingText?: string;
  disabled?: boolean;
}

const SearchInputWithOptions = <T extends string>(props: Props<T>) => {
  const {
    query,
    options,
    searchTags,
    placeholder,
    onInputChange,
    onChange,
    onFocus,
    renderInput,
    dropdownOptions,
    onDropdownOptionChange,
    selectedDropdownOption,
    onClear,
    onSubmit,
    loadingText,
    disabled,
    className
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const renderDropdown = () => {
    const dropdownMenu = (
      <Menu>
        {dropdownOptions.map(option => {
          return (
            <MenuItem
              key={option.id}
              onMenuItemClick={() => {
                onDropdownOptionChange(option);
                setIsDropdownOpen(false);
              }}
              text={option.label}
              IconLeading={option.icon}
              disabled={option.id === selectedDropdownOption.id}
            />
          );
        })}
      </Menu>
    );

    return (
      <Popover
        // @ts-ignore
        isOpenOverride={isDropdownOpen}
        // @ts-ignore
        onRequestClose={() => setIsDropdownOpen(false)}
        alignment="bottom-end"
        hideArrow
        trigger="click"
        content={dropdownMenu}
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.OptionsButton
          type="button"
          onClick={() => setIsDropdownOpen(prev => !prev)}
          disabled={disabled}
        >
          {selectedDropdownOption.label}{" "}
          <ChevronDown
            size={16}
            color={disabled ? grey.ghost : blue.xapienDark}
            style={{ marginBottom: 2 }}
          />
        </S.OptionsButton>
      </Popover>
    );
  };

  return (
    <S.InputContainer className={className}>
      <S.CustomSearchInput
        {...{
          loadingText,
          query,
          options,
          searchTags,
          placeholder:
            searchTags && searchTags.length ? undefined : placeholder,
          onInputChange,
          onChange,
          onFocus,
          onClear,
          onSubmit,
          disabled,
          renderInput:
            renderInput ||
            ((placeholderStr, params, ref) => (
              <TextField
                placeholder={placeholderStr}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null
                }}
                className={classNameOverrides.textInput}
                type="search"
                inputRef={ref}
              />
            ))
        }}
      />
      {renderDropdown()}
    </S.InputContainer>
  );
};

export default SearchInputWithOptions;
