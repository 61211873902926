import { styled } from "@linaria/react";

import { ReactComponent as Arrow } from "img/icons/renders/arrow-rounded.svg";
import { red, grey, standardColors, blue } from "styles/colors";
import { fontSize } from "styles/fonts";

const LocationsBarContainer = styled.div`
  padding-left: 32px;
  flex: 1;
  max-height: 170px;
  overflow-y: auto;
`;

const CollapseArrow = styled(Arrow)`
  width: 16px;
  height: 10px;
  transform: ${({ className }) =>
    className === "isExpanded" ? "rotate(90deg)" : "none"};
`;

const CheckboxAndLabel = styled.div`
  display: flex;
  width: ${props => {
    if (props.numRiskIcons === 3) {
      return "65%";
    }
    if (props.numRiskIcons === 2) {
      return "75%";
    }
    return "100%";
  }};
`;

const Bar = styled.div`
  width: 100%;
  background-color: ${grey.mid};
  margin: 4px 0px;
  font-size: ${fontSize["2xs"]};
  padding: 0 4px;
  color: ${standardColors.white};
  margin-right: 1px;
`;

const SelectedBar = styled(Bar)`
  background-color: ${props => (props.hasRisk ? red.directRisk : blue.icon)};
  border-radius: ${props => (props.inactiveCount ? "4px" : "4px 0px 0px 4px")};
  width: ${props => props.barWidth};
`;

const DefaultSelectedBar = styled(Bar)`
  border-radius: ${props => (props.inactiveCount ? "4px" : "4px 0px 0px 4px")};
  width: ${props => props.barWidth};
  background-color: ${props => (props.hasRisk ? red.directRisk : grey.mid)};
`;

const InactiveBar = styled(Bar)`
  background-color: ${props =>
    props.isBarFullyInactive ? grey.rule : grey.mid};
  border-radius: ${props => (props.hasZeroCount ? "4px" : "0px 4px 4px 0px")};
  width: ${props => props.barWidth};
`;

const CountryBarInnerWrapper = styled.div`
  width: ${props => props.barWidth};
  display: flex;
  border-radius: 2px;
`;

const CountryBarOuterWrapper = styled.div`
  width: 50%;
  cursor: ${props => (props.isDisabled ? "default" : "pointer")};
  text-align: ${props => (props.alignTextRight ? "right" : "left")};
`;

const RiskFlagsContainer = styled.div`
  display: flex;
  margin-right: 4px;
`;

const Label = styled.label`
  margin-bottom: 0;
  cursor: ${props => (props.isDisabled ? "default" : "pointer")};
  color: ${props => {
    if (props.isDisabled) {
      return grey.rule;
    }
    if (props.hasRisk) {
      return red.directRisk;
    }
    if (props.isPartiallySelected || props.isFullySelected) {
      return blue.icon;
    }
    return grey.mid;
  }} !important;

  &:before {
    border: ${props => {
      if (props.isDisabled) {
        return `1px solid ${grey.rule}`;
      }
      if (props.hasRisk) {
        return `1px solid ${red.directRisk}`;
      }
      if (props.isPartiallySelected || props.isFullySelected) {
        return `1px solid ${blue.icon}`;
      }
      return `1px solid ${grey.mid}`;
    }} !important;

    background: ${props => {
      if (props.isFullySelected) {
        if (props.hasRisk) {
          return red.directRisk;
        }
        return blue.icon;
      }
      return "transparent";
    }} !important;
  }
`;

const LabelWrapper = styled.div`
  cursor: ${props => (props.isDisabled ? "default" : "pointer")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${props => (props.isChild ? "35px" : "4px")};
  width: ${props => (props.isChild ? "50%" : "calc(50% - 15px)")};
`;

const S = {
  LocationsBarContainer,
  CollapseArrow,
  CheckboxAndLabel,
  Bar,
  SelectedBar,
  DefaultSelectedBar,
  InactiveBar,
  CountryBarInnerWrapper,
  CountryBarOuterWrapper,
  RiskFlagsContainer,
  Label,
  LabelWrapper
};

export default S;
