import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from "react";
import Masonry from "react-masonry-css";

import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import MapboxComponent from "components/molecules/MapboxComponent";
import SectionFooter from "components/atoms/SectionFooter";

import { grey } from "styles/colors";

import { generateUniqueCityString } from "./utils";
import LocationBar from "./LocationsBar";
import LocationCard from "./LocationCard";
import S, { classNameOverrides } from "./styles";

const FilterButton = ({ onClick, active = false, children, disabled }) => (
  <S.FilterButtonContainer>
    <button
      type="button"
      disabled={disabled}
      aria-haspopup="true"
      aria-expanded="false"
      className={`locations__filter-button ${
        active ? "sort-filter-pill-filled" : "locations__sort-filter-pill"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  </S.FilterButtonContainer>
);

const Locations = ({ locationData, isReportRegenerationOpen }) => {
  const locationsRef = useRef();
  const resultsSectionRef = useRef();
  const [isShowingExpandButton, setIsShowingExpandButton] = useState();

  const formatLocationData = () => {
    return locationData?.map(location => {
      return {
        ...location,
        correspondenceAddressRoles: location.correspondenceAddressRoles.map(
          role => {
            const linkedCompany =
              location.originalAddresses &&
              location.originalAddresses.find(address =>
                address.linkedCompanies.find(
                  company =>
                    company.companyIdentifier === role.organisationIdentifier
                )
              );
            return {
              ...role,
              address: linkedCompany ? linkedCompany.address : []
            };
          }
        ),
        registeredAddressCompanies: location.registeredAddressCompanies.map(
          role => {
            const linkedCompany =
              location.originalAddresses &&
              location.originalAddresses.find(address =>
                address.linkedCompanies.find(
                  company =>
                    company.companyIdentifier === role.organisationIdentifier
                )
              );
            return {
              ...role,
              address: linkedCompany ? linkedCompany.address : []
            };
          }
        )
      };
    });
  };

  const locations = formatLocationData();
  const dateRange = useMemo(() => {
    const result = { smallestDate: Infinity, largestDate: 0 };
    locations?.forEach(loc => {
      if (
        loc.earliestStartDate &&
        loc.earliestStartDate.year &&
        loc.earliestStartDate.year < result.smallestDate
      ) {
        result.smallestDate = loc.earliestStartDate.year;
      }

      if (
        loc.mostRecentEndDate &&
        loc.mostRecentEndDate.year &&
        loc.mostRecentEndDate.year > result.largestDate
      ) {
        result.largestDate = loc.mostRecentEndDate.year;
      }

      return result;
    });

    if (result.smallestDate === Infinity) {
      result.smallestDate = "";
    }

    if (!result.largestDate) {
      result.largestDate = "Present";
    }

    return result;
  }, [locations]);

  const [corresAddFilterApplied, setCorresAddFilterApplied] =
    usePrintableReportState("corres-filter-applied", false);
  const [regAddFilterApplied, setRegAddFilterApplied] = usePrintableReportState(
    "reg-filter-applied",
    false
  );
  const [selectedCities, setSelectedCities] = usePrintableReportState(
    "selected-cities",
    []
  );
  const [selectedLocation, setSelectedLocation] = useState({});
  const [isLocationResultsShown, setIsLocationResultsShown] =
    usePrintableReportState(
      "locations-expanded",
      false,
      PRINTABLE_STATE_TYPES.sectionExpand
    );
  const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
    "locations-expanded-fully",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const activeFilter =
    corresAddFilterApplied || regAddFilterApplied || selectedCities.length > 0;

  const getFilteredLocationsByAddress = useCallback(() => {
    let filteredLocations = locations;
    if (corresAddFilterApplied) {
      filteredLocations = filteredLocations.filter(
        l =>
          l.correspondenceAddressRoles &&
          l.correspondenceAddressRoles.length > 0
      );
    }
    if (regAddFilterApplied) {
      filteredLocations = filteredLocations.filter(
        l =>
          l.registeredAddressCompanies &&
          l.registeredAddressCompanies.length > 0
      );
    }
    return filteredLocations;
  }, [corresAddFilterApplied, locations, regAddFilterApplied]);

  const getFilteredLocations = useCallback(() => {
    let filteredLocations = getFilteredLocationsByAddress();

    if (selectedCities.length > 0) {
      filteredLocations = filteredLocations.filter(
        l =>
          l.filterInfo !== null &&
          selectedCities.includes(
            generateUniqueCityString(
              l.filterInfo.countryName,
              l.filterInfo.city
            )
          )
      );
    }
    return filteredLocations;
  }, [getFilteredLocationsByAddress, selectedCities]);

  /**
   * For PDF export purposes - Update the filteredLocations variable on the initial load and on any subsequent
   * changes to the filtering options. We want the data to be reflective of the options selected, so it then displays
   * correctly when the report is exported to a PDF.
   */
  const filteredLocations = useMemo(() => {
    if (activeFilter && !isLocationResultsShown) {
      setIsLocationResultsShown(true);
    }

    return getFilteredLocations();
  }, [
    activeFilter,
    getFilteredLocations,
    isLocationResultsShown,
    setIsLocationResultsShown
  ]);

  useEffect(() => {
    const element = document.querySelector(
      `div[data-index="${selectedLocation.id}"]`
    );

    // Style temporarily before transitioning out
    if (element) {
      element.style.backgroundColor = "rgb(217, 234, 241)";

      setTimeout(() => {
        element.style.transition = "all 1s";
        element.style.backgroundColor = grey.panel;
      }, 3000);
    }

    resultsSectionRef?.current?.scrollTo({
      left: 0,
      top: (element?.offsetTop ?? 0) - (element?.parentNode.offsetTop ?? 0),
      behavior: "smooth"
    });
  }, [selectedLocation]);

  const onToggleExpandResultsSection = () => {
    setIsResultsExpanded(prevState => !prevState);
    if (isResultsExpanded) {
      // Then we must be collapsing the results so ensure
      // the results section remains in view.

      const rect = resultsSectionRef.current.getBoundingClientRect();

      // If results section's top is now hidden i.e. above the viewport then:
      if (rect.top <= 0) {
        // Bring the section into view
        locationsRef.current.scrollIntoView();
      }
    }
  };

  const renderLocationsCount = () => {
    const filteredLength = filteredLocations.length;
    const originalLength = locationData.length;
    if (filteredLength !== originalLength) {
      return (
        <>
          <S.FilteredSectionCount>
            {`Showing ${filteredLength} of ${originalLength} locations`}
          </S.FilteredSectionCount>
          <S.SectionCountAdditional>{`${
            originalLength - filteredLength
          } hidden by active filters`}</S.SectionCountAdditional>
        </>
      );
    }
    return locationData.length;
  };

  const renderHeading = () => (
    <S.Heading>
      <div>
        <S.Dates>
          {dateRange.smallestDate}
          <span>{dateRange.smallestDate && " - "}</span>
          <span>{dateRange.largestDate}</span>
        </S.Dates>
      </div>
      <S.SectionTotal>{renderLocationsCount()}</S.SectionTotal>
    </S.Heading>
  );

  const resetAll = () => {
    setCorresAddFilterApplied(false);
    setRegAddFilterApplied(false);
    setSelectedCities([]);
  };

  const renderTags = () => {
    return (
      <S.ResetFiltersButton onClick={resetAll} disabled={!activeFilter}>
        {activeFilter ? "Reset filters" : "No active filters"}
      </S.ResetFiltersButton>
    );
  };

  const isSelected = id => filteredLocations.some(l => l.locationId === id);

  const renderFilterButtons = () => {
    const corresCount = locations.filter(
      location =>
        isSelected(location.locationId) &&
        location.correspondenceAddressRoles &&
        location.correspondenceAddressRoles.length > 0
    ).length;

    const regCount = locations.filter(
      location =>
        isSelected(location.locationId) &&
        location.registeredAddressCompanies &&
        location.registeredAddressCompanies.length > 0
    ).length;

    return (
      <S.FilterButtonsContainer>
        <FilterButton
          index={1}
          disabled={corresCount === 0}
          onClick={() => {
            if (corresCount > 0) {
              setRegAddFilterApplied(false);
              setCorresAddFilterApplied(!corresAddFilterApplied);
            }
          }}
          active={corresAddFilterApplied}
        >
          Director correspondence address
        </FilterButton>
        <FilterButton
          index={2}
          disabled={regCount === 0}
          onClick={() => {
            if (regCount > 0) {
              setCorresAddFilterApplied(false);
              setRegAddFilterApplied(!regAddFilterApplied);
            }
          }}
          active={regAddFilterApplied}
        >
          Company registered address
        </FilterButton>
      </S.FilterButtonsContainer>
    );
  };

  const scrollToLocation = img => {
    if (!isLocationResultsShown) {
      setIsLocationResultsShown(true);
    }
    setSelectedLocation(img);
  };

  const locationsString =
    filteredLocations?.length === 1 ? "location" : "locations";

  const renderLocationTiles = () => (
    <Masonry
      breakpointCols={2}
      className={classNameOverrides.masonryGrid}
      columnClassName={classNameOverrides.masonryColumn}
    >
      {filteredLocations.map(location => (
        <LocationCard
          key={`${location.addressFullDisplay.join()} ${location.longitude} ${
            location.latitude
          }`}
          image={location.locationStreetViewImage}
          address={location.addressFullDisplay}
          correspondenceAddressRoles={location.correspondenceAddressRoles}
          registeredAddressCompanies={location.registeredAddressCompanies}
          associatedOrganisations={location.associatedOrganisations}
          associatedPerson={location.associatedPerson}
          riskFlags={location.countryRiskFlags}
          sources={location.sources}
        />
      ))}
    </Masonry>
  );

  return (
    <div ref={locationsRef} className="locations-wrapper">
      <S.LocationsTopSectionContainer
        isLocationResultsShown={isLocationResultsShown}
      >
        {renderHeading()}
        <S.LocationsSectionContainer>
          <MapboxComponent
            locations={locations}
            filteredLocations={filteredLocations}
            showClusters={false}
            onMarkerClick={id => scrollToLocation({ id: id[0] })}
            activeFilter={activeFilter}
          />
          <S.FiltersSection>
            {renderTags()}
            {renderFilterButtons()}
            <S.Rule />
            <LocationBar
              locations={locations}
              selectedCities={selectedCities}
              setSelectedCities={setSelectedCities}
              activeFilter={activeFilter}
              filteredLocations={getFilteredLocationsByAddress()}
            />
          </S.FiltersSection>
        </S.LocationsSectionContainer>
        <S.CustomShowResultsButton
          resultsCount={filteredLocations.length + 1}
          customButtonLabel={`${isLocationResultsShown ? "Hide" : "Show"} ${
            filteredLocations.length
          } ${locationsString}`}
          onShowResultsClick={newVal => setIsLocationResultsShown(newVal)}
          resultsString={locationsString}
          isShowingResults={isLocationResultsShown}
        />
      </S.LocationsTopSectionContainer>
      <S.ResultsSection
        isResultsShown={isLocationResultsShown}
        isResultsExpanded={isResultsExpanded}
      >
        <S.MasonrySection
          isResultsShown={isLocationResultsShown}
          isResultsExpanded={isResultsExpanded}
          ref={resultsSectionRef}
        >
          {isLocationResultsShown && renderLocationTiles()}
          {(!filteredLocations || filteredLocations.length === 0) && (
            <S.NoResults>No results</S.NoResults>
          )}
        </S.MasonrySection>
      </S.ResultsSection>
      <S.CustomStickyExpandButton
        isReportRegenerationOpen={isReportRegenerationOpen}
        isResultsExpanded={isResultsExpanded}
        onToggleExpandResultsSection={onToggleExpandResultsSection}
        resultsSectionRef={resultsSectionRef}
        shouldShowButtonCallback={setIsShowingExpandButton}
      />
      {!isLocationResultsShown || !isShowingExpandButton ? (
        <SectionFooter />
      ) : null}
    </div>
  );
};

export default Locations;
