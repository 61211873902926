import { styled } from "@linaria/react";
import { red } from "styles/colors";

const MenuButton = styled.button`
  all: unset;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const TitleCardContainer = styled.div`
  border: 1px solid ${red.directRiskOutline};
  border-radius: 0.75rem;
`;

const S = {
  MenuButton,
  TitleCardContainer
};

export default S;
