import type { SearchResult } from "api/search";

export enum CollectionSearchActions {
  updateQuery = "updateQuery",
  updateResults = "updateResults",
  updateSearchTags = "updateSearchTags"
}

export interface CollectionSearchState {
  query: string;
  results: SearchResult[];
  searchTags: SearchResult[];
}

interface UpdateQueryAction {
  type: CollectionSearchActions.updateQuery;
  query: string;
}

interface UpdateResultsAction {
  type: CollectionSearchActions.updateResults;
  results: SearchResult[];
}

interface UpdateSearchTagsAction {
  type: CollectionSearchActions.updateSearchTags;
  searchTags: SearchResult[];
}

export type CollectionSearchAction =
  | UpdateQueryAction
  | UpdateResultsAction
  | UpdateSearchTagsAction;
