import { createContext } from "react";
import { CollectionSearchActions } from "./types";
import type { CollectionSearchState, CollectionSearchAction } from "./types";

export const initialState: CollectionSearchState = {
  query: "",
  results: [],
  searchTags: []
};

export const CollectionSearchContext = createContext({
  state: initialState,
  dispatch: (_action: CollectionSearchAction) => {}
});

export const collectionSearchReducer = (
  state: CollectionSearchState,
  action: CollectionSearchAction
): CollectionSearchState => {
  switch (action.type) {
    case CollectionSearchActions.updateQuery:
      return { ...state, query: action.query };
    case CollectionSearchActions.updateResults:
      return { ...state, results: action.results };
    case CollectionSearchActions.updateSearchTags:
      return { ...state, searchTags: action.searchTags };
    default:
      return state;
  }
};
