import { styled } from "@linaria/react";
import { standardColors, grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.nav`
  display: flex;
  gap: 1rem;
`;

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background: ${standardColors.white};
`;

const NavItem = styled.button<{ isActive: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.375rem;
  color: ${({ isActive }) => (isActive ? standardColors.white : grey.mid)};
  font-size: ${fontSize.xl};
  font-weight: 700;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;

  &:hover {
    color: ${standardColors.white};
  }

  &:focus {
    outline: none;
  }
`;

const S = { Container, NavItem, Divider };

export default S;
