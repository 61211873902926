import React, { useState } from "react";
import { Edit, Eye, User } from "react-feather";

import ModalContainer from "components/molecules/ModalContainer";
import { ReactComponent as RemoveUser } from "img/icons/remove-user-icon.svg";

import Heading from "components/atoms/Heading";
import useUserPreferences from "util/hooks/useUserPreferences";
import { AutoSharePermission } from "api/user/types";
import { SearchResult } from "api/search";

import ShareItem from "./ShareItem";
import AddAutoShareEntryControl from "./AddAutoShareEntryControl";

import S, { classNameOverrides } from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
}

const dropdownOptions = [
  { id: AutoSharePermission.Read, label: "Can view", icon: Eye },
  {
    id: AutoSharePermission.Write,
    label: "Can edit",
    icon: Edit,
    showSeparator: true
  },
  {
    id: AutoSharePermission.Delete,
    label: "Remove",
    icon: RemoveUser,
    className: classNameOverrides.removeOption
  }
];

const AutoShareModal = ({ isOpen, toggleOpen }: Props) => {
  const { state } = useUserPreferences();
  const { sharedWith } = state;

  const [selectedSuggestions, setSelectedSuggestions] = useState<
    SearchResult[]
  >([]);

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onExitClick={toggleOpen}
      title="Auto-share settings"
    >
      <S.ModalContent>
        <div>
          <S.Text>
            You can auto-share with any number of users and groups.
          </S.Text>
          <S.AutoshareWith>
            <Heading level={6}>Auto-share with</Heading>
            <AddAutoShareEntryControl
              selectedSuggestions={selectedSuggestions}
              setSelectedSuggestions={setSelectedSuggestions}
            />
          </S.AutoshareWith>
        </div>
        <div>
          <Heading level={6}>
            You reports will automatically be shared with
          </Heading>
          {!sharedWith.length ? (
            <S.EmptyPlaceholderText>
              Nothing to see here...
            </S.EmptyPlaceholderText>
          ) : (
            <S.SharedWithItemsContainer>
              {sharedWith.map(shareObj => {
                return (
                  <ShareItem
                    key={shareObj.id}
                    id={shareObj.id}
                    title={shareObj.title}
                    subtitle={shareObj.subtitle}
                    icon={<User />}
                    options={dropdownOptions}
                    savedOption={
                      dropdownOptions.find(option => {
                        return (
                          (option.id as string) ===
                          (shareObj.permission as string)
                        );
                      })!
                    }
                  />
                );
              })}
            </S.SharedWithItemsContainer>
          )}
        </div>
      </S.ModalContent>
    </ModalContainer>
  );
};

export default AutoShareModal;
