import React, { useState, CSSProperties, useContext } from "react";
import { Minus, Check } from "react-feather";

import { isPDX } from "static-config";

import { inject } from "mobx-react";

import { RadioButton } from "components/atoms/RadioButton";
import Popover from "components/atoms/Popover";
import ConfirmationForm from "components/molecules/ConfirmationForm";
import useViewerMode from "util/hooks/useViewerMode";
import ReportStore from "state/ReportStore";
import usePdxDiagnosticsMode from "util/hooks/usePdxDiagnosticsMode";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import DisabledConfirmingModal from "components/molecules/DisabledConfirmingModal";

import S from "./styles";

type Confidence = "confirmed" | "unconfirmed" | "discarded";

interface ConfidenceConfig {
  type: Confidence;
  typeLabel: string;
  reviewLabel?: string;
  tooltipExplainer: string;
  defaultExplainer: string;
  userOptionExplainer: string;
  undoExplainer?: string;
  undoTooltipExplainer?: string;
  regenExplainer?: string;
}

interface ConfidenceStates {
  confirmed: ConfidenceConfig;
  unconfirmed: ConfidenceConfig;
  discarded: ConfidenceConfig;
}

interface Props {
  subjectType?: string;
  currentSetConfidence: Confidence;
  originalSetConfidence: Confidence;
  confidenceHasBeenSetOnRegen?: boolean;
  onConfidenceChange: (
    newConfidence: string,
    originalSetConfidence: string,
    itemIds: string[] | string
  ) => void;
  itemIds: string[] | string;
  disabled?: boolean;
  disabledTooltipText?: string;
  reportStore: ReportStore;
  className?: string;
  style?: CSSProperties;
}

const CONFIDENCE_STATES: ConfidenceStates = {
  confirmed: {
    type: "confirmed",
    typeLabel: "Confirmed",
    reviewLabel: "Correct",
    defaultExplainer:
      "Xapien is confident this is linked to your subject either directly or indirectly.",
    tooltipExplainer:
      "You can change Xapien’s assessment that has no link to your subject.",
    userOptionExplainer: "I know this is linked to my subject.",
    undoExplainer: "You told us this relates to your subject.",
    undoTooltipExplainer:
      "You can undo your assessment that this relates to your subject.",
    regenExplainer:
      "You have previously confirmed this is linked to your subject."
  },
  discarded: {
    type: "discarded",
    typeLabel: "Discarded",
    reviewLabel: "Wrong",
    defaultExplainer: "Xapien is confident this has no link to your subject.",
    tooltipExplainer:
      "You can change Xapien’s assessment that does not relate to your subject",
    userOptionExplainer: "I know this has no link to my subject.",
    undoExplainer: "You told us does not relate to your subject.",
    undoTooltipExplainer:
      "You can undo your assessment that does not relate to your subject.",
    regenExplainer:
      "You have previously confirmed this has no link to your subject."
  },
  unconfirmed: {
    type: "unconfirmed",
    typeLabel: "For review",
    tooltipExplainer:
      "You can change Xapien’s assessment that the relevance of this should be reviewed.",
    defaultExplainer:
      "You may want to you review whether this is linked to your subject either directly or indirectly.",
    userOptionExplainer: "I'm not sure if this is linked to my subject."
  }
};

const WrongButtonIcon = ({
  isActive = false,
  appearsDisabled = false
}: {
  isActive?: boolean;
  appearsDisabled?: boolean;
}) => {
  return (
    <S.ButtonIcon isActive={isActive} appearsDisabled={appearsDisabled}>
      <Minus />
    </S.ButtonIcon>
  );
};

const RightButtonIcon = ({
  isActive = false,
  appearsDisabled = false
}: {
  isActive?: boolean;
  appearsDisabled?: boolean;
}) => {
  return (
    <S.ButtonIcon isActive={isActive} appearsDisabled={appearsDisabled}>
      <Check />
    </S.ButtonIcon>
  );
};

const ConfidenceMenu = ({
  currentSetConfidence,
  originalSetConfidence,
  confidenceHasBeenSetOnRegen,
  onConfidenceChange = () => {},
  itemIds,
  disabled = false,
  disabledTooltipText,
  reportStore,
  className,
  style,
  subjectType = "Subject"
}: Props) => {
  const [isConfidenceMenuOpen, setIsConfidenceMenuOpen] = useState(false);
  const [radioSelectionConfidence, setRadioSelectionConfidence] = useState();

  const { isViewerModeEnabled } = useViewerMode();
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const { enabled: pdxDiagnosticsEnabled } = usePdxDiagnosticsMode();

  // We can use the absence of the item ids as an indicator on whether
  // the report is old (therefore we do not have the correct data to perform confidence switching and regeneration)
  const isItemIdPresent =
    (Array.isArray(itemIds) && !itemIds?.length) || itemIds;

  const shouldMenuBeDisabled =
    disabled || !isItemIdPresent || isViewerModeEnabled;

  const hasChangedFromOriginalConfidence =
    currentSetConfidence !== originalSetConfidence;

  const onApplyConfidence = (newConfidence: Confidence) => {
    setIsConfidenceMenuOpen(false);
    onConfidenceChange(newConfidence, originalSetConfidence, itemIds);
    setRadioSelectionConfidence(undefined);
  };

  const getBannerText = () => {
    if (hasChangedFromOriginalConfidence) {
      return CONFIDENCE_STATES[currentSetConfidence]?.undoExplainer;
    }
    if (confidenceHasBeenSetOnRegen) {
      return CONFIDENCE_STATES[currentSetConfidence]?.regenExplainer;
    }
    return CONFIDENCE_STATES[currentSetConfidence]?.defaultExplainer;
  };

  const getTooltipText = () => {
    if (hasChangedFromOriginalConfidence) {
      return CONFIDENCE_STATES[currentSetConfidence]?.undoTooltipExplainer;
    }
    if (confidenceHasBeenSetOnRegen) {
      return CONFIDENCE_STATES[currentSetConfidence]?.regenExplainer;
    }
    return CONFIDENCE_STATES[currentSetConfidence]?.tooltipExplainer;
  };

  const renderConfidenceExplainer = (text?: string) => {
    return (
      <>
        <S.ConfidenceType>
          {CONFIDENCE_STATES[currentSetConfidence]?.typeLabel}
          <S.UserSetInfoText>:</S.UserSetInfoText>
        </S.ConfidenceType>{" "}
        {text}
      </>
    );
  };

  const renderTooltipText = () => {
    if (shouldMenuBeDisabled && disabledTooltipText) {
      return disabledTooltipText;
    }

    if (isViewerModeEnabled) {
      return "Confirm/discard is disabled in viewer mode.";
    }

    if (!isItemIdPresent) {
      return "Confirm/discard is disabled because this report was generated using an old verison of our software; please re-run the report";
    }

    const text = getTooltipText();

    return (
      <S.TooltipExplainerText>
        {renderConfidenceExplainer(text)}
      </S.TooltipExplainerText>
    );
  };

  const renderModalContent = () => {
    let body;
    let newConfidence: Confidence;
    let promptText;
    let promptSubtext;

    if (hasChangedFromOriginalConfidence) {
      body = (
        <div>
          <S.PromptText>
            <strong>
              Would you like to <span>undo</span> this?
            </strong>
          </S.PromptText>
          <S.PromptSubtext>
            It will go back to{" "}
            <strong>
              {originalSetConfidence.slice(0, 1).toUpperCase() +
                originalSetConfidence.slice(1)}
            </strong>
            .
          </S.PromptSubtext>
        </div>
      );
    } else if (
      currentSetConfidence === CONFIDENCE_STATES.confirmed.type ||
      currentSetConfidence === CONFIDENCE_STATES.discarded.type
    ) {
      if (currentSetConfidence === CONFIDENCE_STATES.confirmed.type) {
        newConfidence = CONFIDENCE_STATES.discarded.type;
        promptText = `Wrong ${subjectType}`;
        promptSubtext = "You're sure this does not relate to your subject.";
      } else {
        newConfidence = CONFIDENCE_STATES.confirmed.type;
        promptText = `Correct ${subjectType}`;
        promptSubtext = "You're sure this relates to your subject.";
      }

      body = (
        <div>
          <S.PromptText>
            <strong>{promptText}?</strong>
          </S.PromptText>
          <S.PromptSubtext>{promptSubtext}</S.PromptSubtext>
        </div>
      );
    } else {
      body = (
        <>
          <p>
            <strong>Tell Xapien what you know</strong>
          </p>
          {Object.values(CONFIDENCE_STATES).map(value => {
            if (value.type !== CONFIDENCE_STATES.unconfirmed.type) {
              return (
                <S.ConfidenceRadioInputContainer
                  key={value.type}
                  onClick={() => {
                    newConfidence = value.type;
                    setRadioSelectionConfidence(value.type);
                  }}
                >
                  <RadioButton
                    value={value.type}
                    checked={radioSelectionConfidence === value.type}
                    onChange={() => {
                      newConfidence = value.type;
                      setRadioSelectionConfidence(value.type);
                    }}
                  />
                  <S.ConfidenceLabel htmlFor={value.type}>
                    <strong>{value.reviewLabel}</strong>
                  </S.ConfidenceLabel>
                  : {value.userOptionExplainer}
                </S.ConfidenceRadioInputContainer>
              );
            }
            return null;
          })}
        </>
      );
    }

    if (isPDX && !diagnosticsModeEnabled && !pdxDiagnosticsEnabled) {
      return (
        <S.DisabledConfirmingModalContainer>
          <DisabledConfirmingModal
            onClose={() => setIsConfidenceMenuOpen(false)}
          />
        </S.DisabledConfirmingModalContainer>
      );
    }

    const getPrimaryActionIcon = () => {
      if (hasChangedFromOriginalConfidence) {
        return null;
      }

      if (currentSetConfidence === CONFIDENCE_STATES.confirmed.type) {
        return <WrongButtonIcon />;
      }

      if (currentSetConfidence === CONFIDENCE_STATES.discarded.type) {
        return <RightButtonIcon />;
      }

      return null;
    };

    const primaryActionIcon = getPrimaryActionIcon();

    return (
      <ConfirmationForm
        header="Tell Xapien what you know"
        subHeader={renderConfidenceExplainer(getBannerText())}
        body={body}
        secondaryActionString="Cancel"
        primaryActionString={
          <S.PrimaryActionString>
            {hasChangedFromOriginalConfidence ? "Undo" : promptText ?? "Apply"}
          </S.PrimaryActionString>
        }
        primaryActionIcon={primaryActionIcon}
        togglePopover={() => setIsConfidenceMenuOpen(false)}
        onPrimaryActionClick={() =>
          onApplyConfidence(
            hasChangedFromOriginalConfidence
              ? originalSetConfidence
              : newConfidence ?? radioSelectionConfidence
          )
        }
        reportStore={reportStore}
        isPrimaryActionDisabled={
          currentSetConfidence === CONFIDENCE_STATES.unconfirmed.type &&
          !radioSelectionConfidence
        }
      />
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={className} style={style} onClick={e => e.stopPropagation()}>
      <Popover
        alignment="bottom-end"
        hideArrow
        borderRadius={12}
        trigger="click"
        isOpenOverride={isConfidenceMenuOpen}
        onRequestClose={() => setIsConfidenceMenuOpen(false)}
        content={renderModalContent()}
        position="fixed"
      >
        <Popover
          position="fixed"
          borderRadius={12}
          disabled={isConfidenceMenuOpen}
          content={<S.TooltipContent>{renderTooltipText()}</S.TooltipContent>}
          maxWidth="300px"
        >
          <S.DropdownToggle
            disabled={shouldMenuBeDisabled}
            onClick={() => setIsConfidenceMenuOpen(prev => !prev)}
            className={
              hasChangedFromOriginalConfidence || confidenceHasBeenSetOnRegen
                ? "hasChangedFromOriginalConfidence"
                : ""
            }
          >
            <S.MenuButtonIcon
              aria-label="Change confidence"
              className={
                hasChangedFromOriginalConfidence || confidenceHasBeenSetOnRegen
                  ? "hasChangedFromOriginalConfidence"
                  : ""
              }
            />
          </S.DropdownToggle>
        </Popover>
      </Popover>
    </div>
  );
};

export default inject("reportStore")(ConfidenceMenu);
