import { styled } from "@linaria/react";

import { grey, blue } from "styles/colors";

const ErrorContainer = styled.div`
  padding: 2rem;
  margin: 2rem;
  color: ${grey.dark};
  display: block;
  background-color: ${grey.panel};
  border-radius: 5px;
  max-width: 95%;

  a,
  a:hover {
    color: ${blue.icon};
  }
`;

const S = { ErrorContainer };

export default S;
