import React from "react";
import { Chip, AutocompleteRenderGetTagProps } from "@mui/material";
import { blue } from "styles/colors";
import { family } from "styles/fonts";

import { X } from "react-feather";

const styles = {
  fontFamily: family.interRegular,
  letterSpacing: "normal",
  backgroundColor: blue.xapienDark,
  borderRadius: "4px",
  color: "white",
  padding: "6px",
  height: "24px",
  "& .MuiChip-label": {
    paddingLeft: "2px",
    paddingRight: "4px"
  },
  "& .MuiChip-deleteIcon": {
    color: "white",
    margin: 0,
    "&:hover": { color: "white" }
  }
};

const InputTags = (
  options: string[],
  getTagProps: AutocompleteRenderGetTagProps
) => {
  return options.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      label={option}
      deleteIcon={<X width="20px" />}
      sx={styles}
    />
  ));
};

export default InputTags;
