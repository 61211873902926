import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

import CommonButton from "components/atoms/Button";

const SortFilterCategories = styled.div`
  max-height: 155px;
  overflow-y: auto;
  font-size: ${fontSize.sm};
`;

const FilterTitle = styled.div`
  font-size: ${fontSize.sm};

  display: flex;
  justify-content: space-between;
`;

const FilterTitleLabel = styled.span`
  font-family: ${family.interSemiBold};
  padding-left: 8px;
`;

const SortButton = styled(CommonButton)`
  padding: 0 8px !important;
  border: 1px solid ${grey.rule} !important;
  border-radius: 16px !important;
  font-size: ${fontSize.sm} !important;
  min-height: 20px !important;
  min-width: 38px !important;

  background-color: ${props =>
    props.isSortAlphabeticallyActive ? grey.ghost : "transparent"} !important;

  color: ${props =>
    props.isSortAlphabeticallyActive
      ? standardColors.white
      : grey.mid} !important;

  &:hover,
  &:focus {
    box-shadow: none !important;
  }
`;

const Controls = styled.div`
  padding-bottom: 8px;
`;

const S = {
  SortFilterCategories,
  FilterTitle,
  FilterTitleLabel,
  SortButton,
  Controls
};

export default S;
