import { styled } from "@linaria/react";
import { Spinner } from "reactstrap";
import { AlertCircle } from "react-feather";

import { red, grey, purple, standardColors } from "styles/colors";

import { isPDX } from "static-config";

// @ts-ignore
import theme from "theme";

import MenuStyles from "components/organisms/InsightReport/ReportContent/Menu/styles";

// Important is needed as Menu item is styling svg colours itself
const ErrorIcon = styled(AlertCircle)`
  color: ${red.directRiskFill} !important;
`;

const LoadingIcon = styled(Spinner)`
  width: 16px;
  height: 16px;
`;

const ActionMenuItem = styled(MenuStyles.MenuItem)<{
  active?: boolean;
  disabled?: boolean;
}>`
  color: ${({ active, disabled }) => {
    if (active) {
      return standardColors.white;
    }

    if (disabled) {
      return grey.ghost;
    }

    if (isPDX) {
      return theme.primaryColor;
    }

    return purple.darker;
  }};

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  svg {
    width: ${({ active }) => (active ? "20px" : "16px")};
    height: ${({ active }) => (active ? "20px" : "16px")};

    rect {
      fill: ${({ disabled }) => {
        if (disabled) {
          return grey.ghost;
        }

        if (isPDX) {
          return theme.primaryColor;
        }

        return purple.darker;
      }};
    }

    path {
      fill: ${standardColors.white};
    }
  }
`;

const S = { ActionMenuItem, ErrorIcon, LoadingIcon };

export default S;
