import type {
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection,
  Element as RawElement
} from "api/insights";

export const removeElementFromReportByElementId = (
  report: RawReport,
  elementId: string
): RawReport => {
  const topSection = report.sections.find((s: RawSection) =>
    s.subsections.find((sub: RawSubsection) =>
      sub.elements.find(element => element.id === elementId)
    )
  );

  if (!topSection) return report;

  const subSection = topSection.subsections.find((sub: RawSubsection) =>
    sub.elements.find(element => element.id === elementId)
  );

  if (!subSection) return report;

  const updatedElements = subSection.elements.filter(
    (element: RawElement) => element.id !== elementId
  );

  const updatedSubSection = { ...subSection, elements: updatedElements };

  const updatedSection = {
    ...topSection,
    subsections: topSection.subsections.map(sub =>
      sub.id === subSection.id ? updatedSubSection : sub
    )
  };

  return {
    ...report,
    sections: report.sections.map((s: RawSection) =>
      s.id === updatedSection.id ? updatedSection : s
    )
  };
};
