import React, { FC, SVGProps, useState } from "react";

import { SearchResult } from "api/search";
import useManageGroup from "util/hooks/useManageGroup";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import ButtonNew from "components/atoms/ButtonNew";
import { Spinner } from "reactstrap";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import SearchInputWithOptions, {
  DropdownOption
} from "components/molecules/SearchInputWithOptions";
import useUserAndGroupSearch from "util/hooks/useUserAndGroupSearch";
import { GroupUserRole } from "api/groups/types";

import S from "./styles";

const dropdownOptions = [
  { id: GroupUserRole.Standard, label: "Member" },
  { id: GroupUserRole.Admin, label: "Group admin" }
];

const AddGroupUserInputControl = ({
  canAddUsers,
  selectedSuggestions,
  setSelectedSuggestions
}: {
  canAddUsers: boolean;
  selectedSuggestions: SearchResult[];
  setSelectedSuggestions: React.Dispatch<React.SetStateAction<SearchResult[]>>;
}) => {
  const [query, setQuery] = useState("");
  const [selectedDropdownOption, setSelectedDropdownOption] = useState<
    DropdownOption<GroupUserRole>
  >(dropdownOptions[0]);

  const { suggestions, isFetchingSuggestions, onSearchUpdate } =
    useUserAndGroupSearch({ usersOnly: true });

  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();

  const { addMembersToGroup, addAdminsToGroup } = useManageGroup();

  const onAddMembersClick = async () => {
    dispatch({ type: RequestActions.SendRequest });
    const userIds = selectedSuggestions.map(suggestion => suggestion.id);

    if (selectedDropdownOption.id === GroupUserRole.Admin) {
      const result = await addAdminsToGroup(userIds);
      if (!result.status) {
        dispatch({
          type: RequestActions.SetError,
          errorMessage: result.message
        });
        return;
      }
    } else {
      const result = await addMembersToGroup(userIds);
      if (!result.status) {
        dispatch({
          type: RequestActions.SetError,
          errorMessage: result.message
        });
        return;
      }
    }
    // Reset selected suggestions (they've been successfully added)
    setSelectedSuggestions([]);
    dispatch({ type: RequestActions.SetSuccess });
  };

  const onQueryInputChange = (newQuery: string) => {
    setQuery(newQuery);
    onSearchUpdate(newQuery);
  };

  const onSelectedSuggestionsChange = (
    newSelectedSuggestions: SearchResult[]
  ) => {
    if (newSelectedSuggestions.length === 0) {
      setSelectedSuggestions([]);
      return;
    }

    let updatedSuggestions = [...selectedSuggestions];
    const existingSuggestion = selectedSuggestions.findIndex(
      suggestion =>
        suggestion?.id ===
        newSelectedSuggestions[newSelectedSuggestions.length - 1]?.id
    );

    // If the suggestion has already been selected and the user selected the same suggestion
    // in the options list, then remove it
    if (
      existingSuggestion > -1 &&
      newSelectedSuggestions.length > selectedSuggestions.length
    ) {
      updatedSuggestions = [
        ...updatedSuggestions.slice(0, existingSuggestion),
        ...updatedSuggestions.slice(existingSuggestion + 1)
      ];
    } else {
      updatedSuggestions = newSelectedSuggestions;
    }
    setSelectedSuggestions(updatedSuggestions);
  };

  return (
    <>
      <S.AddUserControlContainer>
        <SearchInputWithOptions
          query={query}
          options={suggestions}
          loadingText={
            isFetchingSuggestions ? "Loading..." : "No results found"
          }
          searchTags={selectedSuggestions}
          placeholder="Enter name"
          onInputChange={onQueryInputChange}
          onChange={onSelectedSuggestionsChange}
          onFocus={() => {}}
          onClear={() => {}}
          onSubmit={() => {}}
          dropdownOptions={dropdownOptions}
          onDropdownOptionChange={option => {
            setSelectedDropdownOption(option);
          }}
          selectedDropdownOption={selectedDropdownOption}
          disabled={!canAddUsers}
        />
        <ButtonNew
          type={ButtonType.Filled}
          size={ButtonSize.Large}
          text="Add"
          disabled={!canAddUsers || fetching || !selectedSuggestions.length}
          IconTrailing={
            fetching
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
          onClick={onAddMembersClick}
        />
      </S.AddUserControlContainer>
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            "There was an issue adding users to the group. Try again in a moment."
          }
          onClick={() => {
            dispatch({ type: RequestActions.ResetError });
          }}
        />
      )}
    </>
  );
};

export default AddGroupUserInputControl;
