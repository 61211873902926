import { styled } from "@linaria/react";

export interface TextProps {
  color: string;
}

const Text = styled.span<TextProps>`
  text-decoration: ${({ color }) => color} wavy underline;
`;

const S = {
  Text
};

export default S;
