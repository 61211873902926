import { createContext } from "react";
import { inject, observer } from "mobx-react";
import { reportStore } from "./Store";

export const ImageStoreContext = createContext(reportStore.imageMap);

export default inject(
  "reportStore",
  "userAssessmentStore",
  "enquiryStore"
)(observer(ImageStoreContext));
