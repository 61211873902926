import { styled } from "@linaria/react";
import { green, standardColors } from "styles/colors";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: ${standardColors.black};
`;

const FlagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

const FlagOverLapContainer = styled(FlagContainer)`
  margin-left: -6px;
`;

const ExtraFlagsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -6px;
  width: 40px;
  height: 40px;
  background: ${green.pale};
  border-radius: 50%;
  color: ${green.xapienGreen};
`;

const S = {
  Container,
  FlagContainer,
  FlagOverLapContainer,
  ExtraFlagsCount
};

export default S;
