import { styled } from "@linaria/react";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const S = { Container };

export default S;
