import { styled } from "@linaria/react";

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const S = {
  SectionHeader
};

export default S;
