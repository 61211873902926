import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import ReportUsagePanel from "components/molecules/ReportUsagePanel";
import TitleCard from "components/molecules/TitleCard";
import { blue, grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";
import { ReactComponent as Building } from "img/building.svg";

const ManageOrganisation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 90vh;
  justify-content: space-between;
`;

const ManageOrganisationInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const CustomTitleCard = styled(TitleCard)`
  background-color: ${blue.portalBlue};

  h5 + div {
    color: ${standardColors.white};
  }
`;

const OrgDetails = styled.p`
  margin: 0;
  color: ${grey.rule};
  margin-left: 3.75rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const UsagePanel = styled(ReportUsagePanel)`
  background-color: ${blue.portalBlue};
  margin-top: 0.75rem;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  width: 100%;
`;

const Button = styled(ButtonNew)`
  flex: 1;
`;

const LeftControls = styled.div`
  display: flex;
  gap: 1.25rem;
`;

const ErrorFallback = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 12.5rem;
  color: ${grey.ghost};
  font-size: ${fontSize.xl};
  gap: 1rem;
`;

const BuildingIcon = styled(Building)`
  transform: scale(0.875);
`;

const Text = styled.p`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  line-height: 1.375rem;
  margin: 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 34.375rem;
  overflow: auto;
`;

const LoadingBar = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: 104%;
  height: 104%;
  border-radius: 50%;
  border: 4px solid ${blue.xapienDark};
  border-left-color: ${blue.blueDiamond};
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const AvatarIconContainer = styled.div`
  position: relative;
  border-radius: 50%;
`;

const MonitoringModalSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const TextWrapper = styled.div`
  color: ${grey.ghost};
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const S = {
  ManageOrganisation,
  ManageOrganisationInnerContainer,
  CustomTitleCard,
  OrgDetails,
  InputGroup,
  UsagePanel,
  Controls,
  Button,
  LeftControls,
  ErrorFallback,
  BuildingIcon,
  MonitoringModalSectionHeader,
  AvatarIconContainer,
  LoadingBar,
  HeaderWrapper,
  TextWrapper,
  Text,
  ModalContent
};

export default S;
