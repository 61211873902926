import { css } from "@linaria/core";

const mapboxContainer = css`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const classNameOverrides = {
  mapboxContainer
};
