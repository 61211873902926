import { styled } from "@linaria/react";

import { ReactComponent as ExitIcon } from "img/icons/cross-in-circle.svg";
import { grey, standardColors, green } from "styles/colors";
import { fontSize } from "styles/fonts";

const Tab = styled.button<{ selected?: boolean; backgroundColor: string }>`
  all: unset;
  font-size: ${fontSize.sm};
  color: ${props => (props.selected ? grey.dark : standardColors.white)};
  min-width: 230px;
  width: 230px;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => {
    if (props.selected) {
      return standardColors.white;
    }
    return props.backgroundColor ?? green.xapienGreen;
  }}};
  cursor: pointer;
  border-radius: 11px 11px 0 0;
  margin-bottom: 0 !important;
  padding: 0 16px;
  z-index: ${props => (props.selected ? 1000 : 1)};
  box-shadow: ${props =>
    props.selected ? `1px solid ${standardColors.white}` : "none"};
  outline: none !important;

  &:hover,
  &:focus {
    filter: ${props => (props.selected ? "none" : "brightness(96%)")};
    box-shadow: 0 0 1px 2px ${props => props.backgroundColor};
  }

  transition: filter 0.3s ease-out, box-shadow 0.3s ease-out;
`;

const TabTitle = styled.div`
  width: 156px;
  padding-left: 4px;
`;

const TabExitIcon = styled(ExitIcon)<{ tabIsSelected?: boolean }>`
  height: 20px;
  width: 20px;
  z-index: 10;
  margin-right: -6px;

  &:hover {
    cursor: pointer;
  }

  circle {
    stroke: transparent;
  }

  path {
    fill: ${props => (props.tabIsSelected ? grey.mid : standardColors.white)};
  }
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
`;

const TabContainer = styled.div`
  display: flex;
`;

const S = {
  Tab,
  TabTitle,
  TabExitIcon,
  TabContent,
  TabContainer
};

export default S;
