import { createContext } from "react";

import {
  InsightsTimelineAction,
  InsightsTimelineActions,
  InsightsTimelineState,
  InsightsTimelineStatus
} from "./types";

export const initialState: InsightsTimelineState = {
  errorMessage: undefined,
  timeline: undefined,
  status: InsightsTimelineStatus.idle
};

export const insightsTimelineReducer = (
  state: InsightsTimelineState,
  action: InsightsTimelineAction
) => {
  switch (action.type) {
    case InsightsTimelineActions.fetchTimeline:
      return { ...state, status: InsightsTimelineStatus.fetching };
    case InsightsTimelineActions.updateTimeline:
      return {
        ...state,
        status: action.timeline?.length
          ? InsightsTimelineStatus.idle
          : InsightsTimelineStatus.noTimeline,
        timeline: action.timeline,
        errorMessage: undefined
      };
    case InsightsTimelineActions.deleteTimeLineEvent:
      return {
        ...state,
        status: InsightsTimelineStatus.deletingEvent,
        eventId: action.eventId
      };
    case InsightsTimelineActions.deleteTimelineEventSuccess:
      return {
        ...state,
        status: InsightsTimelineStatus.deletingEventSuccess
      };
    case InsightsTimelineActions.errorDeletingTimelineEvent:
    case InsightsTimelineActions.errorFetchingTimeline:
      return {
        ...state,
        status: InsightsTimelineStatus.error,
        errorMessage: action.errorMessage
      };
    default:
      return state;
  }
};

export const InsightsTimelineContext = createContext({
  state: initialState,
  dispatch: (_: InsightsTimelineAction) => {}
});
