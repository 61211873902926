import React, { FC, useEffect, useState } from "react";

import { InformationSource } from "api/report/report-types";
import { Segment } from "api/insight-reports/types";

import WithInspector from "components/organisms/WithInspector";
import GeneratingText from "components/atoms/GeneratingText";

import { useInsightsSources } from "util/hooks/useInsightsSources";

import { reportStore } from "state/Store";
import { motion } from "framer-motion";
import { InsightsAmaAnswer } from "util/hooks/useInsightsAma/types";
import S from "./styles";

type SourcedAnswerSegmentProps = {
  segment: Segment;
};

const SourcedAnswerSegment: FC<SourcedAnswerSegmentProps> = ({ segment }) => {
  const mapSource = (sourceId: string) => {
    return reportStore.webAndMediaData.find(data => data.sourceId === sourceId);
  };

  const sources = segment.sources
    .flatMap(({ wamSourceIds, extract: { text } }) => {
      return wamSourceIds.map(sourceId => {
        const source = mapSource(sourceId);

        if (!source) return undefined;

        const parseSingleDigitDateString = (value: number): string =>
          value < 10 ? `0${value}` : value.toString();

        const publicationDateFull =
          source.datePublished &&
          source.datePublished.day &&
          source.datePublished.month &&
          source.datePublished.year
            ? new Date(
                `${source.datePublished.year}-${parseSingleDigitDateString(
                  source.datePublished.month
                )}-${parseSingleDigitDateString(source.datePublished.day)}`
              ).toISOString()
            : "";

        return {
          ...source,
          heading: source.title || source.heading,
          provider: source.publisher || source.provider,
          publicationDateFull,
          readingTime: undefined,
          subHeading: undefined,
          tags: [],
          text
        };
      });
    })
    .filter(Boolean) as InformationSource[];

  if (sources.length === 0) {
    return <>{segment.text}</>;
  }

  return (
    <>
      <WithInspector
        display="inline"
        sources={sources}
        showTopSection={false}
        popoverTitle={`${sources.length} Strongest sources`}
        withPagination
        showXiReactToolTip
        renderSourceItems={(
          _sourcesArr,
          _,
          sourceContainerRef,
          page,
          paginationComponent,
          resizeHeight
        ) => {
          return (
            <S.SourceItemsContainer ref={sourceContainerRef}>
              {sources[page] && (
                // @ts-ignore
                <S.MediaSourceCard
                  {...sources[page]}
                  // TODO: MediaSourceCard needs types
                  show
                  key={`${sources[page].url}_${page}`}
                  activeRiskDirectionality="direct"
                  filterOutIndirectTags
                  paginationComponent={paginationComponent}
                  resizeHeight={resizeHeight}
                />
              )}
            </S.SourceItemsContainer>
          );
        }}
      >
        {segment.text}
      </WithInspector>
      {segment.text.trimEnd().endsWith(".") ? " " : ""}
      {/* ^sorts out a spacing issue */}
    </>
  );
};

type InsightsAnswerProps = {
  answer: InsightsAmaAnswer;
  isFetchingSources: boolean;
  onFetchSources: (v: boolean) => void;
};

const InsightsAnswer: FC<InsightsAnswerProps> = ({
  answer,
  isFetchingSources,
  onFetchSources
}) => {
  const { sourcedSegments, fetchSources, error } = useInsightsSources({
    answer
  });

  useEffect(() => {
    if (error) {
      onFetchSources(false);
    }
  }, [error, onFetchSources]);

  useEffect(() => {
    if (sourcedSegments && sourcedSegments.length) {
      onFetchSources(false);
    }
  }, [sourcedSegments, onFetchSources]);

  const onInspectorOpen = () => {
    onFetchSources(true);
    fetchSources();
  };

  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    if (!hasAnimated) {
      setHasAnimated(true);
    }
  }, [hasAnimated]);

  if (sourcedSegments && sourcedSegments.length) {
    return (
      <div>
        {sourcedSegments.map((segment, index) => (
          <SourcedAnswerSegment key={index} segment={segment} />
        ))}
      </div>
    );
  }

  if (isFetchingSources && !error) {
    return (
      <S.FetchingSourcesContainer>
        <WithInspector display="inline" hidePopover showXiReactToolTip>
          {answer.elements?.[0].body}
        </WithInspector>
        <GeneratingText />
      </S.FetchingSourcesContainer>
    );
  }

  return (
    <div>
      <WithInspector
        display="inline"
        showXiReactToolTip
        isInspectorOpen={(open: boolean) => open && onInspectorOpen()}
      >
        <motion.div
          initial="hidden"
          animate={hasAnimated ? "reveal" : "hidden"}
        >
          {answer.elements?.[0].body}
        </motion.div>
      </WithInspector>

      {error && (
        <S.ErrorFetchingSourcesMessage>
          Oops! There&apos;s been a hiccup in generating sourcing. Please try
          again later.
        </S.ErrorFetchingSourcesMessage>
      )}
    </div>
  );
};

export default InsightsAnswer;
