import { InsightReportState } from "util/hooks/useInsightReport/types";

export const getReorderedSubSection = ({
  state
}: {
  state: InsightReportState;
}) => {
  const section = state.report?.sections?.find(
    s => s.id === state.reorderingSectionId
  );

  if (!section) return null;

  return section.content
    .flatMap(item => item.content)
    .find(item => item?.id === state.reorderingSubsectionId);
};
