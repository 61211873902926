import React, { FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import type { Location, Params, NavigateFunction } from "react-router-dom";

/*
 * These functions are only a stop-gap required for while we still have
 * class-based React components.
 * As these are slowly refactored into functional components, it's better
 * to use the hooks directly.
 */

export function withLocation(Component: FC<{ location: Location }>) {
  const LocationComponent = (props: Record<string, unknown>) => (
    <Component {...props} location={useLocation()} />
  );

  return LocationComponent;
}

export function withParams(Component: FC<{ params: Params }>) {
  const ParamsComponent = (props: Record<string, unknown>) => (
    <Component {...props} params={useParams()} />
  );

  return ParamsComponent;
}

export function withNavigate(Component: FC<{ navigate: NavigateFunction }>) {
  const NavigateComponent = (props: Record<string, unknown>) => (
    <Component {...props} navigate={useNavigate()} />
  );

  return NavigateComponent;
}
