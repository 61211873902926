import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Panel = styled.div`
  position: relative;
  width: 100%;
  background-color: ${standardColors.white};
  page-break-inside: avoid;
  min-height: 30px;
`;

const SectionHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${standardColors.white};
  height: 54px;
  font-size: ${fontSize.sm};
`;

const SectionCount = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize["2xl"]};
`;

const DateRange = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  height: 22px;
`;

const SectionContent = styled.div`
  border-top: 1px solid ${grey.rule};
  padding-bottom: 16px;
  margin: 0 16px;
`;

const SectionHeaderText = styled.div`
  color: ${grey.mid};
`;

const S = {
  Panel,
  SectionHeader,
  SectionCount,
  DateRange,
  SectionContent,
  SectionHeaderText
};

export default S;
