import React from "react";
import { isPDX } from "static-config";
import config from "config";

import S from "./styles";

const ErrorGenerating = () => {
  return (
    <S.Container>
      <S.LogoOuterContainer>
        <S.LogoInnerContainer>
          <S.Logo />
        </S.LogoInnerContainer>
      </S.LogoOuterContainer>

      <S.Heading level={4}>Let’s work together</S.Heading>

      <S.Paragraph>
        Insights distills thousands of web and media articles, but we have not
        been able to confidently attribute any of these to your subject. This
        could be due to the subject’s limited online presence.
      </S.Paragraph>

      {!isPDX && (
        <>
          <S.Heading level={5}>Here’s what we recommend:</S.Heading>
          <S.Recommendation>
            Review the articles in the discarded Web and Media section and
            confirm anything you recognise, and then re-generate your report.
          </S.Recommendation>
        </>
      )}

      <S.Paragraph>
        If you still need help, our{" "}
        <a href={`mailto:${config.supportEmail}`}>Customer Success</a> team are
        on hand.
      </S.Paragraph>
    </S.Container>
  );
};

export default ErrorGenerating;
