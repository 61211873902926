import React, { ReactNode } from "react";

import config from "config";

import { Mail } from "react-feather";
import { isPDX } from "static-config";

import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

const DesktopOnlyWarning = ({ children }: { children: ReactNode }) => {
  const onContactSupport = () => {
    window.open(
      `mailto:${config.supportEmail}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const appName = isPDX ? "Dow Jones Integrity Check" : "Xapien";

  return (
    <S.WarningContainer>
      <S.OuterContainer>
        <S.InnerContainer>
          <S.IconContainer>
            <S.Icon />
          </S.IconContainer>
          <S.Content>
            <S.Heading level={5}>Unavailable on mobile</S.Heading>
            <S.Description>
              <S.Highlight>{appName}</S.Highlight> is not available on mobile
              devices yet. Please log in on desktop for full functionality.
            </S.Description>
          </S.Content>

          <S.ContactButton
            onClick={onContactSupport}
            type={ButtonType.Filled}
            size={ButtonSize.Large}
            text="Customer Success"
            IconTrailing={Mail}
          />
        </S.InnerContainer>
      </S.OuterContainer>
      {children}
    </S.WarningContainer>
  );
};

export default DesktopOnlyWarning;
