import http from "services/http";
import endpoints from "services/endpoints";
import { apm } from "@elastic/apm-rum";

export default class Assertions {
  /**
   * @param   {Object} assertion
   * @param   {Object} enquiryId
   * @return  {String} {"AssertionId": "...", "AssertionResult": "..." or null}
   */
  async insertAssertion(assertion, enquiryId = null) {
    const url = endpoints.assertion;
    const res = await http.post(url, {
      Assertion: assertion,
      ReportId: enquiryId
    });
    return res.body;
  }

  /**
   * @param   {String}  testCaseId
   * @param   {String}  userId
   * @return  {Array}   assertions
   */
  async listAssertions(testCaseId = "", userId = "") {
    let url = endpoints.assertion;
    if (testCaseId && userId) {
      url += `?TestCaseId=${testCaseId}&UserId=${userId}`;
    } else if (testCaseId) {
      url += `?TestCaseId=${testCaseId}`;
    } else if (userId) {
      url += `?UserId=${userId}`;
    }
    try {
      return await http.get(url);
    } catch (e) {
      apm.captureError(e);
      console.error(
        `Error: listAssertions request failed for testCaseId: "${testCaseId}", userId: "${userId}"`,
        e
      );
      return [];
    }
  }

  /**
   * @param   {String} assertionId
   * @return  {Object} assertion
   */
  async getAssertion(assertionId) {
    const url = `${endpoints.assertion}/${assertionId}`;
    try {
      return await http.get(url);
    } catch (e) {
      apm.captureError(e);
      console.error(
        `Error: getAssertion request failed for "${assertionId}"`,
        e
      );
      return undefined;
    }
  }

  /**
   * @param   {String} assertionId
   */
  async deleteAssertion(assertionId) {
    const url = `${endpoints.assertion}/${assertionId}`;
    try {
      return await http.delete(url);
    } catch (e) {
      apm.captureError(e);
      console.error(
        `Error: deleteAssertion request failed for "${assertionId}"`,
        e
      );
      return undefined;
    }
  }

  /**
   * Queries the EnquiryStateTable-{env} for Subject and Context, using them to obtain the testCaseId.
   * For now, assume the environment is QA.
   * @param   {String} enquiryId
   * @return  {Object} testCaseId
   */
  async getTestCaseFromReport(enquiryId) {
    const url = `${endpoints.testcaseFromReport}?ReportId=${enquiryId}`;
    try {
      const {
        body: { TestCaseId }
      } = await http.get(url);
      return TestCaseId;
    } catch (e) {
      apm.captureError(e);
      console.error(
        `Error: getTestCaseFromReport request failed for "${enquiryId}"`,
        e
      );
      return undefined;
    }
  }

  /**
   * Sends enquiryId to lambda, which retrieves the relevant assertions and validates them, returning their results.
   * @param   {String} enquiryId
   * @return  {Array} [(assertion, assertionResult)]
   */
  async validateReport(enquiryId) {
    const url = `${endpoints.validateReport}?ReportId=${enquiryId}`;
    try {
      const {
        body: { ValidationResults }
      } = await http.post(url, {}).then(res => res);
      return JSON.parse(ValidationResults);
    } catch (e) {
      apm.captureError(e);
      console.error(
        `Error: validateReport request failed for "${enquiryId}"`,
        e
      );
      return undefined;
    }
  }
}
