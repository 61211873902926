import { styled } from "@linaria/react";

import ButtonNew from "components/atoms/ButtonNew";

import { hexToRgb } from "styles/hexToRgb";
import { standardColors, green } from "styles/colors";

const Container = styled.div`
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 100%;
  height: 100%;
  padding: 25px 47px;
  background: ${standardColors.white};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  color: ${standardColors.black};

  p {
    text-align: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(
    ${() => {
      const { r, g, b } = hexToRgb(green.xapienGreen);
      return `${r}, ${g}, ${b}`;
    }},
    0.15
  );
  width: 48px;
  height: 48px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Button = styled(ButtonNew)`
  color: ${standardColors.white} !important;
  background-color: ${green.xapienGreen};

  svg path {
    stroke: ${standardColors.white} !important;
  }

  &:hover {
    color: ${standardColors.white} !important;
    svg path {
      stroke: ${standardColors.white} !important;
    }
  }
`;

const S = {
  Container,
  LogoContainer,
  Button
};

export default S;
