import { useRef, useLayoutEffect } from "react";
import { usePrintableReportState } from "./usePrintableState";

const getScrollPosition = elementRef => {
  const element = elementRef.current;
  if (element) {
    return { x: element.scrollLeft, y: element.scrollTop };
  } else {
    return { x: 0, y: 0 };
  }
};

export const usePrintableScrollPosition = key => {
  const elementRef = useRef(null);
  const internalKey = `${key}-scroll-position`;
  const [position, setPosition] = usePrintableReportState(internalKey, null);
  const currentScroll = useRef(null);

  const intervalInternal = 200;
  let throttleTimeout = null;
  // if (position) {
  //   console.log("Scroll position from url", position, {internalKey});
  // }

  useLayoutEffect(() => {
    const container = elementRef.current;
    if (container) {
      const callback = () => {
        const scrollPosition = getScrollPosition(elementRef);
        // console.log("Setting scroll position", { scrollPosition, key});
        // eslint-disable-next-line  react-hooks/exhaustive-deps
        throttleTimeout = null;
        currentScroll.current = scrollPosition;
        setPosition(scrollPosition);
      };
      if (position && !currentScroll.current) {
        currentScroll.current = { x: position.x, y: position.y };
        // console.log("Scrolling to", position, {internalKey});
        container.scroll(position.x, position.y);
      }
      const handleScroll = () => {
        // console.log("handleScroll", { throttleTimeout, );
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callback, intervalInternal);
        }
      };
      container.addEventListener("scroll", handleScroll);
      // console.log("Added scroll event handler", { internalKey, container});

      return () => {
        if (throttleTimeout) {
          window.clearTimeout(throttleTimeout);
        }
        container.removeEventListener("scroll", handleScroll);
        // console.log("Removed scroll event handler");
      };
    } else {
    }
  });
  return elementRef;
};
