import { Avatar as MuiAvatar } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";

import { standardColors, blue } from "styles/colors";

import { AvatarSize } from "./types";

const getAvatarDimensions = (size: AvatarSize): string => {
  switch (size) {
    case AvatarSize.Tiny:
      return "1rem";
    case AvatarSize.Small:
      return "1.5rem";
    case AvatarSize.Medium:
      return "2rem";
    case AvatarSize.Large:
    default:
      return "3rem";
  }
};

const getAvatarFontSize = (size: AvatarSize): string => {
  switch (size) {
    case AvatarSize.Small:
      return "0.75rem";
    case AvatarSize.Tiny:
      return "0.5rem";
    default:
      return "1.125rem";
  }
};

/** MUI style overrides – didn't seem to work with Linaria */

const Avatar = muiStyled(MuiAvatar, {
  shouldForwardProp: prop => prop !== "avatarSize" && prop !== "hasBorder"
})(
  ({
    avatarSize,
    hasBorder,
    background
  }: {
    avatarSize: AvatarSize;
    hasBorder?: boolean;
    background?: string;
  }): any => {
    const dimension: string = getAvatarDimensions(avatarSize);
    const fontSize: string = getAvatarFontSize(avatarSize);
    const border: string | false | undefined = hasBorder
      ? `1px solid ${standardColors.white}`
      : "none !important";
    const backgroundStyle =
      background ||
      `linear-gradient(45deg, ${blue.xapienDark} 0%, ${blue.blueDiamond} 100%)`;

    return {
      width: dimension,
      height: dimension,
      background: backgroundStyle,
      fontSize,
      border
    };
  }
);

const S = {
  Avatar
};

export default S;
