import { REPORT_TYPES } from "util/reportTypes";
import { reportStore } from "state/Store";
import { isLegalEntityReport } from "util/isLegalEntityReport";

const useReportType = () => {
  const report = reportStore.report as any;
  const subjectType = report?.subjectType;

  const isLegalEntity =
    subjectType === REPORT_TYPES.organisation &&
    isLegalEntityReport(report?.contexts[0].item);

  if (isLegalEntity) {
    return REPORT_TYPES.legalEntity;
  }

  return subjectType;
};

export default useReportType;
