import { styled } from "@linaria/react";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;
`;

const S = { Container };

export default S;
