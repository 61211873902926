import React, { useContext } from "react";

import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";

import S from "./styles";

interface Props {
  heading: string;
  message: string;
  diagnosticsOnly?: boolean;
}

const SystemIssueWarning = ({ heading, message, diagnosticsOnly }: Props) => {
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;

  if (diagnosticsOnly && !diagnosticsModeEnabled) {
    return "";
  }

  return (
    <S.WarningBanner>
      <S.Header>{heading}</S.Header>
      <S.Body>{message}</S.Body>
    </S.WarningBanner>
  );
};

export default SystemIssueWarning;
