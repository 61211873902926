import { styled } from "@linaria/react";

import { motion } from "framer-motion";

import BaseHeading from "components/atoms/Heading";
import { grey, standardColors, blue } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.div`
  background: ${standardColors.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const Heading = styled(BaseHeading)`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const DetailsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const DetailsSection = styled.div<{ height?: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-left: 1px solid ${grey.rule};
  padding-left: 16px;
  overflow-y: scroll;
  height: ${({ height }) => (height ? `${height}px` : "auto")};

  &:first-child {
    padding: 0;
    border-left: 0;
  }
`;

const DetailsItem = styled.div``;

const DetailsItemHeading = styled(BaseHeading)`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const DetailsItemValues = styled.div``;

const DetailsItemValue = styled(motion.div)`
  font-size: ${fontSize.sm};
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    width: 24px;
    height: 16px;
    margin: 0;
    border-radius: 2px;

    &:hover {
      width: 24px;
      height: 16px;
      margin: 0;
    }
  }
`;

const DetailsItemTagValue = styled.div`
  font-size: ${fontSize.xs};
  background: ${blue.panel};
  padding: 0 4px;
  border-radius: 4px;
  color: ${standardColors.white};
  display: inline-flex;
  letter-spacing: 1px;
`;

const DetailsItemFlagValue = styled(DetailsItemValue)`
  padding-top: 3px;
`;

const DetailsItemLargeValue = styled(DetailsItemValue)`
  margin-bottom: 8px;
`;

const ToggleItemsButton = styled.button`
  all: unset;
  font-size: ${fontSize.xs};
  color: ${grey.mid};
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const S = {
  Container,
  Heading,
  DetailsContainer,
  DetailsSection,
  DetailsItem,
  DetailsItemHeading,
  DetailsItemValues,
  DetailsItemValue,
  DetailsItemFlagValue,
  DetailsItemLargeValue,
  DetailsItemTagValue,
  ToggleItemsButton
};

export default S;
