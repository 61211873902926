import React, { FC, SVGProps, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { Switch } from "@mui/material";

import ModalContainer from "components/molecules/ModalContainer";
import Heading from "components/atoms/Heading";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ButtonNew from "components/atoms/ButtonNew";
import useUserSettings from "util/hooks/useUserSettings";
import useUserPreferences from "util/hooks/useUserPreferences";
import ModalSection from "components/atoms/ModalSection";
import ModalSectionHeader from "components/atoms/ModalSectionHeader";

import UserPersonalDetails, {
  DetailsFormError,
  DetailsFormFields
} from "components/organisms/UserPersonalDetails";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
}

const MySettingsModal = ({ isOpen, toggleOpen }: Props) => {
  const {
    state: {
      fetching: userSettingsLoading,
      userDetails: { firstName, lastName, email, jobTitle }
    },
    toggleAutoshareModal,
    updateUserDetails,
    resetError
  } = useUserSettings();
  const {
    state: { autoshare },
    toggleAutoshare
  } = useUserPreferences();

  const [formErrors, setFormErrors] = useState<DetailsFormError[]>([]);

  const [userFirstName, setUserFirstName] = useState(firstName);
  const [userLastName, setUserLastName] = useState(lastName);
  const [userJobTitle, setUserJobTitle] = useState(jobTitle);

  useEffect(() => {
    setUserFirstName(firstName);
    setUserLastName(lastName);
    setUserJobTitle(jobTitle);
  }, [firstName, lastName, jobTitle]);

  const validateFormInputs = () => {
    const errors: DetailsFormError[] = [];

    if (!userFirstName) {
      errors.push({ type: DetailsFormFields.FirstName });
    }

    if (!userLastName) {
      errors.push({ type: DetailsFormFields.LastName });
    }
    return errors;
  };

  const inputsUnchanged =
    firstName === userFirstName &&
    lastName === userLastName &&
    jobTitle === userJobTitle;

  const onSaveUserDetails = async () => {
    // Reset form errors on resave/retry
    setFormErrors([]);

    if (inputsUnchanged) {
      resetError();
      toggleOpen();
      return;
    }

    const errors = validateFormInputs();

    if (errors.length !== 0) {
      setFormErrors(errors);
    } else {
      await updateUserDetails(userFirstName, userLastName, userJobTitle);
      resetError();
      toggleOpen();
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={onSaveUserDetails}
      onExitClick={onSaveUserDetails}
      title="My settings"
    >
      <S.ModalContent>
        <ModalSection>
          <Heading level={5}>Personal details</Heading>

          <UserPersonalDetails
            {...{
              userFirstName,
              userLastName,
              userJobTitle,
              userEmail: email,
              formErrors
            }}
            onUserFirstNameChange={value => setUserFirstName(value)}
            onUserLastNameChange={value => setUserLastName(value)}
            onUserJobTitleChange={value => setUserJobTitle(value)}
          />
        </ModalSection>
        <ModalSection>
          <ModalSectionHeader>
            <Heading level={5}>Auto-share</Heading>
            <Switch checked={autoshare} onChange={toggleAutoshare} />
          </ModalSectionHeader>
          <S.Text>
            When switched on and setup, you can share new reports automatically.
          </S.Text>
          <S.CustomButton
            size={ButtonSize.Small}
            type={ButtonType.Filled}
            text="Set up Auto-share"
            disabled={!autoshare}
            onClick={toggleAutoshareModal}
          />
        </ModalSection>
      </S.ModalContent>
      <ButtonNew
        text="Done"
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={onSaveUserDetails}
        IconTrailing={
          userSettingsLoading
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : undefined
        }
        disabled={userSettingsLoading}
      />
    </ModalContainer>
  );
};

export default MySettingsModal;
