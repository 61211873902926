/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from "react";

import { usePrintableReportState } from "util/hooks/usePrintableState";
import { useImageKeyLookup } from "util/hooks/useImageKeyLookup";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { Features, useFeatureEnabled } from "services/features";
import SourceLink from "components/atoms/SourceLink";
import { ReactComponent as PersonPlaceholder } from "img/icons/person-placeholder.svg";
import { DiImage } from "components/molecules/DiImage";

import CroppedImage from "./CroppedImage";
import S from "./styles";

const IMAGE_WIDTH = 120;
const IMAGE_HEIGHT = 168;

export const ImageGallery = props => {
  const [imageSelection, setImageSelection] = usePrintableReportState(
    "image-selection",
    0
  );
  const printModeEnabled = usePrintModeEnabled();
  const sourceLinkPopoverContainerRef = useRef(null);
  const [anchorComponent, setAnchorComponent] = useState();

  useEffect(() => {
    setAnchorComponent(sourceLinkPopoverContainerRef.current);
  }, []);

  // if snapshot section in action, don't need little images below profile pic. Do still need fwd and bkwd arrows for modding for PDF export
  const snapshotSection = useFeatureEnabled(Features.SnapshotSection);

  const { getSrc } = useImageKeyLookup();
  const images = props.images
    ?.map(i => {
      if (typeof i === "string") {
        // backwards compatibility
        return {
          src: getSrc(i)
        };
      }
      return {
        ...i,
        image: {
          ...i.image,
          src: getSrc({
            src: i.image.src,
            imageKey: i.image.imageKey
          })
        }
      };
    })
    .filter(i => i.image && i.image.src);

  const [linksToInclude, setNewList] = useState(new Set(images));
  const removeLinkFromUsableList = src =>
    setNewList(
      new Set([...linksToInclude].filter(urlToCheck => urlToCheck.src !== src))
    );

  const imageHeight = props.imageHeight ?? IMAGE_HEIGHT;
  const imageWidth = props.imageWidth ?? IMAGE_WIDTH;

  return linksToInclude.size && !props.blockImages ? (
    <>
      <S.SourceLinkPopoverContainer ref={sourceLinkPopoverContainerRef} />
      <S.CustomCarousel
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        showArrows
        showThumbs={
          linksToInclude.size > 0 && !printModeEnabled && !snapshotSection
        }
        thumbWidth={52}
        showIndicators={false}
        infiniteLoop
        showStatus={false}
        selectedItem={imageSelection}
        dynamicHeight={false}
        onChange={setImageSelection}
      >
        {(printModeEnabled
          ? [...linksToInclude].slice(0, 1)
          : [...linksToInclude]
        ).map(image =>
          image.image.originUrl && image.image.originUrl.length > 0 ? (
            <SourceLink
              key={image.image.src}
              href={image.image.originUrl}
              popoverPortalAnchorElement={anchorComponent}
              renderPopoverWithPortal
            >
              {image.boundingBox ? (
                <CroppedImage
                  imageData={image}
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                />
              ) : (
                <DiImage
                  image={{ src: image.image.src }}
                  alt="Related photograph"
                  onError={() => removeLinkFromUsableList(image.image.src)}
                />
              )}
            </SourceLink>
          ) : image.boundingBox ? (
            <CroppedImage
              imageData={image}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
            />
          ) : (
            <DiImage
              image={{ src: image.image.src }}
              alt="Related photograph"
              onError={() => removeLinkFromUsableList(image.image.src)}
            />
          )
        )}
      </S.CustomCarousel>
    </>
  ) : (
    <PersonPlaceholder />
  );
};

export default ImageGallery;
