import React from "react";

import PageTitle from "components/atoms/PageTitle";
import Heading from "components/atoms/Heading";
import ReportPersonaList from "./ReportPersonaList";
import S from "./styles";

const EnquirySummary = () => {
  return (
    <>
      <PageTitle title="Xapien | Enquiry Summary" />
      <S.HeadingContainer>
        <Heading level={4}>Enquiry Summary</Heading>
      </S.HeadingContainer>
      <ReportPersonaList />
    </>
  );
};

export default EnquirySummary;
