import { useCallback, useContext, useEffect, useMemo } from "react";
import { InsightsReportContext } from "./context";
import { InsightsReportActions } from "./types";

export const useInsightsReport = () => {
  const { state, dispatch } = useContext(InsightsReportContext);
  useEffect(() => {
    dispatch({ type: InsightsReportActions.StartLoad });
  }, [dispatch]);
  const generate = useCallback(
    () => dispatch({ type: InsightsReportActions.StartGenerate }),
    [dispatch]
  );
  const value = useMemo(() => ({ generate, ...state }), [state, generate]);
  return value;
};
