import React, { useEffect, useMemo, useState } from "react";

import { motion } from "framer-motion";

import ComboBox, { MenuItem } from "components/molecules/ComboBox";

import Api from "api/organisations";

import S from "./styles";

interface Props {
  setProjectReference: (value: string) => void;
  disabled?: boolean;
}

const ProjectIdentifierStage = React.forwardRef<HTMLDivElement, Props>(
  ({ setProjectReference, disabled }, ref) => {
    const [projectReferences, setProjectReferences] = useState<MenuItem[]>([]);
    const OrgApi = useMemo(() => new Api(), []);

    useEffect(() => {
      OrgApi.getProjectReferences().then(response => {
        if (response.status && response.response) {
          setProjectReferences(
            response.response.map(project => ({
              title: project.reference,
              id: project.projectId
            }))
          );
        }
      });
    }, [OrgApi]);

    return (
      <S.StageContainer ref={ref}>
        <ComboBox
          disabled={disabled}
          defaultMenuItems={projectReferences}
          onInputChanged={setProjectReference}
          inputPlaceholderText="Your project reference number (optional)"
        />
      </S.StageContainer>
    );
  }
);

export default motion(ProjectIdentifierStage);
