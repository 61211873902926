import React, { Fragment } from "react";

import Masonry from "react-masonry-css";

import { usePrintableReportState } from "util/hooks/usePrintableState";
import { entityMasonryBreakpoints } from "themes/projectdx/ThemedScreening/util";
import RelationshipsHeader from "themes/projectdx/ThemedScreening/RelationshipsHeader";
import RelatedEntityCard from "themes/projectdx/ThemedScreening/RelatedEntityCard";

import S, { classNameOverrides } from "./styles";

const EntityMasonry = ({
  entities,
  entitiesExpanded = false,
  entitiesBackgroundColorOverride,
  entitiesImageSize = "large",
  hideNewReportButton = false,
  disableNewReportButton,
  renderFlagHierarchyPills = () => {},
  filterBucketType,
  reportSubjectType,
  reportSubjectName,
  sectionStyles,
  onViewAssociateProfile,
  associatedEntityProfiles
}) => {
  const [isEntitiesExpanded, setIsEntitiesExpanded] = usePrintableReportState(
    "entity-masonry-fully-expanded",
    entitiesExpanded
  );

  if (!entities?.length) {
    return null;
  }

  return (
    <S.Relationships className={sectionStyles}>
      <S.Heading
        onClick={e => {
          e.stopPropagation();
          setIsEntitiesExpanded(prevState => !prevState);
        }}
      >
        <Fragment>
          <RelationshipsHeader entities={entities} />
          <S.CollapseChevronButton>
            <S.CollapseChevron isCollapsed={!isEntitiesExpanded} />
          </S.CollapseChevronButton>
        </Fragment>
      </S.Heading>

      {isEntitiesExpanded && (
        <Masonry
          breakpointCols={
            entities?.length > 1 ? entityMasonryBreakpoints : { default: 1 }
          }
          className={classNameOverrides.masonryGrid}
          columnClassName={classNameOverrides.masonryColumn}
        >
          {entities.map(entity => {
            return (
              <RelatedEntityCard
                key={entity.entityName}
                entity={entity}
                entitiesBackgroundColorOverride={
                  entitiesBackgroundColorOverride
                }
                entitiesImageSize={entitiesImageSize}
                hideNewReportButton={hideNewReportButton}
                disableNewReportButton={disableNewReportButton}
                renderFlagHierarchyPills={renderFlagHierarchyPills}
                filterBucketType={filterBucketType}
                reportSubjectType={reportSubjectType}
                reportSubjectName={reportSubjectName}
                onViewAssociateProfile={onViewAssociateProfile}
                associatedEntityProfiles={associatedEntityProfiles}
              />
            );
          })}
        </Masonry>
      )}
    </S.Relationships>
  );
};

export default EntityMasonry;
