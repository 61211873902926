import http from "../../services/http";
import endpoints from "../../services/endpoints";

export default class Diagnostics {
  constructor(inProgress) {
    this.inProgress = inProgress;
  }

  async overview(enquiryId) {
    this.inProgress(true);
    try {
      return await http.post(`${endpoints.diagnosticsOverview}`, {
        EnquiryId: enquiryId
      });
    } finally {
      this.inProgress(false);
    }
  }

  content(enquiryId) {
    return new Promise((resolve, reject) => {
      this.inProgress(true);
      http
        .post(`${endpoints.diagnosticsContent}`, { EnquiryId: enquiryId })
        .then(
          s => {
            this.inProgress(false);
            resolve(s);
          },
          () => {
            this.inProgress(false);
            reject();
          }
        );
    });
  }

  graph() {
    return new Promise((resolve, reject) => {
      reject("not implemented");
    });
  }
}
