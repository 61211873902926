import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const getDurationSinceStartDate = ({
  startDate,
  endDate,
  excludeSuffix = true,
  includeParentheses = true
}) => {
  // Months come from the data 1-indexed, so we have
  // to decrement them before using them.
  const startMonth = startDate?.month ? startDate.month - 1 : 0;
  const endMonth = endDate?.month ? endDate.month - 1 : 0;

  if (!startDate?.year) {
    return null;
  }
  const startDateAsDate = dayjs(
    new Date(startDate.year, startMonth, startDate.day ?? 1)
  );

  let timeSinceStartDate;

  // If we have an end date then we should use that when calculating the
  // duration of the role.
  if (endDate) {
    const endDateAsDate = dayjs(
      new Date(
        endDate.year ?? new Date().getFullYear(),
        endMonth,
        endDate.day ?? 1
      )
    );

    if (
      startDateAsDate.format("DD MMM YYYY") ===
      endDateAsDate.format("DD MMM YYYY")
    ) {
      if (
        startDateAsDate.format("DD MMM YYYY") === dayjs().format("DD MMM YYYY")
      ) {
        timeSinceStartDate = "today";
      } else {
        timeSinceStartDate = "1 day";
      }
    } else {
      timeSinceStartDate = startDateAsDate.from(endDateAsDate, excludeSuffix);
    }
  } else {
    if (
      startDateAsDate.format("DD MMM YYYY") === dayjs().format("DD MMM YYYY")
    ) {
      timeSinceStartDate = "today";
    } else {
      timeSinceStartDate = dayjs(startDateAsDate).fromNow(excludeSuffix);
    }
  }

  // Reformat dayjs's label (special case)
  if (timeSinceStartDate === "a year") {
    timeSinceStartDate = "1 year";
  }

  if (includeParentheses) {
    return `(${timeSinceStartDate})`;
  }
  return `${timeSinceStartDate}`;
};
