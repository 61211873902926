import { UpcResponse } from "api/upc-reports";

export type UpcDetailsState = UpcResponse;

export enum UpcDetailsActions {
  updateState = "updateState"
}

export interface UpdateUpcDetailsStateAction {
  type: UpcDetailsActions.updateState;
  state: UpcDetailsState;
}

export type UpcDetailsAction = UpdateUpcDetailsStateAction;
