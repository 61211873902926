import { styled } from "@linaria/react";
import { Spinner as ReactSpinner } from "reactstrap";
import { grey } from "styles/colors";

const Spinner = styled(ReactSpinner)`
  position: absolute;
  color: ${grey.ghost};
  opacity: 0.5;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  display: ${({ className }) => (className === "isVisible" ? "block" : "none")};
  & > span {
    display: none;
  }
`;

const Canvas = styled.canvas`
  display: ${({ className }) => (className === "isVisible" ? "block" : "none")};
`;

const Container = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;
const S = {
  Spinner,
  Canvas,
  Container
};
export default S;
