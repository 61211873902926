import React, { CSSProperties, ReactNode } from "react";
import Skeleton from "components/atoms/Skeleton";

import S, { classes } from "./styles";

interface Props {
  level: number;
  size?: number;
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}

const Heading = ({
  level,
  size,
  loading,
  children,
  className,
  style
}: Props) => {
  const H = S[`H${level}` as keyof typeof S];
  let levelSize = size;

  // Set size to be the same as level if not set, keeping in mind "0" is a valid size
  if (!size && size !== 0) levelSize = level;
  const sizeClass = classes[`size${levelSize}` as keyof typeof classes];

  return loading ? (
    <Skeleton width="100%" />
  ) : (
    <H className={`${sizeClass} ${className}`} style={style}>
      {children}
    </H>
  );
};

export default Heading;
