import { styled } from "@linaria/react";
import { standardColors } from "styles/colors";
import { ALIGNMENT_OPTIONS } from "components/organisms/InsightReport/TimelineSection/constants";

export interface EventNodeProps {
  size: number;
  color: string;
}

export interface EventNodeOuterContainerProps {
  size: number;
}

export interface InnerEventNodeCircleProps {
  size: number;
  color: string;
}

export interface EventLineProps {
  nodeSize: number;
  color: string;
}

export interface EventNodeContainerProps {
  alignment: string;
}

const EventNodeContainer = styled.div<EventNodeContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: ${({ alignment }) =>
    alignment === ALIGNMENT_OPTIONS.left ? "row-reverse" : "row"};
`;

const EventNode = styled.div<EventNodeProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${standardColors.white};
  border-radius: 50%;
  border: 1px solid ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const InnerEventNodeCircle = styled.div<InnerEventNodeCircleProps>`
  height: ${({ size }) => Math.floor(size * 0.65)}px;
  width: ${({ size }) => Math.floor(size * 0.65)}px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

const EventLine = styled.div<EventLineProps>`
  width: ${({ nodeSize }) => 37 - nodeSize / 2}px;
  border-top: 2px dotted ${({ color }) => color};
`;

const EventNodeOuterContainer = styled.div<EventNodeOuterContainerProps>`
  position: relative;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const S = {
  EventNodeContainer,
  EventNode,
  InnerEventNodeCircle,
  EventLine,
  EventNodeOuterContainer
};

export default S;
