import React, { Fragment } from "react";

import TruncateLength from "util/TruncateLength";
import Assertable from "util/Assertable";

import { formatForDisplay } from "./util";
import S from "./styles";

const Datalist = ({
  fieldList,
  dataFieldClassName,
  termClassName,
  detailClassName,
  shouldDataValuesWrap = false
}) => {
  const displayField = fieldData => {
    if (fieldData.value === null || fieldData.value === "") return false;
    const {
      value,
      title,
      isUncertain,
      id,
      assertionTemplate,
      doesStringValueContainMultipleAssertableValues
    } = fieldData;
    const displayValue = assertionTemplate
      ? formatForDisplay(
          value,
          assertionTemplate,
          doesStringValueContainMultipleAssertableValues
        )
      : formatForDisplay(value, () => {});

    if (displayValue !== undefined && displayValue !== "") {
      const displayContent = doesStringValueContainMultipleAssertableValues ? (
        <Fragment>
          {isUncertain ? "~" : ""}
          {displayValue}{" "}
        </Fragment>
      ) : (
        <Assertable template={assertionTemplate}>
          {isUncertain ? "~" : ""}
          {displayValue}{" "}
        </Assertable>
      );

      return (
        <div className={dataFieldClassName} key={id ?? title}>
          <S.DescriptionTerm className={termClassName}>
            {title}
          </S.DescriptionTerm>
          <S.DescriptionDetail className={detailClassName}>
            {shouldDataValuesWrap ? (
              displayContent
            ) : (
              <TruncateLength>{displayContent}</TruncateLength>
            )}
          </S.DescriptionDetail>
        </div>
      );
    }

    return false;
  };
  return (
    <S.DataList>
      {fieldList.map(item => {
        return displayField(item);
      })}
    </S.DataList>
  );
};
export default Datalist;
