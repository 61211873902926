import { styled } from "@linaria/react";

import { ReactComponent as Chevron } from "img/icons/arrow-down.svg";
import { ReactComponent as BuildingSvg } from "img/building.svg";

import { green, standardColors, grey } from "styles/colors";

const Tabs = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  width: 920px; /* Dependent on widths of the tabs */
  overflow-x: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  scroll-behavior: smooth;
`;

const TabBar = styled.div`
  background-color: ${props => props.backgroundColor ?? green.xapienGreen};
  width: 1024px;
  height: 52px;
  display: flex;
  align-items: flex-end;
  border-bottom: none;
`;

const LeftChevron = styled(Chevron)`
  margin: 0 12px 14px 12px;
  transform: rotate(90deg) scale(1.2);
  cursor: ${props => (props.isActive ? "pointer" : "default")};
  path {
    fill: ${props => (props.isActive ? standardColors.white : grey.rule)};
  }
`;

const RightChevron = styled(Chevron)`
  margin: 0 12px 14px 12px;
  transform: rotate(-90deg) scale(1.2);
  cursor: ${props => (props.isActive ? "pointer" : "default")};
  path {
    fill: ${props => (props.isActive ? standardColors.white : grey.rule)};
  }
`;

const BuildingIcon = styled(BuildingSvg)`
  transform: scale(0.7);
  path {
    fill: ${props =>
      props.isSelected
        ? props.fill ?? green.xapienGreen
        : standardColors.white};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 21px;
  margin-bottom: 7px;
  align-self: flex-end;
  background-color: ${props =>
    props.shouldDisplay ? grey.panel : props.backgroundColor};
`;

const TabContainer = styled.div`
  display: flex;
`;

const S = {
  Tabs,
  TabBar,
  LeftChevron,
  RightChevron,
  BuildingIcon,
  Divider,
  TabContainer
};

export default S;
