import React, { CSSProperties, FC, ReactNode, SVGProps, useState } from "react";

import { ChevronDown } from "react-feather";

import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Avatar from "components/atoms/Avatar";

import { AvatarSize } from "components/atoms/Avatar/types";
import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";

import S from "./styles";

export interface DropdownOption {
  id: string;
  label: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  className?: string;
  showSeparator?: boolean;
  disabled?: boolean;
}

interface Props {
  options: DropdownOption[];
  icon: ReactNode;
  title: string;
  subtitle?: string;
  selectedOption: DropdownOption;
  onSelectOption: (option: DropdownOption) => void;
  optionIcon?: FC<SVGProps<SVGSVGElement>>;
  avatarUrl?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

const ListItemWithOptions = ({
  title,
  subtitle,
  options,
  icon,
  selectedOption,
  onSelectOption,
  optionIcon,
  avatarUrl,
  disabled,
  className,
  style
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const renderButton = () => {
    const dropdownMenu = (
      <Menu>
        {options.map(option => {
          return (
            <MenuItem
              key={option.id}
              text={option.label}
              disabled={option.id === selectedOption.id || option.disabled}
              onMenuItemClick={() => {
                onSelectOption(option);
                setIsDropdownOpen(false);
              }}
              IconLeading={option.icon}
              showSeparator={option.showSeparator}
              className={option.className}
            />
          );
        })}
      </Menu>
    );

    return (
      <Popover
        // @ts-ignore
        isOpenOverride={isDropdownOpen}
        // @ts-ignore
        onRequestClose={() => setIsDropdownOpen(false)}
        trigger="click"
        hideArrow
        alignment="bottom-end"
        content={dropdownMenu}
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <ButtonNew
          type={ButtonType.Text}
          size={ButtonSize.Small}
          text={selectedOption.label}
          IconTrailing={optionIcon || ChevronDown}
          onClick={() => setIsDropdownOpen(prev => !prev)}
          disabled={disabled}
        />
      </Popover>
    );
  };

  return (
    <S.ListItemWithOptions className={className} style={style}>
      <S.ListItemWithOptionsContent>
        {avatarUrl ? (
          <Avatar avatarSize={AvatarSize.Large} imgSrc={avatarUrl} />
        ) : (
          <Avatar avatarSize={AvatarSize.Large}>{icon}</Avatar>
        )}
        <S.TitleAndSubtitle>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.TitleAndSubtitle>
      </S.ListItemWithOptionsContent>
      {renderButton()}
    </S.ListItemWithOptions>
  );
};

export default ListItemWithOptions;
