import { createContext } from "react";

import type { Group } from "api/user/types";

import {
  UserSettingsAction,
  UserSettingsActions,
  UserSettingsState
} from "./types";

export const initialState: UserSettingsState = {
  userDetails: {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    groups: [],
    organisationId: "",
    organisationRole: ""
  },
  isAutoshareModalOpen: false,
  isSettingsModalOpen: false,
  isInviteUserModalOpen: false,
  fetching: false,
  error: false
};

export const UserSettingsContext = createContext({
  state: initialState,
  updateUserDetails: async (
    _firstName: string,
    _lastName: string,
    _jobTitle: string
  ) => {
    console.error("updateUserDetails does not have any implementation");
  },
  resetError: () => {
    console.error("resetError does not have any implementation");
  },
  toggleSettingsModal: () => {
    console.error("toggleSettingsModal does not have any implementation");
  },
  toggleAutoshareModal: () => {
    console.error("toggleAutoshareModal does not have any implementation");
  },
  toggleInviteUserModal: () => {
    console.error("toggleInviteUserModal does not have any implementation");
  },
  addUserGroup: (_group: Group) => {
    console.error("addUserGroup does not have any implementation");
  },
  updateUserGroup: (_group: Partial<Group>) => {
    console.error("updateUserGroup does not have any implementation");
  },
  refresh: () => {
    console.error("Refresh user settings does not have any implementation");
  }
});

export const userSettingsReducer = (
  state: UserSettingsState,
  action: UserSettingsAction
): UserSettingsState => {
  switch (action.type) {
    case UserSettingsActions.UpdateUserDetails:
      return { ...state, userDetails: action.userDetails };
    case UserSettingsActions.ToggleSettingsModal:
      return {
        ...state,
        isAutoshareModalOpen: false,
        isSettingsModalOpen: !state.isSettingsModalOpen
      };
    case UserSettingsActions.ToggleAutoshareModal:
      return {
        ...state,
        isAutoshareModalOpen: !state.isAutoshareModalOpen,
        isSettingsModalOpen: false
      };
    case UserSettingsActions.ToggleInviteUserModal:
      return { ...state, isInviteUserModalOpen: !state.isInviteUserModalOpen };
    case UserSettingsActions.ResetUserSettings:
      return initialState;
    default:
      return state;
  }
};
