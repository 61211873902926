import React, { useState } from "react";

import Heading from "components/atoms/Heading";
import Input from "components/molecules/SimpleSearchInput";
import S from "components/organisms/CopyReportModal/styles";
import { SearchResult } from "api/search";

import useUserSearch from "./useUserSearch";

interface Props {
  selectedSuggestion?: SearchResult;
  setSelectedSuggestion: React.Dispatch<
    React.SetStateAction<SearchResult | undefined>
  >;
  chosenOrgId?: string;
}

const SelectOwner = ({
  selectedSuggestion,
  setSelectedSuggestion,
  chosenOrgId
}: Props) => {
  const [query, setQuery] = useState("");
  const [previousChosenOrgId, setPreviousChosenOrgId] = useState(chosenOrgId);

  const { userSuggestions, onSearchUpdate, isFetchingSuggestions, clearUsers } =
    useUserSearch();

  // If the chosen organisation is cleared, then clear the stored users list
  if (chosenOrgId !== previousChosenOrgId) {
    setPreviousChosenOrgId(chosenOrgId);
    if (!chosenOrgId) {
      clearUsers();
    }
  }

  const onQueryInputChange = (newQuery: string) => {
    setQuery(newQuery);
    if (chosenOrgId) {
      onSearchUpdate(newQuery, chosenOrgId);
    }
  };

  const onSelectedSuggestionsChange = (
    newSelectedSuggestions: SearchResult[]
  ) => {
    // If the selected suggestion is the suggestion that has already been selected, remove it
    if (
      !newSelectedSuggestions.length ||
      (selectedSuggestion &&
        selectedSuggestion.id ===
          newSelectedSuggestions[newSelectedSuggestions.length - 1].id)
    ) {
      setSelectedSuggestion(undefined);
    } else {
      const newSuggestion = newSelectedSuggestions.pop();
      setSelectedSuggestion(newSuggestion);
    }
  };

  return (
    <S.InputGroup>
      <Heading level={6}>Select the new owner</Heading>
      <Input
        query={query}
        onQueryInputChange={onQueryInputChange}
        selectedSuggestion={selectedSuggestion ? [selectedSuggestion] : []}
        onSelectedSuggestionChange={onSelectedSuggestionsChange}
        suggestions={userSuggestions}
        loadingText={isFetchingSuggestions ? "Loading..." : "No results found"}
        placeholder="Enter name"
        disabled={!chosenOrgId}
      />
    </S.InputGroup>
  );
};

export default SelectOwner;
