import React, { useState } from "react";

import { ButtonKind } from "components/atoms/Button/types";

import S from "./styles";

type Props = {
  resultsCount: number;
  expandOveride: boolean;
  resultsString?: string;
  onShowResultsClick: (isShowingResults: boolean) => void;
  isShowingResults: boolean;
  customButtonLabel?: string;
  className?: string;
  style?: React.CSSProperties;
};

const ShowResultsButton = ({
  resultsCount,
  resultsString = "results",
  onShowResultsClick,
  expandOveride,
  isShowingResults,
  customButtonLabel,
  className,
  style
}: Props) => {
  const [isResultsExpanded, setIsResultsExpanded] = useState(false);

  const buttonLabel = `${
    expandOveride
      ? isShowingResults
        ? "Collapse"
        : "Expand"
      : isShowingResults
      ? "Hide"
      : "Show"
  } ${resultsCount} ${resultsString}`;

  return (
    resultsCount > 0 && (
      <S.ShowDetailButtonContainer className={className} style={style}>
        <S.DetailButtonDividerLine />
        <S.Button
          kind={ButtonKind.primary}
          onClick={() => {
            onShowResultsClick(!isShowingResults);
            setIsResultsExpanded(!isResultsExpanded);
          }}
        >
          <S.ButtonAndArrowContainer>
            <S.ChevronDown
              className={isResultsExpanded ? "isResultsExpanded" : ""}
            />
            {customButtonLabel ?? buttonLabel}
            <S.ChevronDown
              className={isResultsExpanded ? "isResultsExpanded" : ""}
            />
          </S.ButtonAndArrowContainer>
        </S.Button>
        <S.DetailButtonDividerLine />
      </S.ShowDetailButtonContainer>
    )
  );
};

export default ShowResultsButton;
