import { styled } from "@linaria/react";

import { orange } from "styles/colors";
import { fontSize } from "styles/fonts";

const ErrorBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${orange.indirectRiskFill};
  min-height: 1.5rem;
  font-size: ${fontSize.sm};
  border-radius: 0.375rem;
  padding: 0 0.5rem;
  gap: 0.625rem;
  line-height: 1.25rem;
`;

const CloseButton = styled.button`
  all: unset;
  display: flex;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const S = {
  ErrorBanner,
  CloseButton
};

export default S;
