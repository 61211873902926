import React, { ReactNode, useCallback, useMemo, useState } from "react";

import { GroupUser, GroupUserRole } from "api/groups/types";
import Api from "api/groups";
import { FetchResult } from "api/types";

import { ManageGroupContext } from "./context";

const ManageGroupContextProvider = ({
  children,
  groupId
}: {
  children: ReactNode;
  groupId: string;
}) => {
  const [isManageGroupModalOpen, setIsManageGroupModalOpen] = useState(false);
  const [isRemoveFromGroupModalOpen, setIsRemoveFromGroupModalOpen] =
    useState(false);
  const [groupMembers, setGroupMembers] = useState<GroupUser[]>([]);

  const GroupsApi = useMemo(() => new Api(), []);

  const getGroupMembers = useCallback(async (): Promise<boolean> => {
    const members = await GroupsApi.getGroupMembers(groupId);
    if (!members) {
      return false;
    }
    setGroupMembers(members);
    return true;
  }, [GroupsApi, groupId]);

  const addMembersToGroup = useCallback(
    async (userIds: string[]): Promise<FetchResult> => {
      const result = await GroupsApi.addMembersToGroup(userIds, groupId);

      if (result.status) {
        await getGroupMembers();
      }

      return result;
    },
    [GroupsApi, getGroupMembers, groupId]
  );

  const removeMembersFromGroup = useCallback(
    async (userIds: string[]): Promise<FetchResult> => {
      const result = await GroupsApi.removeMembersFromGroup(userIds, groupId);

      if (result.status) {
        await getGroupMembers();
      }

      return result;
    },
    [GroupsApi, getGroupMembers, groupId]
  );

  const addAdminsToGroup = useCallback(
    async (userIds: string[]): Promise<FetchResult> => {
      const result = await GroupsApi.addAdminsToGroup(userIds, groupId);

      if (result.status) {
        await getGroupMembers();
      }

      return result;
    },
    [GroupsApi, getGroupMembers, groupId]
  );

  const removeAdminsFromGroup = useCallback(
    async (userIds: string[]): Promise<FetchResult> => {
      const result = await GroupsApi.removeAdminsFromGroup(userIds, groupId);

      if (result.status) {
        await getGroupMembers();
      }

      return result;
    },
    [GroupsApi, getGroupMembers, groupId]
  );

  const updateGroupDetails = useCallback(
    async (name: string, description?: string) => {
      const result = await GroupsApi.updateGroup(groupId, name, description);
      return result;
    },
    [GroupsApi, groupId]
  );

  const changeMemberGroupRole = useCallback(
    async (userId: string, role: GroupUserRole): Promise<FetchResult> => {
      const result = await GroupsApi.changeMemberGroupRole(
        userId,
        groupId,
        role
      );

      const userIndex = groupMembers.findIndex(member => member.id === userId);
      let updatedGroupMembers = [...groupMembers];

      if (result.status) {
        // Update state for immediate feedback to reflect changed permission
        updatedGroupMembers = [
          ...updatedGroupMembers.slice(0, userIndex),
          { ...updatedGroupMembers[userIndex], groupRole: role },
          ...updatedGroupMembers.slice(userIndex + 1)
        ];
        setGroupMembers(updatedGroupMembers);
      }

      return result;
    },
    [GroupsApi, groupId, groupMembers]
  );

  const toggleManageGroupModal = useCallback(() => {
    setIsManageGroupModalOpen(prev => !prev);
  }, []);

  const toggleRemoveFromGroupModal = useCallback(() => {
    setIsRemoveFromGroupModalOpen(prev => !prev);
  }, []);

  const providerValue = useMemo(
    () => ({
      isManageGroupModalOpen,
      isRemoveFromGroupModalOpen,
      groupMembers,
      changeMemberGroupRole,
      updateGroupDetails,
      toggleManageGroupModal,
      toggleRemoveFromGroupModal,
      addMembersToGroup,
      removeMembersFromGroup,
      addAdminsToGroup,
      removeAdminsFromGroup,
      getGroupMembers
    }),
    [
      isManageGroupModalOpen,
      isRemoveFromGroupModalOpen,
      groupMembers,
      changeMemberGroupRole,
      updateGroupDetails,
      toggleManageGroupModal,
      toggleRemoveFromGroupModal,
      addMembersToGroup,
      removeMembersFromGroup,
      addAdminsToGroup,
      removeAdminsFromGroup,
      getGroupMembers
    ]
  );
  return (
    <ManageGroupContext.Provider value={providerValue}>
      {children}
    </ManageGroupContext.Provider>
  );
};

export default ManageGroupContextProvider;
