import { useContext } from "react";
import { RegistrationContext } from "./context";
import { RegistrationContextProvider } from "./provider";
import { RegistrationActions } from "./types";

const useRegistration = () => {
  const context = useContext(RegistrationContext);

  return context;
};

export {
  RegistrationContext,
  RegistrationContextProvider,
  useRegistration,
  RegistrationActions
};
