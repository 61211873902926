import { styled } from "@linaria/react";

const TooltipContent = styled.div<{ width?: number | string }>`
  text-align: left;
  padding: 2px;
  width: ${props => props.width ?? 275}px;
  max-width: 275px;
  max-height: 325px;
  overflow-y: auto;
  white-space: pre-wrap;
`;

const S = {
  TooltipContent
};

export default S;
