import React, { FC } from "react";
import { Position } from "reactflow";
import { UBOHandle } from "components/atoms/UBO";
import type { UboReportNode } from "api/ubo-reports";
import type { UboSharePercentage } from "api/ubo-reports/types";
import S from "./styles";

interface Props {
  data: UboReportNode["data"];
}

const getSharePercentageText = (percentage: UboSharePercentage | undefined) => {
  const { lower, upper } = percentage || {};

  // We can have a lower with no upper, but not an upper with no lower.
  if (!lower) return "";
  if (!upper) return `${lower}%`;
  return `${lower}-${upper}%`;
};

const EdgeLabelNode: FC<Props> = ({ data: { label, shares } }) => {
  const sharePercentageText = getSharePercentageText(shares?.percentage);
  const asOfDateText = shares?.asOfDate ? `As of ${shares.asOfDate}` : "";

  return (
    <>
      <UBOHandle isHidden type="source" position={Position.Top} />
      <S.Wrapper>
        <S.Label>{`${sharePercentageText} ${label}`}</S.Label>
        <S.SecondaryLabel>{asOfDateText}</S.SecondaryLabel>
      </S.Wrapper>
      <UBOHandle isHidden type="target" position={Position.Bottom} />
    </>
  );
};

export default EdgeLabelNode;
