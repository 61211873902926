import { FePersonReport, FeOrgReport } from "api/old-insights";

type FeInsightReport = FePersonReport | FeOrgReport;

export enum InsightsReportStates {
  NotLoaded = "notLoaded",
  NotGenerated = "notGenerated",
  Generating = "generating",
  Loading = "loading",
  Loaded = "loaded",
  Error = "error",
  InvalidNeedsRegenerating = "invalidNeedsRegenerating"
}

type NotLoadedState = { type: InsightsReportStates.NotLoaded };
type NotGeneratedState = { type: InsightsReportStates.NotGenerated };
type Generating = { type: InsightsReportStates.Generating };
type LoadingState = { type: InsightsReportStates.Loading };
type LoadedState = {
  type: InsightsReportStates.Loaded;
  report: FeInsightReport;
};
type InvalidNeedsRegenerating = {
  type: InsightsReportStates.InvalidNeedsRegenerating;
};
type ErrorState = { type: InsightsReportStates.Error; error: string };
export type InsightsReportState =
  | NotLoadedState
  | NotGeneratedState
  | Generating
  | LoadingState
  | LoadedState
  | InvalidNeedsRegenerating
  | ErrorState;

export enum InsightsReportActions {
  StartLoad = "startLoad",
  CompleteLoad = "completeLoad",
  NotFound = "notFound",
  StartGenerate = "startGenerate",
  Error = "error",
  ValidationFailed = "validationFailed"
}

type StartLoad = {
  type: InsightsReportActions.StartLoad;
};
type Loaded = {
  type: InsightsReportActions.CompleteLoad;
  report: FeInsightReport;
};
type NotFound = {
  type: InsightsReportActions.NotFound;
};
type Error = {
  type: InsightsReportActions.Error;
  error: string;
};
type StartGenerate = {
  type: InsightsReportActions.StartGenerate;
};
type ValidationFailed = {
  type: InsightsReportActions.ValidationFailed;
};
export type InsightsReportAction =
  | StartLoad
  | Loaded
  | Error
  | NotFound
  | ValidationFailed
  | StartGenerate;

export type InsightsReportContextValue = {
  state: InsightsReportState;
  dispatch: React.Dispatch<InsightsReportAction>;
};
