import { styled } from "@linaria/react";

import { grey, blue, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

import CommonButton from "components/atoms/Button";
import { hexToRgb } from "styles/hexToRgb";

const BarsContainer = styled.div`
  font-size: ${fontSize.sm};
  align-items: center;
  max-height: 140px;
  overflow-y: auto;
`;

const Bar = styled.div`
  width: 100%;
  background-color: ${props => {
    if (props.isChecked) {
      return blue.mid;
    }

    const { r, g, b } = hexToRgb(blue.mid);

    return `rgba(${r}, ${g}, ${b}, 0.6)`;
  }};
  margin: 4px 0px;
  font-size: ${fontSize["2xs"]};
  padding: 0 4px;
  color: ${standardColors.white};
  margin-right: 1px;
  border-radius: 4px;
`;

const SingleBarContainer = styled.div`
  display: flex;

  font-size: ${fontSize.sm};
  align-items: center;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  transition: opacity 0.2s;

  &:hover {
    background: rgba(0, 168, 229, 0.15);
    cursor: hand;

    ${Bar} {
      background-color: ${blue.mid};
    }
  }

  input[type="checkbox"]:checked + label {
    color: ${blue.mid};
  }

  input[type="checkbox"] {
    display: none;
  }
`;

const BarLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4px;
  width: calc(50% - 15px);
  max-width: 215px;
  cursor: pointer;

  & > div {
    display: flex;
    width: 100%;
  }
`;

const BarLabel = styled.label`
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
  color: ${grey.mid};
  margin-bottom: 0;
  max-width: 90%;
  padding-left: 1px;

  &:before {
    background-color: ${blue.icon};
  }
`;

const LabelContainer = styled.span`
  div {
    padding: 0;
  }
`;

const BarContainer = styled.div`
  width: 50%;
  cursor: pointer;
`;

const BarInnerWrapper = styled.div`
  display: flex;
  border-radius: 2px;
  width: ${props => props.width};
`;

const ShowAllButton = styled(CommonButton)`
  color: ${grey.mid} !important;
  font-size: ${fontSize.xs} !important;
  min-width: 0;
  padding-top: 8px !important;

  &:hover,
  &:focus {
    border-color: ${grey.mid} !important;
  }
`;

const S = {
  BarsContainer,
  SingleBarContainer,
  LabelContainer,
  BarLabelWrapper,
  BarLabel,
  BarContainer,
  BarInnerWrapper,
  Bar,
  ShowAllButton
};

export default S;
