import { createContext } from "react";

export enum EntityType {
  Person = "person",
  Organisation = "organisation"
}

export const ReportSubjectEntityTypeContext = createContext<
  undefined | EntityType
>(undefined);
