import React from "react";

import Skeleton from "components/atoms/Skeleton";

import { Props } from "./types";

import S from "./styles";

const Avatar = ({
  hasBorder,
  background,
  avatarSize,
  imgSrc,
  children,
  loading,
  ...muiProps
}: Props) => {
  const sharedProps = {
    avatarSize,
    hasBorder,
    background,
    role: "presentation",
    alt: ""
  };

  if (loading) {
    return (
      <Skeleton variant="circular">
        <S.Avatar {...muiProps} {...sharedProps} src={imgSrc} />
      </Skeleton>
    );
  }

  if (!imgSrc && children) {
    return (
      <S.Avatar {...muiProps} {...sharedProps}>
        {children}
      </S.Avatar>
    );
  }

  return <S.Avatar {...muiProps} {...sharedProps} src={imgSrc} />;
};

export default Avatar;
