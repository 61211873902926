import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { grey } from "styles/colors";

const ViewContainer = styled.main`
  display: flex;
  justify-content: center;
`;

const ViewInnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 1424px;
`;

const Breadcrumb = styled.button`
  all: unset;
  position: absolute;
  top: 45px;
  left: 16px;
  bottom: 0;
  height: 3rem;
  font-size: ${fontSize.sm};
  color: ${grey.rule};
  cursor: pointer;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  z-index: 10;

  &:not(:focus-visible) {
    outline: none;
  }

  & > div {
    padding-bottom: 2px;
  }
`;

const S = { ViewContainer, ViewInnerContainer, Breadcrumb };

export default S;
