import React, { memo } from "react";
import { SortFilterPill } from "./SortFilterPill";
import { DropdownItem } from "reactstrap";
import { SortFilterHierarchicalCategories } from "./SortFilterHierarchicalCategories";

export const SourceTypeSortFilterPill = memo(props => {
  const { sourceTypes, sourceTypesToUse, setSourceTypesToUse } = props;
  const canReset = sourceTypesToUse && sourceTypesToUse.size;
  const active = canReset;
  const reset = () => setSourceTypesToUse(new Set());
  return (
    <SortFilterPill {...{ reset, active, canReset }} title="Source">
      <div className="sort-filter-dropdown-menu-header">
        <div>In this report:</div>
        <div>
          {
            [...(sourceTypes.children.keys() || [])].filter(cat => cat != null)
              .length
          }{" "}
          types of source
        </div>
      </div>
      <DropdownItem divider />
      <SortFilterHierarchicalCategories
        categories={sourceTypes}
        categoriesToUse={sourceTypesToUse}
        setCategoriesToUse={setSourceTypesToUse}
      />
    </SortFilterPill>
  );
});
