import { useContext } from "react";
import { CollectionSearchContext } from "./context";
import { CollectionSearchContextProvider } from "./provider";
import { CollectionSearchActions } from "./types";

const useCollectionSearch = () => {
  const context = useContext(CollectionSearchContext);

  if (!context) {
    throw new Error(
      "useCollectionSearch must be used within a CollectionSearchContextProvider"
    );
  }
  return context;
};

export {
  useCollectionSearch,
  CollectionSearchContext,
  CollectionSearchContextProvider,
  CollectionSearchActions
};
