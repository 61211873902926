import React, { FC, ReactNode, SVGProps } from "react";
import { Spinner } from "reactstrap";

import ListItemWithOptions from "components/molecules/ListItemWithOptions";

import type { DropdownOption } from "components/molecules/ListItemWithOptions";
import useUserPreferences from "util/hooks/useUserPreferences";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import ErrorBanner from "components/atoms/ErrorBanner";
import { AutoSharePermission } from "api/user/types";

interface Props {
  id: string;
  title: string;
  subtitle: string;
  options: DropdownOption[];
  icon: ReactNode;
  savedOption: DropdownOption;
}

const ShareItem = ({
  id,
  title,
  subtitle,
  options,
  icon,
  savedOption
}: Props) => {
  const { updateSharePermission } = useUserPreferences();
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();

  const onSelectOption = async (option: DropdownOption) => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await updateSharePermission(
      id,
      option.id as AutoSharePermission
    );

    if (status) {
      dispatch({ type: RequestActions.SetSuccess });
    } else {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
    }
  };

  return (
    <>
      <ListItemWithOptions
        title={title}
        subtitle={subtitle}
        options={options}
        icon={icon}
        optionIcon={
          fetching
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : undefined
        }
        disabled={fetching}
        selectedOption={savedOption}
        onSelectOption={onSelectOption}
      />
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            `Error changing ${title}'s permissions. Try again in a moment.`
          }
          onClick={() => dispatch({ type: RequestActions.ResetError })}
        />
      )}
    </>
  );
};

export default ShareItem;
