import React, { useState } from "react";

import Heading from "components/atoms/Heading";
import { SearchResult } from "api/search";

import S, {
  classNameOverrides
} from "components/organisms/CopyReportModal/styles";
import SearchInputWithOptions, {
  DropdownOption
} from "components/molecules/SearchInputWithOptions";
import { GroupReportRole } from "api/tenant/types";

import useGroupSearch from "./useGroupSearch";

interface Props {
  selectedSuggestion?: SearchResult;
  setSelectedSuggestion: React.Dispatch<
    React.SetStateAction<SearchResult | undefined>
  >;
  selectedGroupPermission?: DropdownOption<GroupReportRole>;
  setSelectedGroupPermission: React.Dispatch<
    React.SetStateAction<DropdownOption<GroupReportRole>>
  >;
  chosenOrgId?: string;
}

export const dropdownOptions = [
  { id: GroupReportRole.READ, label: "Can view" },
  { id: GroupReportRole.WRITE, label: "Can edit" }
];

const SelectGroup = ({
  selectedSuggestion,
  setSelectedSuggestion,
  selectedGroupPermission,
  setSelectedGroupPermission,
  chosenOrgId
}: Props) => {
  const [query, setQuery] = useState("");
  const [previousChosenOrgId, setPreviousChosenOrgId] = useState(chosenOrgId);

  const {
    groupSuggestions,
    onSearchUpdate,
    isFetchingSuggestions,
    clearGroups
  } = useGroupSearch();

  // If the chosen organisation is cleared, then clear the stored users list
  if (chosenOrgId !== previousChosenOrgId) {
    setPreviousChosenOrgId(chosenOrgId);
    if (!chosenOrgId) {
      clearGroups();
    }
  }

  const onQueryInputChange = (newQuery: string) => {
    setQuery(newQuery);
    if (chosenOrgId) {
      onSearchUpdate(newQuery, chosenOrgId);
    }
  };

  const onSelectedSuggestionsChange = (
    newSelectedSuggestions: SearchResult[]
  ) => {
    // If the selected suggestion is the suggestion that has already been selected, remove it
    if (
      !newSelectedSuggestions.length ||
      (selectedSuggestion &&
        selectedSuggestion.id ===
          newSelectedSuggestions[newSelectedSuggestions.length - 1].id)
    ) {
      setSelectedSuggestion(undefined);
    } else {
      const newSuggestion = newSelectedSuggestions.pop();
      setSelectedSuggestion(newSuggestion);
    }
  };

  return (
    <S.InputGroup>
      <Heading level={6}>Share with a group (optional)</Heading>
      <SearchInputWithOptions
        dropdownOptions={dropdownOptions}
        onDropdownOptionChange={(option: DropdownOption<GroupReportRole>) =>
          setSelectedGroupPermission(option)
        }
        selectedDropdownOption={selectedGroupPermission ?? dropdownOptions[0]}
        query={query}
        options={groupSuggestions}
        searchTags={selectedSuggestion ? [selectedSuggestion] : []}
        onInputChange={onQueryInputChange}
        onChange={onSelectedSuggestionsChange}
        loadingText={isFetchingSuggestions ? "Loading..." : "No results found"}
        onSubmit={() => {}}
        onClear={() => {}}
        className={classNameOverrides.selectGroupInput}
        placeholder="Enter group"
        disabled={!chosenOrgId}
      />
    </S.InputGroup>
  );
};

export default SelectGroup;
