import React, { useState, useContext, useRef } from "react";
import isEqual from "lodash.isequal";

import { ReactComponent as ComplyAdvantageLogo } from "img/comply-advantage-logo.svg";
import { UserVerificationContext } from "util/hooks/useUserVerification";
import {
  AssessmentOptions,
  userAssessmentMapping
} from "pages/report/AssessmentOptions";
import { usePreviousValue } from "util/hooks/usePreviousValue";
import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import { ENTITY_TYPE } from "pages/define/utils";

import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";
import { isPDX } from "static-config";
import ListEntries from "./ListEntries";

import S from "./styles";

// Modify the data so that we can set the default confidence states.
const transformScreeningData = (
  subjectListings,
  disregardedListings,
  possibleListings,
  reportSubjectType
) => {
  const screeningTypes =
    reportSubjectType === ENTITY_TYPE.Person
      ? ["sanctions", "watchLists", "peps"]
      : ["sanctions", "watchLists"];

  const confirmed = {};
  const unconfirmed = {};
  const discarded = {};
  const userDiscarded = {};
  screeningTypes.forEach(type => {
    confirmed[type] = subjectListings?.[type]?.hits?.map(hit => ({
      ...hit,
      originalSetConfidence: CONFIDENCE_CATEGORIES.confirmed,
      currentSetConfidence: CONFIDENCE_CATEGORIES.confirmed
    }));

    discarded[type] = disregardedListings?.[type]?.hits?.map(hit => ({
      ...hit,
      originalSetConfidence: CONFIDENCE_CATEGORIES.discarded,
      currentSetConfidence: CONFIDENCE_CATEGORIES.discarded
    }));

    unconfirmed[type] = possibleListings?.[type]?.hits?.map(hit => ({
      ...hit,
      originalSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed,
      currentSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed
    }));
  });

  return { confirmed, unconfirmed, discarded, userDiscarded };
};

const ThemedScreening = ({
  screeningData,
  reportSubjectType,
  hideCAFooter,
  isReportRegenerationOpen
}) => {
  const resultsSectionRef = useRef(null);
  const [isShowingExpandButton, setIsShowingExpandButton] = useState();
  const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
    "screening-section-expanded-fully",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const onToggleExpandResultsSection = () => {
    setIsResultsExpanded(prevState => !prevState);
    if (isResultsExpanded) {
      const rect = resultsSectionRef.current.getBoundingClientRect();

      if (rect.top <= 0) {
        resultsSectionRef.current.scrollIntoView();
      }
    }
  };

  const data = screeningData?.default;

  const casedReportSubjectType =
    // TODO DJ
    // eslint-disable-next-line no-unsafe-optional-chaining
    reportSubjectType?.charAt(0)?.toUpperCase() + reportSubjectType.slice(1);
  const subjectListings = `subject${casedReportSubjectType}Listings`;
  const disregardedListings = `disregarded${casedReportSubjectType}Listings`;
  const possibleListings = `possible${casedReportSubjectType}Listings`;

  const [transformedScreeningData, setTransformedScreeningData] = useState(
    transformScreeningData(
      data?.[subjectListings],
      data?.[disregardedListings],
      data?.[possibleListings],
      reportSubjectType
    )
  );

  const [userVerifications, setUserVerifications, , setUserVerificationsCount] =
    useContext(UserVerificationContext);

  const assessmentCount = Array.from(userVerifications?.values() ?? [])?.filter(
    value => value !== 0 && value !== AssessmentOptions.NoUserAssessment
  )?.length;
  const prevAssessmentCount = usePreviousValue(assessmentCount);

  // The user undid all user assessments
  if (
    prevAssessmentCount !== 0 &&
    prevAssessmentCount !== undefined &&
    assessmentCount === 0
  ) {
    const newlyTransformedScreeningData = transformScreeningData(
      data[subjectListings],
      data[disregardedListings],
      data[possibleListings],
      reportSubjectType
    );
    // Reset all user assigned confidence
    // but only if there has been a change to any of the user assigned confidences in the
    // data
    if (!isEqual(newlyTransformedScreeningData, transformedScreeningData)) {
      setTransformedScreeningData(newlyTransformedScreeningData);
    }
  }

  const onConfidenceChange = (
    newConfidence,
    originalConfidence,
    entryId,
    screeningType
  ) => {
    const screeningEntryToMoveIndex = transformedScreeningData[
      originalConfidence
    ][screeningType].findIndex(screeningEntry => {
      return screeningEntry.userAssessmentId === entryId;
    });
    const screeningEntryToMove =
      transformedScreeningData[originalConfidence][screeningType][
        screeningEntryToMoveIndex
      ];
    screeningEntryToMove.currentSetConfidence = newConfidence;
    // Insert regen ids into map and update user changes count
    const newUserVerifications = new Map(userVerifications);
    if (newConfidence !== screeningEntryToMove.originalSetConfidence) {
      // Store/update the user assigned confidence for the screening entry using its id
      newUserVerifications.set(entryId, userAssessmentMapping[newConfidence]);
      setUserVerificationsCount(prev => prev + 1);
    } else {
      newUserVerifications.set(entryId, 0); // Clear
      setUserVerificationsCount(prev => prev - 1);
    }
    setUserVerifications(newUserVerifications);
  };

  const renderResults = () => {
    return (
      <S.ResultsTable aria-label="Screening results">
        <ListEntries
          listEntries={transformedScreeningData.confirmed}
          confidenceCategory={CONFIDENCE_CATEGORIES.confirmed}
          onConfidenceChange={onConfidenceChange}
          onCollapse={() => setIsResultsExpanded(false)}
        />
        <ListEntries
          listEntries={transformedScreeningData.unconfirmed}
          confidenceCategory={CONFIDENCE_CATEGORIES.unconfirmed}
          onConfidenceChange={onConfidenceChange}
          onCollapse={() => setIsResultsExpanded(false)}
        />
        <ListEntries
          listEntries={transformedScreeningData.discarded}
          confidenceCategory={CONFIDENCE_CATEGORIES.discarded}
          onConfidenceChange={onConfidenceChange}
          onCollapse={() => setIsResultsExpanded(false)}
        />
        {/* TODO NEEDS TO BE WIRED UP TO TAKE DISCARDED */}
        {isPDX && (
          <ListEntries
            listEntries={transformedScreeningData.userDiscarded}
            confidenceCategory={CONFIDENCE_CATEGORIES.userDiscarded}
            onConfidenceChange={onConfidenceChange}
            onCollapse={() => setIsResultsExpanded(false)}
          />
        )}
      </S.ResultsTable>
    );
  };

  const renderFooter = () => {
    return (
      <S.Footer isShowingExpandButton={isShowingExpandButton}>
        {!hideCAFooter && (
          <>
            Xapien’s dynamic global PEP, sanction and watchlist screening is
            supplied in real-time by Comply Advantage.
            <ComplyAdvantageLogo />
          </>
        )}
      </S.Footer>
    );
  };

  return (
    <>
      <S.SectionBanner>
        <S.SectionBannerInfo>
          Xapien uses data from multiple sources to confirm screening matches
          for the primary entity.
        </S.SectionBannerInfo>
      </S.SectionBanner>
      <S.SectionContent
        ref={resultsSectionRef}
        isResultsExpanded={isResultsExpanded}
      >
        {renderResults()}
      </S.SectionContent>
      {renderFooter()}
      <S.CustomStickyExpandButton
        isReportRegenerationOpen={isReportRegenerationOpen}
        isResultsExpanded={isResultsExpanded}
        onToggleExpandResultsSection={onToggleExpandResultsSection}
        resultsSectionRef={resultsSectionRef}
        shouldShowButtonCallback={setIsShowingExpandButton}
      />
    </>
  );
};
export default ThemedScreening;
