import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import { Section } from "pages/report/Section";
import SectionErrorWrapper from "pages/report/SectionErrorWrapper";
import { InsightsReportStates } from "util/hooks/useOldInsightsReport/types";
import { useInsightsEnabled } from "util/hooks/useInsightsEnabled";
import { TypingDots } from "components/atoms/TypingDots";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { InsightReportSection } from "components/organisms/InsightReportSection";
import { useInsightsReport } from "util/hooks/useOldInsightsReport";
import { SourcedInsightsAnswer } from "components/organisms/SourcedInsightsAnswer";

import { grey } from "styles/colors";

import { useQuestionAndAnswer } from "./useQuestionAndAnswer";

const InsightsReport: FC<{}> = _props => {
  const insights = useInsightsReport();
  const { generate, type } = insights;
  const notGenerated = type === InsightsReportStates.NotGenerated;
  useEffect(() => {
    if (notGenerated) {
      generate();
    }
  }, [generate, notGenerated]);
  // for now going with auto-generate instead of button
  // if (insights.type === InsightsReportStates.NotGenerated) {
  //   return (
  //     <ButtonNew
  //       type={ButtonType.Filled}
  //       size={ButtonSize.Medium}
  //       text="Generate"
  //     />
  //   );
  // }
  if (insights.type === InsightsReportStates.InvalidNeedsRegenerating) {
    return (
      <div>
        The Insights Report for this enquiry is in an old format, to use
        Insights on this enquiry you need to generate a fresh Insights Report
        <ButtonNew
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          text="Generate Insights Report"
          onClick={generate}
        />
      </div>
    );
  }
  if (type === InsightsReportStates.Error) {
    return <div>{insights.error}</div>;
  }
  if (type === InsightsReportStates.Loaded) {
    const { report } = insights;
    return (
      <div>
        <InsightReportSection
          title="Overview"
          answer={report.executive_summary}
        />
        {"wealth" in report ? (
          <>
            <InsightReportSection answers={report.biographical_details} />
            <InsightReportSection answers={report.crime_and_controversy} />
            <InsightReportSection answers={report.sanctions_exposure} />
            <InsightReportSection answers={report.political_exposure} />
            <InsightReportSection answers={report.esg} />
            <InsightReportSection answers={report.business_interests} />
            <InsightReportSection answers={report.wealth} />
            <InsightReportSection answers={report.character_and_achievements} />
            <InsightReportSection answers={report.family_friends_associates} />
            <InsightReportSection answers={report.locations} />
          </>
        ) : (
          <>
            <InsightReportSection answers={report.biographical_details} />
            <InsightReportSection answers={report.controversy} />
            <InsightReportSection answers={report.finances} />
            <InsightReportSection answers={report.key_people} />
            <InsightReportSection answers={report.legal} />
            <InsightReportSection answers={report.sanctions_ownership} />
            <InsightReportSection answers={report.esg} />
            <InsightReportSection answers={report.positive_impact} />
          </>
        )}
      </div>
    );
  }
  return <TypingDots />;
};

const EndAdornment = ({ onClick }: { onClick: () => void }) => {
  const styles = {
    boxShadow: "none",
    color: grey.dark,
    minWidth: "32px",
    padding: 0,
    width: "32px",
    height: "32px",
    borderRadius: "100%"
  };

  return (
    <InputAdornment position="end">
      <IconButton style={styles} onClick={onClick}>
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );
};

const QuestionAndAnswerBox: FC<{}> = _props => {
  const { ask, answer, asking, clear, error, errored } = useQuestionAndAnswer();
  const [question, setQuestion] = useState<string | undefined>();
  // Uncomment to auto-ask this question for quick devving
  // useEffect(() => {
  //   if (loaded) {
  //     const presetQuestion = `Who is ${subjectName}?`;
  //     setQuestion(presetQuestion);
  //     ask(presetQuestion);
  //   }
  // }, [ask, setQuestion, loaded, subjectName]);

  const hasQuestion = question && question.length;
  const onClearClick = useCallback(() => {
    setQuestion(undefined);
    clear();
  }, [setQuestion, clear]);

  return (
    <>
      <TextField
        label={
          hasQuestion
            ? undefined
            : "Ask a question about the subject of this report..."
        }
        variant="outlined"
        fullWidth
        margin="normal"
        value={question || ""}
        onChange={e => setQuestion(e.target.value)}
        onKeyDown={e => (e.key === "Enter" ? ask(question) : undefined)}
        InputProps={{
          endAdornment: hasQuestion ? (
            <EndAdornment onClick={onClearClick} />
          ) : undefined
        }}
      />
      {errored
        ? error || "An error ocurred, please try again later"
        : undefined}
      {asking ? "Waiting for answer..." : undefined}
      {answer ? <SourcedInsightsAnswer answer={answer} /> : undefined}
    </>
  );
};

type InsightsSectionProps = {};

const InsightsSectionInner: FC<InsightsSectionProps> = () => {
  const title = "Insights";
  const [isExpanded, setExpanded] = useState(true);

  return (
    <SectionErrorWrapper title={title} isExpanded>
      <Section title={title} isExpanded={isExpanded} setExpanded={setExpanded}>
        <InsightsReport />
        <QuestionAndAnswerBox />
      </Section>
    </SectionErrorWrapper>
  );
};

export const InsightsSection: FC<InsightsSectionProps> = props => {
  const enabled = useInsightsEnabled();
  useMemo(() => {
    if (enabled) {
      // eslint-disable-next-line no-console
      console.info("Insights Report Enabled");
    } else {
      console.debug("Insights Report Disabled");
    }
  }, [enabled]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return enabled ? <InsightsSectionInner {...props} /> : <></>;
};
