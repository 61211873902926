import { useEffect, useState } from "react";
import Organisations from "api/organisations";
import { useAuthentication } from "util/hooks/useAuthentication";
import { UsageLoadingStates, UsageState } from "./types";

const useOrganisationUsage = () => {
  const {
    state: {
      permissions: { canGetOrganisationUsage }
    }
  } = useAuthentication();
  const [usage, setUsage] = useState<UsageState>({
    loadingState: UsageLoadingStates.loading
  });
  useEffect(() => {
    if (!canGetOrganisationUsage) {
      return;
    }

    const api = new Organisations();
    const fetch = async () => {
      const { response, status, message } = await api.getOrganisationUsage();

      if (status) {
        setUsage({ ...response!, loadingState: UsageLoadingStates.loaded });
      } else {
        setUsage({
          loadingState: UsageLoadingStates.loadingFailed,
          error: message ?? "Loading usage numbers for your organisation failed"
        });
      }
    };
    fetch();
  }, [canGetOrganisationUsage]);

  const downloadUsage = (onFinish: () => void) => {
    const api = new Organisations();
    api.downloadUsage().then(result => {
      if (result.status && result.response) {
        const anchor = document.createElement("a");
        anchor.href = result.response;
        anchor.target = "_blank";

        anchor.click();
        onFinish();
      }
    });
  };

  const dismissError = () =>
    usage.loadingState === UsageLoadingStates.loadingFailed
      ? setUsage({ ...usage, error: undefined })
      : undefined;

  return { usage, downloadUsage, dismissError };
};

export { useOrganisationUsage };
