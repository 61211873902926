import React, { Fragment } from "react";

import SectionHeader from "components/atoms/SectionHeader";
import SectionFooter from "components/atoms/SectionFooter";

const ScreeningFallback = () => {
  return (
    <Fragment>
      <SectionHeader text="No screening risk found" />
      <SectionFooter />
    </Fragment>
  );
};

export default ScreeningFallback;
