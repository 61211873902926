import React, { CSSProperties, ReactNode } from "react";

import Truncate from "components/atoms/Truncate";

import { Placement } from "@floating-ui/react";
import S, { classNameOverrides } from "./styles";

interface Props {
  text?: string;
  length?: number;
  children: ReactNode;
  position?: Placement;
  className?: string;
  style?: CSSProperties;
  disableTooltip?: boolean;
}

export const TruncateLength = ({
  text,
  length,
  children,
  position = "top",
  className,
  style,
  disableTooltip = false
}: Props) => {
  if (length !== undefined && text) {
    if (text.length <= length || text.length < 1 || typeof text === "object")
      return <>{text}</>;

    let shortText = text.substr(0, length);

    // If the last character(s) is whitespace, remove
    if (shortText.charAt(shortText.length - 1) === " ") {
      shortText = shortText.trim();
    }

    return (
      <S.Tooltip
        interactive
        tooltip={text}
        position={position}
        className={className}
        style={style}
        disabled={disableTooltip}
      >
        {shortText}
        <span>...</span>
      </S.Tooltip>
    );
  }
  if (children) {
    if (text) {
      return (
        <Truncate tooltip={text} hideDelay={0} showDelay={500}>
          {children}
        </Truncate>
      );
    }

    return (
      <Truncate
        hideDelay={0}
        showDelay={500}
        tooltipClassName={
          disableTooltip ? classNameOverrides.tooltipClass : undefined
        }
      >
        {children}
      </Truncate>
    );
  }

  return null;
};

export default TruncateLength;
