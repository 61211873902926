import React, { FC } from "react";

import Heading from "components/atoms/Heading";

import S from "./styles";

const LoadingTimeline: FC = () => (
  <S.LoadingContainer>
    <S.LoadingLogo>
      <S.LoadingSpinner />
      <S.Logo />
    </S.LoadingLogo>
    <Heading level={3}>Generating your timeline</Heading>
    <p>We&apos;re working to generate your timeline content.</p>
    <p>Please check back here again shortly.</p>
  </S.LoadingContainer>
);

export default LoadingTimeline;
