import { styled } from "@linaria/react";

import { grey } from "styles/colors";

const FiltersContainer = styled.div`
  max-height: 280px;
  display: flex;
  flex: 1;
`;

const Filter = styled.div`
  width: 100%;
  border-right: 1px solid ${grey.rule};
  padding: 0 10px;
  min-width: 0;

  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  &:first-child {
    padding-left: 0;
  }
`;

const S = {
  FiltersContainer,
  Filter
};

export default S;
