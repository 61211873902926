import React from "react";

// @ts-ignore
import theme from "theme";

import S from "./styles";

const Loading = () => {
  return <S.BigSpinner borderColor={theme.alternativePrimaryColor} />;
};

export default Loading;
