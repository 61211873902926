import React, { FC, SVGProps } from "react";
import { Chip } from "@mui/material";
import { Person } from "@mui/icons-material";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";

import ModalContainer from "components/molecules/ModalContainer";
import Heading from "components/atoms/Heading";
import TitleCard from "components/molecules/TitleCard";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ButtonNew from "components/atoms/ButtonNew";
import ErrorBanner from "components/atoms/ErrorBanner";
import { blue, standardColors } from "styles/colors";
import { routes } from "pages/Router";

import useCreateOrg from "./useCreateOrg";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  orgName: string;
  orgDescription?: string;
  admins?: string;
  users?: string;
  metadataUrl?: string | null;
  metadata?: string | null;
}

const parseCSV = (csv?: string) => {
  if (!csv) {
    return [];
  }

  const list = csv
    .split(",")
    .map(value => {
      const strippedValue = value.trim();
      if (strippedValue) {
        return strippedValue;
      }
      return "";
    })
    .filter(Boolean);

  return [...new Set(list)];
};

const pillStyles = {
  bgcolor: blue.xapienDark,
  borderRadius: 1,
  color: standardColors.white
};

const pillIconStyles = {
  fill: standardColors.white,
  width: 12,
  height: 12
};

const CreateOrgModal = ({
  isOpen,
  toggleOpen,
  orgName,
  orgDescription,
  admins,
  users,
  metadataUrl,
  metadata
}: Props) => {
  const adminsList = parseCSV(admins);
  const usersList = parseCSV(users);
  const { createOrganisation, reset, fetching, error, errorMessage } =
    useCreateOrg();
  const navigate = useNavigate();

  const onToggleOpen = () => {
    toggleOpen();
    reset();
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={onToggleOpen}
      onExitClick={onToggleOpen}
      title="Create organisation"
    >
      <S.ModalContent>
        <div>
          <Heading level={6}>
            You are about to create a new client organisation
          </Heading>
          <S.Explainer>
            Users will receive email invitations. Check the details below
            carefully before you confirm.
          </S.Explainer>
        </div>
        <TitleCard
          title={orgName}
          subtitle={orgDescription}
          icon={<S.BuildingIcon />}
        />
        {adminsList.length > 0 && (
          <>
            <Heading level={6}>
              {adminsList.length} admin{adminsList.length > 1 ? "s" : ""} will
              be invited
            </Heading>
            <S.PillsContainer>
              {adminsList.map(admin => {
                return (
                  <Chip
                    key={admin}
                    icon={
                      <S.PersonIcon>
                        <Person sx={pillIconStyles} />
                      </S.PersonIcon>
                    }
                    label={admin}
                    sx={pillStyles}
                  />
                );
              })}
            </S.PillsContainer>
          </>
        )}
        {usersList.length > 0 && (
          <>
            <Heading level={6}>
              {usersList.length} user{usersList.length > 1 ? "s" : ""} will be
              invited
            </Heading>
            <S.PillsContainer>
              {usersList.map(user => {
                return (
                  <Chip
                    key={user}
                    icon={
                      <S.PersonIcon>
                        <Person sx={pillIconStyles} />
                      </S.PersonIcon>
                    }
                    label={user}
                    sx={pillStyles}
                  />
                );
              })}
            </S.PillsContainer>
          </>
        )}
      </S.ModalContent>
      {error && (
        <ErrorBanner
          text={
            errorMessage ??
            "There was an issue creating your organisation. Please try again in a moment."
          }
        />
      )}
      <S.Controls>
        <ButtonNew
          text="Cancel"
          size={ButtonSize.Medium}
          type={ButtonType.Outlined}
          onClick={onToggleOpen}
        />
        <ButtonNew
          text="Confirm"
          size={ButtonSize.Medium}
          type={ButtonType.Filled}
          onClick={async () => {
            const status = await createOrganisation(
              orgName,
              adminsList,
              usersList,
              orgDescription,
              metadataUrl,
              metadata
            );
            if (status) {
              navigate(`/${routes.hub}`);
            }
          }}
          disabled={fetching}
          IconTrailing={
            fetching
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
        />
      </S.Controls>
    </ModalContainer>
  );
};

export default CreateOrgModal;
