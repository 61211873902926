import React, { FC, ReactNode, SVGProps } from "react";
import { Spinner } from "reactstrap";
import { RotateCw } from "react-feather";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";

import Heading from "components/atoms/Heading";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

export interface ErrorSectionProps {
  showRegenerateButton?: boolean;
  children: ReactNode;
}

const ErrorSection: FC<ErrorSectionProps> = ({
  children,
  showRegenerateButton = false
}) => {
  const { state, dispatch } = useInsightReport();

  const onRegenerateReport = () => {
    dispatch({ type: InsightReportActions.regenerateReport });
  };

  const isLoading = state.status === InsightReportStatus.regeneratingReport;

  return (
    <S.ErrorContainer>
      <S.ErrorLogoContainer>
        <S.ErrorLogo />
      </S.ErrorLogoContainer>
      <Heading level={3}>We&apos;ve hit a snag</Heading>
      {children}
      {showRegenerateButton && (
        <S.RegenerateButton
          onClick={onRegenerateReport}
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          disabled={isLoading}
          text={isLoading ? "Regenerating" : "Regenerate"}
          IconTrailing={
            isLoading
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : RotateCw
          }
        />
      )}
    </S.ErrorContainer>
  );
};

export default ErrorSection;
