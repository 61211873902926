import React, { ReactNode } from "react";

import RemoveConfirmationModal from "components/molecules/RemoveConfirmationModal";

import useDeleteReport from "util/hooks/useDeleteReport";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  reportId: string;
  children?: ReactNode;
}

const DeleteReportModal = ({
  isOpen,
  toggleOpen,
  reportId,
  children
}: Props) => {
  const { deleteReport, error, errorMessage, fetching, resetError } =
    useDeleteReport();

  return (
    <RemoveConfirmationModal
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      heading="Delete report?"
      onRemove={() => deleteReport(reportId)}
      onReset={resetError}
      error={error}
      errorMessage={errorMessage}
      loading={fetching}
      primaryActionText="Delete report"
      description="There is no way to recover a deleted report. All users will lose access to this report."
    >
      {children}
    </RemoveConfirmationModal>
  );
};

export default DeleteReportModal;
