import React, { FC, FormEvent, MouseEventHandler } from "react";
import Input from "components/atoms/Input";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";

import {
  useHubAuthentication,
  HubAuthenticationActions
} from "util/hooks/useHubAuthentication";
import { HubAuthenticationStatus } from "util/hooks/useHubAuthentication/types";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  slogan?: string;
  onForgottenPasswordClick: () => void;
}

const HubLoginForm: FC<Props> = ({ slogan, onForgottenPasswordClick }) => {
  const { state, dispatch } = useHubAuthentication();

  const onEmailChange = (email: string) =>
    dispatch({ type: HubAuthenticationActions.updateEmail, email });

  const onPasswordChange = (password: string) =>
    dispatch({ type: HubAuthenticationActions.updatePassword, password });

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const filledFields =
      state.email &&
      state.email.trim().length &&
      state.password &&
      state.password.trim().length;

    if (!filledFields) {
      dispatch({
        type: HubAuthenticationActions.unauthenticated,
        error: "Please enter your email address and password."
      });
      return;
    }

    dispatch({ type: HubAuthenticationActions.verifyPassword });
  };

  const onDismissError: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch({ type: HubAuthenticationActions.dismissError });
  };

  return (
    <form onSubmit={onSubmit}>
      <S.Container>
        {theme.navigation?.logo ?? <S.XapienLogo />}
        <S.Title level={5}>
          {theme.slogan ?? slogan
            ? slogan
            : "Xapien is a fully-automated research platform"}
        </S.Title>
        <S.Subtitle level={4}>Please sign-in</S.Subtitle>
        <S.Inputs>
          {state.error && (
            <ErrorBanner text={state.error} onClick={onDismissError} />
          )}

          <Input
            inputType="email"
            placeholder="Email"
            onChange={onEmailChange}
            value={state.email}
            autoComplete="email"
          />
          <Input
            inputType="password"
            placeholder="Password"
            onChange={onPasswordChange}
            value={state.password}
            autoComplete="current-password"
          />
        </S.Inputs>
        <S.Link onClick={onForgottenPasswordClick}>
          Forgotten your password?
        </S.Link>

        <ButtonNew
          onClick={onSubmit}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={state.status === HubAuthenticationStatus.authenticating}
          text={
            state.status === HubAuthenticationStatus.authenticating
              ? "Signing in"
              : "Go"
          }
        />
      </S.Container>
    </form>
  );
};

export default HubLoginForm;
