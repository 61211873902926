import React, { ReactNode, useCallback, useMemo, useState } from "react";

import Api from "api/organisations";
import { OrganisationPreferences } from "api/organisations/types";

import { getAuthenticatedUser } from "util/authentication";
import { OrganisationPreferencesContext } from "./context";
import { OrganisationPreferencesState } from "./types";
import { useAuthentication } from "../useAuthentication";

export const OrganisationPreferencesContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [projectRefEnabled, setProjectRefEnabled] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [inviteUsersEnabled, setInviteUserEnabled] = useState(false);
  const [customerAssistEnabled, setCustomerAssistEnabled] = useState(false);
  const {
    state: {
      permissions: { canEditMfa, canGetOrganisationPreferences }
    }
  } = useAuthentication();

  const [loaded, setLoaded] = useState(false);

  const OrgApi = useMemo(() => new Api(), []);

  const preferences: OrganisationPreferences = useMemo(
    () => ({
      projectReferencesEnabled: projectRefEnabled,
      letUsersOnboardOtherUsers: inviteUsersEnabled,
      mfaEnabled
      /* customerAssistEnabled // TODO: Pending backend */
    }),
    [inviteUsersEnabled, projectRefEnabled, mfaEnabled]
  );

  const savePreferences = useCallback(
    async (prefs: OrganisationPreferences) => {
      return OrgApi.setOrganisationPreferences(
        canEditMfa ? prefs : { ...prefs, mfaEnabled: null }
      );
    },
    [OrgApi, canEditMfa]
  );

  const toggleProjectRefEnabled = useCallback(async () => {
    setProjectRefEnabled(prev => !prev);

    const response = await savePreferences({
      ...preferences,
      projectReferencesEnabled: !projectRefEnabled
    });

    if (!response.status) {
      setProjectRefEnabled(prev => !prev);
    }
  }, [preferences, projectRefEnabled, savePreferences]);

  const toggleInviteUsersEnabled = useCallback(async () => {
    setInviteUserEnabled(prev => !prev);

    const response = await savePreferences({
      ...preferences,
      letUsersOnboardOtherUsers: !inviteUsersEnabled
    });

    if (!response.status) {
      setInviteUserEnabled(prev => !prev);
    }
  }, [inviteUsersEnabled, preferences, savePreferences]);

  const toggleMfaEnabled = useCallback(async () => {
    setMfaEnabled(prev => !prev);

    const response = await savePreferences({
      ...preferences,
      mfaEnabled: !mfaEnabled
    });

    if (!response.status) {
      setMfaEnabled(prev => !prev);
      return;
    }

    // the mfaRequired flag config is changed with this action, so force
    // refresh the authenticated user config using AWS's API so that the
    // flag's updated accordingly in the token
    await getAuthenticatedUser({ forceRefresh: true });
  }, [mfaEnabled, preferences, savePreferences]);

  const toggleCustomerEnabled = () => {
    setCustomerAssistEnabled(prev => !prev);
  };

  const initialisePreferences = useCallback(async () => {
    if (!canGetOrganisationPreferences) return;

    const response = await OrgApi.getOrganisationPreferences();

    if (response.status) {
      const storedPreferences = response.response;
      setProjectRefEnabled(
        storedPreferences?.projectReferencesEnabled ?? false
      );
      setInviteUserEnabled(
        storedPreferences?.letUsersOnboardOtherUsers ?? false
      );
      setMfaEnabled(storedPreferences?.mfaEnabled ?? false);
    }

    setLoaded(true);
  }, [OrgApi, canGetOrganisationPreferences]);

  const providerValue: OrganisationPreferencesState = useMemo(
    () => ({
      projectRefEnabled,
      mfaEnabled,
      inviteUsersEnabled,
      customerAssistEnabled,
      loaded,
      toggleProjectRefEnabled,
      toggleMfaEnabled,
      toggleInviteUsersEnabled,
      toggleCustomerEnabled,
      initialisePreferences
    }),
    [
      customerAssistEnabled,
      initialisePreferences,
      inviteUsersEnabled,
      loaded,
      mfaEnabled,
      projectRefEnabled,
      toggleInviteUsersEnabled,
      toggleProjectRefEnabled,
      toggleMfaEnabled
    ]
  );

  return (
    <OrganisationPreferencesContext.Provider value={providerValue}>
      {children}
    </OrganisationPreferencesContext.Provider>
  );
};
