import React from "react";

import { Users } from "react-feather";
import { useNavigate } from "react-router-dom";

import { CollectionListView } from "components/molecules/CollectionListControls";
import { CardVariant } from "components/molecules/Card/types";

import CardSkeleton from "components/atoms/CardSkeleton";
import { collectionCardComponents } from "util/collectionCardComponents";
import GroupCardMenu from "components/organisms/GroupCardMenu";
import { formatGroupName } from "api/reports/utils";
import { formatCardTags } from "util/formatCardTags";
import ManageGroupContextProvider from "util/hooks/useManageGroup/provider";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";

import { Group } from "api/groups";

import { classNameOverrides } from "./styles";

interface Props {
  item?: Group;
  view: CollectionListView;
  loading?: boolean;
  variant: CardVariant;
}

const GroupCard = ({ item, view, loading, variant }: Props) => {
  const { C } = collectionCardComponents[view];
  const navigate = useNavigate();

  if (loading) {
    return <CardSkeleton view={view} />;
  }

  if (!item) {
    return null;
  }

  const groupTags = formatCardTags(item.tags);
  const canClickThrough = item.permissions.canViewReports;

  const onClick = canClickThrough
    ? () => {
        navigate({
          pathname: `/groups/${item.id}`,
          search: `?name=${item.title}&description=${item.context ?? ""}`
        });
      }
    : undefined;
  return (
    <C.Card
      onClick={onClick}
      variant={variant}
      control={
        <ManageGroupContextProvider groupId={item.id}>
          <GroupCardMenu
            groupId={item.id}
            groupName={formatGroupName(item.title) ?? ""}
            groupDescription={item.context}
            groupMemberCount={item.memberCount}
            permissions={item.permissions}
          />
        </ManageGroupContextProvider>
      }
      className={canClickThrough ? classNameOverrides.clickableCard : undefined}
    >
      <C.CardHeader
        variant={variant}
        title={formatGroupName(item.title) ?? ""}
        subtitle={item.context}
        icon={
          <Avatar avatarSize={AvatarSize.Large} hasBorder>
            <Users />
          </Avatar>
        }
      />
      <C.CardBody variant={variant} title={item.description} tags={groupTags} />
    </C.Card>
  );
};

export default GroupCard;
