import React, { FC } from "react";
import { Skeleton as MuiSkeleton } from "@mui/material";
import type { SkeletonProps } from "@mui/material";

type Props = SkeletonProps & { isLightMode?: boolean };

const Skeleton: FC<Props> = ({ isLightMode, ...rest }) => {
  return (
    <MuiSkeleton
      {...rest}
      sx={{
        backgroundColor: isLightMode
          ? "rgba(0,0,0,0.1)"
          : "rgba(255,255,255,0.2)"
      }}
    />
  );
};

export default Skeleton;
