export const copyToClipboard = ({
  richText,
  plainText
}: {
  richText: string;
  plainText: string;
}) => {
  if (typeof ClipboardItem === "undefined") {
    const clipboardFallback = async () => {
      await navigator.clipboard.writeText(plainText);
    };
    clipboardFallback();

    return;
  }

  const clipboardItem = new ClipboardItem({
    "text/plain": new Blob([plainText], { type: "text/plain" }),
    "text/html": new Blob([richText], { type: "text/html" })
  });

  navigator.clipboard.write([clipboardItem]);
};
