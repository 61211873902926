import { styled } from "@linaria/react";
import { Spinner } from "reactstrap";
import { grey, green } from "styles/colors";
import { ReactComponent as XapienLogo } from "img/icons/xapien-logo-icon.svg";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;

  p {
    margin: 0;
  }
`;

const LoadingLogo = styled.div`
  padding: 2rem;
  position: relative;
`;

const Logo = styled(XapienLogo)`
  width: 32px;
  height: 32px;
  path {
    fill: ${grey.dark};
  }
`;

const LoadingSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${green.xapienGreen};
`;

const S = {
  LoadingSpinner,
  Logo,
  LoadingLogo,
  LoadingContainer
};

export default S;
