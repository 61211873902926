import React from "react";

import Heading from "components/atoms/Heading";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { Variant } from "components/molecules/ModalContainer";
import S from "./styles";

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const DisclaimerModal = ({ isOpen, onToggle }: Props) => {
  return (
    <S.DisclaimerModalContainer
      isOpen={isOpen}
      toggleOpen={onToggle}
      onExitClick={onToggle}
      variant={Variant.LIGHT}
    >
      <S.ModalContent>
        <S.HeadingContainer>
          <Heading level={4}>Disclaimer</Heading>
          <S.ExitButton onClick={onToggle}>
            <S.XIcon />
          </S.ExitButton>
        </S.HeadingContainer>
        <p>
          It is important to note that the content in this section has been
          machine written using AI. Insights uses Open AI’s GPT 3.5 and GPT
          Turbo Microsoft Enterprise artificial intelligence technology to
          create machine-generated event summaries relating to your subject.
        </p>
        <p>
          The content generated in the Insights Section is based solely on the
          media and web data currently available to this tool. This data has
          undergone analysis to confirm relevance to the specified inquiry;
          however accuracy and relevance of the data cannot be guaranteed for
          completeness or correctness.
        </p>
        <p>
          As the content produced is machine written, it may not always reflect
          the entirety of the input data accurately. Information may be
          misleading or inaccurately phrased. Users must exercise discretion and
          refer to the original sources used to create the machine-generated
          event summary. Users are expected to review the cited sources and
          other sections of the report, including, where available, any Risk &
          Compliance profile, and to conduct further research outside the report
          before making any decisions.
        </p>
        <p>
          By accessing and using the content generated in the Insights Section
          within our product, users agree to accept and abide by the terms
          outlined in the{" "}
          <strong>Confidentiality statement at the foot of the report.</strong>
        </p>
        <S.Actions>
          <S.GotItButton
            text="Got it"
            type={ButtonType.Filled}
            size={ButtonSize.Large}
            onClick={onToggle}
          />
        </S.Actions>
      </S.ModalContent>
    </S.DisclaimerModalContainer>
  );
};

export default DisclaimerModal;
