import { styled } from "@linaria/react";

import { ReactComponent as LogoIcon } from "img/insights-icon.svg";
import { grey, purple, standardColors } from "styles/colors";
import { ReactComponent as InformationSvgIcon } from "img/icons/information-icon.svg";
import { family, fontSize } from "styles/fonts";

// @ts-ignore
import theme from "theme";

const Panel = styled.div`
  position: relative;
  width: 100%;
  background-color: ${grey.panel};
  min-height: 30px;
  color: ${grey.dark};
  background-color: ${standardColors.white};
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  page-break-before: avoid;
  page-break-after: avoid;
  page-break-inside: avoid;
`;

const ContentPanel = styled(Panel)`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Container = styled.div`
  min-height: 600px;
  display: flex;
`;

const Divider = styled.hr`
  margin: 0;
  background-color: ${grey.panel};
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: ${standardColors.white};
  border-radius: 50%;

  svg {
    width: 13px;
    height: 16px;
    color: ${standardColors.black};
  }
`;

const DisclaimerButton = styled.button`
  background: none;
  display: flex;
  padding-left: 6px;
  align-items: center;
  border-style: none;
  color: ${theme.primaryColor};

  &:focus {
    outline: none;
  }
`;

const InformationIcon = styled(InformationSvgIcon)`
  height: 14px;
  width: 14px;
  margin-left: 6px;
  path {
    fill: ${theme.primaryColor};
  }
`;

const DisclaimerContainer = styled.div`
  display: flex;
  align-items: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: ${family.interRegular};
  font-size: ${fontSize.sm};
  line-height: ${fontSize.sm};

  > strong {
    font-family: ${family.interSemiBold};
  }
`;

const EducationModalButton = styled(DisclaimerButton)`
  color: ${purple.darker};
`;

const Banner = styled.div`
  background-color: ${grey.panel};
  color: ${grey.dark};
  letter-spacing: -0.49px;
  box-shadow: 0px 1px 1px 0px rgba(${standardColors.black}.25);
  align-items: center;
  display: flex;
  gap: 0.75rem;
  padding: 6px 12px;
  height: 36px;
  font-size: ${fontSize.sm};
  justify-content: space-between;
  flex-direction: row;

  > span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal;
  }
`;

const BannerTextContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 0.75rem;
`;

const BannerCta = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const S = {
  Panel,
  ContentPanel,
  Container,
  InformationIcon,
  Divider,
  LogoContainer,
  DisclaimerButton,
  LogoIcon,
  DisclaimerContainer,
  EducationModalButton,
  Banner,
  BannerTextContainer,
  BannerCta
};

export default S;
