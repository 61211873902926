import { red, grey, orange } from "styles/colors";

export const FILTER_LABEL_MAPPINGS = {
  "social-labour": "Social & Labour",
  "scandal-controversy": "Scandal & Controversy"
};

export const FILTER_SECTION_IDS = {
  mediaBySource: "mediaBySource",
  mediaByRiskDirection: "mediaByRiskDirection",
  mediaByDirectRiskCategory: "mediaByDirectRiskCategory",
  mediaByIndirectRiskCategory: "mediaByIndirectRiskCategory",
  mediaByOrganisations: "mediaByOrganisations",
  mediaByPeople: "mediaByPeople",
  mediaByLanguages: "mediaByLanguages",
  mediaByTime: "mediaByTime",
  mediaByLocations: "mediaByLocations"
};

export const RISK_CATEGORY_MAX_DEFAULT_SHOWN = 5;

export const HIGH_RISK_SITES_LABEL = "High Risk Sites";

export const RISK_DIRECTION_FILTER_OPTIONS = {
  directRisk: {
    id: "directRisk",
    label: "Direct risk",
    color: red.directRiskFill
  },
  indirectRisk: {
    id: "indirectRisk",
    label: "Indirect risk",
    color: orange.indirectRiskFill
  },
  noRisk: {
    id: "noRisk",
    label: "No risk identified",
    color: grey.ghost
  }
};

export const MEDIA_SOURCE_COLORS = [
  "#8e70b8",
  "#33b8bf",
  "#6b6f1f",
  "#191102",
  "#61b232",
  "#4674ba",
  "#505f70",
  "#007c89",
  "#6a4394",
  "#338c50",
  "#9e5e22",
  "#005e30",
  "#005572"
];

export const NO_DATE_STRING = "No date";
export const UNDATED_FILTER_ID = "undated";