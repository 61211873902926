import React, { MutableRefObject, ReactNode } from "react";
import {
  InputAdornment,
  TextField,
  AutocompleteRenderInputParams,
  IconButton
} from "@mui/material";
import { grey } from "styles/colors";
import { family } from "styles/fonts";
import { Search } from "react-feather";
import ClearIcon from "@mui/icons-material/Clear";

import { classNameOverrides } from "./styles";

const StartAdornment = ({ baseAdornment }: { baseAdornment: ReactNode }) => {
  return (
    <>
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
      {baseAdornment}
    </>
  );
};

const EndAdornment = ({ onClick }: { onClick: () => void }) => {
  const styles = {
    boxShadow: "none",
    color: grey.dark,
    minWidth: "32px",
    padding: 0,
    width: "32px",
    height: "32px",
    borderRadius: "100%"
  };

  return (
    <InputAdornment position="end">
      <IconButton style={styles} onClick={onClick}>
        <ClearIcon />
      </IconButton>
    </InputAdornment>
  );
};

const styles = {
  input: {
    letterSpacing: "normal",
    fontFamily: family.interRegular,
    "&::placeholder": {
      opacity: 1,
      color: grey.mid
    }
  },
  "& label.Mui-focused": { color: "#ffffff" },
  "& .MuiOutlinedInput-root": {
    padding: "0 !important",
    "&.Mui-focused fieldset": { border: 0 }
  },
  "& .MuiOutlinedInput-notchedOutline": { border: 0 }
};

const InputTextField = (
  params: AutocompleteRenderInputParams & {
    placeholder?: string;
    onSubmit: () => void;
    onClear: () => void;
    showClearButton: boolean;
    inputRef?: MutableRefObject<HTMLElement | undefined>;
  }
) => {
  const {
    InputProps,
    placeholder,
    onSubmit,
    onClear,
    showClearButton,
    inputRef,
    ...rest
  } = params;
  const { startAdornment } = InputProps;
  return (
    <TextField
      {...rest}
      placeholder={placeholder}
      InputProps={{
        ...InputProps,
        startAdornment: <StartAdornment baseAdornment={startAdornment} />,
        endAdornment: showClearButton && <EndAdornment onClick={onClear} />
      }}
      sx={styles}
      onKeyDown={e => e.code === "Enter" && onSubmit()}
      inputRef={inputRef}
      type="search"
      className={classNameOverrides.textField}
    />
  );
};

export default InputTextField;
