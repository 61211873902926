import React, { CSSProperties, FC, MouseEventHandler, ReactNode } from "react";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  name: string;
  children: ReactNode;
  onClick?: MouseEventHandler;
  isSelected?: boolean;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
}

const SwitchItem: FC<Props> = ({
  name,
  children,
  onClick,
  isSelected,
  disabled,
  className,
  style
}) => {
  return (
    <S.Item
      name={name}
      onClick={onClick}
      isSelected={isSelected}
      disabled={disabled}
      className={className}
      style={style}
      boxShadowColor={theme.button?.alternativeBoxShadowColor}
    >
      {children}
    </S.Item>
  );
};

export default SwitchItem;
