import React, { CSSProperties } from "react";

import { ReactComponent as AvatarPlaceholder } from "img/icons/person.svg";

import S from "./styles";

interface Props {
  text: string;
  selected: boolean;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: CSSProperties;
}

const ProfileButton = ({
  text,
  selected,
  disabled,
  onClick,
  className,
  style
}: Props) => {
  return (
    <S.ProfileButton
      selected={selected}
      disabled={disabled}
      onClick={onClick}
      className={className}
      style={style}
    >
      <S.ProfileButtonContent>
        <S.CustomAvatar>
          <AvatarPlaceholder width={24} height={24} />
        </S.CustomAvatar>
        <S.ButtonText>{text}</S.ButtonText>
      </S.ProfileButtonContent>
      <S.ChevronDown role="presentation" />
    </S.ProfileButton>
  );
};

export default ProfileButton;
