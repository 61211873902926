import { styled } from "@linaria/react";

const Assertable = styled.div`
  border: 1px solid orange;
  border-radius: 5px;
  display: flex;
`;

const S = {
  Assertable
};

export default S;
