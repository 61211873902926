import React, { FC } from "react";

import S from "./styles";

export interface TimelineTooltipProps {
  riskColor: string;
  significance: string;
  isRight: boolean;
  above: boolean;
}

const getContent = (significance: string) => {
  switch (significance) {
    case "Direct":
      return {
        title: "Direct Risk",
        description: "A risk that involves your subject"
      };
    case "Indirect":
      return {
        title: "Indirect Risk",
        description: "A risk that involves a connection of your subject"
      };
    default:
      return {
        title: "No Risk",
        description: "No identified risk"
      };
  }
};

const TimelineTooltip: FC<TimelineTooltipProps> = ({
  riskColor,
  significance,
  isRight,
  above
}) => {
  const { title, description } = getContent(significance);

  return (
    <S.Tooltip isRight={isRight} above={above}>
      <S.TooltipContent>
        <S.TooltipHeader>
          <S.TooltipColorBox backgroundColor={riskColor} />
          <S.TooltipTitle>{title}</S.TooltipTitle>
        </S.TooltipHeader>
        <S.TooltipDescription>{description}</S.TooltipDescription>
      </S.TooltipContent>
      <S.TooltipPoint isRight={isRight} above={above} />
    </S.Tooltip>
  );
};

export default TimelineTooltip;
