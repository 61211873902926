import React, { ReactNode, FC, useState, useContext } from "react";

import {
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection
} from "api/insights";

import {
  InsightReportActions,
  useInsightReport
} from "util/hooks/useInsightReport";

import RemoveSectionModal from "components/organisms/InsightReport/RemoveSectionModal";
import ProvideFeedbackModal from "components/organisms/InsightReport/ProvideFeedbackModal";
import ActionToolBar, { SectionType } from "components/molecules/ActionToolBar";
import MoveSectionModal from "components/molecules/ActionToolBar/MoveSectionModal";

import { copyToClipboard } from "components/organisms/InsightReport/utils";

import DisabledInsightsModal from "components/molecules/DisabledInsightsModal";
import { isPDX } from "static-config";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import usePdxDiagnosticsMode from "util/hooks/usePdxDiagnosticsMode";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import S from "./styles";

interface Props {
  id: string;
  subject?: string;
  children: ReactNode;
  richText: string;
  plainText: string;
  sectionTitle?: ReactNode;
  sanitizedChildren: ReactNode;
  onEdit: () => void;
}

const getSectionBySubsectionId = (id: string, rawReport: RawReport) => {
  return rawReport.sections.find(s => s.subsections.find(sub => sub.id === id));
};

const getSectionByElementId = (id: string, rawReport: RawReport) => {
  return rawReport.sections.find(s =>
    s.subsections.find(sub => sub.elements.find(element => element.id === id))
  );
};

const getSubsectionBySubsectionId = (id: string, section: RawSection) => {
  return section.subsections.find(sub => sub.id === id);
};

const getSubsectionByElementId = (id: string, section: RawSection) => {
  return section.subsections.find(sub =>
    sub.elements.find(element => element.id === id)
  );
};

const getElementBySubSection = (id: string, subSection: RawSubsection) => {
  return subSection.elements.find(element => element.id === id);
};

const canMoveSubsection = (
  sectionType: SectionType,
  status: InsightReportStatus,
  subsectionId?: string,
  section?: RawSection
) => {
  if (
    status === InsightReportStatus.reorderingSubSections ||
    status === InsightReportStatus.reorderingSubSectionsSuccess
  ) {
    return [false, false];
  }

  if (!subsectionId || !section || sectionType !== SectionType.Subsection) {
    return [true, true];
  }

  const sectionIndex = section.subsections.findIndex(
    subsection => subsection.id === subsectionId
  );
  const isFirst = sectionIndex === 0;
  const isLast = sectionIndex === section.subsections.length - 1;

  return [!isFirst, !isLast];
};

const WithContextMenu: FC<Props> = ({
  id,
  subject,
  children,
  richText,
  plainText,
  sectionTitle,
  sanitizedChildren,
  onEdit
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isRemovingSection, setIsRemovingSection] = useState(false);
  const [isProvidingFeedback, setIsProvidingFeedback] = useState(false);
  const [isMovingSection, setIsMovingSection] = useState(false);
  const {
    state: { rawReport, status },
    dispatch
  } = useInsightReport();
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const { enabled: pdxDiagnosticsEnabled } = usePdxDiagnosticsMode();
  const [disabledModalTitle, setDisabledModalTitle] = useState<string>("This");
  const [isDisabledInsightsModalShowing, setIsDisabledInsightsModalShowing] =
    useState(false);

  if (!rawReport) return null;

  const showDisabledInsightsModal = (title: string) => {
    setDisabledModalTitle(title);
    setIsDisabledInsightsModalShowing(true);
  };

  const section =
    getSectionBySubsectionId(id, rawReport) ??
    getSectionByElementId(id, rawReport);

  let subSection = section
    ? getSubsectionBySubsectionId(id, section)
    : undefined;

  if (section && !subSection) {
    subSection = getSubsectionByElementId(id, section);
  }

  const element =
    section && subSection ? getElementBySubSection(id, subSection) : undefined;

  const getSectionType = () => {
    if (!subSection && !element) {
      return SectionType.Section;
    }
    if (element) {
      return SectionType.Element;
    }
    return SectionType.Subsection;
  };

  const sectionID = section ? section.id : id;

  const subSectionID = subSection ? subSection.id : undefined;

  const onMoveSection = (isUp: boolean) => {
    if (!section) return;
    if (!subSection) return;

    dispatch({
      type: InsightReportActions.reorderSubSections,
      sectionId: sectionID,
      subsectionId: subSection.id,
      direction: isUp ? "up" : "down"
    });
  };

  const onCopy = async () => {
    setIsCopied(true);
    copyToClipboard({ richText, plainText });
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const onMoveToAnotherSection = () => {
    setIsMovingSection(prev => !prev);
  };

  const onRemove = () => {
    setIsRemovingSection(prev => !prev);
  };

  const onProvideFeedback = () => {
    setIsProvidingFeedback(prev => !prev);
  };

  const feedbackContext = [
    { key: "Report", value: subject },
    { key: "Section", value: sectionTitle },
    { key: "Sub-section", value: subSection?.title },
    { key: "Plain-text", value: plainText }
  ];

  const sectionType = getSectionType();
  const [canMoveUp, canMoveDown] = canMoveSubsection(
    sectionType,
    status,
    subSectionID,
    section
  );

  return (
    <S.ContextMenuContainer aria-label="Insights Content Container">
      <S.ContextMenuControl
        aria-label="Insights Toolbar"
        onMouseLeave={() => setIsDisabledInsightsModalShowing(false)}
      >
        <ActionToolBar
          sectionType={sectionType}
          isCopied={isCopied}
          onCopy={onCopy}
          onMoveSectionUp={canMoveUp ? () => onMoveSection(true) : undefined}
          onMoveSectionDown={
            canMoveDown ? () => onMoveSection(false) : undefined
          }
          onEdit={onEdit}
          onMoveToAnotherSection={onMoveToAnotherSection}
          onProvideFeedback={onProvideFeedback}
          onRemove={onRemove}
          onClickOveride={showDisabledInsightsModal}
        />
        <S.ModalWrapper>
          {isDisabledInsightsModalShowing &&
            isPDX &&
            !diagnosticsModeEnabled &&
            !pdxDiagnosticsEnabled && (
              <DisabledInsightsModal
                title={disabledModalTitle}
                onClose={() => setIsDisabledInsightsModalShowing(false)}
              />
            )}
        </S.ModalWrapper>
      </S.ContextMenuControl>

      {children}

      <ProvideFeedbackModal
        isOpen={isProvidingFeedback}
        onToggle={onProvideFeedback}
        context={feedbackContext}
      />

      <MoveSectionModal
        isOpen={isMovingSection}
        onToggle={onMoveToAnotherSection}
        sectionId={sectionID}
        subSectionId={subSectionID}
      >
        {sanitizedChildren}
      </MoveSectionModal>

      <RemoveSectionModal
        isOpen={isRemovingSection}
        onToggle={onRemove}
        sectionId={sectionID}
        subSectionId={subSectionID}
        elementId={element?.id}
      >
        {sanitizedChildren}
      </RemoveSectionModal>
    </S.ContextMenuContainer>
  );
};

export default WithContextMenu;
