import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ChevronRight } from "react-feather";

import {
  CollectionListActions,
  CollectionListContextProvider,
  useCollectionList
} from "util/hooks/useCollectionList";

import CollectionList from "components/organisms/CollectionList";
import CollectionNavigation from "components/molecules/CollectionNavigation";
import ReportCard from "components/organisms/ReportCard";

import Api, { ORGANISATION_USER_REPORTS_COLLECTION } from "api/hub/reports";
import {
  CollectionInputType,
  CollectionStatus
} from "util/hooks/useCollectionList/types";

import S from "./styles";

const UserReportsListing = () => {
  const { state, dispatch } = useCollectionList();
  const navigate = useNavigate();
  const { userId, orgId } = useParams();
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get("orgName") ?? "Organisation";
  const userName = searchParams.get("userName") ?? "Person";

  useEffect(() => {
    if (userId) {
      const collectionInputConfig = state.collections.find(
        collection => collection.id === ORGANISATION_USER_REPORTS_COLLECTION
      );

      if (
        collectionInputConfig &&
        collectionInputConfig.status === CollectionStatus.stale &&
        collectionInputConfig.input.type === CollectionInputType.list &&
        collectionInputConfig.input?.filterByUserId === undefined
      ) {
        setTimeout(
          () =>
            dispatch({
              type: CollectionListActions.updateCollectionInput,
              id: ORGANISATION_USER_REPORTS_COLLECTION,
              input: {
                ...collectionInputConfig.input,
                filterByUserId: userId
              }
            }),
          100
        );
      }
    }
  }, [userId, state.collections, dispatch]);

  return (
    <AnimatePresence>
      <S.ViewContainer>
        <S.ViewInnerContainer>
          <S.Breadcrumbs>
            <S.Breadcrumb
              onClick={() => {
                navigate(`/hub/manage-organisation/${orgId}`);
              }}
            >
              {orgName}
            </S.Breadcrumb>
            <ChevronRight />
            <S.Breadcrumb
              onClick={() => {
                navigate(
                  `/hub/manage-organisation/${orgId}/users?orgName=${orgName}`
                );
              }}
            >
              Users
            </S.Breadcrumb>
            <ChevronRight />
            <S.Breadcrumb
              onClick={() => {
                navigate(
                  `/hub/manage-organisation/${orgId}/users/${userId}/reports?orgName=${orgName}&userName=${userName}`
                );
              }}
            >
              {userName}
            </S.Breadcrumb>
          </S.Breadcrumbs>
          <CollectionList
            showCount
            CardComponent={ReportCard}
            emptyCollectionText="No reports"
            navigationComponent={
              <CollectionNavigation
                items={[
                  {
                    title: userName ?? "Person",
                    onClick: () => {},
                    isActive: true
                  }
                ]}
              />
            }
          />
        </S.ViewInnerContainer>
      </S.ViewContainer>
    </AnimatePresence>
  );
};

const OrganisationUserReportsList = () => {
  return (
    <CollectionListContextProvider Api={Api}>
      <UserReportsListing />
    </CollectionListContextProvider>
  );
};

export default OrganisationUserReportsList;
