import React, {
  useRef,
  useEffect,
  ChangeEventHandler,
  CSSProperties
} from "react";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  checked: boolean;
  indeterminate?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  label: string;
  checkedColor?: string;
  style?: CSSProperties;
  className?: string;
}

const Checkbox = ({
  checked,
  indeterminate,
  onChange,
  readOnly,
  label,
  checkedColor = theme.primaryColor,
  style,
  className
}: Props) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const checkbox = checkboxRef.current;
    if (checkbox && indeterminate !== undefined) {
      checkbox.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <S.CheckboxContainer style={style} className={className}>
      <S.CheckboxElementContainer checked={checked} checkedColor={checkedColor}>
        <S.CheckboxElement
          type="checkbox"
          id="checkbox"
          ref={checkboxRef}
          checked={checked}
          onChange={onChange}
          readOnly={readOnly}
        />
        <S.CheckIconWrapper>
          <S.CheckIcon checked={checked} />
        </S.CheckIconWrapper>
        <S.CheckState indeterminate={indeterminate}>
          <S.CheckIndeterminate />
        </S.CheckState>
      </S.CheckboxElementContainer>
      <label htmlFor="checkbox">{label}</label>
    </S.CheckboxContainer>
  );
};

export default Checkbox;
