import Api from "api/tenant";
import { GroupReportRole } from "api/tenant/types";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

const useCopyReport = () => {
  const [{ fetching: loading, error, errorMessage }, dispatch] =
    useFetchReducer();

  const TenantApi = new Api();

  const sendCopyOfReport = async ({
    orgId,
    reportId,
    newOwnerId,
    newGroupId,
    newGroupAccessPermission,
    notifyOwner
  }: {
    orgId: string;
    reportId: string;
    newOwnerId: string;
    newGroupId?: string;
    newGroupAccessPermission?: GroupReportRole;
    notifyOwner?: boolean;
  }) => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await TenantApi.sendCopyOfReport({
      orgId,
      reportId,
      newOwnerId,
      newGroupId,
      newGroupAccessPermission,
      notifyOwner
    });

    if (!status) {
      dispatch({
        type: RequestActions.SetError,
        errorMessage: message
      });
    } else {
      dispatch({ type: RequestActions.SetSuccess });
    }

    return status;
  };

  return { sendCopyOfReport, loading, error, errorMessage };
};

export default useCopyReport;
