import React, { memo } from "react";
import { Collapse } from "reactstrap";
import useObservability from "util/hooks/useObservability";

import { InnerSectionHeader } from "pages/report/InnerSectionHeader";
import { showPartialDate } from "pages/report/showPartialDate";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import MediaSourceCard from "components/molecules/MediaSourceCard";

import { SourceStack } from "./SourceStack";

const SourceGroupContents = memo(props => {
  const {
    isDisregarded,
    sourceStacks,
    sources,
    showAssessment,
    isInSnapshot,
    activeRiskCategoryFilters,
    activeRiskDirectionality
  } = props;

  const byId = new Map();

  (sourceStacks && sourceStacks.length ? sourceStacks : sources)
    .filter(sourceStackOrSource => {
      if (sourceStackOrSource) {
        return true;
      }
      console.error("Null source");
      return false;
    })
    .forEach(sourceStackOrSource => {
      const sourceId =
        sourceStackOrSource.sourceId ||
        (sourceStackOrSource.sources[0] &&
          sourceStackOrSource.sources[0].sourceId);
      if (!byId.has(sourceId)) {
        byId.set(sourceId, sourceStackOrSource);
      } else {
        console.warn(
          "Duplicate source id present",
          sourceId,
          sourceStackOrSource
        );
      }
    });

  const distinctSources = [...byId.values()];

  if (!distinctSources.length) {
    console.error("Empty group");
    return "";
  }

  return (
    <div className={`source-group-contents  ${isInSnapshot && "media-group"}`}>
      {distinctSources.map(distinctSource => {
        if (distinctSource.sourceId) {
          return (
            <MediaSourceCard
              key={distinctSource.sourceId}
              {...distinctSource}
              {...{ showAssessment }}
              isInSnapshot={isInSnapshot}
              activeRiskCategoryFilters={activeRiskCategoryFilters}
              activeRiskDirectionality={activeRiskDirectionality}
            />
          );
        }
        return (
          <SourceStack
            isDisregarded={isDisregarded}
            key={
              distinctSource.sources &&
              distinctSource.sources
                .filter(source => source.sourceId)
                .map(source => source.sourceId)
                .join("+")
            }
            {...distinctSource}
            {...{ showAssessment }}
            isInSnapshot={isInSnapshot}
            activeRiskCategoryFilters={activeRiskCategoryFilters}
            activeRiskDirectionality={activeRiskDirectionality}
          />
        );
      })}
    </div>
  );
});

export const SourceGroup = memo(props => {
  const { captureError } = useObservability();
  const {
    isDisregarded,
    title,
    sources: optionalSources,
    summarySnippet,
    earliestDate,
    latestDate,
    summaryImage,
    timeSpan,
    sourceStacks,
    show,
    showAssessment,
    isInSnapshot,
    activeRiskCategoryFilters,
    activeRiskDirectionality
  } = props;
  const sourceStackSources = (sourceStacks || []).flatMap(
    stack => stack.sources
  );
  const sources = optionalSources || sourceStackSources;
  const key = (sources && sources.length ? sources : sourceStackSources)
    .map(s => s.sourceId)
    .join("+");
  const [isExpanded, setExpanded] = usePrintableReportState(
    `source-group-${key}-expanded`,
    !summarySnippet
  );
  const toggleExpanded = () => setExpanded(!isExpanded);
  const title2 = title || `Source group`;
  const count =
    (sources && sources.length) ||
    sourceStackSources.flatMap(stack => stack.sources).length;
  const datesPresent = earliestDate && latestDate && timeSpan;
  try {
    const earliestDateDisplay = datesPresent && showPartialDate(earliestDate);
    const latestDateDisplay = datesPresent && showPartialDate(latestDate);

    return (
      <div className="source-group" style={{ display: show ? "" : "none" }}>
        <InnerSectionHeader
          {...{ title: title2, toggleExpanded, isExpanded, count }}
        />
        {summarySnippet ? (
          <div className="source-group-summary">
            <div className="source-group-summary-snippet">
              {summarySnippet}
              {datesPresent ? (
                <div className="source-group-summary-dates">
                  <small>
                    {earliestDateDisplay === latestDateDisplay
                      ? earliestDateDisplay
                      : `${earliestDateDisplay} - ${latestDateDisplay} (${timeSpan.shortDisplay})`}
                  </small>
                </div>
              ) : (
                ""
              )}
            </div>
            {summaryImage && summaryImage.src ? (
              <a
                className="source-group-summary-image"
                rel="noopener noreferrer"
                href={summaryImage.originUrl}
              >
                <img src={summaryImage.src} alt="Source" />
              </a>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <Collapse isOpen={isExpanded}>
          <SourceGroupContents
            {...{ sources, showAssessment }}
            isDisregarded={isDisregarded}
            isInSnapshot={isInSnapshot}
            activeRiskCategoryFilters={activeRiskCategoryFilters}
            activeRiskDirectionality={activeRiskDirectionality}
          />
        </Collapse>
        <hr className="source-group-end" />
      </div>
    );
  } catch (e) {
    captureError(e);
    console.error("Could not show source group due to an error:", e);
    return (
      <div className="source-group">
        Could not show source group due to an error
      </div>
    );
  }
});
