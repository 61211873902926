import { styled } from "@linaria/react";

import { grey } from "styles/colors";

import { ReactComponent as PersonSvg } from "img/person.svg";
import { ReactComponent as BuildingSvg } from "img/building.svg";

const RelationshipHeader = styled.div`
  color: ${grey.mid};
  display: flex;
  align-items: center;
`;

const PersonRelationshipCount = styled.div`
  margin-right: 20px;
`;

const relationshipIconStyles = {
  width: "19px",
  height: "19px",
  marginTop: "-5px",
  marginRight: "6px"
};

const PersonIcon = styled(PersonSvg)`
  ${relationshipIconStyles};
  path {
    fill: ${grey.ghost};
    transform: scale(0.75);
  }
`;

const OrgIcon = styled(BuildingSvg)`
  ${relationshipIconStyles};
  path {
    fill: ${grey.ghost};
    transform: scale(0.7);
  }
`;

const S = {
  RelationshipHeader,
  PersonRelationshipCount,
  PersonIcon,
  OrgIcon
};

export default S;
