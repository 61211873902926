import {
  InsightReportStatus,
  InsightReportState
} from "util/hooks/useInsightReport/types";

export const getHasBeenReordered = ({
  nodeType,
  state,
  id
}: {
  nodeType: string;
  state: InsightReportState;
  id: string;
}) => {
  if (nodeType !== "div") return false;

  if (state.status !== InsightReportStatus.reorderingSubSectionsSuccess)
    return false;

  if (state.reorderingSubsectionId !== id) return false;

  return true;
};
