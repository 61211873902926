import React from "react";

import { CollectionListView } from "components/molecules/CollectionListControls";

import { collectionCardComponents } from "util/collectionCardComponents";

const CardSkeleton = ({ view }: { view: CollectionListView }) => {
  const { C } = collectionCardComponents[view];

  return (
    <C.Card loading>
      <C.CardHeader loading />
      <C.CardBody loading />
      <C.CardFooter loading />
    </C.Card>
  );
};

export default CardSkeleton;
