import { createContext } from "react";

import type { HubAuthenticationState, HubAuthenticationAction } from "./types";
import { HubAuthenticationActions, HubAuthenticationStatus } from "./types";

export const initialState: HubAuthenticationState = {
  email: "",
  password: "",
  mfaCode: "",
  status: HubAuthenticationStatus.unknown,
  jwtPayload: undefined,
  nextSignInStep: ""
};

export const hubAuthenticationReducer = (
  state: HubAuthenticationState,
  action: HubAuthenticationAction
) => {
  switch (action.type) {
    case HubAuthenticationActions.verifyPassword: {
      return { ...state, status: HubAuthenticationStatus.verifyingPassword };
    }
    case HubAuthenticationActions.passwordVerified: {
      return {
        ...state,
        status: HubAuthenticationStatus.passwordVerified,
        nextSignInStep: action.nextSignInStep,
        getSetupUri: action.getSetupUri
      };
    }
    case HubAuthenticationActions.updateEmail: {
      return { ...state, email: action.email };
    }
    case HubAuthenticationActions.updatePassword: {
      return { ...state, password: action.password };
    }
    case HubAuthenticationActions.updateMfaCode: {
      return { ...state, mfaCode: action.mfaCode };
    }
    case HubAuthenticationActions.authenticate: {
      return { ...state, status: HubAuthenticationStatus.authenticating };
    }
    case HubAuthenticationActions.authenticated: {
      return {
        ...state,
        status: HubAuthenticationStatus.authenticated,
        mfaCode: "",
        password: "",
        email: "",
        jwtPayload: action.jwtPayload,
        nextSignInStep: undefined
      };
    }
    case HubAuthenticationActions.unauthenticated: {
      return {
        ...state,
        status: HubAuthenticationStatus.unauthenticated,
        mfaCode: "",
        error: action.error
      };
    }
    case HubAuthenticationActions.dismissError: {
      return {
        ...state,
        error: undefined
      };
    }
    case HubAuthenticationActions.mfaSetup: {
      return {
        ...state,
        status: HubAuthenticationStatus.mfaSetup
      };
    }
    default: {
      return state;
    }
  }
};

export const HubAuthenticationContext = createContext({
  state: initialState,
  dispatch: (_action: HubAuthenticationAction) => {}
});
