import Notes from "api/notes/Notes";
import { makeObservable, observable, action } from "mobx";

class NotesStore {
  constructor() {
    this.notesApi = new Notes();
    this.error = false;
    this.setError = this.setError.bind(this);
    this.isSaving = false;
    this.setIsSaving = this.setIsSaving.bind(this);

    makeObservable(this, {
      isSaving: observable,
      setIsSaving: action,
      error: observable,
      setError: action
    });
  }

  setIsSaving(value) {
    this.isSaving = value;
  }

  setError(error) {
    this.error = error;
  }

  async getUserNotes(enquiryId) {
    // Reset error
    this.setError(false);
    const response = await this.notesApi.getUserNotes(enquiryId);

    if (response?.hasErrored) {
      this.setError(true);
    }

    return await response.ReportNotes;
  }

  async setUserNotes(enquiryId, userNotes) {
    // Reset error
    this.setError(false);

    this.setIsSaving(true);
    const response = await this.notesApi.setUserNotes(enquiryId, userNotes);
    this.setIsSaving(false);

    if (response?.hasErrored) {
      this.setError(true);
      return false;
    }

    return true;
  }
}

export default NotesStore;
