import React, { useState, useEffect } from "react";

import TabBar from "components/molecules/TabBar";

import S, { classNameOverrides } from "./styles";

const ModalWithTabs = ({
  isModalOpen,
  setIsModalOpen,
  data,
  dataMap,
  ModalContent
}) => {
  const [tabs, setTabs] = useState([
    { id: data?.id, tabTitle: data?.name, tabData: data }
  ]);

  const [activeTab, setActiveTab] = useState(data?.id);

  useEffect(() => {
    setActiveTab(data?.id);
    setTabs([{ id: data?.id, tabTitle: data?.name, tabData: data }]);
  }, [data]);

  const createNewTab = tabConfig => {
    setActiveTab(tabConfig.id);

    let newTab = {
      id: tabConfig.id,
      tabTitle: tabConfig.title,
      tabData: dataMap[tabConfig.id]
    };

    if (!tabs.find(tab => tab.id === tabConfig.id)) {
      setTabs(prevTabs => [...prevTabs, newTab]);
    }
  };

  const onTabDelete = tabConfig => {
    const tabIndex = tabs.findIndex(tab => tab.id === tabConfig.id);
    let nextTabToFocus = tabIndex - 1; // Default to previous tab
    let newTabs = [...tabs.slice(0, tabIndex), ...tabs.slice(tabIndex + 1)];

    setActiveTab(tabs[nextTabToFocus].id);
    setTabs(newTabs);
  };

  const toggleModalOpen = () => {
    setIsModalOpen(prev => !prev);
    // Reset tabs
    setTabs([{ id: data?.id, tabTitle: data?.name, tabData: data }]);
    setActiveTab(data?.id);
  };

  return (
    <S.Modal
      size="xl"
      isOpen={isModalOpen}
      toggle={toggleModalOpen}
      scrollable
      contentClassName={classNameOverrides.modalContent}
    >
      <S.ModalHeader
        close={
          <S.ExitContainer onClick={toggleModalOpen}>
            <S.ExitModalIcon />
          </S.ExitContainer>
        }
      >
        <TabBar
          tabs={tabs}
          onTabDelete={onTabDelete}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </S.ModalHeader>
      <S.ModalBody>
        {tabs.map((tab, index) => (
          <ModalContent
            key={`${tab.tabTitle}_${index}`}
            data={tab.tabData ?? data}
            onCreateNewTab={createNewTab}
            dataMap={dataMap}
            isOnDisplay={activeTab === tab.id}
          />
        ))}
      </S.ModalBody>
    </S.Modal>
  );
};

export default ModalWithTabs;
