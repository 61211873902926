import React, { useState } from "react";

import MenuComponent from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import {
  HubAuthenticationStatus,
  HubAuthenticationActions
} from "util/hooks/useHubAuthentication/types";

import { HUB_ASSUMED_ROLE_TOKEN_KEY } from "util/hooks/useHubAdminRole/provider";
import { useHubAuthentication } from "util/hooks/useHubAuthentication";
import useHubAdminRole from "util/hooks/useHubAdminRole";

import { signOut } from "aws-amplify/auth";

import S from "./menuStyles";

const Menu = () => {
  const {
    state: { jwtPayload, status: authenticationStatus },
    dispatch
  } = useHubAuthentication();
  const { setIsAuthenticatedAsHubAdmin } = useHubAdminRole();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  if (!jwtPayload) return null;

  const { given_name: firstName, family_name: lastName } = jwtPayload;

  const onLogout = async () => {
    try {
      sessionStorage.removeItem(HUB_ASSUMED_ROLE_TOKEN_KEY);
      setIsAuthenticatedAsHubAdmin(false);
      await signOut();
      dispatch({ type: HubAuthenticationActions.unauthenticated });
      setIsMenuOpen(false);
      // @ts-ignore
      if (window.productFruits && window.productFruits.services) {
        // @ts-ignore
        window.productFruits.services.destroy();
      }
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <S.Popover
      interactive
      // @ts-ignore
      isOpenOverride={isMenuOpen}
      // @ts-ignore
      onRequestClose={() => setIsMenuOpen(false)}
      hideArrow
      alignment="bottom-start"
      content={
        <S.Wrapper>
          <S.Header>
            <S.HeaderLeft>
              <S.UserName>
                {firstName} {lastName}
              </S.UserName>
            </S.HeaderLeft>
            <S.AdminLabel>Admin</S.AdminLabel>
          </S.Header>
          <MenuComponent>
            {/*
              <MenuItem
                text="My Hub account"
                IconLeading={SettingsIcon}
                showSeparator
              />
            */}
            <MenuItem text="Log out" onMenuItemClick={onLogout} />
          </MenuComponent>
        </S.Wrapper>
      }
    >
      <S.PopoverButton
        text={
          authenticationStatus === HubAuthenticationStatus.unauthenticated ||
          !firstName
            ? "Your account"
            : `Hi, ${firstName}`
        }
        onClick={() => setIsMenuOpen(prev => !prev)}
        selected={isMenuOpen}
        disabled={
          authenticationStatus !== HubAuthenticationStatus.authenticated
        }
      />
    </S.Popover>
  );
};

export default Menu;
