import React, { useContext } from "react";

import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import WithInspector from "components/organisms/WithInspector";
import Assertion from "components/atoms/Assertion";
import { AssertionModeContext } from "util/context/AssertionModeContext";
import { AssertionState } from "util/hooks/useAssertionsContext";

import S from "./styles.js";

const AssertionSummary = ({ assertions }) => {
  const assertionModeEnabled = useContext(AssertionModeContext).enabled;
  // AssertionState.BeingDeleted is in both lists so that when an Assertion is deleted, it turns yellow in the list
  // before disappearing. You can never view both lists at the same time, so this is okay
  const passedAssertions = assertions.filter(
    assertion =>
      assertion.state === AssertionState.HoldsTrue ||
      assertion.state === AssertionState.BeingDeleted
  );
  const failedAssertions = assertions.filter(
    assertion =>
      assertion.state === AssertionState.Violated ||
      assertion.state === AssertionState.BeingDeleted
  );

  return assertionModeEnabled ? (
    <S.AssertionSummary>
      <WithInspector
        customContent={passedAssertions.map(assertion => (
          <Assertion assertion={assertion}>
            {assertion.AssertionHumanReadable}
          </Assertion>
        ))}
        dataFormat={INSPECTOR_DATA_FORMATS.custom}
        popoverTitle={"Passed Assertions"}
        display={"inline-block"}
      >
        <S.PassedPill>{`PASSED: ${passedAssertions.length}`}</S.PassedPill>
      </WithInspector>
      <WithInspector
        customContent={failedAssertions.map(assertion => (
          <Assertion assertion={assertion}>
            {assertion.AssertionHumanReadable}
          </Assertion>
        ))}
        dataFormat={INSPECTOR_DATA_FORMATS.custom}
        popoverTitle={"Failed Assertions"}
        display={"inline-block"}
      >
        <S.FailedPill>{`FAILED: ${failedAssertions.length}`}</S.FailedPill>
      </WithInspector>
    </S.AssertionSummary>
  ) : null;
};

export default AssertionSummary;
