import React, { FC } from "react";
import type { UboReportCountry } from "api/ubo-reports";

import ReactTooltip from "components/atoms/ReactTooltip";

import Flag from "components/atoms/Flag";

import S from "./styles";

type UBOCountryCircleProps = {
  countries: UboReportCountry[];
};

const FLAGS_TO_SHOW = 3;

const UBOCountryCircles: FC<UBOCountryCircleProps> = ({ countries }) => {
  if (countries.length === 0) {
    return <S.Container>None Identified</S.Container>;
  }

  const overLapCountries = countries.slice(1, FLAGS_TO_SHOW);

  const extraFlags = countries.slice(FLAGS_TO_SHOW);

  const extraFlagsString = extraFlags.map(flag => flag.name).join(", ");

  const firstCountry = countries[0];

  return (
    <S.Container>
      <S.FlagContainer>
        <Flag name={firstCountry.name} code={firstCountry.iso2} showAsCircle />
      </S.FlagContainer>
      {overLapCountries.map(({ iso2, name }) => (
        <S.FlagOverLapContainer key={`FlagOverlapContainer-${iso2}-${name}`}>
          <Flag name={name} code={iso2} showAsCircle />
        </S.FlagOverLapContainer>
      ))}
      {extraFlags.length > 0 && (
        <ReactTooltip tooltip={extraFlagsString}>
          <S.ExtraFlagsCount>
            <span>+{extraFlags.length}</span>
          </S.ExtraFlagsCount>
        </ReactTooltip>
      )}
    </S.Container>
  );
};

export default UBOCountryCircles;
