import type { RefObject, ForwardedRef } from "react";
import dayjs from "dayjs";
import { PartialDate } from "api/report/report-types";

export const generateUniqueId = (keyIndex = 0) => {
  const uniqueId = `${
    Math.floor(Math.random() * 10000) + Date.now()
  }_${keyIndex}`;
  return uniqueId;
};

export const sanitiseDisplayData = (
  dataToDisplay:
    | number
    | string
    | string[]
    | Record<string, string>
    | undefined
    | null
): string | number | string[] | undefined[] | undefined => {
  if (!dataToDisplay) {
    return "";
  }
  const stanitizeItem = (item: number | string | undefined) => {
    return item &&
      item !== "" &&
      item !== undefined &&
      item !== "undefined" &&
      item !== null
      ? item
      : "";
  };

  if (typeof dataToDisplay === "object" && dataToDisplay.length === undefined) {
    console.error("Object when string or number (or list thereof) expected");
    return undefined;
  }

  if (
    typeof dataToDisplay === "object" &&
    Array.isArray(dataToDisplay) &&
    dataToDisplay?.length >= 0
  ) {
    return (dataToDisplay as (string | undefined)[]).map(item =>
      sanitiseDisplayData(item)
    ) as string[];
  }

  return stanitizeItem(dataToDisplay as string | number);
};

export const scrollToTopOfComponent = (
  ref: ForwardedRef<any>,
  callFromMenu = false
) => {
  const offset = 54; // set this to the height (in px) of the persistant sticky header at top
  const element =
    ref && "current" in ref && ref.current instanceof HTMLElement
      ? ref.current
      : null;

  if ((element && element.getBoundingClientRect().top < 0) || callFromMenu) {
    window.scrollTo({
      top: (element?.offsetTop ?? 0) - offset,
      left: 0,
      behavior: "auto"
    });
  }
};

export const scrollToBottomOfComponent = (ref: RefObject<HTMLElement>) => {
  const offset = 32; // set this to the height (in px) of the sticky header at top of section
  const componentHeight = ref.current?.getBoundingClientRect()?.height ?? 0;
  const newScrollPos = componentHeight + (ref.current?.offsetTop ?? 0) - offset;
  window.scrollTo({ top: newScrollPos, left: 0, behavior: "auto" });
};

export const formatDuration = (duration: {
  months?: string;
  years: string;
}) => {
  const months = duration.months ? duration.months : null;
  const years = parseInt(duration.years, 10) > 0 ? duration.years : null;
  const monthsLabel = (parseInt(months ?? "0", 10) ?? 0) > 1 ? "mos" : "mo";
  const yearsLabel = (parseInt(years ?? "0", 10) ?? 0) > 1 ? "yrs" : "yr";

  if (months && years)
    return ` (${years}${yearsLabel}, ${months}${monthsLabel})`;
  if (months) return ` (${months}${monthsLabel})`;
  if (years) return ` (${years}${yearsLabel})`;

  throw new Error(`Unexpected input ${JSON.stringify(duration)}`);
};

export const delay = (timeMs: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise(resolve => setTimeout(resolve, timeMs));

/**
 * Formats a date as 14 Jul 1979.
 * Can take as input a UTC string, or a "partial date"
 */
export const formatDate = (
  input: string | PartialDate,
  isUtc?: boolean
): string => {
  if (!input) {
    return "";
  }

  if (typeof input === "string") {
    try {
      if (isUtc) {
        return dayjs.utc(input.substring(0, 23)).local().format("D MMM YYYY");
      }

      return dayjs(input).local().format("D MMM YYYY");
    } catch (e) {
      console.error("Error formatting date", e);
      return input;
    }
  }

  return `${
    input?.day && input?.monthShort ? `${sanitiseDisplayData(input.day)} ` : ""
  }${
    input?.monthShort ? `${sanitiseDisplayData(input.monthShort)} ` : ""
  }${sanitiseDisplayData(input?.year)}`;
};

export const formatTime = (dateString: string, isUtc?: boolean): string => {
  if (isUtc) {
    return dayjs.utc(dateString.substring(0, 23)).local().format("HH:mm");
  }
  return dayjs(dateString).local().format("HH:mm");
};

export const formatTimePeriod = (
  start: string,
  end: string,
  duration: string
) =>
  `${formatDate(start)} ${start && end ? "-" : ""} ${formatDate(end)} ${
    duration ? `(${duration})` : ""
  }`;

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
export const sortBy = (
  arr: (string | number)[],
  keyFunc: (value: string | number) => number
) => [...arr].sort((a, b) => keyFunc(b) - keyFunc(a));

function makeCamelCase(key: string): string {
  return key.charAt(0).toLowerCase() + key.slice(1);
}

// @ts-ignore this function is a bit mad and probably not worth figuring out the types for
export const mutateToLowerCamelCase = (obj: any) => {
  if (obj === null || obj === undefined) return obj;
  if (obj instanceof Array) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const i in obj) {
      // Joseph - I think this should really be a for-of
      // eslint-disable-next-line no-param-reassign
      obj[i] = mutateToLowerCamelCase(obj[i]);
    }
  }
  if (
    typeof obj === "string" ||
    typeof obj === "number" ||
    typeof obj === "boolean"
  ) {
    return obj;
  }
  const keys = Object.keys(obj);
  let n = keys.length;
  let lowKey;
  // eslint-disable-next-line no-plusplus
  while (n--) {
    const key = keys[n];
    // eslint-disable-next-line no-cond-assign, no-continue
    if (key === (lowKey = makeCamelCase(key))) continue;
    // eslint-disable-next-line no-param-reassign
    obj[lowKey] = mutateToLowerCamelCase(obj[key]);
    // eslint-disable-next-line no-param-reassign
    delete obj[key];
  }
  return obj;
};
