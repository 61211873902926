/* eslint-disable max-len */
import React from "react";

import OrganisationOverview from "components/organisms/OrganisationOverview";

const ThemedOrganisationOverview = React.forwardRef(
  (props, organisationOverviewRef) => {
    const infographicLayoutTemplate = `
    "screening-risk-icons-title screening-risk-icons-title screening-risk-icons-title screening-risk-icons-title risk-icons-title risk-icons-title risk-icons-title risk-icons-title"
    "screening-risk-icons screening-risk-icons screening-risk-icons screening-risk-icons risk-icons risk-icons risk-icons risk-icons"
    `;

    return (
      <OrganisationOverview
        {...props}
        ref={organisationOverviewRef}
        displaySubjectImage={false}
        infographicLayoutTemplate={infographicLayoutTemplate}
      />
    );
  }
);

export default ThemedOrganisationOverview;
