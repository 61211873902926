import React, { useContext, useState } from "react";

import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import WithInspector from "components/organisms/WithInspector";
import { deduplicateSources } from "components/organisms/WithInspector/util";
import { ButtonKind } from "components/atoms/Button/types";
import { grey, red } from "styles/colors";
import { scrollToTopOfComponent } from "util/common";
import { InfographicStateContext } from "util/context/InfographicStateContext";
import ReactTooltip from "components/atoms/ReactTooltip";

import S from "./styles";

const InfoGraphic = ({
  RiskIcon,
  riskCount,
  title,
  inspectorSubtext,
  inspectorData = null,
  inspectorHeader,
  inspectorContent = null,
  sectionToJumpToRef,
  displayLinkToSection = true,
  linkToSectionText,
  tooltipExplainerText,
  additionalLabel,
  colourOverride,
  disabled
}) => {
  const [isInspectorOpen, setIsInspectorOpen] = useState(false);

  let riskCountDisplay = riskCount;

  if (!riskCount) {
    if (disabled) {
      riskCountDisplay = "–";
    } else {
      riskCountDisplay = 0;
    }
  }

  const {
    displayAdditionalLabel,
    setIsAnyInfographicInspectorOpen,
    setDisplayAdditionalLabel
  } = useContext(InfographicStateContext);

  const getRiskIconColor = () => {
    if (colourOverride) return colourOverride;

    if (riskCount) return grey.dark;

    return grey.mid;
  };

  const infoGraphicContent = (
    <ReactTooltip
      disabled={isInspectorOpen}
      delay={600}
      maxWidth="300px"
      tooltip={<div>{tooltipExplainerText}</div>}
    >
      <S.GraphicContainer
        ariaLabel={`Open ${title} summary`}
        color={colourOverride}
        isConfirmedEntriesPresent={riskCount}
      >
        <RiskIcon color={getRiskIconColor()} />
        <S.GraphicHeader>{riskCountDisplay}</S.GraphicHeader>
        <S.GraphicSubtext>
          <div>{title}</div>
          <S.AdditionalLabel
            isOnDisplay={displayAdditionalLabel || isInspectorOpen}
            isInspectorOpen={isInspectorOpen && riskCount}
          >
            {additionalLabel ?? null}
          </S.AdditionalLabel>
        </S.GraphicSubtext>
      </S.GraphicContainer>
    </ReactTooltip>
  );

  return (
    <WithInspector
      disabled={!riskCount} // Backwards compatability
      popoverTitle={inspectorHeader}
      dataFormat={
        inspectorData
          ? INSPECTOR_DATA_FORMATS.filterableList
          : INSPECTOR_DATA_FORMATS.custom
      }
      filterableSources={inspectorData ?? []}
      filterPillColor={red.directRisk}
      customContent={inspectorContent}
      isInspectorOpen={value => {
        setIsInspectorOpen(value);
        setIsAnyInfographicInspectorOpen(value);
        setDisplayAdditionalLabel(value);
      }}
      renderSourceItems={
        inspectorData
          ? (sources, selectedFilter, sourceContainerRef) => {
              const dedupedSources = deduplicateSources(sources);
              return (
                <S.SourceContainer ref={sourceContainerRef}>
                  {dedupedSources?.map((source, index) => (
                    <S.MediaSourceCard
                      {...source}
                      show
                      key={`${source.url}_${index}`}
                      activeRiskCategoryFilters={[[selectedFilter]]}
                      activeRiskDirectionality="direct"
                      filterOutIndirectTags
                      showAssessment
                      tagColor={red.directRisk}
                    />
                  ))}
                </S.SourceContainer>
              );
            }
          : null
      }
      isSingleSourceOrSingleFilterFilterable
      popoverSubtext={
        <div>
          {inspectorSubtext}{" "}
          {displayLinkToSection ? (
            <S.SectionLinkText>
              For full details, go to the{" "}
              <S.SectionLinkButton
                kind={ButtonKind.tertiary}
                onClick={() => scrollToTopOfComponent(sectionToJumpToRef, true)}
              >
                {linkToSectionText ?? "Web and Media section"}
              </S.SectionLinkButton>
              .
            </S.SectionLinkText>
          ) : null}
        </div>
      }
      defaultSelectFirstFilter
    >
      {infoGraphicContent}
    </WithInspector>
  );
};

export default InfoGraphic;
