// TODO DJ
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useLayoutEffect, useRef, useState } from "react";

import S from "./styles";

const StickyExpandButton = ({
  additionalText,
  isReportRegenerationOpen,
  isResultsExpanded,
  onToggleExpandResultsSection,
  resultsSectionRef,
  shouldShowButtonCallback = () => {},
  hideDividingLines = true,
  style,
  className
}) => {
  const expandButtonContainerRef = useRef(null);
  const [shouldShowButton, setShouldShowButton] = useState(false);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      // Safari implements `borderBoxSize` as a single content rect, rather than an array.
      // TODO DJ
      // eslint-disable-next-line no-prototype-builtins, prefer-const
      let boxHeight = entries[0].hasOwnProperty("borderBoxSize")
        ? entries[0]?.borderBoxSize[0]?.blockSize
        : entries[0]?.contentRect?.height;
      const showButton =
        boxHeight &&
        (isResultsExpanded ||
          entries[0]?.target?.scrollHeight > entries[0]?.target?.clientHeight);
      setShouldShowButton(showButton);
      shouldShowButtonCallback(showButton);
    });

    if (resultsSectionRef.current) {
      resizeObserver.observe(resultsSectionRef.current);
    }

    return () => {
      if (resultsSectionRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(resultsSectionRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResultsExpanded]);

  return shouldShowButton ? (
    <S.ExpandButtonContainer
      ref={expandButtonContainerRef}
      isReportRegenerationOpen={isReportRegenerationOpen}
      style={style}
      className={className}
    >
      {!hideDividingLines && <S.DetailButtonDividerLine />}
      <S.ExpandResultsButton
        onClick={onToggleExpandResultsSection}
        additionalText
      >
        <S.ChevronDown className={isResultsExpanded ? "isExpanded" : ""} />
        {isResultsExpanded ? "Collapse" : "Expand"}
        {additionalText && ` ${additionalText}`}
        <S.ChevronDown className={isResultsExpanded ? "isExpanded" : ""} />
      </S.ExpandResultsButton>
      {!hideDividingLines && <S.DetailButtonDividerLine />}
    </S.ExpandButtonContainer>
  ) : null;
};

export default StickyExpandButton;
