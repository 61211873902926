import { styled } from "@linaria/react";
import { blue, grey } from "styles/colors";

const ModalActions = styled.div`
  display: flex;
  gap: 0.75rem;

  & > button {
    flex: 1;
  }
`;

const ModalText = styled.p`
  margin: 0;
  font-weight: 700;

  & > span {
    color: ${blue.xapienLight};
  }
`;

const InviteCard = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0.625rem;
  color: ${grey.rule};
  background-color: ${blue.xapienDark};
  width: fit-content;
  border-radius: 0.25rem;

  & > svg {
    height: 1.5rem;
  }
`;

const S = {
  ModalActions,
  ModalText,
  InviteCard
};

export default S;
