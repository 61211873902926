import http from "services/http";
import { apm } from "@elastic/apm-rum";
import endpoints from "services/endpoints";
import { mutateToLowerCamelCase } from "util/common";

import { SearchValidationRequest } from "./types";

export default class Match {
  inProgress;

  constructor(inProgress: boolean) {
    this.inProgress = inProgress;
  }

  async searchInputValidation(request: SearchValidationRequest) {
    try {
      const searchInputValidationRequest = {
        EnquirySubject: request.EnquirySubject,
        EnquiryContext: request.EnquiryContext,
        RequestType: request.RequestType,
        ExperimentalMode: request.ExperimentalMode,
        Features: request.Features
      };

      const result = await http.post(
        `${endpoints.searchInputValidation}`,
        searchInputValidationRequest
      );

      return mutateToLowerCamelCase(result);
    } catch (err: any) {
      apm.captureError(err as Error);
      if (err.status === 204) {
        return null;
      }
      return {
        userMessage: "An error occurred when validating the search."
      };
    }
  }
}
