import { styled } from "@linaria/react";
import { ReactComponent as Treasury } from "img/hm-treasury.svg";
import { ReactComponent as Transparency } from "img/transparency.svg";
import { ReactComponent as EuropeanSVG } from "img/european.svg";
import { ReactComponent as InternetSVG } from "img/internet.svg";
import { standardColors } from "styles/colors";

const Icon = styled.img`
  padding-right: 4px;
`;

const HMTreasury = styled(Treasury)`
  path {
    fill: ${standardColors.white};
  }
  margin-left: 4px;
  margin-top: -2px;
`;

const TransInt = styled(Transparency)`
  width: 17px;
  height: 17px;
  min-width: 17px;
  margin-left: 4px;
  margin-top: -1px;
`;

const European = styled(EuropeanSVG)`
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-left: 4px;
  margin-top: -2px;
`;

const Internet = styled(InternetSVG)`
  width: 15px;
  height: 15px;
  min-width: 15px;
  margin-left: 4px;
  margin-top: 1px;
`;

const TooltipContainer = styled.div`
  max-width: 240px;
`;

const Tag = styled.div<{ isInteractive?: boolean }>`
  cursor: ${props => (props.isInteractive ? "pointer" : "default")};
`;

const S = {
  Icon,
  HMTreasury,
  TransInt,
  European,
  Internet,
  TooltipContainer,
  Tag
};

export default S;
