/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TimelineEventDate } from "./TimelineEventDate";
import type { TimelineEventRiskCategory } from "./TimelineEventRiskCategory";
import type { TimelineEventSource } from "./TimelineEventSource";

export type TimelineEvent = {
  id: string;
  title: string;
  description: string;
  date?: TimelineEventDate;
  risk_categories: Array<TimelineEventRiskCategory>;
  sources: Array<TimelineEventSource>;
  /**
   * An optional property used to determine if a timeline event is related to a Legal Entity or the Wider Group on LEXI reports.
   */
  bucket?: TimelineEvent.bucket;
};

export namespace TimelineEvent {
  /**
   * An optional property used to determine if a timeline event is related to a Legal Entity or the Wider Group on LEXI reports.
   */
  export enum bucket {
    LEGAL_ENTITY = "legal_entity",
    GROUP = "group"
  }
}
