import React, { FC } from "react";
import S from "./styles";

export const TypingDots: FC<{}> = _props => {
  return (
    <div className={S.typing}>
      <span />
      <span />
      <span />
    </div>
  );
};
