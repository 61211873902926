import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { ReactComponent as Arrow } from "img/icons/renders/arrow-rounded.svg";
import { ReactComponent as TranslationArrows } from "img/translation-arrows.svg";

import { blue, grey, purple, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

import Button from "components/atoms/Button";

const ListName = styled.div`
  max-width: 360px;
  color: ${grey.dark};
  align-items: baseline;
`;

const Location = styled.div`
  font-family: ${family.interLight};
  display: flex;
`;

const Duration = styled.div`
  color: ${grey.mid};
  padding-bottom: 4px;
`;

const StatusPill = styled.span`
  background-color: ${props => props.color};
  border-radius: 10px;
  color: ${standardColors.white};
  padding: 0 8px;
`;

const ConfidenceMenuContainer = styled.div`
  grid-area: cell-confidence;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Divider = styled.div`
  grid-area: divider;
  border-bottom: 1px solid ${grey.rule};
  margin-left: 8px;
  margin-top: 4px;
`;

const BottomDivider = styled.div`
  grid-area: bottomDivider;
  margin-left: 8px;
  border-bottom: 1px solid ${grey.rule};
  margin-top: 4px;
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-areas: ${props => {
    if (props.isExpanded) {
      return `
    "cell-list-name cell-duration cell-status cell-confidence"
    "divider divider divider divider"
    "cell-row-details-1     cell-row-details-2     cell-row-details-3 cell-row-details-3"
    "bottomDivider bottomDivider bottomDivider bottomDivider"
    `;
    }

    // We don't render the bottom divider if we're not expanded.
    return `
    "cell-list-name cell-duration cell-status cell-confidence"
    "divider divider divider divider"
    "cell-row-details-1     cell-row-details-2     cell-row-details-3 cell-row-details-3"
    `;
  }};

  /* Must equal 100% */
  grid-template-columns: 36% 35% 27% 2%;

  & > div:not(${ConfidenceMenuContainer}, ${Divider}, ${BottomDivider}) {
    opacity: ${props => (props.confidenceHasChanged ? 0.2 : 1)};
    transition: opacity 1s;
    min-width: 0;
  }
`;

const GridCell = styled.div`
  padding: 8px;
`;

const GridCellRowDetails1 = styled(GridCell)`
  grid-area: cell-row-details-1;
  word-break: break-word;
  padding-top: 0;
  padding-left: 44px;
`;

const GridCellRowDetails2 = styled(GridCell)`
  grid-area: cell-row-details-2;
  word-break: break-word;
  padding-top: 0;
`;

const GridCellRowDetails3 = styled(GridCell)`
  grid-area: cell-row-details-3;
  word-break: break-word;
  padding-top: 0;
`;

const GridCellListName = styled(GridCell)`
  grid-area: cell-list-name;
  display: flex;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const EntityName = styled.p`
  margin: 0;
  color: ${grey.dark};
`;

const PrimaryEntityInfo = styled.div`
  margin: 0;
  color: ${grey.mid};
`;

const GridCellDuration = styled(GridCell)`
  grid-area: cell-duration;
  &:hover {
    cursor: pointer;
  }
`;

const GridCellStatus = styled(GridCell)`
  grid-area: cell-status;
  &:hover {
    cursor: pointer;
  }
`;

const CollapseButton = styled.button`
  outline: none;
  background: none;
  border: none;
  border-radius: 20px;
  width: 28px;
  min-width: 28px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:focus,
  &:active {
    outline: none;
    background-color: ${grey.light};
  }
`;

const CollapseArrow = styled(Arrow)`
  height: 12px;
  width: 12px;
  transform-origin: center;
  transform: ${({ className }) =>
    className === "isCollapsed" ? "rotate(0deg)" : "rotate(90deg)"};
  margin-left: ${({ className }) => (className === "isCollapsed" ? "2px" : 0)};
`;

const SourceUrl = styled.div`
  max-width: 300px;

  a:hover {
    color: ${purple.mid};
  }
`;

const FieldBlock = styled.div`
  padding-bottom: 12px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const BlockLabel = styled.div`
  color: ${grey.mid};

  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const StatusAndTranslationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TranslationArrowsIcon = styled(TranslationArrows)`
  path {
    transition: fill 0.2s;
    fill: ${({ color }) => {
      if (color) {
        const { r, g, b } = hexToRgb(color);
        return `rgba(${r}, ${g}, ${b}, 0.7)`;
      }

      return standardColors.white;
    }};
  }
`;

const TranslationButton = styled(Button)`
  color: ${({ color }) => {
    if (color) {
      const { r, g, b } = hexToRgb(color);
      return `rgba(${r}, ${g}, ${b}, 0.7)`;
    }

    return standardColors.white;
  }} !important;
  display: inline-flex;
  align-items: center;
  height: 19px;
  justify-content: space-between;
  min-height: 0 !important;
  transition: background 0.2s, border 0.2s, color 0.2s, box-shadow 0.2s;
  box-shadow: none !important;
  background-color: ${({ color }) => {
    if (color) {
      return "transparent";
    }

    const { r, g, b } = hexToRgb(blue.icon);
    return `rgba(${r}, ${g}, ${b}, 0.7)`;
  }} !important;
  font-size: ${fontSize.sm} !important;
  margin: 0 6px;
  border-color: ${({ color }) => {
    if (color) {
      const { r, g, b } = hexToRgb(color);
      return `rgba(${r}, ${g}, ${b}, 0.7)`;
    }

    return "transparent";
  }} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      rgba(
        ${({ color }) => {
          const { r, g, b } = hexToRgb(color);
          return `${r}, ${g}, ${b}`;
        }},
        0.33
      ) !important;
  }

  &:active {
    border-width: 1px !important;
  }
`;

const TranslationLanguage = styled.span`
  padding-left: 6px;
`;

const dataField = css`
  padding-bottom: 6px;
  &:last-child {
    padding: 0;

    dd {
      margin: 0;
    }
  }

  dt {
    margin-bottom: 0;
    text-transform: lowercase;

    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

const RoleDuration = styled.span`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const NoDate = styled.i`
  color: ${grey.mid};
`;

const S = {
  ListName,
  Location,
  Duration,
  StatusPill,
  DetailGrid,
  GridCellRowDetails1,
  GridCellRowDetails2,
  GridCellRowDetails3,
  GridCellListName,
  GridCellDuration,
  GridCellStatus,
  EntityName,
  PrimaryEntityInfo,
  Divider,
  BottomDivider,
  CollapseButton,
  CollapseArrow,
  SourceUrl,
  FieldBlock,
  BlockLabel,
  StatusAndTranslationContainer,
  TranslationArrowsIcon,
  TranslationButton,
  TranslationLanguage,
  RoleDuration,
  NoDate,
  ConfidenceMenuContainer
};

export const classNameOverrides = {
  dataField
};

export default S;
