import React, { FC, SVGProps, ReactNode } from "react";
import ModalContainer, { Variant } from "components/molecules/ModalContainer";
import { Spinner } from "reactstrap";

import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";
import { ModalVariant } from "./types";

interface Props {
  isOpen: boolean;
  title: string;
  description: string;
  isSubmitting: boolean;
  isSubmitted: boolean;
  onSubmit: () => void;
  submitButtonText: string;
  submittingButtonText: string;
  successText: string;
  successIcon: ReactNode;
  successIconColor: string;
  onCloseModal: () => void;
  children: ReactNode;
  overrideStyles?: boolean;
  subTitleText?: string | ReactNode;
  variant: ModalVariant;
}

const ModalWithContext: FC<Props> = ({
  title,
  successText,
  successIcon,
  successIconColor,
  submitButtonText,
  submittingButtonText,
  description,
  isSubmitting,
  isSubmitted,
  isOpen,
  onSubmit,
  onCloseModal,
  overrideStyles = false,
  subTitleText,
  children,
  variant
}) => {
  return isSubmitted ? (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={onCloseModal}
      onExitClick={onCloseModal}
      variant={Variant.LIGHT}
      width={371}
    >
      <S.SuccessModalContainer>
        <S.IconContainer color={successIconColor}>
          {successIcon}
        </S.IconContainer>
        <S.SuccessTextContainer>
          <S.SuccessText>{successText}</S.SuccessText>
          {subTitleText ?? <S.SubTitleText>{subTitleText}</S.SubTitleText>}
        </S.SuccessTextContainer>
      </S.SuccessModalContainer>
    </ModalContainer>
  ) : (
    <ModalContainer
      title={title}
      isOpen={isOpen}
      variant={Variant.LIGHT}
      toggleOpen={onCloseModal}
      onExitClick={onCloseModal}
      width={568}
      noGap
    >
      <p>{description}</p>

      {overrideStyles ? (
        children
      ) : (
        <S.ContextTextArea variant={variant}>
          <S.ContentWrapper>{children}</S.ContentWrapper>
        </S.ContextTextArea>
      )}

      <S.Actions>
        <S.CloseButton
          onClick={onCloseModal}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={isSubmitting}
          text="Cancel"
        />

        <S.SubmitButton
          onClick={onSubmit}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={isSubmitting}
          IconTrailing={
            isSubmitting
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
          variant={variant}
          text={isSubmitting ? submittingButtonText : submitButtonText}
        />
      </S.Actions>
    </ModalContainer>
  );
};

export default ModalWithContext;
