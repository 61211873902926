import React, {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  forwardRef
} from "react";

// @ts-ignore
import theme from "theme";

import { ButtonKind } from "./types";

import S from "./styles";

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: ReactNode;
  kind?: ButtonKind;
  style?: CSSProperties;
  className?: string;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  ariaLabel?: string;
  ariaChecked?: boolean | "false" | "mixed" | "true" | undefined;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      onClick = () => {},
      disabled = false,
      children,
      kind = ButtonKind.primary,
      style,
      className,
      onMouseEnter,
      onMouseLeave,
      ariaLabel,
      ariaChecked
    },
    ref
  ) => {
    switch (kind) {
      case ButtonKind.primary:
        return (
          <S.PrimaryButton
            onClick={onClick}
            disabled={disabled}
            style={style}
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
            aria-label={ariaLabel}
            color={theme.button.primary.color}
            backgroundColor={theme.primaryColor}
            boxShadowColor={theme.button.boxShadowColor}
            aria-checked={ariaChecked}
          >
            {children}
          </S.PrimaryButton>
        );
      case ButtonKind.secondary:
        return (
          <S.SecondaryButton
            onClick={onClick}
            disabled={disabled}
            style={style}
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
            aria-label={ariaLabel}
            borderColor={theme.primaryColor}
            boxShadowColor={theme.button.boxShadowColor}
            aria-checked={ariaChecked}
          >
            {children}
          </S.SecondaryButton>
        );
      case ButtonKind.tertiary:
        return (
          <S.TertiaryButton
            onClick={onClick}
            disabled={disabled}
            style={style}
            className={className}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
            aria-label={ariaLabel}
            color={theme.button.tertiary.color}
            aria-checked={ariaChecked}
          >
            {children}
          </S.TertiaryButton>
        );
      default:
        console.error(
          `Unrecognised 'kind' property: ${kind}. 'kind' must be one of: ${Object.values(
            ButtonKind
          ).join(", ")}`
        );
        return null;
    }
  }
);

export default Button;
