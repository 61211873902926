import React from "react";
import { createRoot } from "react-dom/client";

import "./styles/global/font-face.styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "remirror/styles/all.css";
import "./styles/index.scss";
import "./styles/global/global.styles";
import "./styles/global/grid.styles";
import "./styles/global/print.styles";
import "./styles/global/utils.styles";
import "./styles/components/Collapse.styles";
import "./styles/components/Dropdown.styles";
import "./styles/components/Modal.styles";
import "./styles/components/Spinner.styles";

import * as am4core from "@amcharts/amcharts4/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { init as initApm } from "@elastic/apm-rum";
import { isDevel } from "services/stage";
import { hotjar } from "react-hotjar";
import App from "./components/App";
import config from "./config";
import { currentVersion } from "./services/version";

initApm({
  active: config.isApmEnabled,
  serviceName: "Web Application",
  // Take version from version.tsx
  serviceVersion: currentVersion.toString(),
  serverUrl: config.apmServerUrl,
  environment: config.stage,
  distributedTracing: true,
  distributedTracingOrigins: [
    config.idamApiUrl,
    config.insightsApiUrl,
    config.portalApiUrl
  ]
});

am4core.addLicense("CH224487534");
am4core.addLicense("TL224487534");

if (isDevel) {
  hotjar.initialize({ id: 4975834, sv: 6 });
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
