import React, { ReactNode, useEffect, useRef } from "react";

import TruncateLength from "util/TruncateLength";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  tabTitle: string;
  isSelected: boolean;
  onTabSelect: () => void;
  onTabRemove: () => void;
  tabIcon: ReactNode;
  isInitialTab?: boolean;
}

const Tab = ({
  tabTitle,
  isSelected,
  onTabSelect,
  onTabRemove,
  tabIcon,
  isInitialTab
}: Props) => {
  const tabRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (tabRef.current) {
      tabRef.current.scrollIntoView();
    }
  }, []);

  return (
    <S.Tab
      ref={tabRef}
      selected={isSelected}
      onClick={onTabSelect}
      backgroundColor={theme.primaryColor}
    >
      <S.TabContent>
        {tabIcon}
        <S.TabTitle>
          <TruncateLength>{tabTitle}</TruncateLength>
        </S.TabTitle>
      </S.TabContent>
      {!isInitialTab && (
        <S.TabExitIcon onClick={onTabRemove} tabIsSelected={isSelected} />
      )}
    </S.Tab>
  );
};

export default Tab;
