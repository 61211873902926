/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SupportLevel {
  STRONG_SUPPORT = "STRONG_SUPPORT",
  WEAK_SUPPORT = "WEAK_SUPPORT",
  NO_SUPPORT = "NO_SUPPORT",
  SOME_CONTRADICTION = "SOME_CONTRADICTION"
}
