import { styled } from "@linaria/react";

const StageContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
`;

const HelpText = styled.span`
  p:last-child {
    margin: 0;
  }
`;

const S = {
  StageContainer,
  HelpText
};

export default S;
