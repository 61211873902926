import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import SearchInput from "components/atoms/SearchInput";

import { family, fontSize } from "styles/fonts";
import { blue, grey } from "styles/colors";

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  background-color: ${grey.panel};
  border-radius: 0.375rem;
  padding: 0 0.75rem;
`;

const CustomSearchInput = styled(SearchInput)`
  border-radius: 0;
  background-color: ${grey.panel};
  border: none;
  padding: 0;
  border-radius: 0.375rem;
`;

const OptionsButton = styled.button`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: ${fontSize.sm} !important;
  line-height: 1.125rem;
  color: ${({ disabled }) =>
    disabled ? grey.ghost : blue.xapienDark} !important;
  all: unset;
  white-space: nowrap;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
`;

/** ClassNameOverrides */

const textInput = css`
  font-family: ${family.interRegular};
  vertical-align: middle !important;

  .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiInputBase-input {
    padding: 0 !important;
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
`;

const S = {
  InputContainer,
  CustomSearchInput,
  OptionsButton
};

export const classNameOverrides = {
  textInput
};

export default S;
