import { createContext } from "react";

import { GroupUser, GroupUserRole } from "api/groups/types";
import { FetchResult } from "api/types";

export const ManageGroupContext = createContext({
  isManageGroupModalOpen: false,
  isRemoveFromGroupModalOpen: false,
  groupMembers: [] as GroupUser[],
  toggleManageGroupModal: () => {},
  toggleRemoveFromGroupModal: () => {},
  addMembersToGroup: async (_userIds: string[]): Promise<FetchResult> => {
    console.error("addMembersToGroup does not have any implementation");
    return { status: false };
  },
  removeMembersFromGroup: async (_userIds: string[]): Promise<FetchResult> => {
    console.error("removeMembersFromGroup does not have any implementation");
    return { status: false };
  },
  addAdminsToGroup: async (_userIds: string[]): Promise<FetchResult> => {
    console.error("addAdminsToGroup does not have any implementation");
    return { status: false };
  },
  removeAdminsFromGroup: async (_userIds: string[]): Promise<FetchResult> => {
    console.error("removeAdminsFromGroup does not have any implementation");
    return { status: false };
  },
  getGroupMembers: async (): Promise<boolean> => {
    console.error("getGroupsMembers does not have any implementation");
    return false;
  },
  updateGroupDetails: async (
    _name: string,
    _description?: string
  ): Promise<FetchResult> => {
    console.error("updateGroupDetails does not have any implementation");
    return { status: false };
  },
  changeMemberGroupRole: async (
    _userId: string,
    _role: GroupUserRole
  ): Promise<FetchResult> => {
    console.error("changeMemberGroupRole does not have any implementation");
    return { status: false };
  }
});
