import React, { Fragment } from "react";

import SectionFooter from "components/atoms/SectionFooter";

import StackedBarWithLegends from "./StackedBarWithLegends";
import S from "./styles";

const AssociatedIndustries = ({ employmentHistory }) => {
  const industryCounts =
    employmentHistory.industryCounts && employmentHistory.industryCounts.length
      ? employmentHistory.industryCounts
      : [];

  return (
    <Fragment>
      <S.Panel>
        <S.SectionHeader>
          <S.DateRange>
            <span>
              {employmentHistory.startDate && employmentHistory.startDate.year}
            </span>
            <span>{employmentHistory.startDate && " – "}</span>
            <span>
              {employmentHistory.endDate
                ? employmentHistory.endDate.year
                : "Present"}
            </span>
          </S.DateRange>

          <S.SectionCount>{industryCounts.length}</S.SectionCount>
        </S.SectionHeader>

        <S.SectionContent>
          <StackedBarWithLegends
            media={{
              sources: industryCounts.map(i => ({
                groupName: i.item1,
                articleNumber: i.item2,
                subText: i.item2 > 1 ? "companies" : "company"
              }))
            }}
          />
        </S.SectionContent>
      </S.Panel>
      <SectionFooter />
    </Fragment>
  );
};

export default AssociatedIndustries;
