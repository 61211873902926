import { createContext } from "react";
import { v4 as uuidv4 } from "uuid";

import type {
  RequestQueueState,
  RequestQueueAction,
  RequestJobData,
  RequestJob
} from "./types";
import {
  RequestQueueStatus,
  RequestQueueActions,
  RequestJobStatus
} from "./types";

export const initialState: RequestQueueState = {
  status: RequestQueueStatus.idle,
  requestQueue: [],
  callbackQueue: []
};

export const queueReducer = (
  state: RequestQueueState,
  action: RequestQueueAction
) => {
  switch (action.type) {
    case RequestQueueActions.addJob: {
      const job: RequestJob = {
        ...action.job,
        id: uuidv4(),
        status: RequestJobStatus.idle
      };

      return { ...state, requestQueue: [...state.requestQueue, job] };
    }
    case RequestQueueActions.processJob: {
      return {
        ...state,
        status: RequestQueueStatus.processingTask,
        processingJobId: action.jobId
      };
    }
    case RequestQueueActions.processJobTask: {
      const updatedRequestQueue = state.requestQueue.map(job =>
        job.id === state.processingJobId
          ? { ...job, status: RequestJobStatus.processingTask }
          : job
      );

      return {
        ...state,
        requestQueue: updatedRequestQueue
      };
    }
    case RequestQueueActions.resetStatus: {
      return {
        ...state,
        status: RequestQueueStatus.idle,
        processingJobId: undefined
      };
    }
    case RequestQueueActions.jobFailed: {
      const updatedQueue = state.requestQueue.map(job =>
        job.id === state.processingJobId
          ? { ...job, status: RequestJobStatus.error, error: action.error }
          : job
      );

      return {
        ...state,
        requestQueue: updatedQueue,
        error: action.error,
        status: RequestQueueStatus.error
      };
    }
    case RequestQueueActions.jobCallbacksCompleted: {
      return {
        ...state,
        status: RequestQueueStatus.idle,
        callbackQueue: []
      };
    }
    case RequestQueueActions.jobCompleted: {
      const completedJob = state.requestQueue.find(
        job => job.id === state.processingJobId
      );

      if (!completedJob) return state;

      const updatedQueue = state.requestQueue.filter(
        job => job.id !== state.processingJobId
      );

      const callbackJob = {
        ...completedJob,
        callbackData: action.taskResponse
      };

      return {
        ...state,
        status: RequestQueueStatus.idle,
        requestQueue: updatedQueue,
        callbackQueue: [...state.callbackQueue, callbackJob],
        processingJobId: undefined
      };
    }
    case RequestQueueActions.processCallbacks: {
      return {
        ...state,
        status: RequestQueueStatus.processingCallbacks
      };
    }
    case RequestQueueActions.queueFailed: {
      return {
        ...state,
        status: RequestQueueStatus.error,
        error: action.error
      };
    }
    default: {
      return state;
    }
  }
};

export const RequestQueueContext = createContext({
  state: initialState,
  addJob: (_job: RequestJobData) => {},
  dispatch: (_action: RequestQueueAction) => {}
});
