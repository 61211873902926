import { styled } from "@linaria/react";

import ButtonNew from "components/atoms/ButtonNew";

import { ReactComponent as TickSVG } from "img/icons/tick.svg";
import { purple, grey, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";

export interface SectionSelectSelectedItemProps {
  open: boolean;
}

export interface SectionDropdownItemProps {
  active: boolean;
}

const CancelButton = styled(ButtonNew)`
  background: ${grey.panel};
  padding: 12px 16px;
  font-family: ${family.interDisplay};
`;

const MoveButton = styled(ButtonNew)`
  background: ${isPDX ? theme.primaryColor : purple.darker};
  color: ${standardColors.white};
  padding: 12px 24px;
  font-family: ${family.interDisplay};
  &:hover {
    background: ${isPDX ? theme.primaryColor : purple.darkest};
  }
`;

const Actions = styled.div`
  padding-top: 16px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

const ContextTextArea = styled.div`
  border: 1px solid ${grey.ghost};
  width: 100%;
  height: 180px;
  border-radius: 4px;
  padding: 1rem;
  overflow-y: scroll;
  border-radius: 6px;
  background: ${grey.lighterPanel};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25) inset;
  color: ${grey.mid};
`;

const ContentWrapper = styled.div`
  pointer-events: none;
  & h3 {
    color: ${grey.mid};
  }
`;

const MovedModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px 8px 8px 8px;
  height: 496px;
  height: 268px;
`;

const IconContainer = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 8px;
  justify-content: center;
  border-radius: 48px;
  align-items: center;
  background-color: ${() => {
    const { r, g, b } = hexToRgb(purple.darker);
    return `rgba(${r}, ${g}, ${b}, 0.10)`;
  }};
`;

const TickIcon = styled(TickSVG)`
  color: ${purple.darker};
`;

const MovedText = styled.div`
  font-size: ${fontSize.lg};
  font-family: ${family.interSemiBold};
  font-weight: inherit;
`;

const MovedTextContainer = styled.div`
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MovedSubText = styled.div`
  font-size: ${fontSize.md};
`;

const S = {
  Actions,
  CancelButton,
  MoveButton,
  ContentWrapper,
  ContextTextArea,
  MovedTextContainer,
  MovedModalContainer,
  IconContainer,
  TickIcon,
  MovedSubText,
  MovedText
};

export default S;
