import { styled } from "@linaria/react";
import BaseHeading from "components/atoms/Heading";

import { fontSize } from "styles/fonts";
import { Info, X } from "react-feather";
import { blue, standardColors } from "styles/colors";
import { zIndex } from "styles/zIndex";

const Heading = styled(BaseHeading)`
  font-size: ${fontSize.md};
`;

const Paragraph = styled.p`
  font-size: ${fontSize.sm};
  text-align: center;
  margin: 0;

  a {
    color: ${blue.link};
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`;

const InfoIcon = styled(Info)`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const CloseIcon = styled(X)`
  display: flex;
  width: 10px;
  height: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Container = styled.div`
  cursor: auto;
  position: relative;
  background: ${standardColors.white};
  width: 426px;
  height: 153px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  z-index: ${zIndex.aboveTop};
`;

const S = { Heading, Paragraph, CloseButton, Container, CloseIcon, InfoIcon };

export default S;
