import { TagId } from "api/reports/types";
import { ChipVariant } from "components/atoms/Chip/types";

export const formatCardTags = (
  tags: { name: string; id: string }[],
  excludeList: string[] = []
) => {
  if (!tags) {
    return [];
  }

  return tags
    .filter(tag => !excludeList.includes(tag.id))
    .map(tag => {
      switch (tag.id) {
        case TagId.ResearchType: {
          return {
            name: tag.name,
            variant: ChipVariant.Outlined,
            helpText: "The type of research"
          };
        }
        case TagId.UserRole: {
          return {
            name: tag.name,
            variant: ChipVariant.FilledLight,
            helpText: "The role of this user."
          };
        }
        case TagId.GroupRole: {
          return {
            name: tag.name,
            variant: ChipVariant.FilledLight,
            helpText: `Your role in this group.`
          };
        }
        case TagId.SharedGroup: {
          return {
            name: tag.name,
            variant: ChipVariant.Outlined,
            helpText: `This report is shared with the ${tag.name} group.`
          };
        }
        case TagId.GroupMember: {
          return {
            name: tag.name,
            variant: ChipVariant.Outlined,
            helpText: `This user is part of the ${tag.name} group.`
          };
        }
        case TagId.SharingStatus: {
          return {
            name: tag.name,
            variant: ChipVariant.Outlined,
            helpText: "This report is shared."
          };
        }
        default: {
          return {
            name: tag.name,
            variant: ChipVariant.Outlined,
            helpText: ""
          };
        }
      }
    });
};
