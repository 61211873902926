import React from "react";
import { Person } from "@mui/icons-material";

import ModalContainer from "components/molecules/ModalContainer";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import TitleCard from "components/molecules/TitleCard";
import { ReportType } from "api/reports/types";
import { SearchResult } from "api/search";
import { DropdownOption } from "components/molecules/SearchInputWithOptions";
import { GroupReportRole } from "api/tenant/types";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  reportTitle: string;
  reportContext: string;
  reportType: ReportType;
  selectedUserFromOrganisation?: SearchResult;
  selectedGroupFromOrganisation?: SearchResult;
  selectedGroupPermission: DropdownOption<GroupReportRole>;
  notifyOwner?: boolean;
}

const ConfirmedCopy = ({
  isOpen,
  toggleOpen,
  reportTitle,
  reportContext,
  reportType,
  selectedUserFromOrganisation,
  selectedGroupFromOrganisation,
  selectedGroupPermission,
  notifyOwner
}: Props) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      title="Report copied"
    >
      <S.ConfirmedCopyModalContent>
        <TitleCard
          title={reportTitle}
          subtitle={reportContext}
          icon={
            reportType === ReportType.Organisation ? (
              <S.BuildingIcon />
            ) : (
              <Person fontSize="large" />
            )
          }
        />
        <TitleCard
          title={selectedUserFromOrganisation?.title}
          subtitle={selectedUserFromOrganisation?.description}
        >
          <S.AccessLevel>
            <div>Access level</div>
            <div>Owner</div>
          </S.AccessLevel>
        </TitleCard>
        {selectedGroupFromOrganisation && (
          <TitleCard
            title={selectedGroupFromOrganisation?.title}
            subtitle={selectedGroupFromOrganisation?.description}
          >
            <S.AccessLevel>
              <div>Access level</div>
              <div>{selectedGroupPermission?.label}</div>
            </S.AccessLevel>
          </TitleCard>
        )}
        {notifyOwner && (
          <S.NotifyOwnerConfirmation>
            An email notification has been sent
          </S.NotifyOwnerConfirmation>
        )}
      </S.ConfirmedCopyModalContent>
      <S.Actions>
        <ButtonNew
          text="Done"
          size={ButtonSize.Medium}
          type={ButtonType.Filled}
          onClick={toggleOpen}
        />
      </S.Actions>
    </ModalContainer>
  );
};

export default ConfirmedCopy;
