import React, { useEffect, useState, useMemo } from "react";
import { Provider } from "mobx-react";
import { internalStages, stage } from "services/stage";
import {
  sharedUserInterfaceStore,
  defineStore,
  reportStore,
  enquiryStore,
  diagnosticsStore,
  featureStore,
  userAssessmentStore
} from "state/Store";

import { AssertionModeContext } from "util/context/AssertionModeContext";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { SearchAssistExperimentalModeContext } from "util/context/SearchAssistExperimetalModeContext";
import ViewerModeContextProvider from "util/hooks/useViewerMode/provider";
import { PdxDiagnosticsModeProvider } from "util/hooks/usePdxDiagnosticsMode";
import Router from "pages/Router";

import DesktopOnlyWarning from "components/molecules/DesktopOnlyWarning";

import S from "./styles";

const App = ({ isInViewerMode = false }) => {
  const [diagnosticsModeEnabled, setDiagnosticsModeEnabled] = useState(false);
  const [
    searchAssistExperimentalModeEnabled,
    setSearchAssistExperimentalModeEnabled
  ] = useState(false);
  const [assertionModeEnabled, setAssertionModeEnabled] = useState(false);
  const [isViewerModeEnabled] = useState(isInViewerMode);
  const [primed, setPrimed] = useState(false);

  useEffect(() => {
    if (internalStages.has(stage)) {
      document.title = `(${stage}) ${document.title}`;
    }
  }, []);

  const diagnosticsMode = useMemo(
    () => ({ enabled: diagnosticsModeEnabled }),
    [diagnosticsModeEnabled]
  );
  const searchAssistExperimentalMode = useMemo(
    () => ({
      enabled: searchAssistExperimentalModeEnabled
    }),
    [searchAssistExperimentalModeEnabled]
  );
  const assertionMode = useMemo(
    () => ({ enabled: assertionModeEnabled }),
    [assertionModeEnabled]
  );

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.ctrlKey) {
        const pressed = e.key;
        if (pressed === "x") {
          setPrimed(true);
        } else if (primed && pressed === "i") {
          setDiagnosticsModeEnabled(true);
        } else if (primed && pressed === "a") {
          setAssertionModeEnabled(true);
        } else if (primed && pressed === "s") {
          setSearchAssistExperimentalModeEnabled(true);
          // 192 is the top left key. On the mac this is the '§'
          // key.
        } else if (e.keyCode === 192) {
          if (!e.shiftKey) {
            setDiagnosticsModeEnabled(true);
          }
        } else {
          setPrimed(false);
        }
      } else if (e.metaKey) {
        if (e.keyCode === 192) {
          if (!e.shiftKey) {
            setDiagnosticsModeEnabled(true);
          }
        }
      } else {
        setPrimed(false);
      }
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [primed, diagnosticsModeEnabled, assertionModeEnabled]);

  return (
    <Provider
      sharedUserInterfaceStore={sharedUserInterfaceStore}
      defineStore={defineStore}
      reportStore={reportStore}
      enquiryStore={enquiryStore}
      diagnosticsStore={diagnosticsStore}
      featureStore={featureStore}
      userAssessmentStore={userAssessmentStore}
    >
      <DesktopOnlyWarning>
        <ViewerModeContextProvider enabled={isViewerModeEnabled}>
          <AssertionModeContext.Provider value={assertionMode}>
            <DiagnosticsModeContext.Provider value={diagnosticsMode}>
              <SearchAssistExperimentalModeContext.Provider
                value={searchAssistExperimentalMode}
              >
                <PdxDiagnosticsModeProvider>
                  {diagnosticsModeEnabled ? (
                    <S.DiagnosticsModeHeader>
                      Diagnostics mode{" "}
                      <button
                        type="button"
                        onClick={() => setDiagnosticsModeEnabled(false)}
                      >
                        Exit
                      </button>
                    </S.DiagnosticsModeHeader>
                  ) : (
                    ""
                  )}
                  {assertionModeEnabled ? (
                    <S.DiagnosticsModeHeader>
                      Assertion mode{" "}
                      <button
                        type="button"
                        onClick={() => setAssertionModeEnabled(false)}
                      >
                        Exit
                      </button>
                    </S.DiagnosticsModeHeader>
                  ) : (
                    ""
                  )}
                  {searchAssistExperimentalModeEnabled ? (
                    <S.DiagnosticsModeHeader>
                      Search Assist experimental mode{" "}
                      <button
                        type="button"
                        onClick={() =>
                          setSearchAssistExperimentalModeEnabled(false)
                        }
                      >
                        Exit
                      </button>
                    </S.DiagnosticsModeHeader>
                  ) : (
                    ""
                  )}
                  <Router />
                </PdxDiagnosticsModeProvider>
              </SearchAssistExperimentalModeContext.Provider>
            </DiagnosticsModeContext.Provider>
          </AssertionModeContext.Provider>
        </ViewerModeContextProvider>
      </DesktopOnlyWarning>
    </Provider>
  );
};

export default App;
