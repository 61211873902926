/**
 * Builds up the query parameters necessary to kick off a new report
 * via the URL.
 * @param {Object} searchData - the meta data required to generate the query string
 * @returns query parameter string with the search data
 */
export const constructSearchUrl = ({
  subject,
  contextList,
  subjectType,
  nameOnlySearch,
  autorun = true
}) => {
  if (!subject || !contextList?.length || !subjectType) {
    return;
  }

  // Stringify the array of contexts so it will appear in the URL as an array
  // rather than a comma-separated list. Comma-separated lists will cause an
  // issue if a company name contains a comma.
  const encodedContext = encodeURIComponent(JSON.stringify(contextList));
  const encodedSubject = encodeURIComponent(subject);
  const encodedSubjectType = encodeURIComponent(subjectType?.toLowerCase());
  const enquiryOptions = `subjecttype=${encodedSubjectType}`;
  // autorun query parameter placed here to skip straight to running the enquiry after "Go" is pressed.
  return `?${
    nameOnlySearch ? "NOS=true&" : ""
  }autorun=${autorun}&subject=${encodedSubject}&context=${encodedContext}&${enquiryOptions}`;
};
