import { styled } from "@linaria/react";
import { Spinner } from "reactstrap";

import Notification from "components/atoms/Notification";

import { blue, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

import Button from "components/atoms/Button";

const DefineStageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  align-items: center;
  transition: box-shadow 0.2s;
  padding: 40px 0;
  border-radius: 12px;
`;

const VerificationElementsContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  margin-top: 32px;
`;

const VerifyingFooterElements = styled.div<{ isHidden?: boolean }>`
  transition: opacity 0.2s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerifyingSpinner = styled(Spinner)`
  color: ${blue.xapienLight};
  height: 36px;
  width: 36px;
`;

const CheckingEnquiryText = styled.p`
  font-size: ${fontSize.md};
  color: ${blue.xapienLight};
  background: none;
  border: none;
  padding: 0;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s;
`;

// @ts-ignore
const SearchAssistNotification = styled(Notification)`
  width: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #2d3c5a;
  z-index: 100;
  bottom: 4.6875rem;
`;

const ErrorNotificationHeadingLine = styled.div`
  font-family: ${family.interSemiBold};
`;

const ErrorNotificationDetails = styled.div`
  padding: 10px;
`;

const ValidationButton = styled(Button)<{
  borderColor?: string;
  boxShadowColor?: string;
}>`
  margin-left: 10px;
  color: ${standardColors.white} !important;
  padding: 0 20px !important;
  border-color: ${props => props.borderColor ?? blue.xapienLight} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgba(40, 239, 250, 0.33)"} !important;
  }
`;

const EmboldenedStatement = styled.div`
  font-family: ${family.interSemiBold};
  padding-bottom: 5px;
  padding-top: 10px;
`;

const NotificationGuidance = styled.div`
  padding: 10px 0;
`;

const GoButton = styled(Button)<{
  backgroundColor?: string;
  boxShadowColor?: string;
}>`
  background-color: ${props =>
    props.backgroundColor ?? blue.xapienLight} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgba(40, 239, 250, 0.33)"} !important;
  }
`;

const S = {
  DefineStageContainer,
  VerificationElementsContainer,
  VerifyingFooterElements,
  VerifyingSpinner,
  CheckingEnquiryText,
  SearchAssistNotification,
  ErrorNotificationHeadingLine,
  ErrorNotificationDetails,
  ValidationButton,
  EmboldenedStatement,
  NotificationGuidance,
  GoButton
};

export default S;
