import React, { FC, useCallback, ReactNode, useEffect, useState } from "react";

import { red } from "styles/colors";

import ModalWithContext from "components/organisms/InsightReport/ModalWithContext";
import { ModalVariant } from "components/organisms/InsightReport/ModalWithContext/types";

import {
  useInsightReport,
  InsightReportActions
} from "util/hooks/useInsightReport";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import S from "./styles";

interface Props {
  sectionId: string;
  subSectionId?: string;
  elementId?: string;
  children: ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const RemoveSectionModal: FC<Props> = ({
  sectionId,
  subSectionId,
  elementId,
  children,
  isOpen,
  onToggle
}) => {
  const [hasRemovingStarted, setHasRemovingStarted] = useState(false);
  const { state, dispatch } = useInsightReport();

  const onRemove = () => {
    setHasRemovingStarted(true);
    if (!subSectionId) {
      // Removing section
      dispatch({
        type: InsightReportActions.removeReportSection,
        sectionId
      });

      return;
    }

    if (elementId) {
      // Removing element
      dispatch({
        type: InsightReportActions.removeReportElement,
        sectionId,
        subSectionId,
        elementId
      });
      return;
    }

    dispatch({
      type: InsightReportActions.removeReportSubSection,
      sectionId,
      subSectionId
    });
  };

  const onCloseModal = useCallback(() => {
    onToggle();
  }, [onToggle]);

  const isRemoving =
    state.status === InsightReportStatus.removingReportElement ||
    state.status === InsightReportStatus.removingReportSection ||
    state.status === InsightReportStatus.removingReportSubSection;

  const isRemoved =
    state.status === InsightReportStatus.removingReportSectionSuccess;

  useEffect(() => {
    if (hasRemovingStarted && isRemoved && isOpen) {
      setTimeout(() => {
        setHasRemovingStarted(false);
        onCloseModal();
      }, 1000);
    }
  }, [isOpen, onCloseModal, hasRemovingStarted, isRemoved]);

  return (
    <ModalWithContext
      title="Delete this section"
      successText="Section deleted"
      successIcon={<S.TrashIcon />}
      successIconColor={red.directRisk}
      submitButtonText="Delete"
      submittingButtonText="Deleting..."
      description="Are you sure you want to delete this section? This cannot be undone."
      isSubmitting={isRemoving}
      isSubmitted={isRemoved}
      isOpen={isOpen}
      onSubmit={onRemove}
      onCloseModal={onCloseModal}
      variant={ModalVariant.Danger}
    >
      {children}
    </ModalWithContext>
  );
};

export default RemoveSectionModal;
