import React, { FC, ReactNode, useContext } from "react";
import { SupportLevel } from "api/insights";
import { orange, red } from "styles/colors";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";

import S from "./styles";

export interface ConfidenceIndicatorProps {
  supportLevel: SupportLevel;
  children: ReactNode;
}

const ConfidenceIndicator: FC<ConfidenceIndicatorProps> = ({
  supportLevel,
  children
}) => {
  const { enabled: isDiagnosticsMode } = useContext(DiagnosticsModeContext);

  const getColor = () => {
    if (supportLevel === SupportLevel.WEAK_SUPPORT) {
      return orange.indirectRisk;
    }

    return red.directRisk;
  };

  if (supportLevel === SupportLevel.STRONG_SUPPORT || !isDiagnosticsMode) {
    return <>{children}</>;
  }

  return <S.Text color={getColor()}>{children}</S.Text>;
};

export default ConfidenceIndicator;
