import React, { useState, useEffect, useMemo } from "react";

import { useParams } from "react-router-dom";

import type { Article } from "api/articles";
import ArticlesApi from "api/articles";

import FactivaArticleViewer from "pages/ArticleViewer/FactivaArticleViewer";

const ArticleViewerWrapper = () => {
  const { articleId, provider } = useParams();
  const articlesApi = useMemo(() => new ArticlesApi(), []);
  const [article, setArticle] = useState<Article | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!articleId || !provider) return;

    articlesApi
      .getArticle({ articleId, provider })
      .then(({ status, response, message }) => {
        if (status) {
          setArticle(response);
        } else {
          setErrorMessage(message);
        }
      })
      .catch(() => setErrorMessage("An unknown error has occured"));
  }, [setArticle, articleId, articlesApi, provider]);

  return (
    <FactivaArticleViewer
      articleId={articleId}
      article={article}
      errorMessage={errorMessage}
    />
  );
};

export default ArticleViewerWrapper;
