import React, { Component } from "react";
import { Spinner } from "reactstrap";
// Stop transpilation of mapbox-gl -
//  https://docs.mapbox.com/mapbox-gl-js/guides/install/#:~:text=for%20more%20details.-,Excluding%20Mapbox%20GL%20JS%20explicitly%20from%20transpilation,-If%20other%20parts
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import preparePoints from "./scripts/preparePoints";

import { classNameOverrides } from "./styles";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2hhdW5vbSIsImEiOiJja2Y2bjRyMDIweTNnMnlwOXRmY3RiNzJkIn0.pxE3DYYMLXGx40emVdkysw";

const images = [
  {
    id: "default-pin",
    url: "../../../../../images/icons/Map-pin-default.png"
  },
  {
    id: "risk-pin",
    url: "../../../../../images/icons/Map-pin-risk.png"
  },
  {
    id: "selected-pin",
    url: "../../../../../images/icons/Map-pin-selected.png"
  },
  {
    id: "risk-selected-pin",
    url: "../../../../../images/icons/Map-pin-risk-selected.png"
  },
  {
    id: "risk-no-point-selected-pin",
    url: "../../../../../images/icons/map-pin-no-point-risk-selected.png"
  },
  {
    id: "risk-no-point-pin",
    url: "../../../../../images/icons/map-pin-no-point-risk.png"
  },

  {
    id: "no-point-default-pin",
    url: "../../../../../images/icons/map-pin-no-point-default.png"
  },
  {
    id: "no-point-selected-pin",
    url: "../../../../../images/icons/map-pin-no-point-default-selected.png"
  },
  {
    id: "not-selected-pin",
    url: "../../../../../images/icons/map-pin-not-selected.png"
  },
  {
    id: "no-point-not-selected-pin",
    url: "../../../../../images/icons/map-pin-no-point-not-selected.png"
  }
];

class MapboxComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      left: 0,
      top: 0,
      lng: 5,
      lat: 34,
      zoom: 0,
      info: {
        text: "Points in cluster:",
        points: []
      },
      isLocationSelected: false,
      isDataLoaded: false
    };
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onPointClick = this.onPointClick.bind(this);
    this.map = null;
  }

  componentDidUpdate(prevProps) {
    const { locations = [], filteredLocations, activeFilter } = this.props;
    if (
      this.map &&
      this.map.getSource("locationsWithSpecificLatLon") &&
      this.map.getSource("locationsWithoutSpecificLatLon") &&
      locations !== prevProps.locations
    ) {
      const allPoints = preparePoints(
        locations,
        filteredLocations,
        activeFilter
      );

      const features = allPoints.filter(
        feature =>
          feature.geometry.coordinates[0] !== null ||
          feature.geometry.coordinates[0] === 0
      );
      const featuresWithPoints = features.filter(
        f => f.properties.isResolvedToSpecificLatLon
      );
      const featuresWithoutPoints = features.filter(
        f => !f.properties.isResolvedToSpecificLatLon
      );

      this.map
        .getSource("locationsWithSpecificLatLon")
        .setData({ type: "FeatureCollection", features: featuresWithPoints });
      this.map.getSource("locationsWithoutSpecificLatLon").setData({
        type: "FeatureCollection",
        features: featuresWithoutPoints
      });
      if (!this.state.isDataLoaded) {
        this.setState({ isDataLoaded: true });
      }
    }
  }

  componentDidMount() {
    const { locations, filteredLocations, activeFilter } = this.props;

    const allPoints = preparePoints(locations, filteredLocations, activeFilter);
    const features = allPoints.filter(
      feature =>
        feature.geometry.coordinates[0] !== null ||
        feature.geometry.coordinates[0] === 0
    );
    const featuresWithPoints = features.filter(
      f => f.properties.isResolvedToSpecificLatLon
    );
    const featuresWithoutPoints = features.filter(
      f => !f.properties.isResolvedToSpecificLatLon
    );

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/light-v10",
      center: [this.state.lng, this.state.lat],
      maxBounds: [
        [-180, -85],
        [180, 85]
      ],
      zoom: this.state.zoom,
      attributionControl: false,
      renderWorldCopies: false,
      preserveDrawingBuffer: true,
      cooperativeGestures: true
    });

    this.map.on("load", () => {
      Promise.all(
        images.map(
          img =>
            new Promise(resolve => {
              this.map.loadImage(img.url, (error, res) => {
                this.map.addImage(img.id, res, { sdf: false });
                resolve();
              });
            })
        )
      ).then(() => {
        this.map.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features
          }
        });

        this.map.addSource("locationsWithoutSpecificLatLon", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: featuresWithoutPoints
          }
        });

        this.map.addSource("locationsWithSpecificLatLon", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: featuresWithPoints
          }
        });

        this.map.addLayer({
          id: "mapUnclusteredPoint",
          type: "symbol",
          source: "locationsWithSpecificLatLon",
          layout: {
            "icon-image": [
              "case",
              ["==", ["get", "isSelected"], 0],
              "not-selected-pin",
              [
                "all",
                ["==", ["get", "isSelected"], 1],
                ["==", ["get", "hasRisk"], 1],
                ["==", ["get", "activeFilter"], 1]
              ],
              "risk-selected-pin",
              [
                "all",
                ["==", ["get", "isSelected"], 1],
                ["==", ["get", "hasRisk"], 0],
                ["==", ["get", "activeFilter"], 1]
              ],
              "selected-pin",
              ["==", ["get", "hasRisk"], 1],
              "risk-pin",
              "default-pin"
            ],
            "icon-anchor": "bottom",
            "icon-size": 0.5,
            "icon-allow-overlap": true
          },
          paint: {
            "icon-opacity": ["case", ["==", ["get", "isSelected"], 1], 1, 0.1]
          }
        });

        this.map.addLayer({
          id: "mapUnclusteredNoPoint",
          type: "symbol",
          source: "locationsWithoutSpecificLatLon",
          layout: {
            "icon-image": [
              "case",
              ...this.getPointFreePinConditions(),
              "default-pin"
            ],
            "icon-size": 0.5,
            "icon-allow-overlap": true
          },
          paint: {
            "icon-opacity": ["case", ["==", ["get", "isSelected"], 1], 1, 0.1]
          }
        });

        if (this.props.onMarkerClick) {
          this.map.on("mouseenter", "mapUnclusteredPoint", this.onMouseEnter);
          this.map.on("mouseleave", "mapUnclusteredPoint", this.onMouseLeave);
          this.map.on("mouseenter", "mapUnclusteredNoPoint", this.onMouseEnter);
          this.map.on("mouseleave", "mapUnclusteredNoPoint", this.onMouseLeave);
        }

        this.map.on("click", "mapUnclusteredPoint", this.onPointClick);
        this.map.on("click", "mapUnclusteredNoPoint", this.onPointClick);

        // Needed to resize map to fit container
        this.map.resize();
      });
    });
  }

  onMouseEnter(e) {
    const { properties } = e.features[0];

    if (properties.isSelected === 1) {
      this.map.getCanvas().style.cursor = "pointer";
    }
  }

  onMouseLeave() {
    this.map.getCanvas().style.cursor = "";
  }

  onPointClick(e) {
    const coordinates = e.features[0].geometry.coordinates.slice();
    const { properties } = e.features[0];
    const point = this.map.project(coordinates);

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    this.setState({
      isLocationSelected: true,
      left: `${point.x}px`,
      top: `${point.y}px`,
      info: {
        ...this.state.info,
        points: [properties]
      }
    });

    if (this.props.onMarkerClick && properties.isSelected === 1) {
      this.props.onMarkerClick([properties.id]);
    }
  }

  getPointFreePinConditions() {
    return [
      ["==", ["get", "isSelected"], 0],
      "no-point-not-selected-pin",
      [
        "all",
        ["==", ["get", "isSelected"], 1],
        ["==", ["get", "hasRisk"], 1],
        ["==", ["get", "activeFilter"], 1],
        ["==", ["get", "isResolvedToSpecificLatLon"], 0]
      ],
      "risk-no-point-selected-pin",
      [
        "all",
        ["==", ["get", "isSelected"], 1],
        ["==", ["get", "hasRisk"], 0],
        ["==", ["get", "activeFilter"], 1],
        ["==", ["get", "isResolvedToSpecificLatLon"], 0]
      ],
      "no-point-selected-pin",
      [
        "all",
        ["==", ["get", "hasRisk"], 1],
        ["==", ["get", "isResolvedToSpecificLatLon"], 0]
      ],
      "risk-no-point-pin",
      ["==", ["get", "isResolvedToSpecificLatLon"], 0],
      "no-point-default-pin"
    ];
  }

  render() {
    return (
      <div className="locations__map-container">
        {!this.state.isDataLoaded && (
          <div>
            <Spinner className="locations__loading-spinner" />
          </div>
        )}
        <div
          ref={el => (this.mapContainer = el)}
          className={classNameOverrides.mapboxContainer}
        />
      </div>
    );
  }
}

export default MapboxComponent;
