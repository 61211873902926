import { styled } from "@linaria/react";

import { standardColors } from "styles/colors";
import { family } from "styles/fonts";

const TextInput = styled.input<{ boxShadowColor: string }>`
  font-family: ${family.interLight};
  box-shadow: none;
  padding: 5px 16px;
  border-radius: 18px;
  margin: 0;
  border: 1px solid ${standardColors.white};
  background-color: rgba(255, 255, 255, 0.15);
  transition: border-color 0.2s, box-shadow 0.2s, opacity 0.2s;
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgba(40, 239, 250, 0.33)"};
  }

  &::placeholder {
    opacity: 0.6;
  }
`;

const S = {
  TextInput
};

export default S;
