import React, { FC } from "react";
import TruncateLength from "util/TruncateLength";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import LocationRiskTag from "components/atoms/LocationRiskTag";
import {
  CompanyRegisteredAddresses,
  CorrespondenceAddressForRole,
  FrontendImage,
  InformationSource,
  LocationRiskFlag,
  ValueTupleStringGUID
} from "api/report/report-types";
import LocationRoles from "components/organisms/Locations/LocationRoles";

import SourceLink from "components/atoms/SourceLink";
import S from "./styles";

export interface LocationCardProps {
  image: FrontendImage;
  address: string[];
  correspondenceAddressRoles: CorrespondenceAddressForRole[] | null;
  registeredAddressCompanies: CompanyRegisteredAddresses[] | null;
  associatedOrganisations: ValueTupleStringGUID[] | null;
  associatedPerson: string | null;
  riskFlags: LocationRiskFlag[] | null;
  sources: InformationSource[];
}

const LocationCard: FC<LocationCardProps> = ({
  image,
  address,
  correspondenceAddressRoles,
  registeredAddressCompanies,
  associatedOrganisations,
  associatedPerson,
  riskFlags,
  sources
}) => {
  const hasCorrespondenceAddressRoles =
    correspondenceAddressRoles && correspondenceAddressRoles.length > 0;
  const hasRegisteredAddressCompanies =
    registeredAddressCompanies && registeredAddressCompanies.length > 0;

  const canShowAssociatedOrganisations = () => {
    if (hasCorrespondenceAddressRoles || hasRegisteredAddressCompanies) {
      return false;
    }

    const hasAssociatedOrganisations =
      associatedOrganisations && associatedOrganisations.length > 0;

    return hasAssociatedOrganisations;
  };

  const canShowAssociatedPerson = () => {
    if (
      hasCorrespondenceAddressRoles ||
      hasRegisteredAddressCompanies ||
      canShowAssociatedOrganisations()
    ) {
      return false;
    }

    const hasAssociatedPerson = !!associatedPerson;

    return hasAssociatedPerson;
  };

  const getSourceLinks = () => {
    if (!sources) {
      return [];
    }

    const urls = sources.map(x => x.url).filter(Boolean);
    return urls as string[];
  };

  return (
    <S.LocationCard data-index={image.id}>
      {image.src ? (
        <S.Image
          alt="Related photograph"
          image={image}
          imageSizeToUse={ImageSizeToUse.Medium}
          lazyLoad={false}
          isCopyrighted
        />
      ) : (
        <S.MissingStreetViewImageContainer>
          <S.MissingStreetViewIcon />
          <S.NoStreetviewInfo>
            Streetview image not available for this address
          </S.NoStreetviewInfo>
        </S.MissingStreetViewImageContainer>
      )}
      <S.LocationCardContent>
        <S.AddressColumn>
          {riskFlags && riskFlags.length > 0 && (
            <S.LocationRiskFlagsContainer>
              {riskFlags.map(r => (
                <LocationRiskTag
                  key={r.assigner}
                  riskAssigner={r.assigner}
                  withIcon
                />
              ))}
            </S.LocationRiskFlagsContainer>
          )}
          <S.AddressContainer>
            <SourceLink href={getSourceLinks()}>
              {address.map((line: string) => (
                <div key={line}>
                  <TruncateLength>{line}</TruncateLength>
                </div>
              ))}
            </SourceLink>
          </S.AddressContainer>
        </S.AddressColumn>
        <S.RolesColumn>
          {hasCorrespondenceAddressRoles && (
            <LocationRoles
              roles={correspondenceAddressRoles}
              roleType="address"
              title="Correspondence address for"
            />
          )}
          {hasRegisteredAddressCompanies && (
            <LocationRoles
              roles={registeredAddressCompanies}
              roleType="address"
              title="Registered address for"
            />
          )}
          {canShowAssociatedOrganisations() && (
            <LocationRoles
              roles={associatedOrganisations as ValueTupleStringGUID[]}
              roleType="organisation"
              title="Associated organisations"
            />
          )}
          {canShowAssociatedPerson() && (
            <LocationRoles
              roles={[associatedPerson as string]}
              roleType="person"
              title="Associated person"
            />
          )}
        </S.RolesColumn>
      </S.LocationCardContent>
    </S.LocationCard>
  );
};

export default LocationCard;
