import React, { FC, SVGProps } from "react";

import { Spinner } from "reactstrap";

import ListItemWithOptions, {
  DropdownOption
} from "components/molecules/ListItemWithOptions";
import { User } from "react-feather";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import useManageGroup from "util/hooks/useManageGroup";
import ErrorBanner from "components/atoms/ErrorBanner";
import { UserToRemove } from "components/organisms/RemoveFromGroupModal/types";
import { GroupUserRole } from "api/groups/types";

import { classNameOverrides } from "./styles";

interface Props {
  memberId: string;
  title: string;
  subtitle: string;
  options: DropdownOption[];
  savedOption: DropdownOption;
  onRemoveFromGroup: (userToRemove: UserToRemove) => void;
  canEditMember: boolean;
}

const MemberListItem = ({
  memberId,
  title,
  subtitle,
  options,
  savedOption,
  onRemoveFromGroup,
  canEditMember
}: Props) => {
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();
  const { changeMemberGroupRole } = useManageGroup();

  const onSelectOption = async (option: DropdownOption) => {
    if (option.id === "remove") {
      onRemoveFromGroup({
        id: memberId,
        title,
        subtitle,
        groupRole: savedOption.id as GroupUserRole
      });
      return;
    }

    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await changeMemberGroupRole(
      memberId,
      option.id as GroupUserRole
    );

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return;
    }

    dispatch({ type: RequestActions.SetSuccess });
  };

  return (
    <>
      <ListItemWithOptions
        title={title}
        subtitle={subtitle}
        options={options}
        icon={<User />}
        optionIcon={
          fetching
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : undefined
        }
        disabled={!canEditMember || fetching}
        selectedOption={savedOption}
        onSelectOption={onSelectOption}
        className={classNameOverrides.optionButton}
      />
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            `Error changing ${title}'s role. Try again in a moment.`
          }
          onClick={() => dispatch({ type: RequestActions.ResetError })}
        />
      )}
    </>
  );
};

export default MemberListItem;
