import { styled } from "@linaria/react";

const AssertionSummary = styled.div`
  margin-top: 30px;
`;

const PassedPill = styled.span`
  color: white;
  background: #28a745;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  margin: 2px;
`;

const FailedPill = styled.span`
  color: white;
  background: #dc3545;
  border-radius: 5px;
  padding: 3px 5px 3px 5px;
  margin: 3px;
`;

const S = {
  AssertionSummary,
  PassedPill,
  FailedPill
};

export default S;
