import React, { useState, useEffect } from "react";

import { useCombobox } from "downshift";

// @ts-ignore
import theme from "theme";

import S from "./styles";

export interface MenuItem {
  id: string;
  title: string;
}

interface Props {
  defaultMenuItems: MenuItem[];
  onInputChanged: (value: string) => void;
  inputPlaceholderText: string;
  disabled?: boolean;
}

const ComboBox = ({
  defaultMenuItems = [],
  onInputChanged = () => {},
  inputPlaceholderText,
  disabled
}: Props) => {
  // We record this because we focus the whole row when we focus the input field
  const [isTextInputFocused, setIsTextInputFocused] = useState(false);
  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    inputValue,
    getItemProps
  } = useCombobox({
    items: menuItems,
    itemToString(item) {
      return item ? item.title : "";
    },
    onInputValueChange({ inputValue: value }) {
      setMenuItems(
        defaultMenuItems.filter(
          item =>
            !value || item.title.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  });

  useEffect(() => {
    setMenuItems(defaultMenuItems);
  }, [defaultMenuItems]);

  useEffect(() => {
    onInputChanged(inputValue);
  }, [inputValue, onInputChanged]);

  return (
    <div>
      <S.ComboBox
        disabled={disabled}
        {...getComboboxProps()}
        isFocused={isTextInputFocused}
        boxShadowColor={theme.button?.alternativeBoxShadowColor}
      >
        <S.Input
          {...getInputProps()}
          placeholder={inputPlaceholderText}
          onFocus={() => {
            setIsTextInputFocused(true);
          }}
          onBlur={() => setIsTextInputFocused(false)}
          maxLength={1000}
          disabled={disabled}
        />
        <S.DropdownButton
          type="button"
          {...getToggleButtonProps({
            disabled: defaultMenuItems.length <= 0 || disabled
          })}
          aria-label="toggle menu"
        >
          <S.ArrowDown />
        </S.DropdownButton>
      </S.ComboBox>
      <S.Menu {...getMenuProps()} isOpen={isOpen} isVisible={menuItems.length}>
        {isOpen && (
          <S.MenuItemsContainer>
            {menuItems.map((item, index) => {
              return (
                <S.MenuItem
                  tabIndex="0"
                  highlighted={highlightedIndex === index}
                  key={item.id}
                  {...getItemProps({ item, index })}
                >
                  <span>{item.title}</span>
                </S.MenuItem>
              );
            })}
          </S.MenuItemsContainer>
        )}
      </S.Menu>
    </div>
  );
};

export default ComboBox;
