import React, { ReactNode, useCallback, useMemo, useState } from "react";

import Api from "api/tenant";

import { HubAdminRoleContext } from "./context";

// const mock = () => {
//   return new Promise<{
//     status: boolean;
//     response: {
//       idToken: string;
//       expires: number;
//     };
//   }>(resolve => {
//     setTimeout(() => {
//       resolve({
//         status: true,
//         response: {
//           idToken: "123",
//           expires: 3600
//         }
//       });
//     }, 1000);
//   });
// };

export const HUB_ASSUMED_ROLE_TOKEN_KEY = "hubAssumedRoleAuth";

export const HubAdminRoleContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [isAuthenticatedAsHubAdmin, setIsAuthenticatedAsHubAdmin] =
    useState(false);

  const authenticateAsHubAdmin = useCallback(async (orgId: string) => {
    const TenantApi = new Api();

    const { status, response } = await TenantApi.assumeRole(orgId);

    // if success, update assumed role state
    if (status && response) {
      const { token, expires } = response;
      sessionStorage.setItem(
        HUB_ASSUMED_ROLE_TOKEN_KEY,
        JSON.stringify({ token, expires })
      );
      setIsAuthenticatedAsHubAdmin(true);
    }

    return status;
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticatedAsHubAdmin,
      authenticateAsHubAdmin,
      setIsAuthenticatedAsHubAdmin
    }),
    [authenticateAsHubAdmin, isAuthenticatedAsHubAdmin]
  );

  return (
    <HubAdminRoleContext.Provider value={value}>
      {children}
    </HubAdminRoleContext.Provider>
  );
};
