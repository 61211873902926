import React, { FC, useState } from "react";
import { X } from "react-feather";

import Input from "components/atoms/Input";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";

// @ts-ignore
import theme from "theme";

import { isPDX } from "static-config";
import config from "config";
import { resetPassword } from "aws-amplify/auth";

import S from "./styles";

interface Props {
  onBackClick: () => void;
}

const enum FormState {
  idle = "idle",
  loading = "loading",
  submitted = "submitted",
  error = "error"
}

const ForgottenPassword: FC<Props> = ({ onBackClick }) => {
  const [formState, setFormState] = useState<FormState>(FormState.idle);
  const [error, setError] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState("");

  const onSubmit = async () => {
    setFormState(FormState.loading);
    try {
      await resetPassword({
        username: `${email.toLowerCase()}+${config.tenantId}`
      });
      setFormState(FormState.submitted);
    } catch (e: any) {
      console.error("Password reset error", { message: e.message, ...e });
      const errorMessage =
        e && e.message && typeof e.message === "string" ? e.message : undefined;
      setError(errorMessage);
      setFormState(FormState.error);
    }
  };

  const onDismissError = () => setFormState(FormState.idle);

  const errorText = `There has been a problem with your request. Please check and try again. If the issues persists contact support.${
    error ? ` (${error})` : ""
  }`;
  return (
    <S.Container>
      {!isPDX && (
        <>
          <S.BackButton onClick={onBackClick}>
            <X />
          </S.BackButton>
          {theme.navigation?.logo ?? <S.XapienLogo />}
          <S.Title level={5}>
            {theme.slogan ?? "Xapien is a fully-automated research platform"}
          </S.Title>
        </>
      )}
      {formState === FormState.submitted ? (
        <>
          <S.Subtitle level={4}>Email sent</S.Subtitle>
          <S.Description>
            We have sent you an email with instructions on how to reset your
            password.
          </S.Description>
          <ButtonNew
            onClick={onBackClick}
            type={ButtonType.Filled}
            size={ButtonSize.Medium}
            text="Ok"
          />
        </>
      ) : (
        <>
          {formState === FormState.error && (
            <ErrorBanner text={errorText} onClick={onDismissError} />
          )}

          <S.Subtitle level={4}>Forgotten password</S.Subtitle>

          <S.Main>
            <S.Description>
              Enter your email address. If it is associated with a Xapien
              account, a reset password email will be sent.
            </S.Description>

            <S.Inputs isPDX>
              <Input
                inputType="email"
                placeholder="Email"
                onChange={setEmail}
                value={email}
                autoComplete="email"
              />
            </S.Inputs>

            <S.Contact>
              For further help, please contact us at
              <br />
              <a href={`mailto:${config.supportEmail}`}>
                {config.supportEmail}
              </a>
            </S.Contact>
          </S.Main>

          <ButtonNew
            onClick={onSubmit}
            type={ButtonType.Filled}
            size={ButtonSize.Medium}
            disabled={formState === FormState.loading || !email}
            text={formState === FormState.loading ? "Submitting" : "Send"}
          />
        </>
      )}
    </S.Container>
  );
};

export default ForgottenPassword;
