import { useState, useEffect, useMemo } from "react";

import { SearchItemType, SearchResult } from "api/search";
import Tenant from "api/tenant";
import { User } from "api/tenant/types";
import useDebounce from "util/hooks/useDebounce";

const useHubUserSearch = (organisationId: string) => {
  const [allUsers, setAllUsers] = useState<User[] | undefined>(undefined);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(true);
  const [suggestions, setSuggestions] = useState<SearchResult[]>([]);
  const { debounce } = useDebounce();

  const Api = useMemo(() => new Tenant(), []);

  useEffect(() => {
    if (!allUsers) {
      Api.getUsersFromOrganisation(organisationId)
        .then(res => {
          setAllUsers(res.response);
        })
        .catch(() => setAllUsers([]));
    }
  }, [Api, organisationId, allUsers]);

  const debounceQuerySearch = debounce(async (query: string) => {
    setIsFetchingSuggestions(true);

    const containsQuery = (value: string | undefined | null): unknown => {
      return value?.toLowerCase()?.includes(query.toLowerCase());
    };

    const filteredUsers =
      allUsers
        ?.filter(u => {
          if (u.name === "Imported User") {
            return false;
          }
          if (containsQuery(u.email)) {
            return true;
          }
          if (containsQuery(u.name)) {
            return true;
          }
          return false;
        })
        .map(user => ({
          id: user.userId,
          title: user.name,
          queryType: SearchItemType.User
        })) ?? [];

    setSuggestions(filteredUsers);
    setIsFetchingSuggestions(false);
  }, 300);

  const onSearchUpdate = (query: string) => {
    debounceQuerySearch(query);
  };

  return { suggestions, isFetchingSuggestions, onSearchUpdate };
};

export default useHubUserSearch;
