import React, { FC, ReactNode } from "react";
import S from "./styles";

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
}

const ModalSectionHeader: FC<Props> = ({ children, style }) => {
  return <S.SectionHeader style={style}>{children}</S.SectionHeader>;
};

export default ModalSectionHeader;
