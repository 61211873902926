import { styled } from "@linaria/react";

import Button from "components/atoms/Button";
import { DiImage } from "components/molecules/DiImage";
import { ReactComponent as OrgPlaceholderSVG } from "img/icons/business_placeholder_simple.svg";
import SourceLinkComponent from "components/atoms/SourceLink";

import { hexToRgb } from "styles/hexToRgb";
import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const NewReportButtonContainer = styled.div`
  padding-bottom: 16px;
  padding-top: ${props => (props.isLogoImagePresent ? "8px" : 0)};
`;

const OrgLogo = styled(DiImage)`
  width: 74px;
  height: 32px;
  object-fit: contain;
`;

const OrgPlaceholderIcon = styled(OrgPlaceholderSVG)`
  height: 32px;
  width: 74px;
  transform: scale(1.25);

  path {
    fill: ${grey.rule};
  }
`;

const ModalOrgPlaceholderIcon = styled(OrgPlaceholderSVG)`
  height: 74px;
  width: 74px;
  margin-bottom: 8px;
  margin-top: -2px;

  path {
    fill: ${grey.rule};
  }
`;

const PlaceholderContainer = styled.div`
  margin-bottom: 5px;
`;

const ConfidenceButtonContainer = styled.div`
  margin-bottom: 5px;
`;

const StatusCountButton = styled(Button)`
  background-color: ${grey.faint} !important;
  font-size: ${fontSize.sm};
  color: ${grey.mid} !important;
  padding: 0 10px !important;
  min-height: 21px !important;
  border: 1px solid transparent !important;
  min-width: 10px !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${() => {
        const { r, g, b } = hexToRgb(grey.ghost);
        return `rgba(${r}, ${g}, ${b}, 0.3)`;
      }} !important;
  }
`;

const RiskFlags = styled.div`
  display: inline-flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  flex-wrap: wrap;
  gap: 4px;
  column-gap: 0;
`;

const LazyLoadPlaceholder = styled.div`
  height: 132px;
  width: 100%;
`;

const TagColumn = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 45px;
  padding-right: 0;
`;

const RoleDuration = styled.span`
  display: inline-block;
`;

// Empty, but I reference the div below
const RowSection = styled.div``;

const StatusPills = styled.div``;

const ProfessionalInformationRow = styled.div`
  ${RowSection}, ${StatusPills}, ${OrgLogo} {
    opacity: ${props => (props.confidenceHasChanged ? 0.2 : 1)};
    transition: opacity 1s;
  }
`;

const SourceLink = styled(SourceLinkComponent)`
  &:hover {
    color: ${standardColors.white};
  }
`;

const S = {
  NewReportButtonContainer,
  OrgLogo,
  OrgPlaceholderIcon,
  ModalOrgPlaceholderIcon,
  PlaceholderContainer,
  ConfidenceButtonContainer,
  StatusCountButton,
  RiskFlags,
  LazyLoadPlaceholder,
  TagColumn,
  RoleDuration,
  ProfessionalInformationRow,
  RowSection,
  StatusPills,
  SourceLink
};

export default S;
