import { styled } from "@linaria/react";

// @ts-ignore
import theme from "theme";

const Shell = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  /* emulate position: fixed on the old raster background*/
  background-size: 125% 100%;
  @media screen and (min-width: 1280px) {
    background-size: 100%;
  }

  background-repeat: no-repeat;
  background-color: ${theme.backgroundColor};
  background-image: none;
  background-attachment: fixed;
`;

const Main = styled.main`
  z-index: 1;
  margin: 0 1em;
`;

const S = {
  Shell,
  Main
};

export default S;
