import { CollectionListView } from "components/molecules/CollectionListControls";

import Card from "components/molecules/Card";
import CardHeader from "components/molecules/CardHeader";
import CardBody from "components/molecules/CardBody";
import CardFooter from "components/molecules/CardFooter";

import ListCard from "components/molecules/ListCard";
import ListCardHeader from "components/molecules/ListCardHeader";
import ListCardBody from "components/molecules/ListCardBody";
import ListCardFooter from "components/molecules/ListCardFooter";

export const collectionCardComponents = {
  [CollectionListView.grid]: {
    C: {
      Card,
      CardHeader,
      CardBody,
      CardFooter
    }
  },
  [CollectionListView.list]: {
    C: {
      Card: ListCard,
      CardHeader: ListCardHeader,
      CardBody: ListCardBody,
      CardFooter: ListCardFooter
    }
  }
};
