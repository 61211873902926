import React, { SVGProps, FC, ReactNode } from "react";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";
import ErrorBanner from "components/atoms/ErrorBanner";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  heading: string;
  subHeading?: string;
  onRemove: () => Promise<boolean> | void;
  onReset: () => void;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  primaryActionText: string;
  description: string;
  children?: ReactNode;
}

const RemoveConfirmationModal = ({
  isOpen,
  toggleOpen,
  heading,
  subHeading = "This action cannot be undone.",
  onRemove,
  onReset,
  loading,
  error,
  errorMessage,
  primaryActionText,
  description,
  children
}: Props) => {
  const onDeleteClick = async () => {
    const status = await onRemove();
    if (status) {
      toggleOpen();
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onExitClick={toggleOpen}
      title={heading}
    >
      {children}
      <S.BodyContent>
        <Heading level={6}>{subHeading}</Heading>
        <S.Explainer>{description}</S.Explainer>
      </S.BodyContent>
      <S.FooterContent>
        <S.SecondaryButton
          text="Cancel"
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          onClick={toggleOpen}
        />
        <S.PrimaryButton
          text={primaryActionText}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          onClick={onDeleteClick}
          IconTrailing={
            loading
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
          disabled={loading}
        />
      </S.FooterContent>
      {error && (
        <ErrorBanner
          text={errorMessage || "Something went wrong. Try again in a moment."}
          onClick={onReset}
        />
      )}
    </ModalContainer>
  );
};

export default RemoveConfirmationModal;
