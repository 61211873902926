import React from "react";

import { SCREENING_FILTERS } from "util/screeningUtils";
import EntryRow from "components/organisms/ScreeningSection/EntryRow";
import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";
import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import { dJToolTipText } from "themes/projectdx/ThemedScreening/util";
import { isPDX } from "static-config";
import InfoIcon from "components/atoms/InfoIcon";

import S from "./styles";

const ListEntries = ({
  listEntries,
  confidenceCategory,
  onConfidenceChange,
  onCollapse
}) => {
  const totalCountForConfidenceCategory = Object.keys(listEntries)?.reduce(
    (acc, screeningType) => {
      return acc + (listEntries?.[screeningType]?.length ?? 0);
    },
    0
  );

  const [isExpanded, setIsExpanded] = usePrintableReportState(
    `screening-${confidenceCategory}-expanded`,
    (confidenceCategory === CONFIDENCE_CATEGORIES.confirmed ||
      confidenceCategory === CONFIDENCE_CATEGORIES.unconfirmed) &&
      totalCountForConfidenceCategory > 0,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const renderExpandHeader = () => {
    let headerText;
    if (isPDX) {
      if (confidenceCategory === CONFIDENCE_CATEGORIES.confirmed) {
        headerText = (
          <div>
            <S.InfoIconContainer>
              <S.ExpandHeaderCountLabel>
                {dJToolTipText.confimed.title}:
              </S.ExpandHeaderCountLabel>{" "}
              {dJToolTipText.confimed.subheading}
              <InfoIcon
                infoContent={
                  <>
                    <strong>{dJToolTipText.confimed.title}: </strong>
                    {dJToolTipText.confimed.text}
                  </>
                }
              />
            </S.InfoIconContainer>
          </div>
        );
      } else if (confidenceCategory === CONFIDENCE_CATEGORIES.unconfirmed) {
        headerText = (
          <div>
            <S.InfoIconContainer>
              <S.ExpandHeaderCountLabel>
                {dJToolTipText.unconfirmed.title}:
              </S.ExpandHeaderCountLabel>{" "}
              {dJToolTipText.unconfirmed.subheading}
              <InfoIcon
                infoContent={
                  <>
                    <strong>{dJToolTipText.unconfirmed.title}: </strong>
                    {dJToolTipText.unconfirmed.text}
                  </>
                }
              />
            </S.InfoIconContainer>
          </div>
        );
      } else if (confidenceCategory === CONFIDENCE_CATEGORIES.discarded) {
        headerText = (
          <div>
            <S.DisabledInfoIconContainer>
              <S.ExpandHeaderCountLabel>
                {dJToolTipText.discarded.title}:
              </S.ExpandHeaderCountLabel>{" "}
              {dJToolTipText.discarded.subheading}
              <InfoIcon
                infoContent={
                  <>
                    <strong>{dJToolTipText.discarded.title}: </strong>
                    {dJToolTipText.discarded.text}
                  </>
                }
              />
            </S.DisabledInfoIconContainer>
          </div>
        );
      } else {
        headerText = (
          <div>
            <S.DisabledInfoIconContainer>
              <S.ExpandHeaderCountLabel>
                {dJToolTipText.userDiscarded.title}:
              </S.ExpandHeaderCountLabel>{" "}
              {dJToolTipText.userDiscarded.subheading}
              <InfoIcon
                infoContent={
                  <>
                    <strong>{dJToolTipText.userDiscarded.title}: </strong>
                    {dJToolTipText.userDiscarded.text}
                  </>
                }
              />
            </S.DisabledInfoIconContainer>
          </div>
        );
      }
    } else if (confidenceCategory === CONFIDENCE_CATEGORIES.confirmed) {
      headerText = (
        <div>
          <S.ExpandHeaderCountLabel>Confirmed:</S.ExpandHeaderCountLabel> Xapien
          is confident these are your subject
        </div>
      );
    } else if (confidenceCategory === CONFIDENCE_CATEGORIES.unconfirmed) {
      headerText = (
        <div>
          <S.ExpandHeaderCountLabel>For review:</S.ExpandHeaderCountLabel> You
          may want to review whether these are your subject
        </div>
      );
    } else {
      headerText = (
        <div>
          <S.ExpandHeaderCountLabel>Discarded:</S.ExpandHeaderCountLabel> Xapien
          is confident these are not your subject
        </div>
      );
    }

    return (
      <S.ExpandHeader
        isTableEntriesEmpty={!totalCountForConfidenceCategory}
        aria-expanded={isExpanded}
        aria-controls={`${confidenceCategory}-entries`}
        aria-label={`${
          isExpanded ? "Collapse" : "Expand"
        } ${confidenceCategory} entries`}
        onClick={() =>
          totalCountForConfidenceCategory &&
          setIsExpanded(prev => {
            if (onCollapse && prev) onCollapse();
            return !prev;
          })
        }
        disabled={
          !totalCountForConfidenceCategory ||
          (isPDX && confidenceCategory === CONFIDENCE_CATEGORIES.discarded)
        }
      >
        {headerText}
        <S.ExpandHeaderRightContent
          isDiscarded={
            isPDX && confidenceCategory === CONFIDENCE_CATEGORIES.discarded
          }
        >
          <S.ConfidenceHitCount>
            {totalCountForConfidenceCategory}
          </S.ConfidenceHitCount>
          <S.Chevron className={isExpanded ? "isExpanded" : ""} />
        </S.ExpandHeaderRightContent>
      </S.ExpandHeader>
    );
  };

  return (
    <>
      {renderExpandHeader()}
      <div hidden={!isExpanded} id={`${confidenceCategory}-entries`}>
        {Object.keys(listEntries)?.map((screeningType, index, array) => {
          const screeningRisks = listEntries?.[screeningType];

          return (
            <S.ScreeningTableWrapper
              key={screeningType}
              isLastTable={index === array.length - 1}
              aria-label={`${confidenceCategory} ${screeningType} table entries`}
            >
              <S.ScreeningTable key={confidenceCategory}>
                <thead>
                  <tr>
                    <S.TableHeading colSpan="4">
                      {screeningRisks?.length ?? 0}{" "}
                      {screeningRisks?.length !== 1
                        ? SCREENING_FILTERS[screeningType].label
                        : SCREENING_FILTERS[screeningType].labelSingular}
                    </S.TableHeading>
                  </tr>
                </thead>
                <tbody>
                  {screeningRisks?.map((data, collection) => (
                    <EntryRow
                      key={`${data.listName.original} ${data.entityName.original} ${index}`}
                      totalRows={collection.length}
                      rowIndex={index}
                      listName={data.listName}
                      entityName={data.entityName}
                      entityType={data.entityType}
                      entityAliases={data.entityAliases}
                      dateOfBirth={data.dateOfBirth}
                      associatedAddresses={data.associatedAddresses}
                      startDate={data.startDate}
                      endDate={data.endDate}
                      entityNationalities={data.entityNationalities}
                      dynamicFields={data.dynamicFields}
                      isKnownToBeOngoing={data.isKnownToBeOngoing}
                      sourceUrl={data.sourceUrl}
                      placeOfBirth={data.placeOfBirth}
                      detectedLanguage={data.detectedLanguage}
                      roles={data.roles}
                      onConfidenceChange={(
                        newConfidence,
                        originalConfidence,
                        entryIds
                      ) =>
                        onConfidenceChange(
                          newConfidence,
                          originalConfidence,
                          entryIds,
                          screeningType
                        )
                      }
                      originalSetConfidence={data.originalSetConfidence}
                      currentSetConfidence={data.currentSetConfidence}
                      userAssessment={data.userAssessment}
                      userAssessmentId={data.userAssessmentId}
                    />
                  ))}
                </tbody>
              </S.ScreeningTable>
            </S.ScreeningTableWrapper>
          );
        })}
      </div>
    </>
  );
};

export default ListEntries;
