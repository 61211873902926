import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Question } from "api/insight-reports/types";

import S from "./styles";

dayjs.extend(customParseFormat);

interface Props {
  question: Question;
}

const InsightsQuestion = ({ question }: Props) => {
  const { user } = question;

  const timestamp = dayjs(question.timestamp)
    .local()
    .format("HH:mm DD/MM/YYYY");

  const questionAuthorString = `${user.firstName} ${user.lastName}`;

  return (
    <S.QuestionContainer>
      <S.Question>{question.elements?.[0].body}</S.Question>
      <S.QuestionDate>
        {questionAuthorString} asked this at {timestamp}
      </S.QuestionDate>
    </S.QuestionContainer>
  );
};

export default InsightsQuestion;
