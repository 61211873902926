import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 4.8125rem;
`;

const Input = styled.input<{ hidePlaceholderOnFocus: boolean }>`
  border: 0;
  border-radius: 6px;
  background: ${grey.panel};
  font-family: ${family.interRegular};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize.md};
  line-height: 24px;
  color: ${grey.dark};
  padding: 12px;

  &:focus::placeholder {
    color: ${({ hidePlaceholderOnFocus }) =>
      hidePlaceholderOnFocus ? "transparent" : grey.mid};
  }

  ::placeholder {
    color: ${grey.mid};
  }
`;

const Label = styled.label`
  color: ${standardColors.white};
  font-size: ${fontSize.sm};
  margin: 0;
`;

const S = { Container, Input, Label };

export default S;
