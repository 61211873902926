import React, {
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useCallback
} from "react";

import User from "api/user";
import type { Group } from "api/user/types";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import {
  UserSettingsContext,
  userSettingsReducer,
  initialState
} from "./context";
import { UserSettingsActions } from "./types";
import { useAuthentication } from "../useAuthentication";
import { AuthenticationStatus } from "../useAuthentication/types";

const UserSettingsContextProvider = ({ children }: { children: ReactNode }) => {
  const UserApi = useMemo(() => new User(), []);

  const [{ fetching, error }, fetchDispatch] = useFetchReducer();

  const [settingsState, settingsDispatch] = useReducer(
    userSettingsReducer,
    initialState
  );

  const {
    state: { status: authStatus }
  } = useAuthentication();

  const getAndSetUserDetails = useCallback(async () => {
    fetchDispatch({ type: RequestActions.SendRequest });
    const {
      status,
      response: storedUserDetails,
      message
    } = await UserApi.getUserDetails();
    if (status && storedUserDetails) {
      settingsDispatch({
        type: UserSettingsActions.UpdateUserDetails,
        userDetails: storedUserDetails
      });
      fetchDispatch({ type: RequestActions.SetSuccess });
      return null;
    }
    fetchDispatch({ type: RequestActions.SetError, errorMessage: message });
    return null;
  }, [fetchDispatch, UserApi, settingsDispatch]);

  useEffect(() => {
    if (authStatus === AuthenticationStatus.unauthenticated) {
      settingsDispatch({ type: UserSettingsActions.ResetUserSettings });
    }
  }, [authStatus]);

  useEffect(() => {
    if (authStatus === AuthenticationStatus.authenticated) {
      getAndSetUserDetails();
    }
  }, [getAndSetUserDetails, authStatus]);

  const updateUserDetails = useCallback(
    async (firstName: string, lastName: string, jobTitle: string) => {
      fetchDispatch({ type: RequestActions.SendRequest });

      const { status, message } = await UserApi.setUserDetails(
        firstName,
        lastName,
        jobTitle
      );

      if (!status) {
        fetchDispatch({ type: RequestActions.SetError, errorMessage: message });
        return;
      }

      const {
        status: updateStatus,
        message: updateMessage,
        response: updatedUserDetails
      } = await UserApi.getUserDetails();

      if (updateStatus && updatedUserDetails) {
        settingsDispatch({
          type: UserSettingsActions.UpdateUserDetails,
          userDetails: updatedUserDetails
        });
        fetchDispatch({ type: RequestActions.SetSuccess });
      } else {
        fetchDispatch({
          type: RequestActions.SetError,
          errorMessage: updateMessage
        });
      }
    },
    [UserApi, fetchDispatch]
  );

  const addUserGroup = useCallback(
    (group: Group) => {
      const newGroups = [...settingsState.userDetails.groups, group].sort(
        (a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }
      );

      settingsDispatch({
        type: UserSettingsActions.UpdateUserDetails,
        userDetails: {
          ...settingsState.userDetails,
          groups: newGroups
        }
      });
    },
    [settingsDispatch, settingsState]
  );

  const updateUserGroup = useCallback(
    (group: Partial<Group>) => {
      const updatedGroups = settingsState.userDetails.groups.map(g =>
        g.groupId === group.groupId ? { ...g, group } : g
      );

      settingsDispatch({
        type: UserSettingsActions.UpdateUserDetails,
        userDetails: {
          ...settingsState.userDetails,
          groups: updatedGroups
        }
      });
    },
    [settingsDispatch, settingsState]
  );

  const resetError = useCallback(() => {
    fetchDispatch({ type: RequestActions.Reset });
  }, [fetchDispatch]);

  const toggleSettingsModal = useCallback(() => {
    settingsDispatch({ type: UserSettingsActions.ToggleSettingsModal });
  }, [settingsDispatch]);

  const toggleAutoshareModal = useCallback(() => {
    settingsDispatch({ type: UserSettingsActions.ToggleAutoshareModal });
  }, [settingsDispatch]);

  const toggleInviteUserModal = useCallback(() => {
    settingsDispatch({ type: UserSettingsActions.ToggleInviteUserModal });
  }, [settingsDispatch]);

  const refresh = getAndSetUserDetails;

  const providerValue = useMemo(
    () => ({
      state: { ...settingsState, fetching, error },
      updateUserDetails,
      addUserGroup,
      updateUserGroup,
      resetError,
      toggleSettingsModal,
      toggleAutoshareModal,
      toggleInviteUserModal,
      refresh
    }),
    [
      error,
      fetching,
      resetError,
      settingsState,
      updateUserDetails,
      addUserGroup,
      updateUserGroup,
      toggleSettingsModal,
      toggleAutoshareModal,
      toggleInviteUserModal,
      refresh
    ]
  );

  return (
    <UserSettingsContext.Provider value={providerValue}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export { UserSettingsContextProvider };
