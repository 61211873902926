export enum Permission {
  Viewer = "VIEWER",
  Editor = "EDITOR",
  Owner = "OWNER"
}

export interface Props {
  permission: Permission;
  owner: string;
  reportId: string;
}
