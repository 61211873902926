import { InformationSource } from "api/report/report-types";
import { getSecondTierOfRiskHierarchy } from "util/getSecondTierOfRiskHierarchy";
import { riskDirections } from "util/riskDirections";

export const deduplicateSources = (sourceData: InformationSource[]) => {
  return sourceData?.filter(
    // "index" is our "current position"
    (source, index) =>
      // If the found index for a matching identifier is not the same as our current position,
      // then that found index must be before our current position, therefore don't include
      // the current positions source, as we already have included that source
      // (only include the first matching instance - when our current position matches the findIndex return)
      sourceData?.findIndex(
        sourceAhead =>
          (source?.id ?? source?.url) === (sourceAhead?.id ?? sourceAhead?.url)
      ) === index
  );
};

/**
 * Groups sources by risk category
 * @param {*} data - in format [ { source: {...}, riskCategories: { category: "", snippets: [...] } } ]
 * @param {boolean} displayCount - whether to display source count on filter pills
 * @returns expected inspector format: { topSectionElement: "", sources: [...] }
 */
export const combineSourcesWithRiskCategories = (
  data: any,
  displayCount: boolean = true
) => {
  // Build category counts
  const riskCategoryCounts = data?.reduce(
    (acc: { [x: string]: number }, curr: { riskCategories: any[] }) => {
      curr.riskCategories?.forEach((risk: { category: string | number }) => {
        if (!acc[risk.category]) {
          acc[risk.category] = 0;
        }
        // eslint-disable-next-line no-plusplus
        acc[risk.category]++;
      });
      return acc;
    },
    {}
  );

  // Modify risk categories to include count label
  const dataWithRiskCount = data?.map(
    (riskSource: { riskCategories: any[] }) => {
      return {
        ...riskSource,
        riskCategories: riskSource.riskCategories?.map(
          (riskCat: { category: any }) => {
            const { category } = riskCat;
            return {
              ...riskCat,
              category: {
                label: category,
                value: displayCount
                  ? `${category} | ${riskCategoryCounts[category]}`
                  : category
              }
            };
          }
        )
      };
    }
  );

  // Bucket sources based on matching risk categories
  // Building the inspectors expected data format - {topSectionElement: _, sources: _}
  const inspectorData = dataWithRiskCount?.reduce(
    (
      acc: { topSectionElement: any; sources: any[] }[],
      curr: { riskCategories: any[]; source: any }
    ) => {
      curr.riskCategories?.forEach(
        (riskCategory: { category: { value: any } }) => {
          const sourceObj = acc.find(
            (obj: { topSectionElement: any }) =>
              obj.topSectionElement === riskCategory.category.value
          );
          if (sourceObj) {
            // Found existing, dump sources into here with risk categories
            sourceObj.sources.push({
              ...curr.source,
              riskCategories: [...curr.riskCategories]
            });
          } else {
            const newSourceObj = {
              topSectionElement: riskCategory.category.value,
              sources: [
                {
                  ...curr.source,
                  riskCategories: [...curr.riskCategories]
                }
              ]
            };
            acc.push(newSourceObj);
          }
        }
      );
      return acc;
    },
    []
  );

  // Sort alphabetically
  inspectorData?.sort(
    (a: { topSectionElement: string }, b: { topSectionElement: any }) => {
      return a.topSectionElement.localeCompare(b.topSectionElement);
    }
  );

  // Sort by source count
  inspectorData?.sort(
    (a: { sources: string | any[] }, b: { sources: string | any[] }) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return b.sources?.length - a.sources?.length;
    }
  );

  // Add IDs
  inspectorData?.forEach((source: { sources: any[] }) => {
    source.sources.forEach(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (source: { id: string; riskCategories: any[]; url: any }) => {
        // eslint-disable-next-line no-param-reassign
        source.id = `${source.riskCategories
          ?.map((risk: { category: { label: any } }) => risk.category.label)
          ?.join()}_${source.url}`;
      }
    );
  });

  return inspectorData;
};

/**
 * @param {string} text - string that contains custom `highlight` tags
 * @returns {object} - object that contains highlight offsets and the text with its highlight tags removed
 */
const convertHighlightTagsToOffsets = (text: string) => {
  const regexpr = /<\/?highlight>*/g;
  const highlightOffsets = [];
  let lettersRemoved = 0; // Keeps track of _removed_ highlight tags so we can align the offsets in tandem
  let indexesCount = 0;
  let startIndex: number | null | undefined;
  let textLength;
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regexpr.exec(text))) {
    lettersRemoved += match[0].length;
    if (indexesCount % 2 === 0) {
      // Start of highlight
      startIndex = regexpr.lastIndex - lettersRemoved;
    } else {
      // End of highlight
      textLength = regexpr.lastIndex - startIndex! - lettersRemoved;
      highlightOffsets.push({ startIndex, textLength });
      // Reset
      startIndex = null;
      textLength = null;
    }
    // eslint-disable-next-line no-plusplus
    indexesCount++;
  }

  return {
    highlightOffsets,
    text: text.replace(regexpr, "")
  };
};

/** NOTE: we should aim to refactor this and have the backend pass us the data in the required structure * */

/**
 * Filters out any tags (risk categories) from the  Web and Media sources' `tags` field that doesn't have
 * anything in their `riskHierarchies` field, i.e. tags that don't refer to any "risk".
 * This also formats each tag such that each tag object uniquely refers to
 * a single tier 2 label, grouping with it any snippets that are linked to that tier 2 risk.
 * @param {object[]} tags - (risk categories) found for particular source
 * @param {booleam} filterOutIndirectTags - determine whether to filter out tags that are indirectly linked with the subject.
 * @returns - see description
 */
export const transformWebAndMediaSourceTagsForInspector = ({
  tags,
  filterOutIndirectTags
}: {
  tags: any[];
  filterOutIndirectTags: boolean;
}): any[] => {
  const formattedRiskCategories = tags?.reduce((acc, tag) => {
    if (
      (filterOutIndirectTags && tag.isNotCloseToSubject) ||
      tag.hideByDefault
    ) {
      return acc;
    }

    // Find all _unique_ tier 2 risks
    const tier2RisksAndFullHierarchies = tag.riskHierarchies?.reduce(
      (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        acc: {
          tier2Risks: { has: (arg0: any) => any; add: (arg0: any) => void };
          fullHierarchies: any[];
        },
        hierarchy: string[]
      ) => {
        const tier2Risk = hierarchy[1]?.toLowerCase();
        if (!acc.tier2Risks.has(tier2Risk)) {
          acc.tier2Risks.add(tier2Risk);
          acc.fullHierarchies.push(hierarchy);
        }
        return acc;
      },
      { tier2Risks: new Set(), fullHierarchies: [] }
    );

    if (tier2RisksAndFullHierarchies?.fullHierarchies?.length) {
      // Go through each identified tier 2 risk and produce a modified tag object
      tier2RisksAndFullHierarchies.fullHierarchies.forEach(
        (hierarchy: any[] | undefined) => {
          const tier2Risk =
            getSecondTierOfRiskHierarchy(hierarchy)?.toLowerCase();

          const riskDirection = tag.isNotCloseToSubject
            ? riskDirections.indirect
            : riskDirections.direct;
          const riskId = `${tier2Risk} - ${riskDirection}`;
          // If we already have a tag object that represents the tier 2 risk, then just append to its exisiting snippets
          if (acc.has(riskId)) {
            const existingTagObject = acc.get(riskId);
            tag.snippets?.forEach((snippet: { text: any }) => {
              const processedSnippet = convertHighlightTagsToOffsets(
                snippet.text
              );
              existingTagObject.snippets.push({
                snippet: processedSnippet.text,
                highlightOffsets: processedSnippet.highlightOffsets,
                riskDirection: tag.isNotCloseToSubject
                  ? riskDirections.indirect
                  : riskDirections.direct
              });

              // We want direct risk to trump indirect risk when it comes to the tag's
              // directionality.
              if (
                existingTagObject.category.riskDirection !==
                riskDirections.direct
              ) {
                existingTagObject.category.riskDirection =
                  tag.isNotCloseToSubject
                    ? riskDirections.indirect
                    : riskDirections.direct;
              }
            });
          } else {
            // Create new tag object
            acc.set(riskId, {
              id: riskId,
              category: {
                value: tier2Risk,
                label: tier2Risk,
                hierarchy,
                riskDirection: tag.isNotCloseToSubject
                  ? riskDirections.indirect
                  : riskDirections.direct
              },
              snippets: tag.snippets?.map(
                (snippet: { text: any; translated: any }) => {
                  const processedSnippet = convertHighlightTagsToOffsets(
                    snippet.text
                  );
                  return {
                    snippet: processedSnippet.text,
                    highlightOffsets: processedSnippet.highlightOffsets,
                    translated: snippet.translated,
                    riskDirection: tag.isNotCloseToSubject
                      ? riskDirections.indirect
                      : riskDirections.direct
                  };
                }
              )
            });
          }
        }
      );
    }
    return acc;
  }, new Map());

  return Array.from(formattedRiskCategories?.values() ?? []);
};

/**
 * Modifies the Web and Media source card data format to the required inspector source card format (see @returns)
 * @param {object[]} data - each object in this array contains a tier 2 risk label that
 * is mapped to source ids where the risk is mentioned.
 * @param {object} sources - map of source ids to source objects
 * @returns - expected inspector format: { topSectionElement: "", sources: [...] }
 */
export const transformWebAndMediaSourcesForInspector = (
  data: any[],
  sources: {
    [x: string]: any;
    "82b1ca17-af39-4b67-b8fe-e3a420a0aa12"?: {
      heading: string;
      sourceId: string;
      tags: (
        | {
            hideByDefault: boolean;
            isNotCloseToSubject: boolean;
            riskHierarchies: string[][];
            tagId: string;
          }
        | {
            hideByDefault: boolean;
            isNotCloseToSubject: boolean;
            riskHierarchies: string[][];
            tagId?: undefined;
          }
      )[];
    };
    "e688a557-df88-4a74-b066-fb56df0cf82e"?: {
      heading: string;
      sourceId: string;
      tags: {
        hideByDefault: boolean;
        isNotCloseToSubject: boolean;
        riskHierarchies: string[][];
      }[];
    };
  }
) => {
  const transformedData = data?.map(
    (dataObj: { referenceIds: any[]; riskTier2Cat: any }) => {
      let fullHierarchy: never[] = [];

      const formattedSources = dataObj.referenceIds?.map(
        (referenceId: string | number) => {
          const source = sources[referenceId];
          const riskCategories = transformWebAndMediaSourceTagsForInspector({
            tags: source.tags,
            filterOutIndirectTags: true
          });

          const foundHierarchy = riskCategories.find(
            cat => cat.category.hierarchy[1] === dataObj.riskTier2Cat
          );

          if (foundHierarchy) {
            fullHierarchy = foundHierarchy.category.hierarchy.slice(0, 2);
          }

          return {
            ...source,
            // Also map to expected riskCategories format...
            riskCategories
          };
        }
      );

      return {
        topSectionElement: {
          count: dataObj.referenceIds.length,
          label: dataObj?.riskTier2Cat,
          removesAllInstancesOfRiskCategory: true,
          riskHierarchy: fullHierarchy,
          type: "risk"
        },
        sources: formattedSources,
        id: dataObj.riskTier2Cat
      };
    }
  );

  return transformedData;
};
