import React, {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction
} from "react";

import { AnimatePresence, LayoutGroup, motion } from "framer-motion";

import SubjectStage from "pages/define/SubjectStage";
import ContextStage from "pages/define/ContextStage";
import ProjectIdentifierStage from "pages/define/ProjectIdentifierStage";
import withMountTransition from "pages/define/withMountTransition";
import {
  ENTITY_TYPE,
  unescapeHtmlString,
  SUBJECT_CONTEXTS
} from "pages/define/utils";
import { stage, devel } from "services/stage";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";

import VerifySpinner from "components/atoms/VerifySpinner";
import { ButtonKind } from "components/atoms/Button/types";
import { SearchAssistExperimentalModeContext } from "util/context/SearchAssistExperimetalModeContext";

// @ts-ignore
import theme from "theme";

import {
  renderInvalidWebsiteInputNotification,
  renderInvalidLinkedinInputNotification,
  renderSearchErroredNotification,
  renderInsufficientDataSearchErrorNotification,
  renderSearchConfirmationNotification,
  renderSearchSuggestionNotification,
  renderInvalidWebsiteInputInPersonSearch
} from "pages/define/DefineStage/searchAssistNotifications";
import useOrganisationPreferences from "util/hooks/useOrganisationPreferences";

import { OrgSuggestion, ValidationResponse } from "api/define/types";
import { EntityType, Context, ContextType } from "api/enquiry/types";
import { Features } from "api/feature/types";

import { correctContextTypes } from "./utils";

import { CorrectedType, InputToValidationStages } from "./types";

import S from "./styles";

interface Props {
  subjectName: string;
  subjectType: EntityType;
  setSubjectName: Dispatch<SetStateAction<string>>;
  setSubjectNameOverride: Dispatch<SetStateAction<string | undefined>>;
  setContextOverride: Dispatch<
    SetStateAction<Record<string, Context> | string | undefined>
  >;
  setOrgSuggestions: Dispatch<SetStateAction<OrgSuggestion[] | undefined>>;
  onSubjectTypeChanged: (subjectType: EntityType) => void;
  contexts: Record<string, Context>;
  setContexts: Dispatch<SetStateAction<Record<string, Context>>>;
  validateInput: (
    subject: { type: EntityType; item: string },
    contexts: { type: string; item: string }[],
    experimentalModeActive: boolean,
    features: Features
  ) => ValidationResponse;
  onSearchConfirmed: () => void;
  onOrgSuggestionsReceived: () => void;
  verificationStageErrored: boolean;
  contextLimit: number;
  isAdvancedSearchModeActive: boolean;
  setVerificationStageErrored: Dispatch<SetStateAction<boolean>>;
  projectReference?: string;
  setProjectReference: Dispatch<SetStateAction<string | undefined>>;
  stageTitle: string;
  isVerifying: boolean;
  setIsVerifying: Dispatch<SetStateAction<boolean>>;
  features: Features;
}

const DefineStage = ({
  subjectName,
  subjectType,
  setSubjectName,
  setSubjectNameOverride,
  setContextOverride,
  setOrgSuggestions,
  onSubjectTypeChanged,
  contexts,
  setContexts,
  validateInput,
  onSearchConfirmed,
  onOrgSuggestionsReceived,
  verificationStageErrored,
  contextLimit,
  isAdvancedSearchModeActive,
  setVerificationStageErrored,
  projectReference,
  setProjectReference,
  stageTitle,
  isVerifying,
  setIsVerifying,
  features
}: Props) => {
  const [validationResult, setValidationResult] = useState<
    ValidationResponse | undefined
  >();
  const [checkingSearchFailed, setCheckingSearchFailed] = useState(false);

  const [proposedSubjectName, setProposedSubjectName] = useState<
    string | undefined
  >();
  const [proposedContexts, setProposedContexts] = useState<
    Record<string, Context> | undefined
  >();
  const [inputsHaveCorrections, setInputsHaveCorrections] = useState(false);
  const [
    isAcceptedSuggestionsNotificationOpen,
    setIsAcceptedSuggestionsNotificationOpen
  ] = useState(false);
  const [
    isRejectedSuggestionsNotificationOpen,
    setIsRejectedSuggestionsNotificationOpen
  ] = useState(false);
  const [shouldContextsBeCorrected, setShouldContextsBeCorrected] =
    useState(false);
  const [inputIndicesWithSuggestedTypes, setInputIndicesWithSuggestedTypes] =
    useState<CorrectedType[]>([]);
  const [validateOnly, setValidateOnly] = useState(false);

  const { projectRefEnabled, loaded, initialisePreferences } =
    useOrganisationPreferences();

  useEffect(() => {
    if (!loaded) {
      initialisePreferences();
    }
  }, [loaded, initialisePreferences]);

  useEffect(() => {
    if (verificationStageErrored) {
      setSubjectNameOverride(undefined);
      setContextOverride(undefined);
    }
  }, [setContextOverride, setSubjectNameOverride, verificationStageErrored]);

  const searchAssistExperimentalMode = useContext(
    SearchAssistExperimentalModeContext
  ).enabled;

  const isSearchInvalid =
    validationResult && validationResult.queryQuality === 0;

  const diagnosticsMode = useContext(DiagnosticsModeContext);

  const executeActionForAdvancedSearch = ({
    suggestedContexts,
    suggestedSubjectName,
    doInputsHaveCorrections,
    runSearch
  }: {
    suggestedContexts?: Record<string, Context>;
    suggestedSubjectName?: string;
    doInputsHaveCorrections: boolean;
    runSearch: boolean;
  }) => {
    if (doInputsHaveCorrections) {
      // Store the proposed values for later should the user
      // decide to use them.
      if (suggestedSubjectName !== subjectName) {
        setProposedSubjectName(suggestedSubjectName);
      }
      setProposedContexts(suggestedContexts);

      setInputsHaveCorrections(doInputsHaveCorrections);
      // runSearch is temporary. Will revert to just an `else`
    } else if (runSearch) {
      // If there are no typos and no errors then we're good
      // to run the search as is
      onSearchConfirmed();
    }
    setValidateOnly(false);
  };

  const executeActionForOrganisationSearch = ({
    orgSuggestions,
    suggestedContexts,
    suggestedSubjectName,
    doInputsHaveCorrections,
    runSearch
  }: {
    orgSuggestions: OrgSuggestion[];
    suggestedContexts?: Record<string, Context>;
    suggestedSubjectName?: string;
    doInputsHaveCorrections: boolean;
    runSearch: boolean;
  }) => {
    if (isAdvancedSearchModeActive) {
      return executeActionForAdvancedSearch({
        suggestedContexts,
        suggestedSubjectName,
        doInputsHaveCorrections,
        runSearch
      });
    }

    setOrgSuggestions(orgSuggestions);
    if (doInputsHaveCorrections && suggestedSubjectName) {
      // For organisations, the user will see results for the corrected inputs,
      // so we can set the overrides here unlike in Person search.
      setSubjectNameOverride(suggestedSubjectName);
      setContextOverride(suggestedContexts);
    }
    onOrgSuggestionsReceived();
    setValidateOnly(false);
    return null;
  };

  const isSearchInputsValid = () => {
    const isContextValid = Object.keys(contexts).every(contextKey => {
      const contextObj = contexts[contextKey];
      // If we have both context and the context type then
      // this row of context is valid for search.
      if (contextObj.type && contextObj.value) {
        return true;
      }

      // If we lack both then we can still run the search anyway.
      // The row will just be dropped from the enquiry request
      if (!contextObj.type && !contextObj.value) {
        return true;
      }

      // Otherwise we must have a situation where we've either only
      // selected a context type or entered a context name, which
      // is invalid for searching.
      return false;
    });

    return subjectName && isContextValid;
  };

  const isFormDisabled = () => {
    return (
      isVerifying ||
      inputsHaveCorrections ||
      isSearchInvalid ||
      verificationStageErrored ||
      checkingSearchFailed
    );
  };

  const validateAndStoreCorrectedContextTypes = (
    currentTypeToInputValidationStage: InputToValidationStages
  ) => {
    if (
      currentTypeToInputValidationStage === InputToValidationStages.inProgress
    ) {
      return currentTypeToInputValidationStage;
    }

    if (
      currentTypeToInputValidationStage === InputToValidationStages.initial &&
      (subjectType === ENTITY_TYPE.Person ||
        (subjectType === ENTITY_TYPE.Organisation &&
          isAdvancedSearchModeActive))
    ) {
      const generatedInputIndicesWithSuggestedTypes: CorrectedType[] =
        correctContextTypes(contexts, subjectType);
      if (generatedInputIndicesWithSuggestedTypes.length) {
        // Store for later use – when the user accepts the correction, we can use this to target the correct input fields.
        setInputIndicesWithSuggestedTypes(
          generatedInputIndicesWithSuggestedTypes
        );
        return InputToValidationStages.inProgress;
      }
      return InputToValidationStages.complete;
    }
    return InputToValidationStages.complete;
  };

  const onGoClick = async (
    runSearch = true,
    modifiedSearchInputs: { contexts?: Record<string, Context> } = {},
    typeToInputValidationStage = InputToValidationStages.initial,
    keyEvent:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | undefined = undefined
  ) => {
    const contextsToUse = modifiedSearchInputs?.contexts ?? contexts;
    const { enabled } = diagnosticsMode;

    const skipValidationHiddenFeature = enabled && keyEvent?.altKey;

    if (skipValidationHiddenFeature) {
      onSearchConfirmed();
    }

    if (!isSearchInputsValid()) {
      return null;
    }

    setIsVerifying(true);

    // Don't progress until type to input validation has completed
    if (
      validateAndStoreCorrectedContextTypes(typeToInputValidationStage) !==
      InputToValidationStages.complete
    ) {
      setIsVerifying(false);
      return null;
    }

    const contextsToValidate = Object.keys(contextsToUse).reduce(
      (acc: { type: ContextType; item: string }[], contextKey) => {
        const contextObj = contextsToUse[contextKey];
        if (!contextObj.value) {
          return acc;
        }
        return [...acc, { type: contextObj.type, item: contextObj.value }];
      },
      []
    );

    const validationResponse: ValidationResponse = await validateInput(
      { type: subjectType, item: subjectName },
      contextsToValidate,
      searchAssistExperimentalMode,
      features
    );

    setIsVerifying(false);

    const { queryQuality, searchAssistResponse } = validationResponse;
    const {
      orgSuggestions,
      proposedContexts: suggestedContexts,
      proposedSubject
    } = searchAssistResponse;

    if (queryQuality === 0) {
      setValidationResult(validationResponse);
      return null;
    }

    if (!queryQuality) {
      setCheckingSearchFailed(true);
      return null;
    }

    if (!searchAssistResponse) {
      if (runSearch) {
        return onSearchConfirmed();
      }
      setInputIndicesWithSuggestedTypes([]);
      return null;
    }

    const sanitisedProposedContexts: Record<string, Context> =
      suggestedContexts?.reduce(
        (acc: Record<string, Context>, context: any, ind: number) => {
          let item = "";
          if (context.correctedForm) {
            item = unescapeHtmlString(context.correctedForm)!;
          }
          acc[ind] = {
            type: context?.originalForm?.type?.toLowerCase(),
            value: item
          };
          return acc;
        },
        {}
      );

    const sanitisedProposedSubjectName = proposedSubject.correctedForm
      ? unescapeHtmlString(proposedSubject.correctedForm)!
      : undefined;

    const doesContextContainTypo = Object.values(
      sanitisedProposedContexts
    ).some(context => context.value);

    // Check if there is at least one field to be corrected
    setShouldContextsBeCorrected(doesContextContainTypo);

    const doInputsHaveCorrections =
      sanitisedProposedSubjectName !== undefined || doesContextContainTypo;

    if (subjectType === ENTITY_TYPE.Organisation) {
      executeActionForOrganisationSearch({
        orgSuggestions,
        suggestedContexts: sanitisedProposedContexts,
        suggestedSubjectName: sanitisedProposedSubjectName,
        doInputsHaveCorrections,
        runSearch
      });
    } else if (subjectType === ENTITY_TYPE.Person) {
      executeActionForAdvancedSearch({
        suggestedContexts: sanitisedProposedContexts,
        suggestedSubjectName: sanitisedProposedSubjectName,
        doInputsHaveCorrections,
        runSearch
      });
    }

    return null;
  };

  const onTriggerSearchVerification = (
    runSearch = true,
    keyEvent:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | undefined = undefined
  ) => {
    if (!runSearch) {
      setValidateOnly(true);
    }
    setValidationResult(undefined);
    return onGoClick(runSearch, undefined, undefined, keyEvent);
  };

  const resetSearchResultsData = () => {
    setValidationResult(undefined);
    setProposedSubjectName(undefined);
    setProposedContexts(undefined);
    setInputsHaveCorrections(false);
    setOrgSuggestions(undefined);
    setIsAcceptedSuggestionsNotificationOpen(false);
    setIsRejectedSuggestionsNotificationOpen(false);
    setVerificationStageErrored(false);
    setCheckingSearchFailed(false);
    setShouldContextsBeCorrected(false);
  };

  const onSubjectNameChange = (value: string) => {
    resetSearchResultsData();
    setSubjectName(value);
  };

  const onSearchAssistConfirmed = () => {
    setSubjectNameOverride(proposedSubjectName);
    setContextOverride(proposedContexts);
    onSearchConfirmed();
  };

  const renderSearchAssistNotification = () => {
    const hasSuggestedWebAddressContextTypes =
      inputIndicesWithSuggestedTypes.some(
        input =>
          input.suggestedType ===
          SUBJECT_CONTEXTS?.[subjectType]?.webAddress?.type
      );

    const hasSuggestedSocialProfileContextTypes =
      inputIndicesWithSuggestedTypes.some(
        input =>
          input.suggestedType ===
          SUBJECT_CONTEXTS?.[subjectType]?.socialProfile?.type
      );

    // Has suggested web address context type(s) but the subject type is a person
    // (there isn't a "web address" context type that currently exists)
    if (
      hasSuggestedWebAddressContextTypes &&
      subjectType === ENTITY_TYPE.Person
    ) {
      return renderInvalidWebsiteInputInPersonSearch(
        inputIndicesWithSuggestedTypes,
        setInputIndicesWithSuggestedTypes,
        onGoClick,
        contexts,
        subjectType,
        !validateOnly
      );
    }

    if (hasSuggestedSocialProfileContextTypes) {
      return renderInvalidLinkedinInputNotification(
        inputIndicesWithSuggestedTypes,
        setInputIndicesWithSuggestedTypes,
        onGoClick,
        contexts,
        setContexts,
        subjectType,
        !validateOnly
      );
    }

    if (hasSuggestedWebAddressContextTypes) {
      return renderInvalidWebsiteInputNotification(
        inputIndicesWithSuggestedTypes,
        setInputIndicesWithSuggestedTypes,
        onGoClick,
        contexts,
        setContexts,
        subjectType,
        !validateOnly
      );
    }

    if (verificationStageErrored || checkingSearchFailed) {
      return renderSearchErroredNotification(resetSearchResultsData, onGoClick);
    }

    if (isSearchInvalid) {
      return renderInsufficientDataSearchErrorNotification(
        resetSearchResultsData,
        contexts,
        subjectName,
        validationResult
      );
    }

    if (
      isAcceptedSuggestionsNotificationOpen ||
      isRejectedSuggestionsNotificationOpen
    ) {
      return renderSearchConfirmationNotification(
        resetSearchResultsData,
        isAcceptedSuggestionsNotificationOpen,
        onSearchConfirmed,
        onSearchAssistConfirmed
      );
    }

    if (!inputsHaveCorrections) {
      return null;
    }

    return renderSearchSuggestionNotification(
      shouldContextsBeCorrected,
      proposedContexts,
      proposedSubjectName,
      contexts,
      resetSearchResultsData,
      setIsRejectedSuggestionsNotificationOpen,
      setIsAcceptedSuggestionsNotificationOpen,
      setSubjectName,
      setContexts
    );
  };

  const renderProjectIdentifierStage = () => {
    if (projectRefEnabled) {
      return (
        <ProjectIdentifierStage
          /* @ts-ignore TODO */
          projectReference={projectReference}
          setProjectReference={setProjectReference}
          disabled={isFormDisabled()}
          // Fade in/out when mounted/unmounted
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          layout
        />
      );
    }
    return null;
  };

  return (
    <S.DefineStageContainer>
      <SubjectStage
        subjectType={subjectType}
        setSubjectType={onSubjectTypeChanged}
        subjectName={subjectName}
        onSubjectNameChange={onSubjectNameChange}
        onStageComplete={() => onTriggerSearchVerification(true)}
        disabled={isFormDisabled()}
        isAdvancedSearchModeActive={isAdvancedSearchModeActive}
        stageTitle={stageTitle}
      />
      {/* Synchronise layout animations between the presence of the project identifier field, extra context fields, and the
          the positioning of the action buttons.
      */}
      <LayoutGroup>
        <ContextStage
          subjectType={subjectType}
          contexts={contexts}
          setContexts={(contextsArr: Record<string, Context>) => {
            resetSearchResultsData();
            setContexts(contextsArr);
          }}
          onGoClick={onGoClick}
          disabled={isFormDisabled()}
          contextLimit={contextLimit}
          isAdvancedSearchModeActive={isAdvancedSearchModeActive}
          hasSuggestionBeenAccepted={isAcceptedSuggestionsNotificationOpen}
        />
        <AnimatePresence>{renderProjectIdentifierStage()}</AnimatePresence>
        <S.VerificationElementsContainer>
          {!isVerifying &&
          !inputsHaveCorrections &&
          !isSearchInvalid &&
          !checkingSearchFailed &&
          !verificationStageErrored ? (
            // layout prop used by Framer motion to adjust the positioning of these buttons when adjacent elements
            // changes in layout
            <motion.div layout>
              <S.GoButton
                onClick={e => {
                  onTriggerSearchVerification(true, e);
                }}
                kind={ButtonKind.primary}
                disabled={!isSearchInputsValid()}
                backgroundColor={theme.alternativePrimaryColor}
                boxShadowColor={theme.button?.alternativeBoxShadowColor}
              >
                Go
              </S.GoButton>
              {stage === devel && (
                <S.ValidationButton
                  onClick={() => onTriggerSearchVerification(false)}
                  kind={ButtonKind.secondary}
                  borderColor={theme.alternativePrimaryColor}
                  boxShadowColor={theme.button?.alternativeBoxShadowColor}
                >
                  Validate only
                </S.ValidationButton>
              )}
            </motion.div>
          ) : null}
          {isVerifying && <VerifySpinner searchType={subjectType} />}
        </S.VerificationElementsContainer>
      </LayoutGroup>
      {renderSearchAssistNotification()}
    </S.DefineStageContainer>
  );
};

export default withMountTransition(DefineStage);
