import { createContext } from "react";
import {
  CollectionListActions,
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionIndividualItem
} from "./types";
import type {
  CollectionListState,
  CollectionListAction,
  CollectionItem
} from "./types";

export const initialState: CollectionListState = {
  collections: []
};

const getCollection = (id: string, state: CollectionListState) =>
  state.collections.find(collection => collection.id === id);

const updateCollection = (
  collectionUpdate: Partial<CollectionItem> & { id: string },
  state: CollectionListState
): CollectionListState => {
  const { id } = collectionUpdate;
  const existingCollection = getCollection(id, state);

  if (existingCollection) {
    return {
      ...state,
      collections: state.collections.map(c =>
        c.id === id ? { ...c, ...collectionUpdate } : c
      )
    };
  }

  console.warn("Collection update on collection that does not exist", {
    collectionUpdate,
    existingCollectionCount: state.collections.length
  });

  return state;
};

export const CollectionListContext = createContext({
  state: initialState,
  dispatch: (_action: CollectionListAction) => {}
});

const updateList = <T,>(original: T[], update: T[], start?: number) => {
  const fixedStart =
    start === undefined || start === null ? original.length : start;
  const items = [
    ...original.slice(0, fixedStart),
    ...update,
    ...original.slice(fixedStart + update.length)
  ];
  return items;
};

export const collectionListReducer = (
  state: CollectionListState,
  action: CollectionListAction
): CollectionListState => {
  switch (action.type) {
    case CollectionListActions.updateCollectionStatus: {
      const collectionUpdate = {
        id: action.id,
        status: action.status
      };

      return updateCollection(collectionUpdate, state);
    }
    case CollectionListActions.updateCollectionOrder: {
      return updateCollection(action, state);
    }
    case CollectionListActions.updateCollectionPage: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const collectionUpdate = {
        id: action.id,
        status: CollectionStatus.stale,
        offset: collection.offset + COLLECTION_ITEMS_PER_PAGE
      };

      return updateCollection(collectionUpdate, state);
    }
    case CollectionListActions.updateCollectionItems: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const items = updateList(collection.items, action.items, action.offset);

      const collectionUpdate = {
        id: action.id,
        items,
        totalItemCount: action.totalItemCount,
        status: CollectionStatus.complete
      };

      return updateCollection(collectionUpdate, state);
    }
    case CollectionListActions.refreshCollectionItems: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const collectionUpdate = {
        id: action.id,
        items: action.items,
        totalItemCount: action.totalItemCount,
        status: CollectionStatus.complete
      };

      return updateCollection(collectionUpdate, state);
    }
    case CollectionListActions.addCollection: {
      const { type: _, ...newCollection } = action;
      if (getCollection(newCollection.id, state)) {
        // add when collection already exists overwrites it
        return updateCollection({ ...newCollection, hidden: false }, state);
      }

      const updatedCollections = [
        { ...newCollection, hidden: false },
        ...state.collections
      ];

      return {
        ...state,
        collections: updatedCollections
      };
    }
    case CollectionListActions.removeCollection: {
      const updatedCollections = state.collections.filter(
        ({ id }) => id !== action.id
      );

      return {
        ...state,
        collections: updatedCollections
      };
    }
    case CollectionListActions.moveCollectionItem: {
      const { itemId, fromCollectionId, toCollectionId } = action;

      const fromCollection = getCollection(fromCollectionId, state);
      const toCollection = getCollection(toCollectionId, state);
      const itemToMove = fromCollection?.items.find(item => item.id === itemId);

      if (fromCollection && toCollection && itemToMove) {
        const updatedFromCollection = {
          ...fromCollection,
          items: fromCollection.items.filter(item => item.id !== itemId),
          status: CollectionStatus.complete,
          totalItemCount: fromCollection.items.length - 1
        };

        const updatedToCollection = {
          ...toCollection,
          items: [...toCollection.items, itemToMove].sort((a, b) => {
            if (a && b && "createdAt" in a && "createdAt" in b) {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            }
            return 0;
          }) as CollectionIndividualItem[],
          status: CollectionStatus.complete,
          totalItemCount: toCollection.items.length + 1
        };

        return updateCollection(
          updatedToCollection,
          updateCollection(updatedFromCollection, state)
        );
      }
      return state;
    }
    case CollectionListActions.hideCollection: {
      return updateCollection({ id: action.id, hidden: true }, state);
    }
    case CollectionListActions.showCollection: {
      return updateCollection({ id: action.id, hidden: false }, state);
    }
    case CollectionListActions.deleteCollectionItem: {
      const { itemId } = action;

      // Find collection where the item is present
      const collection = state.collections.find(
        collectionItem =>
          collectionItem.items.findIndex(item => item.id === itemId) > -1
      );

      if (collection) {
        const filteredCollectionItems = collection?.items.filter(
          item => item.id !== itemId
        );

        const updatedCollection = {
          ...collection,
          items: filteredCollectionItems,
          totalItemCount: filteredCollectionItems.length
        };

        return updateCollection(updatedCollection, state);
      }
      return state;
    }
    case CollectionListActions.addCollectionItem: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const newItems = [...collection.items, action.item].sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });

      const updatedCollection = {
        ...collection,
        items: newItems,
        totalItemCount: (collection.totalItemCount ?? 0) + 1
      };

      return updateCollection(updatedCollection, state);
    }
    case CollectionListActions.updateCollectionItem: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const itemToUpdate = collection.items.find(
        ({ id }) => id === action.item.id
      );

      if (!itemToUpdate) {
        return state;
      }
      const updatedItem = { ...itemToUpdate, ...action.item };

      const updatedCollection = {
        ...collection,
        items: collection.items.map(item =>
          item.id === updatedItem.id ? updatedItem : item
        )
      };

      // TODO: look into this typescript error
      // @ts-ignore
      return updateCollection(updatedCollection, state);
    }
    case CollectionListActions.enablePolling: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const updatedCollection = {
        ...collection,
        pollingEnabled: true
      };

      return updateCollection(updatedCollection, state);
    }
    case CollectionListActions.disablePolling: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const updatedCollection = {
        ...collection,
        pollingEnabled: false
      };

      return updateCollection(updatedCollection, state);
    }
    case CollectionListActions.updateCollectionFilters: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const updatedCollection = {
        ...collection,
        status: CollectionStatus.stale,
        filters: action.filters
      };

      return updateCollection(updatedCollection, state);
    }
    case CollectionListActions.updateCollectionInput: {
      const collection = getCollection(action.id, state);

      if (!collection) {
        return state;
      }

      const updatedCollection = {
        ...collection,
        status: CollectionStatus.stale,
        items: [],
        input: action.input,
        offset: 0,
        limit: COLLECTION_ITEMS_PER_PAGE
      };

      return updateCollection(updatedCollection, state);
    }
    default:
      return state;
  }
};
