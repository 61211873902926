import { styled } from "@linaria/react";

import Heading from "components/atoms/Heading";

import ModalContainer from "components/molecules/ModalContainer";

import { ReactComponent as Logo } from "img/icons/xapien-logo-icon.svg";

// @ts-ignore
import theme from "theme";

import { blue, grey, standardColors } from "styles/colors";

const CustomModalContainer = styled(ModalContainer)`
  .modal-content {
    padding: 3rem;
  }
`;

const XapienLogo = styled(Logo)`
  width: 48px;
  height: 48px;
  path {
    fill: ${blue.xapienLight};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`;

const Link = styled.div`
  color: ${theme.alternativePrimaryColor ?? blue.blueDiamond};
  text-decoration: none;
  cursor: pointer;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  input {
    width: 100%;
  }
`;

const Title = styled(Heading)`
  color: ${theme.alternativePrimaryColor ?? blue.blueDiamond};
`;

const Subtitle = styled(Heading)`
  color: ${standardColors.white};
`;

const Description = styled.p`
  margin: 0;
  text-align: center;
  color: ${standardColors.white};
`;

const Contact = styled.p`
  margin: 0;
  text-align: center;
  color: ${grey.ghost};
  a {
    color: ${blue.blueDiamond};
  }
`;

const BackButton = styled.button`
  all: unset;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const QRCodeContainer = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 1rem;
`;

const S = {
  QRCodeContainer,
  CustomModalContainer,
  Container,
  Title,
  Subtitle,
  Link,
  Inputs,
  XapienLogo,
  Description,
  BackButton,
  Contact,
  Main
};

export default S;
