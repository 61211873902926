import React, { HTMLAttributes, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import { Users as GroupIcon } from "react-feather";

import type { SearchResult } from "api/search";
import { SearchItemType } from "api/search";
import { getInitials } from "util/getInitials";

import S from "./styles";

const queryTypeMap: Record<SearchItemType, ReactNode> = {
  [SearchItemType.Group]: <GroupIcon />,
  [SearchItemType.User]: undefined,
  [SearchItemType.Organisation]: <S.Building />
};

const InputOption = (
  props: HTMLAttributes<HTMLLIElement>,
  { imageSrc, title, description, queryType }: SearchResult
) => {
  return (
    <li {...props}>
      <Stack alignItems="center" direction="row" spacing={2}>
        {imageSrc ? (
          <Avatar
            avatarSize={AvatarSize.Large}
            hasBorder
            alt={title}
            src={imageSrc}
          />
        ) : (
          <Avatar avatarSize={AvatarSize.Large} hasBorder>
            {queryTypeMap[queryType as SearchItemType] ?? getInitials(title)}
          </Avatar>
        )}
        <Stack>
          <Typography>{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </Stack>
    </li>
  );
};

export default InputOption;
