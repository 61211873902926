import { styled } from "@linaria/react";

import { standardColors, blue } from "styles/colors";
import { fontSize } from "styles/fonts";
import Popover from "components/atoms/Popover";
import ProfileButtonComponent from "components/molecules/ProfileButton";

const ProfilePopover = styled(Popover)`
  margin-left: auto;
`;

const MenuWrapper = styled.div`
  border-radius: 0.375rem;
`;

const ProfileHeader = styled.div`
  padding: 0.75rem;
  border-radius: 6px 6px 0px 0px;
  background-color: ${blue.panel};
  color: ${standardColors.white};
  font-size: ${fontSize.sm};
  display: flex;
  justify-content: space-between;
  gap: 12px;
  line-height: 1.5rem;
`;

const UserName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileHeaderLeft = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

const AdminLabel = styled.div`
  color: ${blue.xapienLight};
`;

const ProfileButton = styled(ProfileButtonComponent)`
  &:disabled {
    background-color: transparent !important;
  }
`;

const AssumedRoleLabel = styled(AdminLabel)`
  text-wrap: nowrap;
`;

const S = {
  ProfilePopover,
  MenuWrapper,
  ProfileHeader,
  UserName,
  ProfileHeaderLeft,
  AdminLabel,
  ProfileButton,
  AssumedRoleLabel
};

export default S;
