import { styled } from "@linaria/react";

import ButtonComponent from "components/atoms/Button";

import { ReactComponent as InformationSvgIcon } from "img/icons/information-icon.svg";
import { ReactComponent as XapienIconLogo } from "img/icons/xapien-logo-icon.svg";
import { ReactComponent as CrossInCircle } from "img/icons/cross-in-circle.svg";

import { family } from "styles/fonts";
import { blue } from "styles/colors";

const InlineNotification = styled.div<{ notificationColor: string }>`
  width: 100%;
  min-height: 100px;
  border-radius: 3px;
  background-color: rgba(35, 41, 71, 0.3);
  border-top-color: ${props => props.notificationColor};
  border-top-width: 2px;
  border-top-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 18px 35px 18px;
  text-align: center;
  margin-top: 10px;
  position: relative;
`;

const InformationIcon = styled(InformationSvgIcon)`
  height: 20px;
  width: 20px;

  path {
    fill: #ffd268;
  }
`;

const XapienLogo = styled(XapienIconLogo)`
  height: 22px;
  width: 22px;

  path {
    fill: ${blue.xapienLight};
  }
`;

const NotificationDescription = styled.div`
  font-family: ${family.interLight};
  padding-top: 10px;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;

  button {
    margin: 0 8px;
    min-height: 32px !important;
    padding: 0 20px !important;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  outline: none;
  border: none;

  &:active,
  &:focus {
    outline: none;
  }
`;

const CloseIcon = styled(CrossInCircle)`
  height: 20px;
  width: 20px;

  circle {
    stroke: transparent;
  }
`;

const PrimaryButton = styled(ButtonComponent)<{
  backgroundColor?: string;
  boxShadowColor?: string;
}>`
  background-color: ${props => {
    return props.backgroundColor ?? blue.xapienLight;
  }} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgba(40, 239, 250, 0.33)"} !important;
  }
`;

const SecondaryButton = styled(ButtonComponent)<{
  borderColor?: string;
  boxShadowColor?: string;
}>`
  border-color: ${props => {
    return props.borderColor ?? blue.xapienLight;
  }} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgba(40, 239, 250, 0.33)"} !important;
  }
`;

const S = {
  InformationIcon,
  XapienLogo,
  InlineNotification,
  NotificationDescription,
  ActionsContainer,
  CloseButton,
  CloseIcon,
  PrimaryButton,
  SecondaryButton
};

export default S;
