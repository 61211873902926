import { useContext } from "react";
import { HubAuthenticationContext } from "./context";
import { HubAuthenticationContextProvider } from "./provider";
import { HubAuthenticationActions } from "./types";

const useHubAuthentication = () => {
  const context = useContext(HubAuthenticationContext);

  if (!context) {
    throw new Error(
      "useHubAuthentication must be used within a HubAuthenticationContextProvider"
    );
  }

  return context;
};

export {
  HubAuthenticationContext,
  HubAuthenticationContextProvider,
  useHubAuthentication,
  HubAuthenticationActions
};
