import { styled } from "@linaria/react";

import { ReactComponent as TrashSVG } from "img/icons/trash.svg";

import { red } from "styles/colors";

const TrashIcon = styled(TrashSVG)`
  color: ${red.directRiskFill};
`;

const S = {
  TrashIcon
};

export default S;
