import React from "react";

import { AnimatePresence, motion } from "framer-motion";
import { Send } from "react-feather";

import Heading from "components/atoms/Heading";
import { green } from "styles/colors";

import S from "./styles";

interface Props {
  shareSuccess: {
    newShares: string[];
    success: boolean;
  };
}

const ShareConfirmedBanner = ({ shareSuccess }: Props) => {
  return (
    <AnimatePresence>
      {shareSuccess.success && (
        <S.ShareConfirmedBanner
          initial={{ height: 0, paddingTop: 0, paddingBottom: 0 }}
          animate={{
            height: 86,
            paddingTop: "1.375rem",
            paddingBottom: "1.75rem"
          }}
          exit={{ height: 0, paddingTop: 0, paddingBottom: 0 }}
          style={{ overflow: "hidden" }}
          transition={{ duration: 0.3 }}
        >
          <S.ShareConfirmedBannerInnerContainer>
            <Send />
            <Heading level={6}>
              Shared with {shareSuccess.newShares.join(", ")}
            </Heading>
          </S.ShareConfirmedBannerInnerContainer>
          <S.CheckContainer>
            <svg style={{ width: 36, height: 36 }} viewBox="0 0 36 36">
              <motion.path
                fill="none"
                strokeWidth="2"
                stroke={green.secondaryLight}
                d="M 8.25 17.8383 L 14.7155 24.5366 L 27 13.1494"
                strokeDasharray="0 1"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ delay: 0.5, duration: 0.4 }}
              />
            </svg>
          </S.CheckContainer>
        </S.ShareConfirmedBanner>
      )}
    </AnimatePresence>
  );
};

export default ShareConfirmedBanner;
