import React, { useMemo, useState, Fragment, memo } from "react";
import { SortFilterPill } from "./SortFilterPill";
import { DropdownItem } from "reactstrap";
import Toggle from "react-toggle";
import ReactDatePicker from "react-datepicker";
import ErrorBoundary from "util/ErrorBoundary";
import Chart from "./Chart";

export const DateSortFilterPill = memo(props => {
  const {
    dates,
    dateBounds,
    setDateBounds,
    showOnlyDatedContent,
    setShowOnlyDatedContent,
    potentialSourceCount
  } = props;
  const [immediateFeedbackBounds, setImmediateFeedbackBounds] = useState({});
  const sortedDates = useMemo(
    () =>
      dates
        .filter(d => {
          if (d && d.date) {
            if (Math.abs(d.date.getTime()) < 100) {
              console.warn("dodgy date - ignoring for now"); // masking issues where DateTime.Min has come through somewhere
              return false;
            } else {
              return true;
            }
          } else {
            return false;
          }
        })
        .sort((a, b) => a.date.getTime() - b.date.getTime()),
    [dates]
  );

  const minDate = sortedDates && sortedDates.length && sortedDates[0].date;
  const maxDate =
    sortedDates &&
    sortedDates.length &&
    sortedDates[sortedDates.length - 1].date;

  const reset = () => {
    setDateBounds({});
    setShowOnlyDatedContent(false);
  };
  const canReset =
    (dateBounds && (dateBounds.startDate || dateBounds.endDate)) ||
    showOnlyDatedContent;
  const active = canReset;
  const dateFormat = "MMM yyyy";
  const datePickerClassName = "date-picker-input";
  const immediateFeedbackLowerBound =
    immediateFeedbackBounds && immediateFeedbackBounds.start;
  const immediateFeedbackUpperBound =
    immediateFeedbackBounds && immediateFeedbackBounds.end;
  const datePickerError = e =>
    console.error("Error showing date picker", { e });

  const someDates = sortedDates && sortedDates.length;

  const setDateBoundsAndDatedContent = bounds => {
    setDateBounds(bounds);
    if (Object.keys(bounds).length !== 0) {
      setShowOnlyDatedContent(true);
    } else {
      setShowOnlyDatedContent(false);
    }
  };

  return (
    <SortFilterPill title="Date" {...{ canReset, reset, active }}>
      <div className="sort-filter-dropdown-menu-header">
        <div>In this report:</div> <div>{potentialSourceCount} items</div>
      </div>
      <DropdownItem divider />
      <div>Show date range:</div>
      <div className="date-chart-header">{}</div>
      <Chart
        dateBounds={dateBounds}
        setDateBounds={setDateBoundsAndDatedContent}
        sortedDates={sortedDates}
        immediateFeedbackBounds={immediateFeedbackBounds}
        setImmediateFeedbackBounds={setImmediateFeedbackBounds}
      />

      {someDates ? (
        <div className="date-range-container">
          <div className="date-range-start">
            <div className="date-range-label">From: </div>
            <ErrorBoundary
              FallbackComponent={Fragment}
              onError={datePickerError}
            >
              <ReactDatePicker
                {...{ dateFormat }}
                className={datePickerClassName}
                selected={
                  immediateFeedbackLowerBound ||
                  (dateBounds && dateBounds.startDate) ||
                  minDate
                }
                disabled
              />
            </ErrorBoundary>
          </div>
          <div className="date-range-end">
            <div className="date-range-label">To: </div>
            <ErrorBoundary
              FallbackComponent={Fragment}
              onError={datePickerError}
            >
              <ReactDatePicker
                {...{ dateFormat }}
                className={datePickerClassName}
                selected={
                  immediateFeedbackUpperBound ||
                  (dateBounds && dateBounds.endDate) ||
                  maxDate
                }
                disabled
              />
            </ErrorBoundary>
          </div>
        </div>
      ) : (
        ""
      )}
      <div style={{ display: someDates ? "none" : "" }}>
        None of the currently selected sources have dates - try removing some
        filters
      </div>
      <DropdownItem divider />
      <div className="toggle-filter">
        <div className="toggle-filter-label">Show only dated content</div>
        <Toggle
          className="toggle-filter-inner"
          checked={showOnlyDatedContent}
          icons={false}
          onChange={() => setShowOnlyDatedContent(!showOnlyDatedContent)}
          value="yes"
        />
      </div>
    </SortFilterPill>
  );
});
