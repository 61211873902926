import React, { ReactNode, useMemo, useState } from "react";

import ViewerModeContext from "./context";

interface Props {
  children: ReactNode;
  enabled: boolean;
}

const ViewerModeContextProvider = ({ children, enabled = false }: Props) => {
  const [isViewerModeEnabled, setIsViewerModeEnabled] = useState(enabled);
  const [isSharedReportLink, setIsSharedReportLink] = useState(true);

  const providerValue = useMemo(
    () => ({
      isViewerModeEnabled,
      isSharedReportLink,
      setIsSharedReportLink,
      setIsViewerModeEnabled
    }),
    [isViewerModeEnabled, isSharedReportLink]
  );

  return (
    <ViewerModeContext.Provider value={providerValue}>
      {children}
    </ViewerModeContext.Provider>
  );
};

export default ViewerModeContextProvider;
