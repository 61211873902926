import React, { FC, ReactNode } from "react";

import { Outlet } from "react-router-dom";

import { HubAuthenticationContextProvider } from "util/hooks/useHubAuthentication";

import HubNavigationBar from "components/organisms/HubNavigationBar";

import S from "./styles";

const ShellProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <HubAuthenticationContextProvider loginPath="/hub/login">
      {children}
    </HubAuthenticationContextProvider>
  );
};

const HubShell = () => {
  return (
    <ShellProviders>
      <S.Shell>
        <HubNavigationBar />
        <S.Main>
          <Outlet />
        </S.Main>
      </S.Shell>
    </ShellProviders>
  );
};

export default HubShell;
