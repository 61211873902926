import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import {
  grey,
  yellow,
  purple,
  blue,
  red,
  green,
  standardColors
} from "styles/colors";
import { fontSize, family } from "styles/fonts";

import WithInspector from "components/organisms/WithInspector";
import SocialMediaIcons from "components/molecules/SocialMediaIcons";

const ROLE_COLOR_MAP = {
  UBO: yellow.mud,
  PSC: blue.blueGreen,
  Officer: purple.dark,
  Secretary: red.shallow,
  Founder: green.mid
};

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CardTopSection = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
`;

const DescriptionSection = styled.div`
  margin-top: 8px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  word-break: break-word;
`;

const SocialMediaWithInspector = styled(WithInspector)`
  padding-top: 6px;
`;

const SocialMediaIconsContainer = styled.div`
  display: flex;
  pointer-events: none;
`;

const CardSummary = styled.div`
  color: ${grey.mid};
`;

const NameField = styled.div`
  font-size: ${fontSize.md};
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
  max-height: 24px;
`;

const DurationPills = styled.div`
  text-align: left;
  line-height: 1.25;
  padding: 4px 0;
`;

const NoDate = styled.span`
  color: ${grey.mid};
`;

const RoleDuration = styled.span`
  color: ${({ isSelected }) =>
    isSelected ? standardColors.white : grey.mid} !important;
  font-size: ${fontSize.sm};

  ${NoDate} {
    color: ${({ isSelected }) =>
      isSelected ? standardColors.white : grey.mid};
  }
`;

const PillsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const RolePill = styled.div`
  padding: 0px 8px;
  border: 1px solid ${grey.ghost};
  border-radius: 16px;
  margin-right: 5px;
  transition: box-shadow 0.2s;
  font-size: ${fontSize.sm};
  text-transform: capitalize;
  opacity: 0.6;
  border-color: ${({ hasRoleEnded, isKnownToBeInactive, roleType }) => {
    return hasRoleEnded || !ROLE_COLOR_MAP[roleType] || isKnownToBeInactive
      ? grey.ghost
      : ROLE_COLOR_MAP[roleType];
  }};
  color: ${({ hasRoleEnded, isKnownToBeInactive, roleType }) => {
    return hasRoleEnded || !ROLE_COLOR_MAP[roleType] || isKnownToBeInactive
      ? grey.ghost
      : ROLE_COLOR_MAP[roleType];
  }};

  &:last-child {
    margin-right: 0;
  }
`;

const ListItem = styled.div`
  margin-bottom: 8px;
  padding: 0;

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 6px;
  }
`;

const RoleTitle = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  line-height: 20px;
`;

const RoleDate = styled.div`
  font-size: ${fontSize.sm};
`;

const Rule = styled.hr`
  color: ${grey.rule};
  margin: 0;
  margin: 8px 0;
`;

const Info = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  white-space: nowrap;
`;

const RolesSinceStartLabel = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  white-space: nowrap;
`;

const OnlineProfilesInfo = styled(Info)`
  color: ${blue.icon};
  margin-top: 3px;
`;

const PillsInfo = styled(Info)`
  color: ${grey.mid};
`;

const LocationText = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  word-break: break-word;
  text-align: left;
  display: flex;
  align-items: center;
`;

const LocationName = styled.div`
  max-width: 180px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 5px;
`;

const DescriptionContainer = styled.div`
  margin-top: -0.5px;
`;

const MissingData = styled.p`
  font-size: ${fontSize.sm};
  font-style: italic;
  margin: 0;
`;

const NoActiveRoles = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.ghost};
`;

const StyledWithInspector = styled(WithInspector)`
  margin-top: 8px;
`;

const InspectorSocialMediaIcons = styled(SocialMediaIcons)`
  padding: 2px 0 3px 0;
  a {
    color: ${({ isSelected }) =>
      isSelected ? standardColors.white : grey.dark} !important;
  }

  path {
    fill: ${({ isSelected }) =>
      isSelected ? standardColors.white : blue.icon} !important;
  }
`;

const RoleContainer = styled.div`
  padding-bottom: 6px;
`;

const MediaHandlerContainer = styled.div`
  padding-bottom: 6px;
`;

const LatestRoleDuration = styled.span``;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /**
    These styles are for "inactive" profile cards (determined by the isKnownToBeInactive prop).
    They are placed here to prevent needing to pass "isKnownToBeInactive" to every required component.

    Note that for implementation reason, "RolePill" remains handling its own logic when
    dealing with the colour changes based on props.
  */
  ${RoleTitle}, ${NameField}, ${DescriptionSection} {
    color: ${({ isKnownToBeInactive }) =>
      isKnownToBeInactive ? grey.ghost : grey.dark};
  }

  ${PillsInfo}, ${LatestRoleDuration}, ${RolesSinceStartLabel}, ${LocationText}, ${MissingData} {
    color: ${({ isKnownToBeInactive }) =>
      isKnownToBeInactive ? grey.ghost : grey.mid} !important;
  }

  ${SocialMediaIconsContainer} {
    filter: ${({ isKnownToBeInactive }) =>
      isKnownToBeInactive ? "grayscale(1)" : "none"};
    opacity: ${({ isKnownToBeInactive }) =>
      isKnownToBeInactive ? "0.6" : "1"};
  }
`;

const pillClassName = css`
  border-radius: 16px;
  padding: 3px 10px;
`;

const S = {
  CardContent,
  CardTopSection,
  DescriptionSection,
  Image,
  SocialMediaWithInspector,
  SocialMediaIconsContainer,
  CardSummary,
  NameField,
  RoleDuration,
  PillsContainer,
  RolePill,
  RoleTitle,
  RoleDate,
  ListItem,
  Rule,
  Info,
  RolesSinceStartLabel,
  OnlineProfilesInfo,
  PillsInfo,
  LocationText,
  LocationName,
  DescriptionContainer,
  MissingData,
  NoDate,
  NoActiveRoles,
  StyledWithInspector,
  DurationPills,
  InspectorSocialMediaIcons,
  RoleContainer,
  MediaHandlerContainer,
  LatestRoleDuration,
  ContentContainer
};

export const classNameOverrides = {
  pillClassName
};

export default S;
