import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { grey, blue, standardColors } from "styles/colors";
import { ReactComponent as InformationIconSvg } from "img/icons/information-icon.svg";
import { fontSize, family } from "styles/fonts";
import InfoIconComponent from "components/atoms/InfoIcon";
import ReactTooltip from "components/atoms/ReactTooltip";

export interface LocationOrgNameProps {
  isDatePresent: boolean;
}

const LocationRolesWrapper = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }
`;

const RoleSectionTitle = styled.div`
  color: ${grey.mid};
`;

const LocationRoleItem = styled.div`
  display: flex;
  align-items: center;
`;

const LocationRolesList = styled.div`
  max-height: 243px;
  overflow: auto;

  & > div {
    max-width: 274px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const InformationIcon = styled(InformationIconSvg)`
  height: 15px;
  margin-left: 1px;
  &:hover {
    cursor: pointer;
    path {
      fill: ${blue.link};
    }
  }
`;

const RoleYear = styled.span`
  font-family: ${family.interLight};
  color: ${grey.mid};
  padding-left: 3px;
`;

const LocationOrgName = styled.div<LocationOrgNameProps>`
  max-width: ${({ isDatePresent }) => (isDatePresent ? "217px" : "255px")};
`;

const InfoIcon = styled(InfoIconComponent)`
  margin-bottom: 3px;
`;

//
// Tooltip
//

const TooltipContainer = styled.div`
  background: ${standardColors.white};
  text-align: left;
  border-radius: 4px;
  font-size: ${fontSize.sm};
  -webkit-column-break-inside: avoid;
`;

const Tooltip = styled(ReactTooltip)`
  margin-top: -8px;
  height: 15px;
`;

const TooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding-bottom: 6px;
`;

const TooltipBody = styled.div`
  padding-top: 6px;
`;

const AddressTooltip = styled.div`
  text-align: left;
`;

const AddressDate = styled.div`
  padding-bottom: 8px;
`;

const ShowAllButton = styled.button`
  color: ${grey.mid};
  border: none;
  background: none;
  padding: 0;
  border-bottom: 1px solid;
  border-color: transparent;
  font-size: ${fontSize.sm};
  padding-top: 10px;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    border-color: ${grey.mid};

    &:focus {
      border-color: ${grey.dark};
    }
  }

  &:focus {
    outline: none;
    color: ${grey.dark};
  }
`;

//
// CSS Overrides
//

const infoIconTooltipContent = css`
  width: auto;
`;

const S = {
  LocationRolesWrapper,
  RoleSectionTitle,
  LocationRoleItem,
  LocationRolesList,
  InformationIcon,
  RoleYear,
  LocationOrgName,
  InfoIcon,
  TooltipContainer,
  Tooltip,
  TooltipBody,
  TooltipHeader,
  AddressTooltip,
  AddressDate,
  ShowAllButton
};

const classNameOverrides = {
  infoIconTooltipContent
};

export default S;
export { classNameOverrides };
