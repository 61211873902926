import { styled } from "@linaria/react";

import { ReactComponent as ArrowDown } from "img/icons/arrow-down.svg";
import { standardColors, grey } from "styles/colors";
import { fontSize } from "styles/fonts";

import CommonButton from "components/atoms/Button";

const ShowDetailButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

const DetailButtonDividerLine = styled.div`
  height: 1px;
  background-color: ${grey.rule};
  flex-grow: 0.5;
`;
const ButtonAndArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  align-items: baseline;
`;

const Button = styled(CommonButton)`
  color: ${standardColors.white} !important;
  min-height: 27px !important;
  min-width: 120px !important;
  font-size: ${fontSize.sm} !important;
  margin: 0 12px;
`;

const ChevronDown = styled(ArrowDown)`
  path {
    fill: ${standardColors.white};
  }

  transform: rotate(
    ${({ className }) => (className === "isResultsExpanded" ? 180 : 0)}deg
  );
`;

const S = {
  ShowDetailButtonContainer,
  DetailButtonDividerLine,
  ChevronDown,
  ButtonAndArrowContainer,
  Button
};

export default S;
