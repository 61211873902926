/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PortalMockAPI_Contracts_Enquiries_StartEnquiryRequest } from "../models/PortalMockAPI_Contracts_Enquiries_StartEnquiryRequest";
import type { PortalMockAPI_Contracts_Enquiries_StartEnquiryResponse } from "../models/PortalMockAPI_Contracts_Enquiries_StartEnquiryResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class EnquiryService {
  /**
   * @returns PortalMockAPI_Contracts_Enquiries_StartEnquiryResponse Success
   * @throws ApiError
   */
  public static postEnquiriesStartEnquiry({
    requestBody
  }: {
    requestBody?: PortalMockAPI_Contracts_Enquiries_StartEnquiryRequest;
  }): CancelablePromise<PortalMockAPI_Contracts_Enquiries_StartEnquiryResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/enquiries/start-enquiry",
      body: requestBody,
      mediaType: "application/json"
    });
  }
}
