import React, { FC } from "react";
import { TimelineEvent } from "api/insights";
import DateTag from "components/organisms/InsightReport/TimelineSection/DateTag";
import { ALIGNMENT_OPTIONS } from "components/organisms/InsightReport/TimelineSection/constants";
import useInsightsFeatures from "util/hooks/useInsightsFeatures";

// @ts-ignore
import theme from "theme";
import { red, orange } from "styles/colors";

import S from "./styles";

export interface EventNodeProps {
  event: TimelineEvent;
  alignment: string;
  nodeSize: number;
  isFirst: boolean;
}

const EventNode: FC<EventNodeProps> = ({
  event,
  alignment,
  isFirst,
  nodeSize
}) => {
  const { timelinesRiskColors } = useInsightsFeatures();

  const getColor = (): string => {
    if (!timelinesRiskColors) {
      return theme.primaryColor;
    }

    const riskSignificance = event.risk_categories.map(x => x.significance);
    if (riskSignificance.includes("Direct")) {
      return red.directRisk;
    }

    if (riskSignificance.includes("Indirect")) {
      return orange.indirectRiskFill;
    }

    return theme.primaryColor;
  };

  const color = getColor();

  return (
    <div>
      <S.EventNodeContainer alignment={alignment}>
        <S.EventNodeOuterContainer size={nodeSize}>
          <S.EventNode size={nodeSize} color={color}>
            <S.InnerEventNodeCircle size={nodeSize} color={color} />
          </S.EventNode>
        </S.EventNodeOuterContainer>
        <S.EventLine nodeSize={nodeSize} color={color} />
        <DateTag
          event={event}
          isRight={alignment === ALIGNMENT_OPTIONS.right}
          isFirst={isFirst}
        />
      </S.EventNodeContainer>
    </div>
  );
};

export default EventNode;
