import React from "react";
import useObservability from "util/hooks/useObservability";

import Checkbox from "components/atoms/Checkbox";
import arrow from "img/icons/arrow.svg";
import TruncateLength from "util/TruncateLength";
import ReactTooltip from "components/atoms/ReactTooltip";
import { blue } from "styles/colors";

import S from "./styles";

const SortFilterCategory = props => {
  const { captureError } = useObservability();
  const {
    selected,
    partiallySelected,
    currentAvailable,
    setSelected,
    display,
    titleCase,
    italics,
    tier,
    expandable,
    expanded,
    setExpanded,
    isInSnapshot,
    listOfNameVariants = ""
  } = props;
  const disabled = false;
  const faded = disabled || !currentAvailable || currentAvailable < 1;
  const hierarchicalControl = tier !== undefined;
  const handleUpdate = () => {
    if (!disabled) {
      if ((partiallySelected && !selected) || selected) {
        setSelected(false);
      } else {
        setSelected(true);
      }
    }
  };
  const toggleExpanded = () => setExpanded(!expanded);
  const arrowWidth = 15;
  const stringToDisplay = titleCase ? display.toLowerCase() : display;
  const arrowClicked = e => {
    toggleExpanded();
    e.stopPropagation();
    return false;
  };

  try {
    return (
      <S.SortFilterFacet
        onClick={handleUpdate}
        role="button"
        aria-label={`${stringToDisplay}`}
        isInSnapshot={isInSnapshot}
        faded={faded}
        paddingLeft={tier}
      >
        <S.SortFilterFacetContents italics={italics} capitalize={titleCase}>
          {hierarchicalControl ? (
            <S.Arrow
              src={arrow}
              alt="Arrow symbol pointing right"
              style={{ visibility: expandable ? "visible" : "hidden" }}
              onClick={arrowClicked}
              width={arrowWidth}
              height={arrowWidth}
              expanded={expanded}
            />
          ) : (
            ""
          )}
          {!isInSnapshot && (
            <Checkbox
              readOnly
              checked={selected}
              indeterminate={partiallySelected}
              checkedColor={blue.icon}
            />
          )}
          <S.SortFilterPillLabel
            isNameVariantPresent={listOfNameVariants.length}
          >
            {listOfNameVariants.length ? (
              <ReactTooltip tooltip={listOfNameVariants}>
                {stringToDisplay}
              </ReactTooltip>
            ) : (
              <TruncateLength>{stringToDisplay}</TruncateLength>
            )}
          </S.SortFilterPillLabel>
        </S.SortFilterFacetContents>
        <div> {currentAvailable || 0}</div>
      </S.SortFilterFacet>
    );
  } catch (e) {
    captureError(e);
    return (
      <div>Could not display sort and filter menu item due to an error</div>
    );
  }
};

export default SortFilterCategory;
