import React, {
  useState,
  ReactNode,
  FC,
  useReducer,
  useCallback,
  useMemo,
  useEffect
} from "react";
import useUserSettings from "util/hooks/useUserSettings";
import type ReportsApi from "api/reports";
import type UsersApi from "api/users";
import type GroupsApi from "api/groups";
import type UserReportsApi from "api/userReports";
import type GroupReportsApi from "api/groupReports";
import type OrganisationsApi from "api/hub/organisations";
import type HubUsersApi from "api/hub/users";
import type HubReportsApi from "api/hub/reports";
import type ReportPersonasApi from "api/reportPersonas/ReportPersonas";

import { collectionListReducer, CollectionListContext } from "./context";
import {
  CollectionStatus,
  CollectionListAction,
  CollectionListActions,
  CollectionInputType,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionIndividualItem,
  SearchCollectionInput,
  ListCollectionInput,
  CollectionItem
} from "./types";

type ListableApi =
  | typeof ReportsApi
  | typeof UsersApi
  | typeof GroupsApi
  | typeof OrganisationsApi
  | typeof UserReportsApi
  | typeof GroupReportsApi
  | typeof HubUsersApi
  | typeof HubReportsApi
  | typeof ReportPersonasApi;

type ListableApiInstance =
  | ReportsApi
  | UsersApi
  | GroupsApi
  | OrganisationsApi
  | UserReportsApi
  | GroupReportsApi
  | HubUsersApi
  | HubReportsApi
  | ReportPersonasApi;

type SearchableApiInstance =
  | ReportsApi
  | UsersApi
  | GroupsApi
  | OrganisationsApi
  | UserReportsApi
  | GroupReportsApi
  | HubUsersApi
  | HubReportsApi
  | ReportPersonasApi;

interface Props {
  Api: ListableApi;
  children: ReactNode;
}

const refreshList = ({
  id,
  api,
  dispatch,
  input,
  items,
  offset,
  userId
}: {
  id: string;
  api: ListableApiInstance;
  dispatch: (action: CollectionListAction) => void;
  input: ListCollectionInput;
  offset: number;
  items: CollectionIndividualItem[];
  userId: string;
}) => {
  api
    .list({ ...input, id, limit: items.length, offset: 0, userId })
    .then(({ items: newItems, totalItemCount: newTotalItemCount }) => {
      dispatch({
        type: CollectionListActions.refreshCollectionItems,
        id,
        items: newItems,
        totalItemCount: newTotalItemCount
      });
    })
    .catch(e => {
      console.error("Error loading list", {
        error: e,
        input,
        id,
        offset
      });
    });
};

const refreshSearch = ({
  id,
  api,
  items,
  dispatch,
  offset,
  input,
  userId
}: {
  id: string;
  api: ListableApiInstance;
  dispatch: (action: CollectionListAction) => void;
  input: SearchCollectionInput;
  offset: number;
  items: CollectionIndividualItem[];
  userId: string;
}) => {
  const { query, searchTags } = input;
  api
    .search({
      ...input,
      query,
      offset: 0,
      limit: items.length,
      searchTags,
      userId
    })
    .then(response => {
      dispatch({
        type: CollectionListActions.refreshCollectionItems,
        id,
        items: response.items,
        totalItemCount: response.totalItemCount
      });
    })
    .catch(e => {
      console.error("Error loading search results", {
        error: e,
        query,
        id,
        offset
      });
    });
};

const updateList = ({
  id,
  api,
  dispatch,
  input,
  limit,
  offset,
  userId
}: {
  id: string;
  api: ListableApiInstance;
  dispatch: (action: CollectionListAction) => void;
  input: ListCollectionInput;
  limit: number;
  offset: number;
  userId: string;
}) => {
  api
    .list({ ...input, id, limit, offset, userId })
    .then(({ items: newItems, totalItemCount: newTotalItemCount }) => {
      dispatch({
        type: CollectionListActions.updateCollectionItems,
        id,
        items: newItems,
        totalItemCount: newTotalItemCount,
        offset
      });
    })
    .catch(e => {
      console.error("Error loading list", {
        error: e,
        input,
        id,
        offset
      });
    });
};

const updateSearch = ({
  id,
  api,
  dispatch,
  offset,
  limit,
  input,
  userId
}: {
  id: string;
  api: SearchableApiInstance;
  dispatch: (action: CollectionListAction) => void;
  input: SearchCollectionInput;
  offset: number;
  limit: number;
  userId: string;
}) => {
  const { query, searchTags } = input;

  api
    .search({ ...input, query, offset, limit, searchTags, userId })
    .then(response => {
      dispatch({
        type: CollectionListActions.updateCollectionItems,
        id,
        items: response.items,
        offset,
        totalItemCount: response.totalItemCount
      });
    })
    .catch(e => {
      console.error("Error loading search results", {
        error: e,
        query,
        id,
        offset
      });
    });
};

export const CollectionListContextProvider: FC<Props> = ({ Api, children }) => {
  const [collectionStatuses, setCollectionStatuses] = useState<
    CollectionStatus[]
  >([]);
  const {
    state: {
      userDetails: { userId }
    }
  } = useUserSettings();

  const [timeoutPageLocation] = useState(window.location.href);
  const [isTimeoutEnabled, setIsTimeoutEnabled] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const api = useMemo(() => new Api(), [Api]);
  const initialState = api.getEmptyCollections();
  const [state, reducerDispatch] = useReducer(
    collectionListReducer,
    initialState
  );

  const dispatch = useCallback((action: CollectionListAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const onCollectionsUpdated = useCallback((): void => {
    // If there is already a timeout enabled, don't try to set up any new ones
    if (isTimeoutEnabled) {
      return;
    }

    setIsTimeoutEnabled(true);

    setTimeout(() => {
      // Handle when a timeout has been set up, but the page has been navigated away from
      if (window.location.href === timeoutPageLocation) {
        setIsRefreshing(true);
      }
      setIsTimeoutEnabled(false);
    }, 10000);
  }, [isTimeoutEnabled, timeoutPageLocation]);

  const refreshCollections = useCallback(() => {
    state.collections.forEach(
      ({ id, offset, input, items, pollingEnabled }) => {
        switch (input.type) {
          case CollectionInputType.list: {
            // TODO: Handle polling when you've got pages of data
            if (
              items.length > COLLECTION_ITEMS_PER_PAGE ||
              !pollingEnabled ||
              document.hidden
            ) {
              break;
            }

            refreshList({
              id,
              api,
              items,
              dispatch,
              input,
              offset,
              userId
            });
            break;
          }
          case CollectionInputType.search: {
            if (
              items.length > COLLECTION_ITEMS_PER_PAGE ||
              !pollingEnabled ||
              document.hidden
            ) {
              break;
            }

            refreshSearch({
              id,
              api,
              items,
              dispatch,
              offset,
              input,
              userId
            });
            break;
          }
          default:
        }
      }
    );

    onCollectionsUpdated();
  }, [dispatch, state.collections, api, onCollectionsUpdated, userId]);

  const updateCollection = useCallback(
    ({ id, limit, offset, input }: CollectionItem) => {
      dispatch({
        type: CollectionListActions.updateCollectionStatus,
        id,
        status: CollectionStatus.loading
      });

      switch (input.type) {
        case CollectionInputType.list:
          updateList({
            id,
            api,
            dispatch,
            input,
            limit,
            offset,
            userId
          });
          break;
        case CollectionInputType.search:
          updateSearch({
            id,
            api,
            dispatch,
            input,
            limit,
            offset,
            userId
          });
          break;
        default:
          break;
      }
    },
    [dispatch, api, userId]
  );

  useEffect(() => {
    // Check to ensure we don't set up refreshing while refreshing
    if (isRefreshing) {
      // Setup auto refreshing
      refreshCollections();
    }

    setIsRefreshing(false);
  }, [isRefreshing, refreshCollections]);

  useEffect(() => {
    // Check if the collection statuses have changed as that is what determines whether to load new data
    if (
      state.collections.length === collectionStatuses.length &&
      state.collections.every(
        (collection, index) => collection.status === collectionStatuses[index]
      )
    ) {
      return;
    }

    state.collections.forEach(collection => {
      const { status, totalItemCount, items } = collection;

      // Only update collection if the state is stale
      if (status !== CollectionStatus.stale) {
        return;
      }

      // Don't update if we've loaded full list
      if (totalItemCount && items.length >= totalItemCount) {
        return;
      }

      updateCollection(collection);
    });

    // Stash the collection statuses separate to the collections - this is what actually determines whether to load new data
    setCollectionStatuses(state.collections.map(({ status }) => status));

    // Callback to setup polling
    onCollectionsUpdated();
  }, [
    state.collections,
    collectionStatuses,
    onCollectionsUpdated,
    updateCollection
  ]);

  return (
    <CollectionListContext.Provider value={providerValue}>
      {children}
    </CollectionListContext.Provider>
  );
};
