import { styled } from "@linaria/react";

import { purple, grey, standardColors } from "styles/colors";
import { family } from "styles/fonts";
import { zIndex } from "styles/zIndex";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";

export interface SectionSelectSelectedItemProps {
  open: boolean;
}

export interface SectionDropdownItemProps {
  active: boolean;
}

const SectionLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: ${family.interDisplay};
  margin-bottom: 16px;
`;

const SectionSelect = styled.div`
  flex: 1;
  border-radius: 4px;
  border: 0;
  background: ${grey.panel};
  padding: 12px 16px;
  font-family: ${family.interDisplay};
  position: relative;
  z-index: ${zIndex.l1};
`;

const SectionDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  background-color: ${standardColors.white};
  border-radius: 6px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.5);
  max-height: 350px;
  overflow-y: auto;
`;

const SectionDropdownItem = styled.div<SectionDropdownItemProps>`
  padding: 12px 16px;
  background-color: ${({ active }) =>
    active ? (isPDX ? theme.primaryColor : purple.dark) : standardColors.white};
  color: ${({ active }) =>
    active ? standardColors.white : standardColors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background-color: ${isPDX ? theme.primaryColor : purple.light};
    color: ${isPDX ? standardColors.white : purple.dark};
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

const SectionSelectSelectedItem = styled.div<SectionSelectSelectedItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
    transform: ${({ open }) => (open ? "rotate(0.5turn)" : "none")};
  }
`;

const S = {
  SectionSelect,
  SectionLabel,
  SectionDropdown,
  SectionDropdownItem,
  SectionSelectSelectedItem
};

export default S;
