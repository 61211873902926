import { styled } from "@linaria/react";

import Button from "components/atoms/Button";

import { grey, red } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import { fontSize } from "styles/fonts";

import TagComponent from "components/atoms/Tag";

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin: 0.1rem;
  }
`;

const SiteRiskTag = styled(TagComponent)`
  background-color: ${() => {
    const { r, g, b } = hexToRgb(red.dark);
    return `rgba(${r}, ${g}, ${b}, 0.3)`;
  }} !important;

  &:hover {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(red.dark);
      return `rgba(${r}, ${g}, ${b}, 1)`;
    }} !important;
  }
`;

const TooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding-bottom: 6px;
`;

const TooltipContent = styled.div`
  padding-top: 6px;
`;

const RiskCountButton = styled(Button)`
  background-color: ${grey.faint} !important;
  font-size: ${fontSize.sm};
  color: ${grey.mid} !important;
  padding: 0 10px !important;
  min-height: 21px !important;
  border: 1px solid transparent !important;
  min-width: 10px !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${() => {
        const { r, g, b } = hexToRgb(grey.ghost);
        return `rgba(${r}, ${g}, ${b}, 0.3)`;
      }} !important;
  }
`;

const S = {
  TagsContainer,
  SiteRiskTag,
  TooltipHeader,
  TooltipContent,
  RiskCountButton
};

export default S;
