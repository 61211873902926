import { styled } from "@linaria/react";

import Heading from "components/atoms/Heading";

import { fontSize } from "styles/fonts";
import { grey, standardColors, blue, orange } from "styles/colors";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;

  input {
    width: 100%;
  }
`;

const Title = styled(Heading)`
  color: ${standardColors.white};
`;

const Subtitle = styled.p`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  margin: 0;
`;

const Divider = styled.hr`
  border-top: 1px solid ${standardColors.white};
  marign: 0;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;

  > * {
    flex: 1;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  gap: 0.5rem;
`;

const Link = styled.a`
  color: ${blue.blueDiamond};
`;

const PasswordInvalid = styled.div`
  color: ${orange.amber};
`;

const PasswordValid = styled.div`
  color: ${blue.blueDiamond};
`;

const ContactSupportLink = styled.a`
  color: ${blue.icon} !important;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
`;

const S = {
  ContactSupportLink,
  Container,
  Description,
  Title,
  Subtitle,
  Inputs,
  Divider,
  ButtonsContainer,
  CheckboxLabel,
  Link,
  PasswordInvalid,
  PasswordValid
};

export default S;
