import React from "react";

import { NavLink, useLocation, useSearchParams } from "react-router-dom";

import { routes } from "pages/Router";

import { useHubAuthentication } from "util/hooks/useHubAuthentication";
import { HubAuthenticationStatus } from "util/hooks/useHubAuthentication/types";

import Menu from "./Menu";

import S from "./styles";

const HubNavigationBar = () => {
  const { pathname } = useLocation();
  const route = pathname.split("/").pop();
  const [searchParams] = useSearchParams();
  const org = searchParams.get("org");

  const {
    state: { status }
  } = useHubAuthentication();

  let pageTitle = "Manage client organisations";
  if (route === routes.createOrg) {
    pageTitle = "Create new organisation";
  } else if (pathname.match(routes.manageOrg)) {
    pageTitle = "Manage organisation";
  } else if (org) {
    pageTitle = org.charAt(0).toUpperCase() + org.slice(1);
  }

  return (
    <S.Container>
      <NavLink to="/hub">
        <S.Logo />
      </NavLink>
      {status === HubAuthenticationStatus.authenticated && (
        <>
          <S.PageTitle level={4}>{pageTitle}</S.PageTitle>
          <Menu />
        </>
      )}
    </S.Container>
  );
};

export default HubNavigationBar;
