import { styled } from "@linaria/react";

import { grey, red, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const FlagCardContainer = styled.div`
  border-radius: 11px;
  background-color: ${grey.panel};
  color: ${grey.dark};
  font-size: ${fontSize.sm};
`;

const FlagBanner = styled.div`
  height: 36px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  border-bottom: none !important;
  border: 1px solid ${props => (props.isActive ? red.directRisk : grey.mid)};
  color: ${props => (props.isActive ? red.directRisk : grey.mid)};
  font-size: ${fontSize.md};
  background-color: ${standardColors.white};
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-family: ${family.interSemiBold};
`;

const FlagContent = styled.div`
  padding: 0 16px;
  border: 1px solid ${props => (props.isActive ? red.directRisk : grey.mid)};
  border-top: none;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  max-height: 390px;
  overflow: auto;

  & > div:last-of-type {
    border: none;
  }
`;

const ListGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  color: ${props => (props.isActive ? grey.dark : grey.mid)};

  border-bottom: 1px solid ${grey.rule};
`;

const ActiveLabel = styled.span`
  color: ${props => (props.isActive ? red.directRisk : grey.mid)};
`;

const S = {
  FlagCardContainer,
  FlagBanner,
  FlagContent,
  ListGroup,
  ActiveLabel
};

export default S;
