import React, { FC, SVGProps, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import ModalContainer, { Variant } from "components/molecules/ModalContainer";
import ErrorBanner from "components/atoms/ErrorBanner";
import { ButtonType, ButtonSize } from "components/atoms/ButtonNew/types";
import {
  InsightsTimelineActions,
  InsightsTimelineStatus
} from "util/hooks/useInsightsTimeline/types";
import { useInsightsTimeline } from "util/hooks/useInsightsTimeline";
import { TimelineEvent } from "api/insights";
import EventContent from "components/organisms/InsightReport/TimelineSection/Event/EventContent/index";

import { ReactComponent as Icon } from "./trash_icon.svg";

import S from "./styles";

interface Props {
  isOpen: boolean;
  event?: TimelineEvent;
  onCloseModal: () => void;
}

const DeleteTimeLineEventModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  event
}) => {
  const { state, dispatch } = useInsightsTimeline();

  const [submissionError, setSubmissionError] = useState<undefined | string>(
    state.errorMessage
  );
  const [showSecondStage, setShowSecondStage] = useState<boolean>(false);

  useEffect(() => {
    if (
      event &&
      state.status === InsightsTimelineStatus.deletingEventSuccess &&
      !showSecondStage
    ) {
      setShowSecondStage(true);
      setTimeout(() => {
        onCloseModal();
        setShowSecondStage(false);
      }, 1000);
    }
  }, [state, onCloseModal, event, showSecondStage]);

  const onDismissError = () => {
    setSubmissionError(undefined);
  };

  const handleDelete = () => {
    if (!event) return;
    dispatch({
      type: InsightsTimelineActions.deleteTimeLineEvent,
      eventId: event.id
    });
  };

  const isDeletingEvent = state.status === InsightsTimelineStatus.deletingEvent;

  if (showSecondStage) {
    return (
      <ModalContainer
        isOpen={showSecondStage}
        toggleOpen={onCloseModal}
        onExitClick={onCloseModal}
        variant={Variant.LIGHT}
        fade={false}
      >
        <S.DeleteTimelineModalContainer>
          <S.IconContainer>
            <Icon />
          </S.IconContainer>
          <S.DeleteEventText>Event Deleted</S.DeleteEventText>
        </S.DeleteTimelineModalContainer>
      </ModalContainer>
    );
  }

  if (!event) {
    return null;
  }

  return (
    <ModalContainer
      title="Delete this event"
      isOpen={isOpen}
      toggleOpen={onCloseModal}
      onExitClick={onCloseModal}
      variant={Variant.LIGHT}
      fade={false}
    >
      <p>
        Are you sure you want to delete this event?
        <br />
        This cannot be undone.
      </p>

      <S.ContextTextArea>
        <S.ContentWrapper>
          <EventContent event={event} />
        </S.ContentWrapper>
      </S.ContextTextArea>

      {submissionError && (
        <ErrorBanner text={submissionError} onClick={onDismissError} />
      )}

      <S.Actions>
        <S.CancelButton
          onClick={onCloseModal}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={isDeletingEvent}
          text="Cancel"
        />
        <S.DeleteButton
          onClick={handleDelete}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={isDeletingEvent}
          text={isDeletingEvent ? "Deleting" : "Delete"}
          IconTrailing={
            isDeletingEvent
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
        />
      </S.Actions>
    </ModalContainer>
  );
};

export default DeleteTimeLineEventModal;
