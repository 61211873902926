export enum CONFIDENCE_CATEGORIES {
  confirmed = "confirmed",
  unconfirmed = "unconfirmed",
  discarded = "discarded",
  userDiscarded = "userDiscarded"
}

export const DOWJONES_CONVERSION = {
  confirmed: "Precise match",
  unconfirmed: "Near match",
  discarded: "Broad match",
  userDiscarded: "userDiscarded"
};
