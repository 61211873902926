/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ParagraphItem } from "./ParagraphItem";

export type BodyItem = {
  type: BodyItem.type;
  contents: string | Array<ParagraphItem>;
};

export namespace BodyItem {
  export enum type {
    PARAGRAPH = "paragraph",
    SUBHEADING = "subheading"
  }
}
