import { styled } from "@linaria/react";

import { hexToRgb } from "styles/hexToRgb";

import MediaSourceCardComponent from "components/molecules/MediaSourceCard";

import { fontSize, family } from "styles/fonts";
import { grey, green, red, standardColors } from "styles/colors";

const SourceItemsContainer = styled.div`
  padding: 8px;
  scrollbar-gutter: stable;
  padding-right: 0px;
  height: 100%;
  overflow: auto;
`;

// @ts-ignore
const MediaSourceCard = styled(MediaSourceCardComponent)`
  width: 100%;
  background: ${standardColors.white};
  border-radius: 12px;
  padding: 16px;
  color: ${grey.dark};

  .di-card-footer {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  .di-card-header {
    border-bottom: 1px solid ${grey.rule};
    margin-bottom: 14px;
    padding-bottom: 8px;
  }

  .di-card-main-text {
    overflow: auto;
    position: relative;
    border-radius: 12px;
    padding: 8px;
    padding-left: 34px;
    background: rgba(
      ${
        // @ts-ignore this thinks it needs a StaticPlaceholder rather than a string
        () => {
          const { r, g, b } = hexToRgb(green.xapienGreen);
          return `${r}, ${g}, ${b}`;
        }
      },
      0.15
    );

    hr {
      display: none;
    }

    &:after {
      content: "“";
      position: absolute;
      top: 12px;
      left: 10px;
      color: ${green.mid};
      font-size: ${fontSize["3xl"]};
      font-family: ${family.interSemiBold};
      line-height: 1;
    }
  }
`;

const ErrorFetchingSourcesMessage = styled.div`
  color: ${red.badgeRed};
  font-size: ${fontSize.sm};
`;

const FetchingSourcesContainer = styled.div`
  width: 100%;
`;

const S = {
  SourceItemsContainer,
  MediaSourceCard,
  ErrorFetchingSourcesMessage,
  FetchingSourcesContainer
};

export default S;
