import { styled } from "@linaria/react";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const DataList = styled.dl`
  margin: 0;
`;

const DescriptionTerm = styled.dt`
  color: ${grey.mid};
  font-size: ${fontSize.sm};
`;

const DescriptionDetail = styled.dd`
  color: ${grey.dark};
  font-size: ${fontSize.sm};
`;

const S = {
  DataList,
  DescriptionTerm,
  DescriptionDetail
};

export default S;
