import React from "react";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import Icon from "components/organisms/InsightReport/ReportContent/icons";

import MenuStyles from "components/organisms/InsightReport/ReportContent/Menu/styles";
import S from "./styles";

const AddNewSectionButton = ({
  status,
  onClick,
  disabled = false
}: {
  status: InsightReportStatus;
  onClick: () => void;
  disabled?: boolean;
}) => {
  const isAddingNewSection = status === InsightReportStatus.addingNewSection;
  const hasErrorAddingSection =
    status === InsightReportStatus.errorAddingNewSection;

  const getButtonIcon = () => {
    if (isAddingNewSection) return <S.LoadingIcon />;
    if (hasErrorAddingSection) return <S.ErrorIcon />;

    return <Icon.AddSection />;
  };

  const getButtonLabel = () => {
    if (isAddingNewSection) return "Adding section...";
    if (hasErrorAddingSection) return "Error adding section, try again";

    return "Add section";
  };

  const onMenuItemClick = () => {
    if (disabled) {
      return;
    }
    if (isAddingNewSection) return;

    onClick();
  };

  const buttonLabel = getButtonLabel();

  return (
    <S.ActionMenuItem onClick={onMenuItemClick} disabled={disabled}>
      <MenuStyles.MenuItemContent>
        {getButtonIcon()}
        <span title={buttonLabel}>{buttonLabel}</span>
      </MenuStyles.MenuItemContent>
    </S.ActionMenuItem>
  );
};

export default AddNewSectionButton;
