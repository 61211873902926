import React, { FC, useState } from "react";

import LoginForm from "components/molecules/LoginModal/LoginForm";
import ForgottenPassword from "components/molecules/LoginModal/ForgottenPassword";

type LoginBoxProps = {
  slogan?: string;
};

const LoginBox: FC<LoginBoxProps> = ({ slogan }) => {
  const [showForgottenPassword, setShowForgottenPassword] = useState(false);

  const onForgottenPasswordToggle = () =>
    setShowForgottenPassword(prev => !prev);

  if (showForgottenPassword) {
    return <ForgottenPassword onBackClick={onForgottenPasswordToggle} />;
  }

  return (
    <LoginForm
      slogan={slogan}
      onForgottenPasswordClick={onForgottenPasswordToggle}
    />
  );
};

export default LoginBox;
