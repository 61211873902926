/* eslint-disable max-len */
import React, { createContext, useMemo, useState } from "react";

// Used for the risk infographics in the overview section. Coordinates the display of the directionality labels (e.g. `+n indirect`) that appear on hover.
const InfographicStateContext = createContext({
  displayAdditionalLabel: false,
  setDisplayAdditionalLabel: () => {}
});

const InfographicStateContextProvider = ({ children }) => {
  const [displayAdditionalLabel, setDisplayAdditionalLabel] = useState(false);
  // Keep track of any open infographic inspectors. Used to keep the additional labels on display.
  const [isAnyInfographicInspectorOpen, setIsAnyInfographicInspectorOpen] =
    useState(false);

  return (
    <InfographicStateContext.Provider
      value={useMemo(
        () => ({
          displayAdditionalLabel,
          setDisplayAdditionalLabel,
          isAnyInfographicInspectorOpen,
          setIsAnyInfographicInspectorOpen
        }),
        [
          displayAdditionalLabel,
          setDisplayAdditionalLabel,
          isAnyInfographicInspectorOpen,
          setIsAnyInfographicInspectorOpen
        ]
      )}
    >
      {children}
    </InfographicStateContext.Provider>
  );
};

export { InfographicStateContext, InfographicStateContextProvider };
