import React, { FC } from "react";
import { Mail } from "react-feather";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import config from "config";

import S from "./styles";

interface ErrorScreenProps {
  errorText: string;
  compact?: boolean;
  showContactButton?: boolean;
}

const ErrorScreen: FC<ErrorScreenProps> = ({
  errorText,
  showContactButton,
  compact = false
}) => {
  const onContactSupport = () => {
    window.open(
      `mailto:${config.supportEmail}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <S.Container compact={compact}>
      <S.Content compact={compact}>
        <S.IconContainer compact={compact}>
          <S.Icon compact={compact} />
        </S.IconContainer>

        <S.Heading level={compact ? 5 : 4}>
          This data is not available
        </S.Heading>

        <S.Paragraph>{errorText}</S.Paragraph>

        {showContactButton && (
          <ButtonNew
            onClick={onContactSupport}
            type={ButtonType.FilledLight}
            size={ButtonSize.Medium}
            text="Customer Success"
            IconTrailing={Mail}
          />
        )}
      </S.Content>
    </S.Container>
  );
};

export default ErrorScreen;
