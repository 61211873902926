import { grey, red } from "styles/colors";

import { MATCH_TYPE_STRINGS } from "util/screeningUtils";

export const getExplainerText = (
  matchTypeString,
  total,
  entityTypeString,
  listLabel
) => {
  const matchesString = total === 1 ? "match" : "matches";

  const zeroCountText = `We have not identified any ${entityTypeString} that have matched or bear a resemblance to your subject.`;

  if (matchTypeString === MATCH_TYPE_STRINGS.unconfirmed) {
    if (total === 0) {
      return zeroCountText;
    }

    return `We have identified ${total} ${listLabel} ${matchesString} for ${entityTypeString} with similar
    names to your subject, but insufficient corroborating data to confirm they are your subject.`;
  }

  if (matchTypeString === MATCH_TYPE_STRINGS.confirmed) {
    if (total === 0) {
      return `We have not identified any ${entityTypeString} that have matched to your subject.`;
    }

    return `We have identified ${total} ${listLabel} ${matchesString} for ${entityTypeString} with similar names to your
    subject. Corroborating data gives confidence they are your subject.`;
  }

  if (matchTypeString === MATCH_TYPE_STRINGS.discarded) {
    if (total === 0) {
      return zeroCountText;
    }

    return `We have identified ${total} ${listLabel} ${matchesString} for ${entityTypeString} with
      names sharing some similarities with your subject. Additional information
      we have found, however, conflicts with what is known about your subject.`;
  }
  return null;
};

export const getEntryStatus = ({ endDate, isKnownToBeOngoing }) => {
  let isEndDateInTheFuture = false;
  if (endDate) {
    const endDateAsObject = new Date(
      endDate.year,
      endDate.month - 1,
      endDate.day
    );

    isEndDateInTheFuture = endDateAsObject > new Date();
  }

  if (!isKnownToBeOngoing && endDate && !isEndDateInTheFuture) {
    return {
      statusColor: grey.mid,
      statusLabel: "Expired"
    };
  }

  if (isKnownToBeOngoing && (!endDate || isEndDateInTheFuture)) {
    return {
      statusColor: red.directRisk,
      statusLabel: "Active"
    };
  }

  return {
    statusColor: grey.ghost,
    statusLabel: "Unknown status"
  };
};
