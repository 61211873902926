import React, { useEffect, useState } from "react";
import Heading from "components/atoms/Heading";
import { AnimatePresence, motion } from "framer-motion";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";

import { CollectionSearchContextProvider } from "util/hooks/useCollectionSearch";
import { CollectionListContextProvider } from "util/hooks/useCollectionList";

import CollectionSearch from "components/organisms/CollectionSearch";
import CollectionList from "components/organisms/CollectionList";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { CardVariant } from "components/molecules/Card/types";
import GroupCard from "components/organisms/GroupCard";
import CreateGroupModal from "components/molecules/CreateGroupModal";
import useUserSettings from "util/hooks/useUserSettings";
import { useAuthentication } from "util/hooks/useAuthentication";
import LoadingPage from "components/molecules/LoadingPage";
import PageTitle from "components/atoms/PageTitle";

import Api from "api/groups";

import S from "./styles";

const Groups = () => {
  const {
    state: {
      isPermissionsLoaded,
      permissions: { canListGroups, canCreateGroup }
    }
  } = useAuthentication();
  const [showAllGroups, setShowAllGroups] = useState(false);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);

  const {
    state: {
      userDetails: { groups }
    },
    refresh: refreshUserSettings
  } = useUserSettings();

  useEffect(() => {
    const interval = setInterval(() => refreshUserSettings(), 10000);
    return () => clearInterval(interval);
  }, [refreshUserSettings]);

  const handleCreateGroupModalToggle = () => {
    setShowCreateGroupModal(!showCreateGroupModal);
  };

  const navigate = useNavigate();

  if (!isPermissionsLoaded) {
    return <LoadingPage />;
  }

  if (!canListGroups) {
    navigate("/");
    return <LoadingPage />;
  }

  return (
    <>
      <PageTitle title="Xapien | Groups" />
      <CollectionSearchContextProvider Api={Api}>
        <CollectionListContextProvider Api={Api}>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <S.ViewContainer>
                <S.ViewInnerContainer>
                  <CollectionSearch placeholder="Search groups..." />
                  <CollectionList
                    filterItemsFn={
                      !showAllGroups
                        ? item => {
                            return groups.some(
                              group => group.groupId === item.id
                            );
                          }
                        : undefined
                    }
                    showCount
                    cardVariant={CardVariant.dark}
                    navigationComponent={
                      <Heading level={4}>
                        {showAllGroups ? "All groups" : "My groups"}
                      </Heading>
                    }
                    createItemComponent={
                      <ButtonNew
                        IconLeading={Plus}
                        type={ButtonType.Outlined}
                        size={ButtonSize.Small}
                        onClick={handleCreateGroupModalToggle}
                        disabled={!canCreateGroup}
                        text="Create new group"
                      />
                    }
                    toggles={[
                      <ButtonNew
                        type={ButtonType.Outlined}
                        size={ButtonSize.Small}
                        text="Show all groups"
                        onClick={() => setShowAllGroups(prev => !prev)}
                        selected={showAllGroups}
                      />
                    ]}
                    CardComponent={GroupCard}
                    emptyCollectionText="No groups"
                  />
                </S.ViewInnerContainer>
              </S.ViewContainer>
              <CreateGroupModal
                isOpen={showCreateGroupModal}
                onToggleOpen={handleCreateGroupModalToggle}
              />
            </motion.div>
          </AnimatePresence>
        </CollectionListContextProvider>
      </CollectionSearchContextProvider>
    </>
  );
};

export default Groups;
