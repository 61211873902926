import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";
import { grey, blue, standardColors } from "styles/colors";

import { ReactComponent as WebpageIcon } from "img/icons/webpage-icon.svg";
import { ReactComponent as CorporateRecordSvgIcon } from "img/icons/corporate-record-icon.svg";
import { ReactComponent as SanctionsSvgIcon } from "img/icons/sanctions-without-circle-icon.svg";
import { ReactComponent as LinkedIn } from "img/icons/online-profile-li.svg";
import { ReactComponent as Twitter } from "img/icons/online-profile-twitter.svg";
import { ReactComponent as DJ } from "img/icons/dj-source.svg";
import { ReactComponent as CommercialAggregator } from "img/icons/commercial-aggregator-icon.svg";

interface SourceListContainerProps {
  paddingTop: string;
}

const SourceListContainer = styled.div<SourceListContainerProps>`
  display: flex;
  flex-direction: column;
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  color: ${grey.mid};
  padding: 8px;
`;

const OpenAll = styled.button`
  all: unset;
  font-size: ${fontSize.sm};
  color: ${grey.mid};

  &:focus,
  &:hover {
    outline: none;
    color: ${blue.icon};
  }
`;

const NumberSources = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const SourceItemsContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const sourceIconStyles = {
  width: "30px",
  height: "30px"
};

const GlobeIcon = styled(WebpageIcon)`
  ${sourceIconStyles};
`;

const CorporateRecordIcon = styled(CorporateRecordSvgIcon)`
  ${sourceIconStyles};
`;

const ScreeningProviderIcon = styled(SanctionsSvgIcon)`
  ${sourceIconStyles};
`;

const LinkedInIcon = styled(LinkedIn)`
  ${sourceIconStyles};
`;

const TwitterIcon = styled(Twitter)`
  ${sourceIconStyles};
`;

const DJIcon = styled(DJ)`
  ${sourceIconStyles};
`;

const CommercialAggregatorIcon = styled(CommercialAggregator)`
  ${sourceIconStyles};
`;

const PublisherLogo = styled.img`
  height: 64px;
  width: 64px;
`;

const IconWrapper = styled.div<{ color?: string }>`
  & > svg path {
    fill: ${({ color }) => color ?? standardColors.white};
  }
`;

const SourceDate = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  text-align: left;
  margin-left: 8px;
`;

const DurationSincePublished = styled.span`
  color: ${grey.mid};
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const PaginationButton = styled.button`
  all: unset;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

const CurrentPage = styled.div`
  font-family: ${family.interSemiBold};
`;

const S = {
  Pagination,
  PaginationButton,
  CurrentPage,
  SourceListContainer,
  NumberSources,
  Options,
  OpenAll,
  SourceItemsContainer,
  GlobeIcon,
  CommercialAggregatorIcon,
  LinkedInIcon,
  TwitterIcon,
  DJIcon,
  CorporateRecordIcon,
  ScreeningProviderIcon,
  PublisherLogo,
  IconWrapper,
  SourceDate,
  DurationSincePublished
};

export default S;
