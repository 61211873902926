import { styled } from "@linaria/react";

const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const S = { HeadingContainer };

export default S;
