import { Assertion, AssertionTemplate } from ".";
import { reportAbstract } from "./recognisers";

const exactPhoneNumberAssertionTemplate = phoneNumber =>
  new AssertionTemplate((isValid, isMust, testCaseId, userId, userComment) => {
    const arrayKey = isValid ? "contains" : "items";
    const constKey = isValid ? "const" : "not";
    const constVal = isValid ? phoneNumber : { const: phoneNumber };

    const assertionSchema = {
      type: "object",
      properties: {
        ReportAbstract: {
          type: "object",
          properties: {
            PhoneNumbers: {
              type: "array",
              [arrayKey]: {
                type: "object",
                properties: {
                  Text: {
                    type: "string",
                    [constKey]: [constVal]
                  }
                }
              }
            }
          }
        }
      }
    };

    const assertionHumanReadable = `Report ${isMust ? "must" : "should"}${
      isValid ? "" : " not"
    } contain "${phoneNumber}" as a phone number.`;
    return new Assertion(
      testCaseId,
      userId,
      userComment,
      isMust,
      assertionSchema,
      assertionHumanReadable
    );
  }, reportAbstract.specificPhoneNumber(phoneNumber));

export default exactPhoneNumberAssertionTemplate;
