import React, { ComponentType, useLayoutEffect, useState } from "react";

interface WithMountProps {
  isMounted?: boolean;
  animationSpeed?: number;
}

/**
 * A Higher Order Component to transition the provided Component into view
 */
const withMountTransition =
  <T extends object>(Component: ComponentType<T>) =>
  // eslint-disable-next-line react/function-component-definition
  ({
    isMounted = true,
    animationSpeed = 0.3,
    ...otherProps
  }: T & WithMountProps) => {
    const [isStageMounted, setIsStageMounted] = useState(isMounted);
    const [onMountStyle, setOnMountStyle] = useState({
      opacity: 0,
      transition: `all ${animationSpeed}s ease`
    });

    // eslint-disable-next-line consistent-return
    useLayoutEffect(() => {
      if (!isMounted) {
        return setIsStageMounted(false);
      }
      setIsStageMounted(true);
      setOnMountStyle(prevOnMountStyle => ({
        ...prevOnMountStyle,
        opacity: 0
      }));
      setTimeout(() =>
        setOnMountStyle(prevOnMountStyle => ({
          ...prevOnMountStyle,
          opacity: 1
        }))
      );
    }, [isMounted]);

    if (!isStageMounted) {
      return null;
    }

    return (
      <div style={onMountStyle}>
        <Component {...(otherProps as T)} />
      </div>
    );
  };

export default withMountTransition;
