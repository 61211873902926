import React, { useState } from "react";
import { Collapse } from "reactstrap";

import SortFilterCategory from "pages/report/sections/WebAndMedia/SortFilterPillCategory";

export const SortFilterHierarchicalCategories = props => {
  const { categories, categoriesToUse, setCategoriesToUse, titleCase } = props;
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  return (
    <div className="sort-filter-dropdown-menu-section">
      <div>Show only...</div>
      <div className="sort-filter-categories">
        <Categories
          categories={sortAndReshapeCategories(categories)}
          {...{
            categoriesToUse,
            setCategoriesToUse,
            titleCase,
            expandedCategories,
            setExpandedCategories,
            expanded: true
          }}
        />
      </div>
    </div>
  );
};

let categryKey = 0;
const Categories = props => {
  const {
    categories,
    categoriesToUse,
    setCategoriesToUse,
    titleCase,
    expandedCategories,
    setExpandedCategories
  } = props;
  const isSelected = cat =>
    cat &&
    categoriesToUse &&
    ((cat.children || []).length
      ? cat.children.every(isSelected)
      : cat.fullKey !== undefined && categoriesToUse.has(cat.fullKey));
  const isPartiallySelected = cat =>
    cat &&
    categoriesToUse &&
    (cat.children || []).some(
      child => isSelected(child) || isPartiallySelected(child)
    );
  return (
    <Collapse isOpen={props.expanded} mountOnEnter={true} unmountOnExit={true}>
      {categories.flatMap(cat => {
        const selected = isSelected(cat);
        const partiallySelected = !selected && isPartiallySelected(cat);
        const fullKey = cat.fullKey;

        // TODO set -> set all children
        // TODO selected = all children selected
        const setSelected = v => {
          const affected = getDescendantsAndCurrent(cat);
          const newSet = new Set(categoriesToUse);
          for (const affectedCategory of affected) {
            if (v) {
              newSet.add(affectedCategory.fullKey);
            } else {
              newSet.delete(affectedCategory.fullKey);
            }
          }
          setCategoriesToUse(newSet);
        };
        const expand = () =>
          setExpandedCategories(
            new Set(expandedCategories || new Set()).add(fullKey + cat.display)
          );
        const collapse = () => {
          const newSet = new Set(expandedCategories);
          newSet.delete(fullKey + cat.display);
          setExpandedCategories(newSet);
        };
        const setExpanded = v => (v ? expand() : collapse());
        const expanded =
          expandedCategories && expandedCategories.has(fullKey + cat.display);
        const expandable = cat.children && cat.children.length;
        // const italics = !(cat.children && cat.children.length);
        const italics = false;
        categryKey++;
        return [
          <SortFilterCategory
            {...cat}
            {...{
              selected,
              partiallySelected,
              setSelected,
              titleCase,
              expand,
              collapse,
              expanded,
              expandable,
              setExpanded,
              italics
            }}
          />,
          <Categories
            categories={cat.children}
            {...{
              categoriesToUse,
              setCategoriesToUse,
              expandedCategories,
              setExpandedCategories,
              titleCase,
              expanded
            }}
            key={categryKey}
          />
        ];
      })}
    </Collapse>
  );
};

const sortAndReshapeCategories = (categories, options) => {
  const { parentFullKey, tier } = options || {};
  const { uncategorisedCounts } = categories;
  const leafCategories =
    categories &&
    categories.children &&
    [...categories.children.entries()].filter(
      e => !(e[1] && e[1].children && e[1].children.size)
    );
  const nonLeafCategories =
    categories &&
    categories.children &&
    [...categories.children.entries()].filter(
      e => e[1] && e[1].children && e[1].children.size
    );
  const leavesCurrentAvailable = [...leafCategories]
    .map(kv => kv[1].currentAvailable || 0)
    .reduce((a, b) => a + b, 0);
  const leavesPotentialAvailable = [...leafCategories]
    .map(kv => kv[1].potentialAvailable)
    .reduce((a, b) => a + b, 0);

  if (nonLeafCategories && nonLeafCategories.length) {
    return nonLeafCategories
      .filter(kv => kv[0] !== undefined && kv[0] !== null)
      .map(kv => {
        const [, value] = kv;
        const fullKey = value.fullKey;
        const nextTier = (tier || 0) + 1;
        const children =
          value.children && value.children.size
            ? sortAndReshapeCategories(value, {
                parentFullKey: fullKey,
                tier: nextTier
              })
            : [];
        return Object.assign({}, value, { children, fullKey, tier: tier || 0 });
      })
      .sort((catA, catB) => {
        // sort by current count, then alphabetically
        const countDiff = catB.currentAvailable - catA.currentAvailable;
        if (countDiff === 0) {
          return (catA.display || "").localeCompare(catB.display || "");
        } else {
          return countDiff;
        }
      })
      .concat(
        (leafCategories && leafCategories.length) ||
          (uncategorisedCounts && uncategorisedCounts.potentialAvailable)
          ? [
              {
                display: "Other",
                key: "Other",
                tier: tier || 0,
                fullKey: parentFullKey,
                currentAvailable:
                  ((uncategorisedCounts &&
                    uncategorisedCounts.currentAvailable) ||
                    0) + leavesCurrentAvailable,
                potentialAvailable:
                  ((uncategorisedCounts &&
                    uncategorisedCounts.potentialAvailable) ||
                    0) + leavesPotentialAvailable,
                children: sortAndReshapeCategories(
                  { children: new Map(leafCategories), uncategorisedCounts },
                  { parentFullKey, tier: (tier || 0) + 1 }
                )
              }
            ]
          : []
      );
  } else {
    return leafCategories
      .filter(kv => kv[0] !== undefined && kv[0] !== null)
      .map(kv => {
        const [, value] = kv;
        // const fullKey = parentFullKey ? `${parentFullKey}/${key}` : key;
        const fullKey = value.fullKey;
        const nextTier = (tier || 0) + 1;
        const children =
          value.children && value.children.size
            ? sortAndReshapeCategories(value, {
                parentFullKey: fullKey,
                tier: nextTier
              })
            : [];
        return Object.assign({}, value, { children, fullKey, tier: tier || 0 });
      })
      .sort((catA, catB) => {
        // sort by current count, then alphabetically
        const countDiff = catB.currentAvailable - catA.currentAvailable;
        if (countDiff === 0) {
          return (catA.display || "").localeCompare(catB.display || "");
        } else {
          return countDiff;
        }
      })
      .concat(
        uncategorisedCounts && uncategorisedCounts.potentialAvailable
          ? [
              {
                display: "Other",
                key: "Other",
                tier: tier || 0,
                fullKey: parentFullKey,
                currentAvailable:
                  (uncategorisedCounts &&
                    uncategorisedCounts.currentAvailable) ||
                  0,
                potentialAvailable:
                  (uncategorisedCounts &&
                    uncategorisedCounts.potentialAvailable) ||
                  0,
                children: []
              }
            ]
          : []
      );
  }
};

export const isSubCategory = (subCat, superCat) =>
  subCat.length <= superCat.length && subCat.every((a, i) => superCat[i] === a);
const getDescendantsAndCurrent = cat =>
  [cat]
    .filter(c => c)
    .concat((cat.children || []).flatMap(getDescendantsAndCurrent));
