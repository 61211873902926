import { SUBJECT_CONTEXTS } from "pages/define/utils";

import { CorrectedType } from "pages/define/DefineStage/types";
import {
  Context,
  EntityType,
  OrganisationContextType,
  PersonContextType
} from "api/enquiry/types";

export const isTextALinkedinUrl = (text: string) => {
  const regex =
    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/?(pub|in|profile)?\/?([-a-zA-Z0-9]+)?\/*/gm;

  return !!text?.trim()?.match(regex);
};

export const isTextAUrl = (text: string) => {
  // https://mathiasbynens.be/demo/url-regex
  // https://gist.github.com/dperini/729294
  const regex = new RegExp(
    "^" +
      // protocol identifier (optional)
      // short syntax // still required
      "(?:(?:(?:https?|ftp):)?\\/\\/)?" +
      // user:pass BasicAuth (optional)
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      // IP address exclusion
      // private & local networks
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      "(?:" +
      "(?:" +
      "[a-z0-9\\u00a1-\\uffff]" +
      "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
      ")?" +
      "[a-z0-9\\u00a1-\\uffff]\\." +
      ")+" +
      // TLD identifier name, may end with dot
      "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
      ")" +
      // port number (optional)
      "(?::\\d{2,5})?" +
      // resource path (optional)
      "(?:[/?#]\\S*)?" +
      "$",
    "i"
  );

  return !!text?.trim()?.match(regex);
};

/**
 * Modifies the original context list by iterating through each context object and replacing the type with the corrected form.
 * @param {[object]} contexts
 * @param {[object]} indicesForInvalidInputs - indices of the inputs to correct
 * @param {string} correctType - the type replacing the currently assigned type (from SUBJECT_CONTEXTS)
 * @returns modified context list with correct types
 */
export const correctContextTypesForInputs = (
  contexts: Record<string, Context>,
  indicesForInvalidInputs: CorrectedType[],
  correctType: { id: string; type: PersonContextType | OrganisationContextType }
) => {
  const modifiedContexts = Object.values(contexts).reduce(
    (acc: Record<string, Context>, context, index) => {
      if (
        indicesForInvalidInputs.find(
          invalidInput => index === invalidInput.index
        ) !== undefined
      ) {
        acc[index] = {
          ...context,
          id: correctType.id,
          type: correctType.type
        };
      } else {
        acc[index] = { ...context };
      }

      return acc;
    },
    {}
  );

  return modifiedContexts;
};

/**
 * Attempts to correct context types depending on the identified input. E.g. does a LinkedIn url input have `LinkedIn Profile` as its context type.
 * @param {object} contexts
 * @param {string} subjectType
 * @returns {[object]} a list of objects each containing a index property, that maps to the input field, and a suggested context type.
 */
export const correctContextTypes = (
  contexts: Record<string, Context>,
  subjectType: EntityType
) => {
  const invalidIndices: CorrectedType[] = [];
  // Loop through contexts and identify whether the input has a correct context type with it
  Object.values(contexts).forEach((contextObj, index) => {
    // If the context type is wrong, then store the index of the input so we can modify the type if the user chooses to do so
    if (contextObj.value && isTextALinkedinUrl(contextObj.value)) {
      if (
        contextObj.type !== SUBJECT_CONTEXTS?.[subjectType]?.socialProfile?.type
      ) {
        invalidIndices.push({
          index,
          suggestedType: SUBJECT_CONTEXTS?.[subjectType]?.socialProfile?.type
        });
      }
    } else if (contextObj.value && isTextAUrl(contextObj.value)) {
      if (
        contextObj.type !== SUBJECT_CONTEXTS?.[subjectType]?.webAddress?.type
      ) {
        invalidIndices.push({
          index,
          suggestedType: SUBJECT_CONTEXTS?.[subjectType].webAddress.type
        });
      }
    }
  });

  return invalidIndices;
};
