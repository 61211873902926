/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetArticleResponse } from "../models/GetArticleResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../../articleViewerCustom/request";

export class DefaultService {
  /**
   * Get an article from a provider
   * An endpoint to get an article from a provider.
   * @returns GetArticleResponse Success
   * @throws ApiError
   */
  public static getArticles({
    provider,
    articleId
  }: {
    /**
     * Provider of the article
     */
    provider: "factiva";
    /**
     * Article identifier
     */
    articleId: string;
  }): CancelablePromise<GetArticleResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/articles/{provider}/{articleId}",
      path: {
        provider: provider,
        articleId: articleId
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Article Not Found`,
        500: `Internal Server Error`
      }
    });
  }
}
