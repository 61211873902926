import React from "react";

import config from "config";
import S from "./styles";

const TokenExpired = () => {
  return (
    <S.Container>
      <S.Description>
        Your invite token has expired. Please contact the Customer Success team
        for assistance.
      </S.Description>

      <S.ContactSupportLink href={`mailto:${config.supportEmail}`}>
        Contact Customer Success
      </S.ContactSupportLink>
    </S.Container>
  );
};

export default TokenExpired;
