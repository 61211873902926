import { styled } from "@linaria/react";
import { CardVariant } from "components/molecules/Card/types";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const Footer = styled.footer`
  border-top: 0.5px solid ${grey.lightRule};
`;

const FooterContentWrapper = styled.div<{ variant: CardVariant }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
  font-size: ${fontSize.xs};
`;

const FooterContentLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DateTimeAndChildren = styled.div`
  display: flex;
  align-items: center;
`;

const S = {
  Footer,
  DateTimeAndChildren,
  FooterContentWrapper,
  FooterContentLeft
};

export default S;
