import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { fontSize } from "styles/fonts";
import { grey, red, green } from "styles/colors";

const Overview = styled.div`
  display: flex;
`;

const OverviewDetails = styled.div`
  margin-left: 12px;
`;

const OverviewImageContainer = styled.div`
  border-radius: 3px;
  width: 74px;
  height: 74px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const Content = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  max-height: calc(
    100vh - 9rem
  ); /* Overriding reactstrap's overflow styles, so this detects overflow before ModalBody does */
  overflow-y: auto;
`;

const OverviewTitle = styled.div`
  font-size: ${fontSize.lg};
  margin-top: -6px;
`;

const Industry = styled.div`
  margin-top: 4px;
`;

const IndustryType = styled.span`
  color: ${grey.mid};
`;

const OrgLocation = styled.div`
  margin-top: 4px;
`;

const OrgTags = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 8px;
`;

const Pill = styled.div`
  display: inline-flex;
  align-items: center;
  height: 22px;
  padding: 0px 8px;
  border-radius: 16px;
  margin-right: 6px;
  transition: box-shadow 0.2s;
  font-size: ${fontSize.sm};
  max-width: 165px;
`;

const RiskPill = styled(Pill)`
  border: 1px solid ${red.directRisk};
  color: ${red.directRisk};
`;

const StatusPill = styled(Pill)`
  border: 1px solid ${props => props.color ?? green.secondary};
  color: ${props => props.color ?? green.secondary};
  opacity: 0.6;
`;

const Section = styled.div`
  border-top: 1px solid ${grey.rule};
  margin-top: 16px;
  padding-top: 10px;
`;

const OrgDetails = styled(Section)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const DataKey = styled.div`
  color: ${grey.mid};
  margin-bottom: 4px;
`;

const Addresses = styled(Section)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const ImageContainer = styled.div`
  border-radius: 6px;
  background-color: ${props =>
    props.isStreetViewMissing ? grey.light : "none"};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 123px;
  width: 232px;
  margin-top: 5px;

  img {
    border-radius: 6px;
    height: 100%;
    object-fit: contain;
  }

  svg {
    height: 50px;
    width: 50px;
  }
`;

const OtherAddresses = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 24px;
  max-height: 230px;
  overflow-y: auto;
`;

const OrgDetails2 = styled(Section)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-height: 335px;
  overflow-y: auto;
`;

const AddressBlock = styled.div`
  padding-top: ${props => (props.isFirstItem ? 0 : "25px")};
`;

const List = styled.ul`
  padding-inline-start: 16px;
  margin-top: 16px;
`;

const ListItem = styled.li`
  & + & {
    margin-top: 8px;
  }

  &::marker {
    color: ${grey.mid};
  }
`;

const Identifiers = styled.div`
  margin-top: 16px;
`;

const IdentifierListItem = styled.div`
  display: flex;
  white-space: pre-wrap;

  & + & {
    margin-top: 8px;
  }
`;

const ListItemSecondaryInfo = styled.span`
  color: ${grey.mid};
`;

const NoneIdentified = styled.div`
  display: ${props => props.display ?? "block"};
  font-style: italic;
  color: ${grey.ghost};
`;

const PreviousNamesContainer = styled.div`
  padding-right: 16px;
`;

const OrganisationType = styled.div`
  padding-right: 8px;
`;

const RelatedOrgCardsTitle = styled.div`
  font-size: ${fontSize.md};
  color: ${grey.mid};
`;

const SourcesList = styled.div`
  margin-top: 8px;
`;

const RegisteredAddress = styled.div`
  border-right: ${props =>
    props.otherAddressesPresent ? `1px solid ${grey.rule}` : null};
  margin-right: ${props => (props.otherAddressesPresent ? "10px" : 0)};
`;

/* ClassNameOverrides */

const masonryGrid = css`
  display: flex;
  width: auto;
  margin-top: 12px;
  max-height: 385px;
  overflow: auto;

  & > div:not(:first-child) {
    padding-left: 10px;
  }
`;

const masonryColumn = css`
  background-clip: padding-box;
  & > div {
    margin-bottom: 14px;
  }
`;

const sourceLink = css`
  display: inline-block;
`;

export const classNameOverrides = {
  masonryGrid,
  masonryColumn,
  sourceLink
};

const S = {
  Overview,
  OverviewDetails,
  OverviewImageContainer,
  Content,
  OverviewTitle,
  Industry,
  IndustryType,
  OrgLocation,
  OrgTags,
  RiskPill,
  StatusPill,
  OrgDetails,
  Addresses,
  DataKey,
  ImageContainer,
  OtherAddresses,
  Section,
  OrgDetails2,
  AddressBlock,
  List,
  ListItem,
  IdentifierListItem,
  Identifiers,
  ListItemSecondaryInfo,
  NoneIdentified,
  PreviousNamesContainer,
  OrganisationType,
  RelatedOrgCardsTitle,
  SourcesList,
  RegisteredAddress
};

export default S;
