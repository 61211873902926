import React from "react";

import { Outlet } from "react-router-dom";

import { HubAdminRoleContextProvider } from "util/hooks/useHubAdminRole/provider";

const GlobalShell = () => {
  return (
    <HubAdminRoleContextProvider>
      <Outlet />
    </HubAdminRoleContextProvider>
  );
};

export default GlobalShell;
