import { styled } from "@linaria/react";

import Heading from "components/atoms/Heading";

import { blue, grey, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

import BaseMenu from "components/molecules/Menu";
import Popover from "components/atoms/Popover";

const Menu = styled(BaseMenu)`
  overflow-y: scroll;
  max-height: 190px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled(Heading)`
  color: ${standardColors.white};
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  button {
    flex: 1;
  }
`;

const Divider = styled.hr`
  border-top: 1px solid ${standardColors.white};
  marign: 0;
`;

const Optional = styled.span`
  font-family: ${family.interLight};
`;

const Subtext = styled.div`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const UserSearch = styled.div`
  display: flex;
  gap: 1rem;
`;

const Autocomplete = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 0.375rem;
  background-color: ${grey.panel};
  border: none;
  padding: 0 1rem 0 0;

  .MuiAutocomplete-root {
    flex: 1;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding-top: 5px !important;
    padding-bottom: 2px !important;
  }
`;

const OptionsButton = styled.button`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: ${fontSize.sm} !important;
  line-height: 1.125rem;
  color: ${blue.xapienDark} !important;
  all: unset;
  white-space: nowrap;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const Emails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Email = styled.div`
  background: ${blue.xapienDark};
  padding: 8px 10px 8px 8px;
  border-radius: 4px;
  color: ${grey.rule};
  display: flex;
  gap: 0.5rem;
`;

const Groups = styled.div`
  display: flex;
  gap: 1rem;
`;

const GroupsPopover = styled(Popover)`
  align-self: flex-start;
`;

const S = {
  Groups,
  Container,
  Title,
  Buttons,
  Divider,
  Optional,
  Subtext,
  UserSearch,
  Autocomplete,
  OptionsButton,
  Emails,
  Email,
  Menu,
  GroupsPopover
};

export default S;
