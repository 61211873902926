import { styled } from "@linaria/react";
import { ReactComponent as CompanyLogoIcon } from "img/icons/xapien-logo-icon.svg";
import CommonButton from "components/atoms/Button";
import { ReactComponent as PersonPlaceholderSVG } from "img/icons/profile-placeholder.svg";
import { standardColors, grey } from "styles/colors";
import { family, fontSize } from "styles/fonts";

const LogoIcon = styled(CompanyLogoIcon)`
  width: 12px;
  height: 12px;
  path {
    fill: ${standardColors.white};
  }
`;

// Centers tooltip
const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled(CommonButton)`
  min-height: 22px !important;
  min-width: 74px !important;
  padding: 3px !important;
  height: 22px;
  color: ${standardColors.white} !important;
  font-size: ${fontSize.sm} !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: ${family.interRegular} !important;
  outline: none !important;

  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

const Span = styled.span`
  font-family: ${family.interSemiBold};
  color: ${grey.dark};
`;

const ModalBody = styled.div`
  display: flex;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  justify-content: space-between;
  min-height: 97px;
`;

const ModalBodyText = styled.div`
  max-width: 300px;
`;

const ButtonText = styled.div`
  height: 22px;
`;

const TooltipContent = styled.div`
  text-align: left;
  padding: 5px;
`;

const PersonPlaceholderIcon = styled(PersonPlaceholderSVG)`
  height: 74px;
  margin-bottom: 8px;
  width: 74px;
  border-radius: 3px;
`;

const S = {
  LogoIcon,
  Button,
  ButtonContainer,
  Span,
  ModalBody,
  ModalBodyText,
  Image,
  ButtonText,
  TooltipContent,
  PersonPlaceholderIcon
};

export default S;
