import React, { useRef, useEffect, memo } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const Chart = memo(props => {
  const {
    sortedDates,
    dateBounds,
    setDateBounds,
    immediateFeedbackBounds,
    setImmediateFeedbackBounds,
    isScrollBarNeeded = true,
    isXAxisNeeded = false,
    isLineSeriesNeeded = false,
    isColumnSeriesNeeded = true
  } = props;

  const chartHolderRef = useRef(null);
  const dateAxisRef = useRef(null);
  const internalDateBounds = useRef({});
  const chartRef = useRef(null);

  const minDatePossible = new Date(-8640000000000000); //useMemo(() => sortedDates[0].date,[dates]);
  const maxDatePossible = new Date(8640000000000000); //useMemo(() => sortedDates[sortedDates.length - 1].date, [sortedDates]);
  const minDate = sortedDates && sortedDates.length && sortedDates[0].date;
  const maxDate =
    sortedDates &&
    sortedDates.length &&
    sortedDates[sortedDates.length - 1].date;

  const transferImmediateFeedbackBoundsToDateBounds = useRef();
  transferImmediateFeedbackBoundsToDateBounds.current = () => {
    if (!isLineSeriesNeeded) {
      const bounds = Object.assign({}, immediateFeedbackBounds);
      const startDate = bounds.start;
      startDate.setDate(1);
      startDate.setHours(0, 0, 0, 0);
      const endDate = bounds.end;
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0); // end of month
      endDate.setHours(23, 59, 59, 999);
      setDateBounds({ startDate, endDate });
      setImmediateFeedbackBounds({});
    }
  };

  useEffect(() => {
    if (chartHolderRef.current) {
      const chart = am4core.create(chartHolderRef.current, am4charts.XYChart);
      chartRef.current = chart;
      chart.data = sortedDates;
      chart.paddingRight = 12; //22;
      chart.paddingLeft = 0; //22;
      if (isLineSeriesNeeded) {
        chart.paddingBottom = 0;
        chart.marginBottom = 0;
        chart.minHeight = 200;
        chart.maxHeight = 200;
      }

      // Create axes
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dataFields.category = "category";
      dateAxis.groupData = true;
      //dateAxis.groupCount = 40;
      dateAxis.keepSelection = true;
      dateAxisRef.current = dateAxis;

      if (isXAxisNeeded) {
        dateAxis.renderer.grid.template.location = 0.5;
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.fontSize = 12;
        dateAxis.renderer.labels.template.fill = am4core.color("#858996"); //light grey

        //ticks
        dateAxis.renderer.ticks.template.disabled = false;
        dateAxis.renderer.ticks.template.strokeOpacity = 1;
        dateAxis.renderer.ticks.template.stroke = am4core.color("#858996");
        dateAxis.renderer.ticks.template.strokeWidth = 1;
        dateAxis.renderer.ticks.template.length = 3;
        //dateAxis.renderer.labels.template.disabled = true;
      } else {
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.disabled = true;
      }

      // dateAxis.groupIntervals.setAll([
      //   { timeUnit: "month", count: 1 },
      //   /* { timeUnit: "year", count: 1 } */
      // ]);

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.tooltip.disabled = true;
      valueAxis.strictMinMax = true;
      valueAxis.min = 0;

      if (isLineSeriesNeeded) {
        dateAxis.tooltip.disabled = true;

        valueAxis.renderer.grid.template.disabled = false;
        valueAxis.renderer.labels.template.disabled = false;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.grid.template.strokeOpacity = 0.5;
        valueAxis.renderer.grid.template.stroke = am4core.color("#858996");
        valueAxis.renderer.grid.template.strokeWidth = 1;
        valueAxis.renderer.labels.template.fill = am4core.color("#858996");
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.minGridDistance = 30;
      }

      // Create series
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "value";
      series.dataFields.dateX = "date";
      series.groupFields.valueY = "sum";
      series.name = "Sources";
      series.columns.template.width = am4core.percent(40);

      if (!isColumnSeriesNeeded) {
        series.disabled = true;
      }

      const lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "value";
      lineSeries.dataFields.dateX = "date";
      lineSeries.groupFields.valueY = "sum";
      lineSeries.name = "SourcesLine";

      if (isLineSeriesNeeded) {
        lineSeries.fillOpacity = 0.4;
        lineSeries.tensionY = 0.95;
        lineSeries.tensionX = 0.95;
        lineSeries.strokeWidth = 2;
        lineSeries.fill = am4core.color("#007C89"); //teal
        lineSeries.setStroke(am4core.color("#007C89")); //teal
        lineSeries.cursorTooltipEnabled = true;
        //lineSeries.tooltipText = "{category}: [bold]{valueY}[/]";
      } else {
        lineSeries.disabled = true;
      }

      // Create scrollbar
      if (isScrollBarNeeded) {
        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(lineSeries);
        chart.scrollbarX.scrollbarChart.series.getIndex(0).disabled = false;
        chart.scrollbarX.scrollbarChart.plotContainer.filters.clear();
        chart.scrollbarX.minHeight = 30;

        chart.scrollbarX.events.on("up", e => {
          const range = e && e.target && e.target.range;
          if (range) {
            onRangeChangedDirect(range.start, range.end);
            setImmediateFeedbackBounds({});
          }
        });

        chart.scrollbarX.events.on("rangechanged", e => {
          const range = e && e.target && e.target.range;
          if (range) {
            const start = dateAxis.positionToDate(range.start);
            const end = dateAxis.positionToDate(range.end);
            setImmediateFeedbackBounds({
              start:
                minDate && minDate.getTime() <= start.getTime()
                  ? start
                  : minDate,
              end: maxDate && maxDate.getTime() >= end.getTime() ? end : maxDate
            });
          }
        });
      }

      chart.cursor = new am4charts.XYCursor();
      if (isLineSeriesNeeded) {
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
        //chart.cursor.snapToSeries = [lineSeries];
      } else {
        chart.cursor.lineX.disabled = true;
        chart.cursor.lineY.disabled = true;
      }

      const onRangeChangedDirect = (start, end) => {
        if (start !== undefined && end !== undefined) {
          const startDate = dateAxis.positionToDate(start);
          startDate.setDate(1);
          startDate.setHours(0, 0, 0, 0);
          const endDate = dateAxis.positionToDate(end);
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0); // last day of month
          endDate.setHours(23, 59, 59, 999);
          const newDateBounds = {
            startDate: startDate,
            endDate: endDate
          };
          // const logPayload = {
          //   range: range,
          //   newDateBounds,
          // };
          internalDateBounds.current = newDateBounds;
          setDateBounds(newDateBounds);
        }
      };
      // const onRangeChangedDebounced = AwesomeDebouncePromise(onRangeChangedDirect, 500);
      // const setImmediateFeedbackBoundsDebounced = AwesomeDebouncePromise(setImmediateFeedbackBounds, 200);

      let selectInitiatedPoint = undefined;
      chart.cursor.events.on("zoomstarted", e => {
        selectInitiatedPoint = dateAxis.positionToDate(
          dateAxis.toAxisPosition(e.target.xPosition)
        );
      });
      chart.cursor.events.on("zoomended", () => {
        selectInitiatedPoint = undefined;
        transferImmediateFeedbackBoundsToDateBounds.current();
      });
      chart.cursor.events.on("cursorpositionchanged", e => {
        if (selectInitiatedPoint) {
          if (!isLineSeriesNeeded) {
            const cursorDate = dateAxis.positionToDate(
              dateAxis.toAxisPosition(
                Math.min(Math.max(0, e.target.xPosition), 1)
              )
            );
            const cursorDateTime = cursorDate.getTime();
            const initiatedDateTime = selectInitiatedPoint.getTime();
            const start =
              cursorDateTime > initiatedDateTime
                ? selectInitiatedPoint
                : cursorDate;
            const end =
              cursorDateTime > initiatedDateTime
                ? cursorDate
                : selectInitiatedPoint;
            setImmediateFeedbackBounds({ start, end });
          }
        }
        // const start = range.start && dateAxis.positionToDate(range.start);
        // const end = range.end && dateAxis.positionToDate(range.end);
      });

      chart.zoomOutButton.events.on("hit", () => {
        setImmediateFeedbackBounds({});
        setDateBounds({});
        internalDateBounds.current = {};
        selectInitiatedPoint = undefined;
      }); // fake event model lazy, but convenient
      // chart.scrollbarY = new am4core.Scrollbar();
      return () => chart.dispose();
    }
    // Intentional omission of dependencies. Mimicking componentDidMount
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const zoomChart = options => {
    const { instantly } = options || {};
    const dateAxis = dateAxisRef.current;
    if (dateBounds && dateAxis && internalDateBounds.current !== dateBounds) {
      dateAxis.zoomToDates(
        dateBounds.startDate || minDatePossible,
        dateBounds.endDate || maxDatePossible,
        true,
        instantly
      );
      internalDateBounds.current = dateBounds;
    }
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (chart && sortedDates && chart.data !== sortedDates) {
      chart.data = sortedDates;
    }
  }, [sortedDates]);

  useEffect(
    zoomChart,
    // TODO: include missing dependencies and remove expression
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [dateBounds && dateBounds.startDate, dateBounds && dateBounds.endDate]
  );

  return (
    <div
      style={{
        height: "200px",
        paddingLeft: "unset",
        paddingRight: "unset",
        paddingTop: "unset"
      }}
      ref={chartHolderRef}
    >
      A
    </div>
  );
});

export default Chart;
