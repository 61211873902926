import { TimelineEventDate } from "api/insights";

const formatDate = (date: TimelineEventDate) => {
  const { month_short: monthShort, year } = date;
  return `${monthShort} ${year}`;
};

enum BucketType {
  group = "group",
  legalEntity = "legal_entity"
}

export const getBucketLabel = (bucket: string) => {
  switch (bucket) {
    case BucketType.group: {
      return "Wider group";
    }
    case BucketType.legalEntity: {
      return "Primary entity";
    }
    default:
      return "";
  }
};

export const getEventDuration = (dates: TimelineEventDate[]) => {
  if (dates.length === 1) {
    return formatDate(dates[0]);
  }

  const orderedDates = dates.sort((a, b) => {
    const dateA = new Date(a.year, a.month, a.day);
    const dateB = new Date(b.year, b.month, b.day);

    return dateA > dateB ? 1 : -1;
  });

  const lastIndex = orderedDates.length - 1;
  const firstDate = formatDate(orderedDates[0]);
  const lastDate = formatDate(orderedDates[lastIndex]);

  return `${firstDate} - ${lastDate}`;
};
