import { createContext } from "react";

import type { UserActionsState } from "./types";

const UserActionsContext = createContext<UserActionsState>({
  deleteUser: ({
    userId: _userId,
    transferUserId: _transferUserId,
    dataLossAcknowledged: _dataLossAcknowledged
  }: {
    userId: string;
    transferUserId?: string;
    dataLossAcknowledged: boolean;
  }) => {
    console.error("deleteUser does not have any implementation");
  },
  addToGroup: (_userId: string, _groupId: string) => {
    console.error("addToGroup does not have any implementation");
  },
  resetAll: () => {},
  resetError: () => {},
  fetching: false,
  error: false,
  success: false
});

export { UserActionsContext };
