import { styled } from "@linaria/react";

import { standardColors } from "styles/colors";

const Menu = styled.ul`
  box-shadow: -0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.16);
  border-radius: 0.375rem;
  background-color: ${standardColors.white};
  padding: 12px 0;
  width: 12.9375rem;
  margin-bottom: 0;
`;

const S = {
  Menu
};

export default S;
