import { useContext } from "react";

import { InsightReportContext } from "./context";
import { isShowingInsightsReport } from "./utils";
import { InsightReportProvider } from "./provider";
import { InsightReportActions } from "./types";

const useInsightReport = () => {
  const context = useContext(InsightReportContext);

  return context;
};

export {
  useInsightReport,
  InsightReportContext,
  InsightReportProvider,
  InsightReportActions,
  isShowingInsightsReport
};
