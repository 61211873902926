import { useContext } from "react";
import { ReportSubjectEntityTypeContext } from "./context";

export { EntityType } from "./context";

export const useReportSubjectEntityType = () => {
  const entityType = useContext(ReportSubjectEntityTypeContext);
  if (!entityType) {
    throw new Error("Setup error - no report subject type");
  }
  return entityType;
};
