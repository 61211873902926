import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import InfoIcon from "components/atoms/InfoIcon";
import { blue, grey } from "styles/colors";

import { fontSize } from "styles/fonts";

const AutoshareContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AutoshareInnerContainer = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;
  align-items: center;
`;

const AutoshareButton = styled.button`
  all: unset;

  &:hover {
    color: ${grey.light};
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const CustomInfoIcon = styled(InfoIcon)`
  margin-bottom: 4px;
  margin-left: 0;

  & svg {
    width: 1rem !important;
    height: 1rem !important;

    path {
      fill: white;
    }
  }
`;

const TooltipContent = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize.sm};
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const TooltipAutoshareButton = styled.button`
  all: unset;
  color: ${blue.icon};

  &:hover {
    color: ${blue.hover};
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const AutoshareToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`;

const SharedWith = styled.div`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  margin-top: 0.25rem;
`;

/** ClassNameOverrides */

const toggle = css`
  margin-bottom: -2px;
`;

const S = {
  AutoshareContainer,
  AutoshareInnerContainer,
  AutoshareButton,
  CustomInfoIcon,
  TooltipContent,
  TooltipAutoshareButton,
  AutoshareToggleContainer,
  SharedWith
};

export const classNameOverrides = {
  toggle
};

export default S;
