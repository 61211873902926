import React, { FC } from "react";
// @ts-ignore
import theme from "theme";

import S from "./styles";

type LoginHeaderProps = {
  slogan?: string;
  subtitle?: string;
};

const LoginHeader: FC<LoginHeaderProps> = ({ slogan, subtitle }) => {
  const defaultSubtitle = subtitle === "A fully-automated research platform";

  return (
    <S.Container>
      {theme.login?.logo ?? <S.XapienLogo />}
      {defaultSubtitle ? (
        <S.DefaultTitle level={5}>
          A <S.Highlight>fully-automated</S.Highlight> research platform
        </S.DefaultTitle>
      ) : (
        <>
          <S.Title level={5}>
            {theme.slogan ?? slogan
              ? slogan
              : "Xapien is a fully-automated research platform"}
          </S.Title>
          <S.Subtitle level={4}>{subtitle || "Please sign-in"}</S.Subtitle>
        </>
      )}
    </S.Container>
  );
};

export default LoginHeader;
