import { styled } from "@linaria/react";
import { purple, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const BetaBadge = styled.div`
  background: ${purple.beta};
  font-size: ${fontSize.md};
  padding: 0.2rem 0.5rem 0.1rem;
  border-radius: 3px;
  color: ${standardColors.white};
`;

const S = { BetaBadge };
export default S;
