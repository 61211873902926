import { styled } from "@linaria/react";
import { Mail } from "react-feather";

import { blue, grey } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-top: 1px solid ${grey.faint};
  padding-top: 1rem;
`;

const TitleCardContainer = styled.a`
  all: unset;
  position: relative;
  cursor: pointer;
  border-radius: 0.75rem;

  &:hover,
  &:focus {
    outline: 2px solid
      ${() => {
        const { r, g, b } = hexToRgb(blue.xapienLight);
        return `rgba(${r}, ${g}, ${b}, 0.6)`;
      }};
  }
`;

const Admins = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 25rem;
  overflow: auto;
  padding: 2px;
`;

const MailIcon = styled(Mail)`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: ${blue.xapienLight};
`;

const S = {
  ModalContent,
  TitleCardContainer,
  Admins,
  MailIcon
};

export default S;
