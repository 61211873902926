import { useContext } from "react";

import { ManageGroupContext } from "./context";

const useManageGroup = () => {
  const context = useContext(ManageGroupContext);

  if (!context) {
    throw new Error(
      "useManageGroup must be used within a ManageGroupContextProvider"
    );
  }

  return context;
};

export default useManageGroup;
