import { Amplify } from "aws-amplify";
import { amplifyConfig } from "config";
import { isDevel, isQa, stage } from "services/stage";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { apm } from "@elastic/apm-rum";

import {
  FetchAuthSessionOptions,
  fetchAuthSession,
  getCurrentUser,
  setUpTOTP
} from "aws-amplify/auth";
import { isPDX } from "static-config";

export const apiToken = async () => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.idToken?.toString() ?? "";
  } catch (e) {
    apm.captureError(e as Error);
    console.error(e);
    return "";
  }
};

export const getAuthenticatedUser = async (
  options?: FetchAuthSessionOptions
) => {
  const { username, signInDetails } = await getCurrentUser();
  const { tokens: session } = await fetchAuthSession(options);

  return {
    username,
    session,
    authenticationFlowType: signInDetails?.authFlowType
  };
};

export const getQrUriForMfa = async (appName: string) => {
  const { getSetupUri } = await setUpTOTP();
  let { username } = await getAuthenticatedUser();
  username = username.includes("+") ? username.split("+")[0] : username;
  const qrUriString = getSetupUri(appName, username).toString();

  return qrUriString;
};

export enum AuthenticationConfig {
  HUB = "hub",
  PORTAL = "portal"
}

const getCookieDomain = () => {
  if (window.location.host.includes("localhost")) {
    return "";
  }

  const isProd = !isDevel && !isQa;

  if (isPDX) {
    if (isProd) {
      return ".integritycheck.xapien.com";
    }

    return `.${stage}.integritycheck.xapien.com`;
  }

  if (isProd) {
    return ".portal.xapien.com";
  }

  return `.portal.${stage}.app.xapien.com`;
};

export function setAuthenticationConfig(config: AuthenticationConfig) {
  Amplify.configure(amplifyConfig[config]);

  const domain = getCookieDomain();
  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({ domain })
  );

  const KEY_NAME = "cleared-legacy-cookie-2";
  if (localStorage.getItem(KEY_NAME) === "true") {
    return;
  }

  const domains = [null, domain];

  domains.forEach(d => {
    document.cookie.split(";").forEach(cookie => {
      let value = `${
        cookie.split("=")[0]
      }=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;

      if (d) {
        value += `;domain=${d}`;
      }

      document.cookie = value;
    });
  });

  localStorage.setItem(KEY_NAME, "true");
}
