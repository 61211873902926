// @ts-nocheck - we'll be removing this shortly, but for now I'm just going to ignore ts checking

import React, { FC } from "react";
import { FeInsightAnswer, FeAnswer } from "api/old-insights";

import { TypingDots } from "components/atoms/TypingDots";
import { SourcedInsightsAnswer } from "components/organisms/SourcedInsightsAnswer";

import S from "./styles";

type InsightReportSectionProps = {
  title?: string;
  answer?: FeAnswer;
  answers?: FeInsightAnswer[];
  pending?: boolean;
};

export const InsightReportSection: FC<InsightReportSectionProps> = ({
  title,
  answers,
  answer,
  pending
}) => {
  if (pending) {
    return <TypingDots />;
  }
  return (
    <>
      <S.InsightReportSection>
        {title ? <S.Heading>{title}</S.Heading> : undefined}
        {answer ? <SourcedInsightsAnswer answer={answer} /> : undefined}
      </S.InsightReportSection>
      {answers
        ? answers.map(a => (
            <S.InsightReportSection key={a.answer.id}>
              <S.Heading>{a.heading}</S.Heading>
              <SourcedInsightsAnswer answer={a.answer} />
            </S.InsightReportSection>
          ))
        : undefined}
    </>
  );
};
