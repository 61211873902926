export const isFactivaUrl = (url: string | string[]) => {
  const urls = Array.isArray(url) ? url : [url];
  const regex = /^https:\/\/dj\.factiva\.com\/article\?id=/;

  return urls.some(u => regex.test(u));
};

export const extractFactivaIdFromUrl = (url: string | string[]) => {
  const factivaUrl = Array.isArray(url) ? url.find(u => isFactivaUrl(u)) : url;

  const idMatch = factivaUrl && factivaUrl.match(/article\?id=(.+)$/);
  return idMatch ? idMatch[1] : null;
};
