import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  CSSProperties
} from "react";
import ReactDom from "react-dom";
import { Popper, PopperChildrenProps, PopperProps } from "react-popper";

const modifiers = [
  {
    name: "preventOverflow",
    enabled: true,
    fn: () => {},
    options: {
      boundary: "viewport",
      padding: 10
    }
  }
];

interface Props extends Omit<PopperProps<{}>, "children"> {
  tagName?: string;
  showDelay?: number;
  hideDelay?: number;
  tooltip?: ReactNode;
  tooltipClassName?: string;
  tooltipStyle?: CSSProperties;
  tooltipMaxWidth?: string | number;
  arrowClassName?: string;
  hideArrow?: boolean;
  container?: Element | DocumentFragment;
  onMouseEnter?: (e: Event) => void;
  onMouseLeave?: (e: Event) => void;
  children?: ReactNode;
}

/**
 * Text component with tooltip support powered by Popper
 */
const Truncate = ({
  tagName: Tag = "div",
  children,
  placement = "top",
  innerRef,
  showDelay = 150,
  hideDelay = 150,
  tooltip,
  tooltipClassName,
  tooltipStyle,
  tooltipMaxWidth,
  arrowClassName,
  hideArrow = false,
  container,
  onMouseEnter,
  onMouseLeave,
  ...rest
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const listenTimer = useRef<NodeJS.Timeout | null>(null);
  const showTimer = useRef<NodeJS.Timeout | null>(null);
  const hideTimer = useRef<NodeJS.Timeout | null>(null);
  const targetNode = useRef<HTMLElement | null>(null);

  const handleMouseEvent = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const renderTooltip = ({
    ref,
    style,
    placement: tooltipPlacement,
    arrowProps
  }: PopperChildrenProps) => {
    const content = tooltip || children;
    const extraStyle = tooltipMaxWidth
      ? { ...tooltipStyle, maxWidth: tooltipMaxWidth }
      : tooltipStyle;

    return ReactDom.createPortal(
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        ref={ref}
        data-texty-tooltip={tooltipPlacement}
        className={tooltipClassName}
        style={extraStyle ? { ...style, ...extraStyle } : style}
        onClick={handleMouseEvent}
        onDoubleClick={handleMouseEvent}
        onContextMenu={handleMouseEvent}
        onMouseDown={handleMouseEvent}
        onMouseUp={handleMouseEvent}
      >
        {content}
        {!hideArrow && (
          <div
            ref={arrowProps.ref}
            data-texty-arrow={tooltipPlacement}
            className={arrowClassName}
            style={arrowProps.style}
          />
        )}
      </div>,
      container || targetNode.current!.ownerDocument.body
    );
  };

  const setTargetRef = (ref: HTMLElement) => {
    if (innerRef && typeof innerRef === "function") innerRef(ref);
    targetNode.current = ref;
  };

  const handleScroll = () => {
    setIsHovered(false);
  };

  const clearListenTimer = () => {
    if (listenTimer.current) {
      clearTimeout(listenTimer.current);
      listenTimer.current = null;
    }
  };

  const clearShowTimer = () => {
    if (showTimer.current) {
      clearTimeout(showTimer.current);
      showTimer.current = null;
    }
  };

  const clearHideTimer = () => {
    if (hideTimer.current) {
      clearTimeout(hideTimer.current);
      hideTimer.current = null;
    }
  };

  const handleMouseEnter = (e: Event) => {
    if (onMouseEnter) onMouseEnter(e);

    clearHideTimer();

    if (!showDelay) {
      setIsHovered(true);
      return;
    }

    showTimer.current = setTimeout(() => {
      setIsHovered(true);
      showTimer.current = null;
    }, showDelay);
  };

  const handleMouseLeave = (e: Event) => {
    if (onMouseLeave) onMouseLeave(e);

    clearShowTimer();

    if (!isHovered) return;

    if (!hideDelay) {
      setIsHovered(false);
      return;
    }

    hideTimer.current = setTimeout(() => {
      setIsHovered(false);
      hideTimer.current = null;
    }, hideDelay);
  };

  useEffect(() => {
    if (isHovered) {
      window.addEventListener("scroll", handleScroll, true);
      // react-virtualized-auto-sizer would trigger scroll events after tooltip shown in some case, we have to skip those scroll events
      listenTimer.current = setTimeout(() => {
        window.addEventListener("scroll", handleScroll, true);
        listenTimer.current = null;
      }, 50);
    } else {
      clearListenTimer();
      window.removeEventListener("scroll", handleScroll, true);
    }

    return () => {
      clearListenTimer();
      window.removeEventListener("scroll", handleScroll, true);
      clearShowTimer();
      clearHideTimer();
    };
  }, [isHovered]);

  if (!children) {
    // @ts-ignore
    return <Tag {...rest} ref={setTargetRef} data-texty={false} />;
  }

  const target = targetNode.current;
  const isTruncated = !!target && target.scrollWidth > target.offsetWidth;
  const showTooltip = isHovered && isTruncated;
  return (
    // @ts-ignore
    <Tag
      {...rest}
      ref={setTargetRef}
      data-texty={showTooltip}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <Popper
          referenceElement={target}
          placement={placement}
          modifiers={modifiers}
        >
          {renderTooltip}
        </Popper>
      )}
    </Tag>
  );
};

export default Truncate;
