import React, { FC, ReactNode, SVGProps } from "react";
import { Spinner } from "reactstrap";

import Heading from "components/atoms/Heading";
import ModalContainer from "components/molecules/ModalContainer";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import useShareReport from "util/hooks/useShareReport";
import ErrorBanner from "components/atoms/ErrorBanner";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  children: ReactNode;
}

const DeleteShareLinkModal = ({ isOpen, toggleOpen, children }: Props) => {
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();
  const { deleteShareLink } = useShareReport();

  const onDeleteLink = async () => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await deleteShareLink();

    if (status) {
      toggleOpen();
      dispatch({ type: RequestActions.SetSuccess });
    } else {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
    }
  };

  return (
    <ModalContainer
      title="Delete share link?"
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onExitClick={toggleOpen}
    >
      <Heading level={6}>Delete share link for the report</Heading>
      {children}
      <S.ModalActions>
        <S.Button
          text="Cancel"
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          onClick={toggleOpen}
        />
        <S.DeleteButton
          text="Delete link"
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          onClick={onDeleteLink}
          disabled={fetching}
          IconTrailing={
            fetching
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
        />
      </S.ModalActions>
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            "There was an issue deleting the share link. Try again in moment."
          }
          onClick={() => dispatch({ type: RequestActions.Reset })}
        />
      )}
    </ModalContainer>
  );
};

export default DeleteShareLinkModal;
