import { styled } from "@linaria/react";

import { blue, standardColors } from "styles/colors";

const Item = styled.button<{ isSelected?: boolean; boxShadowColor: any }>`
  background: none;
  border: none;
  border-radius: 15px;
  z-index: 1;
  flex: 1 1 0;
  color: ${props =>
    props.isSelected ? blue.xapienDark : standardColors.white};
  transition: color 0.5s, opacity 0.2s;
  opacity: ${props => (props.isSelected ? 1 : 0.7)};

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgb(40 239 250 / 33%)"};
    opacity: 1;
  }
`;

const S = {
  Item
};

export default S;
