import React, { FC, useMemo, useState } from "react";

import SourceLink from "components/atoms/SourceLink";
import TruncateLength from "util/TruncateLength";
import { formatDate } from "util/common";
import {
  CompanyRegisteredAddresses,
  CorrespondenceAddressForRole,
  ValueTupleStringGUID
} from "api/report/report-types";

import S, { classNameOverrides } from "./styles";

export type Role =
  | CompanyRegisteredAddresses
  | CorrespondenceAddressForRole
  | ValueTupleStringGUID
  | string;

export type AddressType =
  | CompanyRegisteredAddresses
  | CorrespondenceAddressForRole;

export type RoleType = "address" | "organisation" | "person";

export interface LocationRolesProps {
  roles: Role[];
  roleType: RoleType;
  title: string;
  rolesLimit?: number;
}

const LocationRoles: FC<LocationRolesProps> = ({
  roles,
  roleType,
  title,
  rolesLimit = 5
}) => {
  const [isShowingAll, setIsShowingAll] = useState(false);
  const rolesExceedLimits = useMemo(
    () => roles.length > rolesLimit,
    [roles, rolesLimit]
  );

  const rolesToShow = useMemo(() => {
    const getOrganisationName = (role: Role) => {
      if (roleType === "address") {
        return (role as AddressType).organisationName ?? "";
      }

      if (roleType === "organisation") {
        return (role as ValueTupleStringGUID).item1;
      }

      return role as string;
    };

    const sortedRoles = roles.sort((a, b) => {
      const nameA = getOrganisationName(a);
      const nameB = getOrganisationName(b);

      return nameA.localeCompare(nameB);
    });

    if (rolesExceedLimits && !isShowingAll) {
      return sortedRoles.slice(0, rolesLimit);
    }

    return sortedRoles;
  }, [roles, isShowingAll, rolesLimit, rolesExceedLimits, roleType]);

  const renderAddressRole = (
    role: CompanyRegisteredAddresses | CorrespondenceAddressForRole
  ) => (
    <S.LocationRoleItem key={`${role.organisationName}${role.start}`}>
      {role.links && role.links.length ? (
        <SourceLink href={role.links}>
          <S.LocationOrgName isDatePresent={!!role.start}>
            <TruncateLength>{role.organisationName}</TruncateLength>
          </S.LocationOrgName>
        </SourceLink>
      ) : (
        <S.LocationOrgName isDatePresent={!!role.start}>
          <TruncateLength>{role.organisationName}</TruncateLength>
        </S.LocationOrgName>
      )}
      {role.start && <S.RoleYear>{` ${role.start.year}`}</S.RoleYear>}
      <S.InfoIcon
        tooltipAlignment="right"
        tooltipPosition="fixed"
        infoContent={
          <S.TooltipContainer>
            <S.TooltipHeader>Original address data</S.TooltipHeader>
            <S.TooltipBody>
              {role.start && (
                <S.AddressDate>{formatDate(role.start)}</S.AddressDate>
              )}
              <S.AddressTooltip>
                {role.address.map((line: string) => (
                  <div key={line}>{line}</div>
                ))}
              </S.AddressTooltip>
            </S.TooltipBody>
          </S.TooltipContainer>
        }
        tooltipContentClassName={classNameOverrides.infoIconTooltipContent}
      />
    </S.LocationRoleItem>
  );

  return (
    <S.LocationRolesWrapper>
      <S.RoleSectionTitle>{title}</S.RoleSectionTitle>
      <S.LocationRolesList>
        {rolesToShow.map(role => {
          if (roleType === "address") {
            return renderAddressRole(role as AddressType);
          }

          if (roleType === "organisation") {
            return (
              <S.LocationOrgName isDatePresent={false}>
                <TruncateLength>
                  {(role as ValueTupleStringGUID).item1}
                </TruncateLength>
              </S.LocationOrgName>
            );
          }

          return (
            <S.LocationOrgName isDatePresent={false}>
              <TruncateLength>{role as string}</TruncateLength>
            </S.LocationOrgName>
          );
        })}
        {rolesExceedLimits && (
          <S.ShowAllButton onClick={() => setIsShowingAll(!isShowingAll)}>
            Show {isShowingAll ? "less" : `all ${roles.length}`}
          </S.ShowAllButton>
        )}
      </S.LocationRolesList>
    </S.LocationRolesWrapper>
  );
};

export default LocationRoles;
