import { styled } from "@linaria/react";

import { blue, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

import Button from "components/atoms/Button";

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
`;

const StageHeading = styled.h1`
  font-size: ${fontSize.xl};
  font-family: ${family.interLight};
  margin-bottom: 0;
  padding-bottom: 20px;
`;

const SearchTermsHeading = styled.p`
  text-align: center;
  max-width: 700px;
  word-break: break-word;
`;

const OrgSuggestions = styled.div`
  width: 674px;
  overflow-y: auto;
  border-radius: 18px;
  margin: 20px 0;
  max-height: 518px;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.12);
    display: initial;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
  }
`;

const AlternativeSearchButton = styled(Button)<{ borderColor?: string }>`
  min-height: 32px !important;
  color: ${standardColors.white} !important;
  padding: 0 20px !important;
  margin-top: 20px;
  border-color: ${props => props.borderColor ?? blue.xapienLight} !important;
`;

const NoResultsExplainer = styled.p`
  max-width: 39.6875rem;
`;

const S = {
  StageContainer,
  StageHeading,
  SearchTermsHeading,
  OrgSuggestions,
  AlternativeSearchButton,
  NoResultsExplainer
};

export default S;
