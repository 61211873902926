import React, { FC, useRef, useCallback, useMemo, useEffect } from "react";
import SearchInput from "components/atoms/SearchInput";
import {
  useCollectionSearch,
  CollectionSearchActions
} from "util/hooks/useCollectionSearch";
import type { SearchResult } from "api/search";

import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";
import {
  CollectionInputType,
  CollectionStatus
} from "util/hooks/useCollectionList/types";
import useDebounce from "util/hooks/useDebounce";

import { CollectionListView } from "components/molecules/CollectionListControls";
import S from "./styles";

interface CollectionSearchProps {
  placeholder: string;
  filterByUserId?: string;
  filterByGroupId?: string;
  showAllReports?: boolean;
}

const CollectionSearch: FC<CollectionSearchProps> = ({
  placeholder,
  filterByUserId,
  filterByGroupId,
  showAllReports
}) => {
  const searchRef = useRef<HTMLDivElement>(null);

  const {
    state: { results, query, searchTags },
    dispatch
  } = useCollectionSearch();

  const { state: listState, dispatch: listDispatch } = useCollectionList();
  const searchCollectionIdPrefix = "search";
  const { debounce } = useDebounce();

  const search = useCallback(
    async (q: string, tags: SearchResult[]) => {
      if (
        listState.collections.some(
          ({ id }) => id === `${searchCollectionIdPrefix}-${q}-${tags?.length}`
        )
      ) {
        return;
      }

      // a bit ham-fisted
      listState.collections.forEach(collection => {
        listDispatch({
          type: collection.id.startsWith(searchCollectionIdPrefix)
            ? CollectionListActions.removeCollection
            : CollectionListActions.hideCollection,
          id: collection.id
        });
      });

      listDispatch({
        type: CollectionListActions.addCollection,
        id: `${searchCollectionIdPrefix}-${q}-${tags?.length}`,
        title: "Results",
        items: [],
        status: CollectionStatus.stale,
        totalItemCount: 0,
        view: CollectionListView.list,
        pollingEnabled: false,
        hiddenIfEmpty: false,
        offset: 0,
        limit: 16,
        order: "",
        input: {
          type: CollectionInputType.search,
          query: q,
          searchTags: tags,
          filterByUserId,
          filterByGroupId,
          filterAllAccessible: showAllReports
        }
      });
    },
    [
      listState.collections,
      listDispatch,
      filterByUserId,
      filterByGroupId,
      showAllReports
    ]
  );

  const searchDebounced = useMemo(
    () => debounce(search, 200),
    [debounce, search]
  );

  const onSubmit = useCallback(
    () => searchDebounced(query, searchTags),
    [query, searchDebounced, searchTags]
  );

  const onClear = useCallback(() => {
    dispatch({
      type: CollectionSearchActions.updateSearchTags,
      searchTags: []
    });
    listState.collections.forEach(collection => {
      listDispatch({
        type: collection.id.startsWith(searchCollectionIdPrefix)
          ? CollectionListActions.removeCollection
          : CollectionListActions.showCollection,
        id: collection.id
      });
    });
  }, [listState.collections, listDispatch, dispatch]);

  const handleOnInputChange = useCallback(
    (value: string) => {
      dispatch({ type: CollectionSearchActions.updateQuery, query: value });

      if (value && value.trim().length) {
        searchDebounced(value, searchTags);
      } else {
        onClear();
      }
    },
    [searchDebounced, onClear, dispatch, searchTags]
  );
  const handleOnChange = (value: SearchResult[]) =>
    dispatch({
      type: CollectionSearchActions.updateSearchTags,
      searchTags: value
    });

  useEffect(() => {
    if (searchTags.length > 0) {
      onSubmit();
    } else {
      if (query.length) {
        onSubmit();
        return;
      }
      if (
        !listState.collections.some(({ id }) =>
          id.startsWith(searchCollectionIdPrefix)
        )
      ) {
        return;
      }
      onClear();
    }
  }, [searchTags, onSubmit, onClear, listState.collections, query]);

  return (
    <S.OuterContainer ref={searchRef}>
      <S.InnerContainer>
        <SearchInput
          query={query}
          searchTags={searchTags}
          options={results}
          placeholder={placeholder}
          onInputChange={handleOnInputChange}
          onChange={handleOnChange}
          onSubmit={onSubmit}
          onClear={onClear}
          disabledSuggestions
        />
      </S.InnerContainer>
    </S.OuterContainer>
  );
};

export default CollectionSearch;
