import React, { useState, Fragment } from "react";
import Masonry from "react-masonry-css";

import theme from "theme";

import { yellow } from "styles/colors";
import NewReportButton from "pages/report/NewReportButton";
import TruncateLength from "util/TruncateLength";
import { ReactComponent as MissingStreetView } from "img/icons/missing-street-view.svg";
import { DiImage } from "components/molecules/DiImage";
import { ReactComponent as BuildingIcon } from "img/building.svg";
import SummaryCard from "components/organisms/RelatedOrganisations/SummaryCard";
import { getDurationSinceStartDate } from "util/getDurationSinceStartDate";
import SourceLink from "components/atoms/SourceLink";
import { formatDate } from "util/common";

import S, { classNameOverrides } from "./styles";

const ModalContent = ({ data, onCreateNewTab, dataMap, isOnDisplay }) => {
  const [imageErrored, setImageErrored] = useState(false);

  const renderOrgStatus = () => {
    switch (data.orgStatus?.statusTag?.toUpperCase()) {
      case "ACTIVE":
        return (
          <S.StatusPill>
            <TruncateLength>{data.orgStatus?.label}</TruncateLength>
          </S.StatusPill>
        );
      case "INACTIVE":
        return (
          <S.StatusPill color={yellow.mud}>
            <TruncateLength>{data.orgStatus?.label}</TruncateLength>
          </S.StatusPill>
        );
      case "WARNING":
        return (
          <S.RiskPill>
            <TruncateLength>{data.orgStatus?.label}</TruncateLength>
          </S.RiskPill>
        );
      default:
        return null;
    }
  };

  const renderOrgOverview = () => {
    return (
      <S.Overview>
        <div>
          <S.OverviewImageContainer backgroundColor={theme.primaryColor}>
            <BuildingIcon />
          </S.OverviewImageContainer>
          {/* TODO: Wire up */}
          <NewReportButton isDisabled />
        </div>
        <S.OverviewDetails>
          <S.OverviewTitle>{data.name}</S.OverviewTitle>
          <S.Industry>
            <S.IndustryType>Nature of business: </S.IndustryType>
            {data.industry ? (
              data.industry
            ) : (
              <S.NoneIdentified display="inline">
                None identified
              </S.NoneIdentified>
            )}
          </S.Industry>
          <S.OrgLocation>
            {data.incorporatedCountry?.length > 0
              ? data.incorporatedCountry
              : null}
          </S.OrgLocation>
          {data.orgStatus?.statusTag || data.risk?.length ? (
            <S.OrgTags>
              {data.orgStatus?.statusTag ? renderOrgStatus() : null}{" "}
              {data.risk?.map(risk => (
                <S.RiskPill key={risk}>
                  <TruncateLength>{risk}</TruncateLength>
                </S.RiskPill>
              ))}
            </S.OrgTags>
          ) : null}
        </S.OverviewDetails>
      </S.Overview>
    );
  };

  const renderOrgDetails = () => {
    return (
      <S.OrgDetails>
        <S.OrganisationType>
          <S.DataKey>Organisation type</S.DataKey>
          {data.organisationType ? (
            <div>{data.organisationType}</div>
          ) : (
            <S.NoneIdentified>None identified</S.NoneIdentified>
          )}
        </S.OrganisationType>
        <div>
          <S.DataKey>Date of incorporation</S.DataKey>
          {data.startDate ? (
            <div>
              {formatDate(data.startDate)}{" "}
              <S.ListItemSecondaryInfo>
                {getDurationSinceStartDate({
                  startDate: data.startDate,
                  excludeSuffix: false
                })}
              </S.ListItemSecondaryInfo>
            </div>
          ) : (
            <S.NoneIdentified>None identified</S.NoneIdentified>
          )}
        </div>
      </S.OrgDetails>
    );
  };

  const renderOrgAddress = () => {
    const streetViewImage = data.registeredAddress?.streetViewImage;
    const isStreetViewMissing = !streetViewImage || imageErrored;

    if (
      !data.registeredAddress?.address?.length &&
      (!data.otherAddresses?.length || data.otherAddresses?.length > 2)
    ) {
      return null;
    }

    return (
      <S.Addresses>
        {data.registeredAddress?.address?.length ? (
          <>
            <S.ImageContainer isStreetViewMissing={isStreetViewMissing}>
              {isStreetViewMissing || !streetViewImage ? (
                <MissingStreetView />
              ) : (
                <DiImage
                  image={streetViewImage}
                  alt="Organisation office"
                  onError={() => setImageErrored(true)}
                  width="228px"
                  height="122px"
                  isCopyrighted
                />
              )}
            </S.ImageContainer>
            <S.RegisteredAddress
              otherAddressesPresent={
                data.otherAddresses?.length && data.otherAddresses?.length <= 2
              }
            >
              <S.DataKey>Registered address</S.DataKey>
              <div>
                {data.registeredAddress?.address.map(addressLine => (
                  <div key={addressLine}>{addressLine}</div>
                ))}
              </div>
            </S.RegisteredAddress>
          </>
        ) : null}
        {data.otherAddresses?.length <= 2
          ? data.otherAddresses.map((address, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <S.AddressBlock isFirstItem={index === 0} key={index}>
                {index === 0 ? <S.DataKey>Other addresses</S.DataKey> : null}
                {address?.address?.map(addressLine => (
                  <div key={addressLine}>{addressLine}</div>
                ))}
              </S.AddressBlock>
            ))
          : null}
      </S.Addresses>
    );
  };

  const renderOtherOrgAddresses = () => {
    // If there is less than 2 other addresses, then we know these are already rendered as part of the above section
    if (!data.otherAddresses || data.otherAddresses?.length <= 2) {
      return null;
    }

    return (
      <S.Section>
        <S.DataKey>Other addresses</S.DataKey>
        <S.OtherAddresses>
          {data.otherAddresses?.map((address, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {address?.address?.map(addressLine => (
                <div key={addressLine}>{addressLine}</div>
              ))}
            </div>
          ))}
        </S.OtherAddresses>
      </S.Section>
    );
  };

  const renderOrgDetails2 = () => {
    const renderPreviousNameDuration = (start, end) => {
      if (!start?.year && !end?.year) {
        return null;
      }

      if (start?.year === end?.year) {
        return `(${start?.year})`;
      }

      return `(${start?.year ?? ""}${start?.year ? " – " : ""}${
        end?.year ?? "Unknown"
      })`;
    };

    const orderedPreviousNames = data.previousNames?.sort((a, b) => {
      if (a.end?.year && b.end?.year) {
        return b.end.year - a.end.year;
      }

      if (a.end?.year && b.start?.year) {
        return b.start.year - a.end.year;
      }

      if (a.start?.year && b.end?.year) {
        return b.end.year - a.start.year;
      }

      if ((a.end?.year || a.start?.year) && !b.start?.year && !b.end?.year) {
        return -1;
      }

      if ((b.end?.year || b.start?.year) && !a.start?.year && !a.end?.year) {
        return 1;
      }

      return 0;
    });

    return (
      <S.OrgDetails2>
        <div>
          <S.DataKey>ID numbers</S.DataKey>
          {data.companyIdentifiers?.length ? (
            <S.Identifiers>
              {data.companyIdentifiers?.map(identifier => {
                return (
                  <S.IdentifierListItem
                    key={`${identifier.label}_${identifier.value}`}
                  >
                    <S.DataKey>{identifier.label}: </S.DataKey>
                    <div>{identifier.value}</div>
                  </S.IdentifierListItem>
                );
              })}
            </S.Identifiers>
          ) : (
            <S.NoneIdentified>None identified</S.NoneIdentified>
          )}
        </div>
        <S.PreviousNamesContainer>
          <S.DataKey>Previous names</S.DataKey>
          {data.previousNames?.length ? (
            <S.List>
              {orderedPreviousNames?.map(previous => {
                return (
                  <S.ListItem key={`${previous.name}_${previous.start}`}>
                    {previous.name}{" "}
                    <S.ListItemSecondaryInfo>
                      {renderPreviousNameDuration(
                        previous?.start,
                        previous?.end
                      )}
                    </S.ListItemSecondaryInfo>
                  </S.ListItem>
                );
              })}
            </S.List>
          ) : (
            <S.NoneIdentified>None identified</S.NoneIdentified>
          )}
        </S.PreviousNamesContainer>
      </S.OrgDetails2>
    );
  };

  const getOrgFromId = id => {
    return dataMap[id];
  };

  const getSubsidiaries = () => {
    const subsidiaries = data.subsidiaries?.map(subsidiary => {
      return getOrgFromId(subsidiary);
    });

    return subsidiaries;
  };

  const renderRelatedOrgs = () => {
    const parents = dataMap[data.id].orgParents;
    return (
      <>
        {parents?.length ? (
          <S.Section>
            <S.RelatedOrgCardsTitle>
              Parent organisations
            </S.RelatedOrgCardsTitle>
            <Masonry
              className={classNameOverrides.masonryGrid}
              columnClassName={classNameOverrides.masonryColumn}
            >
              {parents?.map(org => {
                return (
                  <SummaryCard
                    key={org?.id}
                    name={org?.name}
                    industry={org?.industry}
                    location={org?.incorporatedCountry}
                    organisationType={org?.organisationType}
                    parents={dataMap[org.id]?.orgParents}
                    subsidiaryIds={org?.subsidiaries}
                    dateOfIncorporation={org?.startDate}
                    orgStatus={org?.orgStatus}
                    risk={org?.risk}
                    image={
                      <S.OverviewImageContainer
                        backgroundColor={theme.primaryColor}
                      >
                        <BuildingIcon />
                      </S.OverviewImageContainer>
                    }
                    onCardClick={onCreateNewTab}
                    orgId={org?.id}
                  />
                );
              })}
            </Masonry>
          </S.Section>
        ) : null}
        {data.subsidiaries?.length ? (
          <S.Section>
            <S.RelatedOrgCardsTitle>Subsidiaries</S.RelatedOrgCardsTitle>
            <Masonry
              className={classNameOverrides.masonryGrid}
              columnClassName={classNameOverrides.masonryColumn}
            >
              {getSubsidiaries()?.map(org => {
                return (
                  <SummaryCard
                    key={org?.id}
                    name={org?.name}
                    industry={org?.industry}
                    location={org?.incorporatedCountry}
                    organisationType={org?.organisationType}
                    parents={dataMap[org.id]?.orgParents}
                    subsidiaryIds={org?.subsidiaries}
                    dateOfIncorporation={org?.startDate}
                    orgStatus={org?.orgStatus}
                    risk={org?.risk}
                    image={
                      <S.OverviewImageContainer
                        backgroundColor={theme.primaryColor}
                      >
                        <BuildingIcon />
                      </S.OverviewImageContainer>
                    }
                    onCardClick={onCreateNewTab}
                    orgId={org?.id}
                  />
                );
              })}
            </Masonry>
          </S.Section>
        ) : null}
      </>
    );
  };

  const renderSources = () => {
    if (!data?.sources?.length) {
      return null;
    }

    // Back compatibility check
    let sourceUrls;
    if (!Object.prototype.hasOwnProperty.call(data.sources[0], "label")) {
      // Extract out source urls
      sourceUrls = data.sources.map(source => {
        return source.url;
      });
    } else {
      sourceUrls = data.sources.map(source => source.url);
    }

    return (
      <S.Section>
        <S.RelatedOrgCardsTitle>Sources</S.RelatedOrgCardsTitle>
        <S.SourcesList>
          {sourceUrls?.map(source => {
            return (
              <div>
                <SourceLink
                  popoverClassName={classNameOverrides.sourceLink}
                  href={source}
                >
                  {source}
                </SourceLink>
              </div>
            );
          })}
        </S.SourcesList>
      </S.Section>
    );
  };

  return (
    isOnDisplay && (
      <S.Content>
        {renderOrgOverview()}
        {renderOrgDetails()}
        {renderOrgAddress()}
        {renderOtherOrgAddresses()}
        {renderOrgDetails2()}
        {renderRelatedOrgs()}
        {renderSources()}
      </S.Content>
    )
  );
};

export default ModalContent;
