import React, { createContext, useContext, useState } from "react";

export const useUserVerification = key => {
  const [userVerifications, setUserVerifications] = useContext(
    UserVerificationContext
  );
  const setUserVerification = value =>
    setUserVerifications(new Map(userVerifications).set(key, value));
  return [
    userVerifications && userVerifications.has(key)
      ? userVerifications.get(key)
      : undefined,
    setUserVerification
  ];
};

export const UserVerificationContext = createContext([
  new Map(),
  () => console.error("User verification context not setup yet")
]);

export const UserVerificationProvider = props => {
  const { children } = props;
  const [userVerifications, setUserVerifications] = useState(new Map());
  const [userVerificationCount, setUserVerificationCount] = useState(0);

  return (
    <UserVerificationContext.Provider
      value={[
        userVerifications,
        setUserVerifications,
        userVerificationCount,
        setUserVerificationCount
      ]}
    >
      {children}
    </UserVerificationContext.Provider>
  );
};
