import { createContext, useContext } from "react";

export const EnquiryIdContext = createContext<string | undefined>(undefined);
export const useEnquiryId = (): string => {
  const id = useContext(EnquiryIdContext);
  if (id && id.length) {
    return id;
  }

  // TODO: allow this to return undefined and fix everywhere this is used
  return "";
};
