import React, { FC, PropsWithChildren } from "react";
import { EntityType, ReportSubjectEntityTypeContext } from "./context";

export const ReportSubjectEntityTypeProvider: FC<
  PropsWithChildren<{ subjectEntityType: EntityType }>
> = ({ subjectEntityType, children }) => {
  return (
    <ReportSubjectEntityTypeContext.Provider value={subjectEntityType}>
      {children}
    </ReportSubjectEntityTypeContext.Provider>
  );
};
