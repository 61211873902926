import { createContext } from "react";
import { RiskSummariesContextValue, RiskSummariesStatus } from "./types";

const defaultValue: RiskSummariesContextValue = {
  hasRiskSummaries: false,
  status: RiskSummariesStatus.NotLoaded,
  getSummary: () => undefined
};

const RiskSummariesContext = createContext(defaultValue);

export default RiskSummariesContext;
