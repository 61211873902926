import { useRef, useEffect, useMemo } from "react";

const useDebounce = () => {
  const timeout = useRef<NodeJS.Timeout | undefined>();

  const debounce = useMemo(
    () =>
      (func: (...args: any[]) => void, wait: number) =>
      (...args: any[]) => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => func(...args), wait);
      },
    []
  );

  useEffect(() => {
    return () => {
      if (!timeout.current) return;
      clearTimeout(timeout.current);
    };
  }, []);

  return { debounce };
};

export default useDebounce;
