import { Assertion, AssertionTemplate } from ".";
import { professionalOverview } from "./recognisers";

const exactCorporateRecordAssertionTemplate = orgName =>
  new AssertionTemplate((isValid, isMust, testCaseId, userId, userComment) => {
    const constKey = isValid ? "const" : "not";
    const constVal = isValid ? orgName : { const: orgName };
    const arrayKey = isValid ? "contains" : "items";
    const assertionSchema = {
      type: "object",
      properties: {
        ProfessionalOverview: {
          type: "object",
          properties: {
            ListOfProfessionalInformation: {
              type: "array",
              [arrayKey]: {
                type: "object",
                properties: {
                  OrgName: {
                    type: "string",
                    [constKey]: constVal
                  }
                }
              }
            }
          }
        }
      }
    };

    const assertionHumanReadable =
      `Report ${isMust ? "must" : "should"}${isValid ? "" : " not"} ` +
      `contain a corporate record for ${orgName}.`;
    return new Assertion(
      testCaseId,
      userId,
      userComment,
      isMust,
      assertionSchema,
      assertionHumanReadable
    );
  }, professionalOverview.specificOrgRecord(orgName));

export default exactCorporateRecordAssertionTemplate;
