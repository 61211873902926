import React, {
  useState,
  useContext,
  createContext,
  useMemo,
  useRef,
  useEffect
} from "react";
import { useSearchParams } from "react-router-dom";
import Reports from "api/reports";
import { usePrintModeEnabled } from "./useIsPrintModeEnabled";

export const PRINTABLE_STATE_TYPES = {
  sectionExpand: "sectionExpand"
};

export const encodeValue = v => {
  if (v === undefined || v === null) return v;
  if (Array.isArray(v)) {
    return encodeURI(JSON.stringify(v.map(x => encodeValue(x))));
  }
  return encodeURI(v);
};
const createPrintableStateContext = () =>
  createContext({
    state: new Map(),
    setState: () =>
      console.error(
        "Trying to set printable state but print state store not properly set up"
      ),
    removeState: () =>
      console.error(
        "Trying to set printable state but print state store not properly set up"
      ),
    reset: () =>
      console.error(
        "Trying to set printable state but print state store not properly set up"
      )
  });

export const ReportPrintableStateContext = createPrintableStateContext();
export const GlobalPrintableStateContext = createPrintableStateContext();

/**
 * To be used as a substitute for useState when the state in question must be re-produced for server-side rendering of the report when printing
 * The component must be a descendent of the PrintableStateContext context
 * Should NOT be used as a way to propagate global state in the application, as this will reduce component-reusability
 * Use basic stuff like arrays, strings, booleans and numbers as the value - serialisation goes skew-iff otherwise
 * @param {string} key
 * @param {any} defaultValue
 */
export const usePrintableReportState = (
  key,
  defaultValue,
  type = undefined
) => {
  const printableStateStore = useContext(ReportPrintableStateContext);
  const cleanKey = encodeURI(key);
  const initialValue = printableStateStore.state.has(cleanKey)
    ? printableStateStore.state.get(cleanKey)?.value
    : defaultValue;
  const [basicState, setBasicState] = useState(initialValue);
  const currentBasicState = useRef(initialValue);

  useEffect(
    () => {
      if (printableStateStore.state.has(cleanKey)) {
        const cleanValue = printableStateStore.state.get(cleanKey);
        const value = cleanValue;
        if (value.value !== basicState) {
          setBasicState(value.value);
        }
      }
    },
    // TODO: include missing dependencies
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [printableStateStore, printableStateStore.state]
  );

  // If the state is being used to control section expansion, by default add this to the store
  useEffect(() => {
    if (type === PRINTABLE_STATE_TYPES.sectionExpand) {
      printableStateStore.setState(cleanKey, initialValue, type);
    }
    // too risky to change now!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setState = useMemo(
    () => value => {
      let state = value;

      // If the value is a function (state updater)
      // then call it to return the updated value
      if (typeof value === "function") {
        // We're passing in the current held state obtained from a ref - this is so we
        // can work around the closure around the basicState
        state = value(currentBasicState.current);
      }

      // Updating both the state and the state held in the ref
      setBasicState(state);
      currentBasicState.current = state;

      if (state === defaultValue && printableStateStore.state.has(cleanKey)) {
        printableStateStore.removeState(cleanKey);
      } else {
        printableStateStore.setState(cleanKey, state, type); // Note we encode this in ReportHeader, before the call to the API
      }
    },
    // TODO: include missing dependencies
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [setBasicState, printableStateStore, cleanKey]
  );

  return [basicState, setState];
};

const useInitialPrintableState = () => {
  const [searchParams, _] = useSearchParams();
  const printModeEnabled = usePrintModeEnabled();
  const [state, setState] = useState(new Map());
  const stateId = printModeEnabled && searchParams.get("stateId");
  useEffect(() => {
    const api = new Reports();
    if (printModeEnabled) {
      if (!stateId || !stateId.length) {
        console.error(
          "Print mode, but no print request ID - state may not load correctly"
        );
      } else {
        const fetchAsync = async () => {
          try {
            const stateResponse = await api.getPDFMetadata(stateId);
            setState(stateResponse);
          } catch (e) {
            console.error("Error loading report print state", { stateId, e });
          }
        };
        fetchAsync();
      }
    }
  }, [printModeEnabled, stateId]);
  return state;
};

export const PrintStateUrlStateExtractor = props => {
  const { children } = props;
  const state = useInitialPrintableState();
  const isPrintModeEnabled = usePrintModeEnabled();
  const mutableState = useRef(state);

  const setState = useMemo(
    () => (key, val, type) => {
      mutableState.current.set(key, { value: val, type });
    },
    []
  );
  const removeState = useMemo(
    () => key => {
      mutableState.current.delete(key);
    },
    []
  );
  const reset = useMemo(
    () => newState => {
      mutableState.current = newState || new Map();
    },
    []
  );
  const globalPrintableStateStore = useMemo(() => {
    return {
      state: isPrintModeEnabled ? state : mutableState.current,
      setState,
      removeState,
      reset
    };
  }, [setState, removeState, reset, isPrintModeEnabled, state]);

  return useMemo(() => {
    return (
      <GlobalPrintableStateContext.Provider value={globalPrintableStateStore}>
        {children}
      </GlobalPrintableStateContext.Provider>
    );
  }, [globalPrintableStateStore, children]);
};
