import { useContext } from "react";

import { RequestQueueContext } from "./context";

export { RequestQueueContextProvider } from "./provider";

const useRequestQueue = () => {
  const context = useContext(RequestQueueContext);

  if (!context) {
    throw new Error(
      "useRequestQueue must be used within a RequestQueueContextProvider"
    );
  }

  return context;
};

export default useRequestQueue;
