import React from "react";

import ModalContainer from "components/molecules/ModalContainer";
import ErrorBanner from "components/atoms/ErrorBanner";

import { useRegistration } from "util/hooks/useRegistration";
import {
  RegistrationStatus,
  RegistrationActions
} from "util/hooks/useRegistration/types";

import UserDetails from "./UserDetails";
import PasswordFields from "./PasswordFields";
import TokenExpired from "./TokenExpired";

const GettingStartedModal = () => {
  const { state, dispatch } = useRegistration();

  const onDismissError = () => {
    dispatch({ type: RegistrationActions.dismissError });
  };

  return (
    <ModalContainer
      title={
        state.status === RegistrationStatus.tokenExpired
          ? "Expired token"
          : "Get started"
      }
      toggleOpen={() => null}
      isOpen
      width={540}
    >
      {state.error && (
        <ErrorBanner text={state.error} onClick={onDismissError} />
      )}
      {state.status === RegistrationStatus.tokenExpired && <TokenExpired />}
      {state.status === RegistrationStatus.unregistered && <UserDetails />}
      {(state.status === RegistrationStatus.fieldsSubmitted ||
        state.status === RegistrationStatus.registrationSubmitted) && (
        <PasswordFields />
      )}
    </ModalContainer>
  );
};

export default GettingStartedModal;
