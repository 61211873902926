import { styled } from "@linaria/react";

import SourceLink from "components/atoms/SourceLink";

import { blue, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const MediaIcon = styled.div`
  display: flex;

  & > svg {
    width: 20px;
    height: 20px;
    border-radius: 3px;

    path {
      fill: ${blue.icon};
    }
  }
`;

const WebsiteIconContainer = styled(MediaIcon)`
  path {
    fill: ${standardColors.white};
  }
`;

const MediaItem = styled.div`
  overflow: visible;
  white-space: nowrap;
  font-size: ${fontSize.sm};
  padding-bottom: ${props => (props.arrangeIconsInRow ? 0 : 5)}px;
  margin-right: ${props => (!props.arrangeIconsInRow ? 0 : 5)}px;

  &:last-child {
    margin-right: 0;
    padding-bottom: 0;
  }

  ${MediaIcon} {
    & > svg {
      margin-right: ${props => (props.arrangeIconsInRow ? 0 : 5)}px;
    }
  }
`;

const MediaLink = styled(SourceLink)`
  display: flex;
  align-items: center;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "none")};

  &:hover {
    color: ${blue.hover};
  }
`;

const MediaItemContainer = styled.div`
  display: ${props => (props.arrangeIconsInRow ? "flex" : "block")};
  width: ${props => (props.arrangeIconsInRow ? "auto" : "100%")};
`;

const FollowerCount = styled.div`
  color: ${blue.icon};
  font-size: ${fontSize.sm};
  min-width: 30px;
  text-align: right;
`;

const Label = styled.div`
  margin-right: 8px;
`;

const ProfileLabel = styled.div`
  padding-left: 6px;
`;

const MediaLinkWithInspector = styled.div`
  display: flex;
  align-items: center;
  max-width: ${props => props.maxWidth};
`;

const MediaItemWithInspector = styled(MediaItem)`
  display: flex;
  justify-content: space-between;
  padding: 4px;
  align-items: center;
`;

const S = {
  MediaIcon,
  MediaItem,
  MediaLink,
  WebsiteIconContainer,
  MediaItemContainer,
  FollowerCount,
  Label,
  ProfileLabel,
  MediaItemWithInspector,
  MediaLinkWithInspector
};

export default S;
