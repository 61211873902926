import React, { useContext } from "react";
import { isPDX } from "static-config";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { SourceSection } from "./SourceSection";

const DisregardedInformation = React.forwardRef((props, ref) => {
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;

  if (isPDX && !diagnosticsModeEnabled) {
    return null;
  }

  return (
    <SourceSection
      {...props}
      ref={ref}
      isDisregarded
      description="Information that is not about your subject or could not be
      definitively tied to your subject."
    />
  );
});

export default DisregardedInformation;
