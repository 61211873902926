import React, { FormEvent, useEffect, useState } from "react";
import { apm } from "@elastic/apm-rum";
import { QRCodeSVG } from "qrcode.react";
import PinInput from "components/molecules/PinInput";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import {
  useHubAuthentication,
  HubAuthenticationActions
} from "util/hooks/useHubAuthentication";

import { MFA_CODE_LENGTH } from "util/hooks/useHubAuthentication/types";

import ErrorBanner from "components/atoms/ErrorBanner";

import S from "./styles";

const HubMfaForm = () => {
  const [mfaValidationError, setMfaValidationError] = useState(false);
  const [qrCode, setQrCode] = useState<undefined | string>();
  const { state, dispatch } = useHubAuthentication();
  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (state.mfaCode.replace(/ /g, "").length !== MFA_CODE_LENGTH) {
      setMfaValidationError(true);

      return;
    }

    if (state.nextSignInStep === "CONTINUE_SIGN_IN_WITH_TOTP_SETUP") {
      dispatch({ type: HubAuthenticationActions.mfaSetup });
    } else {
      dispatch({ type: HubAuthenticationActions.authenticate });
    }
  };

  const onChange = (value: string) => {
    dispatch({ type: HubAuthenticationActions.updateMfaCode, mfaCode: value });
  };

  const onDismissMfaValidationError = () => {
    setMfaValidationError(false);
  };

  useEffect(() => {
    const promptQrCodeForMfa = async () => {
      try {
        if (state.getSetupUri) {
          const qrUriString = state.getSetupUri("Xapien%20Hub", state.email);
          setQrCode(qrUriString);
        }
      } catch (e) {
        apm.captureError(e as Error);
        console.error(e);
      }
    };

    if (state.nextSignInStep === "CONTINUE_SIGN_IN_WITH_TOTP_SETUP") {
      promptQrCodeForMfa();
    }
  }, [state]);

  return (
    <form onSubmit={onSubmit}>
      <S.Container>
        <S.XapienLogo />
        <S.Title level={5}>Xapien Hub</S.Title>
        <S.Subtitle level={4}>
          Enter your six-digit verification code
        </S.Subtitle>

        <S.Description>
          {qrCode
            ? "Scan the provided QA code with your authentication app and enter the code."
            : "This code is generated by the app you used when setting up two-factor authentication."}
        </S.Description>

        {qrCode && (
          <S.QRCodeContainer>
            <QRCodeSVG value={qrCode} />
          </S.QRCodeContainer>
        )}

        <S.Inputs>
          <PinInput
            length={MFA_CODE_LENGTH}
            onChange={onChange}
            value={state.mfaCode}
          />
        </S.Inputs>

        {mfaValidationError && (
          <ErrorBanner
            text="Please enter a valid 6 digit code"
            onClick={onDismissMfaValidationError}
          />
        )}

        <ButtonNew
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          text="Go"
        />
      </S.Container>
    </form>
  );
};

export default HubMfaForm;
