import React from "react";
import ImageBank from "components/organisms/ImageBank";
import SectionFooter from "components/atoms/SectionFooter";

const ImageGallery = ({ photoMontage }) => {
  return (
    <div>
      <div className="report-section-content-inner">
        <ImageBank images={photoMontage} />
      </div>
      <SectionFooter />
    </div>
  );
};

export default ImageGallery;
