import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";
import { grey, black, standardColors } from "styles/colors";

import { Carousel as BaseCarousel } from "react-responsive-carousel";
import BaseHeading from "components/atoms/Heading";

const SlideContainer = styled.div`
  background: transparent;
  overflow: visible;
`;

const BoxContent = styled.div<{ top: string; left: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 16px;
  background: ${standardColors.white};
  z-index: 2;
  padding: 28px;
  width: 400px;
  height: 266px;
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

// @ts-ignore The carousel library we use does not have typescript
const Carousel = styled(BaseCarousel)`
  .slide {
    background: transparent;
  }

  margin-top: auto;

  .control-dots {
    position: relative;
    padding-top: 1rem;

    .dot {
      background: ${grey.mid42};
      box-shadow: none;

      &.selected,
      &:has(~ .selected) {
        opacity: 1;
        background: ${standardColors.white};
      }
    }
  }
`;

const CarouselContainer = styled.div`
  height: 90vh;
  max-height: 50%;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`;

const CarouselImage = styled.div<{ top: string; left: string }>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  min-height: 280px;
  max-width: 80%;
  max-height: 80%;
  align-self: center;
  position: relative;
`;

const LinkToVideo = styled.div`
  color: #02a3d5;
  text-align: center;
  font-size: ${fontSize.md};
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
  letter-spacing: -0.69px;
  text-decoration-line: underline;
`;

const Heading = styled(BaseHeading)`
  color: ${standardColors.black};
  font-size: ${fontSize.lg};
`;

const Divider = styled.hr`
  color: ${standardColors.black};
  width: 10%;
  height: 2px;
  border-top-color: ${standardColors.black};
  text-align: left;
`;

const Description = styled.p`
  font-size: ${fontSize.md};
  color: ${black.notQuiteBlack};
  font-weight: 500;
  text-align: left;
`;

const ButtonWrapper = styled.button`
  background: transparent;
  color: ${standardColors.white};
  border: none;
`;

const NextArrow = styled.img`
  background: transparent;
  color: ${standardColors.white};
  transform: rotate(180deg);
`;

const PreviousArrow = styled.img`
  background: transparent;
  color: ${standardColors.white};
`;

const ActionButtonContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: auto;
  gap: 24px;
`;

const S = {
  PreviousArrow,
  ButtonWrapper,
  ActionButtonContainer,
  NextArrow,
  LinkToVideo,
  Description,
  Divider,
  Heading,
  BoxContent,
  CarouselImage,
  SlideContainer,
  Carousel,
  CarouselContainer
};

export default S;
