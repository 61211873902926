import { styled } from "@linaria/react";
import { grey } from "styles/colors";

const CollectionListContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
`;

const CollectionListOuterContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 1424px;
  margin: 0;
  gap: 1rem;
  align-items: center;

  @media (min-width: 1424px) {
    min-width: 1424px;
    margin: auto;
  }
`;

const CollectionNavigationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const CollectionEnd = styled.div`
  width: 100%;
  position: absolute;
  bottom: 300px;
`;

const CollectionStart = styled.div`
  width: 100%;
  position: absolute;
  top: 0px;
`;

const NoResults = styled.h2`
  color: ${grey.ghost};
`;

const S = {
  CollectionEnd,
  CollectionStart,
  CollectionListContainer,
  CollectionListOuterContainer,
  CollectionNavigationContainer,
  NoResults
};

export default S;
