import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import ErrorBanner from "components/atoms/ErrorBanner";
import ShareListItem from "components/molecules/ListItemWithOptions";
import { motion } from "framer-motion";
import { blue, green, grey, red, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const ShareInputs = styled.div`
  display: flex;
  flex-direction: column;
`;

const SharedWithList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShareWithOthersContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${grey.mid};
  padding-top: 1rem;
`;

const LinkControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShareLinkContainer = styled.div`
  background-color: ${grey.panel};
  border-radius: 0.375rem;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  flex: 1;
  font-size: ${fontSize.sm};
`;

const SharedWithItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 1.5rem;
  max-height: 15.9375rem;
  overflow: auto;
`;

const OwnerShareListItem = styled(ShareListItem)`
  & button {
    background-color: transparent !important;
  }
`;

const GeneratedLink = styled.div`
  font-size: ${fontSize.sm};
  word-break: break-all;
`;

const TitleCardContainer = styled.div`
  border: 1px solid ${standardColors.white};
  border-radius: 0.75rem;
`;

const DeleteLinkContainer = styled.div`
  border: 1px solid #f14b4b;
  padding: 0.75rem;
  border-radius: 0.75rem;
  background-color: ${blue.xapienDark};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`;

const DeleteLinkExplainer = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
  color: ${grey.ghost};
`;

const SharePermissionItemSkeletonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const SharePermissionItemSkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SharedWithTitle = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
`;

const LinkButton = styled(ButtonNew)`
  text-wrap: nowrap;
  &:focus {
    background-color: none;
  }

  &:hover,
  &:focus {
    background-color: transparent !important;
    color: ${blue.xapienLight} !important;

    & > svg > path {
      stroke: ${blue.xapienLight} !important;
    }
  }

  &:disabled {
    background-color: transparent !important;
    color: ${grey.ghost} !important;

    & > svg > path {
      stroke: ${grey.ghost} !important;
    }
  }
`;

const GeneratedLinkContainer = styled(motion.div)`
  background-color: #355672;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-radius: 0.375rem;
  padding: 0.75rem 0 0.75rem 0.75rem;
  margin-top: 1rem;
  overflow: hidden;
`;

const ShareConfirmedBanner = styled(motion.div)`
  background-color: ${green.secondaryLight};
  padding: 1.375rem 1.75rem 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -2rem -2rem;
  border-radius: 0 0 0.75rem 0.75rem;
`;

const ShareConfirmedBannerInnerContainer = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;
`;

const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${standardColors.white};
  border-radius: 100px;
`;

const AddShareEntryControl = styled.div`
  display: flex;
  gap: 0.75rem;
  padding-top: 1rem;
`;

const AddShareEntryError = styled(ErrorBanner)`
  margin-top: 1rem;
`;

const AddShareEntryButton = styled(ButtonNew)`
  align-self: flex-start;
  background-color: ${blue.xapienLight};
`;

/** ClassNameOverrides */

const deleteOption = css`
  color: ${red.directRiskOutline};

  & p {
    color: ${red.directRiskOutline};
  }
`;

const generatedLink = css`
  & .MuiAvatar-root {
    background: ${blue.xapienDark};
    border: 1px solid ${grey.ghost} !important;
  }
`;

const nonGeneratedLink = css`
  & .MuiAvatar-root {
    background: transparent;
    border: 1px solid ${grey.ghost} !important;

    & > svg {
      stroke: ${grey.ghost};
    }
  }

  & button:disabled {
    background-color: transparent !important;
  }

  /* 😅 */
  & > div > div:nth-child(2) > div:nth-child(1) {
    color: ${grey.ghost};
  }
`;

const addShareReady = css`
  outline: 2px solid ${blue.xapienLight};
`;

const S = {
  ShareInputs,
  SharedWithList,
  ShareWithOthersContainer,
  LinkControls,
  ShareLinkContainer,
  LinkButton,
  SharedWithItemsContainer,
  OwnerShareListItem,
  GeneratedLink,
  TitleCardContainer,
  DeleteLinkContainer,
  DeleteLinkExplainer,
  SharePermissionItemSkeletonContainer,
  SharePermissionItemSkeletonContent,
  SharedWithTitle,
  GeneratedLinkContainer,
  ShareConfirmedBanner,
  ShareConfirmedBannerInnerContainer,
  CheckContainer,
  AddShareEntryControl,
  AddShareEntryError,
  AddShareEntryButton
};

export const classNameOverrides = {
  deleteOption,
  generatedLink,
  nonGeneratedLink,
  addShareReady
};

export default S;
