import { styled } from "@linaria/react";
import { grey } from "styles/colors";

const Disclaimer = styled.section`
  background: ${grey.panel};
  padding: 1rem;

  p {
    margin-top: 1rem;
  }
`;

const S = { Disclaimer };

export default S;
