/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ParagraphItem = {
  type: ParagraphItem.type;
  text: string;
  uri?: string;
};

export namespace ParagraphItem {
  export enum type {
    TEXT = "text",
    LINK = "link"
  }
}
