import React from "react";
import Tock from "tocktimer";
import { inject, observer } from "mobx-react";
import { withParams } from "util/routerHocs";
import Overview from "pages/diagnostics/Overview";
import config from "config";

class DiagnosticOverview extends React.Component {
  constructor(props) {
    super(props);

    this._refresh = this._refresh.bind(this);
    this._clockRefresh = this._clockRefresh.bind(this);
    this.allowTimerStart = true;
    this.timer = new Tock({
      interval: config.diagnosticIntervalMs,
      callback: this._refresh
    });
    this.clockTimer = new Tock({
      interval: 250,
      callback: this._clockRefresh
    });
    this.failCount = 0;
    this.pollSlow = false;
  }

  componentDidMount() {
    const { diagnosticsStore, enquiryId: baseEnquiryId, params } = this.props;

    const enquiryId = baseEnquiryId || params.enquiryId;
    this.timer.start(); // start the refresh loop - want to keep retrying even if first load doesn't succeed before timeout
    diagnosticsStore
      .overview(enquiryId)
      .then(() => {
        if (this.allowTimerStart) {
          this.clockTimer.start();
        }
      })
      .catch(console.error);
  }

  componentDidUpdate() {
    if (this.textArea) {
      this.textArea.scrollTop = 0;
    }
  }

  componentWillUnmount() {
    this.allowTimerStart = false;
    this.timer.stop();
    this.clockTimer.stop();
  }

  async _refresh() {
    const { diagnosticsStore, enquiryId: baseEnquiryId, params } = this.props;

    if (diagnosticsStore.waitingOnResponse) {
      console.debug(
        "Still waiting for diagnostics overview response - backend is being slow"
      );
      return;
    }
    const enquiryId = baseEnquiryId || params.enquiryId;

    try {
      await diagnosticsStore.overview(enquiryId, false);
      // after a success we go back to frequent polling and reset our failure count
      if (this.pollSlow) {
        this.timer.stop();
        this.timer = new Tock({
          interval: config.diagnosticIntervalMs,
          callback: this._refresh
        });
        this.timer.start();
        this.pollSlow = false;
      }
      this.failCount = 0;
    } catch (e) {
      this.failCount += 1;
      console.error("Error loading dianostics overview", e);
      if (
        this.failCount > 20 &&
        this.pollSlow &&
        this.clockTimer.lap() > 30 * 60 * 1000
      ) {
        // 40 mins later, we're just going to give up - there's little chance of getting the
        // response and don't want to risk continuing making calls overnight if the backend
        // is actually broken - could cost us a load of money. Of course really that's the backend's
        // problem so should be sorted there, but that's one for another day
        console.error(
          "Diagnostics screen errors - maybe the enquiry doesn't exist or the backend is broken. Giving up now."
        );
        this.timer.stop();
      } else if (this.failCount > 6 && !this.pollSlow) {
        console.error(
          `
            Diagnostics scree errors - maybe the enquiry doesn't exist or the backend is broken.
            Will slow down polling for a while but if no success will eventually give up.
          `
        );
        this.timer.stop();
        this.timer = new Tock({
          interval: config.diagnosticSlowerIntervalMs,
          callback: this._refresh
        });
        this.timer.start();
        this.pollSlow = true;
      }
    }
  }

  _clockRefresh() {
    const { diagnosticsStore } = this.props;
    diagnosticsStore.calculateTimeTaken(this.clockTimer.lap());
  }

  render() {
    const { diagnosticsStore: diagProps, isLoadingInsights } = this.props;

    if (diagProps.loaded) {
      if (diagProps.notFound) {
        return <h2>Enquiry not found</h2>;
      }

      const insightsInProgress =
        diagProps.enquiryOverview.reportReady && isLoadingInsights;

      const INSIGHTS_STATUS_ROW = 2;
      const INSIGHTS_STATUS_ROW_COLUMN = 3;
      const {
        statusColumns,
        currentStatusText,
        enquiryOverview: { reportReady }
      } = diagProps;

      const modifiedStatusColumns =
        reportReady && isLoadingInsights
          ? statusColumns.map(row =>
              row.map(column => (column ? { ...column, isDone: true } : column))
            )
          : statusColumns;

      const updatedCurrentStatusText = insightsInProgress
        ? "Generating Insights"
        : currentStatusText;

      const updatedStatusColumns = modifiedStatusColumns.map((row, rowIndex) =>
        rowIndex === INSIGHTS_STATUS_ROW
          ? row.map((column, colIndex) =>
              colIndex === INSIGHTS_STATUS_ROW_COLUMN
                ? {
                    isCurrent: insightsInProgress,
                    isDone: false,
                    numberOfTasksComplete: 0,
                    numberOfTasksInError: 0,
                    numberOfTasksInProgress: insightsInProgress ? 1 : 0,
                    orchestrationTasksHaveCompleted: true,
                    state: "INSIGHTS_REPORT", // TODO: Could this come from a constant/enum?
                    stateName: "Generating Insights", // TODO: Could this come from a constant/enum?
                    status: "IN_PROGRESS", // TODO: Could this come from a constant/enum?
                    statusName: "In progress" // TODO: Could this come from a constant/enum?
                  }
                : column
            )
          : row
      );

      return (
        <div style={{ flex: 1, width: "100%" }}>
          <Overview
            isOutage={diagProps.isOutage}
            currentStatusText={updatedCurrentStatusText}
            messages={diagProps.messages}
            statusColumns={updatedStatusColumns}
            timeTaken={diagProps.timeTaken}
            timestampCompleted={diagProps.enquiryOverview.timestampCompletedUtc}
          />
        </div>
      );
    }

    return <div />;
  }
}

export default withParams(
  inject("diagnosticsStore")(observer(DiagnosticOverview))
);
