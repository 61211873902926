export const locationRiskText = {
  transparencyInternational: {
    explainer:
      "This country is a 'high risk country' in Transparency International's Corruption Perceptions Index",
    label: "transparencyinternational"
  },
  eu: {
    explainer:
      "This country is on the EU list of 'high-risk third countries' for money laundering and terrorist financing",
    label: "eu"
  },
  hmtreasury: {
    explainer:
      "This country is on the HM Treasury list of 'high-risk third countries' for money laundering and terrorist financing",
    label: "hmtreasury"
  },
  siteRisk: {
    explainer:
      "This website has been marked with a risk flag because it exposes corruption or malpractice.",
    label: "site-risk"
  }
};
