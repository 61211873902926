import { styled } from "@linaria/react";

import { ReactComponent as CloudTickSvg } from "img/icons/status/cloud-tick.svg";
import { ReactComponent as CloudStrikethroughSvg } from "img/icons/status/cloud-strikethrough.svg";
import { ReactComponent as CircleTickSvg } from "img/icons/status/circle-tick.svg";

import { red, purple } from "styles/colors";
import { family, size } from "styles/fonts";
import { isPDX } from "static-config";

// @ts-ignore
import theme from "theme";

const DefaultIcon = styled(CloudTickSvg)`
  width: 22px;

  path,
  circle {
    stoke: ${isPDX ? theme.primaryColor : purple.darker};
  }
`;

const SuccessIcon = styled(CircleTickSvg)`
  height: 16px;

  path,
  circle {
    stoke: ${isPDX ? theme.primaryColor : purple.darker};
  }
`;

const SavingIconWrapper = styled.div`
  width: 22px;

  img {
    width: 100%;
  }
`;

const ErrorIcon = styled(CloudStrikethroughSvg)`
  width: 22px;

  path {
    stroke: ${red.directRisk};
  }
`;

const Container = styled.div<{ isError?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  height: 20px;

  > span {
    color: ${({ isError }) => {
      if (isError) {
        return red.directRisk;
      }

      return isPDX ? theme.primaryColor : purple.darker;
    }};
    line-height: 26px;
    font-family: ${family.interDisplay};
    font-weight: 600;
    font-size: ${size.sm};
  }
`;

const S = { DefaultIcon, Container, SuccessIcon, SavingIconWrapper, ErrorIcon };

export default S;
