import { styled } from "@linaria/react";
import { Handle as ReactFlowHandle } from "reactflow";

import { grey } from "styles/colors";

const HiddenHandle = styled(ReactFlowHandle)`
  opacity: 0;
`;

const Handle = styled(ReactFlowHandle)`
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 10px solid ${grey.mid};
  }
`;

const S = {
  Handle,
  HiddenHandle
};

export default S;
