import { styled } from "@linaria/react";
import { Modal as RSModal } from "reactstrap";

import HeadingComponent from "components/atoms/Heading";

import { blue, standardColors, grey } from "styles/colors";
import { family } from "styles/fonts";

export interface ModalProps {
  noGap?: boolean;
}

const ExitButton = styled.button`
  all: unset;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const NewExitButton = styled.button`
  border: 0;
  border-radius: 50%;
  padding: 8px;
  background-color: ${grey.panel};

  &:not(:focus-visible) {
    outline: none;
  }
`;

const Modal = styled(RSModal)<ModalProps>`
  max-width: ${({ width }) => (width ? `${width}px` : "43rem")};

  & > div {
    border: ${({ variant }) => (variant === "light" ? "0" : "")};
    background-color: ${({ variant }) =>
      variant === "light" ? standardColors.white : blue.portalBlue};
    border-radius: 0.75rem;
    padding: ${({ variant }) => (variant === "light" ? "1rem" : "2rem")};
    position: relative;
    display: flex;
    gap: ${({ noGap }) => (noGap ? "0" : "1.5rem")};
    color: ${({ variant }) =>
      variant === "light" ? grey.dark : standardColors.white};
  }
`;

const LightHeading = styled(HeadingComponent)`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${family.interDisplay};
  font-weight: 600;
`;

const LightHeadingIconContainer = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: auto;

  &:hover {
    background-color: ${grey.panel};
  }

  svg {
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  padding: 0 1rem 1rem;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const S = {
  Modal,
  ExitButton,
  NewExitButton,
  ModalContent,
  ModalHeader,
  LightHeading,
  LightHeadingIconContainer
};

export default S;
