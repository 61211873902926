import { css } from "@linaria/core";

export const globals = css`
  /* Old legacy code from a long, long time ago. This is essentially modified Bootstrap 4.
  * If you find yourself reaching for these styles to add to or modify, please take this time
  * to make what you're building a Linaria styled component, or if the styles would benefit
  * from being global, a more robust global system than hacked Bootstrap.
  */
  :global() {
    .collapse:not(.show) {
      display: none;
    }

    .collapsing {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: height 0.35s ease;
    }
    @media screen and (prefers-reduced-motion: reduce) {
      .collapsing {
        transition: none;
      }
    }
  }
`;
