import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";

const STACK_BAR_COLORS = {
  0: "#ee7796",
  1: "#8e70b8",
  2: "#33b8bf",
  3: "#858996",
  4: "#f39200",
  5: "#61b232",
  6: "#4674ba",
  7: "#505f70",
  8: "#d7487c",
  9: "#007c89",
  10: "#6a4394",
  11: "#338c50",
  12: "#9e5e22",
  13: "#005e30",
  14: "#9a2e41",
  15: "#005572",
  // Below are random colors not by UX
  16: "#008941",
  17: "#006fa6",
  18: "#a30059",
  19: "#ffdbe5",
  20: "#7a4900"
};

const BarWrapper = styled.div`
  width: 100%;
  font-size: ${fontSize.sm};
  margin-top: 16px;
`;

const BarValue = styled.div`
  visibility: ${props => (props.active ? "visible" : "hidden")};
  display: flex;
  color: ${props => STACK_BAR_COLORS[props.index]};
  width: ${props => props.width}px;
`;

const BarInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

const Bar = styled.div`
  height: 10px;
  margin-right: 1px;
  background: ${props => STACK_BAR_COLORS[props.index]};
`;

const BarItem = styled.div`
  width: ${props => props.width}px;
`;

const LegendList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  column-count: 3;
  column-gap: 1px;
`;

const LegendItem = styled.li`
  -webkit-column-break-inside: avoid;
  word-break: break-word;
  display: flex;

  &:hover {
    cursor: default;
  }
`;

const LegendIndicator = styled.span`
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 5px;
  margin-right: 10px;
  background: ${props => STACK_BAR_COLORS[props.index]};
`;

const LegendLabel = styled.span`
  color: ${props => (props.active ? STACK_BAR_COLORS[props.index] : "inherit")};
`;

const S = {
  BarWrapper,
  BarValue,
  BarInfo,
  Bar,
  BarItem,
  LegendList,
  LegendIndicator,
  LegendLabel,
  LegendItem
};

export default S;
