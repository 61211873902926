import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import { blue, grey, purple, standardColors } from "styles/colors";
import { family, fontSize, size } from "styles/fonts";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 25px;
  gap: 25px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  background: ${standardColors.white};
  border-radius: 20px;
  width: 450px;
  box-shadow: 0px 0px 139px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.5px);
  position: absolute;
  bottom: 20px;
  right: 20px;

  h5 {
    font-family: ${family.interDisplayMedium};
    color: ${standardColors.black};
    font-weight: 600;
    line-height: 32px;
    font-size: 21px;
    margin-bottom: 5px;
  }

  p {
    font-family: ${family.interDisplayMedium};
    color: ${grey.dark};
    font-weight: 500;
    line-height: 25px;
    font-size: 18px;
  }
`;

const VideoWrapper = styled.div`
  background-color: ${blue.xapienDark};
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  height: 250px;
  padding: 32px 32px 0px 32px;
  position: relative;
  width: 100%;

  > video {
    width: calc(100% - 64px);
    position: absolute;
  }
`;

const CloseButton = styled(ButtonNew)`
  color: ${standardColors.white};
  padding: 15px 30px;
  border-radius: 125px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${purple.darker};
  align-self: stretch;
  font-family: ${family.interDisplay};
  font-size: ${size["2lg"]};
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.375px;

  &:hover {
    background-color: ${purple.darkerHover};
  }
`;

const S = {
  VideoWrapper,
  ContentWrapper,
  CloseButton
};

export default S;
