import React from "react";

import InsightReport from "components/organisms/InsightReport";

import { EnquiryIdContext } from "util/hooks/useEnquiryId";

import { PersonaIdContext } from "util/hooks/usePersonaId";
import S from "./styles";

const InsightReportPage = () => {
  return (
    <EnquiryIdContext.Provider value="123">
      <PersonaIdContext.Provider value="123">
        <S.Container>
          <InsightReport />
        </S.Container>
      </PersonaIdContext.Provider>
    </EnquiryIdContext.Provider>
  );
};

export default InsightReportPage;
