import React, { useState, Fragment, useLayoutEffect, useRef } from "react";
import clamp from "clamp-js";

import { hasMultiLineBeenClamped } from "util/hasMultiLineBeenClamped";
import { formatDate } from "util/common";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import TruncateLength from "util/TruncateLength";
import ConditionallyRenderedTooltip from "util/ConditionallyRenderedTooltip";
import { isStringAUrl } from "util/isStringAUrl";
import Datalist from "components/atoms/Datalist";
import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import { ButtonKind } from "components/atoms/Button/types";
import { getEntryStatus } from "components/organisms/ScreeningSection/util";
import SourceLink from "components/atoms/SourceLink";
import ConfidenceMenu from "components/molecules/ConfidenceMenu";
import { AssessmentOptions } from "pages/report/AssessmentOptions";

import { blue } from "styles/colors";

import S, { classNameOverrides } from "./styles";

const EntryRow = ({
  listName,
  entityName,
  entityType,
  entityAliases,
  dateOfBirth,
  associatedAddresses = [],
  placeOfBirth,
  startDate,
  endDate,
  entityNationalities = [],
  dynamicFields,
  isKnownToBeOngoing,
  totalRows,
  rowIndex,
  sourceUrl,
  detectedLanguage,
  roles,
  onConfidenceChange,
  originalSetConfidence,
  currentSetConfidence,
  userAssessment,
  userAssessmentId
}) => {
  const [isRowExpanded, setIsRowExpanded] = usePrintableReportState(
    `${listName}-${rowIndex}-row-expanded`,
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );
  const [isListNameClamped, setIsListyNameClamped] = useState(false);
  const [hasDataBeenTranslated] = useState(
    detectedLanguage !== undefined && detectedLanguage !== "English"
  );
  const [isRowTranslated, setIsRowTranslated] = usePrintableReportState(
    `${listName}-${rowIndex}-row-translated`,
    hasDataBeenTranslated
  );

  const listNameRef = useRef();

  useLayoutEffect(() => {
    if (listNameRef?.current) {
      clamp(listNameRef.current, { clamp: 2 });
      setIsListyNameClamped(hasMultiLineBeenClamped(listNameRef.current));
    }
  }, []);

  const onRowHeaderClicked = () => {
    setIsRowExpanded(prevState => !prevState);
  };

  const getTranslatedField = value => {
    const originalValue = value.original;
    const translatedValue = value.translated;

    // Backwards compatibility check for reports lacking translation
    if (!originalValue) {
      return value;
    }

    if (isRowTranslated) {
      return translatedValue || originalValue;
    }

    return originalValue;
  };

  const renderTranslationTool = () => {
    return (
      <S.TranslationButton
        kind={ButtonKind.secondary}
        ariaLabel={detectedLanguage}
        onClick={e => {
          e.stopPropagation();
          // Can't use function API here as usePrintableReportState
          // doesn't support function state updaters.
          setIsRowTranslated(!isRowTranslated);
        }}
        color={isRowTranslated && blue.icon}
      >
        <S.TranslationArrowsIcon color={isRowTranslated && blue.icon} />
        <S.TranslationLanguage>{detectedLanguage}</S.TranslationLanguage>
      </S.TranslationButton>
    );
  };

  const renderStatus = () => {
    const { statusColor, statusLabel } = getEntryStatus({
      endDate,
      isKnownToBeOngoing
    });
    return <S.StatusPill color={statusColor}>{statusLabel}</S.StatusPill>;
  };

  const renderAddresses = (addresses, label) => {
    if (!addresses?.length) {
      return null;
    }

    const result = addresses.map((address, index, collection) => {
      const addressLines = address.address.map(addressLine => (
        <div key={addressLine + index}>
          {addressLine}
          <br />
        </div>
      ));
      return (
        <S.FieldBlock key={index}>
          <S.BlockLabel>
            {label} {collection.length > 1 ? index + 1 : ""}
          </S.BlockLabel>
          {addressLines}
        </S.FieldBlock>
      );
    });
    return <div>{result}</div>;
  };

  const renderRoles = rolesArr => {
    if (!rolesArr?.length) {
      return null;
    }

    const result = rolesArr.map((role, index, collection) => {
      const roleDurationString = getDurationStringFromDates({
        startDate: role.startDate,
        endDate: role.endDate,
        isKnownToBeOngoing: role.isKnownToBeOngoing,
        lastSeen: role.lastSeen
      });

      return (
        <S.FieldBlock key={index}>
          <S.BlockLabel>
            Role {collection.length > 1 ? index + 1 : ""}
          </S.BlockLabel>
          {role.role}
          <S.RoleDuration>
            <br />
            {roleDurationString ? (
              <>({roleDurationString})</>
            ) : (
              <S.NoDate>(No date)</S.NoDate>
            )}
          </S.RoleDuration>
        </S.FieldBlock>
      );
    });
    return <div>{result}</div>;
  };

  const renderDynamicValues = (label, values) => {
    let updatedValue = values;

    const result = values.map((arrVal, index, collection) => {
      // Fallback to arrVal for older reports where translation is not present.
      const originalArrVal = arrVal.original ?? arrVal;

      let updatedArrVal;

      if (isStringAUrl(originalArrVal)) {
        updatedArrVal = (
          <S.SourceUrl>
            <SourceLink href={originalArrVal}>{originalArrVal}</SourceLink>
          </S.SourceUrl>
        );
      } else {
        updatedArrVal = getTranslatedField(arrVal);
      }

      return (
        <S.FieldBlock key={index}>
          <S.BlockLabel>
            {label} {collection.length > 1 ? index + 1 : ""}
          </S.BlockLabel>
          {updatedArrVal}
        </S.FieldBlock>
      );
    });

    updatedValue = <div>{result}</div>;

    return {
      id: label,
      value: updatedValue
    };
  };

  const remainingFields = dynamicFields.map(({ label, values }) => {
    return renderDynamicValues(label, values);
  });

  const dataFields = [
    {
      title: "Entity type",
      value: entityType
    },
    {
      id: "Place of birth",
      title: "",
      value: placeOfBirth && renderAddresses([placeOfBirth], "Place of birth")
    },
    {
      title: "Also known as",
      value: entityAliases
        .map(alias => {
          return getTranslatedField(alias);
        })
        .join(", ")
    },
    {
      title: "Source URL",
      value: sourceUrl?.url && (
        <S.SourceUrl>
          <SourceLink href={sourceUrl.url}>{sourceUrl.url}</SourceLink>
        </S.SourceUrl>
      )
    },
    {
      id: "Addresses",
      title: "",
      value: renderAddresses(associatedAddresses, "Address")
    },
    {
      title: "Nationalities",
      value: entityNationalities.join(", ")
    },
    {
      id: "Roles",
      title: "",
      value: renderRoles(roles)
    },
    ...remainingFields
  ];

  const column1Data = [];
  const column2Data = [];

  dataFields.forEach((field, index) => {
    // Each iteration, put a field into one column
    // and on the next iteration, the other column.
    // Back and forth so we get an even spread.
    if (index % 2 === 0) {
      column1Data.push(field);
    } else {
      column2Data.push(field);
    }
  });

  return (
    <tr aria-label="Screening listing row">
      <td colSpan={3}>
        <S.DetailGrid
          isExpanded={isRowExpanded}
          confidenceHasChanged={currentSetConfidence !== originalSetConfidence}
        >
          {rowIndex < totalRows - 1 && !isRowExpanded ? <S.Divider /> : null}
          <S.GridCellListName onClick={onRowHeaderClicked}>
            <S.CollapseButton
              aria-label={`${isRowExpanded ? "Collapse" : "Expand"} row`}
            >
              <S.CollapseArrow
                className={!isRowExpanded ? "isCollapsed" : ""}
              />
            </S.CollapseButton>
            <S.ListName ref={listNameRef}>
              <ConditionallyRenderedTooltip
                text={getTranslatedField(listName)}
                shouldRender={isListNameClamped}
                position="top"
              >
                {getTranslatedField(listName)}
              </ConditionallyRenderedTooltip>
            </S.ListName>
          </S.GridCellListName>
          <S.GridCellDuration onClick={onRowHeaderClicked}>
            <TruncateLength>{getTranslatedField(entityName)}</TruncateLength>
            <S.PrimaryEntityInfo>
              <TruncateLength>
                {formatDate(dateOfBirth)}
                {entityNationalities.length > 0
                  ? ` – ${entityNationalities.join(", ")}`
                  : null}
              </TruncateLength>
            </S.PrimaryEntityInfo>
          </S.GridCellDuration>
          <S.GridCellStatus onClick={onRowHeaderClicked}>
            <S.Duration>
              {getDurationStringFromDates({
                startDate,
                endDate,
                isKnownToBeOngoing
              })}
            </S.Duration>
            <S.StatusAndTranslationContainer>
              {renderStatus(endDate)}
              {hasDataBeenTranslated && renderTranslationTool()}
            </S.StatusAndTranslationContainer>
          </S.GridCellStatus>
          <S.ConfidenceMenuContainer>
            <ConfidenceMenu
              originalSetConfidence={originalSetConfidence}
              currentSetConfidence={currentSetConfidence}
              onConfidenceChange={onConfidenceChange}
              itemIds={userAssessmentId}
              confidenceHasBeenSetOnRegen={
                (userAssessment ?? AssessmentOptions.NoUserAssessment) !==
                AssessmentOptions.NoUserAssessment
              }
            />
          </S.ConfidenceMenuContainer>
          {isRowExpanded && (
            <>
              <S.GridCellRowDetails1>
                <Datalist
                  shouldDataValuesWrap
                  dataFieldClassName={classNameOverrides.dataField}
                  fieldList={column1Data}
                />
              </S.GridCellRowDetails1>
              <S.GridCellRowDetails2>
                <Datalist
                  shouldDataValuesWrap
                  dataFieldClassName={classNameOverrides.dataField}
                  fieldList={column2Data}
                />
              </S.GridCellRowDetails2>
              <S.GridCellRowDetails3 />
            </>
          )}
          {rowIndex < totalRows - 1 && isRowExpanded && (
            <S.BottomDivider isExpanded={isRowExpanded} />
          )}
        </S.DetailGrid>
      </td>
    </tr>
  );
};

export default EntryRow;
