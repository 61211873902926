import { search } from "country-js";

function preparePoints(locations, filteredLocations, activeFilter) {
  let points = [];
  locations.forEach(location => {
    const {
      addressContext,
      latitude,
      longitude,
      locationAddress,
      locationId,
      countryRiskFlags,
      locationStreetViewImage,
      isResolvedToSpecificLatLon
    } = location;
    const locationCountry = locationAddress && locationAddress.locationCountry;
    let code = null;
    try {
      code = locationCountry ? search(locationCountry)[0].code : null;
    } catch {
      if (locationCountry) {
        if (
          ["england", "scotland", "wales", "northern ireland"].includes(
            locationCountry.toLowerCase()
          )
        ) {
          code = "GB";
        }
      }
    }
    if (code === "UK") {
      code = "GB";
    }

    if (!addressContext || addressContext.length === 0) {
      points = [
        ...points,
        {
          type: "Feature",
          properties: {
            countryCode: code,
            country: locationCountry,
            address: locationAddress,
            desc: "No context available for this location",
            locationId: locationId,
            id: locationStreetViewImage ? locationStreetViewImage.id : "",
            hasRisk: countryRiskFlags && countryRiskFlags.length > 0 ? 1 : 0,
            isSelected:
              filteredLocations &&
              filteredLocations.some(l => l.locationId === locationId)
                ? 1
                : 0,
            activeFilter: activeFilter ? 1 : 0,
            isResolvedToSpecificLatLon: isResolvedToSpecificLatLon ? 1 : 0
          },
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude]
          }
        }
      ];
    } else {
      addressContext.forEach(context => {
        points = [
          ...points,
          {
            type: "Feature",
            properties: {
              countryCode: code,
              country: locationCountry,
              address: locationAddress,
              desc: context.nameOfOrg,
              locationId: locationId,
              id: locationStreetViewImage.id,
              hasRisk: countryRiskFlags && countryRiskFlags.length > 0 ? 1 : 0,
              isSelected:
                filteredLocations &&
                filteredLocations.some(l => l.locationId === locationId)
                  ? 1
                  : 0,
              activeFilter: activeFilter ? 1 : 0,
              isResolvedToSpecificLatLon: isResolvedToSpecificLatLon ? 1 : 0
            },
            geometry: {
              type: "Point",
              coordinates: [longitude, latitude]
            }
          }
        ];
      });
    }
  });
  return points;
}

export default preparePoints;
