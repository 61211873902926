import { styled } from "@linaria/react";
import { DiImage } from "components/molecules/DiImage";

import { grey } from "styles/colors";
import { family } from "styles/fonts";

const SingleImageContainer = styled.div`
  display: flex;
`;

const FullImage = styled(DiImage)`
  object-fit: cover;
  height: 100%;
  width: 100%;
  padding-bottom: 4px;
`;

const ImageColumn = styled.div`
  flex-basis: ${({ width, height }) => {
    if (width / height >= 1.3) {
      return "294px";
    }
    if (height / width > 1.3) {
      return "152px";
    }
    return "220px";
  }};
  width: ${({ width, height }) => {
    if (width / height >= 1.3) {
      return "294px";
    }
    if (height / width > 1.3) {
      return "152px";
    }
    return "220px";
  }};
`;

const InfoColumn = styled.div`
  width: 400px;
  margin-left: 16px;
`;

const ImageInformation = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ImageDocumentTitle = styled(ImageInformation)`
  font-family: ${family.interRegular};
  color: ${grey.dark};
  margin-bottom: 8px;
`;

const ImageAltText = styled(ImageInformation)`
  font-family: ${family.interRegular};
  color: ${grey.mid};
  margin-bottom: 8px;
`;

const ImageDate = styled(ImageInformation)`
  font-family: ${family.interLight};
  color: ${grey.mid};
  margin-bottom: 8px;
`;

const ImageOriginUrl = styled(ImageInformation)`
  font-family: ${family.interLight};
  color: ${grey.dark};
  margin-bottom: 8px;
`;

const ImageThirdParty = styled(ImageInformation)`
  font-family: ${family.interLight};
  color: ${grey.light};
`;

const ImageSources = styled(ImageInformation)`
  font-family: ${family.interLight};
  color: ${grey.dark};
  margin-top: 8px;
`;

const S = {
  SingleImageContainer,
  FullImage,
  ImageColumn,
  InfoColumn,
  ImageDocumentTitle,
  ImageAltText,
  ImageDate,
  ImageOriginUrl,
  ImageThirdParty,
  ImageSources
};

export default S;
