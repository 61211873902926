export interface SearchResult {
  id: string;
  queryType?: string;
  title: string;
  description?: string;
  imageSrc?: string;
}

export enum SearchItemType {
  Group = "Group",
  User = "User",
  Organisation = "Organisation"
}
