import { useContext } from "react";

import { InsightsAmaContext } from "./context";

export { InsightsAmaContextProvider } from "./provider";

const useInsightsAma = () => {
  const context = useContext(InsightsAmaContext);

  if (!context) {
    throw new Error(
      "useInsightsAma must be used within a InsightsAmaContextProvider"
    );
  }

  return context;
};

export default useInsightsAma;
