import React from "react";
import { Section } from "./Section";
import SectionErrorContents from "./Error";

export default class SectionErrorWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error);
    console.log(errorInfo);
  }

  render() {
    const { forwardedRef, title, isExpanded, expandable, setExpanded } =
      this.props;
    if (this.state.hasError) {
      return (
        <Section
          ref={forwardedRef}
          {...{ title, expandable, isExpanded, setExpanded }}
        >
          <SectionErrorContents />
        </Section>
      );
    }
    return <div>{this.props.children}</div>;
  }
}
