import { styled } from "@linaria/react";
import Heading from "components/atoms/Heading";
import { CardVariant } from "components/molecules/Card/types";

import { grey, blue, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Subtitle = styled.p<{ variant: CardVariant }>`
  margin: 0;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
  font-size: ${fontSize.xs};
  max-width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AvatarAndTitle = styled.div`
  display: flex;
  gap: 0.375rem;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${blue.xapienDark};
`;

const TitlesContainer = styled.div`
  max-width: 17rem;
  width: 17rem;
`;

const CustomHeading = styled(Heading)<{ variant: CardVariant }>`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: none;
  overflow: hidden;
  hyphens: auto;

  color: ${({ variant }) =>
    variant === CardVariant.dark ? standardColors.white : blue.xapienDark};
`;

const S = {
  AvatarAndTitle,
  Subtitle,
  Header,
  TitlesContainer,
  CustomHeading
};

export default S;
