import React, { useRef, useEffect, useState } from "react";
import S from "./styles";

const MAX_PADDING_PERCENT = 35;
const CroppedImage = ({ imageData, imageWidth, imageHeight }) => {
  const canvasRef = useRef();
  const [hasLoaded, setHasLoaded] = useState(false);
  const { image, boundingBox } = imageData;
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const imageObj = new Image();
    const width = boundingBox.bottomRight.x - boundingBox.topLeft.x;
    const height = boundingBox.bottomRight.y - boundingBox.topLeft.y;

    const getSizeRecurring = (widthForSizing, padding) => {
      const leftPadding = (widthForSizing * padding) / 100;
      const widthWithPadding = widthForSizing + 2 * leftPadding;
      const heightWithPadding = (widthWithPadding * imageHeight) / imageWidth;
      return {
        leftPadding,
        heightWithPadding,
        widthWithPadding
      };
    };

    const getSize = widthToChange => {
      const paddingInterval = 5;
      let currentPadding = MAX_PADDING_PERCENT;

      let size = getSizeRecurring(widthToChange, currentPadding);
      let { heightWithPadding } = size;

      while (heightWithPadding > image.height && currentPadding >= 0) {
        currentPadding -= paddingInterval;
        size = getSizeRecurring(widthToChange, currentPadding);
        heightWithPadding = size.heightWithPadding;
      }

      return {
        heightWithPadding,
        widthWithPadding: size.widthWithPadding,
        leftPadding: size.leftPadding
      };
    };

    const dimension = getSize(width);

    const { leftPadding } = dimension;
    const topPadding = (height * MAX_PADDING_PERCENT) / 100;

    const sourceX =
      boundingBox.topLeft.x - leftPadding > 0
        ? boundingBox.topLeft.x - leftPadding
        : 0;
    const sourceY =
      boundingBox.topLeft.y - topPadding > 0
        ? boundingBox.topLeft.y - topPadding
        : 0;
    const sourceWidth = dimension.widthWithPadding;
    const sourceHeight = dimension.heightWithPadding;
    const destWidth = imageWidth;
    const destHeight = imageHeight;
    const destX = 0;
    const destY = 0;

    imageObj.onload = () => {
      context.drawImage(
        imageObj,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        destX,
        destY,
        destWidth,
        destHeight
      );
      setHasLoaded(true);
    };
    imageObj.src = image.src;
  }, [
    boundingBox.bottomRight.x,
    boundingBox.bottomRight.y,
    boundingBox.topLeft.x,
    boundingBox.topLeft.y,
    image.height,
    image.src,
    imageHeight,
    imageWidth
  ]);

  return (
    <S.Container width={imageWidth} height={imageHeight}>
      <S.Spinner className={hasLoaded ? "" : "isVisible"} />
      <S.Canvas
        ref={canvasRef}
        width={imageWidth}
        height={imageHeight}
        className={hasLoaded ? "isVisible" : ""}
      />
    </S.Container>
  );
};
export default CroppedImage;
