import React, { FC, Fragment } from "react";
import dayjs from "dayjs";

import type { Article } from "api/articles";

import SourceLink from "components/atoms/SourceLink";

import S, { classNameOverrides } from "./styles";

const ArticleContent: FC<Article> = ({
  body,
  headline,
  publishedDate,
  publisher,
  byline,
  timeToRead,
  copyright,
  docId
}) => {
  const renderBodyContent = () => {
    if (!body) return null;

    return (
      <>
        {body
          .map((node, index) => {
            switch (node.type) {
              case "paragraph": {
                return (
                  <S.Paragraph key={`${node.type}-${index}`}>
                    {Array.isArray(node.contents)
                      ? node.contents
                          .map((content, subIndex) => {
                            switch (content.type) {
                              case "text": {
                                return (
                                  <Fragment key={`${node.type}-${subIndex}`}>
                                    {content.text}
                                  </Fragment>
                                );
                              }
                              case "link": {
                                return content.uri ? (
                                  <SourceLink
                                    key={`${node.type}-${subIndex}`}
                                    truncateLink={false}
                                    href={content.uri}
                                    popoverClassName={
                                      classNameOverrides.sourceLinkPopover
                                    }
                                  >
                                    {content.text}
                                  </SourceLink>
                                ) : null;
                              }
                              default: {
                                return null;
                              }
                            }
                          })
                          .filter(Boolean)
                      : node.contents}
                  </S.Paragraph>
                );
              }

              case "subheading": {
                return (
                  <S.Heading level={4} key={`${node.type}-${index}`}>
                    {Array.isArray(node.contents)
                      ? node.contents[0].text
                      : node.contents}
                  </S.Heading>
                );
              }
              default: {
                return null;
              }
            }
          })
          .filter(Boolean)}
      </>
    );
  };

  const formatDate = (date: string) => {
    const formattedDate = dayjs(date).format("D MMMM YYYY, H:mm a");
    // checks if publishedDate is a simple date without added time AKA 2024-01-05
    if (formattedDate.includes(" 0:00")) {
      return dayjs(date).format("D MMMM YYYY");
    }
    return formattedDate;
  };

  return (
    <S.Content>
      <S.Heading level={3}>{headline}</S.Heading>

      <S.InfoContainer>
        <S.ProviderAndDate>
          {publishedDate
            ? `${publisher} | ${formatDate(publishedDate)}`
            : publisher}
        </S.ProviderAndDate>
        {byline && <S.Author>{byline}</S.Author>}
        {timeToRead && (
          <S.TimeToRead>{timeToRead.minutes} minute read.</S.TimeToRead>
        )}
      </S.InfoContainer>

      <S.BodyContainer>{renderBodyContent()}</S.BodyContainer>
      <S.CopyrightContainer>
        <div>{copyright}</div>
        <div>Document Identifier: {docId}</div>
      </S.CopyrightContainer>
    </S.Content>
  );
};

export default ArticleContent;
