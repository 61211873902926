import React, { Fragment, useState, useRef, useMemo } from "react";

import NewReportButton from "pages/report/NewReportButton";
import Datalist from "components/atoms/Datalist";
import { getDurationSinceStartDate } from "util/getDurationSinceStartDate";
import FlagCard from "themes/projectdx/ThemedScreening/FlagCard";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import { usePrintableReportState } from "util/hooks/usePrintableState";
import { useReportSubjectEntityType } from "util/hooks/useReportSubjectEntityType";
import TruncateLength from "util/TruncateLength";
import {
  getEntityTypeImage,
  getExplainerText,
  RELATIVE_OR_CLOSE_ASSOCIATE
} from "themes/projectdx/ThemedScreening/util";
import EntityMasonry from "themes/projectdx/ThemedScreening/EntityMasonry";
import { grey } from "styles/colors";
import ReactTooltip from "components/atoms/ReactTooltip";
import { ReactComponent as NoteIcon } from "img/noteIcon.svg";
import { ENTITY_TYPE } from "pages/define/utils";
import { processFlagHierarchy } from "themes/projectdx/ThemedScreening/processFlagHierarchy";
import { formatDate, mutateToLowerCamelCase } from "util/common";

import S, { classNameOverrides } from "./styles";
import { findParentOfLowerThreshold } from "./utils";
import {
  SPECIAL_INTEREST_ENTITY_FLAG,
  SPECIAL_INTEREST_PERSON_FLAG,
  STATE_OWNED_COMPANY_FLAG,
  dowJonesSpecialInterestCategoriesToFilterBy
} from "./constants";

const ownershipMapping = {
  "Past Owner": "Past ownership",
  "Current Owner": "Current ownership"
};

const DetailedModalView = ({
  isModalOpen,
  setIsModalOpen,
  entityData: initialEntityData,
  entityType,
  filterBucketType,
  renderFlagHierarchyPills,
  reportSubjectName,
  reportSubjectType,
  disableNewReportButton,
  associatedEntityProfiles
}) => {
  const [isProfileNotesExpanded, setIsProfileNotesExpanded] =
    usePrintableReportState("profile-notes-fully-expanded", true);

  const [isSourcesListExpanded, setIsSourcesListExpanded] =
    usePrintableReportState("sources-list-fully-expanded", false);

  const [isAlternativeNamesListExpanded, setIsAlternativeNamesListExpanded] =
    usePrintableReportState("alternative-names-fully-expanded", false);

  const [isAddressesListExpanded, setIsAddressesListExpanded] =
    usePrintableReportState("addresses-fully-expanded", false);

  const [isSpecialInterestExpanded, setIsSpecialInterestExpanded] =
    usePrintableReportState("special-interest-fully-expanded", false);

  const [isIdListExpanded, setIsIdListExpanded] = usePrintableReportState(
    "ids-fully-expanded",
    false
  );

  const [isImageLinksExpanded, setIsImageLinksExpanded] =
    usePrintableReportState("image-links-fully-expanded", false);

  const [entityData, setEntityData] = useState(initialEntityData);

  const [displayBackButton, setDisplayBackButton] = useState(false);

  const reportType = useReportSubjectEntityType();

  const modalBodyRef = useRef();

  let associatedOrgsToUse = [];
  let associatedPeopleToUse = [];

  if (
    entityData.associatedOrgs?.length ||
    entityData.associatedPeople?.length
  ) {
    associatedOrgsToUse = entityData.associatedOrgs;
    associatedPeopleToUse = entityData.associatedPeople;
  } else {
    entityData.associatedEntities?.forEach(entity => {
      if (
        entity.entityType?.toLowerCase() ===
        ENTITY_TYPE.Organisation.toLowerCase()
      ) {
        associatedOrgsToUse.push(entity);
      } else {
        associatedPeopleToUse.push(entity);
      }
    });
  }

  const groupLocationsByRelationship = locations => {
    if (!locations?.length) {
      return null;
    }

    const groupedLocations = locations?.reduce((acc, curr) => {
      if (!curr) {
        return acc;
      }

      if (!acc[curr.relationship]) {
        acc[curr.relationship] = {
          relationship: curr.relationship,
          countries: []
        };
      }

      if (curr.countryName || curr.countryCode) {
        acc[curr.relationship].countries.push({
          countryName: curr.countryName,
          countryCode: curr.countryCode
        });
      }

      return acc;
    }, {});

    // Convert to iterable
    return Object.values(groupedLocations);
  };

  const renderDatalist = (title, value, dataLength, classOverride) => {
    if (!dataLength) {
      return null;
    }
    return (
      <Datalist
        fieldList={[
          {
            title,
            value
          }
        ]}
        shouldDataValuesWrap
        dataFieldClassName={classOverride ?? classNameOverrides.dataField}
      />
    );
  };

  const renderAkas = akas => {
    if (!akas?.length) {
      return null;
    }

    let entityAkas;

    // Akas can come in two forms, one being a simple list of strings. In this case, we handle the rendered output here
    if (typeof akas[0] === "string") {
      entityAkas = akas.map(aka => (
        <S.ListItem key={aka}>
          {akas?.length > 1 ? <S.BulletPoint /> : null}
          {aka}
        </S.ListItem>
      ));
    } else {
      entityAkas = akas.map(aka => {
        return (
          <>
            <S.ListItem key={aka.primaryRepresentation}>
              {akas?.length > 1 ? <S.BulletPoint /> : null}
              {aka.primaryRepresentation}
            </S.ListItem>
            {aka.alternativeNameLanguages?.map(akaAltLan => {
              return (
                <S.AkaPair key={akaAltLan.alias}>
                  <S.IdentifierKey>{akaAltLan.language}: </S.IdentifierKey>
                  <span>{akaAltLan.alias}</span>
                </S.AkaPair>
              );
            })}
          </>
        );
      });
    }

    return <S.List>{entityAkas}</S.List>;
  };

  const renderPersonFirstDetails = () => {
    return (
      <S.GridDetailsSection>
        {entityData?.gender?.length > 0 && (
          <div>
            <S.IdentifierKey>Gender</S.IdentifierKey>
            <S.Value>{entityData.gender}</S.Value>
          </div>
        )}
        {entityData?.birthDate?.length > 0 && (
          <div>
            <S.IdentifierKey>Birth</S.IdentifierKey>
            <div>
              <S.Date>{formatDate(entityData.birthDate[0])} </S.Date>
              <S.DateDuration>
                {getDurationSinceStartDate({
                  startDate: entityData?.birthDate[0]
                })}
              </S.DateDuration>
            </div>
          </div>
        )}
        {typeof entityData?.isDeceased === "boolean" && (
          <div>
            <S.IdentifierKey>Deceased</S.IdentifierKey>
            <S.Value>{entityData?.isDeceased ? "Yes" : "No"}</S.Value>
          </div>
        )}
      </S.GridDetailsSection>
    );
  };

  const renderOrgFirstDetails = () => {
    // Collate every location from each of the flag objects to output
    const locations = entityData?.flags?.flatMap(flag => {
      return flag?.locations;
    });

    if (!locations?.length && !entityData?.entityLocation?.countryName) {
      return null;
    }

    const locationsToRender = groupLocationsByRelationship(locations) ?? [];

    if (entityData?.entityLocation?.countryName) {
      locationsToRender.unshift({
        relationship: "Registration",
        countries: [entityData.entityLocation]
      });
    }

    return (
      <S.GridDetailsSection>
        {locationsToRender?.map(location => {
          const mappedLocationTitle =
            ownershipMapping[location?.relationship] ?? location?.relationship;
          const countries = location?.countries;
          return (
            <Fragment key={location?.relationship}>
              {countries[0]?.countryName?.length && (
                <ReactTooltip
                  tooltip={
                    <S.CountryFlagTooltip>
                      <S.CountryFlagTooltipHeader>
                        {mappedLocationTitle}{" "}
                        {countries?.length > 1 ? "locations" : "location"}
                      </S.CountryFlagTooltipHeader>
                      <S.CountryFlagTooltipContent>
                        {countries?.length > 1 ? (
                          <S.AdditionalInfo>
                            {countries
                              ?.map(country => country.countryName)
                              .join(", ")}
                          </S.AdditionalInfo>
                        ) : null}
                        <div>{getExplainerText(mappedLocationTitle)}</div>
                      </S.CountryFlagTooltipContent>
                    </S.CountryFlagTooltip>
                  }
                  trigger="click"
                >
                  {renderDatalist(
                    <S.Field>{mappedLocationTitle}</S.Field>,
                    <S.Field>
                      {countries[0]?.countryCode ? (
                        <S.CountryFlag
                          code={countries[0]?.countryCode}
                          showCodeString={false}
                        />
                      ) : null}
                      <S.CountryName>{countries[0]?.countryName}</S.CountryName>
                      {countries?.length > 1 ? (
                        <S.AdditionalCount>
                          {" "}
                          +{(countries?.length ?? 0) - 1}
                        </S.AdditionalCount>
                      ) : (
                        ""
                      )}
                    </S.Field>,
                    countries[0]?.countryName?.length,
                    classNameOverrides.countryFlagDataField
                  )}
                </ReactTooltip>
              )}
            </Fragment>
          );
        })}
      </S.GridDetailsSection>
    );
  };

  const renderFirstDetails = () => {
    if (entityType === ENTITY_TYPE.Person) {
      return renderPersonFirstDetails();
    }
    return renderOrgFirstDetails();
  };

  const renderRoleDetails = () => {
    if (
      entityType === ENTITY_TYPE.Person &&
      entityData.pepRole &&
      entityData.pepRole.roleTitle
    ) {
      return (
        <S.RoleDetailsSection>
          <div>
            <S.IdentifierKey>PEP</S.IdentifierKey>
            <S.Value>Yes</S.Value>
          </div>
          <div>
            <S.IdentifierKey>Job title</S.IdentifierKey>
            <S.Value>{entityData.pepRole.roleTitle}</S.Value>
          </div>
          <div>
            <S.IdentifierKey>Dates</S.IdentifierKey>
            <S.Value>
              {getDurationStringFromDates({
                startDate: entityData.pepRole.startDate,
                endDate: entityData.pepRole.endDate,
                isKnownToBeOngoing: entityData.pepRole.isOngoing
              })}
            </S.Value>
          </div>
        </S.RoleDetailsSection>
      );
    }

    return null;
  };

  const renderPersonSecondDetails = () => {
    const mapping = {
      residence: "Residence of",
      reportedAllegation: "Reported allegation",
      birthplace: "Birth"
    };

    const locations = [
      "birthplace",
      "citizenship",
      "residence",
      "reportedAllegation",
      "jurisdiction"
    ].map(key => {
      if (!entityData[key]?.length) {
        return null;
      }
      return (
        <ReactTooltip
          tooltip={
            <S.CountryFlagTooltip>
              <S.CountryFlagTooltipHeader>
                {mapping[key] ? mapping[key] : key}
              </S.CountryFlagTooltipHeader>
              <S.CountryNames>
                {entityData[key]?.map(data => data.countryName)?.join(", ")}
              </S.CountryNames>
              <S.CountryFlagTooltipContent>
                {getExplainerText(key)}
              </S.CountryFlagTooltipContent>
            </S.CountryFlagTooltip>
          }
          trigger="click"
        >
          {renderDatalist(
            <S.Key>{mapping[key] ? mapping[key] : key}</S.Key>,
            <S.Field>
              {entityData[key]?.[0]?.countryCode ? (
                <S.CountryFlag
                  code={entityData[key][0].countryCode}
                  showCodeString={false}
                />
              ) : null}
              <S.CountryName>{entityData[key]?.[0]?.countryName}</S.CountryName>{" "}
              {(entityData[key]?.length ?? 0) - 1 > 0 && (
                <S.AdditionalCount>
                  {" "}
                  +{(entityData[key]?.length ?? 0) - 1}
                </S.AdditionalCount>
              )}
            </S.Field>,
            entityData[key]?.[0]?.countryName?.length,
            classNameOverrides.countryFlagDataField
          )}
        </ReactTooltip>
      );
    });

    if (!locations?.filter(Boolean)?.length) {
      return null;
    }

    return <S.LocationsSection>{locations}</S.LocationsSection>;
  };

  const renderOrgSecondDetails = () => {
    if (
      !entityData?.industries?.length &&
      !entityData?.websites?.length &&
      !entityData?.registrationDate?.year
    ) {
      return null;
    }

    return (
      <S.GridDetailsSection>
        {renderDatalist(
          "Industry",
          <div>
            {entityData?.industries?.map(industry => {
              return <div key={industry}>{industry}</div>;
            })}
          </div>,
          entityData?.industries?.length
        )}
        {renderDatalist(
          "Websites",
          <S.WebsitesContainer>
            {entityData?.websites?.map(website => {
              return (
                <S.SourceLink key={website} href={website}>
                  {website}
                </S.SourceLink>
              );
            })}
          </S.WebsitesContainer>,
          entityData?.websites?.length
        )}
        {renderDatalist(
          "Registration date",
          <div>
            <S.Date>{formatDate(entityData?.registrationDate)} </S.Date>
            <S.DateDuration>
              {getDurationSinceStartDate({
                startDate: entityData?.registrationDate
              })}
            </S.DateDuration>
          </div>,
          entityData?.registrationDate?.year
        )}
      </S.GridDetailsSection>
    );
  };

  const renderSecondDetails = () => {
    if (entityType === ENTITY_TYPE.Person) {
      return renderPersonSecondDetails();
    }
    return renderOrgSecondDetails();
  };

  const renderExpandableHeader = (expanded, toggleExpand, title) => {
    return (
      <S.ExpandableHeader
        onClick={e => {
          e.stopPropagation();
          toggleExpand(prevState => !prevState);
        }}
      >
        {title}
        <S.CollapseButton>
          <S.CollapseArrow isCollapsed={!expanded} />
        </S.CollapseButton>
      </S.ExpandableHeader>
    );
  };

  const renderAlternativeNames = () => {
    const alternativeNames = entityData?.alternativeNames;
    const previousNames = entityData?.previousNames;

    if (
      !alternativeNames?.allNames?.length &&
      !previousNames?.allNames?.length
    ) {
      return null;
    }

    return (
      <>
        {renderExpandableHeader(
          isAlternativeNamesListExpanded,
          setIsAlternativeNamesListExpanded,
          "Alternative and previous names"
        )}
        {isAlternativeNamesListExpanded && (
          <S.AlternativeNamesSection>
            {renderDatalist(
              "AKA",
              renderAkas(entityData?.alternativeNames?.allNames),
              entityData?.alternativeNames?.allNames?.length
            )}
            {renderDatalist(
              "Formerly known as",
              renderAkas(entityData?.previousNames?.allNames),
              entityData?.previousNames?.allNames?.length
            )}
          </S.AlternativeNamesSection>
        )}
      </>
    );
  };

  const renderAddresses = () => {
    const addresses = entityData?.addresses;

    if (!addresses?.length) {
      return null;
    }

    return (
      <>
        {renderExpandableHeader(
          isAddressesListExpanded,
          setIsAddressesListExpanded,
          "Addresses"
        )}
        {isAddressesListExpanded && (
          <S.AddressesSection>
            {entityData.addresses.map((address, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <S.AddressBlock key={index}>
                {address?.address?.map((addressLine, addressLineIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={addressLineIndex}>{addressLine}</div>
                ))}
              </S.AddressBlock>
            ))}
          </S.AddressesSection>
        )}
      </>
    );
  };

  const renderIdNumbers = (numbers = []) => {
    return numbers.map(identifier => {
      return (
        <S.IdentifierPair key={`${identifier.label} ${identifier.value}`}>
          <S.BulletPoint />
          <S.IdentifierKey>{identifier.label}: </S.IdentifierKey>
          <span>{identifier.value}</span>
          {identifier?.notes ||
          identifier?.countryOfIssue ||
          identifier?.dateOfIssue ? (
            <S.Tooltip
              tooltip={
                <S.Note>
                  {identifier?.dateOfIssue && (
                    <div>
                      <S.IdentifierKey>Date of issue: </S.IdentifierKey>
                      <span>{formatDate(identifier?.dateOfIssue)}</span>
                    </div>
                  )}
                  {identifier?.countryOfIssue && (
                    <div>
                      <S.IdentifierKey>Country of Issue: </S.IdentifierKey>
                      <span>{identifier?.countryOfIssue?.countryName}</span>
                    </div>
                  )}
                  <div>{identifier.notes}</div>
                </S.Note>
              }
            >
              <NoteIcon />
            </S.Tooltip>
          ) : null}
        </S.IdentifierPair>
      );
    });
  };

  const renderProfileNotes = () => {
    return (
      <>
        {renderExpandableHeader(
          isProfileNotesExpanded,
          setIsProfileNotesExpanded,
          "Profile notes"
        )}

        {isProfileNotesExpanded && (
          <S.Container>
            {entityData?.profileNotes ?? (
              <S.NoneIdentified>
                There are no RnC analyst notes for this{" "}
                {reportSubjectType?.toLowerCase()}.
              </S.NoneIdentified>
            )}
          </S.Container>
        )}
      </>
    );
  };

  const renderSources = () => {
    if (!entityData.sources?.length) {
      return null;
    }

    return (
      <>
        {renderExpandableHeader(
          isSourcesListExpanded,
          setIsSourcesListExpanded,
          "Sources"
        )}

        {isSourcesListExpanded && (
          <S.Container>
            {entityData.sources?.map(source => {
              return (
                <TruncateLength key={source}>
                  <S.SourceLink href={source}>{source}</S.SourceLink>
                </TruncateLength>
              );
            })}
          </S.Container>
        )}
      </>
    );
  };

  const renderStateOwnedCompanyCard = data => {
    const controlData = [
      {
        id: "control-data",
        title: "",
        value: (
          <div>
            {data.control?.map(controlObj => {
              return (
                <S.IdentifierPair key={controlObj.label}>
                  <S.IdentifierKey>{controlObj?.label}: </S.IdentifierKey>
                  <span>{controlObj?.value}</span>
                  {controlObj?.notes ? (
                    <S.Tooltip
                      tooltip={
                        <S.Note>
                          <div>{controlObj.notes}</div>
                        </S.Note>
                      }
                    >
                      <NoteIcon />
                    </S.Tooltip>
                  ) : null}
                </S.IdentifierPair>
              );
            })}
          </div>
        ),
        isNotEmpty: data.control?.length
      },
      {
        id: "ownership-data",
        title: "",
        value: (
          <div>
            {data.ownershipPercentage?.map(ownershipObj => {
              return (
                <S.IdentifierPair key={ownershipObj.label}>
                  <S.IdentifierKey>{ownershipObj?.label}: </S.IdentifierKey>
                  <span>{ownershipObj?.value}</span>
                  {ownershipObj?.notes ? (
                    <S.Tooltip
                      tooltip={
                        <S.Note>
                          <div>{ownershipObj.notes}</div>
                        </S.Note>
                      }
                    >
                      <NoteIcon />
                    </S.Tooltip>
                  ) : null}
                </S.IdentifierPair>
              );
            })}
          </div>
        ),
        isNotEmpty: data.ownershipPercentage?.length
      }
    ].filter(item => item.isNotEmpty);

    const locations = groupLocationsByRelationship(data.locations);

    return (
      <FlagCard
        customHeader={
          <S.FlagBanner isActive={data.isActive}>
            <S.FlagBannerTitle>
              State Owned Company <S.StatusLabel>{data.status}</S.StatusLabel>
            </S.FlagBannerTitle>
            {locations?.length > 0 && (
              <S.FlagLocations>
                {locations.map(location => {
                  const countries = location?.countries;
                  return (
                    <ReactTooltip
                      key={location.relationship}
                      tooltip={
                        <S.CountryFlagTooltip>
                          <S.CountryFlagTooltipHeader>
                            {ownershipMapping[location.relationship]}{" "}
                            {countries?.length > 1 ? "locations" : "location"}
                          </S.CountryFlagTooltipHeader>
                          <S.CountryFlagTooltipContent>
                            {countries?.length > 1 ? (
                              <S.AdditionalInfo>
                                {countries
                                  ?.map(country => country.countryName)
                                  .join(", ")}
                              </S.AdditionalInfo>
                            ) : null}
                            <div>
                              {getExplainerText(
                                ownershipMapping[location.relationship] ??
                                  location.relationship
                              )}
                            </div>
                          </S.CountryFlagTooltipContent>
                        </S.CountryFlagTooltip>
                      }
                      trigger="click"
                    >
                      <S.FlagLocation>
                        <S.IdentifierKey>
                          {ownershipMapping[location.relationship] ??
                            location.relationship}
                          :
                        </S.IdentifierKey>
                        <S.Country>
                          {countries[0]?.countryCode ? (
                            <S.CountryFlag
                              code={countries[0].countryCode}
                              showCodeString={false}
                            />
                          ) : null}
                          <S.CountryName>
                            {countries[0]?.countryName}
                          </S.CountryName>
                        </S.Country>
                        {countries?.length > 1 ? (
                          <S.AdditionalCount>
                            {" "}
                            +{(countries?.length ?? 0) - 1}
                          </S.AdditionalCount>
                        ) : (
                          ""
                        )}
                      </S.FlagLocation>
                    </ReactTooltip>
                  );
                })}
              </S.FlagLocations>
            )}
          </S.FlagBanner>
        }
        customContent={
          <>
            {data.control?.length > 0 && (
              <S.Section>
                <Datalist
                  fieldList={controlData}
                  dataFieldClassName={classNameOverrides.dataField}
                />
              </S.Section>
            )}
          </>
        }
        isFlagInactive={!data.isActive}
      />
    );
  };

  const listReferences = entityData?.listReferences?.some(
    reference => reference.entries?.length > 0
  );
  const stateOwnershipsFlag = entityData?.flags?.find(
    flag => flag.type === STATE_OWNED_COMPANY_FLAG
  );
  const specialInterestFlag = entityData?.flags
    ?.flatMap(flag => processFlagHierarchy(flag))
    ?.find(
      processedFlag =>
        processedFlag.type?.trim() === SPECIAL_INTEREST_PERSON_FLAG ||
        processedFlag.type?.trim() === SPECIAL_INTEREST_ENTITY_FLAG
    );

  const parentsOfLowerThresholds = useMemo(() => {
    entityData.flags?.map(flag => {
      return findParentOfLowerThreshold(flag?.categoryHierarchy)?.description;
    });
  }, [entityData?.flags]);

  const renderLowerThreshold = flag => {
    const hasCommonElement = parentsOfLowerThresholds?.includes(flag);
    if (hasCommonElement) {
      return (
        <S.SpecialInterestFlagList>
          <S.LowerThresholdItem>Lower Threshold</S.LowerThresholdItem>
        </S.SpecialInterestFlagList>
      );
    }
    return null;
  };

  const renderAnalystNotes = () => {
    return (
      <>
        <S.ExpandableHeader
          onClick={e => {
            e.stopPropagation();
            setIsSpecialInterestExpanded(prevState => !prevState);
          }}
        >
          <S.Category>Analyst&apos;s note</S.Category>
          <S.CollapseButton>
            <S.CollapseArrow isCollapsed={!isSpecialInterestExpanded} />
          </S.CollapseButton>
        </S.ExpandableHeader>
        {isSpecialInterestExpanded && (
          <S.SpecialInterestSection>
            {entityData.specialInterest?.analystNotes}
          </S.SpecialInterestSection>
        )}
      </>
    );
  };

  const filterFlagsForDJSICategories = () => {
    return specialInterestFlag?.children.filter(item =>
      dowJonesSpecialInterestCategoriesToFilterBy[reportType].includes(item)
    );
  };

  const renderSpecialInterestCard = isActive => {
    return (
      <FlagCard
        type="Special Interest"
        customContent={
          <>
            <S.SpecialInterestSection>
              <S.SpecialInterestFlagList>
                {filterFlagsForDJSICategories()?.map(category => (
                  <li key={category}>
                    <S.Category>{category}</S.Category>
                    {renderLowerThreshold(category)}
                  </li>
                ))}
              </S.SpecialInterestFlagList>
              <div>{formatDate(entityData.specialInterest?.updatedDate)}</div>
            </S.SpecialInterestSection>
            {entityData.specialInterest?.analystNotes && renderAnalystNotes()}
          </>
        }
        isFlagInactive={!isActive}
      />
    );
  };

  const renderFlagsSection = () => {
    if (!listReferences && !stateOwnershipsFlag && !specialInterestFlag) {
      return null;
    }

    const inactiveCards = [];
    const activeCards = [];

    // Build Watchlist and Sanction lists cards
    if (listReferences) {
      entityData?.listReferences?.forEach(reference => {
        const isFlagInactive = reference.entries?.every(
          entry => !entry.isKnownToBeOngoing
        );
        const card = (
          <FlagCard
            key={reference.listGroup}
            type={reference.listGroup}
            lists={reference.entries?.map(entry => {
              return {
                name: entry.listName,
                duration: getDurationStringFromDates({
                  startDate: entry.startDate,
                  endDate: entry.endDate,
                  isKnownToBeOngoing: entry.isKnownToBeOngoing
                }),
                isKnownToBeOngoing: entry.isKnownToBeOngoing,
                endDate: entry.endDate
              };
            })}
            isFlagInactive={isFlagInactive}
          />
        );
        if (isFlagInactive) {
          inactiveCards.push(card);
        } else {
          activeCards.push(card);
        }
      });
    }

    // Build State Ownerships card
    if (stateOwnershipsFlag) {
      const card = renderStateOwnedCompanyCard(stateOwnershipsFlag);
      if (stateOwnershipsFlag.isActive) {
        activeCards.push(card);
      } else {
        inactiveCards.push(card);
      }
    }

    // Build Special Interest card
    if (specialInterestFlag) {
      const card = renderSpecialInterestCard(specialInterestFlag.isActive);
      if (specialInterestFlag.isActive) {
        activeCards.push(card);
      } else {
        inactiveCards.push(card);
      }
    }

    return (
      <S.FlagsSection>
        {activeCards} {inactiveCards}
      </S.FlagsSection>
    );
  };

  const renderFlagHierarchy = () => {
    const flagHierarchyPills = renderFlagHierarchyPills({
      entityFlags: entityData?.flags,
      isCloseAssociate: false,
      flagContainerStyles: classNameOverrides.flagPillsContainer,
      shouldRenderChildFlags: true
    });

    if (!flagHierarchyPills) {
      return null;
    }

    return <S.Section>{flagHierarchyPills}</S.Section>;
  };

  const renderIdentifiersSection = () => {
    if (
      !entityData?.watchlistIdentifiers?.length &&
      !entityData?.nonWatchlistIdentifiers?.length
    ) {
      return null;
    }

    let nonWatchlistIdentifiers = [];

    if (entityData?.nonWatchlistIdentifiers?.length) {
      nonWatchlistIdentifiers = [...entityData.nonWatchlistIdentifiers];
    }

    if (entityData.profileId) {
      nonWatchlistIdentifiers.unshift({
        label: "Dow Jones RnC ID",
        value: entityData.profileId
      });
    }

    return (
      <>
        {renderExpandableHeader(
          isIdListExpanded,
          setIsIdListExpanded,
          "ID numbers"
        )}
        {isIdListExpanded && (
          <S.IdentifiersSection>
            {renderDatalist(
              "",
              <div>{renderIdNumbers(nonWatchlistIdentifiers)}</div>,
              nonWatchlistIdentifiers?.length
            )}
            {renderDatalist(
              "Sanction and watchlist IDs",
              <div>{renderIdNumbers(entityData?.watchlistIdentifiers)}</div>,
              entityData?.watchlistIdentifiers?.length
            )}
          </S.IdentifiersSection>
        )}
      </>
    );
  };

  const renderImageLinks = () => {
    if (!entityData?.images?.length) {
      return null;
    }

    return (
      <>
        {renderExpandableHeader(
          isImageLinksExpanded,
          setIsImageLinksExpanded,
          "Images"
        )}
        {isImageLinksExpanded && (
          <S.ImageLinks>
            {renderDatalist(
              "",
              <div>
                {entityData.images.map(imageLink => (
                  <div>
                    <S.SourceLink key={imageLink} href={imageLink}>
                      {imageLink}
                    </S.SourceLink>
                  </div>
                ))}
              </div>,
              entityData.images.length
            )}
          </S.ImageLinks>
        )}
      </>
    );
  };

  const renderEntityImage = () => {
    return (
      <S.EntityImage filterBucketType={filterBucketType}>
        {getEntityTypeImage(
          entityData?.isRCA ? RELATIVE_OR_CLOSE_ASSOCIATE : entityType
        )}
      </S.EntityImage>
    );
  };

  const onViewAssociateProfile = associateId => {
    setDisplayBackButton(true);
    const associate = mutateToLowerCamelCase(
      associatedEntityProfiles[associateId]
    );

    // There _should_ be a link, but if there isn't data present, then return -
    // avoids error
    if (!associate) {
      return;
    }

    // Not interested in the associates of the associate
    associate.associatedOrgs = [];
    associate.associatedPeople = [];
    associate.associatedEntities = [];
    setEntityData(associate);
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo(0, 0);
    }
  };

  return (
    <S.Modal
      size="xl"
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(prev => !prev)}
      scrollable
      contentClassName={classNameOverrides.modalContent}
    >
      <S.ModalHeader
        close={
          <S.ExitModalButton onClick={() => setIsModalOpen(false)}>
            <S.ExitModalIcon />
          </S.ExitModalButton>
        }
      >
        <S.ModalTopBorder />
        {displayBackButton ? (
          <S.BackButton
            onClick={() => {
              setEntityData(initialEntityData);
              setDisplayBackButton(false);
            }}
          >
            <S.BackButtonChevron />
            <S.BackButtonText>Back</S.BackButtonText>
          </S.BackButton>
        ) : (
          <S.ModalHeaderTextContainer>
            <S.ModalHeaderTextPrimary>
              Dow Jones Risk & Compliance
            </S.ModalHeaderTextPrimary>
            <S.ModalHeaderTextSecondary>
              Profile viewer
            </S.ModalHeaderTextSecondary>
          </S.ModalHeaderTextContainer>
        )}
      </S.ModalHeader>
      <S.ModalBody ref={modalBodyRef}>
        <S.EntityOverview>
          {renderEntityImage()}
          <S.EntityOverviewDetails>
            <S.EntityTitle>{entityData?.entityName}</S.EntityTitle>
            <S.CountryField>
              {entityData?.entityLocation?.countryCode ||
              entityData?.birthplace?.[0]?.countryCode ||
              entityData?.citizenship?.[0]?.countryCode ? (
                <S.CountryFlag
                  code={
                    entityData?.entityLocation?.countryCode ??
                    entityData?.birthplace?.[0]?.countryCode ??
                    entityData?.citizenship?.[0]?.countryCode
                  }
                  showCodeString={false}
                />
              ) : null}
              <S.CountryName>
                {entityData?.entityLocation?.countryName ??
                  entityData?.birthplace?.[0]?.countryName ??
                  entityData?.citizenship?.[0]?.countryName}
              </S.CountryName>
            </S.CountryField>
            <S.EntityPills>
              <NewReportButton
                subject={entityData.entityName}
                contextList={[reportSubjectName]}
                name={entityData.entityName}
                image={renderEntityImage()}
                contextType={reportSubjectType}
                subjectType={entityType}
                isDisabled={disableNewReportButton}
              />
            </S.EntityPills>
          </S.EntityOverviewDetails>
        </S.EntityOverview>
        {renderFlagHierarchy()}
        {renderFirstDetails()}
        {renderRoleDetails()}
        {renderSecondDetails()}
        {renderAlternativeNames()}
        {renderAddresses()}
        {renderIdentifiersSection()}
        {renderImageLinks()}
        {renderFlagsSection()}
        {associatedPeopleToUse?.length > 0 && (
          <EntityMasonry
            entities={associatedPeopleToUse}
            entitiesExpanded
            entitiesBackgroundColorOverride={grey.panel}
            renderFlagHierarchyPills={renderFlagHierarchyPills}
            sectionStyles={classNameOverrides.associates}
            filterBucketType={filterBucketType}
            reportSubjectName={reportSubjectName}
            reportSubjectType={reportSubjectType}
            disableNewReportButton={disableNewReportButton}
            onViewAssociateProfile={onViewAssociateProfile}
            associatedEntityProfiles={associatedEntityProfiles}
          />
        )}
        {associatedOrgsToUse?.length > 0 && (
          <EntityMasonry
            entities={associatedOrgsToUse}
            entitiesExpanded
            entitiesBackgroundColorOverride={grey.panel}
            renderFlagHierarchyPills={renderFlagHierarchyPills}
            sectionStyles={classNameOverrides.associates}
            filterBucketType={filterBucketType}
            reportSubjectName={reportSubjectName}
            disableNewReportButton={disableNewReportButton}
          />
        )}
        {renderProfileNotes()}
        {renderSources()}
      </S.ModalBody>
      <S.ModalFooter>
        <div>
          {entityData.lastReviewedDate && (
            <>
              <S.LastReviewedLabel>Last reviewed:</S.LastReviewedLabel>
              <span>{formatDate(entityData?.lastReviewedDate)}</span>
            </>
          )}
        </div>
        <S.DowJonesLogo />
      </S.ModalFooter>
    </S.Modal>
  );
};

export default DetailedModalView;
