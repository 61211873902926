import React, { FC, SVGProps, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Send } from "react-feather";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import Api from "api/reports";
import ErrorBanner from "components/atoms/ErrorBanner";

import S from "./styles";

const variants = {
  initial: {
    opacity: 0
  },
  visible: {
    opacity: 1
  },
  hidden: {
    opacity: 0
  }
};

const RequestAccessModal = () => {
  const [requestSent, setRequestSent] = useState(false);
  const [{ error, errorMessage, fetching }, dispatch] = useFetchReducer();
  const { enquiryId } = useParams();
  const reportsApi = new Api();

  const navigate = useNavigate();

  const onClose = () => {
    navigate("/search");
  };

  const onRequestAccess = async () => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await reportsApi.requestAccess(enquiryId);

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return;
    }

    dispatch({ type: RequestActions.SetSuccess });
    setRequestSent(prev => !prev);
  };

  const getPrimaryActionIcon = () => {
    if (fetching) {
      return Spinner as unknown as FC<SVGProps<SVGSVGElement>>;
    }

    if (!requestSent) {
      return Send;
    }

    return undefined;
  };

  return (
    <ModalContainer
      isOpen
      title="Request access"
      onExitClick={() => {
        if (!fetching) navigate("/login");
      }}
      toggleOpen={() => {}}
    >
      <AnimatePresence initial={false} exitBeforeEnter>
        {requestSent ? (
          <motion.div
            key="request_sent"
            initial="initial"
            animate="visible"
            exit="hidden"
            variants={variants}
          >
            <Heading level={5}>
              An email requesting access was sent to the report owner.
            </Heading>
          </motion.div>
        ) : (
          <motion.div
            key="request"
            initial="initial"
            animate="visible"
            exit="hidden"
            variants={variants}
          >
            <Heading level={5}>
              It doesn&apos;t look like you have access to this report.
            </Heading>
          </motion.div>
        )}
      </AnimatePresence>
      <S.ModalContent>
        <AnimatePresence initial={false} exitBeforeEnter>
          {requestSent ? (
            <S.ModalContentText
              key="request"
              initial="initial"
              animate="visible"
              exit="hidden"
              variants={variants}
            >
              You will receive an email when access is granted.
            </S.ModalContentText>
          ) : (
            <S.ModalContentText
              key="request_sent"
              initial="initial"
              animate="visible"
              exit="hidden"
              variants={variants}
            >
              You can request access and we will email the report owner for you.
            </S.ModalContentText>
          )}
        </AnimatePresence>
      </S.ModalContent>
      <S.ModalActions>
        <S.Button
          text="Cancel"
          size={ButtonSize.Medium}
          type={ButtonType.Outlined}
          onClick={onClose}
        />
        <S.Button
          text={requestSent ? "Done" : "Request access"}
          IconTrailing={getPrimaryActionIcon()}
          size={ButtonSize.Medium}
          type={ButtonType.Filled}
          onClick={requestSent ? onClose : onRequestAccess}
          disabled={fetching}
        />
      </S.ModalActions>
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            "There was an issue sending your request. Try again in a moment."
          }
          onClick={() => dispatch({ type: RequestActions.Reset })}
        />
      )}
    </ModalContainer>
  );
};

export default RequestAccessModal;
