import React from "react";

import config from "config";

import S from "./styles";

const DisabledInsightsModal = ({
  onClose = () => {},
  title
}: {
  onClose?: React.MouseEventHandler<HTMLDivElement>;
  title: string;
}) => {
  return (
    <S.Container>
      <S.CloseButton onClick={onClose}>
        <S.CloseIcon />
      </S.CloseButton>
      <S.InfoIcon />
      <S.Heading level={3}>{title} is currently disabled.</S.Heading>
      <S.Paragraph>
        If you require any further support, please
        <br />
        contact{" "}
        <a
          href={`mailto:${config.supportEmail}?subject=Tell Xapien what you know`}
          target="_blank"
          rel="noreferrer"
        >
          Customer Support
        </a>
        .
      </S.Paragraph>
    </S.Container>
  );
};

export default DisabledInsightsModal;
