import { styled } from "@linaria/react";

import { CardVariant } from "components/molecules/Card/types";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const Footer = styled.footer<{ variant?: CardVariant }>`
  display: flex;
  flex-direction: column;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
  font-size: ${fontSize.xs};
  gap: 0.125rem;
  align-items: flex-end;
  justify-content: center;
`;

const FooterTopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const DateTimeAndChildren = styled.div`
  display: flex;
  align-items: center;
`;

const S = {
  Footer,
  DateTimeAndChildren,
  FooterTopContent
};

export default S;
