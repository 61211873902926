import React, {
  ChangeEvent,
  KeyboardEventHandler,
  forwardRef,
  useId
} from "react";

import S from "./styles";

interface Props {
  placeholder: string;
  inputType?: string;
  onChange: (value: string) => void;
  onKeyUp?: KeyboardEventHandler;
  value: string;
  label?: string;
  disabled?: boolean;
  autoComplete?: string;
  hidePlaceholderOnFocus?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      placeholder,
      inputType = "text",
      onChange,
      onKeyUp,
      value,
      label,
      disabled,
      autoComplete,
      hidePlaceholderOnFocus = false
    },
    ref
  ) => {
    const inputId = useId();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    };

    return (
      <S.Container>
        {label && <S.Label htmlFor={inputId}>{label}</S.Label>}
        <S.Input
          id={`${inputId}-search`} // Putting search here prevents lastpass from autofilling??
          ref={ref}
          type={inputType}
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value}
          disabled={disabled} // no styling on disabled ATM, but just in temporary use for a field that isn't fully implemented
          autoComplete={autoComplete}
          hidePlaceholderOnFocus={hidePlaceholderOnFocus}
          data-lpignore="true"
          onKeyUp={onKeyUp}
        />
      </S.Container>
    );
  }
);

export default Input;
