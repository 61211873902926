import { styled } from "@linaria/react";
import BaseHeading from "components/atoms/Heading";
import { grey, green, standardColors } from "styles/colors";
import { Info } from "react-feather";
import { hexToRgb } from "styles/hexToRgb";
import { fontSize } from "styles/fonts";

const Container = styled.div<{ compact: boolean }>`
  background-color: ${standardColors.white};
  border-bottom-right-radius: ${({ compact }) => (compact ? "10px" : 0)};
  border-bottom-left-radius: ${({ compact }) => (compact ? "10px" : 0)};
`;

const Content = styled.div<{ compact: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ compact }) => (compact ? "544px" : "475px")};
  padding: 24px;
  margin: auto;
  gap: 8px;
`;

const IconContainer = styled.div<{ compact: boolean }>`
  background: ${() => {
    const { r, g, b } = hexToRgb(green.xapienGreen);
    return `rgba(${r}, ${g}, ${b}, 0.15)`;
  }};
  border-radius: 50%;
  width: ${({ compact }) => (compact ? "48px" : "100px")};
  height: ${({ compact }) => (compact ? "48px" : "100px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(Info)<{ compact: boolean }>`
  width: ${({ compact }) => (compact ? "24px" : "42px")};
  height: ${({ compact }) => (compact ? "24px" : "42px")};
  color: ${green.xapienGreen};
`;

const Heading = styled(BaseHeading)`
  color: ${standardColors.black};
`;

const Paragraph = styled.div`
  text-align: center;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  margin-bottom: 8px;
`;

const S = {
  Container,
  Content,
  IconContainer,
  Icon,
  Heading,
  Paragraph
};

export default S;
