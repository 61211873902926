import React, { useReducer, useCallback, useMemo, ReactNode } from "react";

import {
  UpcDetailsActions,
  type UpcDetailsAction,
  type UpcDetailsState
} from "./types";

import { UpcDetailsContext, upcDetailsReducer } from "./context";

export const UpcDetailsContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [state, upcDetailsDispatch] = useReducer(upcDetailsReducer, {});

  const dispatch = useCallback((action: UpcDetailsAction) => {
    upcDetailsDispatch(action);
  }, []);

  const updateState = useCallback(
    (updatedState: UpcDetailsState) =>
      dispatch({ type: UpcDetailsActions.updateState, state: updatedState }),
    [dispatch]
  );

  const providerValue = useMemo(
    () => ({ state, updateState }),
    [state, updateState]
  );

  return (
    <UpcDetailsContext.Provider value={providerValue}>
      {children}
    </UpcDetailsContext.Provider>
  );
};
