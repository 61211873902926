import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

export interface TooltipProps {
  withCloseButton: boolean;
}

const Tooltip = styled.div<TooltipProps>`
  background-color: ${standardColors.white};
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  display: flex;
  flex-direction: row;
  width: ${({ withCloseButton }) => (withCloseButton ? "275px" : "250px")};
  z-index: 1;
  right: -50px;
  bottom: calc(100% + 20px);
`;

const TooltipContent = styled.div`
  flex: 1;
`;

const TooltipCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 21px;
  border-radius: 50%;

  &:hover {
    background-color: ${grey.panel};
  }

  > svg {
    width: 19px;
    height: 19px;
  }
`;

const TooltipTitle = styled.p`
  font-family: ${family.interDisplay};
  font-size: ${fontSize.sm};
  margin-bottom: 6px;
`;

const TooltipText = styled.p`
  font-family: ${family.interLight};
  font-size: ${fontSize.xs};
  margin-bottom: 0;
`;

const TooltipPoint = styled.div`
  background-color: ${standardColors.white};
  width: 15px;
  height: 15px;
  transform: rotate(0.125turn);
  position: absolute;
  bottom: -8px;
  right: 57px;
`;

const S = {
  Tooltip,
  TooltipContent,
  TooltipCloseButton,
  TooltipTitle,
  TooltipText,
  TooltipPoint
};

export default S;
