import { styled } from "@linaria/react";
import { ReactComponent as SayariSVG } from "img/sayari-logo.svg";

import { grey, standardColors } from "styles/colors";

const Container = styled.div`
  width: 100%;
  height: 75vh;
  background: ${standardColors.white};
`;

const SayariLogo = styled(SayariSVG)`
  color: ${grey.mid};
  max-height: 100%;
`;

const S = { Container, SayariLogo };

export default S;
