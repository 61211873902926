import React, { FunctionComponent, useState } from "react";

import InsightsAnswer from "components/organisms/InsightsChat/InsightsAnswer";
import InsightsQuestion from "components/organisms/InsightsChat/InsightsQuestion";

import threePurpleBallsGif from "img/threePurpleBalls.gif";

import { motion } from "framer-motion";

import {
  InsightsAmaMessage,
  InsightsAmaMessageStatus
} from "util/hooks/useInsightsAma/types";
import S from "./styles";

interface InsightsMessageProps {
  message: InsightsAmaMessage;
}

const InsightsMessage: FunctionComponent<InsightsMessageProps> = ({
  message
}) => {
  const [isFetchingSources, setIsFetchingSources] = useState(false);

  const renderAnswer = () => {
    if (message.status === InsightsAmaMessageStatus.loading) {
      return (
        <S.FetchingImage
          src={threePurpleBallsGif}
          aria-label="Insights Loading Ball Animation"
        />
      );
    }

    if (message.answer) {
      return (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
        >
          <InsightsAnswer
            answer={message.answer}
            isFetchingSources={isFetchingSources}
            onFetchSources={setIsFetchingSources}
          />
        </motion.div>
      );
    }

    return <S.Error>Something went wrong asking this question.</S.Error>;
  };

  if (
    message.status === InsightsAmaMessageStatus.error ||
    message.status === InsightsAmaMessageStatus.saving ||
    message.status === InsightsAmaMessageStatus.saved
  ) {
    return null;
  }

  return (
    <S.Message
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
    >
      <InsightsQuestion question={message.question} />
      {renderAnswer()}
    </S.Message>
  );
};

export default InsightsMessage;
