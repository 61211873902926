import { css } from "@linaria/core";

export const globals = css`
  /* Old legacy code from a long, long time ago. This is essentially modified Bootstrap 4. */
  :global() {
    @media print {
      *,
      *::before,
      *::after {
        text-shadow: none !important;
        box-shadow: none !important;
      }

      a:not(.btn) {
        text-decoration: underline;
      }

      abbr[title]::after {
        content: " (" attr(title) ")";
      }

      pre {
        white-space: pre-wrap !important;
      }

      pre,
      blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
      }

      thead {
        display: table-header-group;
      }

      tr,
      img {
        page-break-inside: avoid;
      }

      p,
      h2,
      h3 {
        orphans: 3;
        widows: 3;
      }

      h2,
      h3 {
        page-break-after: avoid;
      }

      @page {
        size: a3;
      }

      body {
        min-width: 992px !important;
      }

      .container {
        min-width: 992px !important;
      }
    }
  }
`;
