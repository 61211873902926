import type { UserPermissions } from "api/user/types";

export const MFA_CODE_LENGTH = 6;

export enum AuthenticationActions {
  updateEmail = "updateEmail",
  updatePassword = "updatePassword",
  updateMfaCode = "updateMfaCode",
  authenticate = "authenticate",
  authenticated = "authenticated",
  verify = "verify",
  passwordVerified = "passwordVerified",
  unauthenticated = "unauthenticated",
  dismissError = "dismissError",
  updatePermissions = "updatePermissions",
  mfaSetup = "mfaSetup",
  resetState = "resetState",
  fetchLoginInfo = "fetchLoginInfo",
  fetchLoginInfoSuccess = "fetchLoginInfoSuccess"
}

export enum AuthenticationStatus {
  unknown = "unknown",
  unauthenticated = "unauthenticated",
  authenticating = "authenticating",
  authenticated = "authenticated",
  verifying = "verifying",
  passwordVerified = "passwordVerified",
  mfaSetup = "mfaSetup",
  fetchingLoginInfo = "fetchingLoginInfo",
  passwordRequired = "passwordRequired"
}

export interface AuthenticationState {
  email: string;
  password: string;
  mfaCode: string;
  status: AuthenticationStatus;
  error?: string;
  permissions: UserPermissions;
  isPermissionsLoaded: boolean;
  mfaStatus?: "required" | "setup";
  username?: string;
  loginProvider?: string;
}

interface UpdateMfaCodeAction {
  type: AuthenticationActions.updateMfaCode;
  mfaCode: string;
}

interface VerifyAction {
  type: AuthenticationActions.verify;
}

interface PasswordVerifiedAction {
  type: AuthenticationActions.passwordVerified;
  mfaStatus?: "required" | "setup";
}

interface MfaSetupAction {
  type: AuthenticationActions.mfaSetup;
}
interface ResetState {
  type: AuthenticationActions.resetState;
}

interface UpdatePermissionsAction {
  type: AuthenticationActions.updatePermissions;
  permissions: UserPermissions;
}

interface UpdateEmailAction {
  type: AuthenticationActions.updateEmail;
  email: string;
}

interface UpdatePasswordAction {
  type: AuthenticationActions.updatePassword;
  password: string;
}

interface AuthenticateAction {
  type: AuthenticationActions.authenticate;
}

interface AuthenticatedAction {
  type: AuthenticationActions.authenticated;
  username?: string;
}

interface UnauthenticatedAction {
  type: AuthenticationActions.unauthenticated;
  error?: string;
}

interface DismissErrorAction {
  type: AuthenticationActions.dismissError;
}

interface FetchLoginInfoAction {
  type: AuthenticationActions.fetchLoginInfo;
}

interface FetchLoginInfoSuccessAction {
  type: AuthenticationActions.fetchLoginInfoSuccess;
  loginProvider?: string;
}

export type AuthenticationAction =
  | UpdateEmailAction
  | UpdatePasswordAction
  | AuthenticateAction
  | AuthenticatedAction
  | UnauthenticatedAction
  | DismissErrorAction
  | UpdatePermissionsAction
  | UpdateMfaCodeAction
  | VerifyAction
  | PasswordVerifiedAction
  | ResetState
  | MfaSetupAction
  | FetchLoginInfoAction
  | FetchLoginInfoSuccessAction;
