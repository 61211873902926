import React, { Fragment, useState } from "react";

import TruncateLength from "util/TruncateLength";
import { InfoTypes } from "components/molecules/Accordion";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import { orderByDateAndStatus } from "util/orderByDateAndStatus";
import { getDurationSinceStartDate } from "util/getDurationSinceStartDate";

import S from "./styles";

const SPECIAL_ROLES = {
  "person of significant control": "PSC",
  "ultimate beneficial owner": "UBO"
};

const OrgInfoSummaryRow = ({
  orgName,
  previousNames,
  industry,
  companyStatus,
  sources,
  reasonsForRisk,
  companyLocation,
  addressFullDisplay,
  addressShortDisplay,
  roleHolderName,
  listOfRoles,
  companyIdentifiers,
  onClick
}) => {
  const [previousNamesAccordionExpanded, setPreviousNamesAccordionExpanded] =
    useState(false);

  const previousNamesString = previousNames?.length > 1 ? "names" : "name";

  const renderAddress = () => {
    if (!addressFullDisplay?.length && !addressShortDisplay?.length) {
      return null;
    }

    let addressShortDisplayString;
    if (addressShortDisplay?.length) {
      if (Array.isArray(addressShortDisplay)) {
        addressShortDisplayString = addressShortDisplay?.join(", ");
      } else {
        addressShortDisplayString = addressShortDisplay;
      }
    }

    const content = (
      <S.CountryContainer>
        {companyLocation?.countryCode ? (
          <S.CustomFlag
            showCodeString={false}
            code={companyLocation?.countryCode}
          />
        ) : null}
        <div>{addressShortDisplayString}</div>
      </S.CountryContainer>
    );

    if (addressFullDisplay?.length) {
      return (
        <S.Accordion
          stopTogglePropagation
          title={content}
          type={InfoTypes.Custom}
          countryFlagDisplaying={companyLocation?.countryCode}
          dataToDisplay={
            <S.Address>
              {addressFullDisplay?.map(line => (
                <div key={line}>{line}</div>
              ))}
            </S.Address>
          }
          displayCount={false}
        />
      );
    }

    return <S.SingleAddressContainer>{content}</S.SingleAddressContainer>;
  };

  const renderPreviousNames = () => {
    if (!previousNames?.length) {
      return null;
    }

    if (previousNames?.length === 1) {
      return (
        <S.SinglePreviousName>
          Previous name: <S.Text>{previousNames[0]?.name}</S.Text>
        </S.SinglePreviousName>
      );
    }

    // Order by date
    const orderedPreviousNames = orderByDateAndStatus(
      previousNames.map(previousName => {
        return {
          startDate: previousName.startDate ?? previousName.start,
          endDate: previousName.endDate ?? previousName.end,
          name: previousName.name
        };
      })
    );

    return (
      <S.PreviousNamesAccordion
        stopTogglePropagation
        onToggle={value => setPreviousNamesAccordionExpanded(value)}
        title={
          <Fragment>
            {previousNamesAccordionExpanded ? (
              <div>
                {previousNames?.length > 1 ? previousNames?.length : null}{" "}
                Previous {previousNamesString}
              </div>
            ) : (
              <S.PreviousName>
                Previous name: <S.Text>{previousNames[0]?.name}</S.Text>
              </S.PreviousName>
            )}
          </Fragment>
        }
        type={InfoTypes.Custom}
        dataToDisplay={
          <S.PreviousNamesList>
            {orderedPreviousNames?.map((previousName, index) => {
              const date = getDurationStringFromDates({
                startDate: previousName.startDate,
                endDate: previousName.endDate
              });
              return (
                <S.ListItem key={index}>
                  {previousName.name} {date ? <S.Date>({date})</S.Date> : null}
                </S.ListItem>
              );
            })}
          </S.PreviousNamesList>
        }
        displayCount={false}
      />
    );
  };

  const renderIdNumber = () => {
    if (!companyIdentifiers?.length) {
      return null;
    }

    return (
      <S.CompanyIdentifier>
        <S.Label>ID number: </S.Label>
        <span>{companyIdentifiers[0].value}</span>
      </S.CompanyIdentifier>
    );
  };

  const renderRoleTitle = role => {
    let specialRole = SPECIAL_ROLES[role.roleTitle?.toLowerCase()];

    if (!specialRole && role.isOfficership) {
      specialRole = "Officer";
    }

    if (specialRole) {
      return (
        <S.RolePill roleType={specialRole} roleIsOngoing={role.isOngoing}>
          {specialRole}
        </S.RolePill>
      );
    }

    return <S.Role>{role.roleTitle}</S.Role>;
  };

  const sourcesLength = [...new Set(sources.map(source => source.id))]?.length;

  return (
    <S.InfoSummaryRow
      interactive={sourcesLength}
      onClick={onClick}
      key={orgName}
      icon={
        <S.OrgIconContainer>
          <S.OrgIcon />
        </S.OrgIconContainer>
      }
      heading={orgName}
      subheading={
        sourcesLength
          ? `${sourcesLength} ${sourcesLength > 1 ? "sources" : "source"}`
          : null
      }
      content={
        <Fragment>
          {industry ? <S.IndustryType>{industry}</S.IndustryType> : null}
          {companyStatus || reasonsForRisk?.length ? (
            <S.StatusTags>
              {companyStatus ? (
                <S.StatusTag status={companyStatus?.statusTag}>
                  <TruncateLength>{companyStatus?.label}</TruncateLength>
                </S.StatusTag>
              ) : null}
              {reasonsForRisk?.map(risk => {
                return (
                  <S.StatusTag key={risk} status="Warning">
                    <TruncateLength>{risk}</TruncateLength>
                  </S.StatusTag>
                );
              })}
            </S.StatusTags>
          ) : null}
          {renderAddress()}
          {renderPreviousNames()}
          {renderIdNumber()}
          {listOfRoles?.length > 0 && (
            <S.PersonDetails>
              {listOfRoles?.map((role, index) => {
                const roleStartDate = role.startDate;
                const roleEndDate = role.endDate;
                const roleIsKnownToBeOngoing = role.isOngoing;
                return (
                  <S.OfficershipRole key={index}>
                    <div>{roleHolderName}</div>
                    <S.RoleDate
                      isOngoing={roleIsKnownToBeOngoing || !roleEndDate}
                    >
                      {getDurationStringFromDates({
                        startDate: roleStartDate,
                        endDate: roleEndDate,
                        isKnownToBeOngoing: roleIsKnownToBeOngoing
                      })}
                    </S.RoleDate>{" "}
                    <S.RoleDuration>
                      {getDurationSinceStartDate({
                        startDate: roleStartDate,
                        endDate: roleEndDate
                      })}
                    </S.RoleDuration>
                    {renderRoleTitle(role)}
                  </S.OfficershipRole>
                );
              })}
            </S.PersonDetails>
          )}
        </Fragment>
      }
    />
  );
};

export default OrgInfoSummaryRow;
