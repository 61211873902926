import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";

// @ts-ignore
import theme from "theme";
import { standardColors, grey } from "styles/colors";
import { family, size } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

export interface ListItemProps {
  nodeSize: number;
}

const NoEventsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const NoEventsContent = styled.div`
  text-align: center;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoEventsTitle = styled.h4`
  font-family: ${family.interDisplay};
  margin-bottom: 15px;
`;

const NoEventsText = styled.p`
  color: ${grey.darkish};
  font-size: ${size.sm};
`;
const NoEventsButton = styled(ButtonNew)`
  background-color: ${theme.primaryColor};
  color: ${standardColors.white};
  font-family: ${family.interSemiBold};
  padding: 10px 15px;

  svg > path {
    stroke: ${standardColors.white}!important;
  }
`;

const NoEventsTimelineIconContainer = styled.div`
  display: inline-block;
  padding: 25px;
  border-radius: 50%;
  background-color: ${() => {
    const { r, g, b } = hexToRgb(theme.primaryColor);
    return `rgba(${r}, ${g}, ${b}, 0.17)`;
  }};
  margin-bottom: 25px;
`;

const S = {
  NoEventsContainer,
  NoEventsContent,
  NoEventsTitle,
  NoEventsText,
  NoEventsButton,
  NoEventsTimelineIconContainer
};

export default S;
