import { styled } from "@linaria/react";

import { hexToRgb } from "styles/hexToRgb";

import { standardColors, grey, blue, green } from "styles/colors";
import { fontSize } from "styles/fonts";

import ButtonNew from "components/atoms/ButtonNew";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  padding: 8rem;
  max-width: 940px;
  margin: auto;

  p {
    margin: 0;
    text-align: center;
    font-size: ${fontSize.lg};
  }

  a {
    color: ${blue.icon};
  }
`;

const ErrorLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(
    ${() => {
      const { r, g, b } = hexToRgb(green.xapienGreen);
      return `${r}, ${g}, ${b}`;
    }},
    0.15
  );
  width: 100px;
  height: 100px;

  svg {
    width: 50px;
    height: 50px;
  }
`;

const Button = styled(ButtonNew)`
  color: ${standardColors.white} !important;
  background-color: ${green.xapienGreen};
  margin-top: 1rem;

  svg {
    width: 1.3rem !important;
    height: 1.3rem !important;
  }

  svg path {
    stroke: ${standardColors.white} !important;
  }

  &:hover {
    color: ${standardColors.white} !important;
    svg path {
      stroke: ${standardColors.white} !important;
    }
  }
`;

const Container = styled.div`
  background-color: ${standardColors.white};
  color: ${grey.dark};
  max-width: 1080px;
  border-radius: 6px;
  margin: 84px auto 0;
`;

const S = {
  Container,
  Button,
  ErrorContainer,
  ErrorLogoContainer
};

export default S;
