import React, { FC } from "react";

import Heading from "components/atoms/Heading";
import S from "./styles";

interface Props {
  text?: string;
}

const LoadingScreen: FC<Props> = ({ text = "Loading" }) => {
  return (
    <S.LoadingContainer>
      <S.LoadingLogo>
        <S.LoadingSpinner />
        <S.Logo />
      </S.LoadingLogo>
      <Heading level={4}>{text}</Heading>
    </S.LoadingContainer>
  );
};

export default LoadingScreen;
