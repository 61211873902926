import { useEffect } from "react";

import { internalStages, stage } from "services/stage";

const PageTitle = ({ title }: { title?: string }) => {
  useEffect(() => {
    const pageTitle = `${internalStages.has(stage) ? `(${stage}) ` : ""}${
      title ?? ""
    }`;
    document.title = pageTitle;
  }, [title]);

  return null;
};

export default PageTitle;
