import { css } from "@linaria/core";

export const globals = css`
  /* Old legacy code from a long, long time ago. This is essentially modified Bootstrap 4.
  * If you find yourself reaching for these styles to add to or modify, please take this time
  * to make what you're building a Linaria styled component, or if the styles would benefit
  * from being global, a more robust global system than hacked Bootstrap.
  */
  :global() {
    .d-flex {
      display: flex !important;
    }

    .flex-row {
      flex-direction: row !important;
    }

    .align-items-center {
      align-items: center !important;
    }

    .mw-100 {
      max-width: 100% !important;
    }

    .ml-1,
    .mx-1 {
      margin-left: 0.25rem !important;
    }

    .mt-2,
    .my-2 {
      margin-top: 0.5rem !important;
    }

    .mb-2,
    .my-2 {
      margin-bottom: 0.5rem !important;
    }

    .ml-3,
    .mx-3 {
      margin-left: 1rem !important;
    }

    .p-0 {
      padding: 0 !important;
    }

    .pr-0,
    .px-0 {
      padding-right: 0 !important;
    }

    .pl-0,
    .px-0 {
      padding-left: 0 !important;
    }

    .pt-3,
    .py-3 {
      padding-top: 1rem !important;
    }

    .pb-3,
    .py-3 {
      padding-bottom: 1rem !important;
    }

    .pl-3,
    .px-3 {
      padding-left: 1rem !important;
    }

    // End Bootstrap-lifted styles, the below should be useful global util classes

    .visually-hidden {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }
`;
