import { styled } from "@linaria/react";

import { blue } from "styles/colors";

const BigSpinner = styled.div<{ borderColor?: string }>`
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: ${props => props.borderColor ?? blue.xapienLight};
  animation: spin 2s linear infinite;
  margin-top: 35px;

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: ${props => props.borderColor ?? blue.xapienLight};
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: ${props => props.borderColor ?? blue.xapienLight};
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const S = {
  BigSpinner
};

export default S;
