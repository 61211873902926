import { css } from "@linaria/core";

const card = css`
  cursor: default;
`;

const clickableCard = css`
  cursor: pointer;
  &:hover {
    outline: 2px solid rgba(40, 228, 250, 0.4);
  }
`;

export const classNameOverrides = {
  card,
  clickableCard
};
