import React, { FC, SVGProps, useState } from "react";
import { Spinner } from "reactstrap";
import { Edit, Eye } from "react-feather";

import SearchInputWithOptions, {
  DropdownOption
} from "components/molecules/SearchInputWithOptions";
import { SearchItemType, SearchResult } from "api/search";
import { AutoSharePermission, SharedWithItem } from "api/user/types";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import useUserAndGroupSearch from "util/hooks/useUserAndGroupSearch";
import useUserPreferences from "util/hooks/useUserPreferences";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import S from "./styles";

const dropdownOptions = [
  { id: AutoSharePermission.Read, label: "Can view", icon: Eye },
  { id: AutoSharePermission.Write, label: "Can edit", icon: Edit }
];

interface Props {
  selectedSuggestions: SearchResult[];
  setSelectedSuggestions: React.Dispatch<React.SetStateAction<SearchResult[]>>;
}

const AddAutoShareEntryControl = ({
  selectedSuggestions,
  setSelectedSuggestions
}: Props) => {
  const [selectedDropdownOption, setSelectedDropdownOption] = useState<
    DropdownOption<AutoSharePermission>
  >(dropdownOptions[0]);
  const [query, setQuery] = useState("");

  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();
  const { suggestions, isFetchingSuggestions, onSearchUpdate } =
    useUserAndGroupSearch({});
  const { addShareEntries } = useUserPreferences();

  const onQueryInputChange = (newQuery: string) => {
    setQuery(newQuery);
    onSearchUpdate(newQuery);
  };

  const onSelectedSuggestionChange = (
    newSelectedSuggestions: SearchResult[]
  ) => {
    if (newSelectedSuggestions.length === 0) {
      setSelectedSuggestions([]);
      return;
    }

    let updatedSuggestions = [...selectedSuggestions];
    const existingSuggestion = selectedSuggestions.findIndex(
      suggestion =>
        suggestion?.id ===
        newSelectedSuggestions[newSelectedSuggestions.length - 1]?.id
    );

    // If the suggestion has already been selected and the user selected the same suggestion
    // in the options list, then remove it
    if (
      existingSuggestion > -1 &&
      newSelectedSuggestions.length > selectedSuggestions.length
    ) {
      updatedSuggestions = [
        ...updatedSuggestions.slice(0, existingSuggestion),
        ...updatedSuggestions.slice(existingSuggestion + 1)
      ];
    } else {
      updatedSuggestions = newSelectedSuggestions;
    }
    setSelectedSuggestions(updatedSuggestions);
  };

  const onAddShareItem = async () => {
    dispatch({ type: RequestActions.SendRequest });
    const shareItemsToAdd: (SharedWithItem & { type: SearchItemType })[] = [];

    selectedSuggestions.forEach(suggestion => {
      if (suggestion.queryType === SearchItemType.User) {
        shareItemsToAdd.push({
          userId: suggestion.id,
          permission: selectedDropdownOption.id as AutoSharePermission,
          type: suggestion.queryType,
          firstName: "",
          lastName: "",
          email: "",
          avatarUrl: ""
        });
      } else {
        shareItemsToAdd.push({
          groupId: suggestion.id,
          permission: selectedDropdownOption.id as AutoSharePermission,
          type: suggestion.queryType as SearchItemType,
          name: ""
        });
      }
    });

    const { status, message } = await addShareEntries(shareItemsToAdd);

    if (status) {
      dispatch({ type: RequestActions.SetSuccess });
      // Reset selected suggestions (they've been successfully added)
      setSelectedSuggestions([]);
    } else {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
    }
  };

  return (
    <>
      <S.InputContainer>
        <SearchInputWithOptions
          query={query}
          options={suggestions}
          loadingText={
            isFetchingSuggestions ? "Loading..." : "No results found"
          }
          searchTags={selectedSuggestions}
          placeholder="Enter name or group"
          onInputChange={onQueryInputChange}
          onChange={onSelectedSuggestionChange}
          onFocus={() => {}}
          onClear={() => {}}
          onSubmit={() => {}}
          dropdownOptions={dropdownOptions}
          onDropdownOptionChange={option => {
            setSelectedDropdownOption(option);
          }}
          selectedDropdownOption={selectedDropdownOption}
        />
        <ButtonNew
          type={ButtonType.Filled}
          size={ButtonSize.Large}
          text="Add"
          disabled={selectedSuggestions.length <= 0 || fetching}
          onClick={onAddShareItem}
          IconTrailing={
            fetching
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
          style={{ alignSelf: "flex-start" }}
        />
      </S.InputContainer>
      {error && (
        <S.CustomErrorBanner
          text={errorMessage || "An error occured. Try again in a moment."}
          onClick={() => {
            dispatch({ type: RequestActions.Reset });
          }}
        />
      )}
    </>
  );
};

export default AddAutoShareEntryControl;
