import { Assertion, AssertionTemplate } from ".";
import { reportAbstract } from "./recognisers";

const exactSocialProfileAssertionTemplate = (profileType, profileUrl) =>
  new AssertionTemplate(
    // valid profileTypes include:
    //    "AngelList", "Crunchbase", "Facebook", "Flickr", "Instagram", "LinkedIn",
    //    "Pinterest", "Reddit", "Soundcloud", "Twitter", "VK", "Wikipedia", "Youtube"
    // Capitalisation needs to be verified (never seen some of these before)
    // https://github.com/Digital-Insight-Technologies-Ltd/CustomerWebApp/blob/master/src/components/molecules/SocialMediaIcons/index.js
    (isValid, isMust, testCaseId, userId, userComment) => {
      const profile = {
        type: "object",
        properties: {
          Data: {
            type: "object",
            properties: {
              ProfileType: {
                type: "string",
                const: profileType
              },
              Link: {
                type: "string",
                const: profileUrl
              }
            }
          }
        }
      };
      const constKey = isValid ? "contains" : "not";
      const constVal = isValid ? profile : { contains: profile };

      const assertionSchema = {
        type: "object",
        properties: {
          ReportAbstract: {
            type: "object",
            properties: {
              OnlineProfilesWithUncertainty: {
                type: "array",
                [constKey]: constVal
              }
            }
          }
        }
      };
      const assertionHumanReadable = `Report ${isMust ? "must" : "should"}${
        isValid ? "" : " not"
      } contain the ${profileType} profile "${profileUrl}".`;
      return new Assertion(
        testCaseId,
        userId,
        userComment,
        isMust,
        assertionSchema,
        assertionHumanReadable
      );
    },
    reportAbstract.specificOnlineProfile(profileType, profileUrl)
  );

export default exactSocialProfileAssertionTemplate;
