import React, { useContext } from "react";
import { observer } from "mobx-react";

import SectionHeader from "pages/report/SectionHeader";
import useRiskSummaries from "util/hooks/useRiskSummaries";
import { InfographicStateContext } from "util/context/InfographicStateContext";
import BasicDetails, {
  BasicDetailsType
} from "components/molecules/BasicDetails";
import { REPORT_TYPES } from "util/reportTypes";
import RiskSummaryInfoGraphic from "components/molecules/RiskSummaryInfoGraphic";
import { RiskSummaryTopic } from "api/insights";
import useUpcDetails from "util/hooks/useUpcDetails";

import ThemedScreeningInfographics from "ThemedScreeningInfographics";

import S from "./styles";

const LegalEntityOverview = React.forwardRef(
  (
    {
      orgScreeningSummary,
      sectionRefs,
      infographicLayoutTemplate,
      title,
      // Key details props
      legalName,
      otherNames,
      companyStatus,
      incorporationDate,
      stateOwned,
      registeredAddresses,
      companyTypes,
      jurisdiction,
      registrationNumber,
      otherIdentifiers,
      legalEntityIdentifier,
      businessPurpose,
      publicListingInfo
    },
    ref
  ) => {
    const { state: upcDetails } = useUpcDetails();
    const { getSummary } = useRiskSummaries();

    const { setDisplayAdditionalLabel, isAnyInfographicInspectorOpen } =
      useContext(InfographicStateContext);

    const setterPropsForDisplayingAdditionalLabel = {
      onMouseOver: () => {
        setDisplayAdditionalLabel(true);
      },
      onMouseOut: () => {
        if (!isAnyInfographicInspectorOpen) setDisplayAdditionalLabel(false);
      }
    };

    const renderScreeningRiskIcons = () => {
      return (
        <ThemedScreeningInfographics
          screeningData={orgScreeningSummary}
          reportSectionRefs={sectionRefs}
          reportType={REPORT_TYPES.legalEntity}
        />
      );
    };

    const renderRiskIcons = () => {
      return (
        <>
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.FINANCIAL_CRIME}
            summary={getSummary(RiskSummaryTopic.FINANCIAL_CRIME)?.summary}
          />
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.ESG}
            summary={getSummary(RiskSummaryTopic.ESG)?.summary}
          />
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.PROCEEDINGS}
            summary={getSummary(RiskSummaryTopic.PROCEEDINGS)?.summary}
          />
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.OTHER_FLAGS}
            summary={getSummary(RiskSummaryTopic.OTHER_FLAGS)?.summary}
          />
        </>
      );
    };

    const getStateOwnership = () => {
      if (stateOwned && stateOwned.value) {
        return [
          {
            value: "Yes",
            sources: stateOwned.sources
          }
        ];
      }

      const { anyParentStateOwned } = upcDetails;

      if (anyParentStateOwned) {
        return [
          {
            value: anyParentStateOwned.value ? "Yes" : "No",
            sources: anyParentStateOwned.sources
          }
        ];
      }

      return [{ value: "Not identified", sources: [] }];
    };

    const keyDetails = [
      [
        {
          title: "Legal name",
          itemType: BasicDetailsType.text,
          items: legalName
            ? [{ value: legalName.text, sources: legalName.sources }]
            : []
        },
        {
          title: "Other names",
          itemType: BasicDetailsType.text,
          items:
            otherNames?.map(({ text, sources }) => ({
              value: text,
              sources
            })) ?? []
        },
        {
          title: "Company types",
          itemType: BasicDetailsType.text,
          items:
            companyTypes?.map(({ text, sources }) => ({
              value: text,
              sources
            })) ?? []
        },
        {
          title: "Company status",
          itemType: BasicDetailsType.text,
          items: companyStatus
            ? [
                {
                  value: companyStatus.statusTag,
                  sources: companyStatus.sources
                }
              ]
            : []
        }
      ],
      [
        {
          title: "Date of incorporation",
          itemType: BasicDetailsType.text,
          items: incorporationDate
            ? [
                {
                  value: `${incorporationDate.day ?? ""} ${
                    incorporationDate.monthShort ?? ""
                  } ${incorporationDate.year}`,
                  sources: incorporationDate.sources
                }
              ]
            : []
        },
        {
          title: "Nature of business",
          itemType: BasicDetailsType.text,
          items: businessPurpose
            ? [
                {
                  value: businessPurpose.text,
                  sources: businessPurpose.sources
                }
              ]
            : []
        },
        {
          title: "Jurisdiction",
          itemType: BasicDetailsType.country,
          items: jurisdiction
            ? [
                {
                  code: jurisdiction.countryCode,
                  name: jurisdiction.countryName,
                  sources: jurisdiction.sources
                }
              ]
            : []
        },
        {
          title: "Publicly listed",
          itemType: BasicDetailsType.stock,
          items:
            publicListingInfo && publicListingInfo.stockExchanges
              ? publicListingInfo.stockExchanges
                  .filter(({ ticker }) => !!ticker)
                  .map(({ exchangeName, listingDate, ticker }) => ({
                    name: exchangeName,
                    date: listingDate,
                    ticker
                  }))
              : []
        },
        {
          title: "Other exchanges",
          itemType: BasicDetailsType.stock,
          items:
            publicListingInfo && publicListingInfo.stockExchanges
              ? publicListingInfo.stockExchanges
                  .filter(({ ticker }) => !ticker)
                  .map(({ exchangeName }) => ({
                    name: exchangeName
                  }))
              : []
        },
        {
          title: "State ownership",
          itemType: BasicDetailsType.text,
          items: getStateOwnership()
        }
      ],
      [
        {
          title: "Company number",
          itemType: BasicDetailsType.text,
          items:
            registrationNumber?.map(({ value, sources }) => ({
              value,
              sources
            })) ?? []
        },
        {
          title: "Legal Entity Identifier (LEI)",
          itemType: BasicDetailsType.text,
          items: legalEntityIdentifier
            ? legalEntityIdentifier.map(({ value, sources }) => ({
                value,
                sources
              }))
            : []
        },
        {
          title: "Other Identifiers",
          itemType: BasicDetailsType.text,
          items: otherIdentifiers
            ? otherIdentifiers.map(({ value, label, sources }) => ({
                value,
                label: `${label}: ${value}`,
                sources
              }))
            : []
        }
      ],
      [
        {
          title: "Registered address",
          itemType: BasicDetailsType.address,
          items: registeredAddresses
            ? registeredAddresses.map(({ address, sources }) => ({
                values: address,
                sources
              }))
            : []
        }
      ]
    ];

    return (
      <S.LegalEntityOverviewSection ref={ref} className="report-section">
        <SectionHeader title={title} isContractable={false} />
        <S.OverviewContainer
          infographicLayoutTemplate={infographicLayoutTemplate}
        >
          <S.OverviewSection>
            <S.ScreeningRiskHeader {...setterPropsForDisplayingAdditionalLabel}>
              <S.RiskIconsSectionTitle>
                Screening summary
              </S.RiskIconsSectionTitle>
            </S.ScreeningRiskHeader>
            <S.ScreeningRiskIcons {...setterPropsForDisplayingAdditionalLabel}>
              {renderScreeningRiskIcons()}
            </S.ScreeningRiskIcons>
          </S.OverviewSection>
          <S.OverviewSection>
            <S.RiskHeader {...setterPropsForDisplayingAdditionalLabel}>
              <S.RiskIconsSectionTitle>
                Media for review
              </S.RiskIconsSectionTitle>
            </S.RiskHeader>
            <S.RiskIcons {...setterPropsForDisplayingAdditionalLabel}>
              {renderRiskIcons()}
            </S.RiskIcons>
          </S.OverviewSection>
        </S.OverviewContainer>
        <BasicDetails title="Key details" details={keyDetails} />
      </S.LegalEntityOverviewSection>
    );
  }
);

export default observer(LegalEntityOverview);
