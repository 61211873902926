import React, { FC } from "react";

import { Mail, Info } from "react-feather";

import Heading from "components/atoms/Heading";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import config from "config";

import S from "./styles";

interface Props {
  message: string;
  articleId?: string;
}

const ArticleError: FC<Props> = ({ message, articleId }) => {
  const emailSubject = encodeURIComponent(
    `Article viewer failure: ${articleId}`
  );

  const onContactSupport = () => {
    window.open(
      `mailto:${config.supportEmail}?subject=${emailSubject}&body=${message}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <S.ErrorContainer>
      <S.ErrorLogoContainer>
        <Info />
      </S.ErrorLogoContainer>
      <Heading level={3}>We hit a snag with that article</Heading>
      <p>
        We’ve run into some trouble recalling the original article for that
        source. Try and refresh in a few minutes. However, if the issue
        persists, please reach out to the Customer Success team.
      </p>

      <S.Button
        onClick={onContactSupport}
        type={ButtonType.Filled}
        size={ButtonSize.Large}
        text="Customer Success"
        IconTrailing={Mail}
      />
    </S.ErrorContainer>
  );
};

export default ArticleError;
