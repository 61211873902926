import React, { useMemo, useEffect, useRef, Fragment, useState } from "react";

import { createMarkPositioner } from "remirror/extensions";
import {
  useActive,
  FloatingWrapper,
  useAttrs,
  useChainedCommands,
  useCurrentSelection,
  useMarkRange
} from "@remirror/react";

import { LinkOff, Edit } from "@mui/icons-material";
import SourceLink from "components/atoms/SourceLink";
import { ButtonKind } from "components/atoms/Button/types";

import S from "./styles";

/**
 * Ensures that the input box is only focussed on after the popover
 * in which it sits in has displayed and positioned itself.
 * Specifically, it stops auto focussing prematurely (during the layout algorithm for the popover) -
 * this will cause the window to jump to the initial position of the popover - usually (0,0).
 * @param {boolean} autoFocus - whether to autoFocus on the input box
 * @returns
 */
const DelayAutoFocusInput = ({ autoFocus, ...rest }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!autoFocus) {
      return null;
    }

    const frame = window.requestAnimationFrame(() => {
      inputRef.current?.focus();
    });

    return () => {
      window.cancelAnimationFrame(frame);
    };
  }, [autoFocus]);

  return (
    <S.Popover>
      <S.Input ref={inputRef} {...rest} />
    </S.Popover>
  );
};

const FloatingLinkToolbar = ({
  openLinkToolbar,
  setOpenLinkToolbar,
  isEditModeActive
}) => {
  const active = useActive();
  const activeLink = active.link();
  const selectedText = useMarkRange("link");
  const chain = useChainedCommands();
  const { to, from, empty } = useCurrentSelection();
  const currentAssignedUrl = useAttrs().link()?.href ?? "";
  const [urlInputValue, setUrlInputValue] = useState(currentAssignedUrl);

  // A positioner which only shows for links.
  const linkPositioner = useMemo(
    () => createMarkPositioner({ type: "link" }),
    []
  );

  // Close the link toolbar if the user changes their selection
  useEffect(() => {
    setOpenLinkToolbar(false);
  }, [from, setOpenLinkToolbar]);

  const submitHref = () => {
    setOpenLinkToolbar(false);

    if (urlInputValue === "") {
      chain.removeLink();
    } else {
      // Prepend protocol if not already present
      let updatedUrl = urlInputValue;
      if (!urlInputValue.match(/(https?:\/\/)/g)?.length) {
        updatedUrl = `https://${urlInputValue}`;
        setUrlInputValue(updatedUrl);
      }
      chain.updateLink({ href: updatedUrl, auto: false });
    }

    chain.focus(to).run();
  };

  const cancelHref = () => {
    setOpenLinkToolbar(false);
  };

  const onEditLink = () => {
    if (empty) {
      chain.selectLink();
    }
    setOpenLinkToolbar(true);
  };

  const onRemove = () => {
    chain.removeLink().focus().run();

    setUrlInputValue("");
  };

  // If the toolbar is closed, then reset the input box value to the saved url (currentAssignedUrl)
  if (!openLinkToolbar && currentAssignedUrl !== urlInputValue) {
    setUrlInputValue(currentAssignedUrl);
  }

  const linkEditButtons = activeLink ? (
    <S.LinkEditContainer>
      <S.LinkAttributesContainer>
        <S.SelectedText>{selectedText?.text}</S.SelectedText>
        <SourceLink href={currentAssignedUrl}>{currentAssignedUrl}</SourceLink>
      </S.LinkAttributesContainer>
      <S.ButtonsContainer>
        <S.EditButton kind={ButtonKind.tertiary} onClick={onEditLink}>
          <Edit />
        </S.EditButton>
        <S.RemoveButton kind={ButtonKind.tertiary} onClick={onRemove}>
          <LinkOff />
        </S.RemoveButton>
      </S.ButtonsContainer>
    </S.LinkEditContainer>
  ) : (
    <div />
  );

  return (
    <>
      {!openLinkToolbar && isEditModeActive && (
        <S.FloatingToolbar positioner={linkPositioner}>
          {linkEditButtons}
        </S.FloatingToolbar>
      )}

      <FloatingWrapper
        positioner="always"
        placement="bottom"
        enabled={openLinkToolbar}
        renderOutsideEditor
      >
        <DelayAutoFocusInput
          autoFocus
          placeholder="Enter link..."
          onChange={event => setUrlInputValue(event.target.value)}
          value={urlInputValue}
          onKeyDown={event => {
            const { code } = event;
            if (code === "Enter") {
              submitHref();
            }
            if (code === "Escape") {
              cancelHref();
            }
          }}
        />
      </FloatingWrapper>
    </>
  );
};

export default FloatingLinkToolbar;
