import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";

import { ReactComponent as BuildingSvg } from "img/building.svg";

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${grey.mid};
  padding-top: 1rem;
`;

const Building = styled(BuildingSvg)`
  transform: scale(0.8);

  path {
    fill: ${standardColors.white};
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1rem;

  & > button {
    flex: 1;
  }
`;

const S = {
  ModalContent,
  Building,
  Actions
};

export default S;
