import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

const ContentContainer = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  padding: 14px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
`;

const DarkContentContainer = styled.div<{
  maxWidth: string;
  minWidth?: string;
}>`
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth ?? "auto"};
  padding: 8px 10px;
  font-size: ${fontSize["2xs"]};
  background-color: ${standardColors.black};
  color: ${standardColors.white};
  border-radius: 6px;
  display: flex;
  gap: 11px;
`;

const S = {
  ContentContainer,
  DarkContentContainer
};

export default S;
