import http from "../../services/http";
import endpoints from "../../services/endpoints";

export default class Feature {
  get() {
    const url = endpoints.features;
    return new Promise((resolve, reject) => {
      http.get(url).then(
        result => {
          resolve(result.features);
        },
        error => {
          if (error.status === 204) {
            resolve(null);
          } else {
            reject(error);
          }
        }
      );
    });
  }
}
