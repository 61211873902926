import React, { ReactElement } from "react";

import companiesHouseLogo from "img/icons/companies-house-logo.png";
import openCorporatesLogo from "img/icons/open-corporates-logo.png";
import sayariLogo from "img/icons/sayari-logo.svg";

import { PartialDate, SourceType } from "api/report/report-types";
import { formatDate } from "util/common";
import { getDurationSinceStartDate } from "util/getDurationSinceStartDate";

import S from "./styles";

export const DATE_FIELDS = {
  datePublished: "datePublished",
  dateAccessed: "dateAccessed"
};

export const SOURCE_TYPES: {
  [key in SourceType]?: {
    id: string;
    icon: React.ReactElement | null;
    preferredDateField: string;
    publishers?: {
      [key: string]: {
        logo: React.ReactElement;
      };
    };
  };
} = {
  OnlineProfile: {
    id: "OnlineProfile",
    icon: null,
    preferredDateField: DATE_FIELDS.dateAccessed
  },
  Article: {
    id: "Article",
    icon: <S.GlobeIcon />,
    preferredDateField: DATE_FIELDS.datePublished
  },
  Webpage: {
    id: "Webpage",
    icon: <S.GlobeIcon />,
    preferredDateField: DATE_FIELDS.datePublished
  },
  CorporateRecord: {
    id: "CorporateRecord",
    icon: <S.CorporateRecordIcon />,
    preferredDateField: DATE_FIELDS.dateAccessed,
    publishers: {
      "company-information": {
        logo: (
          <S.PublisherLogo
            alt="Companies house logo"
            src={companiesHouseLogo}
          />
        )
      },
      opencorporates: {
        logo: (
          <S.PublisherLogo
            alt="Open Corporates logo"
            src={openCorporatesLogo}
          />
        )
      },
      sayari: {
        logo: <S.PublisherLogo alt="Sayari logo" src={sayariLogo} />
      }
    }
  },
  ScreeningProvider: {
    id: "ScreeningProvider",
    preferredDateField: DATE_FIELDS.dateAccessed,
    icon: null
  },
  CommercialAggregator: {
    id: "CommercialAggregator",
    preferredDateField: DATE_FIELDS.dateAccessed,
    icon: <S.CommercialAggregatorIcon />
  },
  Unknown: undefined
};

const ONLINE_PROFILE_ICONS: {
  [key: string]: ReactElement;
} = {
  linkedin: <S.LinkedInIcon />,
  twitter: <S.TwitterIcon />
};

const SCREENING_PROVIDER_ICONS: {
  [key: string]: ReactElement;
} = {
  dowjones: <S.DJIcon />
};

export const renderSourceIcon = (
  sourceType: SourceType,
  sourcePublisher?: string | null,
  iconColor?: string
) => {
  let iconToDisplay = <S.GlobeIcon />;

  if (sourceType === SOURCE_TYPES.OnlineProfile?.id) {
    if (sourcePublisher) {
      const onlineProfileIcon =
        ONLINE_PROFILE_ICONS[sourcePublisher.toLowerCase()];
      if (onlineProfileIcon) {
        iconToDisplay = onlineProfileIcon;
      }
    } else {
      iconToDisplay = <S.GlobeIcon />;
    }
  } else if (sourceType === SOURCE_TYPES.ScreeningProvider?.id) {
    iconToDisplay = (sourcePublisher &&
      SCREENING_PROVIDER_ICONS[sourcePublisher]) || <S.ScreeningProviderIcon />;
  } else {
    iconToDisplay = SOURCE_TYPES[sourceType]?.icon ?? <S.GlobeIcon />;
  }

  return <S.IconWrapper color={iconColor}>{iconToDisplay}</S.IconWrapper>;
};

export const renderSourceDate = ({
  sourceType,
  dateAccessed,
  datePublished,
  className
}: {
  sourceType: SourceType;
  dateAccessed?: PartialDate | null;
  datePublished?: PartialDate | null;
  className?: string;
}) => {
  let dateElement;
  let durationSinceElement;

  const getDateComponent = (dateLabel: string, dateObj: PartialDate) => {
    const { year, month, day } = dateObj;
    dateElement = formatDate(dateObj);
    const todaysDate = new Date();
    durationSinceElement = (
      <S.DurationSincePublished>
        {" "}
        ({dateLabel.toLowerCase()}{" "}
        {getDurationSinceStartDate({
          startDate: {
            year,
            month,
            day
          },
          endDate: {
            day: todaysDate.getDate(),
            month: todaysDate.getMonth() + 1,
            year: todaysDate.getFullYear()
          },
          excludeSuffix: false,
          includeParentheses: false
        })}
        )
      </S.DurationSincePublished>
    );

    return { dateElement, durationSinceElement };
  };

  const dateFieldForSource = SOURCE_TYPES[sourceType]?.preferredDateField;

  if (dateFieldForSource === DATE_FIELDS.datePublished && datePublished) {
    const dateComponent = getDateComponent("Published", datePublished);
    dateElement = dateComponent.dateElement;
    durationSinceElement = dateComponent.durationSinceElement;
  } else if (dateAccessed) {
    const dateComponent = getDateComponent("Collected", dateAccessed);
    dateElement = dateComponent.dateElement;
    durationSinceElement = dateComponent.durationSinceElement;
  } else {
    durationSinceElement = null;
    dateElement = <i>No date</i>;
  }

  return (
    <S.SourceDate className={className}>
      {dateElement} {durationSinceElement}
    </S.SourceDate>
  );
};
