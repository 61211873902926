import { Assertion, AssertionTemplate } from ".";
import { reportAbstract } from "./recognisers";

const exactImageAssertionTemplate = imageSrc =>
  new AssertionTemplate((isValid, isMust, testCaseId, userId, userComment) => {
    const constKey = isValid ? "const" : "not";
    const constVal = isValid ? imageSrc : { const: imageSrc };
    const arrayKey = isValid ? "contains" : "items";
    const assertionSchema = {
      type: "object",
      properties: {
        ReportAbstract: {
          type: "object",
          properties: {
            Images: {
              type: "array",
              [arrayKey]: {
                type: "object",
                properties: {
                  Url: {
                    type: "string",
                    [constKey]: constVal
                  }
                }
              }
            }
          }
        }
      }
    };
    const assertionHumanReadable = `Image "${imageSrc}" ${
      isMust ? "must" : "should"
    }${isValid ? "" : " not"} be included.`;
    return new Assertion(
      testCaseId,
      userId,
      userComment,
      isMust,
      assertionSchema,
      assertionHumanReadable
    );
  }, reportAbstract.specificImage(imageSrc));

export default exactImageAssertionTemplate;
