import { styled } from "@linaria/react";

import { ReactComponent as Rerun } from "img/icons/rerun-icon.svg";
import { red } from "styles/colors";

const MenuButton = styled.button`
  all: unset;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const RerunIcon = styled(Rerun)`
  width: 1rem;
  height: 1rem;
`;

const TitleCardContainer = styled.div`
  border: 1px solid ${red.directRiskOutline};
  border-radius: 0.75rem;
`;

const S = {
  MenuButton,
  RerunIcon,
  TitleCardContainer
};

export default S;
