import { createContext } from "react";

const ViewerModeContext = createContext({
  isViewerModeEnabled: false,
  isSharedReportLink: true,
  setIsViewerModeEnabled: (_value: boolean) => {},
  setIsSharedReportLink: (_value: boolean) => {}
});

export default ViewerModeContext;
