import React, { useEffect, useState } from "react";

import withMountTransition from "pages/define/withMountTransition";

import Loading from "components/atoms/Loading";

import S from "./styles";

interface Props {
  createEnquiryInternal: () => void;
}

const VerifyStage = ({ createEnquiryInternal }: Props) => {
  const [headingText] = useState("Creating your enquiry...");

  useEffect(() => {
    createEnquiryInternal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.StageContainer>
      <Loading />
      <S.StageHeading>{headingText}</S.StageHeading>
      <S.DetailText>
        We are preparing the system to process your enquiry, this will only take
        a few seconds.
      </S.DetailText>
    </S.StageContainer>
  );
};

export default withMountTransition(VerifyStage);
