import React, { CSSProperties, MouseEventHandler, ReactNode } from "react";

import withMountTransition from "pages/define/withMountTransition";
import Button from "components/atoms/Button";
import { ButtonKind } from "components/atoms/Button/types";

import { blue } from "styles/colors";

// @ts-ignore
import theme from "theme";

import S from "./styles";

export enum NOTIFICATION_TYPES {
  prompt = "prompt",
  info = "info"
}

interface Action {
  name: string;
  kind: ButtonKind;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

interface Props {
  notificationString: ReactNode;
  notificationType: keyof typeof NOTIFICATION_TYPES;
  notificationActions?: Action[];
  style?: CSSProperties;
  className?: string;
  isCloseable?: boolean;
  onCloseNotificationClick: () => void;
  notificationDescriptionClass?: string;
}

const Notification = ({
  notificationString,
  notificationType,
  notificationActions,
  style,
  className,
  isCloseable,
  onCloseNotificationClick,
  notificationDescriptionClass
}: Props) => {
  const renderIcon = () => {
    switch (notificationType) {
      case NOTIFICATION_TYPES.info:
        return <S.InformationIcon />;
      case NOTIFICATION_TYPES.prompt:
        return theme.notification?.logo ?? <S.XapienLogo />;
      default:
        return null;
    }
  };

  const renderNotificationActions = () => {
    return (
      <S.ActionsContainer>
        {notificationActions?.map(action => {
          if (action.kind === "primary") {
            return (
              <S.PrimaryButton
                key={action.name}
                kind={action.kind}
                onClick={action.onClick}
                backgroundColor={theme.alternativePrimaryColor}
                boxShadowColor={theme.button?.alternativeBoxShadowColor}
              >
                {action.name}
              </S.PrimaryButton>
            );
          }
          if (action.kind === "secondary") {
            return (
              <S.SecondaryButton
                key={action.name}
                kind={action.kind}
                onClick={action.onClick}
                borderColor={theme.alternativePrimaryColor}
                boxShadowColor={theme.button?.alternativeBoxShadowColor}
              >
                {action.name}
              </S.SecondaryButton>
            );
          }
          return (
            <Button
              key={action.name}
              kind={action.kind}
              onClick={action.onClick}
            >
              {action.name}
            </Button>
          );
        })}
      </S.ActionsContainer>
    );
  };

  const renderCloseButton = () => {
    return (
      <S.CloseButton onClick={onCloseNotificationClick}>
        <S.CloseIcon />
      </S.CloseButton>
    );
  };

  const getNotificationColor = () => {
    switch (notificationType) {
      case NOTIFICATION_TYPES.info:
        return "#ffd268";
      case NOTIFICATION_TYPES.prompt:
        return theme.alternativePrimaryColor ?? blue.xapienLight;
      default:
        return null;
    }
  };

  return (
    <S.InlineNotification
      notificationColor={getNotificationColor()}
      style={style}
      className={className}
    >
      {renderIcon()}
      <S.NotificationDescription className={notificationDescriptionClass}>
        {notificationString}
      </S.NotificationDescription>
      {notificationActions && notificationActions.length
        ? renderNotificationActions()
        : null}
      {isCloseable && renderCloseButton()}
    </S.InlineNotification>
  );
};

export default withMountTransition(Notification);
