import React, { useState, memo } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import ErrorBoundary from "util/ErrorBoundary";

export const SortFilterPill = memo(props => {
  const { children, reset, title, active, canReset, onOpenChanged } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleMenuOpen = () => {
    if (onOpenChanged) {
      onOpenChanged(!dropdownOpen);
    }
    setDropdownOpen(!dropdownOpen);
  };

  if (!(children && children.length)) {
    console.warn(`Nothing in sort-filter pill, not showing`, { title, active });
    return "";
  }

  return (
    <div className="sort-filter-pill-container">
      <Dropdown isOpen={dropdownOpen} toggle={toggleMenuOpen} inNavbar={true}>
        <DropdownToggle
          className={`sort-filter-pill ${
            active
              ? " sort-filter-pill-filled"
              : dropdownOpen
              ? " sort-filter-pill-open"
              : ""
          }`}
        >
          {title}
        </DropdownToggle>
        <DropdownMenu className="sort-filter-dropdown-menu">
          <ErrorBoundary
            FallbackComponent={FilterPillErrorComponent}
            onError={e => console.error("Error rendering filter", { e, title })}
          >
            {children}
            <DropdownItem divider />
            <div className="sort-filter-dropdown-menu-bottom-button-container">
              <button disabled={!canReset} onClick={reset}>
                Reset
              </button>
              <button onClick={toggleMenuOpen}>Done</button>
            </div>
          </ErrorBoundary>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
});

const FilterPillErrorComponent = () => {
  return (
    <div>
      Sorry there was an error constructing this filter, try refreshing the page
      or using another browser
    </div>
  );
};
