import React, { useContext, useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Resizable } from "re-resizable";

import {
  SCREENING_FILTERS,
  SCREENING_LIST_TYPES,
  ORGANISATION_SCREENING_FILTERS,
  DOW_JONES_ORGANISATION_SCREENING_FILTERS
} from "util/screeningUtils";
import {
  getConfirmedExplainerTextElement,
  getForReviewExplainerTextElement
} from "components/molecules/InfoGraphic/ScreeningInfoGraphic/utils";
import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";
import WithInspector from "components/organisms/WithInspector";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import { REPORT_TYPES } from "util/reportTypes";
import { getAgeFromDob } from "util/getAgeFromDob";
import { formatDate, scrollToTopOfComponent } from "util/common";
import { InfographicStateContext } from "util/context/InfographicStateContext";
import ReactTooltip from "components/atoms/ReactTooltip";
import SourceCard from "components/molecules/SourceList/SourceCard";
import { red, grey } from "styles/colors";
import { ButtonKind } from "components/atoms/Button/types";
import Pages, { DIRECTION } from "components/molecules/Pages";
import BackButton from "components/atoms/BackButton";
import { isPDX } from "static-config";
import { orderByDateAndStatus } from "util/orderByDateAndStatus";

import S, { classNameOverrides } from "./styles";

const animateProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 }
};

const ScreeningInfoGraphic = ({
  confirmedScreeningData,
  confirmedScreeningCount,
  unconfirmedScreeningCount,
  listType,
  entityType = REPORT_TYPES.organisation,
  sectionToJumpToRef,
  CustomIcon,
  customInspectorHeader,
  customInspectorContent,
  customConfirmedExplainerText,
  customUnconfirmedExplainerText,
  listLabel,
  displaySummaryView,
  displayLinkToScreeningSection = true
}) => {
  const [isInspectorOpen, setIsInspectorOpen] = useState(false);

  const {
    displayAdditionalLabel,
    setIsAnyInfographicInspectorOpen,
    setDisplayAdditionalLabel
  } = useContext(InfographicStateContext);

  const [screeningListingsCurrentPage, setScreeningListingsCurrentPage] =
    useState(0);

  const [selectedScreeningSummaryData, setSelectedScreeningSummaryData] =
    useState();

  const pagesCompRef = useRef();

  const matchesCount = confirmedScreeningCount ?? 0;

  let matchStrengthString;
  if (confirmedScreeningCount > 0) {
    matchStrengthString = CONFIDENCE_CATEGORIES.confirmed;
  } else if (unconfirmedScreeningCount > 0) {
    matchStrengthString = CONFIDENCE_CATEGORIES.unconfirmed;
  } else {
    matchStrengthString = CONFIDENCE_CATEGORIES.discarded;
  }

  const screeningString =
    matchesCount !== 1 || !SCREENING_FILTERS[listType]?.labelSingular
      ? SCREENING_FILTERS[listType].label
      : SCREENING_FILTERS[listType].labelSingular;

  const entityTypeString =
    entityType === REPORT_TYPES.person ? "people" : "organisations";

  const hasConfirmedMatches = confirmedScreeningCount > 0;

  const toggleScreeningListView = direction => {
    pagesCompRef.current?.switchPageByDirection(direction);
  };

  const renderPopoverSubtext = () => {
    let text;

    if (confirmedScreeningCount > 0) {
      text =
        customConfirmedExplainerText ??
        getConfirmedExplainerTextElement(listType, confirmedScreeningCount);
    } else if (unconfirmedScreeningCount > 0) {
      text =
        customUnconfirmedExplainerText ??
        getForReviewExplainerTextElement(
          listType,
          unconfirmedScreeningCount,
          entityType,
          confirmedScreeningCount > 0
        );
    } else {
      text = `Xapien has not located any ${entityTypeString} that have matched or bear a resemblance to your subject.`;
    }

    const showDescriptionText = () => {
      if (isPDX && entityType === REPORT_TYPES.organisation) {
        return DOW_JONES_ORGANISATION_SCREENING_FILTERS[listType].description;
      }
      if (entityType === REPORT_TYPES.person) {
        return (
          <>
            {text} {SCREENING_FILTERS[listType].description}
          </>
        );
      }
      return (
        <>
          {text} {ORGANISATION_SCREENING_FILTERS[listType].description}
        </>
      );
    };

    return (
      <AnimatePresence initial={false} exitBeforeEnter>
        {
          [
            <motion.div {...animateProps} key="screening summaries">
              {showDescriptionText(text)}
              {displayLinkToScreeningSection ? (
                <S.SectionLinkText>
                  For full details, go to the{" "}
                  <S.SectionLinkButton
                    kind={ButtonKind.tertiary}
                    onClick={() =>
                      scrollToTopOfComponent(sectionToJumpToRef, true)
                    }
                  >
                    Screening section
                  </S.SectionLinkButton>
                  .
                </S.SectionLinkText>
              ) : null}
            </motion.div>,
            <BackButton
              animationProps={animateProps}
              onClick={() => toggleScreeningListView(DIRECTION.backward)}
              label={<b>Back</b>}
            />
          ][screeningListingsCurrentPage]
        }
      </AnimatePresence>
    );
  };

  const renderIcon = () => {
    const iconColor = hasConfirmedMatches ? red.directRiskOutline : grey.mid;

    if (listType === SCREENING_LIST_TYPES.sanctions) {
      return CustomIcon ? (
        <CustomIcon color={iconColor} />
      ) : (
        <S.SanctionsIcon color={iconColor} />
      );
    }

    if (listType === SCREENING_LIST_TYPES.watchlists) {
      return CustomIcon ? (
        <CustomIcon color={iconColor} />
      ) : (
        <S.WatchlistsIcon color={iconColor} />
      );
    }

    if (listType === SCREENING_LIST_TYPES.peps) {
      return CustomIcon ? (
        <CustomIcon color={iconColor} />
      ) : (
        <S.PepsIcon color={iconColor} />
      );
    }

    return <CustomIcon color={iconColor} />;
  };

  const renderListSummary = entity => {
    const count = entity?.listings?.length;
    const activeCount = entity?.listings?.filter(
      list => list.isKnownToBeOngoing
    )?.length;

    if (listType === SCREENING_LIST_TYPES.peps) {
      return (
        <div>
          {count} PEP marker{count !== 1 ? "s" : ""}
        </div>
      );
    }
    return (
      <div>
        {count} {SCREENING_FILTERS[listType]?.labelSingular}
        {count !== 1 ? "s" : ""} ({activeCount} active)
      </div>
    );
  };

  const renderList = entity => {
    const orderedList = orderByDateAndStatus(entity?.listings);

    return (
      <S.Lists>
        {orderedList?.map(list => {
          return (
            <S.ListItem key={list.listName}>
              <S.ListName>
                {list.listName}{" "}
                {list.isKnownToBeOngoing ? (
                  <S.ListStatus>
                    – <S.ListStatusLabel>Active</S.ListStatusLabel>
                  </S.ListStatus>
                ) : null}
              </S.ListName>
              <S.ListDate>
                {getDurationStringFromDates({
                  startDate: list.startDate,
                  endDate: list.endDate,
                  isKnownToBeOngoing: list.isKnownToBeOngoing
                })}
              </S.ListDate>
            </S.ListItem>
          );
        })}
      </S.Lists>
    );
  };

  const renderScreeningSummaryInfo = screeningList => {
    if (!screeningList?.length) {
      return null;
    }

    return (
      <Resizable
        handleStyles={{ bottom: { bottom: -25 } }}
        bounds="window"
        minHeight={275}
        defaultSize={{ height: 240 }}
        enable={{
          top: false,
          right: false,
          bottom: true,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }}
      >
        <Pages
          ref={pagesCompRef}
          onPageChange={currentPage =>
            setScreeningListingsCurrentPage(currentPage)
          }
          pages={[
            <S.ScreeningSummaryInfo>
              {screeningList?.map((item, index) => {
                return (
                  <S.InfoSummaryRow
                    interactive={displaySummaryView}
                    onClick={() => {
                      setSelectedScreeningSummaryData(item);
                      toggleScreeningListView(DIRECTION.forward);
                    }}
                    key={index}
                    heading={item.entityName}
                    content={
                      <>
                        <S.PersonDetails>
                          {item.relation && (
                            <div>
                              <S.RelationLabel>Relationship:</S.RelationLabel>{" "}
                              <S.RelationLabel>{item.relation}</S.RelationLabel>
                            </div>
                          )}
                          {entityType === REPORT_TYPES.person && (
                            <div>
                              {item.nationality?.length
                                ? item.nationality.join(", ")
                                : ""}
                              {item.dateOfBirth?.year
                                ? ` ${
                                    item.nationality?.length ? "–" : ""
                                  } ${formatDate(
                                    item.dateOfBirth
                                  )} (${getAgeFromDob(
                                    new Date(
                                      item.dateOfBirth?.year,
                                      (item.dateOfBirth?.month ?? 1) - 1,
                                      item.dateOfBirth?.day
                                    )
                                  )} yrs)`
                                : null}
                            </div>
                          )}
                        </S.PersonDetails>
                        {displaySummaryView
                          ? renderListSummary(item)
                          : renderList(item)}
                      </>
                    }
                  />
                );
              })}
            </S.ScreeningSummaryInfo>,
            <S.InfoSummaryRow
              content={renderList(selectedScreeningSummaryData)}
            />
          ]}
        />
      </Resizable>
    );
  };

  const renderTooltipExplainer = () => {
    return (
      <div>
        <div>
          {customConfirmedExplainerText ??
            getConfirmedExplainerTextElement(listType, confirmedScreeningCount)}
        </div>
        {unconfirmedScreeningCount > 0 ? (
          <S.TooltipExplainerSection>
            {customUnconfirmedExplainerText ??
              getForReviewExplainerTextElement(
                listType,
                unconfirmedScreeningCount,
                entityType,
                confirmedScreeningCount > 0
              )}
          </S.TooltipExplainerSection>
        ) : null}
        {confirmedScreeningCount > 0 || unconfirmedScreeningCount > 0 ? (
          <S.TooltipExplainerSection>
            Full details can be found in the Screening section below.
          </S.TooltipExplainerSection>
        ) : null}
      </div>
    );
  };

  return (
    <WithInspector
      disabled={!confirmedScreeningCount}
      popoverTitle={
        customInspectorHeader ??
        `${confirmedScreeningCount} ${matchStrengthString} ${screeningString}`
      }
      customContent={
        customInspectorContent ??
        renderScreeningSummaryInfo(confirmedScreeningData)
      }
      dataFormat={INSPECTOR_DATA_FORMATS.custom}
      popoverSubtext={renderPopoverSubtext()}
      isInspectorOpen={value => {
        setIsInspectorOpen(value);
        setIsAnyInfographicInspectorOpen(value);
        setDisplayAdditionalLabel(value);
        if (!value) setScreeningListingsCurrentPage(0);
      }}
      renderSourceItems={(sources, selectedFilter, sourceContainerRef) => {
        return (
          <S.SourceItemsContainer ref={sourceContainerRef}>
            {sources?.map(source => (
              <SourceCard
                key={source.url}
                source={source}
                selectedFilter={selectedFilter}
              />
            ))}
          </S.SourceItemsContainer>
        );
      }}
      filterPillColor={red.directRisk}
      pillClassName={classNameOverrides.riskPill}
    >
      <ReactTooltip
        disabled={isInspectorOpen}
        delay={600}
        maxWidth="400px"
        tooltip={<div>{renderTooltipExplainer()}</div>}
      >
        <S.GraphicContainer
          aria-label={`Open ${listLabel} summary`}
          isConfirmedEntriesPresent={hasConfirmedMatches}
        >
          {renderIcon()}
          <S.GraphicHeader>{matchesCount || 0}</S.GraphicHeader>
          <S.GraphicSubtext>
            <S.ScreeningGraphicSubtext>{listLabel}</S.ScreeningGraphicSubtext>
            <S.AdditionalLabel
              isOnDisplay={displayAdditionalLabel || isInspectorOpen}
              isInspectorOpen={isInspectorOpen && hasConfirmedMatches}
            >
              {`+${unconfirmedScreeningCount ?? 0} for review`}
            </S.AdditionalLabel>
          </S.GraphicSubtext>
        </S.GraphicContainer>
      </ReactTooltip>
    </WithInspector>
  );
};

export default ScreeningInfoGraphic;
