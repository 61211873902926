/**
 * Base class for Assertions, ready to be inserted into the database
 * @param {string}  testCaseId
 * @param {string}  userId
 * @param {string}  userComment
 * @param {boolean} isMust
 * @param {object}  assertionSchema
 * @param {string}  assertionHumanReadable
 * @return {Assertion}
 */
export class Assertion {
  constructor(
    testCaseId,
    userId,
    userComment,
    isMust,
    assertionSchema,
    assertionHumanReadable
  ) {
    this.TestCaseId = testCaseId;
    this.AssertionComment = userComment;
    this.UserId = userId;
    this.IsMust = isMust;
    this.AssertionSchema = assertionSchema;
    this.AssertionHumanReadable = assertionHumanReadable;
  }
}

/**
 * Passed to the "Assertable" component to build and recognise Assertions
 * @param {function} buildAssertion
 * @param {function} recogniseAssertion
 * @return {AssertionTemplate}
 */
export class AssertionTemplate {
  constructor(buildAssertion, recogniseAssertion) {
    /**
     * Builds an Assertion with the provided parameters
     * @param {string}  testCaseId
     * @param {string}  userId
     * @param {string}  userComment
     * @param {boolean} isMust
     * @param {object}  assertionSchema
     * @param {string}  assertionHumanReadable
     *
     * @return {Assertion}
     */
    this.buildAssertion = buildAssertion;
    /**
     * Recognises an Assertion by inspecting its assertionSchema
     *
     * @param {Assertion} assertion
     * @return {boolean}
     */
    this.recogniseAssertion = recogniseAssertion;
  }
}

export const comparisonTextMap = {
  const: "exactly",
  minimum: "more than",
  maximum: "fewer than"
};

export const camelToPascal = str => str[0].toUpperCase() + str.substring(1);
