import React, { FC, SVGProps, useState } from "react";
import { Switch } from "@mui/material";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";

import { Person, PersonOff } from "@mui/icons-material";
import { useAuthentication } from "util/hooks/useAuthentication";
import { ReactComponent as CrossedBuilding } from "img/icons/crossed-building-icon.svg";
import { orange } from "styles/colors";

import ModalSection from "components/atoms/ModalSection";
import ModalSectionHeader from "components/atoms/ModalSectionHeader";
import Heading from "components/atoms/Heading";
import useOrganisationPreferences from "util/hooks/useOrganisationPreferences";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";

import Avatar from "components/atoms/Avatar";
import ErrorBanner from "components/atoms/ErrorBanner";
import { AvatarSize } from "components/atoms/Avatar/types";
import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";
import { Report, ReportStatus, ReportType } from "api/reports/types";
import S from "./styles";
import useUpdateMonitoring from "./useUpdateMonitoring";

interface Props {
  isOpen: boolean;
  id: string;
  collectionId: string;
  toggleOpen: () => void;
  subject: string;
  context: string;
  showScreening?: boolean;
  showMonitoring?: boolean;
  status: Report["status"];
  enteringAdverseMediaState: boolean;
  enteringSanctionsState: boolean;
  typeOfOrganisation: ReportType;
}

const MonitoringModal = ({
  isOpen,
  id,
  collectionId,
  toggleOpen,
  showMonitoring = false,
  showScreening = false,
  subject,
  status,
  typeOfOrganisation,
  context,
  enteringAdverseMediaState,
  enteringSanctionsState
}: Props) => {
  const {
    state: { status: authStatus }
  } = useAuthentication();

  const { dispatch } = useCollectionList();

  const {
    fetching,
    changeMonitoringPermission,
    error,
    errorMessage,
    resetError
  } = useUpdateMonitoring();

  const [adverseMediaState, setAdverseMediaState] = useState(
    enteringAdverseMediaState
  );
  const [sanctionsState, setSanctionsState] = useState(enteringSanctionsState);

  const { loaded, initialisePreferences } = useOrganisationPreferences();

  if (!loaded && authStatus === AuthenticationStatus.authenticated) {
    initialisePreferences();
  }

  const onCloseModal = (save = false) => {
    if (
      save &&
      (adverseMediaState !== enteringAdverseMediaState ||
        sanctionsState !== enteringSanctionsState)
    ) {
      changeMonitoringPermission(id, adverseMediaState, sanctionsState).then(
        (success: boolean) => {
          if (success) {
            toggleOpen();
            dispatch({
              type: CollectionListActions.updateCollectionItem,
              id: collectionId,
              item: {
                id,
                monitoring: {
                  adverseMedia: adverseMediaState,
                  sanctions: sanctionsState
                }
              }
            });
          }
        }
      );
    } else {
      toggleOpen();
      resetError();
    }
  };

  const getCardIcon = () => {
    if (status === ReportStatus.failed) {
      if (typeOfOrganisation === ReportType.Organisation) {
        return <CrossedBuilding />;
      }
      return <PersonOff fontSize="large" />;
    }

    if (typeOfOrganisation === ReportType.Organisation) {
      return <S.BuildingIcon />;
    }
    return <Person fontSize="large" />;
  };

  const renderMonitoring = () => {
    return (
      <ModalSection>
        <ModalSectionHeader>
          <Heading level={5}>Adverse Media Alerts</Heading>
          <Switch
            checked={adverseMediaState}
            onChange={() => setAdverseMediaState(!adverseMediaState)}
          />
        </ModalSectionHeader>
        <S.TextWrapper>
          <Heading level={6}>
            Receive adverse media alerts for this subject.
          </Heading>
          <S.Text>
            Averse media alerts inform a report’s owner monthly by email of any
            adverse media related to subject of a report.
          </S.Text>
        </S.TextWrapper>
      </ModalSection>
    );
  };
  const renderScreening = () => {
    return (
      <ModalSection>
        <ModalSectionHeader>
          <Heading level={5}>Screening alerts</Heading>
          <Switch
            checked={sanctionsState}
            onChange={() => setSanctionsState(!sanctionsState)}
          />
        </ModalSectionHeader>
        <S.TextWrapper>
          <Heading level={6}>Receive screening alerts for this subject</Heading>
          <S.Text>
            Screening alerts inform a report’s owner daily by email of any
            change in PEP, sanction or watchlist matches related to the subject
            of a report.
          </S.Text>
        </S.TextWrapper>
      </ModalSection>
    );
  };
  const renderUserHeader = () => {
    return (
      <ModalSection>
        <S.HeaderWrapper>
          <S.AvatarIconContainer>
            <Avatar
              avatarSize={AvatarSize.Large}
              hasBorder
              background={
                status === ReportStatus.failed
                  ? orange.indirectRiskFill
                  : undefined
              }
            >
              {getCardIcon()}
            </Avatar>
            {status !== ReportStatus.ready && status !== ReportStatus.failed ? (
              <S.LoadingBar />
            ) : null}
          </S.AvatarIconContainer>
          <S.MonitoringModalSectionHeader>
            <Heading level={5}>{subject}</Heading>
            <S.Text>{context}</S.Text>
          </S.MonitoringModalSectionHeader>
        </S.HeaderWrapper>
      </ModalSection>
    );
  };

  return (
    <ModalContainer
      title="Xapien Monitoring"
      icon
      onExitClick={() => onCloseModal()}
      isOpen={isOpen}
      toggleOpen={() => onCloseModal()}
    >
      <S.ModalContent>
        {renderUserHeader()}
        {showScreening && renderScreening()}
        {showMonitoring && renderMonitoring()}
      </S.ModalContent>
      <S.Button
        text="Done"
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={() => onCloseModal(true)}
        disabled={fetching}
        IconTrailing={
          fetching
            ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
            : undefined
        }
      />
      {error && (
        <ErrorBanner
          text={errorMessage || "Something went wrong. Try again in a moment."}
          onClick={resetError}
        />
      )}
    </ModalContainer>
  );
};

export default MonitoringModal;
