import { styled } from "@linaria/react";

import { blue, red, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

const Button = styled.button`
  background: none;
  border-width: 1px;
  border-style: solid;
  transition: color 0.2s, opacity 0.2s, border-color 0.2s, box-shadow 0.2s,
    background-color 0.2s;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "default")};

  &:focus {
    outline: none;
  }
`;

const PrimaryButton = styled(Button)<{
  backgroundColor?: string;
  boxShadowColor?: string;
  borderColor?: string;
}>`
  color: ${props => props.color ?? blue.xapienDark};
  border-color: transparent;
  padding: 0 10px;
  border-radius: 18px;
  min-height: 36px;
  background-color: ${props => props.backgroundColor ?? blue.xapienLight};
  border: 2px solid transparent;
  min-width: 80px;

  &:active {
    border: 2px solid ${standardColors.white};
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgba(40, 239, 250, 0.33)"};
  }
`;

const SecondaryButton = styled(Button)<{
  borderColor?: string;
  boxShadowColor?: string;
}>`
  color: ${standardColors.white};
  border-color: ${props => props.borderColor ?? blue.xapienLight};
  background-color: transparent;
  padding: 0 10px;
  border-radius: 18px;
  min-height: 36px;
  min-width: 80px;

  &:active {
    border: 2px solid ${standardColors.white};
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgba(40, 239, 250, 0.33)"};
  }
`;

const TertiaryButton = styled(Button)<{ color?: string }>`
  font-size: ${fontSize.md};
  color: ${props => props.color ?? blue.xapienLight};
  background: none;
  border: none;
  padding: 0;
  border-bottom: 1px solid transparent;

  transition: border-color 0.2s;

  &:hover,
  &:focus {
    outline: none;
    border-bottom: 1px solid ${props => props.color ?? blue.xapienLight};
  }
`;

const DangerButton = styled(Button)`
  color: ${standardColors.white};
  border-color: transparent;
  padding: 0 10px;
  border-radius: 18px;
  min-height: 36px;
  background-color: ${red.directRisk};
  border: 2px solid transparent;
  min-width: 80px;

  &:active {
    border: 2px solid ${standardColors.white};
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px
      rgba(
        ${() => {
          const { r, g, b } = hexToRgb(red.directRisk);
          return `${r}, ${g}, ${b}`;
        }},
        0.25
      );
  }
`;

const S = {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  DangerButton
};

export default S;
