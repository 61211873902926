import { grey } from "styles/colors";
import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { ReactComponent as BuildingSvg } from "img/building.svg";

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 100px;
  border: 1px solid ${grey.ghost};
  padding: 8px;
`;

/** ClassNameOverrides */

const textField = css`
  /* clears the ‘X’ from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
`;

const Building = styled(BuildingSvg)`
  transform: scale(0.8);
`;

export const classNameOverrides = {
  textField
};

const S = { InputContainer, Building };

export default S;
