import config from "config";

const tenantImageMap: Record<string, boolean> = {
  projectdx: true,
  default: false
};

// Some tenants don't want to see images for which
// we lack the appropriate copyrights.
export const doesTenantProhibitImages = () => {
  return tenantImageMap[config.tenant];
};
