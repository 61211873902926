import React, { FC } from "react";

import { User } from "react-feather";

import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

interface Props {
  onClose: () => void;
  emails: string[];
}

const InvitesComplete: FC<Props> = ({ emails, onClose }) => {
  return (
    <S.Container>
      <S.Title level={6}>
        An email invitation to join Xapien has been sent to
      </S.Title>

      <S.Emails>
        {emails.map(email => (
          <S.Email key={`InvitesComplete-${email}`}>
            <User />
            {email}
          </S.Email>
        ))}
      </S.Emails>

      <ButtonNew
        onClick={onClose}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        text="OK"
      />
    </S.Container>
  );
};

export default InvitesComplete;
