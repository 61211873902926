import { useContext } from "react";

import { PopoverModalContext } from "./context";

const usePopoverModal = () => {
  const context = useContext(PopoverModalContext);

  if (!context) {
    return null;
  }

  return context;
};

export default usePopoverModal;
