// TODO DJ
/* eslint-disable max-len */
import React from "react";

import { ReactComponent as Building } from "img/building.svg";
import { ReactComponent as Person } from "img/person.svg";
import { ReactComponent as RelativeOrCloseAssociate } from "themes/projectdx/img/rca-icon.svg";
import { ENTITY_TYPE } from "pages/define/utils";

import S from "themes/projectdx/ThemedScreening/styles";

export const RELATIVE_OR_CLOSE_ASSOCIATE = "rca";

export const FILTER_TYPES = {
  confirmed: "confirmed",
  unconfirmed: "unconfirmed",
  disregarded: "discarded",
  userDiscarded: "userDiscarded"
};

export const getEntityTypeImage = (entityType, iconClass) => {
  if (entityType === ENTITY_TYPE.Person) {
    return <Person className={iconClass} />;
  }
  if (entityType === ENTITY_TYPE.Organisation) {
    return <Building className={iconClass} />;
  }
  if (entityType === RELATIVE_OR_CLOSE_ASSOCIATE) {
    return <RelativeOrCloseAssociate className={iconClass} />;
  }
  return null;
};

export const dJToolTipText = {
  confimed: {
    title: "Precise match",
    subheading: "Xapien is confident that these relate to your subject",
    text:
      "Xapien is confident these are your subject. Xapien identified " +
      "content that is confirmed to be the subject of investigation. There " +
      "are likely multiple pieces of data corroborating this data is highly " +
      "relevant to the subject."
  },
  unconfirmed: {
    title: "Near match",
    subheading:
      "Xapien could not determine with confidence whether these relate to your subject",
    text:
      "Xapien identified content that could potentially be the subject " +
      "of the investigation, but it was not possible to confirm through " +
      "automated processing.\n\nThis content should be checked as part of a " +
      "FCC/ABC/AML process."
  },
  discarded: {
    title: "Broad match",
    subheading: "Xapien has low confidence that these relate to your subject",
    text:
      "Xapien identified content that is unlikely to be the subject of the investigation, " +
      "but could reasonably have been subject to data quality or algorithmic issues within Xapien " +
      "or one of the underlying data providers.\n\nThis content should be checked as part of a FCC/ABC/AML."
  },
  userDiscarded: {
    title: "User discarded",
    subheading: "A user has determined these do not relate to your subject",
    text: "Content discarded by the user"
  }
};

export const entityMasonryBreakpoints = {
  default: 2,
  700: 1
};

export const getExplainerText = key => {
  // Preprocess state owned key (may contain country)
  if (key.toLowerCase().trim().includes("state owned")) {
    return "state owned";
  }

  const mapping = {
    birthplace: "The country where the individual was born.",
    citizenship: "The country an individual is reported to be a citizen of.",
    residence:
      "Where an individual is reported to be a resident. This refers to physical residency rather than fiscal.",
    jurisdiction:
      "The country that has entrusted a Politically Exposed Person (PEP) with political roles, in other words, the government of the country for which an individual works or has worked in their capacity as a PEP.",
    reportedallegation:
      "Where the reported criminal activity took place and/or the country that has criminal jurisdiction for the alleged offence.",
    affiliation:
      "Where an entity is based or operates as suggested by a regulatory source.",
    registration:
      "The country where an entity is officially registered or incorporated.",
    "current ownership":
      "The country in which the government currently controls the shares, voting rights or board appointments.",
    "past ownership":
      "The country in which the government previously controlled the shares, voting rights or board appointments.",
    "enhanced risk":
      "The country that prompted the addition of the Enhanced Country Risk category to the profile.",
    "sanctions lists":
      "Lists of persons and entities that are subject to comprehensive or targeted restrictive measures for a number of reasons, including: having links to terrorism, terrorist financing, the proliferation of weapons of mass destruction, arms trafficking, narco-trafficking or war crimes.",
    watchlists:
      "Includes persons or entities associated with one or more of the following categories of potentially high-risk activities: advisories, enforcement orders, visa/ travel ban, exclusion list, law enforcement, trade restrictions, war crimes, warnings.",
    "related to sanctioned party":
      "Companies owned or controlled by individuals, entities, countries or regions sanctioned by the U.S. Department of the Treasury's Office of Foreign Assets Control (OFAC) and/or the European Union.",
    "state owned": (
      <div>
        <div>
          Business organisations with a corporate structure for which the
          supranational, national, regional, provincial or local government
          (including governmental departments and agencies as well as
          international organisations):
        </div>
        <S.List>
          <li>
            Holds 1 percent or more of the issued shares in the enterprise
          </li>
          <li>
            Holds majority voting control (such as through a “golden share”) and
            thus has the power to veto shareholder decisions of the enterprise
          </li>
          <li>
            Has the power to appoint a majority of the members of the
            enterprise&apos;s administrative, managerial or supervisory bodies
          </li>
        </S.List>
      </div>
    ),
    "politically exposed person":
      "Senior ranking public officials, including government officials, politicians and their relatives or close associates.",
    "other exclusion lists":
      "Lists that tend to have an anti-corruption focus and impose specific exclusions from public sector activities against people and/or entities operating in a particular jurisdiction, sector or industry, usually for a set period of time.",
    "relative or close associate":
      "Immediate family members or closest non-family associates of a politically exposed person.",
    "special interest person":
      "This content set contains profiles of individuals formally accused of, arrested for or convicted of serious crime within six categories: Corruption, Financial Crime, Trafficking, Organised Crime, Terror, Tax Crime.",
    "special interest entity":
      "Includes entities that have at least one current Sanction List, Other Official List or Other Exclusion List reference.",
    "adverse media":
      "R&C analysts have identified adverse media relating to this company. Full details coming soon…"
  };

  return mapping[key.toLowerCase()];
};
