import React from "react";

import { ENTITY_TYPE } from "pages/define/utils";
import { EntityType } from "api/enquiry/types";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  searchType: EntityType;
}

const VerifySpinner = ({ searchType }: Props) => (
  <S.VerifyingFooterElements>
    <S.VerifyingSpinner color={theme.alternativePrimaryColor} />
    <S.CheckingEnquiryText color={theme.alternativePrimaryColor}>
      {searchType === ENTITY_TYPE.Organisation
        ? "Locating the organisation’s online presence"
        : "Checking your enquiry"}
    </S.CheckingEnquiryText>
  </S.VerifyingFooterElements>
);

export default VerifySpinner;
