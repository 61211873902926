import React, { useEffect, useState } from "react";
import { NavItem } from "reactstrap";

import ReactTooltip from "components/atoms/ReactTooltip";
import LegacyReportApi from "api/report/Report";
import { RefreshCw } from "react-feather";
import S from "./styles";

enum NewReportState {
  idle = "idle",
  success = "success",
  loading = "loading",
  error = "error"
}

interface Props {
  enquiryId: string;
}

const GenerateERReportButton = ({ enquiryId }: Props) => {
  const [newReportState, setNewReportState] = useState(NewReportState.idle);

  const isLoading = newReportState === NewReportState.loading;

  useEffect(() => {
    if (newReportState !== NewReportState.success) return;
    if (!enquiryId) return;
    window.open(`/report/preparing/${enquiryId}`, "_blank");
  }, [newReportState, enquiryId]);

  const onGenerateERReport = async () => {
    setNewReportState(NewReportState.loading);
    const api = new LegacyReportApi();

    try {
      // awaited so we can use the status synchronously in next step.
      const { status } = await api.generateERReport(enquiryId);

      if (status) {
        setNewReportState(NewReportState.success);
      } else {
        setNewReportState(NewReportState.error);
      }
    } catch (error) {
      console.error(error);
      setNewReportState(NewReportState.error);
    }
  };

  return (
    <NavItem onClick={() => onGenerateERReport()}>
      <S.GenerateERButtonContainer>
        <ReactTooltip
          tooltip="Generate ER report"
          open={undefined}
          style={undefined}
          className={undefined}
        >
          <S.GenerateERButton
            disabled={isLoading}
            aria-label="Generate ER report"
          >
            <RefreshCw />
          </S.GenerateERButton>
        </ReactTooltip>
      </S.GenerateERButtonContainer>
    </NavItem>
  );
};

export default GenerateERReportButton;
