import { reportSourcing } from "./recognisers";
import { Assertion, AssertionTemplate } from ".";

const exactSourceUrlAssertionTemplate = (url, sourceType) =>
  new AssertionTemplate((isValid, isMust, testCaseId, userId, userComment) => {
    // sourceType can be "Relevant", "NonRelevant", "NotProcessed" (will be a dropdown)
    const sourceTypeKey = sourceType + "SourceGroups";

    const arrayKey = isValid ? "contains" : "items";
    const constKey = isValid ? "const" : "not";
    const constVal = isValid ? url : { const: url };

    let assertionSchema;

    function reportSourcingProperty(arrayKey, constKey, constVal) {
      return {
        type: "array",
        [arrayKey]: {
          type: "object",
          properties: {
            SourceStacks: {
              type: "array",
              [arrayKey]: {
                type: "object",
                properties: {
                  Sources: {
                    type: "array",
                    [arrayKey]: {
                      type: "object",
                      properties: {
                        Url: {
                          type: "object",
                          properties: {
                            FullUrl: {
                              type: "string",
                              [constKey]: constVal
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      };
    }

    if (!isValid) {
      assertionSchema = {
        type: "object",
        properties: {
          ReportSourcing: {
            type: "object",
            properties: {
              [sourceTypeKey]: reportSourcingProperty(
                arrayKey,
                constKey,
                constVal
              )
            }
          }
        }
      };
    } else {
      assertionSchema = {
        type: "object",
        properties: {
          ReportSourcing: {
            type: "object",
            properties: {}
          }
        }
      };

      const altSourceTypeKeys = () => {
        switch (sourceType) {
          case "Relevant":
            return ["NonRelevantSourceGroups", "NotProcessedSourceGroups"];
          case "NonRelevant":
            return ["RelevantSourceGroups", "NotProcessedSourceGroups"];
          default:
            return ["RelevantSourceGroups", "NonRelevantSourceGroups"];
        }
      };

      const altArrayKey = isValid ? "items" : "contains";
      const altConstKey = isValid ? "not" : "const";
      const altConstVal = isValid ? { const: url } : url;

      assertionSchema.properties.ReportSourcing.properties[
        altSourceTypeKeys()[0]
      ] = reportSourcingProperty(altArrayKey, altConstKey, altConstVal);
      assertionSchema.properties.ReportSourcing.properties[
        altSourceTypeKeys()[1]
      ] = reportSourcingProperty(altArrayKey, altConstKey, altConstVal);
      assertionSchema.properties.ReportSourcing.properties[sourceTypeKey] =
        reportSourcingProperty(arrayKey, constKey, constVal);
    }

    const sourceTypeMap = {
      Relevant: "included",
      NonRelevant: "disregarded",
      NotProcessed: "not-processed"
    };

    const assertionHumanReadable =
      `Url "${url}" ${isMust ? "must" : "should"}${isValid ? "" : " not"} be ` +
      `a${sourceType === "Relevant" ? "n" : ""} ${
        sourceTypeMap[sourceType]
      } source.`;
    return new Assertion(
      testCaseId,
      userId,
      userComment,
      isMust,
      assertionSchema,
      assertionHumanReadable
    );
  }, reportSourcing.specificSource(url, sourceType));

export default exactSourceUrlAssertionTemplate;
