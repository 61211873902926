import { Assertion, AssertionTemplate } from ".";
import { reportAbstract } from "./recognisers";

const exactNationalityAssertionTemplate = nationality =>
  new AssertionTemplate((isValid, isMust, testCaseId, userId, userComment) => {
    const constVal = isValid ? nationality : { const: nationality };
    const constKey = isValid ? "const" : "not";
    const arrayKey = isValid ? "contains" : "items";
    const assertionSchema = {
      type: "object",
      properties: {
        ReportAbstract: {
          type: "object",
          properties: {
            Nationality: {
              type: "array",
              [arrayKey]: {
                type: "object",
                properties: {
                  Text: {
                    type: "string",
                    [constKey]: constVal
                  }
                }
              }
            }
          }
        }
      }
    };
    const assertionHumanReadable =
      `Report ${isMust ? "must" : "should"}${isValid ? "" : " not"} list ` +
      `"${nationality}" as a nationality`;
    return new Assertion(
      testCaseId,
      userId,
      userComment,
      isMust,
      assertionSchema,
      assertionHumanReadable
    );
  }, reportAbstract.specificNationality(nationality));

export default exactNationalityAssertionTemplate;
