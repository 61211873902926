import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { grey, red, orange } from "styles/colors";
import { fontSize } from "styles/fonts";
import { ReactComponent as ExitSvgIcon } from "img/icons/exit-icon.svg";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  outline: none !important;
  background: none;
  border: none;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px rgb(255 255 255 / 33%);
  }
`;

const CloseIcon = styled(ExitSvgIcon)`
  path {
    fill: ${grey.mid};
  }

  &:hover {
    path {
      fill: ${grey.dark};
    }
  }
`;

const Snippet = styled.p`
  font-size: ${fontSize.sm};
`;

const TranslatedLabel = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const NoSnippet = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  font-style: italic;
`;

const directRisk = css`
  color: ${red.directRiskFill};
`;

const indirectRisk = css`
  color: ${orange.indirectRiskFill};
`;

const S = {
  HeaderWrapper,
  CloseButton,
  CloseIcon,
  Snippet,
  TranslatedLabel,
  NoSnippet
};

export const classNameOverrides = {
  directRisk,
  indirectRisk
};

export default S;
