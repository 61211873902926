import React, { FC, SVGProps, useState } from "react";

import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";

import ModalContainer from "components/molecules/ModalContainer";
import TitleCard from "components/molecules/TitleCard";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";
import useHubAdminRole from "util/hooks/useHubAdminRole";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  orgName: string;
  orgDescription: string;
  orgId: string;
}

const EnterOrganisationModal = ({
  isOpen,
  toggleOpen,
  orgName,
  orgDescription,
  orgId
}: Props) => {
  const { authenticateAsHubAdmin } = useHubAdminRole();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onEnterOrganisation = async () => {
    setLoading(true);

    const status = await authenticateAsHubAdmin(orgId);

    if (status) {
      navigate("/");
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onExitClick={toggleOpen}
      title="Enter organisation"
    >
      <S.ModalContent>
        <Heading level={6}>
          You are about to enter an organisation as a Customer Success assistant
        </Heading>
        <TitleCard
          title={orgName}
          subtitle={orgDescription}
          icon={<S.Building />}
        />
      </S.ModalContent>
      <S.Actions>
        <ButtonNew
          text="Cancel"
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          onClick={toggleOpen}
        />
        <ButtonNew
          text="Enter"
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          onClick={onEnterOrganisation}
          disabled={loading}
          IconTrailing={
            loading
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
        />
      </S.Actions>
    </ModalContainer>
  );
};

export default EnterOrganisationModal;
