import React, { FC } from "react";
import useInsightsFeatures from "util/hooks/useInsightsFeatures";
import { TimelineEventRiskCategory } from "api/insights/models/TimelineEventRiskCategory";
import { grey, orange, red } from "styles/colors";

import S from "./styles";

export interface RiskTagsProps {
  risks: TimelineEventRiskCategory[];
}

const RiskTags: FC<RiskTagsProps> = ({ risks }) => {
  const { timelinesRiskTags } = useInsightsFeatures();

  const getBackgroundColor = (risk: TimelineEventRiskCategory): string => {
    switch (risk.significance) {
      case "Direct":
        return red.directRisk;
      case "Indirect":
        return orange.indirectRisk;
      default:
        return grey.darkish;
    }
  };

  if (!timelinesRiskTags) {
    return null;
  }

  return (
    <>
      {risks.map(risk => (
        <S.RiskCategoryPill
          backgroundColor={getBackgroundColor(risk)}
          key={risk.label}
        >
          {risk.label}
        </S.RiskCategoryPill>
      ))}
    </>
  );
};

export default RiskTags;
