import type {
  Report as RawReport,
  Subsection as RawSubsection
} from "api/insights";

export const addToSectionBySectionId = (
  report: RawReport,
  sectionId: string,
  content: RawSubsection
): RawReport => {
  const sections = report.sections.map(section => {
    if (section.id === sectionId) {
      return {
        ...section,
        subsections: [...section.subsections, ...[content]]
      };
    }
    return section;
  });

  return {
    ...report,
    sections
  };
};
