import React, { useState, FC, SVGProps } from "react";
import { Spinner } from "reactstrap";
import { ChevronDown, Eye, Edit, User, Send } from "react-feather";
import { AnimatePresence, motion } from "framer-motion";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import ModalContainer from "components/molecules/ModalContainer";
import BrandedModalHeader from "components/molecules/BrandedModalHeader";
import { Idam_Contracts_Enums_ReportRole } from "api/portal";
import useViewerMode from "util/hooks/useViewerMode";
import { useAuthentication } from "util/hooks/useAuthentication";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";
import RequestAccountModal from "components/organisms/RequestAccountModal";

import useRequestEditAccess from "./useRequestEditAccess";

import { Permission, Props } from "./types";

import S from "./styles";

const ReportAccessButton = ({ permission, owner, reportId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequestAccountModalOpen, setIsRequestAccountModalOpen] =
    useState(false);

  const {
    reset,
    fetching,
    error,
    errorMessage,
    resetError,
    success,
    requestEditAccess
  } = useRequestEditAccess();

  const {
    state: { status }
  } = useAuthentication();

  const { isSharedReportLink } = useViewerMode();

  const displayRequestAnAccount =
    isSharedReportLink && status !== AuthenticationStatus.authenticated;

  const toggleModalOpen = () => {
    if (isModalOpen) {
      reset();
    }
    setIsModalOpen(prev => !prev);
  };

  let menuItemDetails;

  if (permission === Permission.Viewer) {
    menuItemDetails = { text: "You can view", icon: Eye };
  } else if (permission === Permission.Owner) {
    menuItemDetails = { text: "You are the owner", icon: User };
  } else {
    menuItemDetails = { text: "You can edit", icon: Edit };
  }

  return (
    <>
      <Popover
        content={
          <Menu>
            <MenuItem
              text={menuItemDetails?.text}
              IconLeading={menuItemDetails?.icon}
              disabled
            />
            {permission === Permission.Viewer && !displayRequestAnAccount && (
              <MenuItem
                text="Request edit access"
                IconLeading={Edit}
                onMenuItemClick={toggleModalOpen}
              />
            )}
            {displayRequestAnAccount && (
              <MenuItem
                text="Request an account"
                IconLeading={Send}
                onMenuItemClick={() =>
                  setIsRequestAccountModalOpen(prev => !prev)
                }
              />
            )}
          </Menu>
        }
        hideArrow
        trigger="click"
        alignment="bottom-end"
        distance={4}
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.AccessButton
          text={permission?.toLowerCase()}
          type={ButtonType.Filled}
          size={ButtonSize.Small}
          IconTrailing={ChevronDown}
        />
      </Popover>
      <ModalContainer
        isOpen={isModalOpen}
        toggleOpen={toggleModalOpen}
        onExitClick={toggleModalOpen}
        width={540}
      >
        <S.ModalContent>
          <BrandedModalHeader
            title={
              <S.ModalTitle>
                {!success ? "Request edit access" : ""}
              </S.ModalTitle>
            }
          />
          <AnimatePresence exitBeforeEnter>
            {!success ? (
              <S.Text
                key="1"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                Request edit access from the owner of this report,
                <br />
                <S.Email>{owner}</S.Email>.
              </S.Text>
            ) : (
              <S.ConfirmationText
                key="2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                An email was sent to <S.Email>{owner}</S.Email>.
                <br /> You will recieve an email when edit access is granted.
              </S.ConfirmationText>
            )}
          </AnimatePresence>
          <>
            <AnimatePresence exitBeforeEnter>
              {success ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="done"
                >
                  <ButtonNew
                    text="Done"
                    size={ButtonSize.Medium}
                    type={ButtonType.Filled}
                    onClick={toggleModalOpen}
                  />
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key="request edit access"
                >
                  <ButtonNew
                    text="Request edit access"
                    disabled={fetching}
                    IconTrailing={
                      fetching
                        ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                        : Send
                    }
                    size={ButtonSize.Medium}
                    type={ButtonType.Filled}
                    onClick={() =>
                      requestEditAccess(
                        reportId,
                        Idam_Contracts_Enums_ReportRole.WRITE
                      )
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
            {error && (
              <ErrorBanner
                text={
                  errorMessage || "Something went wrong. Try again in a moment."
                }
                onClick={resetError}
              />
            )}
          </>
        </S.ModalContent>
      </ModalContainer>
      <RequestAccountModal
        isOpen={isRequestAccountModalOpen}
        toggleOpen={() => setIsRequestAccountModalOpen(prev => !prev)}
        reportId={reportId}
      />
    </>
  );
};

export default ReportAccessButton;
