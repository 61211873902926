import React, { FC } from "react";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { Mail } from "react-feather";
import config from "config";
import { ReactComponent as TimelineIcon } from "./timeline-icon.svg";

import S from "./styles";

const NoEvents: FC = () => {
  const handleClick = () => {
    window.location.replace(
      `mailto:${config.supportEmail}?subject=Insights%20Timeline`
    );
  };

  return (
    <S.NoEventsContainer>
      <S.NoEventsContent>
        <S.NoEventsTimelineIconContainer>
          <TimelineIcon />
        </S.NoEventsTimelineIconContainer>
        <S.NoEventsTitle>Insufficient data for timeline</S.NoEventsTitle>
        <S.NoEventsText>
          Timeline searches thousands of web and media articles. However, a very
          low public profile may result in no timeline events. We&apos;re always
          improving our databases, so may be able to do better in future.
        </S.NoEventsText>
        <S.NoEventsButton
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          IconTrailing={Mail}
          onClick={handleClick}
          text="Customer Support"
        />
      </S.NoEventsContent>
    </S.NoEventsContainer>
  );
};

export default NoEvents;
