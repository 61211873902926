import React, { useRef, useEffect, ChangeEvent, KeyboardEvent } from "react";

import { ENTITY_TYPE } from "pages/define/utils";
import SwitchItem from "components/atoms/ContentSwitch/SwitchItem";
import { EntityType } from "api/enquiry/types";

import S from "./styles";

interface Props {
  subjectType: EntityType;
  setSubjectType: (subjectType: EntityType) => void;
  onStageComplete: () => void;
  subjectName: string;
  onSubjectNameChange: (name: string) => void;
  disabled: boolean;
  isAdvancedSearchModeActive: boolean;
  stageTitle: string;
}

const SubjectStage = ({
  subjectType,
  setSubjectType,
  onStageComplete,
  subjectName,
  onSubjectNameChange,
  disabled,
  isAdvancedSearchModeActive,
  stageTitle
}: Props) => {
  const subjectBoxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (subjectBoxRef && subjectBoxRef.current) {
      subjectBoxRef.current.focus();
    }
  }, [isAdvancedSearchModeActive]);

  const getPlaceHolderText = (type: EntityType) => {
    switch (type) {
      case ENTITY_TYPE.Person:
        return "Enter individual's name";
      case ENTITY_TYPE.Organisation:
        return "Enter organisation's name";
      default:
        return "";
    }
  };

  const handleChangeOfSubject = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    onSubjectNameChange(event.target.value);
  };

  const handleKeyDownOnSubject = (event: KeyboardEvent<HTMLInputElement>) => {
    const keyPressed = event.key;
    if (keyPressed === "Enter") {
      onStageComplete();
    }
  };

  const handleChangeOfSubjectType = (selectedItemName: string) => {
    subjectBoxRef.current?.focus();
    setSubjectType(selectedItemName as EntityType);
  };

  return (
    <S.StageContainer>
      <S.StageHeading disabled={disabled}>{stageTitle}</S.StageHeading>
      <S.ContentSwitchContainer>
        <S.ContentSwitch
          onChange={handleChangeOfSubjectType}
          disabled={disabled}
          defaultSelection={subjectType}
          width={234}
        >
          <SwitchItem name={ENTITY_TYPE.Person}>Person</SwitchItem>
          <SwitchItem name={ENTITY_TYPE.Organisation}>Organisation</SwitchItem>
        </S.ContentSwitch>
      </S.ContentSwitchContainer>

      <S.TextInput
        placeholder={getPlaceHolderText(subjectType)}
        value={subjectName || ""}
        onChange={handleChangeOfSubject}
        onKeyDown={handleKeyDownOnSubject}
        ref={subjectBoxRef}
        disabled={disabled}
      />
    </S.StageContainer>
  );
};

export default SubjectStage;
