import React, { useState } from "react";

import {
  SUBJECT_CONTEXTS,
  DEFAULT_CONTEXT_PER_SUBJECT_TYPE
} from "pages/define/utils";
import TextInputWithOptionsList, {
  Row
} from "components/molecules/TextInputWithOptionsList";
import { Context, ContextConfig, EntityType } from "api/enquiry/types";

import S from "./styles";

interface Props {
  subjectType: EntityType;
  contexts: Record<string, Context>;
  setContexts: (contexts: Record<string, Context>) => void;
  onGoClick: () => void;
  disabled?: boolean;
  contextLimit: number;
  isAdvancedSearchModeActive: boolean;
  hasSuggestionBeenAccepted: boolean;
}

const ContextStage = ({
  subjectType,
  contexts,
  setContexts,
  onGoClick,
  disabled,
  contextLimit,
  isAdvancedSearchModeActive,
  hasSuggestionBeenAccepted
}: Props) => {
  const [displayMultipleContextPrompt, setDisplayMultipleContextPrompt] =
    useState(false);

  const contextsAvailableForSubject = Object.keys(
    SUBJECT_CONTEXTS[subjectType]
  ).reduce((acc: Record<string, ContextConfig>, contextKey) => {
    const contextOption = SUBJECT_CONTEXTS[subjectType][contextKey];

    if (
      isAdvancedSearchModeActive &&
      !contextOption.isAvailableInAdvancedMode
    ) {
      return acc;
    }

    acc[contextKey] = SUBJECT_CONTEXTS[subjectType][contextKey];

    return acc;
  }, {});

  const transformContextsToRows = () => {
    return Object.keys(contexts).reduce(
      (acc: Record<string, Row>, contextId) => {
        acc[contextId] = {
          selectedOption: contexts[contextId].type,
          textInputValue: contexts[contextId].value
        };

        return acc;
      },
      {}
    );
  };

  const onRowUpdated = (updatedRowId: number, updatedRowObj?: Row) => {
    const value = updatedRowObj?.textInputValue?.trim();

    if (value && value.indexOf(",") > -1) {
      setDisplayMultipleContextPrompt(true);
    } else {
      setDisplayMultipleContextPrompt(false);
    }

    // If there isn't an updated row object then assume
    // it has been deleted from the rows.
    if (!updatedRowObj) {
      const contextsCopy = { ...contexts };
      delete contextsCopy[updatedRowId];

      setContexts(contextsCopy);
    } else {
      setContexts({
        ...contexts,
        [updatedRowId]: {
          type: contextsAvailableForSubject[updatedRowObj.selectedOption!]
            ?.type,
          // Remove all trailing commmas
          value: updatedRowObj?.textInputValue?.replace(/[,]+$/, ""),
          id: updatedRowObj.selectedOption
        }
      });
    }
  };

  const renderHelpText = () => {
    return (
      <S.HelpText>
        <p>
          To help Xapien find the organisation&apos;s website, enter a location,
          sector, URL or key person.
        </p>
        <p>If you know there is no website, use Advanced search.</p>
      </S.HelpText>
    );
  };

  return (
    <S.StageContainer>
      <TextInputWithOptionsList
        key={`${subjectType} - ${isAdvancedSearchModeActive} - ${hasSuggestionBeenAccepted}`}
        isOnlyTextInputAvailable={!isAdvancedSearchModeActive}
        onEnterPressedInTextInput={onGoClick}
        rowLimit={contextLimit}
        disabled={disabled}
        defaultSelectedOptionForNewRows={
          DEFAULT_CONTEXT_PER_SUBJECT_TYPE[subjectType]
        }
        options={contextsAvailableForSubject}
        onRowUpdated={onRowUpdated}
        initialRows={transformContextsToRows()}
        displayAddRowPrompt={displayMultipleContextPrompt}
        customAddRowPrompt="To enter multiple contexts, click the '+' icon."
        displayHelpInfo={!isAdvancedSearchModeActive}
        helpText={renderHelpText()}
      />
    </S.StageContainer>
  );
};

export default ContextStage;
