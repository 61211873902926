import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { ReactComponent as Arrow } from "img/icons/renders/arrow-rounded.svg";
import { ReactComponent as Chevron } from "img/right-chevron.svg";

import { grey } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const Relationships = styled.div`
  padding-top: 11px;
  cursor: pointer;
`;

const Heading = styled.div`
  border-top: 1px solid ${grey.rule};
  color: ${grey.mid};
  font-size: ${fontSize.md};
  display: flex;
  align-items: center;
  font-family: ${family.interRegular};
  justify-content: space-between;
  padding: 8px 0;
`;

const CollapseButton = styled.button`
  outline: none;
  background: none;
  border: none;
  border-radius: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:focus,
  &:active {
    outline: none;
    background-color: ${grey.light};
  }
`;

const CollapseArrow = styled(Arrow)`
  width: 10px;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? "rotate(0deg)" : "rotate(90deg)"};
  padding-left: 2px;

  path {
    fill: ${grey.mid};
  }
`;

const CollapseChevron = styled(Chevron)`
  height: 16px;
  width: 16px;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? "rotate(0deg)" : "rotate(90deg)"};

  path {
    fill: ${grey.mid};
  }
`;

const CollapseChevronButton = styled(CollapseButton)`
  border-radius: 20px;
  width: 28px;
  height: 24px;
`;

/** ClassNameOverrides */

const masonryGrid = css`
  display: flex;
  width: auto;
  margin-top: 12px;
  max-height: 620px;
  overflow: auto;

  & > div:not(:first-child) {
    padding-left: 16px;
  }
`;

const masonryColumn = css`
  background-clip: padding-box;
  & > div {
    margin-bottom: 14px;
  }
`;

const S = {
  Relationships,
  Heading,
  CollapseButton,
  CollapseArrow,
  CollapseChevron,
  CollapseChevronButton
};

export default S;

export const classNameOverrides = {
  masonryColumn,
  masonryGrid
};
