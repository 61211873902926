import React, { ChangeEvent, forwardRef, useId } from "react";

import S from "./styles";

interface Props {
  onChange: (file: File | null) => void;
  label?: string;
  disabled?: boolean;
}

const FileInput = forwardRef<HTMLInputElement, Props>(
  ({ onChange, label, disabled }, ref) => {
    const inputId = useId();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files?.length) {
        onChange(files[0]);
      } else {
        onChange(null);
      }
    };

    return (
      <S.Container>
        {label && <S.Label htmlFor={inputId}>{label}</S.Label>}
        <S.Input
          id={`${inputId}-search`} // Putting search here prevents lastpass from autofilling??
          ref={ref}
          type="file"
          onChange={handleChange}
          disabled={disabled} // no styling on disabled ATM, but just in temporary use for a field that isn't fully implemented
          data-lpignore="true"
        />
      </S.Container>
    );
  }
);

export default FileInput;
