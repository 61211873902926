import { useEffect, useRef, useState } from "react";

const useIntersect = ({
  root = null,
  rootMargin,
  threshold = 0
}: {
  root?: any;
  rootMargin?: any;
  threshold?: number | number[];
}): [any, IntersectionObserverEntry | undefined] => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry>();
  const [node, setNode] = useState(null);

  const observer = useRef(
    new window.IntersectionObserver(([newEntry]) => updateEntry(newEntry), {
      root,
      rootMargin,
      threshold
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry];
};

export default useIntersect;
