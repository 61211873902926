import React from "react";

import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

const LoadingArticle = () => {
  return (
    <S.LoadingContainer>
      <S.LoadingSection>
        <Skeleton width="100%" height={40} isLightMode />
        <Skeleton width="70%" height={40} isLightMode />
      </S.LoadingSection>

      <S.LoadingSection>
        <Skeleton width="60%" height={20} isLightMode />
        <Skeleton width="30%" height={20} isLightMode />
        <Skeleton width="20%" height={20} isLightMode />
      </S.LoadingSection>

      <S.LoadingSection>
        <Skeleton width="100%" height={80} isLightMode />
        <Skeleton width="100%" height={100} isLightMode />
        <Skeleton width="100%" height={120} isLightMode />
        <Skeleton width="100%" height={80} isLightMode />
      </S.LoadingSection>

      <S.LoadingSection>
        <Skeleton width="50%" height={20} isLightMode />
        <Skeleton width="50%" height={20} isLightMode />
      </S.LoadingSection>
    </S.LoadingContainer>
  );
};

export default LoadingArticle;
