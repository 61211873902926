import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

const SectionBanner = styled.div`
  height: 54px;
  width: 100%;
  background-color: ${standardColors.white};
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const SectionCount = styled.div`
  text-align: end;
  color: ${grey.dark};
  font-size: ${fontSize["2xl"]};
`;

const ImageSection = styled.div`
  flex: 1;
`;

const ImageSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const S = {
  SectionBanner,
  SectionCount,
  ImageSection,
  ImageSectionContainer
};

export default S;
