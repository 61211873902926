import type { ContentNode } from "api/insight-reports";
import { parseListString } from "./parseListString";

const parseWhitespace = (input?: string): string => {
  if (!input) return "";
  return input.replace(/\n/g, "<br/>");
};

export const renderAsRichText = ({
  nodeType,
  value,
  content
}: ContentNode): string => {
  const renderSubContent = () =>
    content?.map(contentNode => renderAsRichText(contentNode)).join("") ?? "";

  switch (nodeType) {
    case "h1": {
      return `<h1>${renderSubContent()}</h1>`;
    }

    case "h2": {
      return `<h2>${renderSubContent()}</h2>`;
    }

    case "h3": {
      return `<h3>${renderSubContent()}</h3>`;
    }

    case "h4": {
      return `<h4>${renderSubContent()}</h4>`;
    }

    case "div": {
      return renderSubContent();
    }

    case "p": {
      return `<p>${renderSubContent()}</p>`;
    }

    case "text": {
      return parseWhitespace(parseListString(value, true));
    }
    default: {
      return parseWhitespace(parseListString(value ?? "", true));
    }
  }
};
