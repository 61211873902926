/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SubjectType {
  PERSON = "person",
  ORGANISATION = "organisation"
}
