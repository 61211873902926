import React, { Fragment, useState, useRef, memo, useMemo } from "react";
import { Collapse } from "reactstrap";

import { usePrintableReportState } from "util/hooks/usePrintableState";

import MediaSourceCard from "components/molecules/MediaSourceCard";

export const SourceStackExpandedContents = memo(props => {
  const {
    isDisregarded,
    sources,
    expanded,
    show,
    showAssessment,
    contract,
    emphasis,
    setEmphasis,
    stackCount,
    isInSnapshot,
    activeRiskCategoryFilters,
    activeRiskDirectionality
  } = props;
  return (
    <Collapse
      className={`source-stack-expanded-contents ${
        isInSnapshot && "media-results-expanded"
      }`}
      isOpen={expanded}
      style={{ display: show ? "" : "none" }}
      mountOnEnter
      unmountOnExit
    >
      {sources.map(s => (
        <MediaSourceCard
          key={s && s.sourceId}
          {...s}
          {...{
            isDisregarded,
            show,
            showAssessment,
            contract,
            emphasis,
            setEmphasis,
            stackCount,
            isInSnapshot,
            activeRiskCategoryFilters,
            activeRiskDirectionality
          }}
        />
      ))}
    </Collapse>
  );
});

export const SourceStack = props => {
  const {
    sources,
    show,
    showAssessment,
    isInSnapshot,
    activeRiskCategoryFilters,
    activeRiskDirectionality,
    isDisregarded
  } = props;
  const topSource = sources[0];
  const key = `source-stack-${topSource ? topSource.sourceId : "unknown"}`;
  const [expanded, setExpanded] = usePrintableReportState(key, false);
  const [emphasis, setEmphasis] = useState(false);
  const topCard = useRef();

  const stackCount = sources.length;
  const expand = useMemo(
    () => !expanded && stackCount > 1 && (() => setExpanded(true)),
    [expanded, setExpanded, stackCount]
  );
  const contract = useMemo(
    () =>
      expanded &&
      (() => {
        setExpanded(false);
        topCard.current.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }),
    [expanded, setExpanded]
  );
  const disabled = expanded;

  if (!sources.length) {
    // Ensure that the ErrorBoundary is hit to display
    // the error text in the UI.
    console.error("Empty stack");
    return null;
  }

  return (
    <>
      <MediaSourceCard
        {...{
          isDisregarded,
          expand,
          stackCount,
          emphasis,
          setEmphasis,
          disabled,
          contract,
          showAssessment,
          isInSnapshot,
          activeRiskCategoryFilters,
          activeRiskDirectionality
        }}
        {...{ ...topSource, show }}
        ref={topCard}
      />
      <SourceStackExpandedContents
        {...{
          isDisregarded,
          contract,
          stackCount,
          emphasis,
          setEmphasis,
          sources,
          expanded,
          show,
          showAssessment,
          isInSnapshot,
          activeRiskCategoryFilters,
          activeRiskDirectionality
        }}
      />
    </>
  );
};
