import { styled } from "@linaria/react";

import Link from "components/atoms/SourceLink";
import Button from "components/atoms/Button";

import { grey } from "styles/colors";

const SourceLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${grey.hover} !important;
  display: inline-block;

  .link-icon {
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
`;

const ExpandButton = styled(Button)`
  min-width: 0 !important;
  width: 26px;
  height: 26px;
  border: none !important;
`;

const S = {
  SourceLink,
  ExpandButton
};

export default S;
