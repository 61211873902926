import { useEffect, useMemo, useState } from "react";

import useDebounce from "util/hooks/useDebounce";

import Api from "api/tenant";
import { SearchItemType, SearchResult } from "api/search";
import { Organisation } from "api/tenant/types";

const containsQuery = (value: string, query: string): boolean => {
  return value?.toLowerCase()?.includes(query.toLowerCase());
};

const useSearchOrganisations = () => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [organisationSuggestions, setOrganisationSuggestions] = useState<
    SearchResult[]
  >([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);

  const { debounce } = useDebounce();

  const TenantApi = useMemo(() => new Api(), []);

  useEffect(() => {
    setIsFetchingSuggestions(true);
    TenantApi.getOrganisations().then(({ response, status }) => {
      if (status) {
        setOrganisations(response ?? []);
      }
      setIsFetchingSuggestions(false);
    });
  }, [TenantApi]);

  const getOrganisationSuggestions = (query: string) => {
    const suggestions: SearchResult[] = [];

    organisations.forEach(org => {
      if (
        containsQuery(org.name, query) ||
        containsQuery(org.description, query)
      ) {
        suggestions.push({
          id: org.organisationId,
          title: org.name,
          description: org.description,
          queryType: SearchItemType.Organisation
        });
      }
    });

    return suggestions;
  };

  const debounceQuerySearch = debounce(async (query: string) => {
    setIsFetchingSuggestions(true);
    const organisationResults: SearchResult[] =
      getOrganisationSuggestions(query);

    setOrganisationSuggestions(organisationResults);
    setIsFetchingSuggestions(false);
  }, 300);

  const onSearchUpdate = (query: string) => {
    debounceQuerySearch(query);
  };

  return { organisationSuggestions, onSearchUpdate, isFetchingSuggestions };
};

export default useSearchOrganisations;
