// @ts-nocheck
import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { fontSize } from "styles/fonts";
import { grey, green, blue } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";

import { DiImage } from "components/molecules/DiImage";

const SourceItemContainer = styled.div`
  background-color: rgba(0, 124, 137, 0.07);
  max-height: 70px;
  height: 70px;
  border-radius: 4px;
  display: flex;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    background-color: rgba(0, 124, 137, 0.13);
    cursor: pointer;
    outline: none;
  }
`;

const ItemIconContainer = styled.div<{ source?: { snippets?: any } }>`
  background-color: rgba(
    ${() => {
      const { r, g, b } = hexToRgb(green.mid);
      return `${r}, ${g}, ${b}`;
    }},
    0.7
  );
  width: 30px;
  height: ${props => (props.source?.snippets ? "" : "70px")};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SourceTitle = styled.span<{ noImage?: boolean }>`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  margin-left: 8px;
  max-width: ${({ noImage }) => (noImage ? 385 : 325)}px;
`;

const ItemContents = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const SourceUrl = styled.span<{ noImage?: boolean }>`
  font-size: ${fontSize.sm};
  margin-left: 8px;
  max-width: ${({ noImage }) => (noImage ? 385 : 325)}px;
  color: ${blue.mid} !important;
`;

const ItemContentsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2px;
`;

const SourceImage = styled(DiImage)`
  object-fit: cover;
  height: 64px;
  border-radius: 8px;
`;

const SourceUrlPlaceholder = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-left: 8px;
`;

/** ClassNameOverrides */

const sourceItemLink = css`
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const Quote = styled.q`
  font-style: italic;
  display: block;
`;

const S = {
  SourceItemContainer,
  ItemIconContainer,
  SourceTitle,
  ItemContents,
  SourceUrl,
  ItemContentsContainer,
  SourceImage,
  SourceUrlPlaceholder,
  Quote
};

export const classNameOverrides = {
  sourceItemLink
};

export default S;
