import { RiskSummary, RiskSummaryTopic } from "api/insights";

export interface RiskSummariesContextValue {
  hasRiskSummaries: boolean;
  status: RiskSummariesStatus;
  getSummary: (topic: RiskSummaryTopic) => RiskSummary | undefined;
}

export enum RiskSummariesStatus {
  NotLoaded,
  Loading,
  Loaded,
  Error
}
