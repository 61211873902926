import http from "services/http";
import endpoints from "services/endpoints";
import { apm } from "@elastic/apm-rum";

class Notes {
  async getUserNotes(enquiryId) {
    const urlParams = new URLSearchParams(window.location.search);

    try {
      if (urlParams.get("token") !== null) {
        return await http.get(
          `${endpoints.getUserNotes}/${enquiryId}/${urlParams.get(
            "token"
          )}/notes`
        );
      }

      return await http.get(`${endpoints.getUserNotes}/${enquiryId}/notes`);
    } catch (error) {
      apm.captureError(error);
      console.error("Error retrieving notes: ", error);
      return { hasErrored: true, error };
    }
  }

  async setUserNotes(enquiryId, userNotes) {
    try {
      return await http.put(`${endpoints.setUserNotes}/${enquiryId}/notes`, {
        ReportNotes: userNotes
      });
    } catch (error) {
      apm.captureError(error);
      console.error("Error setting notes: ", error);
      return { hasErrored: true, error };
    }
  }
}

export default Notes;
