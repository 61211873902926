import { styled } from "@linaria/react";

import { ReactComponent as CompanyLogo } from "img/logo.svg";

import { standardColors } from "styles/colors";
import Heading from "components/atoms/Heading";

const Container = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.5rem 1rem;
`;

const Logo = styled(CompanyLogo)`
  path {
    fill: ${standardColors.white};
  }
`;

const PageTitle = styled(Heading)`
  color: white;
  flex: 1;
  text-align: center;
`;

const Menu = styled.div``;

const S = {
  Container,
  Logo,
  PageTitle,
  Menu
};

export default S;
