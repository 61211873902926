import {
  InsightReportStatus,
  InsightReportState
} from "util/hooks/useInsightReport/types";

export const getHasBeenDisplacedFromBelow = ({
  nodeType,
  parentIds,
  state,
  id
}: {
  nodeType: string;
  parentIds: string[];
  state: InsightReportState;
  id: string;
}) => {
  if (nodeType !== "div") return false;

  if (state.status !== InsightReportStatus.reorderingSubSectionsSuccess)
    return false;

  if (state.reorderingSubsectionDirection === "down") return false;

  const [sectionId] = parentIds;

  const reorderingSection = state.rawReport?.sections.find(
    s => s.id === state.reorderingSectionId
  );

  if (!reorderingSection) return false;

  if (reorderingSection.id !== sectionId) return false; // Not the same section

  const { subsections } = reorderingSection;

  const reorderingSubsectionIndex = subsections.findIndex(
    subsection => subsection.id === state.reorderingSubsectionId
  );

  const displacedSubsection = subsections[reorderingSubsectionIndex - 1];

  return displacedSubsection.id === id;
};
