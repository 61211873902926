import { styled } from "@linaria/react";

import ReactTooltip from "components/atoms/ReactTooltip";
import ButtonNew from "components/atoms/ButtonNew";
import Heading from "components/atoms/Heading";

import { hexToRgb } from "styles/hexToRgb";
import { blue, grey, purple, standardColors } from "styles/colors";
import { family } from "styles/fonts";
import { zIndex } from "styles/zIndex";

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

const DropdownButtonText = styled.span`
  &:hover {
    color: ${purple.dark};
  }
`;

const Dropdown = styled.div`
  width: 336px;
`;

const DropdownOption = styled.div`
  padding: 8px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${grey.panel};
  }

  &:hover {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(blue.hover);
      return `rgba(${r}, ${g}, ${b}, 0.05)`;
    }};
  }
`;

const Tooltip = styled(ReactTooltip)`
  display: flex;
`;

const ControlButton = styled.div<{ appearsDisabled: boolean }>`
  background: ${grey.panel};
  padding: 6px 12px 6px 8px;
  border-radius: 100px;
  font-family: ${family.interSemiBold};
  color: ${({ appearsDisabled }) => (appearsDisabled ? grey.rule : grey.dark)};

  h3,
  p,
  svg {
    color: ${grey.dark};
  }

  &:hover {
    background: ${({ appearsDisabled }) =>
      appearsDisabled ? grey.panel : purple.lighter};

    span {
      color: ${({ appearsDisabled }) =>
        appearsDisabled ? grey.rule : purple.dark};
    }
  }
`;

const ButtonIcon = styled.div<{ isActive: boolean; appearsDisabled: boolean }>`
  background: ${({ isActive, appearsDisabled }) => {
    if (appearsDisabled) return grey.rule;
    if (isActive) return purple.darker;

    return standardColors.white;
  }};
  color: ${({ isActive, appearsDisabled }) => {
    if (appearsDisabled) return standardColors.white;
    if (isActive) return standardColors.white;

    return purple.darker;
  }};

  border-radius: 50%;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;

  svg {
    color: ${({ isActive, appearsDisabled }) => {
      if (appearsDisabled) return standardColors.white;
      if (isActive) return standardColors.white;

      return purple.darker;
    }};
    max-width: 100%;
    max-height: 100%;
  }
`;

const DiscardModalContainer = styled.div`
  z-index: ${zIndex.l7};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
`;

const DiscardedCover = styled.div`
  z-index: ${zIndex.l7};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
`;

const DiscardedIcon = styled.div`
  background: ${standardColors.white};
  border: 1px solid ${purple.darker};
  color: ${purple.darker};

  border-radius: 50%;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const DiscardedCoverHeading = styled(Heading)`
  color: ${purple.darker};
`;

const UndoButton = styled(ButtonNew)`
  background: ${grey.panel};

  &:hover {
    background: ${grey.rule};
    color: ${blue.xapienDark};
  }
`;

const DiscardModalContainerInnerContainer = styled.div`
  cursor: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${standardColors.white};
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

const S = {
  DropdownButton,
  DropdownButtonText,
  Dropdown,
  DropdownOption,
  Tooltip,
  ControlButton,
  ButtonIcon,
  DiscardModalContainer,
  DiscardModalContainerInnerContainer,
  DiscardedCover,
  DiscardedIcon,
  DiscardedCoverHeading,
  UndoButton
};

export default S;
