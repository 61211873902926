import React, { FC, useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Input } from "@mui/material";

import useInsightsAma from "util/hooks/useInsightsAma";
import { useSubjectName } from "util/hooks/useSubjectName";
import { InsightsAmaStatus } from "util/hooks/useInsightsAma/types";
import useReportType from "util/hooks/useReportType";
import { REPORT_TYPES } from "util/reportTypes";

import Suggestions from "./Suggestions";
import S from "./styles";

interface Props {
  sectionId: string;
  showInput?: boolean;
}

const InsightsChat: FC<Props> = ({ sectionId, showInput = false }) => {
  const subjectName = useSubjectName();
  const {
    askQuestion,
    state: { status }
  } = useInsightsAma();
  const reportType = useReportType();
  const isLegalEntity = reportType === REPORT_TYPES.legalEntity;

  const [question, setQuestion] = useState("");
  const [showAskXapien, setShowAskXapien] = useState(showInput);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isAskXapienButtonDisabled = status === InsightsAmaStatus.askingQuestion;

  const onAskQuestion = () => {
    if (!question.length) {
      return;
    }
    setShowAskXapien(false);
    askQuestion(question, sectionId);
    setQuestion("");
  };

  const onSuggestionCallback = () => {
    setShowAskXapien(false);
  };

  const onSubmit = () => {
    onAskQuestion();
    inputRef.current?.blur();
  };

  const onAskQuestionClick = () => {
    if (isAskXapienButtonDisabled) {
      return;
    }

    setShowAskXapien(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !showInput
      ) {
        setShowAskXapien(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [containerRef, setShowAskXapien, showInput]);

  return (
    <div ref={containerRef}>
      <AnimatePresence>
        {showAskXapien ? (
          <motion.div
            key="AskInputMotion"
            initial={{ opacity: 0, height: 0 }}
            exit={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
          >
            <S.InsightsChat>
              <S.AskInputContainer>
                <Input
                  placeholder="Tell me about their..."
                  endAdornment={
                    <S.AskInputHelpTextButton onClick={onSubmit}>
                      Press Enter <S.EnterIcon />
                    </S.AskInputHelpTextButton>
                  }
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                  onChange={e => setQuestion(e.target.value)}
                  value={question}
                  inputRef={inputRef}
                />
              </S.AskInputContainer>
              <Suggestions
                sectionId={sectionId}
                onSuggestionCallback={onSuggestionCallback}
              />
            </S.InsightsChat>
          </motion.div>
        ) : (
          <motion.div
            key="AskXapienMotion"
            initial={{ opacity: 0, height: 0, paddingTop: 0 }}
            exit={{ opacity: 0, height: 0, paddingTop: 0 }}
            animate={{
              opacity: 1,
              height: "auto",
              paddingTop: 25
            }}
          >
            <S.AskXapienButton
              onClick={onAskQuestionClick}
              disabled={isAskXapienButtonDisabled}
            >
              <S.AskXapienButtonIcon disabled={isAskXapienButtonDisabled} />
              {isLegalEntity ? (
                "Ask your own question based on the information collected"
              ) : (
                <>
                  Ask your own question about
                  <span>{subjectName}</span>
                </>
              )}
            </S.AskXapienButton>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default InsightsChat;
