import { useContext } from "react";
import { AuthenticationContext } from "./context";
import { AuthenticationContextProvider } from "./provider";
import { AuthenticationActions } from "./types";

const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationContextProvider"
    );
  }

  return context;
};

export {
  AuthenticationContext,
  AuthenticationContextProvider,
  useAuthentication,
  AuthenticationActions
};
