import React, { CSSProperties, ReactNode } from "react";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  title: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const BrandedModalHeader = ({ title, style, className }: Props) => {
  return (
    <S.Header className={className} style={style}>
      {theme.navigation?.logo ?? <S.XapienLogo />}
      <S.TitleContainer>{title}</S.TitleContainer>
    </S.Header>
  );
};

export default BrandedModalHeader;
