import { observable, action, runInAction, makeObservable } from "mobx";
import { apm } from "@elastic/apm-rum";

import Report from "api/report/Report";
import { REPORT_TYPES } from "util/reportTypes";

import { getUserRemovedRiskCategories } from "./util";

export const ReportLoadingStatus = Object.freeze({
  error: 0,
  success: 1,
  loading: 2,
  initial: 3,
  deleted: 4,
  shareTokenDeleted: 5,
  unauthorised: 6
});

class ReportStore {
  constructor(sharedUserInterface, reportApi) {
    this.sharedUserInterface = sharedUserInterface;
    this.reportApi =
      reportApi ||
      new Report(state => {
        this.sharedUserInterface.apiCallInProgress = state;
      });
    this.report = null;
    this.reportMeta = {};
    this.imageMap = new Map();
    this.reports = [];
    this.loadingStatus = ReportLoadingStatus.initial;
    this.isReportSaving = false;
    this.isReportRegenerationOpen = false;
    this.isUserChangesFooterOpen = false;
    this.userRemovedRiskCategories = {};
    this.webAndMediaData = [];
    this.riskyWebAndMediaData = [];
    this.riskData = {};
    this.savedWebAndMediaData = [];
    this.riskySavedWebAndMediaData = [];
    this.savedRiskData = {};

    this.setIsReportRegenerationOpen =
      this.setIsReportRegenerationOpen.bind(this);
    this.removeRiskCategory = this.removeRiskCategory.bind(this);
    this.clearRemovedRiskCategories =
      this.clearRemovedRiskCategories.bind(this);
    this.saveRiskMods = this.saveRiskMods.bind(this);
    this.setIsUserChangesFooterOpen =
      this.setIsUserChangesFooterOpen.bind(this);
    this.setSavedWebAndMediaData = this.setSavedWebAndMediaData.bind(this);
    this.setRiskySavedWebAndMediaData =
      this.setRiskySavedWebAndMediaData.bind(this);
    this.setSavedRiskData = this.setSavedRiskData.bind(this);
    this.setIsReportSaving = this.setIsReportSaving.bind(this);

    makeObservable(this, {
      report: observable,
      imageMap: observable,
      reports: observable,
      loadingStatus: observable,
      fetch: action,
      saveRiskMods: action,
      setIsReportRegenerationOpen: action,
      removeRiskCategory: action,
      clearRemovedRiskCategories: action,
      setSavedWebAndMediaData: action,
      setRiskySavedWebAndMediaData: action,
      setSavedRiskData: action,
      setIsReportSaving: action,
      isReportSaving: observable,
      isReportRegenerationOpen: observable,
      isUserChangesFooterOpen: observable,
      webAndMediaData: observable,
      riskyWebAndMediaData: observable,
      riskData: observable,
      savedWebAndMediaData: observable,
      riskySavedWebAndMediaData: observable,
      savedRiskData: observable
    });
  }

  list() {
    return new Promise((resolve, reject) => {
      this.reportApi.list().then(reports => {
        runInAction(() => {
          this.reports = reports;
        });
        resolve(reports);
      }, reject);
    });
  }

  async fetch(enquiryId, personaId, shareToken, isForPDFExport, presignedUrl) {
    runInAction(() => {
      this.loadingStatus = ReportLoadingStatus.loading;
    });
    try {
      let report = {};
      let reportMeta = {
        owner: {
          userId: ""
        },
        permissions: {
          canView: true,
          canEdit: true,
          canExport: true
        }
      };
      if (presignedUrl) {
        const response = await fetch(decodeURIComponent(presignedUrl));
        const reportJson = await response.json();
        report =
          this.reportApi.mutateLowerCamelCaseObjectRecursively(reportJson);
      } else {
        const response = await this.reportApi.get(
          enquiryId,
          personaId,
          shareToken,
          isForPDFExport
        );
        [report, reportMeta] = response;
      }
      // Reset web and media and risk data state when a new fetch is invoked, i.e.
      // when the report is refreshed or regenerated.
      this.webAndMediaData = [];
      this.riskyWebAndMediaData = [];
      this.savedWebAndMediaData = [];
      this.riskySavedWebAndMediaData = [];
      this.riskData = {};
      this.savedRiskData = {};

      // Capture the web and media sources so we can easily traverse them
      // when responding to the killing of risk categories.
      if (report.reportSourcing?.relevantSourceGroups) {
        this.setWebAndMediaData(
          JSON.parse(JSON.stringify(report.reportSourcing.relevantSourceGroups))
        );
      }
      if (report.reportSourcing?.nonRelevantSourceGroups) {
        this.setRiskyWebAndMediaData(
          JSON.parse(
            JSON.stringify(report.reportSourcing.nonRelevantSourceGroups)
          )
        );
      }

      this.setRiskData(report);

      runInAction(() => {
        this.report = report;
        this.reportMeta = reportMeta;
        this.imageMap = new Map(Object.entries(reportMeta.imageMap ?? {}));
        if (
          this.report?.reportFailed &&
          !this.report?.reportMetadata?.reportIssues?.find(
            issue => issue === "SubjectCouldNotBeIdentified"
          )
        ) {
          this.loadingStatus = ReportLoadingStatus.error;
        } else {
          this.loadingStatus = ReportLoadingStatus.success;
        }
      });
    } catch (e) {
      apm.captureError(e);
      if (e.status === 410) {
        console.error("Report has been deleted");
        runInAction(() => {
          this.loadingStatus = ReportLoadingStatus.deleted;
          this.report = null;
          this.imageMap = new Map();
        });
      } else if (shareToken && (e.status === 403 || e.status === 404)) {
        console.warn("Share link has been deleted", { shareToken, e });
        runInAction(() => {
          this.loadingStatus = ReportLoadingStatus.shareTokenDeleted;
          this.report = null;
          this.imageMap = new Map();
        });
      } else if (e.status === 403) {
        this.loadingStatus = ReportLoadingStatus.unauthorised;
        this.report = null;
        this.imageMap = new Map();
      } else {
        console.error("Error loading report", e);
        runInAction(() => {
          this.loadingStatus = ReportLoadingStatus.error;
          this.report = null;
          this.imageMap = new Map();
        });
      }
    }
  }

  setIsReportSaving(value) {
    this.isReportSaving = value;
  }

  setSavedWebAndMediaData(sources) {
    this.savedWebAndMediaData = sources;
  }

  setRiskySavedWebAndMediaData(sources) {
    this.riskySavedWebAndMediaData = sources;
  }

  setSavedRiskData(sources) {
    this.savedRiskData = sources;
  }

  setIsReportRegenerationOpen(value) {
    this.isReportRegenerationOpen = value;
  }

  setIsUserChangesFooterOpen(value) {
    this.isUserChangesFooterOpen = value;
  }

  setRiskyWebAndMediaData(sources) {
    const result = [];

    if (!this.riskyWebAndMediaData?.length) {
      // Initialise and pre-process
      sources.forEach(group => {
        group.sourceStacks.forEach(stack => {
          if (stack.sources.length === 1) {
            result.push(stack.sources[0]);
          } else if (stack.sources.length > 1) {
            const parentArticle = stack.sources[0];
            // If any of the articles in the story directly mentions
            // the subject, then include the whole story.
            result.push(parentArticle);
            parentArticle.storySources = [];
            stack.sources.slice(1, stack.sources.length).forEach(source => {
              parentArticle.storySources.push(source);
              result.push({
                ...source,
                parentSourceId: parentArticle.sourceId
              });
            });
          }
        });
      });
      this.riskyWebAndMediaData = result;
      this.setRiskySavedWebAndMediaData(result);
    } else {
      this.riskyWebAndMediaData = sources;
    }
  }

  setWebAndMediaData(sources) {
    const result = [];

    if (!this.webAndMediaData?.length) {
      // Initialise and pre-process
      sources.forEach(group => {
        group.sourceStacks.forEach(stack => {
          if (
            stack.sources.length === 1 &&
            stack.sources[0].hasDirectSubjectMention
          ) {
            result.push({
              ...stack.sources[0],
              isAdjacentContent: group.isAdjacentContent
            });
          } else if (stack.sources.length > 1) {
            const parentArticle = stack.sources[0];
            // If any of the articles in the story directly mentions
            // the subject, then include the whole story.
            if (stack.sources.some(source => source.hasDirectSubjectMention)) {
              result.push({
                ...parentArticle,
                isAdjacentContent: group.isAdjacentContent
              });
              parentArticle.storySources = [];
              stack.sources.slice(1, stack.sources.length).forEach(source => {
                parentArticle.storySources.push({
                  ...source,
                  isAdjacentContent: group.isAdjacentContent
                });
                result.push({
                  ...source,
                  isAdjacentContent: group.isAdjacentContent,
                  parentSourceId: parentArticle.sourceId
                });
              });
            }
          }
        });
      });
      this.webAndMediaData = result;
      this.setSavedWebAndMediaData(result);
    } else {
      this.webAndMediaData = sources;
    }
  }

  setRiskData(data) {
    if (!data) {
      return;
    }
    // Initialise and pre-process
    if (!Object.keys(this.riskData)?.length) {
      // have to be cautious of abstract and/or org details not being there in the case of "subject not found" reports
      const riskDataFromReportJson =
        data.reportMetadata.subjectType === REPORT_TYPES.organisation
          ? data?.organisationDetails?.riskData
          : data?.reportAbstract?.riskData;
      if (riskDataFromReportJson) {
        // Filter out any sourceless tier 2 categories
        const riskDataCopy = { ...riskDataFromReportJson };
        Object.keys(riskDataCopy).forEach(riskGroupName => {
          if (Array.isArray(riskDataCopy[riskGroupName])) {
            const filteredDirectTier2RiskCats = riskDataCopy[
              riskGroupName
            ]?.directRiskCategories?.filter(tier2WithSources => {
              return tier2WithSources?.referenceIds?.length > 0;
            });
            const filteredIndirectTier2RiskCats = riskDataCopy[
              riskGroupName
            ]?.indirectRiskCategories?.filter(tier2WithSources => {
              return tier2WithSources?.referenceIds?.length > 0;
            });
            riskDataCopy[riskGroupName].directRiskCategories =
              filteredDirectTier2RiskCats;
            riskDataCopy[riskGroupName].indirectRiskCategories =
              filteredIndirectTier2RiskCats;
          }
        });
        this.riskData = JSON.parse(JSON.stringify(riskDataCopy));
        this.setSavedRiskData(JSON.parse(JSON.stringify(riskDataCopy)));
      }
    } else {
      this.riskData = data;
    }
  }

  removeRiskCategory({ sourceId, riskHierarchies }) {
    riskHierarchies.forEach(h => {
      const result = getUserRemovedRiskCategories({
        sourceId,
        removedRiskHierarchy: h,
        sources: this.webAndMediaData,
        currentUserRemovedRiskCategories: this.userRemovedRiskCategories,
        webAndMediaData: this.webAndMediaData,
        riskData: this.riskData
      });
      this.webAndMediaData = result.webAndMediaData;
      this.riskData = result.riskData;
      this.userRemovedRiskCategories = result.removedRiskCategories;
    });
    this.isUserChangesFooterOpen = true;
  }

  clearRemovedRiskCategories() {
    this.setWebAndMediaData(
      JSON.parse(JSON.stringify(this.savedWebAndMediaData))
    );
    this.setRiskyWebAndMediaData(
      JSON.parse(JSON.stringify(this.riskySavedWebAndMediaData))
    );

    this.setRiskData(JSON.parse(JSON.stringify(this.savedRiskData)));

    this.userRemovedRiskCategories = {};
    this.isUserChangesFooterOpen = false;
  }

  async saveRiskMods(enquiryId) {
    const assertions = Object.entries(this.userRemovedRiskCategories).map(
      ([key, value]) => {
        return {
          SourceId: key,
          RiskHierarchies: value.riskHierarchies
        };
      }
    );
    const result = await this.reportApi.saveReportChanges(
      enquiryId,
      assertions
    );
    if (!result.hasErrored) {
      this.setSavedWebAndMediaData(
        JSON.parse(JSON.stringify(this.webAndMediaData))
      );
      this.setRiskySavedWebAndMediaData(
        JSON.parse(JSON.stringify(this.riskyWebAndMediaData))
      );
      this.setSavedRiskData(JSON.parse(JSON.stringify(this.riskData)));
      this.userRemovedRiskCategories = {};
    }
    return result;
  }
}
export default ReportStore;
