import React, { ReactNode, CSSProperties } from "react";
import Popover from "components/atoms/Popover";

import type { Placement, Strategy } from "@floating-ui/react";

import S from "./styles";

export enum TooltipVariant {
  DEFAULT = "default",
  DARK = "dark"
}

interface Props {
  children: ReactNode;
  tooltip: ReactNode;
  position?: Placement;
  layoutPosition?: Strategy;
  maxWidth?: string;
  minWidth?: string;
  distance?: number;
  open?: boolean;
  arrow?: boolean;
  offset?: number;
  disabled?: boolean;
  trigger?: string;
  onRequestClose?: () => void;
  style?: CSSProperties;
  className?: string;
  delay?: number;
  interactive?: boolean;
  variant?: TooltipVariant;
}

const ReactTooltip = ({
  children,
  tooltip,
  position = "top",
  layoutPosition = "absolute",
  maxWidth = "100%",
  minWidth,
  distance = 10,
  open,
  arrow = true,
  offset = 0,
  disabled = false,
  trigger = "mouseenter",
  onRequestClose = () => {},
  style,
  className,
  delay = 500,
  interactive = false,
  variant = TooltipVariant.DEFAULT
}: Props) => {
  return (
    <Popover
      position={layoutPosition}
      alignment={position}
      maxWidth={maxWidth}
      distance={distance}
      isOpenOverride={open}
      hideArrow={!arrow}
      tooltipOffset={offset}
      disabled={disabled}
      trigger={trigger}
      onRequestClose={onRequestClose}
      style={style}
      className={className}
      delay={delay}
      interactive={interactive}
      variant={variant}
      content={
        variant === TooltipVariant.DEFAULT ? (
          <S.ContentContainer maxWidth={maxWidth}>{tooltip}</S.ContentContainer>
        ) : (
          <S.DarkContentContainer maxWidth={maxWidth} minWidth={minWidth}>
            {tooltip}
          </S.DarkContentContainer>
        )
      }
    >
      {children}
    </Popover>
  );
};

export default React.memo(ReactTooltip);
