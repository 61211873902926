import { useContext } from "react";

import { HubAdminRoleContext } from "./context";

const useHubAdminRole = () => {
  const context = useContext(HubAdminRoleContext);

  if (!context) {
    throw new Error(
      "useHubAdminRole must used within a HubAdminRoleContextProvider"
    );
  }

  return context;
};

export default useHubAdminRole;
