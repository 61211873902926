import { useMemo, useState } from "react";

import { SearchItemType, SearchResult } from "api/search";
import useDebounce from "util/hooks/useDebounce";
import { User } from "api/tenant/types";

import Api from "api/tenant";

const containsQuery = (value: string, query: string): boolean => {
  return value?.toLowerCase()?.includes(query.toLowerCase());
};

const useUserSearch = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [userSuggestions, setUserSuggestions] = useState<SearchResult[]>([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [currentOrgId, setCurrentOrgId] = useState<string | undefined>();

  const { debounce } = useDebounce();

  const TenantApi = useMemo(() => new Api(), []);

  const getUserSuggestions = async (query: string, orgId: string) => {
    const suggestions: SearchResult[] = [];
    let usersToFilter = users;

    // If the organisation that was selected has changed, then update the stored set of users
    if ((orgId && !users.length) || (orgId && currentOrgId !== orgId)) {
      const { status, response } = await TenantApi.getUsersFromOrganisation(
        orgId
      );
      if (status && response) {
        usersToFilter = response;
        setUsers(response);
      } else {
        setUsers([]);
      }
      setCurrentOrgId(orgId);
    }

    usersToFilter.forEach(user => {
      if (containsQuery(user.name, query) || containsQuery(user.email, query)) {
        suggestions.push({
          id: user.userId,
          title: user.name,
          description: user.email,
          queryType: SearchItemType.User
        });
      }
    });

    return suggestions;
  };

  const debounceQuerySearch = debounce(async (query: string, orgId) => {
    setIsFetchingSuggestions(true);
    const userResults: SearchResult[] = await getUserSuggestions(query, orgId);

    setUserSuggestions(userResults);
    setIsFetchingSuggestions(false);
  }, 300);

  const onSearchUpdate = (query: string, orgId: string) => {
    debounceQuerySearch(query, orgId);
  };

  const clearUsers = () => {
    setUsers([]);
    setUserSuggestions([]);
  };

  return { userSuggestions, onSearchUpdate, isFetchingSuggestions, clearUsers };
};

export default useUserSearch;
