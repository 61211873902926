import { createContext } from "react";
import {
  UserOrganisationAction,
  UserOrganisationActions,
  UserOrganisationContextContents,
  UserOrganisationState
} from "./types";

export const initialState: UserOrganisationState = {
  details: { loaded: false },
  users: [],
  saving: false,
  descriptionToSave: undefined,
  saved: false
};

export const UserOrganisationContext =
  createContext<UserOrganisationContextContents>({
    state: initialState,
    dispatch: (_: UserOrganisationAction) => {}
  });

export const userOrganisationReducer = (
  state: UserOrganisationState,
  action: UserOrganisationAction
): UserOrganisationState => {
  switch (action.type) {
    case UserOrganisationActions.detailsLoaded:
      return {
        ...state,
        details: { ...action.details, loaded: true },
        lastSavedDetails: action.details,
        users: action.users
      };
    case UserOrganisationActions.saveDescription:
      return { ...state, descriptionToSave: action.description, saving: true };
    case UserOrganisationActions.detailsSaved:
      if (state.details.loaded) {
        return {
          ...state,
          saving: false,
          descriptionToSave: undefined,
          saved: true,
          details: {
            ...state.details,
            description: action.description
          }
        };
      }
      return state;

    case UserOrganisationActions.updateFailed:
      console.error("User organisation update failed", { action });
      if (state.details.loaded) {
        return {
          ...state,
          error: action.message,
          saving: false,
          descriptionToSave: undefined
        };
      }
      return state;
    case UserOrganisationActions.dismissError:
      return { ...state, error: undefined };
    case UserOrganisationActions.resetSavedStatus:
      return { ...state, saved: false };
    case UserOrganisationActions.resetOrganisationDetails:
      return initialState;
    default:
      console.error("Unmapped action", action);
      return state;
  }
};
