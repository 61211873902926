import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import FlagPill from "themes/projectdx/ThemedScreening/FlagPill";
import { ReactComponent as RightChevronIcon } from "img/right-chevron.svg";
import { grey, red, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";
import { FILTER_TYPES } from "themes/projectdx/ThemedScreening/util";

const EntityCardContainer = styled.div`
  border-bottom: 2px solid ${standardColors.white};
  &:last-child {
    border-bottom: none;
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const EntityImage = styled.div<{ filterBucketType: string }>`
  background-color: ${props => {
    if (props.filterBucketType === FILTER_TYPES.confirmed) {
      return red.directRisk;
    }
    if (props.filterBucketType === FILTER_TYPES.unconfirmed) {
      return grey.mid;
    }
    return grey.ghost;
  }};
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
`;

const FlagPillsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  padding-top: 11px;
`;

const RightChevron = styled(RightChevronIcon)<{ isActive: boolean }>`
  height: 11px;
  width: 11px;
  margin: 0 3px;
  margin-bottom: 3px;

  path {
    fill: ${isActive => (isActive ? red.directRisk : grey.mid)};
  }
`;

// @ts-ignore
const ChildFlagPill = styled(FlagPill)`
  margin-right: 6px;
`;

const Chevron = styled(RightChevronIcon)<{ isExpanded: boolean }>`
  width: 10px;
  transform: ${isExpanded => (isExpanded ? "rotate(90deg)" : "rotate(0deg)")};

  path {
    fill: ${grey.dark};
  }
`;

const ConfidenceHitCount = styled.span`
  color: ${grey.dark};
  font-size: ${fontSize.lg};
  margin-right: 8px;
`;

const ExpandHeader = styled.button<{ isTableEntriesEmpty: boolean }>`
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  font-size: ${fontSize.md};
  color: ${grey.mid};
  height: 48px;
  background-color: ${grey.light};
  cursor: ${isTableEntriesEmpty =>
    isTableEntriesEmpty ? "default" : "pointer"} !important;

  ${ConfidenceHitCount}, ${Chevron} {
    color: ${isTableEntriesEmpty =>
      isTableEntriesEmpty ? grey.ghost : grey.dark};

    path {
      fill: ${isTableEntriesEmpty =>
        isTableEntriesEmpty ? grey.ghost : grey.dark};
    }
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const ExpandHeaderCountLabel = styled.span`
  color: ${grey.dark};
`;

const ExpandHeaderRightContent = styled.div<{
  isDiscarded: boolean;
  isTableEntriesEmpty: boolean;
}>`
  display: flex;
  align-items: center;
  opacity: ${isDiscarded => (isDiscarded ? 0.2 : 1)};

  cursor: ${isTableEntriesEmpty =>
    isTableEntriesEmpty ? "default" : "pointer"} !important;

  ${ConfidenceHitCount}, ${Chevron} {
    color: ${isTableEntriesEmpty =>
      isTableEntriesEmpty ? grey.ghost : grey.dark};

    path {
      fill: ${isTableEntriesEmpty =>
        isTableEntriesEmpty ? grey.ghost : grey.dark};
    }
  }
`;
/* ClassNameOverrides */

const cardStyles = css`
  border-top: none !important;
`;

const InfoIconContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DisabledInfoIconContainer = styled(InfoIconContainer)`
  opacity: 0.2;
`;

const cardStylesNullified = css`
  border-top: none !important;

  #pdx-confidence-opacity {
    opacity: 0.2;
    transition: opacity 1s;
  }

  /*
     Creating a global stylesheet to target multiple elements of the
     entity card that displays the screening hit. By apply the styles this way,
     we can avoid applying the same styles (opacity of 0.2) onto the confidence menu toggle
  */
  :global() {
    .confidence-opacity__transparent {
      opacity: 0.2;
      transition: opacity 1s;
    }

    .confidence-opacity__opaque {
      opacity: 1;
    }
  }
`;

const S = {
  EntityCardContainer,
  EntityImage,
  FlagPillsContainer,
  RightChevron,
  InfoIconContainer,
  ChildFlagPill,
  ExpandHeader,
  ExpandHeaderCountLabel,
  Chevron,
  ConfidenceHitCount,
  ExpandHeaderRightContent,
  DisabledInfoIconContainer
};

export default S;

export const classNameOverrides = {
  cardStyles,
  cardStylesNullified
};
