import React, { Fragment, useState, useContext } from "react";

import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";
import { UserVerificationContext } from "util/hooks/useUserVerification";
import { FILTER_TYPES } from "themes/projectdx/ThemedScreening/util";
import {
  AssessmentOptions,
  userAssessmentMapping
} from "pages/report/AssessmentOptions";
import { usePreviousValue } from "util/hooks/usePreviousValue";

import S from "./styles";
import EntityList from "./EntityList";

// Format json to include original assignments
const getAssignmentsWithOriginalConfidence = data => {
  // Deep copy original data and add new field, originalSetConfidence and currentSetConfidence
  const dataCopy = {
    confirmed: [
      ...(data.confirmed?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.confirmed,
        currentSetConfidence: CONFIDENCE_CATEGORIES.confirmed
      })) ?? [])
    ],
    unconfirmed: [
      ...(data.unconfirmed?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed,
        currentSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed
      })) ?? [])
    ],
    discarded: [
      ...(data.discarded?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.discarded,
        currentSetConfidence: CONFIDENCE_CATEGORIES.discarded
      })) ?? [])
    ],
    associates: data?.associates
  };

  return dataCopy;
};

const ThemedScreening = ({
  screeningData,
  reportSubjectType,
  reportSubjectName,
  disableNewReportButton = true
}) => {
  const data = screeningData?.dowSection;

  const [transformedScreeningData, setTransformedScreeningData] = useState(
    getAssignmentsWithOriginalConfidence(data)
  );

  const [userVerifications, setUserVerifications, , setUserVerificationsCount] =
    useContext(UserVerificationContext);

  const assessmentCount = Array.from(userVerifications?.values() ?? [])?.filter(
    value => value !== 0 && value !== AssessmentOptions.NoUserAssessment
  )?.length;
  const prevAssessmentCount = usePreviousValue(assessmentCount);

  // The user undid all user assessments
  if (
    prevAssessmentCount !== 0 &&
    prevAssessmentCount !== undefined &&
    assessmentCount === 0
  ) {
    const newlyTransformedScreeningData =
      getAssignmentsWithOriginalConfidence(data);
    // Reset all user assigned confidence
    // but only if there has been a change to any of the user assigned confidences in the
    // data
    if (
      JSON.stringify(newlyTransformedScreeningData) !==
      JSON.stringify(transformedScreeningData)
    ) {
      setTransformedScreeningData(newlyTransformedScreeningData);
    }
  }

  const onConfidenceChange = (newConfidence, originalConfidence, entryId) => {
    const screeningEntryToMoveIndex = transformedScreeningData[
      originalConfidence
    ].findIndex(screeningEntry => {
      return screeningEntry.userAssessmentId === entryId;
    });
    const screeningEntryToMove =
      transformedScreeningData[originalConfidence][screeningEntryToMoveIndex];
    screeningEntryToMove.currentSetConfidence = newConfidence;
    // Insert regen ids into map and update user changes count
    const newUserVerifications = new Map(userVerifications);
    if (newConfidence !== screeningEntryToMove.originalSetConfidence) {
      // Store/update the user assigned confidence for the screening entry using its id
      newUserVerifications.set(entryId, userAssessmentMapping[newConfidence]);
      setUserVerificationsCount(prev => prev + 1);
    } else {
      newUserVerifications.set(entryId, 0); // Clear
      setUserVerificationsCount(prev => prev - 1);
    }
    setUserVerifications(newUserVerifications);
  };

  const renderResults = () => {
    return (
      <S.SectionContent>
        <EntityList
          entities={transformedScreeningData[CONFIDENCE_CATEGORIES.confirmed]}
          isHoverable
          filterBucketType={CONFIDENCE_CATEGORIES.confirmed}
          reportSubjectType={reportSubjectType}
          reportSubjectName={reportSubjectName}
          disableNewReportButton={disableNewReportButton}
          associatedEntityProfiles={transformedScreeningData?.associates}
          onConfidenceChange={onConfidenceChange}
        />
        <EntityList
          entities={transformedScreeningData[CONFIDENCE_CATEGORIES.unconfirmed]}
          isHoverable
          filterBucketType={CONFIDENCE_CATEGORIES.unconfirmed}
          reportSubjectType={reportSubjectType}
          reportSubjectName={reportSubjectName}
          disableNewReportButton={disableNewReportButton}
          associatedEntityProfiles={transformedScreeningData?.associates}
          onConfidenceChange={onConfidenceChange}
        />
        <EntityList
          entities={transformedScreeningData[CONFIDENCE_CATEGORIES.discarded]}
          isHoverable
          filterBucketType={CONFIDENCE_CATEGORIES.discarded}
          reportSubjectType={reportSubjectType}
          reportSubjectName={reportSubjectName}
          disableNewReportButton={disableNewReportButton}
          associatedEntityProfiles={transformedScreeningData?.associates}
          onConfidenceChange={onConfidenceChange}
        />
        <EntityList
          entities={
            transformedScreeningData[CONFIDENCE_CATEGORIES.userDiscarded]
          }
          isHoverable
          filterBucketType={CONFIDENCE_CATEGORIES.userDiscarded}
          reportSubjectType={reportSubjectType}
          reportSubjectName={reportSubjectName}
          disableNewReportButton={disableNewReportButton}
          associatedEntityProfiles={transformedScreeningData?.associates}
          onConfidenceChange={onConfidenceChange}
        />
      </S.SectionContent>
    );
  };

  const computeResultsCount = () => {
    const confirmedCount =
      transformedScreeningData[FILTER_TYPES.confirmed]?.length ?? 0;
    const unconfirmedCount =
      transformedScreeningData[FILTER_TYPES.unconfirmed]?.length ?? 0;
    const discardedCount =
      transformedScreeningData[FILTER_TYPES.discarded]?.length ?? 0;
    const userDiscardedCount =
      transformedScreeningData[FILTER_TYPES.userDiscarded]?.length ?? 0;
    return (
      confirmedCount + unconfirmedCount + discardedCount + userDiscardedCount
    );
  };

  if (!transformedScreeningData) {
    return null;
  }

  return (
    <>
      <S.Header id="screening">
        <S.SectionBanner>
          <S.SectionBannerInfo>
            Xapien uses data from external sources to confirm screening matches
          </S.SectionBannerInfo>
          <S.SectionCount>{computeResultsCount()}</S.SectionCount>
        </S.SectionBanner>
      </S.Header>
      {renderResults()}
      <S.Footer>
        Xapien’s underlying screening data is supplied by Dow Jones
        <S.DowJonesLogo />
      </S.Footer>
    </>
  );
};

export default ThemedScreening;
