import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { blue, grey, standardColors } from "styles/colors";

import { ReactComponent as BuildingSvg } from "img/building.svg";

const BuildingIcon = styled(BuildingSvg)`
  transform: scale(0.7);
  path {
    fill: ${standardColors.white};
`;

const Details = styled.div`
  text-align: right;
  position: relative;
  top: -0.75rem;
  right: 0.25rem;
`;

const DetailItem = styled.div`
  color: ${grey.rule};
`;

/** classNameOverrides */

const card = css`
  cursor: pointer;

  &:hover {
    outline: 2px solid ${blue.xapienLight};
  }
`;

const S = {
  BuildingIcon,
  Details,
  DetailItem
};

export const classNameOverrides = {
  card
};

export default S;
