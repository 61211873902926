/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SectionIcon {
  PROFILE = "profile",
  AWARD = "award",
  FOLDER = "folder",
  BRIEFCASE = "briefcase",
  DISABLED = "disabled",
  FILE = "file",
  DATABASE = "database",
  NOMINEE = "nominee",
  ENVIRONMENT = "environment",
  FRIENDS = "friends",
  LEGAL = "legal",
  GLOBAL = "global",
  MEASURES = "measures",
  EYE = "eye",
  PHILANTHROPY = "philanthropy",
  FINANCIAL_CRIME = "financial_crime"
}
