import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";

import { grey, red, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const ExplainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Explainer = styled.p`
  margin: 0;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const ModalActions = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Button = styled(ButtonNew)`
  flex: 1;
`;

const PrimaryButton = styled(Button)`
  &:not(:disabled, :focus, :hover) {
    background-color: ${red.directRiskFill};
    color: ${standardColors.white};
  }
`;

const S = {
  ExplainerContainer,
  Explainer,
  ModalActions,
  Button,
  PrimaryButton
};

export default S;
