import { styled } from "@linaria/react";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

import { ReactComponent as Arrow } from "img/icons/renders/arrow-rounded.svg";

const AccordionContainer = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding: 3px 0;
`;

const AccordionHeader = styled.button`
  all: unset;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${grey.mid};
  font-size: ${fontSize.sm};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const DataCount = styled.span`
  margin-right: 0.25rem;
`;

const AccordionContent = styled.div`
  padding-bottom: 1px;
  font-size: ${fontSize.sm};
`;

const ContentList = styled.ul<{ maxHeight?: string }>`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: ${props => props.maxHeight ?? "auto"};
  overflow-y: auto;
`;

const ContentItem = styled.li`
  display: flex;
  min-height: 22px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const CountAndToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CollapseArrow = styled(Arrow)`
  height: 12px;
  width: 12px;
  transform: ${({ className }) =>
    className === "isExpanded" ? "rotate(90deg)" : "rotate(0deg)"};
`;

const S = {
  AccordionContainer,
  AccordionHeader,
  AccordionContent,
  DataCount,
  ContentList,
  ContentItem,
  CountAndToggleContainer,
  CollapseArrow
};

export default S;
