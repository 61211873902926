import { getConnectedEdges, type Instance, type Node } from "reactflow";

export const getConnectedNodes = ({
  id,
  parentId,
  getNode,
  getEdges
}: {
  id: string;
  parentId?: string;
  getNode: Instance.GetNode<any>;
  getEdges: Instance.GetEdges<any>;
}): (Node<any, string | undefined> | undefined)[] => {
  const nodes = getNode(id);
  const edges = getEdges();

  if (!nodes) return [];

  const connectedEdges = getConnectedEdges([nodes], edges);

  const connectedNodeIds = connectedEdges.map(edge =>
    edge.source === id ? edge.target : edge.source
  );

  const connectedNodes = connectedNodeIds.map(nodeId => getNode(nodeId));

  if (parentId) {
    return connectedNodes.filter(node => node?.id !== parentId);
  }

  if (connectedNodes.length > 1) return connectedNodes;

  const connectedNode = connectedNodes[0];

  if (connectedNode && connectedNode.type === "EdgeLabel") {
    return getConnectedNodes({
      id: connectedNode.id,
      parentId: id,
      getNode,
      getEdges
    });
  }

  return [connectedNode];
};
