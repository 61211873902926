import React, { ReactNode } from "react";
import ReactTooltip from "components/atoms/ReactTooltip";

import type { Placement } from "@floating-ui/react";

interface Props {
  shouldRender: boolean;
  children: ReactNode;
  text: string;
  position?: Placement;
  maxWidth?: string;
  distance?: number;
  open: boolean;
  trigger?: string;
  onRequestClose?: () => void;
}

const ConditionallyRenderedTooltip = ({
  shouldRender,
  children,
  text,
  position = "bottom",
  maxWidth,
  distance,
  open,
  trigger,
  onRequestClose = () => {}
}: Props) => {
  if (shouldRender) {
    return (
      <ReactTooltip
        tooltip={text}
        position={position}
        maxWidth={maxWidth}
        distance={distance}
        open={open}
        trigger={trigger}
        onRequestClose={onRequestClose}
        className={undefined}
        style={undefined}
      >
        {children}
      </ReactTooltip>
    );
  }
  if (children) {
    return children;
  }
  return false;
};

export default ConditionallyRenderedTooltip;
