export const getDurationStringFromDates = ({
  startDate,
  endDate,
  isKnownToBeOngoing,
  lastSeen,
  showMonths = true
}) => {
  const unknownDateString = "Unknown date";
  const ongoingDateString = "Ongoing";

  let durationString = ``;

  const startMonth =
    startDate?.monthShort && showMonths ? `${startDate.monthShort} ` : "";
  const endMonth =
    endDate?.monthShort && showMonths ? `${endDate.monthShort} ` : "";
  const lastSeenMonth =
    lastSeen?.monthShort && showMonths ? `${lastSeen.monthShort} ` : "";

  if (startDate && startDate.year && endDate && endDate.year) {
    const startDurationString = `${startMonth}${startDate.year}`;
    const endDurationString = `${endMonth}${endDate.year}`;

    if (startDurationString === endDurationString) {
      // If the two strings are the same then we only need
      // to display one of them.
      durationString = startDurationString;
    } else {
      durationString = `${startDurationString} – ${endDurationString}`;
    }
  } else if (startDate) {
    let endDateString = unknownDateString;
    if (isKnownToBeOngoing) {
      endDateString = ongoingDateString;
    } else if (lastSeen?.year) {
      endDateString = `${lastSeenMonth}${lastSeen?.year} (Last seen)`;
    }
    durationString = `${startMonth}${startDate.year} – ${endDateString}`;
  } else if (endDate?.year) {
    durationString = `${unknownDateString} – ${endMonth}${endDate.year}`;
  } else if (isKnownToBeOngoing) {
    durationString = `${unknownDateString} – ${ongoingDateString}`;
  } else if (lastSeen?.year) {
    durationString = `Last seen ${lastSeenMonth}${lastSeen.year}`;
  } else {
    return null;
  }

  return durationString;
};
