import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import Button from "components/atoms/Button";
import { DiImage } from "components/molecules/DiImage";
import { standardColors, grey, blue } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const NavigationButton = styled.button`
  all: unset;
  margin-right: 10px;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

const NavigationButtonsContainer = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 16px;

  svg {
    width: 22px;
    height: 22px;

    &:hover {
      path {
        fill: ${grey.dark};
      }
    }
  }
  svg.disabled {
    path {
      fill: ${grey.light};
    }
  }
`;

const OrderButton = styled(Button)`
  background-color: ${({ active }) =>
    active ? blue.icon : standardColors.white} !important;
  color: ${({ active }) =>
    active ? standardColors.white : grey.dark} !important;
  font-size: ${fontSize.sm};
  min-height: 22px !important;
  min-width: 50px !important;
  margin-right: 6px;
  border-color: ${grey.light} !important;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
    background-color: ${blue.icon} !important;
    color: ${standardColors.white} !important;
    border: 1px solid ${blue.icon} !important;
  }
`;

const GalleryViewContainer = styled.div`
  display: flex;
  border-top: 1px solid ${grey.rule};
`;

const GalleryView = styled.div`
  max-width: 59%;
`;

const GalleryImages = styled.div`
  overflow-x: auto;
  position: relative;
  width: auto;
  max-height: 429px;

  img {
    border: 2px solid ${standardColors.white};

    &:hover {
      border: 3px solid ${blue.icon};
    }
  }
`;

const GridImage = styled(DiImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ImageBankContainer = styled.div`
  font-size: ${fontSize.sm};
`;

const Header = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const OrderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 16px;
`;

const OrderByLabel = styled.span`
  margin-right: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const FullImage = styled(DiImage)`
  object-fit: cover;
  padding-bottom: 4px;
`;

const FullImageCard = styled.div`
  font-size: ${fontSize.sm};
  line-height: 20px;
`;

const FullImageCardContainer = styled.div`
  padding-left: 34px;
  max-width: 38%;
  a:hover {
    cursor: pointer;
  }
`;

const ImageDocumentTitle = styled.div`
  font-family: ${family.interRegular};
  color: ${grey.dark};
`;

const ImageAltText = styled.div`
  font-family: ${family.interRegular};
  color: ${grey.mid};
`;

const ImageDate = styled.div`
  font-family: ${family.interLight};
  color: ${grey.mid};
`;

const ImageOriginUrl = styled.div`
  font-family: ${family.interLight};
  color: ${grey.dark};
`;

const ImageThirdParty = styled.div`
  font-family: ${family.interLight};
  color: ${grey.light};
`;

const ImageSources = styled.div`
  font-family: ${family.interLight};
  color: ${grey.dark};
  margin-top: 8px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  height: 36px;
`;

const SectionCount = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize["2xl"]};
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 4px;
  width: fit-content;
`;

const GridContainer = styled.div`
  width: 588px;
`;

/** ClassNameOverrides */

const columnFirst = css`
  grid-template-rows: repeat(3, 1fr);
  grid-auto-flow: column;
`;

const rowFirst = css`
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: rows;
`;

const twoByTwo = css`
  grid-template: 1fr 1fr / 1fr 1fr;
`;

export const classNameOverrides = {
  columnFirst,
  rowFirst,
  twoByTwo
};

const S = {
  NavigationButton,
  NavigationButtonsContainer,
  OrderButton,
  GalleryViewContainer,
  GalleryView,
  GalleryImages,
  GridImage,
  ImageBankContainer,
  Header,
  OrderButtonsContainer,
  OrderByLabel,
  FullImage,
  FullImageCard,
  FullImageCardContainer,
  ImageDocumentTitle,
  ImageAltText,
  ImageDate,
  ImageOriginUrl,
  ImageThirdParty,
  ImageSources,
  HeaderContainer,
  SectionCount,
  Grid,
  GridContainer
};

export default S;
