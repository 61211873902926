import { styled } from "@linaria/react";

import { ReactComponent as ArrowDownSvg } from "img/icons/arrow-down.svg";

import { blue, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

const InputPreview = styled.li`
  list-style-type: none;
  height: 28px;
  margin-bottom: 4px;
  background-color: ${props =>
    props.highlighted ? "rgba(40, 228, 250, 0.3)" : "transparent"};
  cursor: pointer;

  &:focus {
    background-color: rgba(40, 228, 250, 0.3);
    outline: none;
  }
`;

const Menu = styled.ul`
  z-index: 9;
  position: absolute;
  width: 387px;
  background-color: ${blue.menu};
  padding: 12px 0;
  margin-top: 12px;
  border-radius: 6px;
  /*https://github.com/downshift-js/downshift/tree/master/src/hooks/useCombobox#:~:text=defaults%20to%20ref.-,Please,-keep%20in%20mind*/
  visibility: ${props =>
    props.isOpen && props.isVisible ? "visible" : "hidden"};
`;

const MenuItem = styled.li`
  cursor: pointer;
  background-color: ${props =>
    props.highlighted ? "rgba(40, 228, 250, 0.3)" : "transparent"};
  list-style-type: none;
  line-height: 28px;
  height: 28px;
  padding-left: 12px;

  &:hover,
  &:focus {
    background-color: rgba(40, 228, 250, 0.3);
    outline: none;
  }
`;

const ComboBox = styled.div`
  display: flex;
  width: 387px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid ${standardColors.white};
  box-shadow: 0 0 0 4px
    ${props =>
      props.isFocused ? props.boxShadowColor ?? "rgb(40 239 250 / 33%)" : ""};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

const Input = styled.input`
  border: none;
  height: 36px;
  border-radius: 18px;
  font-family: ${family.interLight};
  box-shadow: none;
  width: 100%;
  margin: 0;
  padding: 5px 0 7px 13px;
  font-size: ${fontSize.md};
  line-height: 1.5;
  background-color: transparent;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    opacity: 0.6;
  }

  &:focus {
    outline: 0;
  }
`;

const DropdownButton = styled.button`
  all: unset;
  border-radius: 18px;
  width: 36px;

  &:focus {
    box-shadow: 0 0 0 4px
      ${props => props.boxShadowColor ?? "rgb(40 239 250 / 33%)"} !important;
    outline: none;
  }
`;

const ArrowDown = styled(ArrowDownSvg)`
  margin-left: 11px;
  path {
    fill: ${standardColors.white};
  }
`;

const Info = styled.span`
  opacity: 0.7;
`;

const MenuItemsContainer = styled.div`
  margin-top: 4px;
  max-height: 140px;
  overflow: auto;
`;

const InputPreviewText = styled.div`
  line-height: 28px;
  margin-left: 12px;
  padding-bottom: 5px;
`;

const Rule = styled.div`
  border-bottom: 1px solid rgba(242, 244, 245, 0.4);
  margin-left: 12px;
`;

const S = {
  InputPreview,
  Menu,
  MenuItem,
  ComboBox,
  Input,
  DropdownButton,
  ArrowDown,
  Info,
  MenuItemsContainer,
  InputPreviewText,
  Rule
};

export default S;
