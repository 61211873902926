import { styled } from "@linaria/react";
import { X } from "react-feather";

import { blue, standardColors, grey } from "styles/colors";
import { CardVariant } from "components/molecules/Card/types";

import { ChipSize, ChipVariant } from "./types";

const getChipFontSize = (
  chipSize: ChipSize
): { fontSize: string; lineHeight: string } => {
  switch (chipSize) {
    case ChipSize.Large:
      return { fontSize: "0.875rem", lineHeight: "1.875rem" };
    case ChipSize.Medium:
      return { fontSize: "0.75rem", lineHeight: "1.25rem" };
    default:
      return { fontSize: "0.625rem", lineHeight: "0.75rem" };
  }
};

const getChipBorderRadius = (chipSize: ChipSize): string => {
  switch (chipSize) {
    case ChipSize.Large:
      return "0.5rem";
    case ChipSize.Medium:
      return "0.25rem";
    default:
      return "0.125rem";
  }
};

const getChipPadding = (chipSize: ChipSize): string => {
  switch (chipSize) {
    case ChipSize.Large:
      return "0.125rem 0.5rem";
    case ChipSize.Medium:
      return "0.125rem 0.375rem";
    default:
      return "0.125rem 0.25rem";
  }
};

const XIcon = styled(X)`
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s;
  height: 1rem;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.2);
    outline: none;
  }
`;

const Chip = styled.div<{
  size: ChipSize;
  variant?: ChipVariant;
  colorVariant: CardVariant;
}>`
  border: 1px solid
    ${({ colorVariant, variant }) => {
      if (colorVariant === CardVariant.dark) {
        if (variant === ChipVariant.FilledLight) {
          return standardColors.white;
        }
        return grey.rule;
      }
      return blue.xapienDark;
    }};
  height: fit-content;
  padding: ${({ size }) => getChipPadding(size)};
  border-radius: ${props => getChipBorderRadius(props.size)};
  font-size: ${props => getChipFontSize(props.size).fontSize};
  line-height: ${props => getChipFontSize(props.size).lineHeight};
  background-color: ${({ variant }) => {
    if (variant === ChipVariant.FilledDark) {
      return blue.xapienDark;
    }
    if (variant === ChipVariant.FilledLight) {
      return standardColors.white;
    }
    return "transparent";
  }};
  color: ${({ variant, colorVariant }) => {
    if (variant === ChipVariant.FilledDark) {
      return standardColors.white;
    }
    if (variant === ChipVariant.FilledLight) {
      return blue.xapienDark;
    }
    if (colorVariant === CardVariant.dark) {
      return grey.rule;
    }
    return blue.xapienDark;
  }};
  display: flex;
  align-items: center;
  gap: 0.125rem;

  ${XIcon} {
    stroke: ${({ variant }) => {
      if (variant === ChipVariant.FilledDark) {
        return standardColors.white;
      }
      return grey.dark;
    }};
  }
`;

const S = {
  Chip,
  XIcon
};

export default S;
