import React, { FC, useState } from "react";

import { Lock } from "react-feather";
import ModalContainer from "components/molecules/ModalContainer";
import ErrorBanner from "components/atoms/ErrorBanner";
import TitleCard from "components/molecules/TitleCard";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Input from "components/atoms/Input";

import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";

import GroupsApi, { stripGroupName } from "api/groups";

import useUserSettings from "util/hooks/useUserSettings";
import S from "./styles";

interface Props {
  isOpen: boolean;
  onToggleOpen: () => void;
}

const CreateGroupModal: FC<Props> = ({ isOpen, onToggleOpen }) => {
  const { dispatch: listDispatch } = useCollectionList();
  const {
    state: {
      userDetails: { firstName, lastName, email }
    },
    addUserGroup
  } = useUserSettings();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const onClose = () => {
    onToggleOpen();
  };

  const onSetName = (value: string) => {
    let modifiedValue = value;

    modifiedValue = modifiedValue.replace(" ", "-");

    if (value !== "" && value.charAt(0) !== "@") {
      modifiedValue = `@${value}`;
    }

    if (modifiedValue.charAt(1) === "@") {
      modifiedValue = modifiedValue.slice(0, 1) + modifiedValue.slice(2);
    }

    setName(modifiedValue);
  };

  const onDismissError = () => setHasError(false);

  const onSubmit = () => {
    setIsLoading(true);
    setErrorMessage(undefined);
    setHasError(false);

    const api = new GroupsApi();

    api
      .createGroup(name, description)
      .then(response => {
        setIsLoading(false);
        if (response.status) {
          addUserGroup({
            groupId: response.response as string,
            name: stripGroupName(name),
            role: "Admin"
          });
          listDispatch({
            type: CollectionListActions.addCollectionItem,
            id: "groups",
            item: {
              id: response.response as string,
              icon: "group",
              title: stripGroupName(name),
              context: description,
              description: "1 members",
              memberCount: 1,
              permissions: {
                canView: true,
                canEdit: true,
                canViewMembers: true,
                canEditMembers: true,
                canEditAdmins: true,
                canLeave: true,
                canViewReports: true,
                canDelete: true
              },
              tags: []
            }
          });
          setName("");
          setDescription("");
          onClose();
        } else {
          setHasError(true);
          setErrorMessage(response.message);
        }
      })
      .catch(e => {
        console.error(e);
        setIsLoading(false);
        setHasError(true);
      });
  };

  return (
    <ModalContainer
      title="Create new group"
      width={688}
      isOpen={isOpen}
      toggleOpen={onClose}
      onExitClick={onClose}
    >
      {hasError && (
        <ErrorBanner
          text={
            errorMessage ||
            "There has been a problem with your request. Please check and try again."
          }
          onClick={onDismissError}
        />
      )}

      <S.Container>
        <Input
          placeholder="@Group"
          inputType="text"
          onChange={onSetName}
          value={name}
          label="Enter the group name"
        />

        <Input
          placeholder="Description"
          inputType="text"
          onChange={setDescription}
          value={description}
          label="Enter a short description (optional)"
        />

        <S.Divider />

        <S.Title level={6}>Group admin (you)</S.Title>

        <TitleCard
          title={`${firstName} ${lastName}`}
          subtitle={email}
          rightIcon={<Lock />}
        />

        <S.Subtext>You can manage group admins later</S.Subtext>

        <S.Buttons>
          <ButtonNew
            onClick={onToggleOpen}
            type={ButtonType.Outlined}
            size={ButtonSize.Medium}
            text="Cancel"
          />

          <ButtonNew
            onClick={onSubmit}
            type={ButtonType.Filled}
            size={ButtonSize.Medium}
            disabled={isLoading || name.length === 0}
            text={isLoading ? "Submitting..." : "Create new group"}
          />
        </S.Buttons>
      </S.Container>
    </ModalContainer>
  );
};

export default CreateGroupModal;
