import React, { CSSProperties, ReactNode } from "react";

import Heading from "components/atoms/Heading";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";

import S from "./styles";

interface Props {
  imageSrc?: string;
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const TitleCard = ({
  imageSrc,
  title,
  subtitle,
  children,
  icon,
  rightIcon,
  style,
  className
}: Props) => {
  return (
    <S.TitleCard style={style} className={className}>
      <S.Container>
        <S.TopContent>
          {imageSrc ? (
            <Avatar avatarSize={AvatarSize.Large} imgSrc={imageSrc} />
          ) : (
            <Avatar avatarSize={AvatarSize.Large} hasBorder>
              {icon}
            </Avatar>
          )}
          <S.TitleContainer>
            {title && <Heading level={5}>{title}</Heading>}
            <S.SubHeading level={5}>{subtitle}</S.SubHeading>
          </S.TitleContainer>
        </S.TopContent>
        {rightIcon && <S.RightIcon>{rightIcon}</S.RightIcon>}
      </S.Container>
      {children}
    </S.TitleCard>
  );
};

export default TitleCard;
