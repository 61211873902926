import React, { FC } from "react";
import { v4 as uuidv4 } from "uuid";

import { CardVariant } from "components/molecules/Card/types";
import type { CollectionListView } from "components/molecules/CollectionListControls";

import { COLLECTION_ITEMS_PER_PAGE } from "util/hooks/useCollectionList/types";

const LoadingCollectionCards: FC<{
  cardVariant: CardVariant;
  CardComponent: FC<any>;
  view: CollectionListView;
}> = ({ cardVariant, CardComponent, view }) => {
  return (
    <>
      {Array.from({ length: COLLECTION_ITEMS_PER_PAGE }).map(() => (
        <CardComponent
          key={uuidv4()}
          loading
          variant={cardVariant}
          view={view}
        />
      ))}
    </>
  );
};

export default LoadingCollectionCards;
