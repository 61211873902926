import { styled } from "@linaria/react";

const MenuButton = styled.button`
  all: unset;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const S = {
  MenuButton
};

export default S;
