import React from "react";
import { NavItem } from "reactstrap";

import ReactTooltip from "components/atoms/ReactTooltip";

import { useNavigate } from "react-router-dom";
import { Layers } from "react-feather";
import S from "./styles";

interface Props {
  enquiryId: string;
}

const ViewERReportButton = ({ enquiryId }: Props) => {
  const navigate = useNavigate();

  return (
    <NavItem onClick={() => navigate(`/report/summary/${enquiryId}`)}>
      <S.ViewERButtonContainer>
        <ReactTooltip
          tooltip="View ER report"
          open={undefined}
          style={undefined}
          className={undefined}
        >
          <S.ViewERButton aria-label="View ER report">
            <Layers size={12} />
          </S.ViewERButton>
        </ReactTooltip>
      </S.ViewERButtonContainer>
    </NavItem>
  );
};

export default ViewERReportButton;
