import React, { useRef } from "react";

import { delay, scrollToTopOfComponent } from "util/common";

import S from "./styles";

const ExpandingList = ({
  renderContent,
  listData,
  collapsedElementCount,
  isFullyExpanded,
  setFullyExpanded,
  animate,
  sectionRef
}) => {
  const contentRef = useRef(null);
  const lastCollapsedHeight = useRef(0);
  const lastExpandedHeight = useRef(0);
  const expandContractDuration = 800; // coupled to CSS transition
  const listLength = listData.length;
  const numberToDisplay = isFullyExpanded
    ? listLength
    : Math.min(listLength, collapsedElementCount);
  const expandable = collapsedElementCount < listLength;

  const setFullyExpandedInternal = async v => {
    const element = contentRef && contentRef.current;
    const currentHeight = element && element.offsetHeight;
    const newNumberToDisplay = v
      ? listLength
      : Math.min(listLength, collapsedElementCount);

    // estimate the height the list will be next, will only be used if no relevant previous height to use
    const projectedHeight =
      (currentHeight * newNumberToDisplay) / numberToDisplay;

    if (
      element &&
      currentHeight &&
      animate &&
      newNumberToDisplay !== numberToDisplay
    ) {
      // eg. for when currently hidden, don't do animation
      element.style.removeProperty("max-height");
      element.style.maxHeight = `${currentHeight}px`;
      await delay(0);
      if (newNumberToDisplay > numberToDisplay) {
        // expanding
        lastCollapsedHeight.current = currentHeight;
        setFullyExpanded(true);
        // console.log("Running expansion animation", { lastExpandedHeight: lastExpandedHeight.current, currentHeight,  numberToDisplay, newNumberToDisplay});
        element.style.maxHeight = `${
          lastExpandedHeight.current || projectedHeight
        }px`;
        await delay(expandContractDuration);
      } else if (newNumberToDisplay < numberToDisplay) {
        // collapsing
        lastExpandedHeight.current = currentHeight;
        element.style.maxHeight = `${
          lastCollapsedHeight.current || projectedHeight
        }px`;
        await delay(expandContractDuration);
        setFullyExpanded(false);
        scrollToTopOfComponent(sectionRef, true);
      }

      // unsetting max-height means we don't have to worry about being exact for the values of max-height used for the transition above,
      // the worst that will happen is a small jump at the end of a transition
      element.style.removeProperty("max-height");
    } else {
      setFullyExpanded(v);
      if (!v) {
        scrollToTopOfComponent(sectionRef, true);
      }
    }
  };

  const toggleFullyExpanded = () => {
    setFullyExpandedInternal(!isFullyExpanded);
  };

  const renderExpandButton = () => {
    if (!expandable) return false;

    return (
      <S.ShowResultsButton
        resultsCount={listLength}
        onShowResultsClick={toggleFullyExpanded}
        isShowingResults={isFullyExpanded}
      />
    );
  };

  return (
    <>
      <S.ListContainer ref={sectionRef}>
        {renderContent(listData.slice(0, numberToDisplay))}
      </S.ListContainer>
      {!expandable && <S.CustomSectionFooter />}
      {renderExpandButton()}
    </>
  );
};

export default ExpandingList;
