import React, { FC, useRef, useEffect } from "react";

import {
  useCollectionList,
  CollectionListActions
} from "util/hooks/useCollectionList";
import S from "./styles";

const CollectionEnd: FC<{ id: string }> = ({ id }) => {
  const { dispatch } = useCollectionList();
  const collectionEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          dispatch({
            type: CollectionListActions.updateCollectionPage,
            id
          });
        }
      },
      { threshold: 1 }
    );

    let collectionEndRefValue: HTMLDivElement | null = null;

    if (collectionEndRef.current) {
      observer.observe(collectionEndRef.current);
      collectionEndRefValue = collectionEndRef.current;
    }

    return () => {
      if (collectionEndRefValue) {
        observer.unobserve(collectionEndRefValue);
      }
    };
  }, [collectionEndRef, dispatch, id]);

  return <S.CollectionEnd ref={collectionEndRef} />;
};

export default CollectionEnd;
