/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Idam_Contracts_Enums_UsagePeriod {
  REPORTS_RUN_PAST_YEAR = "ReportsRunPastYear",
  REPORTS_RUN_PAST30DAYS = "ReportsRunPast30Days",
  REPORTS_RUN_PAST7DAYS = "ReportsRunPast7Days",
  REPORTS_RUN_SINCE_ORGANISATION_CREATION = "ReportsRunSinceOrganisationCreation"
}
