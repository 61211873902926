import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import ReactTooltip from "components/atoms/ReactTooltip";

const Tooltip = styled(ReactTooltip)`
  display: inherit;
`;

/** ClassNameOverrides */

const tooltipClass = css`
  display: none;
`;

export const classNameOverrides = {
  tooltipClass
};

const S = {
  Tooltip
};

export default S;
