import React, { forwardRef } from "react";

import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";

import ExpandingList from "./ExpandingList";
import { Section } from "./Section";

const SectionWithExpandingList = forwardRef((props, ref) => {
  const {
    title,
    renderContent,
    listData,
    collapsedElementCount,
    children,
    isExpanded,
    setExpanded
  } = props;
  const [isFullyExpanded, setFullyExpanded] = usePrintableReportState(
    `${title}-expanding-list-fully-expanded`,
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const setExpandedState = value => {
    if (value && !isExpanded) {
      setFullyExpanded(false);
    }
    setExpanded(value);
  };

  return (
    <Section
      {...props}
      {...{ isExpanded }}
      setExpanded={setExpandedState}
      ref={ref}
    >
      {children}
      <ExpandingList
        {...{
          isFullyExpanded,
          setFullyExpanded,
          renderContent,
          listData,
          collapsedElementCount
        }}
        sectionRef={ref}
        animate={isExpanded}
      />
    </Section>
  );
});

export default SectionWithExpandingList;
