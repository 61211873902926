import {
  AutoSharePermission,
  SharedWithGroup,
  SharedWithUser
} from "api/user/types";
import { SearchItemType } from "api/search";

export interface ShareItem {
  title: string;
  subtitle: string;
  type: SearchItemType;
  id: string;
  permission: AutoSharePermission;
}

export interface UserPreferencesState {
  sharedWithUsers: SharedWithUser[];
  sharedWithGroups: SharedWithGroup[];
  sharedWith: ShareItem[];
  autoshare: boolean;
}

export enum UserPreferencesActions {
  updateAutoSharedWith = "UpdateAutoSharedWith",
  updateAutoShareEnabled = "UpdateAutoShareEnabled",
  resetUserPreferences = "resetUserPreferences"
}

interface UpdateAutoSharedWith {
  type: UserPreferencesActions.updateAutoSharedWith;
  sharedWithUsers: SharedWithUser[];
  sharedWithGroups: SharedWithGroup[];
  autoshare?: boolean;
}

interface UpdateAutoShareEnabled {
  type: UserPreferencesActions.updateAutoShareEnabled;
  autoshare: boolean;
}

interface ResetUserPreferences {
  type: UserPreferencesActions.resetUserPreferences;
}

export type UserPreferencesAction =
  | UpdateAutoSharedWith
  | UpdateAutoShareEnabled
  | ResetUserPreferences;
