import { styled } from "@linaria/react";
import { blue, grey, standardColors } from "styles/colors";

import { ReactComponent as BuildingSvg } from "img/building.svg";
import { css } from "@linaria/core";
import { fontSize } from "styles/fonts";

const Rule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${grey.mid};
`;

const BuildingIcon = styled(BuildingSvg)`
  transform: scale(0.8);
  path {
    fill: ${standardColors.white};
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-top: 1px solid ${grey.mid};
  border-bottom: 1px solid ${grey.mid};
  padding: 1rem 0;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Actions = styled.div`
  display: flex;
  gap: 0.75rem;

  & > button {
    flex: 1;
  }
`;

const NotifyOwnerOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: ${fontSize.sm};

  & input {
    width: 1.125rem;
    height: 1.125rem;
  }

  & input:checked {
    accent-color: ${blue.xapienLight};
  }

  & label {
    margin: 0;
  }
`;

const ConfirmedCopyModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${grey.mid};
  padding-top: 1.5rem;
`;

const AccessLevel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: ${fontSize.sm};
  padding-left: 3.875rem;
  padding-right: 1rem;

  & > div:first-of-type {
    color: ${grey.rule};
  }
`;

const NotifyOwnerConfirmation = styled.p`
  margin: 0;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

/** ClassNameOverrides */

const selectGroupInput = css`
  padding: 0.5rem 0.75rem;
  min-height: 3rem;
`;

export const classNameOverrides = {
  selectGroupInput
};

const S = {
  Rule,
  BuildingIcon,
  Inputs,
  InputGroup,
  Actions,
  NotifyOwnerOption,
  ConfirmedCopyModalContent,
  AccessLevel,
  NotifyOwnerConfirmation
};

export default S;
