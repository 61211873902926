import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";
import Api from "api/groups";

const useDeleteGroup = () => {
  const [{ fetching, error, success, errorMessage }, dispatch] =
    useFetchReducer();
  const { dispatch: collectionsDispatch } = useCollectionList();

  const GroupsApi = new Api();

  const deleteGroup = async (groupId: string): Promise<boolean> => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await GroupsApi.deleteGroup(groupId);

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return false;
    }

    dispatch({ type: RequestActions.SetSuccess });
    collectionsDispatch({
      type: CollectionListActions.deleteCollectionItem,
      itemId: groupId
    });
    return true;
  };

  const reset = () => {
    dispatch({ type: RequestActions.Reset });
  };

  return { fetching, error, errorMessage, success, reset, deleteGroup };
};

export default useDeleteGroup;
