import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import ButtonNew from "components/atoms/ButtonNew";

import { blue, grey, red } from "styles/colors";
import { fontSize } from "styles/fonts";
import { Edit } from "react-feather";

const AddUserControlContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 1rem 0;
`;

const Explainer = styled.p`
  margin: 0;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const DoneButton = styled(ButtonNew)`
  flex: 1;
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-top: 1px solid ${grey.rule};
  padding-top: 1.5rem;
`;

const MembersHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MembersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-height: 12.5rem;
  overflow: auto;
`;

const MembersError = styled.p`
  margin: 0;
  color: ${grey.ghost};
`;

const GroupDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  background-color: ${blue.xapienDark};
  border-radius: 0.75rem;
  align-items: center;
`;

const EditIcon = styled(Edit)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
  cursor: pointer;
`;

const GroupDetailsContentContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  flex: 1;
`;

const Details = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const EditDetailsTooltipContent = styled.div`
  padding: 0.875rem;
  font-size: ${fontSize.sm};
  white-space: nowrap;
`;

const Input = styled.input`
  all: unset;
  background-color: transparent;
  width: 100%;

  &::placeholder {
    opacity: 0.5;
  }
`;

const GroupNameInput = styled(Input)`
  font-size: ${fontSize.lg};
  line-height: 1.625rem;
`;

const GroupDescriptionInput = styled(Input)`
  line-height: 1.5rem;
  color: ${grey.rule};
`;

/** ClassNameOverrides */

const optionButton = css`
  & button:disabled {
    background-color: transparent !important;
  }
`;

const remove = css`
  & > button {
    color: ${red.directRiskOutline};

    & p {
      color: ${red.directRiskOutline};
    }
  }
`;

export const classNameOverrides = {
  remove,
  optionButton
};

const S = {
  AddUserControlContainer,
  Explainer,
  DoneButton,
  MembersContainer,
  MembersHeader,
  MembersList,
  MembersError,
  GroupDetailsContainer,
  EditIcon,
  GroupDetailsContentContainer,
  Details,
  EditDetailsTooltipContent,
  GroupDescriptionInput,
  GroupNameInput
};

export default S;
