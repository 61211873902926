import React, { useState, Fragment } from "react";
import ReactTooltip from "components/atoms/ReactTooltip";
import NewReportModal from "components/organisms/NewReportModal";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import useViewerMode from "util/hooks/useViewerMode";
import { SubjectType } from "api/enquiry/types";

import S from "./styles";

const NewReportButton = ({
  subject,
  contextList,
  name,
  isDisabled = false
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isViewerModeEnabled } = useViewerMode();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const renderTooltipContent = () => {
    if (isDisabled) {
      return "Coming soon";
    }

    if (isViewerModeEnabled) {
      return "You are viewing this report in Viewer mode.";
    }
    return (
      <>
        Run new report on <div>{name}</div>
      </>
    );
  };

  return (
    <>
      <S.ButtonContainer>
        <ReactTooltip
          layoutPosition="fixed"
          tooltip={renderTooltipContent()}
          position="bottom"
          maxWidth="200px"
        >
          <ButtonNew
            onClick={toggleModal}
            type={ButtonType.OutlinedLight}
            size={ButtonSize.ExtraSmall}
            text="New report"
            disabled={isViewerModeEnabled || isDisabled}
          />
        </ReactTooltip>
      </S.ButtonContainer>
      <NewReportModal
        isOpen={isModalOpen}
        label={subject}
        subjectType={SubjectType.Person}
        contexts={contextList}
        onClose={toggleModal}
      />
    </>
  );
};

export default NewReportButton;
