import React, { FC } from "react";

import S from "./styles";

export interface LocationRiskIconProps {
  assigner: string;
}

const LocationRiskIcon: FC<LocationRiskIconProps> = ({ assigner }) => {
  if (assigner === "EU") {
    return <S.European />;
  }

  if (assigner === "TransparencyInternational") {
    return <S.Transparency />;
  }

  if (assigner === "HMTreasury") {
    return <S.Treasury />;
  }

  return null;
};

export default LocationRiskIcon;
