import React, { Fragment } from "react";

import Assertable from "util/Assertable";

export const formatForDisplay = (
  value,
  assertionTemplate,
  doesStringValueContainMultipleAssertableValues = false,
  shouldPipeSeparate = false
) => {
  const makeAssertable = v => (
    <Assertable
      template={
        doesStringValueContainMultipleAssertableValues
          ? assertionTemplate(v.text)
          : assertionTemplate
      }
    >
      {v.text}
    </Assertable>
  );

  const prepareWithPipes = item => {
    // Backwards compat with fields containing the "values" property
    const items = Object.prototype.hasOwnProperty.call(item, "values")
      ? item.values
      : item;
    return items?.length ? (
      <>
        {items.map((v, i) => (
          <Fragment key={`WithPipes-${v.text}`}>
            {makeAssertable(v)}
            {i !== items.length - 1 && " | "}
          </Fragment>
        ))}
      </>
    ) : (
      <>{makeAssertable(item)}</>
    );
  };

  if (shouldPipeSeparate) {
    return prepareWithPipes(value);
  }

  return Array.isArray(value) && value.length >= 0
    ? value.map((item, i) => (
        <Fragment
          key={`Text-${
            item.length > 0
              ? item.map(subitem => subitem.text).join()
              : item.text
          }`}
        >
          {prepareWithPipes(item)}
          {i !== value.length - 1 && ", "}
        </Fragment>
      ))
    : value;
};
