import React, { ReactNode, useCallback, useMemo, useState } from "react";

import { PopoverModalContext } from "./context";
import { PopoverModalState } from "./types";

export const PopoverModalContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [popover, setPopover] = useState<ReactNode | undefined>(undefined);
  const [x, setX] = useState<null | number>(null);
  const [y, setY] = useState<null | number>(null);

  const resetState = useCallback(() => {
    setPopover(undefined);
    setX(null);
    setY(null);
  }, [setPopover, setX, setY]);

  const providerValue: PopoverModalState = useMemo(
    () => ({
      popover,
      setPopover,
      x,
      y,
      setX,
      setY,
      resetState
    }),
    [popover, setPopover, x, setX, y, setY, resetState]
  );

  return (
    <PopoverModalContext.Provider value={providerValue}>
      {popover}
      {children}
    </PopoverModalContext.Provider>
  );
};
