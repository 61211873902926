import React, { FC, useRef, useEffect } from "react";

import {
  useCollectionList,
  CollectionListActions
} from "util/hooks/useCollectionList";
import S from "./styles";

const CollectionStart: FC<{ id: string }> = ({ id }) => {
  const { dispatch } = useCollectionList();
  const collectionStartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          dispatch({ type: CollectionListActions.enablePolling, id });
        } else {
          dispatch({ type: CollectionListActions.disablePolling, id });
        }
      },
      { threshold: 1 }
    );

    let collectionStartRefValue: HTMLDivElement | null = null;

    if (collectionStartRef.current) {
      observer.observe(collectionStartRef.current);
      collectionStartRefValue = collectionStartRef.current;
    }

    return () => {
      if (collectionStartRefValue) {
        observer.unobserve(collectionStartRefValue);
      }
    };
  }, [collectionStartRef, dispatch, id]);

  return <S.CollectionStart ref={collectionStartRef} />;
};

export default CollectionStart;
