import { styled } from "@linaria/react";

import { ReactComponent as SpecialInterestSvg } from "img/icons/special-interest-icon.svg";
import { ReactComponent as RCASanctionSvg } from "themes/projectdx/img/icons/rca-sanctions-icon.svg";
import { ReactComponent as RCAWatchlistSvg } from "themes/projectdx/img/icons/rca-watchlists-icon.svg";
import { ReactComponent as RCAPepsSvg } from "themes/projectdx/img/icons/rca-peps-icon.svg";
import { ReactComponent as RCASpecialInterestSvg } from "themes/projectdx/img/icons/rca-specialinterest-icon.svg";
import { grey, standardColors } from "styles/colors";

import { fontSize } from "styles/fonts";

const riskIconStyles = {
  height: "62px",
  marginBottom: "1px"
};

const SpecialInterestIcon = styled(SpecialInterestSvg)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const RCASanctionIcon = styled(RCASanctionSvg)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const RCAWatchlistIcon = styled(RCAWatchlistSvg)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const RCAPepsIcon = styled(RCAPepsSvg)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const RCASpecialInterestIcon = styled(RCASpecialInterestSvg)`
  ${riskIconStyles};

  path {
    fill: ${({ color }) => color};
  }
`;

const ExplainerTooltipSection = styled.div`
  padding-top: 16px;
`;

const Category = styled.b`
  font-size: ${fontSize.sm};
`;

const SpecialInterestHeaderLabel = styled.p`
  margin: 0 0 6px;
`;

const SpecialInterestCategoriesAnalystNotesHeaderLabel = styled.strong`
  font-size: ${fontSize.sm};
  margin: 0 0 6px;
`;

const SpecialInterestCategoriesList = styled.ul`
  padding-left: 28px;
  margin: 0;
`;

const SpecialInterestPopoverContent = styled.div`
  font-size: ${fontSize.sm};
  padding: 16px 12px;
  max-height: 270px;
  overflow-y: auto;
`;
const MultipleSpecialInterestPopoverContent = styled(
  SpecialInterestPopoverContent
)`
  background-color: ${standardColors.white};
  padding: 0;
`;

const AnalystNote = styled.div`
  margin-top: 10px;
`;

const Wrapper = styled.div`
  margin: 14px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SIContainer = styled.div`
  margin: 14px;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${grey.lighterPanel};
`;

const Divider = styled.hr`
  width: 100%;
  margin: 10px 0 10px 0;
  height: 2px;
  border-top-color: ${grey.rule};
`;

const LowerThresholdItem = styled.li`
  ::before {
    content: "– "; /* Add em dash before each list item */
  }
  list-style-type: none;
  padding: 0;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  font-weight: bold;
`;

const S = {
  SpecialInterestIcon,
  RCASanctionIcon,
  RCAWatchlistIcon,
  RCAPepsIcon,
  SIContainer,
  RCASpecialInterestIcon,
  ExplainerTooltipSection,
  Category,
  Divider,
  AnalystNote,
  SpecialInterestHeaderLabel,
  SpecialInterestCategoriesAnalystNotesHeaderLabel,
  SpecialInterestCategoriesList,
  SpecialInterestPopoverContent,
  MultipleSpecialInterestPopoverContent,
  Wrapper,
  LowerThresholdItem
};

export default S;
