import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";
import { grey, green, red } from "styles/colors";

const CardContentContainer = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
`;

const OrgName = styled.div`
  font-size: ${fontSize.md};
  color: ${grey.dark};
  font-family: ${family.interSemiBold};
`;

const DataKey = styled.div`
  color: ${grey.mid};
`;

const Industry = styled.div`
  display: flex;
  white-space: pre-wrap;
`;

const NoneIdentified = styled.div`
  color: ${grey.ghost};
  font-style: italic;
`;

const MidContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid ${grey.rule};
  padding-top: 8px;
  margin-top: 8px;
`;

const Column = styled.div`
  & > div:not(:first-child) {
    margin-top: 8px;
  }
`;

const ParentName = styled.div`
  display: flex;
  max-width: 180px;
`;

const ParentNamesAdditionalCount = styled.div`
  color: ${grey.mid};
  white-space: pre;
`;

const OrgStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${grey.rule};
  padding-top: 10px;
  margin-top: 8px;
  row-gap: 6px;
`;

const Pill = styled.div`
  display: inline-flex;
  align-items: center;
  height: 22px;
  padding: 0px 8px;
  border-radius: 16px;
  margin-right: 6px;
  transition: box-shadow 0.2s;
  font-size: ${fontSize.sm};
  max-width: 165px;
`;

const RiskPill = styled(Pill)`
  border: 1px solid ${red.directRisk};
  color: ${red.directRisk};
`;

const StatusPill = styled(Pill)`
  border: 1px solid ${props => props.color ?? green.secondary};
  color: ${props => props.color ?? green.secondary};
  opacity: 0.6;
`;

const OrganisationType = styled.div`
  max-width: 170px;
`;

const IndustryValue = styled.div`
  max-width: 220px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const S = {
  CardContentContainer,
  OrgName,
  DataKey,
  Industry,
  NoneIdentified,
  MidContent,
  Column,
  ParentName,
  ParentNamesAdditionalCount,
  OrgStatus,
  RiskPill,
  StatusPill,
  OrganisationType,
  IndustryValue,
  TopContent
};

export default S;
