export const SPECIAL_INTEREST_PERSON_FLAG = "Special Interest Person (SIP)";
export const SPECIAL_INTEREST_ENTITY_FLAG = "Special Interest Entity (SIE)";
export const STATE_OWNED_COMPANY_FLAG = "State Owned Company";
export const dowJonesSpecialInterestCategoriesToFilterBy = {
  organisation: [
    "Competitive/Financial",
    "Corruption",
    "Environment/Production",
    "Financial Crime",
    "Organised Crime",
    "Organised Crime Japan",
    "Regulatory",
    "Social/Labour",
    "Terror",
    "Trafficking"
  ],
  person: [
    "Corruption",
    "Financial Crime",
    "Trafficking",
    "Organised Crime",
    "Organised Crime Japan",
    "Tax Crime",
    "Terror"
  ]
};
