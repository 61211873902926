import { apm } from "@elastic/apm-rum";
import {
  Idam_Contracts_Reports_SearchRequest,
  IdentityOrganisationApiService
} from "api/portal";
import { Report } from "api/reports";
import {
  buildReportCardTags,
  buildReportScreeningFlags,
  mapReportStateToStatus,
  mapReportType
} from "api/reports/utils";
import { SearchResult } from "api/search";
import { CollectionListView } from "components/molecules/CollectionListControls";
import {
  CollectionInputType,
  CollectionListState,
  CollectionStatus
} from "util/hooks/useCollectionList/types";
import { CollectionSearchState } from "util/hooks/useCollectionSearch/types";
import { isLegalEntityReport } from "util/isLegalEntityReport";

export const USER_REPORTS_COLLECTION_ID = "user-reports";

export default class UserReports {
  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: USER_REPORTS_COLLECTION_ID,
          title: "Reports",
          items: [],
          status: CollectionStatus.stale,
          totalItemCount: 0,
          view: CollectionListView.list,
          offset: 0,
          limit: 16,
          order: "desc",
          hiddenIfEmpty: false,
          input: {
            type: CollectionInputType.list
          },
          hidden: false,
          pollingEnabled: true
        }
      ]
    };
  }

  async list({
    limit,
    offset,
    userId,
    filterByUserId
  }: {
    limit: number;
    offset: number;
    userId: string;
    filterByUserId?: string;
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    if (!filterByUserId) {
      return { items: [], totalItemCount: 0 };
    }

    try {
      const { reports, total: totalItemCount } =
        await IdentityOrganisationApiService.postOrganisationsUsersReportsSearch(
          { userId: filterByUserId, requestBody: { start: offset, limit } }
        );

      if (!reports) return { items: [], totalItemCount: 0 };

      const items = reports
        .filter(Boolean)
        .map(
          ({
            reportId,
            imageUrl,
            displayName,
            groups,
            contexts,
            startTime,
            state,
            sanctions,
            watchLists,
            pepsOrStateOwned,
            rcas,
            sips,
            flags,
            owner,
            project,
            permissions,
            monitoring,
            type
          }) => {
            // TODO: The endpoint isn't returning legal entity type so this is the only way determine if the source has sayari.
            const isLegalEntityReportType = isLegalEntityReport(contexts);
            const context = isLegalEntityReportType
              ? ""
              : contexts?.join(", ") || "";

            const reportType = mapReportType(type, isLegalEntityReportType);

            const tags = buildReportCardTags({
              currentUserId: userId,
              reportOwnerUserId: owner?.userId!,
              groupsReportIsPartOf: groups!,
              reportType
            });

            const risks = buildReportScreeningFlags({
              sanctions: sanctions ?? 0,
              watchLists: watchLists ?? 0,
              pepsOrStateOwned: pepsOrStateOwned ?? 0,
              flags: flags ?? 0,
              rcas: rcas ?? 0,
              sips: sips ?? 0
            });

            const author =
              owner?.firstName || owner?.lastName
                ? `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`
                : "unknown";

            return {
              id: reportId ?? "unknown",
              imageSrc: imageUrl ?? "",
              status: mapReportStateToStatus(state),
              title: displayName ?? "unknown",
              author,
              authorId: owner?.userId ?? "",
              authorEmail: owner?.email ?? "",
              createdAt: startTime ?? "",
              context,
              projectReference: project?.reference ?? undefined,
              permissions: {
                canView: !!permissions?.canView,
                canEdit: !!permissions?.canEdit,
                canDelete: !!permissions?.canDelete,
                canViewSharingPreferences: true,
                // !!permissions?.canViewSharingPreferences,
                canEditSharingPreferences:
                  !!permissions?.canEditSharingPreferences,
                canViewShareLink: !!permissions?.canViewShareLink,
                canGenerateShareLink: !!permissions?.canGenerateShareLink,
                canDeleteShareLink: !!permissions?.canDeleteShareLink,
                canTransferOwnership: !!permissions?.canTransferOwnership,
                canExport: !!permissions?.canExport,
                canViewAdverseMediaMonitoring:
                  !!permissions?.canViewAdverseMediaMonitoring,
                canEditAdverseMediaMonitoring:
                  !!permissions?.canEditAdverseMediaMonitoring,
                canViewSanctionMonitoring:
                  !!permissions?.canViewSanctionMonitoring,
                canEditSanctionMonitoring:
                  !!permissions?.canEditSanctionMonitoring
              },
              monitoring: {
                adverseMedia: !!monitoring?.adverseMedia,
                sanctions: !!monitoring?.sanctions
              },
              tags,
              risks,
              type: reportType
            };
          }
        );

      return { items, totalItemCount: totalItemCount ?? reports.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async search({
    query,
    offset,
    limit,
    searchTags,
    userId,
    filterByUserId
  }: {
    query: string;
    offset: number;
    limit: number;
    searchTags?: SearchResult[];
    userId: string;
    filterByUserId?: string;
  }): Promise<{ items: Report[]; totalItemCount: number }> {
    const filters =
      searchTags && searchTags.length > 0
        ? searchTags.reduce(
            (acc: { [key: string]: boolean | string[] }, tag) => {
              switch (tag.queryType) {
                case "filter": {
                  acc[tag.id] = true;
                  return acc;
                }

                case "users": {
                  if (typeof acc.users === "object") {
                    acc.users = [...acc.users, tag.id];
                    return acc;
                  }
                  acc.users = [tag.id];

                  return acc;
                }

                case "groups": {
                  if (typeof acc.groups === "object") {
                    acc.groups = [...acc.groups, tag.id];
                    return acc;
                  }
                  acc.groups = [tag.id];

                  return acc;
                }

                default: {
                  return acc;
                }
              }
            },
            {}
          )
        : {};

    const requestBody: Idam_Contracts_Reports_SearchRequest = {
      ...filters,
      queryString: query,
      start: offset,
      limit
    };

    if (!filterByUserId) {
      return { items: [], totalItemCount: 0 };
    }

    try {
      const { reports, total: totalItemCount } =
        await IdentityOrganisationApiService.postOrganisationsUsersReportsSearch(
          { userId: filterByUserId, requestBody }
        );

      if (!reports) {
        return { items: [], totalItemCount: 0 };
      }

      const items = reports.map(
        ({
          reportId,
          displayName,
          groups,
          project,
          startTime,
          state,
          imageUrl,
          contexts,
          owner,
          sanctions,
          watchLists,
          pepsOrStateOwned,
          rcas,
          sips,
          flags,
          permissions,
          monitoring,
          type
        }) => {
          // TODO: The endpoint isn't returning legal entity type so this is the only way determine if the source has sayari.
          const isLegalEntityReportType = isLegalEntityReport(contexts);
          const context = isLegalEntityReportType
            ? ""
            : contexts?.join(", ") || "";

          const reportType = mapReportType(type, isLegalEntityReportType);

          const author =
            owner?.firstName || owner?.lastName
              ? `${owner?.firstName ?? ""} ${owner?.lastName ?? ""}`
              : "unknown";

          const tags = buildReportCardTags({
            currentUserId: userId,
            reportOwnerUserId: owner?.userId!,
            groupsReportIsPartOf: groups!,
            reportType
          });

          const risks = buildReportScreeningFlags({
            sanctions: sanctions ?? 0,
            watchLists: watchLists ?? 0,
            pepsOrStateOwned: pepsOrStateOwned ?? 0,
            flags: flags ?? 0,
            rcas: rcas ?? 0,
            sips: sips ?? 0
          });

          return {
            id: reportId ?? "unknown",
            status: mapReportStateToStatus(state),
            imageSrc: imageUrl ?? "unknown",
            title: displayName ?? "unknown",
            author,
            authorId: owner?.userId ?? "",
            authorEmail: owner?.email ?? "",
            createdAt: startTime ?? "",
            context,
            projectReference: project?.reference ?? undefined,
            tags,
            permissions: {
              canView: !!permissions?.canView,
              canEdit: !!permissions?.canEdit,
              canDelete: !!permissions?.canDelete,
              canViewSharingPreferences:
                !!permissions?.canViewSharingPreferences,
              canEditSharingPreferences:
                !!permissions?.canEditSharingPreferences,
              canViewShareLink: !!permissions?.canViewShareLink,
              canGenerateShareLink: !!permissions?.canGenerateShareLink,
              canDeleteShareLink: !!permissions?.canDeleteShareLink,
              canTransferOwnership: !!permissions?.canTransferOwnership,
              canExport: !!permissions?.canExport,
              canViewAdverseMediaMonitoring:
                !!permissions?.canViewAdverseMediaMonitoring,
              canEditAdverseMediaMonitoring:
                !!permissions?.canEditAdverseMediaMonitoring,
              canViewSanctionMonitoring:
                !!permissions?.canViewSanctionMonitoring,
              canEditSanctionMonitoring:
                !!permissions?.canEditSanctionMonitoring
            },
            monitoring: {
              adverseMedia: !!monitoring?.adverseMedia,
              sanctions: !!monitoring?.sanctions
            },
            risks,
            type: reportType
          };
        }
      );

      return { items, totalItemCount: totalItemCount ?? items.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }
}
