import {
  CollectionListState,
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType
} from "util/hooks/useCollectionList/types";
import { CollectionListView } from "components/molecules/CollectionListControls";

import {
  Idam_Contracts_Enums_OrganisationRole,
  Idam_Contracts_Enums_UserState,
  IdentityTenantApiService
} from "api/portal";
import { CollectionSearchState } from "util/hooks/useCollectionSearch/types";

import type { User, Tag } from "api/users/types";
import { TagId } from "api/users/types";

import { formatGroupName } from "api/reports/utils";

export const ORGANISATION_USERS_COLLECTION = "organisation-users";

export default class Users {
  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: ORGANISATION_USERS_COLLECTION,
          title: "Users",
          limit: COLLECTION_ITEMS_PER_PAGE,
          offset: 0,
          order: "desc",
          view: CollectionListView.grid,
          pollingEnabled: true,
          items: [],
          totalItemCount: 0,
          status: CollectionStatus.stale,
          hidden: false,
          hiddenIfEmpty: false,
          input: { type: CollectionInputType.list }
        }
      ]
    };
  }

  async list({
    id: _id,
    limit: _limit,
    offset: _offset,
    filters: _filters,
    filterByOrgId
  }: {
    id: string;
    limit: number;
    offset: number;
    filters?: string[];
    filterByOrgId?: string;
  }): Promise<{ items: User[]; totalItemCount: number }> {
    if (!filterByOrgId) {
      return { items: [], totalItemCount: 0 };
    }

    const response =
      await IdentityTenantApiService.getTenantsOrganisationsUsers({
        organisationId: filterByOrgId
      });

    const items = response.map(
      ({ userId, firstName, lastName, email, groups, role, state }) => {
        const tags: Tag[] = [];

        if (role === Idam_Contracts_Enums_OrganisationRole.ADMIN) {
          tags.push({ id: TagId.UserRole, name: "Admin" });
        }
        groups?.forEach(({ name }) => {
          tags.push({
            id: TagId.GroupMember,
            name: formatGroupName(name) ?? ""
          });
        });
        return {
          id: userId ?? "unknown",
          icon: "user",
          title: `${firstName} ${lastName}`,
          context: email ?? "unknown",
          tags,
          resource: {
            userId,
            firstName,
            lastName,
            email,
            role,
            groups,
            state: state ?? Idam_Contracts_Enums_UserState.ENABLED
          },
          permissions: {
            canViewReports: true,
            canView: true,
            canEdit: true,
            canDelete: true
          }
        };
      }
    );

    return {
      items,
      totalItemCount: items.length
    };
  }

  async search() {
    return { items: [], totalItemCount: 0 };
  }
}
