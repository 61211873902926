import { styled } from "@linaria/react";

import Link from "components/atoms/SourceLink";
import { ReactComponent as ArrowIcon } from "img/icons/renders/arrow-rounded.svg";

import { grey } from "styles/colors";

const SourceLink = styled(Link)`
  display: block;
  color: ${grey.hover} !important;

  &:first-of-type {
    margin-top: 8px;
  }

  .link-icon {
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
`;

const Arrow = styled(ArrowIcon)`
  height: 18px;
  width: 18px;
  margin-top: 12px;
  transform: rotate(${props => (props.isExpanded ? "90deg" : 0)});
`;

const S = {
  SourceLink,
  Arrow
};

export default S;
