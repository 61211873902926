import { createContext } from "react";

export interface PdxDiagnosticsModeContextValue {
  enabled: boolean;
}

const defaultValue: PdxDiagnosticsModeContextValue = {
  enabled: false
};

const PdxDiagnosticsModeContext = createContext(defaultValue);

export default PdxDiagnosticsModeContext;
