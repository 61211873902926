import React, { useState } from "react";

import SimpleSearchInput from "components/molecules/SimpleSearchInput";
import { SearchResult } from "api/search";
import useHubUserSearch from "util/hooks/useHubUserSearch";
import { useParams } from "react-router-dom";

interface Props {
  selectedSuggestion?: SearchResult;
  setSelectedSuggestion: React.Dispatch<
    React.SetStateAction<SearchResult | undefined>
  >;
}

// TODO: this is copied from SelectUserInput to allow us to use the Tenant/Hub API.
// This should be refactored and removed in future.
const SelectHubUserInput = ({
  selectedSuggestion,
  setSelectedSuggestion
}: Props) => {
  const [query, setQuery] = useState("");
  const { orgId } = useParams();

  const { suggestions, isFetchingSuggestions, onSearchUpdate } =
    useHubUserSearch(orgId ?? "");

  const onQueryInputChange = (newQuery: string) => {
    setQuery(newQuery);
    onSearchUpdate(newQuery);
  };

  const onSelectedSuggestionsChange = (
    newSelectedSuggestions: SearchResult[]
  ) => {
    // If the selected suggestion is the suggestion that has already been selected, remove it
    if (
      !newSelectedSuggestions.length ||
      (selectedSuggestion &&
        selectedSuggestion.id ===
          newSelectedSuggestions[newSelectedSuggestions.length - 1].id)
    ) {
      setSelectedSuggestion(undefined);
    } else {
      const newSuggestion = newSelectedSuggestions.pop();
      setSelectedSuggestion(newSuggestion);
    }
  };

  return (
    <SimpleSearchInput
      query={query}
      onQueryInputChange={onQueryInputChange}
      selectedSuggestion={selectedSuggestion ? [selectedSuggestion] : []}
      onSelectedSuggestionChange={onSelectedSuggestionsChange}
      suggestions={suggestions}
      loadingText={isFetchingSuggestions ? "Loading..." : "No results found"}
      placeholder="Search users..."
    />
  );
};

export default SelectHubUserInput;
