import React, { ReactNode } from "react";

import { CardVariant } from "components/molecules/Card/types";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

export interface Props {
  title?: string;
  subtitle?: string;
  overview?: React.ReactNode;
  variant?: CardVariant;
  loading?: boolean;
  icon?: ReactNode;
}

const ListCardHeader = ({
  title,
  subtitle,
  overview,
  loading,
  variant = CardVariant.default,
  icon
}: Props) => {
  return loading ? (
    <S.Header>
      <S.AvatarAndTitle>
        <Avatar avatarSize={AvatarSize.Large} loading />
        <S.TitlesContainer>
          <Skeleton width="30%" height="26px" />
          <Skeleton width="100%" height="18px" />
        </S.TitlesContainer>
      </S.AvatarAndTitle>
    </S.Header>
  ) : (
    <S.Header>
      <S.AvatarAndTitle>
        {icon}
        <S.TitlesContainer>
          <S.CustomHeading level={5} variant={variant}>
            {title}
          </S.CustomHeading>
          <S.Subtitle variant={variant}>{subtitle}</S.Subtitle>
        </S.TitlesContainer>
      </S.AvatarAndTitle>
      {overview}
    </S.Header>
  );
};

export default ListCardHeader;
