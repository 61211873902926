import React, { FormEvent, useState } from "react";
import { Lock } from "react-feather";

import Input from "components/atoms/Input";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import TitleCard from "components/molecules/TitleCard";
import ErrorBanner from "components/atoms/ErrorBanner";

import {
  useRegistration,
  RegistrationActions
} from "util/hooks/useRegistration";

import S from "./styles";

enum DetailsFormFields {
  FirstName = "firstName",
  LastName = "lastName"
}

interface DetailsFormError {
  type: DetailsFormFields;
}

const getExpiresInDays = (expiryDate: string) => {
  const currentDate = new Date();

  const targetDate = new Date(expiryDate);

  const timeDiff = targetDate.getTime() - currentDate.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

const UserDetails = () => {
  const { state, dispatch } = useRegistration();

  const [formErrors, setFormErrors] = useState<DetailsFormError[]>([]);

  const onChange = (field: string, value: string) => {
    dispatch({ type: RegistrationActions.updateField, field, value });
  };

  const validateFormInputs = () => {
    const errors: DetailsFormError[] = [];

    if (!state.firstName?.trim()) {
      errors.push({ type: DetailsFormFields.FirstName });
    }

    if (!state.lastName?.trim()) {
      errors.push({ type: DetailsFormFields.LastName });
    }
    return errors;
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    // Reset form errors on resave/retry
    setFormErrors([]);

    const errors = validateFormInputs();

    if (errors.length !== 0) {
      setFormErrors(errors);
    } else {
      dispatch({ type: RegistrationActions.fieldsSubmitted });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <S.Container>
        <TitleCard subtitle={state.email} rightIcon={<Lock />} />

        <S.Description>
          <S.Title level={6}>Enter your details</S.Title>
          <S.Subtitle>
            Use the link from your welcome email to get started at any time in
            the next {getExpiresInDays(state.tokenExpiry)} days.
          </S.Subtitle>
        </S.Description>

        <S.Inputs>
          <Input
            inputType="text"
            placeholder="Enter first name"
            label="First name"
            onChange={(firstName: string) => onChange("firstName", firstName)}
            value={state.firstName}
            autoComplete="given-name"
          />
          {formErrors.some(e => e.type === DetailsFormFields.FirstName) && (
            <ErrorBanner text="First name is required." />
          )}
          <Input
            inputType="text"
            placeholder="Enter last name"
            label="Last name"
            onChange={(lastName: string) => onChange("lastName", lastName)}
            value={state.lastName}
            autoComplete="family-name"
          />
          {formErrors.some(e => e.type === DetailsFormFields.LastName) && (
            <ErrorBanner text="Last name is required." />
          )}
          <Input
            inputType="text"
            placeholder="Enter your role or job title"
            label="Job title (optional)"
            onChange={(jobTitle: string) => onChange("jobTitle", jobTitle)}
            value={state.jobTitle}
          />
        </S.Inputs>
        <ButtonNew
          onClick={onSubmit}
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          text="Next"
        />
      </S.Container>
    </form>
  );
};

export default UserDetails;
