import { css } from "@linaria/core";

const Tag = css`
  border-radius: 2px !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
`;

const C = { Tag };

export { C };
