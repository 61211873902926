import React, { FC, SVGProps, useEffect, useState } from "react";
import { Spinner } from "reactstrap";

import Heading from "components/atoms/Heading";
import ModalContainer from "components/molecules/ModalContainer";
import TitleCard from "components/molecules/TitleCard";
import { formatGroupName } from "api/reports/utils";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import useManageGroup from "util/hooks/useManageGroup";
import { ReactComponent as RemoveUser } from "img/icons/remove-user-icon.svg";
import RemoveFromGroupModal from "components/organisms/RemoveFromGroupModal";
import { UserToRemove } from "components/organisms/RemoveFromGroupModal/types";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import useUserSettings from "util/hooks/useUserSettings";
import { stripGroupName } from "api/groups";
import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";
import { GroupUser, GroupUserRole } from "api/groups/types";
import TitleCardLoadingSkeleton from "components/molecules/TitleCard/TitleCardLoadingSkeleton";
import { SearchResult } from "api/search";

import AddGroupUserInputControl from "./AddGroupUserInputControl";
import MemberListItem from "./MemberListItem";
import GroupDetails from "./GroupDetails";

import S, { classNameOverrides } from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  groupName: string;
  groupDescription?: string;
  groupId: string;
  canEditMembers: boolean;
  canEditGroup: boolean;
}

const ManageGroupModal = ({
  isOpen,
  toggleOpen,
  groupName,
  groupDescription,
  groupId,
  canEditMembers,
  canEditGroup
}: Props) => {
  const [userToRemove, setUserToRemove] = useState<UserToRemove>({
    id: "",
    title: "",
    subtitle: "",
    groupRole: GroupUserRole.Standard
  });
  const [groupNameText, setGroupName] = useState(groupName);
  const [groupDescriptionText, setGroupDescription] =
    useState(groupDescription);
  const [selectedSuggestions, setSelectedSuggestions] = useState<
    SearchResult[]
  >([]);

  const [{ fetching: membersLoading, error: membersError }, dispatch] =
    useFetchReducer();

  const [{ fetching: savingDetails }, detailsDispatch] = useFetchReducer();

  const { dispatch: listDispatch } = useCollectionList();

  const { updateUserGroup } = useUserSettings();
  const { updateGroupDetails } = useManageGroup();
  const {
    state: {
      userDetails: { userId }
    }
  } = useUserSettings();

  const {
    groupMembers,
    isRemoveFromGroupModalOpen,
    getGroupMembers,
    toggleManageGroupModal,
    toggleRemoveFromGroupModal
  } = useManageGroup();

  const groupAdminsCount = groupMembers.reduce((count, member) => {
    if (member.groupRole === GroupUserRole.Admin) {
      return count + 1;
    }
    return count;
  }, 0);

  useEffect(() => {
    // Reset to saved values on close
    if (!isOpen) {
      setGroupName(groupName);
      setGroupDescription(groupDescription);
    }
  }, [groupName, groupDescription, isOpen]);

  useEffect(() => {
    const initialiseGroupMembers = async () => {
      dispatch({ type: RequestActions.SendRequest });
      const status = await getGroupMembers();
      if (!status) {
        dispatch({ type: RequestActions.SetError });
        return;
      }
      dispatch({ type: RequestActions.SetSuccess });
    };

    if (isOpen) {
      initialiseGroupMembers();
    }
  }, [dispatch, getGroupMembers, isOpen]);

  const memberDropdownOptions = [
    { id: GroupUserRole.Admin, label: "Group admin" },
    {
      id: GroupUserRole.Standard,
      label: "Member",
      showSeparator: true
    },
    {
      id: "remove",
      label: "Remove from group",
      icon: RemoveUser,
      className: classNameOverrides.remove
    }
  ];

  const onRemoveFromGroup = (userDetails: UserToRemove) => {
    setUserToRemove(userDetails);
    toggleManageGroupModal();
    toggleRemoveFromGroupModal();
  };

  const onGroupNameChanged = (value: string) => {
    let modifiedValue = value;

    if (value.length > 20) {
      return;
    }

    modifiedValue = modifiedValue.replace(" ", "-");

    if (value !== "" && value.charAt(0) !== "@") {
      modifiedValue = `@${value}`;
    }

    if (modifiedValue.charAt(1) === "@") {
      modifiedValue = modifiedValue.slice(0, 1) + modifiedValue.slice(2);
    }

    setGroupName(modifiedValue);
  };

  const onSaveSettings = async () => {
    if (
      groupDescriptionText === groupDescription &&
      groupNameText === groupName
    ) {
      toggleOpen();
      return;
    }

    detailsDispatch({ type: RequestActions.SendRequest });

    // Default to saved name if user provides no text
    const groupNameToSend = !groupNameText?.length ? groupName : groupNameText;

    const { status, message } = await updateGroupDetails(
      groupNameToSend,
      groupDescriptionText
    );

    if (!status) {
      detailsDispatch({ type: RequestActions.SetError, errorMessage: message });
      return;
    }

    updateUserGroup({
      groupId,
      name: stripGroupName(groupNameToSend)
    });
    listDispatch({
      type: CollectionListActions.updateCollectionItem,
      id: "groups",
      item: {
        id: groupId,
        title: stripGroupName(groupNameToSend),
        context: groupDescriptionText
      }
    });

    detailsDispatch({ type: RequestActions.SetSuccess });
    toggleOpen();
  };

  const getCanEditMember = (member: GroupUser) => {
    // Prevent the removal of the last admin or the user themselves
    if (
      (groupAdminsCount === 1 && member.groupRole === GroupUserRole.Admin) ||
      userId === member.id
    ) {
      return false;
    }

    return canEditMembers;
  };

  const isMember = groupMembers.some(member => member.id === userId);

  return (
    <>
      <ModalContainer
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        title="Manage group"
        onExitClick={toggleOpen}
      >
        <GroupDetails
          groupName={formatGroupName(groupNameText) ?? ""}
          groupDescription={groupDescriptionText}
          onGroupDescriptionChanged={value => setGroupDescription(value)}
          onGroupNameChanged={onGroupNameChanged}
          canEditGroup={canEditGroup}
        />
        {isMember && (
          <div>
            <Heading level={6}>
              Add members to {formatGroupName(groupName)}
            </Heading>
            <AddGroupUserInputControl
              canAddUsers={isMember}
              selectedSuggestions={selectedSuggestions}
              setSelectedSuggestions={setSelectedSuggestions}
            />
            <S.Explainer>Users will be notified by email</S.Explainer>
          </div>
        )}
        <S.MembersContainer>
          <S.MembersHeader>
            <Heading level={6}>Members</Heading>
            <Heading level={6}>{groupMembers.length}</Heading>
          </S.MembersHeader>
          <S.MembersList>
            {membersError && (
              <S.MembersError>Something went wrong...</S.MembersError>
            )}
            {membersLoading && <TitleCardLoadingSkeleton />}
            {!membersError &&
              !membersLoading &&
              groupMembers.map(member => (
                <MemberListItem
                  memberId={member.id}
                  title={`${member.firstName} ${member.lastName}`}
                  subtitle={
                    member.jobTitle.length ? member.jobTitle : member.email
                  }
                  options={memberDropdownOptions}
                  savedOption={
                    memberDropdownOptions.find(
                      option => option.id === member.groupRole
                    )!
                  }
                  onRemoveFromGroup={onRemoveFromGroup}
                  canEditMember={getCanEditMember(member)}
                />
              ))}
          </S.MembersList>
        </S.MembersContainer>
        <S.DoneButton
          text="Done"
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          disabled={savingDetails || selectedSuggestions.length > 0}
          IconTrailing={
            savingDetails
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
          onClick={onSaveSettings}
        />
      </ModalContainer>
      <RemoveFromGroupModal
        isOpen={isRemoveFromGroupModalOpen}
        toggleOpen={() => {
          toggleRemoveFromGroupModal();
          toggleManageGroupModal();
        }}
        groupName={groupName}
        memberId={userToRemove.id}
        groupRole={userToRemove.groupRole}
      >
        <TitleCard
          title={userToRemove.title}
          subtitle={userToRemove.subtitle}
        />
      </RemoveFromGroupModal>
    </>
  );
};

export default ManageGroupModal;
