import { useContext } from "react";

import { OrganisationPreferencesContext } from "./context";

const useOrganisationPreferences = () => {
  const context = useContext(OrganisationPreferencesContext);

  if (!context) {
    throw new Error(
      "useOrganisationPreferences must be used within a OrganisationPreferencesContextProvider"
    );
  }

  return context;
};

export default useOrganisationPreferences;
