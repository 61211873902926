import React, { useState } from "react";

import { AnimatePresence } from "framer-motion";

import {
  BoldExtension,
  ItalicExtension,
  StrikeExtension,
  OrderedListExtension,
  BulletListExtension,
  BlockquoteExtension,
  PlaceholderExtension,
  TextColorExtension,
  LinkExtension
} from "remirror/extensions";
import { useRemirror, Remirror, EditorComponent } from "@remirror/react";

import useViewerMode from "util/hooks/useViewerMode";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";

import theme from "theme";

import ToolbarMenu from "./ToolbarMenu";
import FloatingLinkToolbar from "./FloatingLinkToolbar";

import S from "./styles";

const TextEditor = ({
  content = { type: "doc", content: [{ type: "paragraph" }] }, // Default to empty content
  readOnly, // Stop the unauthenticated users from editing notes
  onSave = () => {},
  isSaving = false,
  editorId = "note-editor-default"
}) => {
  const { isViewerModeEnabled } = useViewerMode();

  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [editorState, setEditorState] = useState();
  const [openLinkToolbar, setOpenLinkToolbar] = useState(false);
  const isPrintMode = usePrintModeEnabled();

  // We store the editor draft state in local storage to persist across refreshes.
  // If there is any notes saved, then we grab this to update the editor with the unsaved changes.
  const locallyStoredEditorState = localStorage.getItem(editorId);

  const { manager, state } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new StrikeExtension(),
      new OrderedListExtension(),
      new BulletListExtension(),
      new BlockquoteExtension(),
      new PlaceholderExtension({ placeholder: "Add a note..." }),
      new TextColorExtension(),
      new LinkExtension({
        autoLink: true,
        openLinkOnClick: false
      })
    ],
    selection: "start",
    stringHandler: "html",
    content
  });

  const [initialEditorState, setInitialEditorState] = useState(content);

  // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  // "content" can update after the initial render of this component. This is because of the async fetch of the saved notes,
  // so we need to ensure that the editor state matches this change.
  if (JSON.stringify(initialEditorState) !== JSON.stringify(content)) {
    manager.view?.updateState(manager.createState({ content }));
    setInitialEditorState(content);
  }

  // Storing drafts in localStorage to persist across refreshes.
  const storeEditorStateInStore = doc => {
    localStorage.setItem(editorId, JSON.stringify(doc));
  };

  const removeEditorStateFromStore = () => {
    localStorage.removeItem(editorId);
  };

  const onSaveClick = async () => {
    const saveSuccess = await onSave(editorState);

    // Only when the save is successful do we then want to close edit mode and remove the draft from local storage.
    // This will also remove the "unsaved changes" label from display.
    if (saveSuccess) {
      setIsEditModeActive(false);
      removeEditorStateFromStore();
    }
  };

  const onCancelClick = () => {
    manager.view.updateState(manager.createState({ content }));
    setIsEditModeActive(false);
    removeEditorStateFromStore();
  };

  const onTextChange = e => {
    setEditorState(e.state.doc);
    // Update the local store with the latest unsaved changes
    if (e.tr?.docChanged) {
      storeEditorStateInStore(e.state.doc);
    }
  };

  return (
    <S.EditorContainer
      isEditModeActive={isEditModeActive}
      viewerMode={isViewerModeEnabled}
      isPrintMode={isPrintMode}
      onClick={e => {
        const clickTarget = e.target.nodeName.toLowerCase();

        // open links in new tab, but only if not in edit mode
        if (clickTarget === "a" && !isEditModeActive) {
          // Remirror will still set the focus to the contenteditable element
          // even if edit mode isn't set to active - stop this with .blur()
          document.activeElement.blur();
          window.open(e.target.href, "_blank").focus();

          // Only needed for read only mode - otherwise we both open a new tab
          // as well as overwrite the existing report URL
          e.preventDefault();
        }

        if (
          !isEditModeActive &&
          !readOnly &&
          !isViewerModeEnabled &&
          clickTarget !== "a"
        ) {
          setIsEditModeActive(true);

          // If there is any draft notes in local storage, then display this when in edit mode.
          if (locallyStoredEditorState) {
            manager.view?.updateState(
              manager.createState({
                content: JSON.parse(locallyStoredEditorState)
              })
            );
            setEditorState(JSON.parse(locallyStoredEditorState));
          }
        }
      }}
      disabled={readOnly || isViewerModeEnabled}
      onKeyDown={e => {
        if (e.key === "Escape" && isEditModeActive && !openLinkToolbar) {
          setIsEditModeActive(false);
          e.target.blur();

          // When not in edit mode, the text in the editor should reflect the
          // _saved_ version of the user's notes.
          manager.view?.updateState(manager.createState({ content }));
        }
      }}
      aria-label="Edit mode"
    >
      {/* the className is used to define css variables necessary for the editor */}
      <div className="remirror-theme">
        <Remirror
          onChange={onTextChange}
          editable={!readOnly && !isViewerModeEnabled && !isSaving}
          manager={manager}
          initialContent={state}
        >
          {!isViewerModeEnabled && (
            <ToolbarMenu
              onLinkOptionClick={setOpenLinkToolbar}
              isLinkOptionActive={openLinkToolbar}
              isEditModeActive={isEditModeActive}
            />
          )}
          <EditorComponent />
          <FloatingLinkToolbar
            openLinkToolbar={openLinkToolbar}
            setOpenLinkToolbar={setOpenLinkToolbar}
            isEditModeActive={isEditModeActive}
          />
        </Remirror>
      </div>
      <S.Actions>
        {locallyStoredEditorState && (
          <S.UnsavedChanges layout>(Unsaved changes)</S.UnsavedChanges>
        )}
        <AnimatePresence>
          {(isEditModeActive || isSaving) && (
            <S.ActionButtons
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <S.CancelButton
                kind="secondary"
                onClick={onCancelClick}
                color={theme.primaryColor}
                disabled={isSaving}
              >
                Cancel
              </S.CancelButton>
              <S.SaveButton onClick={onSaveClick} disabled={isSaving}>
                {isSaving ? <S.Spinner /> : "Save"}
              </S.SaveButton>
            </S.ActionButtons>
          )}
        </AnimatePresence>
      </S.Actions>
    </S.EditorContainer>
  );
};

export default TextEditor;
