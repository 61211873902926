import React, { FC, ReactNode, SVGProps } from "react";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";
import { formatGroupName } from "api/reports/utils";
import Heading from "components/atoms/Heading";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import useManageGroup from "util/hooks/useManageGroup";
import ErrorBanner from "components/atoms/ErrorBanner";
import { GroupUserRole } from "api/groups/types";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  groupName: string;
  groupRole: GroupUserRole;
  memberId: string;
  children: ReactNode;
}

const RemoveFromGroupModal = ({
  isOpen,
  toggleOpen,
  groupName,
  groupRole,
  memberId,
  children
}: Props) => {
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();
  const { removeAdminsFromGroup, removeMembersFromGroup } = useManageGroup();

  const onRemoveFromGroup = async () => {
    dispatch({ type: RequestActions.SendRequest });

    let result;
    if (groupRole === GroupUserRole.Admin) {
      result = await removeAdminsFromGroup([memberId]);
    } else {
      result = await removeMembersFromGroup([memberId]);
    }

    if (!result.status) {
      dispatch({ type: RequestActions.SetError, errorMessage: result.message });
      return;
    }
    dispatch({ type: RequestActions.SetSuccess });
    toggleOpen();
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      title={`Remove member from ${formatGroupName(groupName)}?`}
    >
      {children}
      <S.ExplainerContainer>
        <Heading level={6}>
          Members can be added back to the group at any time.
        </Heading>
        <S.Explainer>Members will be notified by email</S.Explainer>
      </S.ExplainerContainer>
      <S.ModalActions>
        <S.Button
          text="Cancel"
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          onClick={toggleOpen}
        />
        <S.PrimaryButton
          text="Remove from group"
          type={ButtonType.Filled}
          size={ButtonSize.Medium}
          onClick={onRemoveFromGroup}
          disabled={fetching}
          IconTrailing={
            fetching
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
        />
      </S.ModalActions>
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            "There was an issue removing the user from the group. Try again in a moment."
          }
          onClick={() => dispatch({ type: RequestActions.ResetError })}
        />
      )}
    </ModalContainer>
  );
};

export default RemoveFromGroupModal;
