import React, { FC, useRef, useMemo, useState, useEffect } from "react";
import Lottie from "lottie-react";
import { motion } from "framer-motion";

import useInsightsAma from "util/hooks/useInsightsAma";
import useInsightsSuggestions from "components/organisms/InsightsChat/useInsightsSuggestions";
import loadingAnimation from "components/organisms/InsightsChat/loading.json";
import S from "./styles";

const calculateSuggestionSpeed = (suggestion: string) => {
  const readingSpeedInSeconds = 5;

  const wordCount = suggestion.split(/\s+/).length;

  return wordCount / readingSpeedInSeconds;
};

const Suggestion = ({
  disabled,
  suggestion,
  onClick
}: {
  disabled: boolean;
  suggestion: string;
  onClick: (s: string) => void;
}) => {
  const containerRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (!containerRef.current || !contentRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const contentWidth = contentRef.current.offsetWidth;

    if (contentWidth > containerWidth) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const duration = useMemo(
    () => calculateSuggestionSpeed(suggestion),
    [suggestion]
  );

  const getXOffset = () => {
    if (!containerRef.current || !contentRef.current) return 0;

    const containerWidth = containerRef.current.offsetWidth;
    const contentWidth = contentRef.current.offsetWidth;
    const offset = containerWidth - contentWidth;

    if (offset > 0) return 0;

    return offset;
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 10);
  }, []);

  const xOffset = getXOffset();
  const shouldShowCover = xOffset < 0;

  return (
    <S.Suggestion
      disabled={disabled}
      ref={containerRef}
      key={suggestion}
      onClick={() => onClick(suggestion)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isHovered={isHovered}
    >
      <S.SuggestionText
        ref={contentRef}
        whileHover="hover"
        initial="initial"
        animate="initial"
      >
        <motion.div
          key={`SuggestionMotionDiv-${isLoaded}`}
          variants={{
            hover: {
              x: xOffset,
              transition: {
                duration
              }
            },
            initial: {
              x: 0,
              transition: {
                duration: duration / 2
              }
            }
          }}
        >
          {suggestion}
        </motion.div>
      </S.SuggestionText>
      {shouldShowCover && <S.SuggestionCover />}
    </S.Suggestion>
  );
};

interface Props {
  sectionId: string;
  onSuggestionCallback: () => void;
}

const Suggestions: FC<Props> = ({ sectionId, onSuggestionCallback }) => {
  const { askQuestion } = useInsightsAma();
  const {
    getSuggestions,
    loadingSuggestions,
    suggestionsError,
    suggestedQuestions
  } = useInsightsSuggestions();

  const askSuggestedQuestion = (suggestion: string) => {
    onSuggestionCallback();
    askQuestion(suggestion, sectionId);
  };

  return (
    <S.Container>
      <S.SuggestedQuestions>
        {loadingSuggestions ? (
          <>
            <S.SuggestionSkeleton />
            <S.SuggestionSkeleton />
            <S.SuggestionSkeleton />
            <S.SuggestionSkeleton />
          </>
        ) : (
          suggestedQuestions?.map(suggestion => (
            <Suggestion
              disabled={loadingSuggestions}
              key={suggestion}
              suggestion={suggestion}
              onClick={askSuggestedQuestion}
            />
          ))
        )}
      </S.SuggestedQuestions>
      {loadingSuggestions ? (
        <S.LoadingAnimation>
          <Lottie animationData={loadingAnimation} />
        </S.LoadingAnimation>
      ) : (
        <S.NewSuggestionsContainer>
          <S.NewSuggestionsButton
            disabled={loadingSuggestions}
            hasError={suggestionsError}
            onClick={getSuggestions}
          >
            {suggestionsError
              ? "Oops, something went wrong!"
              : "Give me other suggestions"}
          </S.NewSuggestionsButton>
        </S.NewSuggestionsContainer>
      )}
    </S.Container>
  );
};

export default Suggestions;
