import { styled } from "@linaria/react";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  position: relative;
  padding: 0 24px 0 44px;
`;

const MessageContainer = styled.div``;

const S = { Container, MessageContainer };

export default S;
