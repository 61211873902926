import React, { FC, useState } from "react";

import { useHubAuthentication } from "util/hooks/useHubAuthentication";
import { HubAuthenticationStatus } from "util/hooks/useHubAuthentication/types";
import HubLoginForm from "./HubLoginForm";
import HubForgottenPassword from "./HubForgottenPassword";
import HubMfaForm from "./HubMfaForm";

import S from "./styles";

interface Props {
  slogan?: string;
  isOpen: boolean;
}

const HubLoginModal: FC<Props> = ({ isOpen, slogan }) => {
  const { state } = useHubAuthentication();
  const [showForgottenPassword, setShowForgottenPassword] = useState(false);

  const onForgottenPasswordToggle = () =>
    setShowForgottenPassword(prev => !prev);

  const renderModalContent = () => {
    if (showForgottenPassword) {
      return <HubForgottenPassword onBackClick={onForgottenPasswordToggle} />;
    }

    if (
      [
        HubAuthenticationStatus.unauthenticated,
        HubAuthenticationStatus.verifyingPassword,
        HubAuthenticationStatus.unknown,
        HubAuthenticationStatus.authenticating
      ].includes(state.status)
    ) {
      return (
        <HubLoginForm
          slogan={slogan}
          onForgottenPasswordClick={onForgottenPasswordToggle}
        />
      );
    }

    if (
      [
        HubAuthenticationStatus.passwordVerified,
        HubAuthenticationStatus.authenticating,
        HubAuthenticationStatus.authenticated
      ].includes(state.status)
    ) {
      return <HubMfaForm />;
    }

    return null;
  };

  return (
    <S.CustomModalContainer toggleOpen={() => null} isOpen={isOpen} width={540}>
      {renderModalContent()}
    </S.CustomModalContainer>
  );
};

export default HubLoginModal;
