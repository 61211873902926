import React, {
  FC,
  PropsWithChildren,
  Reducer,
  useEffect,
  useMemo,
  useReducer
} from "react";
import { DefaultService, SubjectType } from "api/old-insights";
import { InsightsReportContext } from "./context";
import {
  InsightsReportState,
  InsightsReportStates,
  InsightsReportAction,
  InsightsReportActions
} from "./types";
import { useEnquiryId } from "../useEnquiryId";
import { useSubjectName } from "../useSubjectName";
import { useReportSubjectEntityType } from "../useReportSubjectEntityType";
import { EntityType } from "../useReportSubjectEntityType/context";

const reducer: Reducer<InsightsReportState, InsightsReportAction> = (
  state,
  action
) => {
  switch (action.type) {
    case InsightsReportActions.StartLoad:
      return { type: InsightsReportStates.Loading };
    case InsightsReportActions.CompleteLoad:
      return { type: InsightsReportStates.Loaded, report: action.report };
    case InsightsReportActions.NotFound:
      return { type: InsightsReportStates.NotGenerated };
    case InsightsReportActions.StartGenerate:
      return { type: InsightsReportStates.Generating };
    case InsightsReportActions.Error:
      return { type: InsightsReportStates.Error, error: action.error };
    case InsightsReportActions.ValidationFailed:
      return { type: InsightsReportStates.InvalidNeedsRegenerating };
    default:
      return state;
  }
};

export const OldInsightsReportProvider: FC<PropsWithChildren<{}>> = ({
  children
}) => {
  // TODO - make backend auto-import enquiry if not yet imported
  const [state, dispatch] = useReducer(reducer, {
    type: InsightsReportStates.NotLoaded
  });
  const enquiryId = useEnquiryId();
  const subjectName = useSubjectName();
  const subjectType = useReportSubjectEntityType();
  const insightsSubjectType =
    subjectType === EntityType.Person
      ? SubjectType.PERSON
      : SubjectType.ORGANISATION;

  const loading = state.type === InsightsReportStates.Loading;

  useEffect(() => {
    if (loading && enquiryId) {
      const promise =
        DefaultService.fetchInsightReportEnquiryEnquiryIdInsightReportGet({
          enquiryId,
          cacheBust: 2,
          subjectType: insightsSubjectType
        });
      promise.then(
        response => {
          dispatch({
            report: response,
            type: InsightsReportActions.CompleteLoad
          });
        },
        reason => {
          if (reason.status === 404) {
            dispatch({ type: InsightsReportActions.NotFound });
          } else if (reason.status === 422) {
            console.warn(
              "Xapien Inisghts report validation error, needs to be re-generated",
              { reason }
            );
            dispatch({ type: InsightsReportActions.ValidationFailed });
          } else if (!promise.isCancelled) {
            console.error("Error loading Xapien Insights report", { reason });
            const message = `Error loading Xapien Insights report: ${
              reason.message || reason || ""
            }`;
            dispatch({ type: InsightsReportActions.Error, error: message });
          }
        }
      );
      return () => promise.cancel();
    }
    return undefined;
  }, [enquiryId, loading, insightsSubjectType]);

  const generating = state.type === InsightsReportStates.Generating;

  useEffect(() => {
    if (generating && enquiryId && subjectName) {
      const promise =
        DefaultService.generateInsightReportEnquiryEnquiryIdInsightReportPost({
          enquiryId,
          subjectName,
          subjectType: insightsSubjectType
        });
      promise.then(
        response => {
          dispatch({
            report: response,
            type: InsightsReportActions.CompleteLoad
          });
        },
        reason => {
          if (!promise.isCancelled) {
            console.error("Error generating Xapien Insights report", {
              reason
            });
            const message = `Error generating Xapien Insights report: ${
              reason.message || reason || ""
            }`;
            dispatch({ type: InsightsReportActions.Error, error: message });
          }
        }
      );
      return () => promise.cancel();
    }
    return undefined;
  }, [generating, enquiryId, subjectName, insightsSubjectType]);
  const context = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <InsightsReportContext.Provider value={context}>
      {children}
    </InsightsReportContext.Provider>
  );
};
