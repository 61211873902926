import { styled } from "@linaria/react";
import { ReactComponent as UpArrowSVG } from "img/icons/actiontoolbar/up-arrow.svg";
import { ReactComponent as DownArrowSVG } from "img/icons/actiontoolbar/down-arrow.svg";
import { ReactComponent as MoveArrowSVG } from "img/icons/actiontoolbar/move-arrow.svg";
import { ReactComponent as EditSVG } from "img/icons/actiontoolbar/edit.svg";
import { ReactComponent as CopySVG } from "img/icons/actiontoolbar/copy.svg";
import { ReactComponent as MessageSVG } from "img/icons/actiontoolbar/message.svg";
import { ReactComponent as TrashSVG } from "img/icons/actiontoolbar/trash.svg";
import { MoreVertical } from "react-feather";
import { grey, purple, red, standardColors } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import { motion } from "framer-motion";
import { zIndex } from "styles/zIndex";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";

const IconContainer = styled.div`
  position: absolute;
  left: 190px;
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: ${standardColors.white};
  box-shadow:
    0px 0px 12px 0px rgba(0, 0, 0, 0.1),
    0px 0px 1px 0px rgba(0, 0, 0, 0.25);
`;

const ThreeDots = styled(MoreVertical)`
  cursor: pointer;
  height: 24px;

  &:hover {
    color: ${isPDX ? theme.primaryColor : purple.darker};
  }
`;

const ToolBarContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  gap: 2px;
  padding: 2px;
  background: ${standardColors.white};
  border-radius: 16px;
  box-shadow:
    0px 0px 12px 0px rgba(0, 0, 0, 0.1),
    0px 0px 1px 0px rgba(0, 0, 0, 0.2);

  :after {
    content: "";
    position: absolute;
    top: -10px; // Extend touch target by 10px on top
    right: -10px; // Extend touch target by 10px on right
    bottom: -10px; // Extend touch target by 10px on bottom
    left: -10px; // Extend touch target by 10px on left
    border: 10px solid transparent;
    z-index: ${zIndex.bottom};
  }
`;

const ToolBarWrapper = styled.div`
  position: absolute;
  left: -210px;
  top: -13px;
`;

const ToolbarButton = styled.button<{ disableToolTips?: boolean }>`
  padding: 6px;
  justify-content: center;
  text-align: center;
  display: flex;
  background: ${standardColors.white};
  border-radius: 26px;
  box-shadow: none;
  border: none;

  opacity: ${({ disableToolTips }) => (disableToolTips ? "0.3" : "1")};

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${({ disableToolTips }) =>
      disableToolTips ? standardColors.white : grey.panel};
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const UpArrow = styled(UpArrowSVG)`
  width: 18px;
  height: 18px;
`;

const DownArrow = styled(DownArrowSVG)`
  width: 18px;
  height: 18px;
`;

const MoveArrow = styled(MoveArrowSVG)`
  width: 18px;
  height: 18px;
`;

const Edit = styled(EditSVG)`
  width: 18px;
  height: 18px;
`;

const Copy = styled(CopySVG)`
  width: 18px;
  height: 18px;
`;

const Message = styled(MessageSVG)`
  width: 18px;
  height: 18px;
`;

const DiscardToolbarButton = styled(ToolbarButton)`
  &:hover {
    background: ${() => {
      const { r, g, b } = hexToRgb(red.directRiskFill);
      return `rgba(${r}, ${g}, ${b}, 0.10)`;
    }};
  }
`;

const Trash = styled(TrashSVG)<{ disabled: boolean }>`
  width: 18px;
  height: 18px;
  color: ${({ disabled }) =>
    disabled ? standardColors.black : red.directRiskFill};
`;

const S = {
  UpArrow,
  DownArrow,
  MoveArrow,
  Edit,
  Copy,
  Message,
  Trash,
  ToolBarContainer,
  ToolbarButton,
  ThreeDots,
  IconContainer,
  DiscardToolbarButton,
  ToolBarWrapper
};

export default S;
