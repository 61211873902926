import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import ModalContainer from "components/molecules/ModalContainer";
import { X } from "react-feather";
import { standardColors } from "styles/colors";

// @ts-ignore
import theme from "theme";

const ModalContent = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 8px;
`;

const Actions = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-direction: row-reverse;
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const XIcon = styled(X)`
  width: 32px;
  height: 32px;
`;

const ExitButton = styled.button`
  cursor: pointer;
  background: none;
  border-radius: 0;
  box-shadow: none;
  border: none;
`;

const GotItButton = styled(ButtonNew)`
  background: ${theme.primaryColor};
  color: ${standardColors.white};
`;

const DisclaimerModalContainer = styled(ModalContainer)`
  padding: 0px;
`;

const S = {
  ModalContent,
  ExitButton,
  DisclaimerModalContainer,
  HeadingContainer,
  Actions,
  GotItButton,
  XIcon
};

export default S;
