import { ReactNode } from "react";

export enum CardVariant {
  default = "default",
  dark = "dark"
}

export interface Props {
  control?: ReactNode;
  children: ReactNode;
  loading?: boolean;
  variant?: CardVariant;
  interactive?: boolean;
  className?: string;
  onClick?: () => void;
}
