import { styled } from "@linaria/react";

import { blue } from "styles/colors";
import { fontSize } from "styles/fonts";

const FlagContainer = styled.span`
  svg {
    height: ${fontSize.sm};
    width: ${fontSize.sm};
    margin-right: 5px;
    margin-bottom: 3px;
  }
  white-space: break-spaces;

  &:hover {
    path {
      fill: ${blue.icon};
    }
  }
`;

const S = { FlagContainer };

export default S;
