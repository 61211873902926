import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { FILTER_TYPES } from "themes/projectdx/ThemedScreening/util";
import { grey, red } from "styles/colors";

const EntityImage = styled.div`
  background-color: ${props => {
    if (props.filterBucketType === FILTER_TYPES.confirmed) {
      return red.directRisk;
    }
    if (props.filterBucketType === FILTER_TYPES.unconfirmed) {
      return grey.mid;
    }
    return grey.ghost;
  }};
  width: ${props => (props.size === "small" ? "44px" : "74px")};
  height: ${props => (props.size === "small" ? "44px" : "74px")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
`;

const TooltipContent = styled.div`
  width: 230px;
`;

const TooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding-bottom: 6px;
`;

const TooltipBody = styled.div`
  padding-top: 6px;
`;

// ClassNameOverrides

const entityIcon = css`
  transform: scale(0.6);
`;

const cardStyles = css`
  border: none !important;
`;

const rcaCardStyles = css`
  border: 1px solid ${red.directRiskOutline} !important;
`;

const S = {
  EntityImage,
  TooltipHeader,
  TooltipBody,
  TooltipContent
};

export default S;

export const classNameOverrides = {
  entityIcon,
  cardStyles,
  rcaCardStyles
};
