import React, {
  CSSProperties,
  FC,
  Children,
  useEffect,
  useState,
  ReactElement,
  cloneElement
} from "react";

import S from "./styles";

interface Props {
  children: ReactElement | ReactElement[];
  onChange: (value: string) => void;
  style?: CSSProperties;
  disabled?: boolean;
  defaultSelection?: string;
  width: number;
  className?: string;
}

const ContentSwitch: FC<Props> = ({
  children,
  onChange = () => {},
  style,
  className,
  disabled = false,
  defaultSelection,
  width
}) => {
  const [isLeftItemSelected, setIsLeftItemSelected] = useState(true);
  const firstChild = Children.toArray(children)[0] as unknown as ReactElement;
  const [selectedItemName, setSelectedItemName] = useState(
    defaultSelection ?? firstChild.props.name
  );

  useEffect(() => {
    setSelectedItemName(defaultSelection ?? firstChild.props.name);
  }, [defaultSelection, firstChild.props.name]);

  const onItemClick = (item: ReactElement) => {
    // Only trigger the events if the selection has changed
    if (item.props.name !== selectedItemName) {
      setIsLeftItemSelected(prevValue => !prevValue);
      setSelectedItemName(item.props.name);
      onChange(item.props.name);
    }
  };

  const computeSelectionPillPosition = () => {
    const selectedChildIndex = Children.toArray(children).findIndex(
      child =>
        (child as unknown as ReactElement).props.name === selectedItemName
    );

    const containerWidth = width;
    const itemsWidth = containerWidth / 2;

    return selectedChildIndex * itemsWidth - 1;
  };

  if (Children.count(children) > 2) {
    console.error(
      `Error in ContextSwitch. Children length ${Children.count(
        children
      )} exceeds accepted length of 2`
    );
  }

  return (
    <S.ContentSwitchContainer
      style={style}
      className={className}
      disabled={disabled}
      width={width}
    >
      {Children.map(children, (childItem: ReactElement) =>
        cloneElement(childItem, {
          onClick: () => onItemClick(childItem),
          key: `SwitchItem-${childItem.props.name}`,
          isSelected: childItem.props.name === selectedItemName,
          disabled
        })
      )}
      <S.SelectionPill
        isLeftItemSelected={isLeftItemSelected}
        pillPosition={computeSelectionPillPosition()}
        width={width / 2}
      />
    </S.ContentSwitchContainer>
  );
};

export default ContentSwitch;
