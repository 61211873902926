import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";

import { AnimatePresence } from "framer-motion";

import TextInputWithOptions from "components/molecules/TextInputWithOptions";
import {
  Option,
  Props as TextInputWithOptionsProps
} from "components/molecules/TextInputWithOptions/types";

import S from "./styles";

export type Row = {
  selectedOption?: string;
  textInputValue?: string | null;
};

type Props<T extends Option> = {
  isOnlyTextInputAvailable?: boolean;
  initialRows: Record<string, Row>;
  customDropdown?: () => ReactElement;
  rowLimit?: number;
  disabled?: boolean;
  defaultSelectedOptionForNewRows?: string;
  onRowUpdated: (rowId: number, rowObject?: Row) => void;
  displayHelpInfo?: boolean;
} & Omit<TextInputWithOptionsProps<T>, "rowId">;

const TextInputWithOptionsList = <T extends Option>({
  isOnlyTextInputAvailable,
  initialRows,
  onEnterPressedInTextInput,
  customDropdown,
  rowLimit = 3,
  disabled,
  defaultSelectedOptionForNewRows,
  options,
  onRowUpdated = () => {},
  customAddRowPrompt,
  displayAddRowPrompt,
  displayHelpInfo,
  helpText
}: Props<T>) => {
  const [rows, setRows] = useState(initialRows);

  useEffect(() => {
    setRows(initialRows);
  }, [initialRows]);

  const onRowOptionSelected = (updatedRowId: number, optionId: string) => {
    const updatedRowObj = {
      ...rows[updatedRowId],
      selectedOption: optionId
    };
    const newRows = {
      ...rows,
      [updatedRowId]: updatedRowObj
    };
    setRows(newRows);
    onRowUpdated(updatedRowId, updatedRowObj);
  };

  const onRowInputChanged = (
    event: ChangeEvent<HTMLInputElement>,
    updatedRowId: number
  ) => {
    const { value } = event.target;
    const updatedRowObj = {
      ...rows[updatedRowId],
      textInputValue: value
    };
    const newRows = {
      ...rows,
      [updatedRowId]: updatedRowObj
    };
    setRows(newRows);
    onRowUpdated(updatedRowId, updatedRowObj);
  };

  const onInputRowRemoved = (rowId: number) => {
    const rowsCopy = { ...rows };
    delete rowsCopy[rowId];
    setRows(rowsCopy);
    onRowUpdated(rowId);
  };

  const onInputRowAdded = () => {
    // Fetch the largest ID integer. The new ID will be one more
    // than that.
    const newRowId = Math.max(...Object.keys(rows).map(Number)) + 1;
    const newRowObj = {
      textInputValue: "",
      selectedOption: defaultSelectedOptionForNewRows
    };
    const newRows = {
      ...rows,
      [newRowId]: newRowObj
    };
    setRows(newRows);
    onRowUpdated(newRowId, newRowObj);
  };

  return (
    <S.InputsContainer>
      <AnimatePresence initial={false}>
        {Object.keys(rows).map((rowId, index, collection) => {
          const rowObj = rows[rowId];
          const rowIdNumber = parseInt(rowId, 10);
          const canAddRow =
            !isOnlyTextInputAvailable &&
            !collection[index + 1] &&
            collection.length < rowLimit;

          return (
            <TextInputWithOptions
              key={rowId}
              onEnterPressedInTextInput={onEnterPressedInTextInput}
              disabled={disabled}
              canRemoveRow={!isOnlyTextInputAvailable && collection.length > 1}
              canAddRow={canAddRow}
              canSelectOption={!isOnlyTextInputAvailable}
              rowId={rowIdNumber}
              options={options}
              defaultSelection={rowObj.selectedOption}
              onRemoveRowClick={() => onInputRowRemoved(rowIdNumber)}
              onAddRowClick={onInputRowAdded}
              onOptionSelect={onRowOptionSelected}
              onInputChange={event => onRowInputChanged(event, rowIdNumber)}
              customDropdownFn={customDropdown}
              initialTextInputValue={rowObj.textInputValue}
              displayAddRowPrompt={displayAddRowPrompt}
              customAddRowPrompt={customAddRowPrompt}
              showInfoIcon={displayHelpInfo}
              helpText={helpText}
              // Fade in/out when mounted/unmounted
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
          );
        })}
      </AnimatePresence>
    </S.InputsContainer>
  );
};

export default TextInputWithOptionsList;
