import React, { Fragment, useState, useRef, useLayoutEffect } from "react";
import { InfoTypes } from "components/molecules/Accordion";
import { UncertainDataFlag } from "components/atoms/UncertainDataFlag";
import TruncateLength from "util/TruncateLength";
import WithInspector from "components/organisms/WithInspector";
import Assertable from "util/Assertable";
import exactEmailAssertionTemplate from "util/Assertable/util/exactEmailAssertionTemplate";
import exactPhoneNumberAssertionTemplate from "util/Assertable/util/exactPhoneNumberAssertionTemplate";
import exactWebsiteAssertionTemplate from "util/Assertable/util/exactWebsiteAssertionTemplate";
import SourceLink from "components/atoms/SourceLink";

import S from "./styles";

const ACCORDION_ID_PREFIX = "CONTACT_INFO";

const ACCORDION_HEADER_HEIGHT = 36;

const SECTION_COUNT = 4;

const CONTACT_CATEGORIES = {
  onlineProfiles: "ONLINE_PROFILES",
  websites: "KEY_WEBSITES",
  emails: "EMAIL",
  phoneNumbers: "PHONE_NUMBERS"
};

const ContactInformation = ({
  onlineProfilesWithUncertainty,
  significantWebsites,
  emailAddresses,
  phoneNumbers
}) => {
  const [contactExpanded, setContactExpanded] = useState();

  const [containerHeight, setContainerHeight] = useState();

  const containerRef = useRef();

  useLayoutEffect(() => {
    // Set the expanded height based on the available space minus the sum of the displaying header heights
    if (containerRef.current && !containerHeight) {
      setContainerHeight(
        `${
          containerRef.current.clientHeight -
          ACCORDION_HEADER_HEIGHT * SECTION_COUNT
        }px`
      );

      if (!contactExpanded) {
        setContactExpanded(CONTACT_CATEGORIES.onlineProfiles);
      }
    }
  }, [contactExpanded, containerHeight]);

  const renderPhoneInfo = itemData => {
    return (
      <WithInspector
        sources={itemData.sources}
        display="flex"
        topSectionElement={
          <S.PhoneInfoContainer>
            {itemData.isUncertain ? <UncertainDataFlag /> : ""}
            {itemData.text}
          </S.PhoneInfoContainer>
        }
      >
        <Assertable template={exactPhoneNumberAssertionTemplate(itemData.text)}>
          {itemData.isUncertain ? <UncertainDataFlag /> : ""}
          {itemData.text}
        </Assertable>
      </WithInspector>
    );
  };

  const renderEmailInfo = itemData => {
    return (
      <WithInspector
        display="flex"
        sources={itemData.sources}
        topSectionElement={
          <>
            {itemData.isUncertain ? <UncertainDataFlag /> : ""}
            {itemData.text}
          </>
        }
      >
        <Assertable template={exactEmailAssertionTemplate(itemData.text)}>
          {itemData.isUncertain ? <UncertainDataFlag /> : ""}
          <S.EmailItem isUncertain={itemData.isUncertain}>
            <TruncateLength>{itemData.text}</TruncateLength>
          </S.EmailItem>
        </Assertable>
      </WithInspector>
    );
  };

  const renderWebsiteInfo = itemData => {
    const urlElement = <TruncateLength>{itemData.text}</TruncateLength>;
    return (
      <WithInspector
        display="flex"
        sources={itemData.sources}
        topSectionElement={
          <SourceLink href={itemData.text}>{urlElement}</SourceLink>
        }
      >
        <Assertable template={exactWebsiteAssertionTemplate(itemData.text)}>
          <S.WebsiteItem isUncertain={itemData.isUncertain}>
            {itemData.isUncertain ? <UncertainDataFlag /> : ""}
            {urlElement}
          </S.WebsiteItem>
        </Assertable>
      </WithInspector>
    );
  };

  return (
    <S.ContactInformationContainer ref={containerRef}>
      <div
        onClick={() => setContactExpanded(CONTACT_CATEGORIES.onlineProfiles)}
      >
        <S.CustomAccordion
          dataToDisplay={
            <S.SocialMedia
              profileData={onlineProfilesWithUncertainty}
              maxLinkWidth={180}
              showFollowerCount
              isInspectorEnabled
              maxHeight={containerHeight ?? "none"}
            />
          }
          title="Online presence"
          id={`${ACCORDION_ID_PREFIX}_${CONTACT_CATEGORIES.onlineProfiles}`}
          type="custom"
          displayArrow={false}
          displayCount={false}
          hideCountOnCollapse={false}
          isExpandedOverride={
            contactExpanded === CONTACT_CATEGORIES.onlineProfiles
          }
        />
      </div>
      <div
        onClick={() => {
          setContactExpanded(CONTACT_CATEGORIES.websites);
        }}
      >
        <S.CustomAccordion
          dataToDisplay={significantWebsites ?? []}
          title="Key websites"
          type={InfoTypes.Website}
          id={`${ACCORDION_ID_PREFIX}_${CONTACT_CATEGORIES.websites}`}
          renderDataItem={renderWebsiteInfo}
          displayArrow={false}
          hideCountOnCollapse={false}
          maxHeight={containerHeight}
          isExpandedOverride={contactExpanded === CONTACT_CATEGORIES.websites}
          dataCount={significantWebsites?.length ?? 0}
        />
      </div>
      <div onClick={() => setContactExpanded(CONTACT_CATEGORIES.emails)}>
        <S.CustomAccordion
          dataToDisplay={emailAddresses ?? []}
          title="Email addresses"
          type={InfoTypes.Email}
          id={`${ACCORDION_ID_PREFIX}_${CONTACT_CATEGORIES.emails}`}
          renderDataItem={renderEmailInfo}
          displayArrow={false}
          hideCountOnCollapse={false}
          maxHeight={containerHeight}
          isExpandedOverride={contactExpanded === CONTACT_CATEGORIES.emails}
          dataCount={emailAddresses?.length ?? 0}
        />
      </div>
      <div onClick={() => setContactExpanded(CONTACT_CATEGORIES.phoneNumbers)}>
        <S.CustomAccordion
          dataToDisplay={phoneNumbers ?? []}
          title="Phone numbers"
          type={InfoTypes.Phone}
          id={`${ACCORDION_ID_PREFIX}_${CONTACT_CATEGORIES.phoneNumbers}`}
          renderDataItem={renderPhoneInfo}
          displayArrow={false}
          hideCountOnCollapse={false}
          maxHeight={containerHeight}
          isExpandedOverride={
            contactExpanded === CONTACT_CATEGORIES.phoneNumbers
          }
          dataCount={phoneNumbers?.length ?? 0}
        />
      </div>
    </S.ContactInformationContainer>
  );
};

export default ContactInformation;
