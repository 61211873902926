import { FetchResult } from "api/types";
import { getErrorMessage } from "api/util";

import { DefaultService } from "api/article-viewer";
import type { GetArticleResponse as Article } from "api/article-viewer";
import { apm } from "@elastic/apm-rum";

export type { Article };

const SUPPORTED_PROVIDERS = ["factiva"];

export default class Articles {
  async getArticle({
    articleId,
    provider
  }: {
    articleId: string;
    provider: string;
  }): Promise<FetchResult<Article>> {
    try {
      if (!SUPPORTED_PROVIDERS.includes(provider)) {
        return { status: false, message: `${provider} provider not supported` };
      }

      const response = await DefaultService.getArticles({
        provider: provider as "factiva",
        articleId
      });

      return { status: true, response };
    } catch (e) {
      console.error(e);
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e) ?? "An unknown error has occured"
      };
    }
  }
}
