import React, {
  Fragment,
  FC,
  useState,
  createRef,
  RefObject,
  KeyboardEvent,
  useEffect
} from "react";

import Input from "components/atoms/Input";

import S from "./styles";

interface Props {
  length: number;
  value: string;
  onChange: (value: string) => void;
}

const PinInput: FC<Props> = ({ length, value, onChange }) => {
  const [inputRefs] = useState<RefObject<HTMLInputElement>[]>(() =>
    Array.from({ length }, () => createRef())
  );

  const handleOnChange = (v: string, i: number) => {
    if (v !== "") {
      const nextIndex = i + 1;
      if (nextIndex < length && inputRefs !== null) {
        const inputRef = inputRefs[i + 1];
        inputRef?.current?.focus();
      }
    }

    const newValue = v === "" ? " " : v.replace(/ /g, "");

    const updatedValue = value.slice(0, i) + newValue + value.slice(i + 1);

    onChange(updatedValue.slice(0, length));
  };

  // When backspace is keyed on the pin input boxes, move focus back to
  // the previous box to aid a nicer UX.
  const handleOnKeyUp = (e: KeyboardEvent<Element>, index: number) => {
    if (e.key !== "Backspace") {
      return;
    }

    if (index > 0) {
      inputRefs[index - 1]?.current?.focus();
    }
  };

  // Ensure the first code box is in focus on load.
  useEffect(() => {
    inputRefs[0]?.current?.focus();
  }, [inputRefs]);

  return (
    <S.Container>
      {inputRefs.map((ref, i) => (
        <Fragment key={`PinInputFragment-${i}`}>
          {Math.ceil(length / 2) === i && <S.Spacer />}
          <Input
            ref={ref}
            placeholder="•"
            inputType="text"
            value={value ? value[i] ?? "" : ""}
            onChange={v => handleOnChange(v, i)}
            hidePlaceholderOnFocus
            autoComplete="off"
            onKeyUp={e => handleOnKeyUp(e, i)}
          />
        </Fragment>
      ))}
    </S.Container>
  );
};

export default PinInput;
