import React, { Fragment } from "react";

import SectionHeader from "components/atoms/SectionHeader";
import SectionFooter from "components/atoms/SectionFooter";

const WebAndMediaArticlesFallback = () => {
  return (
    <>
      <SectionHeader text="No web content or news articles relating to your subject found" />
      <SectionFooter />
    </>
  );
};

export default WebAndMediaArticlesFallback;
