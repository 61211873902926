import React, { Fragment } from "react";

import TruncateLength from "util/TruncateLength";

import S from "./styles";

const BAR_MAX_WIDTH = 222;
const BAR_MIN_WIDTH = 16;

const Bar = ({ value, count, addFilter, maximumOptionCount, isChecked }) => {
  const getBarWidth = () => {
    const width = (count / maximumOptionCount) * BAR_MAX_WIDTH;
    return width < BAR_MIN_WIDTH ? `${BAR_MIN_WIDTH}px` : `${width}px`;
  };

  const toggleChecked = e => {
    e.preventDefault();

    addFilter(value);
  };

  return (
    <>
      <S.BarLabelWrapper onClick={toggleChecked}>
        <div>
          <input type="checkbox" id={value} defaultChecked={isChecked} />
          <S.BarLabel htmlFor={value}>
            <S.LabelContainer>
              <TruncateLength>{value}</TruncateLength>
            </S.LabelContainer>
          </S.BarLabel>
        </div>
      </S.BarLabelWrapper>
      <S.BarContainer onClick={toggleChecked}>
        <S.BarInnerWrapper width={getBarWidth()}>
          <S.Bar isChecked={isChecked}>
            <div>{count}</div>
          </S.Bar>
        </S.BarInnerWrapper>
      </S.BarContainer>
    </>
  );
};

const FilterBars = ({
  data,
  onFilterClick,
  maximumOptionCount,
  isFilterActive,
  rolesExceedLimit,
  onShowAllClick,
  isShowingAllRoles,
  totalRoleTitles
}) => {
  return (
    <S.BarsContainer>
      {data.map(dataItem => {
        return (
          <S.SingleBarContainer
            disabled={!dataItem.isChecked && isFilterActive}
            key={dataItem.id}
          >
            <Bar
              count={dataItem.count}
              value={dataItem.label}
              addFilter={() => onFilterClick(dataItem.id)}
              isChecked={dataItem.isChecked}
              maximumOptionCount={maximumOptionCount}
            />
          </S.SingleBarContainer>
        );
      })}
      {rolesExceedLimit && (
        <S.ShowAllButton kind="tertiary" onClick={onShowAllClick}>
          Show {isShowingAllRoles ? "less" : `all ${totalRoleTitles}`}
        </S.ShowAllButton>
      )}
    </S.BarsContainer>
  );
};

export default FilterBars;
