import React from "react";

import PersonOverview from "components/organisms/PersonOverview";

const ThemedPersonOverview = React.forwardRef((props, personOverviewRef) => {
  const infographicLayoutTemplate = `
  "screening-risk-icons-title screening-risk-icons-title screening-risk-icons-title screening-risk-icons-title risk-icons-title risk-icons-title risk-icons-title risk-icons-title"
  "screening-risk-icons screening-risk-icons screening-risk-icons screening-risk-icons risk-icons risk-icons risk-icons risk-icons"
  `;

  return (
    <PersonOverview
      {...props}
      ref={personOverviewRef}
      displaySubjectImage={false}
      infographicLayoutTemplate={infographicLayoutTemplate}
    />
  );
});

export default ThemedPersonOverview;
