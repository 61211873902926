import React from "react";
import S from "./styles";

const DJDisclaimer = () => {
  return (
    <S.DJDisclaimerContainer>
      <S.DJDisclaimer>
        <S.InformationIcon />
        <div>
          <S.DJDisclaimerTitle>
            This summary is authored using generative AI from web and media
            content.
          </S.DJDisclaimerTitle>
          <span>
            You may wish to open and review items in the{" "}
            {/* This is an UGLY hack and should be done through a forwardRef context */}
            <S.Link href="#screening">Screening</S.Link> section, including the{" "}
            <strong>Profile notes</strong> written by Dow Jones R&C analysts.
          </span>
        </div>
      </S.DJDisclaimer>
    </S.DJDisclaimerContainer>
  );
};

export default DJDisclaimer;
