import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";

import { red, standardColors } from "styles/colors";

const ModalActions = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Button = styled(ButtonNew)`
  flex: 1;
`;

const DeleteButton = styled(Button)`
  color: ${standardColors.white};
  &:not(:hover, :focus, :disabled) {
    background-color: ${red.directRiskFill};
  }
`;

const S = {
  ModalActions,
  Button,
  DeleteButton
};

export default S;
