import { createContext, useContext } from "react";
import NotesStore from "state/NotesStore";

const UserNotesContext = createContext({});

const UserNotesContextProvider = ({ children }) => {
  const notesStore = new NotesStore();
  return (
    <UserNotesContext.Provider value={notesStore}>
      {children}
    </UserNotesContext.Provider>
  );
};

const useUserNotes = () => {
  const context = useContext(UserNotesContext);

  if (!context) {
    throw new Error(
      "useUserNotes must be used within a UserNotesContextProvider"
    );
  }
  return context;
};

export { UserNotesContextProvider, useUserNotes };
