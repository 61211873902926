import { styled } from "@linaria/react";
import { FloatingToolbar as RemirrorFloatingToolbar } from "@remirror/react";

import Button from "components/atoms/Button";

import { standardColors, grey } from "styles/colors";
import { family } from "styles/fonts";

const Popover = styled.div`
  border-radius: 6px;
  background-color: ${standardColors.white};
  padding: 10px;
  box-shadow: 0 4px 20px 4px rgb(0 20 60 / 10%),
    0 4px 80px -8px rgb(0 20 60 / 20%);
`;

const Input = styled.input`
  color: ${grey.dark};
`;

const FloatingToolbar = styled(RemirrorFloatingToolbar)`
  box-shadow: 0 4px 20px 4px rgb(0 20 60 / 10%),
    0 4px 80px -8px rgb(0 20 60 / 20%);
  z-index: 99;
  border-radius: 12px;
  color: ${grey.dark};

  & > div {
    border-radius: 12px;
  }
`;

const LinkEditContainer = styled.div`
  width: 180px;
  max-width: 180px;
  overflow-wrap: break-word;
`;

const SelectedText = styled.div`
  font-family: ${family.interSemiBold};
  padding-bottom: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

// We require "!important" here as Linaria struggles to override styling on our custom Button component.
const EditButton = styled(Button)`
  min-height: 0 !important;
  min-width: 0 !important;
  color: ${grey.dark} !important;
  border-bottom: none !important;
  flex: 1;
  border-top: 1px solid ${grey.rule} !important;
  padding: 4px !important;
  transition: background-color 0.2s;

  svg {
    height: 20px;
    width: 20px;
  }

  path {
    fill: black;
  }

  &:hover,
  &:focus {
    border-bottom: none !important;

    background-color: rgba(0, 0, 0, 0.04) !important;
  }
`;

const LinkAttributesContainer = styled.div`
  padding: 16px;
`;

const RemoveButton = styled(EditButton)`
  border-left: 1px solid ${grey.rule} !important;
`;

const S = {
  Popover,
  Input,
  FloatingToolbar,
  LinkEditContainer,
  SelectedText,
  ButtonsContainer,
  EditButton,
  LinkAttributesContainer,
  RemoveButton
};

export default S;
