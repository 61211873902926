import { isPDX, tenant, Tenants } from "static-config";
import { ResourcesConfig } from "aws-amplify";
import { stage, qa, diDemo } from "./services/stage";

export const devOptions = {
  newExpander: true,
  pdfExportOriginUrl: process.env.REACT_APP_local_pdf_export_origin_url
};

const getEnvironmentUrl = () => {
  const environmentUrl = "app.xapien.com";
  return `${stage}.${environmentUrl}`;
};

const getPortalCognitoUserPoolId = () => {
  switch (stage) {
    case diDemo:
      return "us-west-2_qLwzTveQN";
    case qa:
      return "us-west-2_vPaBAqzDJ";
    default:
      return "us-west-2_A29go6fav";
  }
};

const getPortalCognitoWebAppClientId = () => {
  switch (stage) {
    case diDemo:
      return "ge02tm49k4neb769djnf1epl";
    case qa:
      return "1oou8rift2o9uhrki1m2itafev";
    default:
      return "22d2chdq3m14jhu0o5pk8nklpb";
  }
};

const getPortalCognitoAuthUrl = () => `auth.identity.${stage}.xapien.com`;

const getPortalRedirectUrls = () => {
  if (window.location.host === "localhost") {
    return ["http://localhost:3000/sso/callback"];
  }
  if (isPDX) {
    if (stage === diDemo) {
      return [
        "https://integritycheck.xapien.com/sso/callback",
        "https://di-demo.integritycheck.xapien.com/sso/callback"
      ];
    }
    return [`https://${stage}.integritycheck.xapien.com/sso/callback`];
  }
  if (stage === diDemo) {
    return [
      "https://portal.xapien.com/sso/callback",
      "https://portal.di-demo.app.xapien.com/sso/callback"
    ];
  }
  return [`https://portal.${stage}.app.xapien.com/sso/callback`];
};

const getPortalRedirectSignOutUrls = () => {
  if (window.location.host === "localhost") {
    return ["http://localhost:3000"];
  }
  if (isPDX) {
    if (stage === diDemo) {
      return [
        "https://integritycheck.xapien.com",
        "https://di-demo.integritycheck.xapien.com"
      ];
    }
    return [`https://${stage}.integritycheck.xapien.com`];
  }
  if (stage === diDemo) {
    return [
      "https://portal.xapien.com",
      "https://portal.di-demo.app.xapien.com"
    ];
  }
  return [`https://portal.${stage}.app.xapien.com`];
};

export const getSearchAssistRedirectUrl = () => {
  if (stage === diDemo) {
    return "https://search.portal.xapien.com";
  }
  return `https://search.portal.${stage}.app.xapien.com`;
};

const getHubCognitoUserPoolId = () => {
  switch (stage) {
    case diDemo:
      return "us-west-2_YW5Phsc3i";
    case qa:
      return "us-west-2_jQYo2Fn3W";
    default:
      return "us-west-2_YPIDG2MsT";
  }
};

const getHubCognitoWebAppClientId = () => {
  switch (stage) {
    case diDemo:
      return "3cbka941k4d2cojhd1od3ksqsv";
    case qa:
      return "1u12jb57h2kmun9ltj7cq57bd0";
    default:
      return "5lqcg0mcvqpjm4t393luo6q004";
  }
};

const getHubCognitoAuthUrl = () => `tenant-auth.identity.${stage}.xapien.com`;

const getIdamApiUrl = () => `https://api.identity.${stage}.xapien.com`;

const getPortalApiUrl = () => `https://api.portal.${stage}.xapien.com`;

const getInsightsApiUrl = () => `https://api.insights.${stage}.xapien.com`;

const getTenantId = () => {
  const defaultDevTenantId = "ef14c0fe-1f85-4df2-8246-bbe709a15003";
  // You will need to append this to the email to get the username for cognito!
  const isProjectDx = tenant === Tenants.projectDX;
  switch (stage) {
    case "di-demo":
      return isProjectDx
        ? "8e507211-3fbe-488b-9f2d-20fd06a337e6"
        : "a852c7d6-9f2c-4705-ba96-3951412f72d0";
    case "qa":
      return isProjectDx
        ? "63ee2c1b-ff85-45d4-8038-399d260d420a"
        : "437019bf-5065-42ef-8e14-32bab77e9317";
    case "devel":
      return isProjectDx
        ? "3a914e61-eb77-4647-8b65-ace88f212602"
        : defaultDevTenantId;
    default:
      return defaultDevTenantId;
  }
};

const getApmServerUrl = () => {
  switch (stage) {
    case "di-demo":
      return "https://5c8c0ace641742958f1418befeca2440.apm.us-west-2.aws.cloud.es.io:443";
    default:
      return "https://a02c30a2f0ad468382c815f60a8c5757.apm.us-west-2.aws.cloud.es.io:443";
  }
};

export const emailAddressSwitchBasedOnTenant = () => {
  return isPDX ? "dj-integritycheck@xapien.com" : "support@xapien.com";
};

const config = {
  stage,
  tenantId: getTenantId(),
  skipWaitingScreen: false,
  inDebugMode: false,
  preparingReportPollIntervalMs: 3000,
  diagnosticIntervalMs: 3000,
  diagnosticSlowerIntervalMs: 20000,
  environmentUrl: getEnvironmentUrl(),
  tenant: process.env.REACT_APP_TENANT ?? "default",
  useNewLogin: process.env.REACT_APP_USE_NEW_LOGIN,
  amplify: {
    portal: {
      Auth: {
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: getPortalCognitoUserPoolId(),
        userPoolWebClientId: getPortalCognitoWebAppClientId(),
        oauth: {
          domain: getPortalCognitoAuthUrl(),
          scope: ["email", "profile", "openid"],
          redirectSignIn: getPortalRedirectUrls(),
          redirectSignOut: getPortalRedirectSignOutUrls(),
          responseType: "code"
        }
      }
    },
    hub: {
      Auth: {
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: getHubCognitoUserPoolId(),
        userPoolWebClientId: getHubCognitoWebAppClientId(),
        oauth: {
          domain: getHubCognitoAuthUrl(),
          scope: ["email", "profile", "openid"],
          redirectSignIn: getPortalRedirectUrls(),
          redirectSignOut: process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_OUT,
          responseType: "code"
        }
      }
    }
  },
  isApmEnabled: !!process.env.REACT_APP_APM_ENABLED,
  apmServerUrl: getApmServerUrl(),
  idamApiUrl: getIdamApiUrl(),
  portalApiUrl: getPortalApiUrl(),
  insightsApiUrl: getInsightsApiUrl(),
  supportEmail: emailAddressSwitchBasedOnTenant()
};

type AmplifyConfig = {
  portal: ResourcesConfig;
  hub: ResourcesConfig;
};

export const amplifyConfig: AmplifyConfig = {
  portal: {
    Auth: {
      Cognito: {
        userPoolClientId: getPortalCognitoWebAppClientId(),
        userPoolId: getPortalCognitoUserPoolId(),
        loginWith: {
          oauth: {
            domain: getPortalCognitoAuthUrl(),
            scopes: ["email", "profile", "openid"],
            redirectSignIn: getPortalRedirectUrls(),
            redirectSignOut: getPortalRedirectSignOutUrls(),
            responseType: "code"
          }
        }
      }
    }
  },
  hub: {
    Auth: {
      Cognito: {
        userPoolClientId: getHubCognitoWebAppClientId(),
        userPoolId: getHubCognitoUserPoolId(),
        loginWith: {
          oauth: {
            domain: getHubCognitoAuthUrl(),
            scopes: ["email", "profile", "openid"],
            redirectSignIn: [process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_IN!],
            redirectSignOut: [process.env.REACT_APP_AMPLIFY_REDIRECT_SIGN_OUT!],
            responseType: "code"
          }
        }
      }
    }
  }
};

export default config;
