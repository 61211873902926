import type { Report as RawReport } from "api/insights";

export const moveSubSectionToAnotherSection = (
  report: RawReport,
  currentSectionId: string,
  currentSubSectionId: string,
  destinationSectionId: string
): RawReport => {
  // Find the current section and the subsection to move
  const currentSection = report.sections.find(
    section => section.id === currentSectionId
  );
  if (!currentSection) throw new Error("Current section not found");

  const subsectionToMove = currentSection.subsections.find(
    subsection => subsection.id === currentSubSectionId
  );
  if (!subsectionToMove) throw new Error("Subsection to move not found");

  // Remove the subsection from the current section
  currentSection.subsections = currentSection.subsections.filter(
    subsection => subsection.id !== currentSubSectionId
  );

  // Find the destination section
  const destinationSection = report.sections.find(
    section => section.id === destinationSectionId
  );
  if (!destinationSection) throw new Error("Destination section not found");

  // Add the subsection to the destination section
  destinationSection.subsections.push(subsectionToMove);

  // Return the updated report
  return {
    ...report,
    sections: report.sections.map(section =>
      section.id === currentSectionId
        ? currentSection
        : section.id === destinationSectionId
          ? destinationSection
          : section
    )
  };
};
