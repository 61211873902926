import React, {
  CSSProperties,
  ChangeEventHandler,
  KeyboardEventHandler
} from "react";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  placeholder: string;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  value: string;
  style?: CSSProperties;
  className?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    { placeholder, disabled, onChange, onKeyDown, value, style, className },
    ref
  ) => {
    return (
      <S.TextInput
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        type="text"
        ref={ref}
        style={style}
        className={className}
        boxShadowColor={theme.button?.alternativeBoxShadowColor}
      />
    );
  }
);

export default TextInput;
