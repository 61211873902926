import React from "react";

import { TimelineEvent } from "api/insights";

import EventNode from "components/organisms/InsightReport/TimelineSection/Event/EventNode";
import EventContent from "components/organisms/InsightReport/TimelineSection/Event/EventContent";

import S from "./styles";

export interface EventProps {
  event: TimelineEvent;
  alignment: string;
  nodeSize: number;
  className?: string;
  isFirst: boolean;
  handleToggleDeleteModel: () => void;
}

const Event: React.FC<EventProps> = ({
  event,
  alignment,
  nodeSize,
  className,
  isFirst,
  handleToggleDeleteModel
}) => {
  return (
    <S.EventContainer
      nodeSize={nodeSize}
      alignment={alignment}
      className={className}
    >
      <EventNode
        alignment={alignment}
        nodeSize={nodeSize}
        event={event}
        isFirst={isFirst}
      />
      <EventContent
        alignment={alignment}
        nodeSize={nodeSize}
        event={event}
        handleToggleDeleteModel={handleToggleDeleteModel}
      />
    </S.EventContainer>
  );
};

export default Event;
