import React, { FC } from "react";

import { FeAnswer, FeSenteceFragment } from "api/old-insights";
import { InformationSource, SourceType } from "api/report/report-types";
import WithInspector from "components/organisms/WithInspector";
import { useSourceMapping } from "util/hooks/useSourceMapping";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";

import { useInsightsSources } from "./useInsightsSources_delete";

type Props = {
  answer: FeAnswer;
};

const SourcedAnswerFragment: FC<{ fragment: FeSenteceFragment }> = ({
  fragment
}) => {
  const mapSource = useSourceMapping();
  // When we have more time we should group so that multiple sentences
  // from one source -> one source rather than repeating the source
  const sources: InformationSource[] =
    fragment.supporting_source_sentences.flatMap(sentence => {
      const sourceDoc = sentence?.from_document;
      if (!sourceDoc || !sourceDoc.wam_source_ids) {
        return [];
      }
      const sourcesFromMap = sourceDoc.wam_source_ids
        .map(id => mapSource(id))
        .flatMap(s => (s ? { ...s, snippets: [sentence.text] } : []));

      if (sourcesFromMap.length) {
        return sourcesFromMap;
      }
      console.warn("Missing source from map", sentence);
      return [
        {
          title: sourceDoc.title,
          snippets: [sentence.text],
          id: sourceDoc.id,
          isAvailableOnline: false,
          url: null,
          sourceType: SourceType.Webpage
        }
      ];
    });
  return (
    <>
      <WithInspector display="inline" sources={sources}>
        {fragment.text}
      </WithInspector>
      {fragment.text.trimEnd().endsWith(".") ? " " : ""}
      {/* ^sorts out a spacing issue */}
    </>
  );
};

export const SourcedInsightsAnswer: FC<Props> = ({ answer }) => {
  const { sourcedFragments, fetchSources, error, loading } = useInsightsSources(
    {
      answer
    }
  );
  return (
    <div>
      {sourcedFragments && sourcedFragments.length && !loading ? (
        sourcedFragments.map(frag => (
          <SourcedAnswerFragment key={frag.ordinal} fragment={frag} />
        ))
      ) : (
        <WithInspector
          customContent={
            loading ? "Loading sourcing..." : error || "No sources found"
          }
          dataFormat={INSPECTOR_DATA_FORMATS.custom}
          isInspectorOpen={(open: boolean) => open && fetchSources()}
        >
          {answer.plain_text}
        </WithInspector>
      )}
    </div>
  );
};
