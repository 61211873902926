import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";
import { blue } from "styles/colors";
import Accordion from "components/molecules/Accordion";
import { hexToRgb } from "styles/hexToRgb";
import SocialMediaIcons from "components/molecules/SocialMediaIcons";

const ContactInformationContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: ${fontSize.sm};

  & > div:first-of-type {
    padding-top: 0;
  }
`;

const EmailItem = styled.div`
  max-width: ${props => (props.isUncertain ? "165px" : "175px")};
`;

const WebsiteItem = styled.div`
  max-width: ${props => (props.isUncertain ? "165px" : "175px")};
`;

const CustomAccordion = styled(Accordion)`
  padding-top: 0;

  & > button {
    height: 28px;
    align-items: center;
    border-radius: 3px;
    padding-left: 2px;
    cursor: ${props =>
      props.dataCount === 0 ? "default" : "pointer"} !important;

    &:focus,
    &:hover {
      outline: none;
      background-color: ${props => {
        if (props.dataCount === 0) {
          return "";
        }
        const { r, g, b } = hexToRgb(blue.icon);
        return `rgba(${r}, ${g}, ${b}, 0.15)`;
      }};
    }
  }
`;

const SocialMedia = styled(SocialMediaIcons)`
  max-height: ${props => props.maxHeight};
  overflow-y: auto;
`;

const PhoneInfoContainer = styled.div`
  display: flex;
`;

const S = {
  ContactInformationContainer,
  WebsiteItem,
  EmailItem,
  CustomAccordion,
  SocialMedia,
  PhoneInfoContainer
};

export default S;
