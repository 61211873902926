import type {
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection
} from "api/insights";

export const removeSubSectionFromReportBySubSectionId = (
  report: RawReport,
  subsectionId: string
): RawReport => {
  const topSection = report.sections.find((s: RawSection) =>
    s.subsections.find((sub: RawSubsection) => sub.id === subsectionId)
  );

  if (!topSection) return report;

  const updatedSubSections = topSection.subsections.filter(
    (sub: RawSubsection) => sub.id !== subsectionId
  );

  const updatedSection = { ...topSection, subsections: updatedSubSections };

  return {
    ...report,
    sections: report.sections.map((s: RawSection) =>
      s.id === updatedSection.id ? updatedSection : s
    )
  };
};
