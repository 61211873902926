import React, {
  useMemo,
  useReducer,
  useCallback,
  useEffect,
  FC,
  ReactNode
} from "react";
import { useNavigate } from "react-router-dom";
import Api from "api/users";

import {
  initialState,
  registrationReducer,
  RegistrationContext
} from "./context";
import {
  RegistrationStatus,
  RegistrationAction,
  RegistrationActions
} from "./types";

interface Props {
  token: string;
  children: ReactNode;
}

interface Token {
  requestId: string;
  email: string;
  expiry: string;
}

const isValidTokenFormat = (value: any): value is Token => {
  return (
    typeof value === "object" &&
    value !== null &&
    typeof value.requestId === "string" &&
    typeof value.email === "string" &&
    typeof value.expiry === "string"
  );
};

const parseToken = (token: string): Token => {
  try {
    const value = JSON.parse(atob(token));
    if (!isValidTokenFormat(value)) {
      throw new Error("Invalid token format");
    }

    return value as Token;
  } catch (e) {
    console.error(e);
    throw new Error("Invalid token format");
  }
};

const validateToken = async (token: string) => {
  const api = new Api();

  return api.validateRegistrationToken({ token });
};

export const RegistrationContextProvider: FC<Props> = ({ token, children }) => {
  const navigate = useNavigate();
  const { email, expiry } = parseToken(token);
  const [state, reducerDispatch] = useReducer(registrationReducer, {
    ...initialState,
    token,
    email,
    tokenExpiry: expiry
  });

  const dispatch = useCallback((action: RegistrationAction) => {
    reducerDispatch(action);
  }, []);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    const expiryDate = new Date(expiry);
    const currentDate = new Date();

    if (expiryDate < currentDate) {
      dispatch({ type: RegistrationActions.tokenExpired });
    }
  }, [expiry, dispatch]);

  useEffect(() => {
    if (token) {
      validateToken(token).then(({ status }) => {
        if (!status) {
          navigate("/login");
        }
      });
    }
  }, [token, navigate]);

  useEffect(() => {
    if (state.status === RegistrationStatus.registrationSubmitted) {
      const api = new Api();

      api.register(state).then(({ status, message }) => {
        if (status) {
          navigate("/login");
        } else {
          dispatch({
            type: RegistrationActions.registrationFailed,
            error: message || "Something went wrong. Please try again."
          });
        }
      });
    }
  }, [state, dispatch, navigate]);

  return (
    <RegistrationContext.Provider value={providerValue}>
      {children}
    </RegistrationContext.Provider>
  );
};
