import { styled } from "@linaria/react";

import ButtonNew from "components/atoms/ButtonNew";

import { grey, standardColors, red } from "styles/colors";
import { fontSize, family } from "styles/fonts";

export interface SectionSelectSelectedItemProps {
  open: boolean;
}

export interface SectionDropdownItemProps {
  active: boolean;
}

const CancelButton = styled(ButtonNew)`
  background: ${grey.panel};
  padding: 12px 16px;
  font-family: ${family.interDisplay};
`;

const DeleteButton = styled(ButtonNew)`
  background: ${red.directRiskFill};
  color: ${standardColors.white};
  padding: 12px 24px;
  font-family: ${family.interDisplay};

  &:hover {
    background-color: ${red.dark};
  }
`;

const Actions = styled.div`
  padding-top: 16px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

const ContextTextArea = styled.div`
  border: 1px solid ${red.directRiskFill};
  width: 100%;
  height: 180px;
  padding: 1rem;
  overflow-y: scroll;
  border-radius: 10px;
  background: ${grey.lighterPanel};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25) inset;
  color: ${grey.mid};
`;

const ContentWrapper = styled.div`
  pointer-events: none;
  & h3 {
    color: ${grey.mid};
  }
`;

const DeleteTimelineModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px 8px 8px 8px;
  height: 268px;
`;

const IconContainer = styled.div`
  margin-bottom: 12px;
`;

const DeleteEventText = styled.div`
  font-size: ${fontSize.lg};
  font-family: ${family.interSemiBold};
  font-weight: inherit;
`;
const EventContent = styled.div`
  font-size: ${fontSize.sm};
  font-family: ${family.interRegular};
  color: ${grey.dark};
  margin-bottom: 6px;
`;

const S = {
  Actions,
  CancelButton,
  DeleteButton,
  ContentWrapper,
  ContextTextArea,
  DeleteTimelineModalContainer,
  IconContainer,
  DeleteEventText,
  EventContent
};

export default S;
