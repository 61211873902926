import { styled } from "@linaria/react";

import { ReactComponent as InformationSvgIcon } from "img/icons/information-icon.svg";

const InfoIconContainer = styled.div`
  margin-bottom: 2px;
  margin-left: 3px;
`;

const InformationIcon = styled(InformationSvgIcon)`
  height: 14px;
  width: 14px;

  &:hover {
    cursor: pointer;
  }
`;

const TooltipContent = styled.div<{ width?: number }>`
  text-align: left;
  padding: 2px;
  width: ${props => props.width ?? 275}px;
  max-width: 275px;
  max-height: 325px;
  overflow-y: auto;
`;

const S = {
  InfoIconContainer,
  InformationIcon,
  TooltipContent
};

export default S;
