import { useContext } from "react";
import { CollectionListContext } from "./context";
import { CollectionListContextProvider } from "./provider";
import { CollectionListActions } from "./types";

const useCollectionList = () => {
  const context = useContext(CollectionListContext);

  return context;
};

export {
  CollectionListContext,
  CollectionListContextProvider,
  useCollectionList,
  CollectionListActions
};
