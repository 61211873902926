import { styled } from "@linaria/react";
import { grey } from "styles/colors";
import { family, size } from "styles/fonts";

const Disclaimer = styled.span`
  color: ${grey.mid};
  font-feature-settings: "liga" off, "clig" off;
  font-family: ${family.interRegular};
  font-size: ${size.xs};
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.57px;
`;

const S = { Disclaimer };

export default S;
