import { UserDetails } from "api/user/types";

export interface UserSettingsState {
  userDetails: UserDetails;
  isSettingsModalOpen: boolean;
  isAutoshareModalOpen: boolean;
  isInviteUserModalOpen: boolean;
  fetching: boolean;
  error: boolean;
}

export enum UserSettingsActions {
  UpdateUserDetails = "UpdateUserDetails",
  ToggleSettingsModal = "ToggleSettingsModal",
  ToggleAutoshareModal = "ToggleAutoshareModal",
  ToggleInviteUserModal = "ToggleInviteUserModal",
  ResetUserSettings = "ResetUserSettings"
}

interface UpdateUserDetailsAction {
  type: UserSettingsActions.UpdateUserDetails;
  userDetails: UserDetails;
}

interface ToggleSettingsModal {
  type: UserSettingsActions.ToggleSettingsModal;
}

interface ToggleAutoshareModal {
  type: UserSettingsActions.ToggleAutoshareModal;
}

interface ToggleInviteUserModal {
  type: UserSettingsActions.ToggleInviteUserModal;
}

interface ResetUserSettings {
  type: UserSettingsActions.ResetUserSettings;
}

export type UserSettingsAction =
  | UpdateUserDetailsAction
  | ToggleAutoshareModal
  | ToggleSettingsModal
  | ToggleInviteUserModal
  | ResetUserSettings;
