import React from "react";

import { ReactComponent as ValidAssertion } from "img/icons/assertions/tick_circle.svg";
import { ReactComponent as FailedAssertion } from "img/icons/assertions/exclamation_circle.svg";
import { ReactComponent as PendingAssertion } from "img/icons/assertions/ellipsis_circle.svg";
import { AssertionState } from "util/hooks/useAssertionsContext";

import S from "./styles";

const AssertionIcon = ({ assertion }) => {
  switch (assertion.state) {
    case AssertionState.HoldsTrue:
      return (
        <S.AssertionIcon data-state={1}>
          <ValidAssertion alt="ValidAssertion" />
        </S.AssertionIcon>
      );
    case AssertionState.Violated:
      return (
        <S.AssertionIcon data-state={2}>
          <FailedAssertion alt="FailedAssertion" />
        </S.AssertionIcon>
      );
    default:
      // AssertionStates "Pending" (0) and "BeingDeleted" (3) are both yellow, so we just use 0 here
      return (
        <S.AssertionIcon data-state={0}>
          <PendingAssertion alt="PendingAssertion" />
        </S.AssertionIcon>
      );
  }
};

export default AssertionIcon;
