// kept for backwards compatibility with reports pre-enum-as-string serialisation
const AssessmentOptionsOld = Object.freeze({
  Clear: 0,
  Verify: 1,
  Exclude: 2
});

export const AssessmentOptions = Object.freeze({
  NoUserAssessment: "NoUserAssessment",
  RightPerson: "RightPerson",
  WrongPerson: "WrongPerson"
});

export const convertOldToNew = old => {
  switch (old) {
    case AssessmentOptionsOld.Clear:
      return AssessmentOptions.NoUserAssessment;
    case AssessmentOptionsOld.Verify:
      return AssessmentOptions.RightPerson;
    case AssessmentOptionsOld.Exclude:
      return AssessmentOptions.WrongPerson;
    default:
      return AssessmentOptions.NoUserAssessment;
  }
};

// have to use this to work around the fact that the apis are using number-enum json converters still
export const convertNewToOld = newValue => {
  switch (newValue) {
    case AssessmentOptions.NoUserAssessment:
      return AssessmentOptionsOld.Clear;
    case AssessmentOptions.RightPerson:
      return AssessmentOptionsOld.Verify;
    case AssessmentOptions.WrongPerson:
      return AssessmentOptionsOld.Exclude;
    default:
      return -1;
  }
};

export const userAssessmentMapping = {
  confirmed: AssessmentOptions.RightPerson,
  discarded: AssessmentOptions.WrongPerson
};
