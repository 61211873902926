import React, { useState } from "react";

import Heading from "components/atoms/Heading";
import { SearchResult } from "api/search";
import Input from "components/molecules/SimpleSearchInput";
import S from "components/organisms/CopyReportModal/styles";

import useOrganisationSearch from "./useOrganisationSearch";

interface Props {
  selectedSuggestion?: SearchResult;
  setSelectedSuggestion: React.Dispatch<
    React.SetStateAction<SearchResult | undefined>
  >;
}

const SelectOrganisation = ({
  selectedSuggestion,
  setSelectedSuggestion
}: Props) => {
  const [query, setQuery] = useState("");

  const { organisationSuggestions, onSearchUpdate, isFetchingSuggestions } =
    useOrganisationSearch();

  const onQueryInputChange = (newQuery: string) => {
    setQuery(newQuery);
    onSearchUpdate(newQuery);
  };

  const onSelectedSuggestionsChange = (
    newSelectedSuggestions: SearchResult[]
  ) => {
    // If the selected suggestion is the suggestion that has already been selected, remove it
    if (
      !newSelectedSuggestions.length ||
      (selectedSuggestion &&
        selectedSuggestion.id ===
          newSelectedSuggestions[newSelectedSuggestions.length - 1].id)
    ) {
      setSelectedSuggestion(undefined);
    } else {
      const newSuggestion = newSelectedSuggestions.pop();
      setSelectedSuggestion(newSuggestion);
    }
  };

  return (
    <S.InputGroup>
      <Heading level={6}>Select an organisation</Heading>
      <Input
        query={query}
        onQueryInputChange={onQueryInputChange}
        selectedSuggestion={selectedSuggestion ? [selectedSuggestion] : []}
        onSelectedSuggestionChange={onSelectedSuggestionsChange}
        suggestions={organisationSuggestions}
        loadingText={isFetchingSuggestions ? "Loading..." : "No results found"}
        placeholder="Enter organisation name"
      />
    </S.InputGroup>
  );
};

export default SelectOrganisation;
