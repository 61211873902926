import React, { FC, useState } from "react";
import { TimelineEvent } from "api/insights";
import useInsightsFeatures from "util/hooks/useInsightsFeatures";
import TimelineTooltip from "components/organisms/InsightReport/TimelineSection/TimelineTooltip";

// @ts-ignore
import theme from "theme";
import { red, orange } from "styles/colors";
import { isPDX } from "static-config";

import { ReactComponent as BackgroundXapien } from "./timeline_date_tag_xapien.svg";
import { ReactComponent as BackgroundPdx } from "./timeline_date_tag_pdx.svg";
import { ReactComponent as BackgroundOrange } from "./timeline_date_tag_orange.svg";
import { ReactComponent as BackgroundRed } from "./timeline_date_tag_red.svg";

import S from "./styles";

export interface DateTagProps {
  event: TimelineEvent;
  isRight: boolean;
  isFirst?: boolean;
}

const DateTag: FC<DateTagProps> = ({ event, isRight, isFirst }) => {
  const { timelinesRiskColors } = useInsightsFeatures();
  const [showTooltip, setShowTooltip] = useState(false);

  const getColor = (): [string, string] => {
    if (!timelinesRiskColors) {
      return ["Riskless", theme.primaryColor];
    }

    const riskSignificance = event.risk_categories.map(x => x.significance);
    if (riskSignificance.includes("Direct")) {
      return ["Direct", red.directRisk];
    }

    if (riskSignificance.includes("Indirect")) {
      return ["Indirect", orange.indirectRisk];
    }

    return ["Riskless", theme.primaryColor];
  };

  const renderBackgroundSvg = () => {
    if (timelinesRiskColors) {
      const riskSignificance = event.risk_categories.map(x => x.significance);
      if (riskSignificance.includes("Direct")) {
        return <BackgroundRed />;
      }

      if (riskSignificance.includes("Indirect")) {
        return <BackgroundOrange />;
      }
    }

    if (isPDX) {
      return <BackgroundPdx />;
    }

    return <BackgroundXapien />;
  };

  const handleMouseEvent = () => {
    if (!timelinesRiskColors) {
      return;
    }

    setShowTooltip(prev => !prev);
  };

  const [significance, riskColor] = getColor();

  return (
    <>
      {showTooltip && (
        <TimelineTooltip
          riskColor={riskColor}
          significance={significance}
          isRight={isRight}
          above={!isFirst}
        />
      )}
      <S.DateTagContainer
        isRight={isRight}
        onMouseEnter={handleMouseEvent}
        onMouseLeave={handleMouseEvent}
      >
        {renderBackgroundSvg()}
        <S.DateTag>
          {event.date!.month_short ?? ""} {event.date!.year ?? ""}
        </S.DateTag>
      </S.DateTagContainer>
    </>
  );
};

export default DateTag;
