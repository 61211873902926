import React, { Fragment } from "react";
import numeral from "numeral";

import TruncateLength from "util/TruncateLength";
import { UncertainDataFlag } from "components/atoms/UncertainDataFlag";
import { ReactComponent as AngelList } from "img/icons/socialMedia/AngelList.svg";
import { ReactComponent as Crunchbase } from "img/icons/socialMedia/Crunchbase.svg";
import { ReactComponent as Facebook } from "img/icons/socialMedia/Facebook.svg";
import { ReactComponent as Flickr } from "img/icons/socialMedia/Flickr.svg";
import { ReactComponent as Instagram } from "img/icons/socialMedia/Instagram.svg";
import { ReactComponent as Linkedin } from "img/icons/socialMedia/Linkedin.svg";
import { ReactComponent as Pinterest } from "img/icons/socialMedia/Pinterest.svg";
import { ReactComponent as Reddit } from "img/icons/socialMedia/Reddit.svg";
import { ReactComponent as Soundcloud } from "img/icons/socialMedia/Soundcloud.svg";
import { ReactComponent as Twitter } from "img/icons/socialMedia/Twitter.svg";
import { ReactComponent as VK } from "img/icons/socialMedia/VK.svg";
import { ReactComponent as Wikipedia } from "img/icons/socialMedia/Wikipedia.svg";
import { ReactComponent as Youtube } from "img/icons/socialMedia/Youtube.svg";
import { ReactComponent as Website } from "img/icons/socialMedia/Website.svg";
import WithInspector from "components/organisms/WithInspector";
import Assertable from "util/Assertable";
import exactSocialProfileAssertionTemplate from "util/Assertable/util/exactSocialProfileAssertionTemplate";
import ReactTooltip from "components/atoms/ReactTooltip";

import S from "./styles";

const socialMediaIconComponent = label => {
  switch (label && label.toLowerCase()) {
    case "angellist":
      return (
        <S.MediaIcon>
          <AngelList alt="Angel List" />
        </S.MediaIcon>
      );
    case "crunchbase":
      return (
        <S.MediaIcon>
          <Crunchbase alt="Crunchbase" />
        </S.MediaIcon>
      );
    case "facebook":
      return (
        <S.MediaIcon>
          <Facebook alt="Facebook" />
        </S.MediaIcon>
      );
    case "flickr":
      return (
        <S.MediaIcon>
          <Flickr alt="Flickr" />
        </S.MediaIcon>
      );
    case "instagram":
      return (
        <S.MediaIcon>
          <Instagram alt="Instagram" />
        </S.MediaIcon>
      );
    case "linkedin":
      return (
        <S.MediaIcon>
          <Linkedin alt="linkedin" />
        </S.MediaIcon>
      );
    case "pinterest":
      return (
        <S.MediaIcon>
          <Pinterest alt="pinterest" />
        </S.MediaIcon>
      );
    case "reddit":
      return (
        <S.MediaIcon>
          <Reddit alt="reddit" />
        </S.MediaIcon>
      );
    case "soundcloud":
      return (
        <S.MediaIcon>
          <Soundcloud alt="soundcloud" />
        </S.MediaIcon>
      );
    case "twitter":
      return (
        <S.MediaIcon>
          <Twitter alt="twitter" />
        </S.MediaIcon>
      );
    case "vk":
      return (
        <S.MediaIcon>
          <VK alt="vk" />
        </S.MediaIcon>
      );
    case "wikipedia":
      return (
        <S.MediaIcon>
          <Wikipedia alt="wikipedia" />
        </S.MediaIcon>
      );
    case "youtube":
      return (
        <S.MediaIcon>
          <Youtube alt="youtube" />
        </S.MediaIcon>
      );
    default:
      return (
        <S.WebsiteIconContainer>
          <Website alt="website" />
        </S.WebsiteIconContainer>
      );
  }
};

const SocialMediaIcons = ({
  profileData,
  showLabels = true,
  arrangeIconsInRow = false,
  showFollowerCount = false,
  isInspectorEnabled,
  disabled,
  maxLinkWidth,
  className,
  style
}) => {
  if (!profileData || profileData.length < 1) {
    return false;
  }

  const renderFollowerCount = numberOfFollowers => {
    return (
      <ReactTooltip
        position="right"
        tooltip={<div>Number of followers or connections.</div>}
        maxWidth="300px"
        delay={800}
      >
        <S.FollowerCount>
          {numberOfFollowers ? numeral(numberOfFollowers).format("0a") : null}
        </S.FollowerCount>
      </ReactTooltip>
    );
  };

  const renderMediaItemValues = (profileType, label, isUncertain) => {
    return (
      <Fragment>
        {profileType ? socialMediaIconComponent(profileType) : false}
        {isUncertain ? <UncertainDataFlag /> : ""}
        {showLabels && <TruncateLength>{label}</TruncateLength>}
      </Fragment>
    );
  };

  const renderInspectorMediaItem = data => {
    return (
      <WithInspector
        key={`${data.data.link}_${data.data.label}`}
        highlightPadding="0px"
        sources={data.data?.sources}
        topSectionElement={
          <S.MediaLink
            href={disabled ? null : data.data.link}
            target="_blank"
            rel="noopener noreferrer"
            disabled={disabled}
          >
            {data.data.profileType
              ? socialMediaIconComponent(data.data.profileType)
              : false}
            {data.isUncertain ? <UncertainDataFlag /> : ""}
            <S.ProfileLabel>{data.data.label}</S.ProfileLabel>
          </S.MediaLink>
        }
      >
        <S.MediaItemWithInspector>
          <S.MediaLinkWithInspector
            href={data.data.link}
            target="_blank"
            rel="noopener noreferrer"
            maxWidth={
              showFollowerCount && data.data.numberOfFollowers
                ? // Remove 10px to account for the presence of a follower count
                  `${maxLinkWidth - 10}px`
                : `${maxLinkWidth}px`
            }
          >
            {renderMediaItemValues(
              data.data.profileType,
              data.data.label,
              data.isUncertain
            )}
          </S.MediaLinkWithInspector>
          {showFollowerCount &&
            renderFollowerCount(data.data.numberOfFollowers)}
        </S.MediaItemWithInspector>
      </WithInspector>
    );
  };

  const renderMediaItem = data => {
    return (
      <S.MediaItem
        key={`${data.data.link}_${data.data.label}`}
        arrangeIconsInRow={arrangeIconsInRow}
      >
        <Assertable
          template={exactSocialProfileAssertionTemplate(
            data.data.profileType,
            data.data.link
          )}
        >
          <S.MediaLink
            href={disabled ? null : data.data.link}
            target="_blank"
            rel="noopener noreferrer"
            disabled={disabled}
            maxWidth={maxLinkWidth}
          >
            {renderMediaItemValues(
              data.data.profileType,
              data.data.label,
              data.isUncertain
            )}
            {showFollowerCount &&
              renderFollowerCount(data.data.numberOfFollowers)}
          </S.MediaLink>
        </Assertable>
      </S.MediaItem>
    );
  };

  return (
    <S.MediaItemContainer
      arrangeIconsInRow={arrangeIconsInRow}
      className={className}
      style={style}
    >
      {profileData
        .filter(d => !!d)
        .map(data => {
          if (data.data) {
            if (isInspectorEnabled) {
              return renderInspectorMediaItem(data);
            }

            return renderMediaItem(data);
          }

          return false;
        })}
    </S.MediaItemContainer>
  );
};

export default SocialMediaIcons;
