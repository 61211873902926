import React, { FC, CSSProperties, ReactNode } from "react";

import S from "./styles";

interface Props {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
}

const SectionFooter: FC<Props> = ({ style, className, children }) => {
  return (
    <S.Footer style={style} className={className}>
      {children}
    </S.Footer>
  );
};

export default SectionFooter;
