import React from "react";
import { dJToolTipText } from "themes/projectdx/ThemedScreening/util";
import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";

import S from "./styles";

interface Props {
  selectedConfidenceFilter: CONFIDENCE_CATEGORIES;
}

const ConfidenceToolTipText = ({ selectedConfidenceFilter }: Props) => {
  const renderToolTipContent = () => {
    if (selectedConfidenceFilter === CONFIDENCE_CATEGORIES.confirmed) {
      return (
        <>
          <strong>{dJToolTipText.confimed.title}: </strong>
          {dJToolTipText.confimed.text}
        </>
      );
    }
    if (selectedConfidenceFilter === CONFIDENCE_CATEGORIES.unconfirmed) {
      return (
        <>
          <strong>{dJToolTipText.unconfirmed.title}: </strong>
          {dJToolTipText.unconfirmed.text}
        </>
      );
    }
    if (selectedConfidenceFilter === CONFIDENCE_CATEGORIES.discarded) {
      return (
        <>
          <strong>{dJToolTipText.discarded.title}: </strong>
          {dJToolTipText.discarded.text}
        </>
      );
    }
    if (selectedConfidenceFilter === CONFIDENCE_CATEGORIES.userDiscarded) {
      return (
        <>
          <strong>{dJToolTipText.userDiscarded.title}: </strong>
          {dJToolTipText.userDiscarded.text}
        </>
      );
    }
    return null;
  };

  return <S.TooltipContent>{renderToolTipContent()}</S.TooltipContent>;
};

export default ConfidenceToolTipText;
