import { styled } from "@linaria/react";

const Container = styled.div`
  width: 1080px;
  background: white;
  border-radius: 20px;
  padding: 2rem;
  margin: auto;
`;

const S = { Container };

export default S;
