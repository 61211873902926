import React, { useState, useEffect } from "react";

import SortFilterCategory from "pages/report/sections/WebAndMedia/SortFilterPillCategory";
import { ButtonKind } from "components/atoms/Button/types";

import S from "./styles";

const delay = timeoutMs =>
  new Promise(r => {
    setTimeout(r, timeoutMs);
  });

const SortFilterCategories = props => {
  const {
    categories,
    categoriesToUse,
    setCategoriesToUse,
    titleCase,
    loadEager,
    // Temporary value until we remove Not Processed and
    // Disregarded sections. This value ensures backewards
    // compatibility between the new and older sections.
    isInSnapshot,
    nameVariants = {},
    title = ""
  } = props;

  const transformedCats = [...((categories && categories.keys()) || [])].reduce(
    (acc, key) => {
      if (key !== undefined && key !== null) {
        return [...acc, categories.get(key)];
      }

      return acc;
    },
    []
  );

  const [sortedCats, setSortedCats] = useState(transformedCats);
  const [loadLimit, setLoadLimit] = useState(10);
  const [isSortAlphabeticallyActive, setIsSortAlphabeticallyActive] =
    useState(false);

  const updateCategoriesToDisplay = sortAlphabetically => {
    if (sortAlphabetically) {
      const sortedByAlphabet = [...transformedCats].sort((catA, catB) =>
        (catA.display || "").localeCompare(catB.display || "")
      );
      setSortedCats(sortedByAlphabet);
    } else {
      const sortedByFreq = [...transformedCats].sort((catA, catB) => {
        return catB.currentAvailable - catA.currentAvailable;
      });
      setSortedCats(sortedByFreq);
    }
  };

  useEffect(() => {
    updateCategoriesToDisplay(isSortAlphabeticallyActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    let limit = 0;
    let stop = false;
    (async () => {
      while (categories && limit < categories.size && !stop) {
        limit += 50;
        setLoadLimit(limit);
        // eslint-disable-next-line no-await-in-loop
        await delay(1000 * Math.random());
      }
    })();
    return () => {
      stop = true;
    };
  }, [categories]);

  return (
    <div data-testid="pill-category">
      {!isInSnapshot ? (
        <div>Show only...</div>
      ) : (
        <S.FilterTitle>
          <S.FilterTitleLabel>{title}</S.FilterTitleLabel>
          {transformedCats?.length > 0 && (
            <S.Controls>
              <S.SortButton
                kind={ButtonKind.secondary}
                onClick={() => {
                  // Set sort type
                  const sortAlphabetically = !isSortAlphabeticallyActive;
                  setIsSortAlphabeticallyActive(sortAlphabetically);
                  updateCategoriesToDisplay(sortAlphabetically);
                }}
                isSortAlphabeticallyActive={isSortAlphabeticallyActive}
              >
                A-Z
              </S.SortButton>
            </S.Controls>
          )}
        </S.FilterTitle>
      )}
      <S.SortFilterCategories role="menu">
        {sortedCats
          .slice(0, loadEager ? sortedCats.length : loadLimit)
          .map(cat => {
            const { key } = cat;
            const setSelected = () => {
              const newSet = new Set(categoriesToUse);
              if (categoriesToUse.has(key)) {
                newSet.delete(key);
                setCategoriesToUse(newSet, key);
              } else {
                newSet.add(key);
                setCategoriesToUse(newSet, key);
              }
            };
            return (
              <SortFilterCategory
                selected={categoriesToUse && categoriesToUse.has(key)}
                {...cat}
                {...{ setSelected }}
                {...{ titleCase }}
                key={key}
                isInSnapshot={isInSnapshot}
                listOfNameVariants={nameVariants[cat.key]}
              />
            );
          })}
      </S.SortFilterCategories>
    </div>
  );
};

export default SortFilterCategories;
