import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";

import Heading from "components/atoms/Heading";
import Input from "components/atoms/Input";
import FileInput from "components/atoms/FileInput";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import CreateOrgModal from "components/organisms/CreateOrgModal";
import PageTitle from "components/atoms/PageTitle";
import { routes } from "pages/Router";

import S from "./styles";

const ORG_NAME_LIMIT = 30;

const CreateOrganisation = () => {
  const [isCreateOrgModalOpen, setIsCreateOrgModalOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgDescription, setOrgDescription] = useState("");
  const [users, setUsers] = useState("");
  const [admins, setAdmins] = useState("");
  const [enableSso, setEnableSso] = useState(false);
  const [metadataUrl, setMetadataUrl] = useState<string | null>(null);
  const [metadata, setMetadata] = useState<string | null>(null);

  const navigate = useNavigate();

  const onOrgNameChange = (value: string) => {
    if (value.length <= ORG_NAME_LIMIT) {
      setOrgName(value);
    }
  };

  const onOrgDescriptionChange = (value: string) => {
    setOrgDescription(value);
  };

  const onAdminsChange = (value: string) => {
    setAdmins(value);
  };

  const onUsersChange = (value: string) => {
    setUsers(value);
  };

  const handleMetadataFileChange = (file: File | null) => {
    if (!file) {
      setMetadata(null);
      return;
    }

    if (file.type !== "text/xml") {
      return;
    }

    file.text().then(setMetadata);
  };

  const requiredFieldsFilled =
    orgName &&
    orgName.length &&
    ((users && users.length) || (admins && admins.length));

  return (
    <>
      <S.CreateOrganisation>
        <PageTitle title="Create organisation" />
        <S.CreateOrganisationInputs>
          <S.Section>
            <S.InputGroup>
              <Heading level={6}>Enter the organisation name</Heading>
              <Input
                placeholder="Enter name"
                onChange={onOrgNameChange}
                value={orgName}
                autoComplete="off"
              />
            </S.InputGroup>
            <S.InputGroup>
              <Heading level={6}>Enter a description (optional)</Heading>
              <Input
                placeholder="Enter description"
                onChange={onOrgDescriptionChange}
                value={orgDescription}
                autoComplete="off"
              />
            </S.InputGroup>
          </S.Section>
          <S.Rule />
          <S.Section>
            <S.InputGroup>
              <div>
                <Heading level={6}>Invite admins</Heading>
                <S.InputExplainer>
                  Admins can manage the organisation, view usage, promote users
                  to administrators, delete users and manage all groups.
                </S.InputExplainer>
              </div>
              <Input
                placeholder="Add emails, comma separated"
                onChange={onAdminsChange}
                value={admins}
                autoComplete="off"
              />
            </S.InputGroup>
            <S.InputGroup>
              <div>
                <Heading level={6}>Invite users</Heading>
                <S.InputExplainer>
                  Users can run and share reports, create groups and invite new
                  users.
                </S.InputExplainer>
              </div>
              <Input
                placeholder="Add emails, comma separated"
                onChange={onUsersChange}
                value={users}
                autoComplete="off"
              />
            </S.InputGroup>
          </S.Section>
          <S.Rule />
          <S.Section>
            <div>
              <Heading level={5}>Single Sign On</Heading>
              <S.InputExplainer>
                Enable Single Sign On (SSO) for this organisation. This will
                allow users to login with their organisation&apos;s
                authentication provider.
              </S.InputExplainer>
            </div>
            <Switch
              checked={enableSso}
              onChange={e => setEnableSso(e.target.checked)}
            />
            {enableSso && (
              <>
                <div>
                  <Heading level={6}>Metadata URL</Heading>
                  <S.InputExplainer>
                    The URL to the identity provider&apos;s SAML metadata file.
                  </S.InputExplainer>
                </div>
                <Input
                  placeholder="https://"
                  autoComplete="off"
                  value={metadataUrl ?? ""}
                  onChange={setMetadataUrl}
                />
                <div>
                  <Heading level={6}>Metadata File</Heading>
                  <S.InputExplainer>
                    The identity provider&apos;s SAML metadata file. This is
                    required if no URL is set above.
                  </S.InputExplainer>
                </div>
                <FileInput onChange={handleMetadataFileChange} />
              </>
            )}
          </S.Section>
        </S.CreateOrganisationInputs>
        <S.Controls>
          <S.Button
            text="Cancel"
            size={ButtonSize.Medium}
            type={ButtonType.Outlined}
            onClick={() => navigate(`${routes.hub}`)}
          />
          <S.Button
            text="Create organisation"
            size={ButtonSize.Medium}
            type={ButtonType.Filled}
            disabled={!requiredFieldsFilled}
            onClick={() =>
              requiredFieldsFilled && setIsCreateOrgModalOpen(true)
            }
          />
        </S.Controls>
      </S.CreateOrganisation>
      <CreateOrgModal
        isOpen={isCreateOrgModalOpen}
        toggleOpen={() => setIsCreateOrgModalOpen(prev => !prev)}
        orgName={orgName}
        orgDescription={orgDescription}
        admins={admins}
        users={users}
        metadataUrl={metadataUrl}
        metadata={metadata}
      />
    </>
  );
};

export default CreateOrganisation;
