import { useContext } from "react";

import ViewerModeContext from "./context";

const useViewerMode = () => {
  const context = useContext(ViewerModeContext);

  if (!context) {
    console.error(
      "useViewerMode must be used within a ViewerModeContextProvider"
    );
    throw new Error(
      "useViewerMode must be used within a ViewerModeContextProvider"
    );
  }

  return context;
};

export default useViewerMode;
