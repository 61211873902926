import React, { useState } from "react";
import {
  FormatBold,
  FormatListBulleted as ULIcon,
  FormatListNumbered as OLIcon,
  FormatQuote as BlockquoteIcon,
  FormatItalic,
  FormatStrikethrough,
  Link,
  ColorLens
} from "@mui/icons-material";
import { useCommands, useActive, useCurrentSelection } from "@remirror/react";

import Popover from "components/atoms/Popover";

import S from "./styles";

// Options for changing text colour.
const COLORS = [
  "#c0392b",
  "#e74c3c",
  "#d35400",
  "#e67e22",
  "#f39c12",
  "#f1c40f",
  "#27ae60",
  "#1abc9c",
  "#2980b9",
  "#3498db",
  "#8e44ad",
  "#ce39ba",
  "#bdc3c7",
  "#95a5a6",
  "#7f8c8d",
  "#474952"
];

const ToolbarMenu = ({
  onLinkOptionClick,
  isLinkOptionActive,
  isEditModeActive
}) => {
  const {
    focus,
    toggleBold,
    toggleItalic,
    toggleStrike,
    toggleOrderedList,
    toggleBulletList,
    toggleBlockquote,
    setTextColor
  } = useCommands();
  const active = useActive();
  const [isColorTooltipOpen, setIsColorTooltipOpen] = useState(false);

  const { empty } = useCurrentSelection();

  return (
    <S.Toolbar role="toolbar">
      <S.ToolbarButton
        onClick={() => {
          focus();
          toggleBold();
        }}
        isActive={isEditModeActive && active.bold()}
      >
        <FormatBold />
      </S.ToolbarButton>
      <S.ToolbarButton
        onClick={() => {
          focus();
          toggleItalic();
        }}
        isActive={isEditModeActive && active.italic()}
      >
        <FormatItalic />
      </S.ToolbarButton>
      <S.ToolbarButton
        onClick={() => {
          focus();
          toggleOrderedList();
        }}
        isActive={isEditModeActive && active.orderedList()}
      >
        <OLIcon />
      </S.ToolbarButton>
      <S.ToolbarButton
        onClick={() => {
          focus();
          toggleBulletList();
        }}
        isActive={isEditModeActive && active.bulletList()}
      >
        <ULIcon />
      </S.ToolbarButton>
      <S.ToolbarButton
        onClick={() => {
          focus();
          toggleBlockquote();
        }}
        isActive={isEditModeActive && active.blockquote()}
      >
        <BlockquoteIcon />
      </S.ToolbarButton>
      <S.ToolbarButton
        onClick={() => {
          focus();
          toggleStrike();
        }}
        isActive={isEditModeActive && active.strike()}
      >
        <FormatStrikethrough />
      </S.ToolbarButton>
      <S.ToolbarButton
        onClick={() => {
          onLinkOptionClick(prev => !prev);
        }}
        isActive={isLinkOptionActive}
        disabled={empty}
      >
        <Link />
      </S.ToolbarButton>
      <Popover
        style={{ display: "inline" }}
        content={
          <S.ColorPicker>
            {COLORS.map(color => (
              <S.ColorOption
                key={color}
                color={color}
                onClick={() => {
                  focus();
                  setTextColor(color);
                  setIsColorTooltipOpen(false);
                }}
              />
            ))}
          </S.ColorPicker>
        }
        isOpenOverride={isColorTooltipOpen}
        alignment="bottom-start"
        hideArrow
        trigger="click"
        interactive
        onRequestClose={() => {
          setIsColorTooltipOpen(false);
        }}
      >
        <S.ToolbarButton
          onClick={() => {
            setIsColorTooltipOpen(prevState => !prevState);
          }}
          isActive={false}
        >
          <ColorLens />
        </S.ToolbarButton>
      </Popover>
    </S.Toolbar>
  );
};

export default ToolbarMenu;
