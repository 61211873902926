import React from "react";
import { TextField } from "@mui/material";

import { SearchResult } from "api/search";

import S, { classNameOverrides } from "./styles";

interface Props {
  query: string;
  onQueryInputChange: (query: string) => void;
  onSelectedSuggestionChange: (selectedSuggestion: SearchResult[]) => void;
  selectedSuggestion: SearchResult[];
  suggestions: SearchResult[];
  loadingText: string;
  placeholder: string;
  disabled?: boolean;
}

const SimpleSearchInput = ({
  query,
  onQueryInputChange,
  onSelectedSuggestionChange,
  selectedSuggestion,
  suggestions,
  loadingText,
  placeholder,
  disabled
}: Props) => {
  return (
    <S.Input
      query={query}
      options={suggestions}
      searchTags={selectedSuggestion}
      onInputChange={onQueryInputChange}
      onChange={onSelectedSuggestionChange}
      onClear={() => {}}
      onSubmit={() => {}}
      renderInput={(placeholderStr, params, inputRef) => {
        return (
          <TextField
            placeholder={placeholderStr}
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: null
            }}
            className={classNameOverrides.textInput}
            type="search"
            inputRef={inputRef}
          />
        );
      }}
      loadingText={loadingText}
      placeholder={
        selectedSuggestion && selectedSuggestion.length
          ? undefined
          : placeholder
      }
      disabled={disabled}
    />
  );
};

export default SimpleSearchInput;
