import { styled } from "@linaria/react";

export interface SectionProps {
  isOpen: boolean;

  // Used to add page breaks when the report is exported.
  isPaginated: boolean;
}

// TODO: make the page breaks only work on paginated PDFs
const Section = styled.div<SectionProps>`
  page-break-inside: ${({ isPaginated }) => (isPaginated ? "avoid" : "auto")};
  page-break-before: ${({ isPaginated }) => (isPaginated ? "always" : "auto")};

  @media print {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  }
`;

const S = { Section };

export default S;
