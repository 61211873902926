import { InsightReport } from "api/insight-reports";
import type { InsightReportState } from "util/hooks/useInsightReport/types";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";

export const isShowingInsightsReport = (
  state: InsightReportState,
  report: InsightReport | undefined
) => {
  const { status } = state;
  const isShowingReport =
    !!report &&
    (status === InsightReportStatus.addingNewSection ||
      status === InsightReportStatus.editingTitle ||
      status === InsightReportStatus.errorAddingNewSection ||
      status === InsightReportStatus.errorAddingToSection ||
      status === InsightReportStatus.errorFetchingSources ||
      status === InsightReportStatus.errorRemovingReportSection ||
      status === InsightReportStatus.fetchingSources ||
      status === InsightReportStatus.idle ||
      status === InsightReportStatus.movingSubSection ||
      status === InsightReportStatus.movingSubsectionSuccess ||
      status === InsightReportStatus.removingReportElement ||
      status === InsightReportStatus.removingReportSection ||
      status === InsightReportStatus.removingReportSubSection ||
      status === InsightReportStatus.removingReportSectionSuccess ||
      status === InsightReportStatus.reorderingSubSections ||
      status === InsightReportStatus.reorderingSubSectionsSuccess ||
      status === InsightReportStatus.savingTitle);

  return isShowingReport;
};
