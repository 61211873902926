import { useMemo } from "react";

import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import ReportsApi from "api/reports";
import {
  CollectionListActions,
  useCollectionList
} from "util/hooks/useCollectionList";

const useDeleteReport = () => {
  const api = useMemo(() => new ReportsApi(), []);
  const [{ fetching, error, errorMessage, success }, dispatch] =
    useFetchReducer();
  const { dispatch: collectionsDispatch } = useCollectionList();

  const deleteReport = async (reportId: string): Promise<boolean> => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await api.deleteReport(reportId);

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return false;
    }

    // Dispatch useCollection event to update report list
    collectionsDispatch({
      type: CollectionListActions.deleteCollectionItem,
      itemId: reportId
    });

    dispatch({ type: RequestActions.SetSuccess });
    return true;
  };

  const reset = () => {
    setTimeout(() => {
      dispatch({ type: RequestActions.Reset });
    }, 1000);
  };

  const resetError = () => {
    dispatch({ type: RequestActions.ResetError });
  };

  return {
    fetching,
    error,
    success,
    errorMessage,
    resetError,
    reset,
    deleteReport
  };
};

export default useDeleteReport;
