import React, { FC, ReactNode, SVGProps, useState } from "react";
import { ChevronDown, Edit, Eye } from "react-feather";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";
import Heading from "components/atoms/Heading";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Popover from "components/atoms/Popover";
import { ReportSharePermission } from "api/reports/types";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import useShareReport from "util/hooks/useShareReport";
import ErrorBanner from "components/atoms/ErrorBanner";

import S from "./styles";

interface Props {
  children: ReactNode;
  isOpen: boolean;
  toggleOpen: () => void;
  reportId: string;
  recipientUserId: string;
}

const dropdownOptions = [
  { id: ReportSharePermission.Write, label: "Can edit", icon: Eye },
  { id: ReportSharePermission.Read, label: "Can view", icon: Edit }
];

const TransferOwnerModal = ({
  children,
  isOpen,
  toggleOpen,
  reportId,
  recipientUserId
}: Props) => {
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    dropdownOptions[0]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [{ fetching, error, errorMessage }, dispatch] = useFetchReducer();

  const { transferOwnership } = useShareReport();

  const onToggleOpen = () => {
    dispatch({ type: RequestActions.Reset });
    toggleOpen();
  };

  const onTransferOwnership = async () => {
    dispatch({ type: RequestActions.SendRequest });

    const { status: transferStatus, message: transferMessage } =
      await transferOwnership(
        reportId,
        recipientUserId,
        selectedDropdownOption.id as
          | ReportSharePermission.Write
          | ReportSharePermission.Read
      );

    if (transferStatus) {
      dispatch({ type: RequestActions.SetSuccess });
      onToggleOpen();
      return;
    }

    dispatch({
      type: RequestActions.SetError,
      errorMessage: transferMessage
    });
  };

  const renderDropdown = () => {
    return (
      <Menu>
        {dropdownOptions.map(option => (
          <MenuItem
            key={option.id}
            text={option.label}
            onMenuItemClick={() => {
              setSelectedDropdownOption(option);
              setIsDropdownOpen(false);
            }}
            disabled={option.id === selectedDropdownOption.id}
            IconLeading={option.icon}
          />
        ))}
      </Menu>
    );
  };

  return (
    <ModalContainer
      title="Transfer ownership?"
      isOpen={isOpen}
      toggleOpen={onToggleOpen}
      onExitClick={onToggleOpen}
    >
      <Heading level={6}>Transfer ownership of the report</Heading>
      {children}
      <S.TransferControl>
        <Heading level={6}>Choose what level of access you retain</Heading>
        <Popover
          // @ts-ignore
          isOpenOverride={isDropdownOpen}
          // @ts-ignore
          onRequestClose={() => setIsDropdownOpen(false)}
          content={renderDropdown()}
          alignment="bottom-start"
          disableHideOnClip={undefined}
          className={undefined}
          style={{ alignSelf: "start" }}
          hideArrow
        >
          <ButtonNew
            size={ButtonSize.Small}
            type={ButtonType.Outlined}
            text={selectedDropdownOption.label}
            IconTrailing={ChevronDown}
            onClick={() => setIsDropdownOpen(prev => !prev)}
          />
        </Popover>
        <S.Explainer>The new owner will be notified by email.</S.Explainer>
      </S.TransferControl>
      <S.ModalControls>
        <S.Button
          text="Cancel"
          size={ButtonSize.Medium}
          type={ButtonType.Outlined}
          onClick={onToggleOpen}
        />
        <S.Button
          text="Transfer ownership"
          size={ButtonSize.Medium}
          type={ButtonType.Filled}
          onClick={onTransferOwnership}
          disabled={fetching}
          IconTrailing={
            fetching
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
        />
      </S.ModalControls>
      {error && (
        <ErrorBanner
          text={
            errorMessage ||
            "There was an error transfering ownership. Try again in a moment."
          }
          onClick={() => dispatch({ type: RequestActions.Reset })}
        />
      )}
    </ModalContainer>
  );
};

export default TransferOwnerModal;
