export interface Group {
  groupId: string;
  name: string;
  role: string;
}

export interface UserDetails {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  groups: Group[];
  organisationId: string;
  organisationRole: string;
}

export enum AutoSharePermission {
  Read = "Read",
  Write = "Write",
  Delete = "Delete"
}

export interface SharedWithUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  permission: AutoSharePermission;
}

export interface SharedWithGroup {
  groupId: string;
  name: string;
  permission: AutoSharePermission;
}

export type SharedWithItem = SharedWithGroup | SharedWithUser;

export interface UserSharingPreferencesConfig {
  autoshare: boolean;
  sharedWithUsers: SharedWithUser[];
  sharedWithGroups: SharedWithGroup[];
}

export interface UserPreferencesConfig {
  sharingPreferences: UserSharingPreferencesConfig;
}

export interface UserPermissions {
  canInviteUsers: boolean;
  canInviteAdmins: boolean;
  canChangeUserRole: boolean;
  canGetOrganisationUsage: boolean;
  canEditMfa: boolean;
  canExportOrganisationUsage: boolean;
  canGetOrganisation: boolean;
  canUpdateOrganisation: boolean;
  canGetOrganisationPreferences: boolean;
  canUpdateOrganisationPreferences: boolean;
  canListUsers: boolean;
  canListGroups: boolean;
  canCreateGroup: boolean;
  canListReports: boolean;
  canStartReports: boolean;
  canResetPassword: boolean;
  canStartLegalEntityReports: boolean;
}

export enum UserPermissionsResultType {
  Success = "success",
  Failed = "failed"
}
export interface UserPermissionsFailed {
  type: UserPermissionsResultType.Failed;
  message?: string;
}
export interface UserPermissionsSuccess {
  type: UserPermissionsResultType.Success;
  permissions: UserPermissions;
}
export type UserPermissionsResult =
  | UserPermissionsSuccess
  | UserPermissionsFailed;
