import React, { ReactElement } from "react";

import { SectionIcon as SectionIconType } from "api/insights";

import Icon from "components/organisms/InsightReport/ReportContent/icons";

const SectionIcon = ({
  iconType
}: {
  iconType: SectionIconType | string | undefined;
}) => {
  const sectionIcons: Record<string | SectionIconType, ReactElement> = {
    [SectionIconType.FOLDER]: <Icon.Biographical />,
    [SectionIconType.BRIEFCASE]: <Icon.BusinessInterests />,
    [SectionIconType.AWARD]: <Icon.CharacterAchievements />,
    [SectionIconType.LEGAL]: <Icon.CrimeControversy />,
    [SectionIconType.ENVIRONMENT]: <Icon.ESG />,
    [SectionIconType.FRIENDS]: <Icon.FamilyFriendsAssociates />,
    [SectionIconType.GLOBAL]: <Icon.GlobalReach />,
    [SectionIconType.FILE]: <Icon.File />,
    [SectionIconType.NOMINEE]: <Icon.PoliticalExposure />,
    [SectionIconType.DISABLED]: <Icon.SanctionsExposure />,
    [SectionIconType.DATABASE]: <Icon.Wealth />,
    [SectionIconType.PHILANTHROPY]: <Icon.Philanthropy />,
    [SectionIconType.FINANCIAL_CRIME]: <Icon.FinancialCrime />,
    executive_summary: <Icon.ExecutiveSummary />,
    profile: <Icon.ExecutiveSummary />,
    timeline: <Icon.Timeline />,

    // These are deprecated/we don't have a section icon type for them, but there are likely old reports that need these, so leaving here for now.
    "business interests": <Icon.BusinessInterests />,
    controversy: <Icon.Controversy />,
    finances: <Icon.Wealth />,
    "global reach": <Icon.GlobalReach />,
    "key details": <Icon.KeyDetails />,
    "key people": <Icon.KeyPeople />,
    "legal regulatory and compliance": <Icon.LegalRegulatoryCompliance />,
    "legal, regulatory, and compliance": <Icon.LegalRegulatoryCompliance />,
    ownership: <Icon.Ownership />,
    "ownership and group structure": <Icon.Ownership />
  };

  if (!iconType) return <Icon.Unknown />;

  const icon = sectionIcons[iconType];

  return icon ?? <Icon.Unknown />;
};

export default SectionIcon;
