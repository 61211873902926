import { SortFilterPill } from "./SortFilterPill";
import React, { memo, useState } from "react";
import SortFilterCategories from "../../../../components/molecules/SortFilterCategories";
import { DropdownItem } from "reactstrap";

export const EntitySortFilterPill = memo(props => {
  const { entities, entitiesToUse, setEntitiesToUse, entityPlural, title } =
    props;
  const reset = () => setEntitiesToUse(new Set());
  const [loadEager, setLoadEager] = useState(false);
  const onOpenChanged = v => setLoadEager(v);
  const canReset = entitiesToUse.size > 0;
  const active = entitiesToUse.size > 0;
  return (
    <SortFilterPill
      title={title || entityPlural}
      {...{ canReset, reset, active, onOpenChanged }}
    >
      <div className="sort-filter-dropdown-menu-header">
        <div>In this report:</div>{" "}
        <div>
          {
            [...(entities.keys() || [])].filter(
              e => e !== null && e !== undefined
            ).length
          }{" "}
          {entityPlural}
        </div>
      </div>
      <DropdownItem divider />
      <SortFilterCategories
        {...{
          categories: entities,
          categoriesToUse: entitiesToUse,
          setCategoriesToUse: setEntitiesToUse,
          loadEager
        }}
      />
    </SortFilterPill>
  );
});
