import { css } from "@linaria/core";
import theme from "theme";
import { standardColors } from "../colors";
import { family, fontSize } from "../fonts";

export const globals = css`
  :global() {
    button {
      cursor: pointer;
      white-space: nowrap;
      border-radius: 0;
    }

    body {
      margin: 0;
      padding: 0;
      font-size: ${fontSize.md};
      font-weight: 400;
      line-height: 1.5;

      // Given that at the time of writing, Xapien is a predominantly
      // dark UI with light text, except for the main report, it makes sense
      // at the time of writing to set the default text colour to white and
      // override it more selectively down the line on a high level.
      background: ${theme.backgroundColor};
      color: ${standardColors.white};
    }

    input {
      color: ${standardColors.white};

      &::placeholder {
        color: rgba(255, 255, 255, 0.85);
      }
    }

    b,
    strong {
      font-family: ${family.interSemiBold};
      font-weight: 600;
    }

    /* START Global styles that used to come from Bootstrap before its removal */
    /* Do not modify these styles directly here. If you wish to change any of them,
     * please excise the declaration from the below block so that it's clear
     * what is our code and what is Bootstrap code.
     */

    /*!
     * Bootstrap v4.1.1 (https://getbootstrap.com/)
     * Copyright 2011-2018 The Bootstrap Authors
     * Copyright 2011-2018 Twitter, Inc.
     * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
     */

    /*
    DON'T REMOVE (unless you find the cause for this odd behaviour of course :) )
    But for some odd reason, removing this will cause the PDF export width to become narrow.
    */
    :root {
      --info: "";
    }

    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    html {
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      -ms-overflow-style: scrollbar;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    [tabindex="-1"]:focus {
      outline: 0 !important;
    }

    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
      margin-left: 0px;
      margin-right: 0px;
    }

    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    ol,
    ul,
    dl {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0;
    }

    dt {
      font-family: ${family.interSemiBold};
    }

    dd {
      margin-bottom: 0.5rem;
      margin-left: 0;
    }

    blockquote {
      margin: 0 0 1rem;
    }

    dfn {
      font-style: italic;
    }

    small {
      color: #848895;
    }

    sub,
    sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }

    sub {
      bottom: -0.25em;
    }

    sup {
      top: -0.5em;
    }

    a:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: none;
    }

    a:not([href]):not([tabindex]):hover,
    a:not([href]):not([tabindex]):focus {
      color: inherit;
      text-decoration: none;
    }

    a:not([href]):not([tabindex]):focus {
      outline: 0;
    }

    figure {
      margin: 0 0 1rem;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
      border-style: none;
    }

    svg:not(:root) {
      overflow: hidden;
    }

    table {
      border-collapse: collapse;
    }

    caption {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      color: #6c757d;
      text-align: left;
      caption-side: bottom;
    }

    th {
      text-align: inherit;
    }

    label {
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    button:focus {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    button,
    input {
      overflow: visible;
    }

    button,
    select {
      text-transform: none;
    }

    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button;
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }

    input[type="radio"],
    input[type="checkbox"] {
      box-sizing: border-box;
      padding: 10;
    }

    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
      -webkit-appearance: listbox;
    }

    textarea {
      overflow: auto;
      resize: vertical;
    }

    [hidden] {
      display: none !important;
    }

    hr {
      border: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    small,
    .small {
      font-size: 95%;
    }

    svg {
      vertical-align: middle;
    }

    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    /* END Global styles that used to come from Bootstrap before its removal */
  }
`;
