import { styled } from "@linaria/react";

import CommonButton from "components/atoms/Button";
import Popover from "components/atoms/Popover";

import { standardColors, grey, purple } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const DropdownHeader = styled.div`
  height: 32px;
  background-color: ${purple.darker};
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  color: ${standardColors.white};
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-bottom: none;
  font-size: ${fontSize.md};
  font-family: ${family.interSemiBold};
`;

const DropdownFooter = styled.div`
  border-top: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 8px 16px 16px 16px;
`;

const FooterInfoText = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  padding-bottom: 2px;
`;

const DropdownBody = styled.div`
  padding: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  white-space: normal;
`;

const ConfirmButton = styled(CommonButton)<{ color: string }>`
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color} !important;
  font-size: ${fontSize.sm};
  min-height: 28px !important;
  background-color: ${purple.darker} !important;
  border: 0 !important;

  &:hover,
  &:focus {
    border: 0 !important;
    box-shadow: none !important;
  }
`;

const CancelButton = styled(CommonButton)<{
  color?: string;
}>`
  color: ${props => props.color ?? grey.dark} !important;
  min-height: 28px !important;
  font-size: ${fontSize.sm};
  background: ${grey.panel} !important;
  margin-right: 8px;

  &:hover,
  &:focus {
    box-shadow: none !important;
  }
`;

const DropdownContainer = styled.div`
  width: 426px;
`;

const DropdownSubtext = styled.div`
  background-color: ${purple.lighter};
  padding: 12px 14px;
  color: ${grey.dark};
  font-size: ${fontSize.sm};
`;

const Tooltip = styled(Popover)`
  display: inline-block;
  z-index: 100;
`;

const TooltipContent = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  padding: 14px;
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const S = {
  ActionsContainer,
  DropdownHeader,
  DropdownFooter,
  FooterInfoText,
  DropdownBody,
  ConfirmButton,
  CancelButton,
  DropdownContainer,
  DropdownSubtext,
  Tooltip,
  TooltipContent
};

export default S;
