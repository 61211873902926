import React, { useState, useRef, useEffect } from "react";

import Tab from "components/atoms/Tab";

import theme from "theme";

import S from "./styles";

const MAX_TABS_ON_DISPLAY = 4;

const TabBar = ({ tabs, onTabDelete, activeTab, setActiveTab }) => {
  const [leftScrollActive, setLeftScrollActive] = useState();
  const [rightScrollActive, setRightScrollActive] = useState();

  const tabBarRef = useRef();

  useEffect(() => {
    if (tabs.length > MAX_TABS_ON_DISPLAY) {
      setRightScrollActive(true);
    }
  }, [tabs.length]);

  const scrollLeft = () => {
    if (tabBarRef.current) {
      tabBarRef.current.scrollLeft -= 230;
    }
  };

  const scrollRight = () => {
    if (tabBarRef.current) {
      tabBarRef.current.scrollLeft += 230;
    }
  };

  const onScroll = e => {
    // Disable left scroll button when we have reached the furthest left
    if (e.target.scrollLeft === 0) {
      setLeftScrollActive(false);
      setRightScrollActive(true);
    } else if (
      // Disable the right scroll when we have reached the furthest right
      e.target.scrollLeft + e.target.offsetWidth >=
      e.target.scrollWidth
    ) {
      setRightScrollActive(false);
      setLeftScrollActive(true);
    } else {
      // We are not at either end of the scroll, so allow scroll to either end
      setRightScrollActive(true);
      setLeftScrollActive(true);
    }
  };

  return (
    <S.TabBar backgroundColor={theme.primaryColor}>
      {tabs.length >= MAX_TABS_ON_DISPLAY && (
        <S.LeftChevron
          onClick={() => scrollLeft()}
          isActive={leftScrollActive}
        />
      )}
      {tabs.length > 1 && (
        <S.Tabs ref={tabBarRef} onScroll={onScroll}>
          {tabs.map((tab, index) => (
            <S.TabContainer>
              <S.Divider
                shouldDisplay={
                  index !== 0 &&
                  activeTab !== tab.id &&
                  !(tabs.findIndex(tab => tab.id === activeTab) === index - 1) // Previous tab is not selected
                }
                backgroundColor={theme.primaryColor}
              />
              <Tab
                tabTitle={tab.tabTitle}
                isSelected={activeTab === tab.id}
                onTabSelect={() => setActiveTab(tab.id)}
                onTabRemove={e => {
                  e.stopPropagation();
                  onTabDelete(tab);
                }}
                isInitialTab={index === 0}
                isLastTab={index === tabs.length - 1}
                tabIcon={
                  <S.BuildingIcon
                    isSelected={activeTab === tab.id}
                    fill={theme.primaryColor}
                  />
                }
              />
            </S.TabContainer>
          ))}
        </S.Tabs>
      )}
      {tabs.length >= MAX_TABS_ON_DISPLAY && (
        <S.RightChevron
          onClick={() => scrollRight()}
          isActive={rightScrollActive}
        />
      )}
    </S.TabBar>
  );
};

export default TabBar;
