import React, { Fragment, useState, useMemo, useRef, useEffect } from "react";

import ProfessionalInformation from "components/organisms/ProfessionalOverview/ProfessionalInformation";
import StackedBar from "components/molecules/StackedBar";
import ConfidenceDropDownMenu from "components/molecules/ConfidenceDropDownMenu";
import ShowResultsButton from "pages/report/ShowResultsButton";
import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import {
  CONFIDENCE_CATEGORIES,
  DOWJONES_CONVERSION
} from "util/confidenceCategories";
import DropdownButton from "components/molecules/DropdownButton";

import { blue, grey } from "styles/colors";

import { isPDX } from "static-config";
import FilterBars from "../FilterBars";
import S from "./styles";

const FILTER_IDS = {
  risk: "risk",
  ubo: "ubo",
  psc: "psc",
  director: "director",
  currentRoles: "current-roles",
  pscNotApplicable: "psc-not-applicable",
  uboNotApplicable: "ubo-not-applicable",
  formerRoles: "former-roles",
  noRisk: "no-risk",
  resigned: "resigned",
  roleFilters: "role-filters"
};

const FILTER_SETTINGS = {
  [FILTER_IDS.risk]: {
    label: "Risk identified",
    color: "#F01919"
  },
  [FILTER_IDS.ubo]: {
    label: "Beneficial owner",
    color: "#9E5E22"
  },
  [FILTER_IDS.psc]: {
    label: "Person of significant control",
    color: "#005572"
  },
  [FILTER_IDS.director]: {
    label: "Current",
    color: "#338C50"
  },
  [FILTER_IDS.currentRoles]: {
    label: "Current roles",
    color: "#338C50"
  },
  [FILTER_IDS.pscNotApplicable]: {
    label: "Not applicable",
    color: grey.mid
  },
  [FILTER_IDS.uboNotApplicable]: {
    label: "Not applicable",
    color: grey.mid
  },
  [FILTER_IDS.formerRoles]: {
    label: "Former roles",
    color: grey.mid
  },
  [FILTER_IDS.noRisk]: {
    label: "No risk identified",
    color: grey.mid
  },
  [FILTER_IDS.resigned]: {
    label: "Resigned",
    color: grey.mid
  },
  [FILTER_IDS.roleFilters]: {
    // Special case given we don't know the role titles ahead of time.
    // Label is derived later on in the render cycle.
    color: blue.mid
  }
};

const SPECIAL_ROLES = {
  ubo: "ultimate beneficial owner",
  psc: "person of significant control"
};

const sanitiseRoleString = (roleString = "") => {
  return roleString.toLowerCase().trim();
};

const ResultsWithFilters = ({
  orgsWithDirectorshipRoles,
  listOfProfessionalInformation,
  employmentHistory,
  isReportRegenerationOpen,
  subjectName,
  selectedConfidenceFilter,
  onConfidenceFilterSelected,
  onConfidenceChange,
  confidenceBucketCounts
}) => {
  const [activeFilterId, setActiveFilterId] = usePrintableReportState(
    "employment-overview-active-filter-id",
    undefined
  );
  const [isShowingAllRoles, setIsShowingAllRoles] = useState(false);
  const [isDirectorshipsResultsShown, setIsDirectorshipsResultsShown] =
    usePrintableReportState(
      "employment-overview-results-shown",
      false,
      PRINTABLE_STATE_TYPES.sectionExpand
    );
  const [hasFilterBeenActive, setHasFilterBeenActive] = useState(false);

  const getRoleTitleId = roleTitleLabel => {
    return `role-title-${roleTitleLabel}`;
  };

  const onFilterChanged = (barId, filterFn) => {
    setIsDirectorshipsResultsShown(true);
    setHasFilterBeenActive(true);
    return filterFn(barId);
  };

  const onFilterClick = bar => {
    // If we've selected the current filter again, then deselect it
    if (bar.id === activeFilterId) {
      setActiveFilterId(undefined);
    } else {
      setActiveFilterId(bar.id);
    }
  };

  const getOrgsWithOngoingDirectorshipRoles = () => {
    return orgsWithDirectorshipRoles.filter(role =>
      role.listOfRoles.some(r => r.isOfficership && r.isOngoing)
    );
  };

  const getOrgsWithNoOngoingDirectorshipRoles = () => {
    return orgsWithDirectorshipRoles.filter(role =>
      role.listOfRoles.every(r => !r.isOfficership || !r.isOngoing)
    );
  };

  const getDirectorshipBars = () => {
    return [
      {
        id: FILTER_IDS.director,
        count: getOrgsWithOngoingDirectorshipRoles().length,
        opacity: 0.6,
        ...FILTER_SETTINGS.director
      },
      {
        id: FILTER_IDS.resigned,
        count: getOrgsWithNoOngoingDirectorshipRoles().length,
        opacity: 0.6,
        ...FILTER_SETTINGS.resigned
      }
    ];
  };

  const getEmploymentHistoryBars = () => {
    return [
      {
        id: FILTER_IDS.currentRoles,
        count: listOfProfessionalInformation.reduce((acc, professionalInfo) => {
          const activeRolesCount = professionalInfo.listOfRoles.filter(
            role => !role.endDate
          ).length;

          return acc + activeRolesCount;
        }, 0),
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.currentRoles]
      },
      {
        id: FILTER_IDS.formerRoles,
        count: listOfProfessionalInformation.reduce((acc, professionalInfo) => {
          const formerRolesCount = professionalInfo.listOfRoles.filter(
            role => role.endDate
          ).length;

          return acc + formerRolesCount;
        }, 0),
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.formerRoles]
      }
    ];
  };

  const getRiskBars = () => {
    return [
      {
        id: FILTER_IDS.risk,
        count: listOfProfessionalInformation.filter(
          role =>
            (role.listOfOrgWarnings && role.listOfOrgWarnings.length > 0) ||
            (role.orgStatus &&
              role.orgStatus.statusTag?.toUpperCase() === "WARNING") ||
            (role.countryInfo &&
              role.countryInfo.riskFlags &&
              role.countryInfo.riskFlags.length > 0) ||
            (role.insolvencyFilings && role.insolvencyFilings.length)
        ).length,
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.risk]
      },
      {
        id: FILTER_IDS.noRisk,
        count: listOfProfessionalInformation.filter(
          role =>
            !role.listOfOrgWarnings.length &&
            (!role.orgStatus ||
              role.orgStatus.statusTag?.toUpperCase() !== "WARNING") &&
            (!role.countryInfo ||
              !role.countryInfo.riskFlags ||
              !role.countryInfo.riskFlags.length) &&
            (!role.insolvencyFilings || !role.insolvencyFilings.length)
        ).length,
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.noRisk]
      }
    ];
  };

  const getUboBars = () => {
    return [
      {
        id: FILTER_IDS.ubo,
        count: listOfProfessionalInformation.filter(directorRole =>
          directorRole.listOfRoles.some(
            role => sanitiseRoleString(role.roleTitle) === SPECIAL_ROLES.ubo
          )
        ).length,
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.ubo]
      },
      {
        id: FILTER_IDS.uboNotApplicable,
        count: listOfProfessionalInformation.filter(directorRole =>
          directorRole.listOfRoles.every(
            role => sanitiseRoleString(role.roleTitle) !== SPECIAL_ROLES.ubo
          )
        ).length,
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.uboNotApplicable]
      }
    ];
  };

  const getPscBars = () => {
    return [
      {
        id: FILTER_IDS.psc,
        count: listOfProfessionalInformation.filter(directorRole =>
          directorRole.listOfRoles.some(
            role => sanitiseRoleString(role.roleTitle) === SPECIAL_ROLES.psc
          )
        ).length,
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.psc]
      },
      {
        id: FILTER_IDS.pscNotApplicable,
        count: listOfProfessionalInformation.filter(directorRole =>
          directorRole.listOfRoles.every(
            role => sanitiseRoleString(role.roleTitle) !== SPECIAL_ROLES.psc
          )
        ).length,
        opacity: 0.6,
        ...FILTER_SETTINGS[FILTER_IDS.pscNotApplicable]
      }
    ];
  };

  const getRolesBucketedByTitle = () =>
    listOfProfessionalInformation.reduce((acc, profInfo) => {
      profInfo.listOfRoles.forEach(role => {
        const roleString = sanitiseRoleString(role.roleTitle);
        if (
          role.roleTitle &&
          roleString !== SPECIAL_ROLES.psc &&
          roleString !== SPECIAL_ROLES.ubo
        ) {
          const roleTitleId = getRoleTitleId(role.roleTitle);
          if (acc[roleTitleId]) {
            acc[roleTitleId].count += 1;
          } else {
            acc[roleTitleId] = {
              count: 1,
              label: role.roleTitle
            };
          }
        }
      });

      return acc;
    }, {});

  const [directorshipBars, setDirectorshipBars] = useState([]);
  const [employmentHistoryBars, setEmploymentHistoryBars] = useState([]);
  const [riskBars, setRiskBars] = useState([]);
  const [uboBars, setUboBars] = useState([]);
  const [pscBars, setPscBars] = useState([]);
  const [rolesCountsBucketedByTitle, setRolesCountsBucketedByTitle] = useState(
    {}
  );

  useEffect(() => {
    setRolesCountsBucketedByTitle(getRolesBucketedByTitle());
    setPscBars(getPscBars());
    setUboBars(getUboBars());
    setRiskBars(getRiskBars());
    setEmploymentHistoryBars(getEmploymentHistoryBars());
    setDirectorshipBars(getDirectorshipBars());
    // TODO DJ REMOVE BELOW
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfProfessionalInformation]);

  const [isResultsExpanded, setIsResultsExpanded] = usePrintableReportState(
    "employment-overview-expanded-fully",
    false,
    PRINTABLE_STATE_TYPES.sectionExpand
  );

  const resultsSectionRef = useRef(null);
  const employmentOverviewRef = useRef(null);

  const onStatusBarClick = barId => {
    if (barId === FILTER_IDS.director) {
      return getOrgsWithOngoingDirectorshipRoles();
    }
    return getOrgsWithNoOngoingDirectorshipRoles();
  };

  const onRiskBarClick = barId => {
    const hasOrgWarnings = role =>
      role.listOfOrgWarnings && role.listOfOrgWarnings.length > 0;
    const hasWarningStatus = role =>
      role.orgStatus && role.orgStatus.statusTag?.toUpperCase() === "WARNING";
    const hasLocationRisk = role =>
      role.countryInfo &&
      role.countryInfo.riskFlags &&
      role.countryInfo.riskFlags.length > 0;
    const hasInsolvency = role =>
      role.insolvencyFilings && role.insolvencyFilings.length;

    let filteredData;

    if (barId === FILTER_IDS.risk) {
      filteredData = listOfProfessionalInformation.filter(
        role =>
          hasOrgWarnings(role) ||
          hasWarningStatus(role) ||
          hasLocationRisk(role) ||
          hasInsolvency(role)
      );
    } else {
      filteredData = listOfProfessionalInformation.filter(
        role =>
          !hasOrgWarnings(role) &&
          !hasWarningStatus(role) &&
          !hasLocationRisk(role) &&
          !hasInsolvency(role)
      );
    }
    return filteredData;
  };

  const onUboBarClick = barId => {
    if (barId === FILTER_IDS.ubo) {
      return listOfProfessionalInformation.filter(role =>
        role.listOfRoles.some(
          r => sanitiseRoleString(r.roleTitle) === SPECIAL_ROLES.ubo
        )
      );
    }
    return listOfProfessionalInformation.filter(role =>
      role.listOfRoles.every(
        r => sanitiseRoleString(r.roleTitle) !== SPECIAL_ROLES.ubo
      )
    );
  };

  const onPscBarClick = barId => {
    if (barId === FILTER_IDS.psc) {
      return listOfProfessionalInformation.filter(role =>
        role.listOfRoles.some(
          r => sanitiseRoleString(r.roleTitle) === SPECIAL_ROLES.psc
        )
      );
    }
    return listOfProfessionalInformation.filter(role =>
      role.listOfRoles.every(
        r => sanitiseRoleString(r.roleTitle) !== SPECIAL_ROLES.psc
      )
    );
  };

  const onCurrentRolesBarClick = barId => {
    if (barId === FILTER_IDS.currentRoles) {
      return listOfProfessionalInformation.filter(professionalInfo => {
        return professionalInfo.listOfRoles.some(role => !role.endDate);
      });
    }
    return listOfProfessionalInformation.filter(professionalInfo => {
      return professionalInfo.listOfRoles.some(role => role.endDate);
    });
  };

  const onRoleBarClick = () => {
    setIsDirectorshipsResultsShown(true);
    return listOfProfessionalInformation.filter(professionalInfo => {
      return professionalInfo.listOfRoles.some(
        role => getRoleTitleId(role.roleTitle) === activeFilterId
      );
    });
  };

  const onToggleExpandResultsSection = () => {
    // usePrintableStateHook doesn't accept state setter functions
    if (isResultsExpanded) {
      setIsResultsExpanded(false);
    } else {
      setIsResultsExpanded(true);
    }
    if (isResultsExpanded) {
      // Then we must be collapsing the results so ensure
      // the results section remains in view.

      const rect = resultsSectionRef.current.getBoundingClientRect();

      // If results section's top is now hidden i.e. above the viewport then:
      if (rect.top <= 0) {
        // Bring the section into view
        employmentOverviewRef.current.scrollIntoView();
      }
    }
  };

  /**
   * In order to ensure the results list end up in the exported PDF we need
   * to calculate the results on each render AND ONLY when the filter has changed.
   *
   * This way, on initial load of component, the stored filter ID in the PDF export
   * state store can be used to filter the results and produce the appropriate list
   * of results in the exported PDF.
   */
  const filteredRoleData = useMemo(
    () => {
      if (
        activeFilterId === FILTER_IDS.risk ||
        activeFilterId === FILTER_IDS.noRisk
      ) {
        return onFilterChanged(activeFilterId, onRiskBarClick);
      }
      if (
        activeFilterId === FILTER_IDS.resigned ||
        activeFilterId === FILTER_IDS.director
      ) {
        return onFilterChanged(activeFilterId, onStatusBarClick);
      }
      if (
        activeFilterId === FILTER_IDS.ubo ||
        activeFilterId === FILTER_IDS.uboNotApplicable
      ) {
        return onFilterChanged(activeFilterId, onUboBarClick);
      }
      if (
        activeFilterId === FILTER_IDS.psc ||
        activeFilterId === FILTER_IDS.pscNotApplicable
      ) {
        return onFilterChanged(activeFilterId, onPscBarClick);
      }
      if (
        activeFilterId === FILTER_IDS.currentRoles ||
        activeFilterId === FILTER_IDS.formerRoles
      ) {
        return onFilterChanged(activeFilterId, onCurrentRolesBarClick);
      }
      if (rolesCountsBucketedByTitle[activeFilterId]) {
        return onRoleBarClick(activeFilterId);
      }
      return listOfProfessionalInformation;
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [activeFilterId, listOfProfessionalInformation]
  );

  const isStackedBarNotSelected = (barId, barsCollection) => {
    return (
      activeFilterId &&
      barsCollection.findIndex(
        bar => bar.id === activeFilterId || barId === activeFilterId
      ) < 0
    );
  };

  const getRoles = (roles, rolesExceedLimit) => {
    let truncatedRoles = Object.keys(roles).sort((a, b) => {
      if (roles[a]?.count > roles[b]?.count) {
        return -1;
      }

      return 1;
    });
    if (rolesExceedLimit && !isShowingAllRoles) {
      truncatedRoles = truncatedRoles.slice(0, 5);
    }

    return truncatedRoles.map(roleTitleId => {
      return {
        id: roleTitleId,
        count: roles[roleTitleId]?.count,
        label: roles[roleTitleId]?.label,
        isChecked: roleTitleId === activeFilterId
      };
    });
  };

  const renderTenure = tenure => {
    return (
      <span>
        {tenure ? (
          <>
            {tenure.years ? `${tenure.years}yrs` : false}
            {tenure.months
              ? ` ${tenure.months}mos`
              : tenure.years
              ? false
              : "<1"}
          </>
        ) : !employmentHistory.totalJobs ? (
          "n/a"
        ) : (
          ""
        )}
      </span>
    );
  };

  const renderEmploymentHistory = () => {
    const totalNumberOfRoles = listOfProfessionalInformation.reduce(
      (acc, professionalInfo) => {
        const activeRolesCount = professionalInfo.listOfRoles.length;

        return acc + activeRolesCount;
      },
      0
    );

    const totalRoleTitles = Object.keys(rolesCountsBucketedByTitle).length;
    const rolesExceedLimit = totalRoleTitles > 5;
    const highestRoleCount = Math.max(
      ...Object.values(rolesCountsBucketedByTitle).map(
        roleTitleData => roleTitleData.count
      )
    );
    const filterBarsData = getRoles(
      rolesCountsBucketedByTitle,
      rolesExceedLimit
    );

    return (
      <>
        <S.SubSectionHeader>
          <div>
            <S.SubSectionTitle>All roles</S.SubSectionTitle>
            {totalNumberOfRoles ? (
              <S.MetaInformation>
                <span>
                  <span>
                    {" "}
                    {employmentHistory.startDate &&
                      employmentHistory.startDate.year}
                  </span>
                  <span>{employmentHistory.startDate && " - "}</span>
                  <span>
                    {employmentHistory.endDate
                      ? employmentHistory.endDate.year
                      : "Present"}
                  </span>
                </span>
                <S.AverageTenureLabel>
                  (Avg. tenure: {renderTenure(employmentHistory.averageTenure)})
                </S.AverageTenureLabel>
              </S.MetaInformation>
            ) : null}
          </div>
        </S.SubSectionHeader>
        {totalNumberOfRoles ? (
          <>
            <S.CurrentRolesBarContainer>
              <StackedBar
                total={totalNumberOfRoles}
                bars={employmentHistoryBars}
                showZeroCountBars
                showTotal={false}
                onBarSectionClick={onFilterClick}
                selectedBarId={activeFilterId}
                isBarNotSelected={isStackedBarNotSelected(
                  FILTER_IDS.formerRoles,
                  employmentHistoryBars
                )}
                rightAlignLastPill
              />
            </S.CurrentRolesBarContainer>
            {filterBarsData.length > 0 && (
              <>
                <S.FilterBarsHeader>
                  <S.SubSectionTitle>Role titles</S.SubSectionTitle>
                </S.FilterBarsHeader>
                <FilterBars
                  data={filterBarsData}
                  onFilterClick={filterLabel => {
                    if (filterLabel === activeFilterId) {
                      setActiveFilterId(undefined);
                    } else {
                      setActiveFilterId(filterLabel);
                    }
                  }}
                  maximumOptionCount={highestRoleCount}
                  isFilterActive={activeFilterId !== undefined}
                  rolesExceedLimit={rolesExceedLimit}
                  onShowAllClick={() =>
                    setIsShowingAllRoles(prevState => !prevState)
                  }
                  isShowingAllRoles={isShowingAllRoles}
                  totalRoleTitles={totalRoleTitles}
                />
              </>
            )}
          </>
        ) : (
          <S.NoneIdentifiedText>None identified</S.NoneIdentifiedText>
        )}
      </>
    );
  };

  const renderDirectorships = () => {
    // Extract dates
    const { startDates, endDates } = orgsWithDirectorshipRoles.reduce(
      (acc, curr) => {
        curr.listOfRoles?.forEach(role => {
          if (role.startDate?.year) {
            acc.startDates.push(role.startDate.year);
          }
          if (role.endDate?.year) {
            acc.endDates.push(role.endDate.year);
          }
        });
        return acc;
      },
      { startDates: [], endDates: [] }
    );

    const earliestStartDate = startDates.length
      ? Math.min(...startDates)
      : undefined;
    const latestEndDate = endDates.length ? Math.max(...endDates) : undefined;

    return (
      <>
        <S.SubSectionHeader ref={employmentOverviewRef}>
          <div>
            <S.SubSectionTitle>Directorships / officerships</S.SubSectionTitle>
            {orgsWithDirectorshipRoles.length > 0 && (
              <div className="font__size--smallest font__color--regular">
                <span>{earliestStartDate}</span>
                <span>{earliestStartDate && " - "}</span>
                <span>
                  {latestEndDate === new Date().getFullYear() || !latestEndDate
                    ? "Present"
                    : latestEndDate}
                </span>
              </div>
            )}
          </div>
        </S.SubSectionHeader>
        <div>
          {orgsWithDirectorshipRoles.length ? (
            <>
              <StackedBar
                total={orgsWithDirectorshipRoles.length}
                bars={directorshipBars}
                showZeroCountBars
                showTotal={false}
                onBarSectionClick={onFilterClick}
                selectedBarId={activeFilterId}
                isBarNotSelected={isStackedBarNotSelected(
                  FILTER_IDS.resigned,
                  directorshipBars
                )}
                rightAlignLastPill
              />
              <StackedBar
                total={orgsWithDirectorshipRoles.length}
                bars={riskBars}
                showTotal={false}
                showZeroCountBars
                onBarSectionClick={onFilterClick}
                selectedBarId={activeFilterId}
                isBarNotSelected={isStackedBarNotSelected(
                  FILTER_IDS.noRisk,
                  riskBars
                )}
                rightAlignLastPill
              />
              <StackedBar
                total={orgsWithDirectorshipRoles.length}
                bars={uboBars}
                showZeroCountBars
                showTotal={false}
                onBarSectionClick={onFilterClick}
                selectedBarId={activeFilterId}
                isBarNotSelected={isStackedBarNotSelected(
                  FILTER_IDS.uboNotApplicable,
                  uboBars
                )}
                rightAlignLastPill
              />
              <StackedBar
                total={orgsWithDirectorshipRoles.length}
                bars={pscBars}
                showTotal={false}
                showZeroCountBars
                onBarSectionClick={onFilterClick}
                selectedBarId={activeFilterId}
                isBarNotSelected={isStackedBarNotSelected(
                  FILTER_IDS.pscNotApplicable,
                  pscBars
                )}
                rightAlignLastPill
              />
            </>
          ) : (
            <S.NoneIdentifiedText>None identified</S.NoneIdentifiedText>
          )}
        </div>
      </>
    );
  };

  const renderRoleData = () => {
    return filteredRoleData.map((i, index) => {
      const item = {
        ...i,
        listOfRoles: i.listOfRoles.filter(
          role => sanitiseRoleString(role.roleTitle) !== SPECIAL_ROLES.ubo
        )
      };

      const uboRole = i.listOfRoles.find(
        role => sanitiseRoleString(role.roleTitle) === SPECIAL_ROLES.ubo
      );

      let itemData = item;
      let multiRolesAtSameClient = false;

      if (item.listOfRoles.length > 1) {
        multiRolesAtSameClient = true;
        const startDate = item.earliestStartDate;
        const endDate = item.latestEndDate;

        itemData = {
          ...item,
          listOfRoles: [
            {
              roleTitle: `${item.listOfRoles.length} Roles`,
              isOngoing: item.isTenureOngoing,
              roleDuration: item.overallDurationOfEngagement,
              startDate,
              endDate
            },
            ...item.listOfRoles
          ]
        };
      }
      return (
        <ProfessionalInformation
          item={itemData}
          key={`professionalInformationRow_${index}`}
          numberOfRoles={item.listOfRoles.length}
          multiRolesAtSameClient={multiRolesAtSameClient}
          uboRole={uboRole}
          subjectName={subjectName}
          matchedPersonName={item.matchedPersonName}
          originalSetConfidence={item.originalSetConfidence}
          currentSetConfidence={item.currentSetConfidence}
          onConfidenceChange={onConfidenceChange}
        />
      );
    });
  };

  const renderBannerFallbackLabel = () => {
    return hasFilterBeenActive
      ? "No active filters"
      : "Job roles and officerships ordered by most recent start date.";
  };

  const totalNumberOfEmployers = filteredRoleData.length;
  const companyString =
    totalNumberOfEmployers === 1 ? "appointment" : "appointments";
  const itemString = totalNumberOfEmployers === 1 ? "item" : "items";
  const isFilterActive = activeFilterId !== undefined;
  // Is the selected filter one of the role title filters on the bottom-right
  // of the widget?
  const isRoleFilterActive =
    rolesCountsBucketedByTitle[activeFilterId] !== undefined;
  let filterOptions = {};

  if (FILTER_SETTINGS[activeFilterId]) {
    filterOptions = FILTER_SETTINGS[activeFilterId];
  } else if (isRoleFilterActive) {
    filterOptions = {
      ...FILTER_SETTINGS[FILTER_IDS.roleFilters],
      label: rolesCountsBucketedByTitle[activeFilterId]?.label
    };
  }

  return (
    <>
      <S.SectionHeader>
        <S.ActiveFiltersContainer>
          <S.ActiveFiltersLabel>
            {isFilterActive ? "Active filters:" : renderBannerFallbackLabel()}
          </S.ActiveFiltersLabel>{" "}
          {isFilterActive && (
            <S.ActiveFilterPill backgroundColor={filterOptions.color}>
              <S.ActiveFilterPillLabel>
                {filterOptions.label}
              </S.ActiveFilterPillLabel>
              <S.CrossIcon
                onClick={() => {
                  setActiveFilterId(undefined);
                }}
              />
            </S.ActiveFilterPill>
          )}
        </S.ActiveFiltersContainer>
        {isPDX ? (
          <ConfidenceDropDownMenu
            isPdx
            inputtedHoveredState={selectedConfidenceFilter}
            dropDownButtonText={DOWJONES_CONVERSION[selectedConfidenceFilter]}
            dropDownMenuItem1={{
              label: confidenceBucketCounts.confirmed,
              onClickHandler: () =>
                onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.confirmed)
            }}
            dropDownMenuItem2={{
              label: confidenceBucketCounts.unconfirmed,
              onClickHandler: () =>
                onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.unconfirmed)
            }}
            dropDownMenuItem3={{
              label: confidenceBucketCounts.unconfirmed,
              onClickHandler: () =>
                onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.discarded)
            }}
            dropDownMenuItem4={{
              label: confidenceBucketCounts.userDiscarded,
              onClickHandler: () =>
                onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.userDiscarded)
            }}
          />
        ) : (
          <DropdownButton label={`${selectedConfidenceFilter} results`}>
            <S.OptionsDropdownMenuItem
              onClick={() => {
                onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.confirmed);
              }}
            >
              Confirmed
              <S.ConfidencebucketCount>
                {confidenceBucketCounts.confirmed}
              </S.ConfidencebucketCount>
            </S.OptionsDropdownMenuItem>
            <S.OptionsDropdownMenuItem
              onClick={() => {
                onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.unconfirmed);
              }}
            >
              Unconfirmed
              <S.ConfidencebucketCount>
                {confidenceBucketCounts.unconfirmed}
              </S.ConfidencebucketCount>
            </S.OptionsDropdownMenuItem>
            <S.OptionsDropdownMenuItem
              onClick={() => {
                onConfidenceFilterSelected(CONFIDENCE_CATEGORIES.discarded);
              }}
            >
              Discarded
              <S.ConfidencebucketCount>
                {confidenceBucketCounts.discarded}
              </S.ConfidencebucketCount>
            </S.OptionsDropdownMenuItem>
          </DropdownButton>
        )}
      </S.SectionHeader>
      <div className="snapshot__directorship-header-container">
        <div className="snapshot__directorship-bars-container">
          {renderDirectorships()}
        </div>
        <S.EmploymentDetailsContainer
          className="snapshot__employment-details-container"
          isLeftPanelEmpty={orgsWithDirectorshipRoles.length === 0}
        >
          {renderEmploymentHistory()}
        </S.EmploymentDetailsContainer>
      </div>
      {isPDX ? (
        <ShowResultsButton
          expandOveride
          resultsCount={totalNumberOfEmployers}
          onShowResultsClick={newVal => setIsDirectorshipsResultsShown(newVal)}
          resultsString={itemString}
          isShowingResults={isDirectorshipsResultsShown}
        />
      ) : (
        <ShowResultsButton
          resultsCount={totalNumberOfEmployers}
          onShowResultsClick={newVal => setIsDirectorshipsResultsShown(newVal)}
          resultsString={companyString}
          isShowingResults={isDirectorshipsResultsShown}
        />
      )}
      <S.ResultsSection
        ref={resultsSectionRef}
        isResultsShown={isDirectorshipsResultsShown}
        isResultsExpanded={isResultsExpanded}
      >
        {isDirectorshipsResultsShown && renderRoleData()}
      </S.ResultsSection>
      <S.CustomStickyExpandButton
        isReportRegenerationOpen={isReportRegenerationOpen}
        isResultsExpanded={isResultsExpanded}
        onToggleExpandResultsSection={onToggleExpandResultsSection}
        resultsSectionRef={resultsSectionRef}
        hideDividingLines={false}
      />
    </>
  );
};

export default ResultsWithFilters;
