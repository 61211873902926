import { css } from "@linaria/core";

export const globals = css`
  :global() {
    @font-face {
      font-family: "Inter-Light";
      font-display: fallback;
      src: url("../fonts/inter/Inter-Light.woff2") format("woff2");
      font-weight: 300;
      font-style: italic;
    }

    @font-face {
      font-family: "Inter-Regular";
      font-display: fallback;
      src: url("../fonts/inter/Inter-Regular.woff2") format("woff2");
      font-weight: 400;
      font-style: normal;
    }

    @font-face {
      font-family: "Inter-SemiBold";
      font-display: fallback;
      src: url("../fonts/inter/Inter-SemiBold.woff2") format("woff2");
      font-weight: 600;
      font-style: normal;
    }

    @font-face {
      font-family: "InterDisplay-SemiBold";
      font-display: fallback;
      src: url("../fonts/inter/InterDisplay-SemiBold.woff2") format("woff2");
      font-weight: 400;
      font-style: normal;
    }
   }
  }
`;
