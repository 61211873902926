import React, { memo } from "react";
import { StyledCardText } from "./StyledCardText";

export const CardText = memo(props => {
  const { text, label } = props;
  const textLabel = props.textLabel || "text";

  return (
    <div className={props.className ?? "di-card-text"} style={props.style}>
      <div className={props.cardClassOverride}>
        <StyledCardText {...{ textLabel }}>{text}</StyledCardText>
      </div>
      {label && label.length ? (
        <div className="di-card-text-label">{label}</div>
      ) : (
        ""
      )}
    </div>
  );
});
