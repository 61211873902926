import React, {
  useReducer,
  useMemo,
  ReactNode,
  useEffect,
  useCallback
} from "react";

import User from "api/user";

import { SearchItemType } from "api/search";
import {
  AutoSharePermission,
  SharedWithGroup,
  SharedWithItem,
  SharedWithUser
} from "api/user/types";
import { useAuthentication } from "util/hooks/useAuthentication";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";

import { UserPreferencesActions } from "./types";
import {
  UserPreferencesContext,
  userPreferencesReducer,
  initialState
} from "./context";

const UserPreferencesContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const UserApi = useMemo(() => new User(), []);

  const [preferencesState, preferencesDispatch] = useReducer(
    userPreferencesReducer,
    initialState
  );

  const {
    state: { status: authStatus }
  } = useAuthentication();

  const sharedWith = useMemo(
    () => [
      ...preferencesState.sharedWithUsers.map(user => ({
        title: `${user.firstName} ${user.lastName}`,
        subtitle: user.email,
        type: SearchItemType.User,
        id: user.userId,
        avatarUrl: user.avatarUrl,
        permission: user.permission
      })),
      ...preferencesState.sharedWithGroups.map(group => ({
        title: group.name,
        subtitle: "",
        type: SearchItemType.Group,
        id: group.groupId,
        permission: group.permission
      }))
    ],
    [preferencesState]
  );

  useEffect(() => {
    if (authStatus === AuthenticationStatus.unauthenticated) {
      preferencesDispatch({
        type: UserPreferencesActions.resetUserPreferences
      });
    }
  }, [authStatus]);

  useEffect(() => {
    const getAndSetUserPreferences = async () => {
      const { response: storedUserPreferences } =
        await UserApi.getUserPreferences();
      if (storedUserPreferences) {
        const { sharingPreferences } = storedUserPreferences;
        const { autoshare, sharedWithGroups, sharedWithUsers } =
          sharingPreferences;

        preferencesDispatch({
          type: UserPreferencesActions.updateAutoShareEnabled,
          autoshare
        });
        preferencesDispatch({
          type: UserPreferencesActions.updateAutoSharedWith,
          sharedWithUsers,
          sharedWithGroups
        });
        return null;
      }
      return null;
    };

    if (authStatus === AuthenticationStatus.authenticated) {
      getAndSetUserPreferences();
    }
  }, [UserApi, authStatus]);

  const updateShareEntryPermission = useCallback(
    (
      shareEntries: SharedWithItem[],
      shareEntryIndex: number,
      permission: AutoSharePermission
    ) => {
      let shareEntriesCopy = shareEntries.map(shareItem => ({ ...shareItem }));
      // Delete
      if (permission === AutoSharePermission.Delete) {
        shareEntriesCopy = [
          ...shareEntriesCopy.slice(0, shareEntryIndex),
          ...shareEntriesCopy.slice(shareEntryIndex + 1)
        ];
      } else {
        // Change permission
        shareEntriesCopy[shareEntryIndex].permission = permission;
      }

      return shareEntriesCopy;
    },
    []
  );

  const updateSharePermission = useCallback(
    async (shareEntryId: string, permission: AutoSharePermission) => {
      let updatedSharedWithUsers = preferencesState.sharedWithUsers;
      let updatedSharedWithGroups = preferencesState.sharedWithGroups;

      const itemIndexWithinUsers = updatedSharedWithUsers.findIndex(
        share => share.userId === shareEntryId
      );
      const itemIndexWithinGroups = updatedSharedWithGroups.findIndex(
        share => share.groupId === shareEntryId
      );

      if (itemIndexWithinUsers > -1) {
        updatedSharedWithUsers = updateShareEntryPermission(
          updatedSharedWithUsers,
          itemIndexWithinUsers,
          permission
        ) as SharedWithUser[];
      } else if (itemIndexWithinGroups > -1) {
        updatedSharedWithGroups = updateShareEntryPermission(
          updatedSharedWithGroups,
          itemIndexWithinGroups,
          permission
        ) as SharedWithGroup[];
      }

      // Toggle off autoshare if there are no users/groups being shared with
      const updatedAutoshare =
        !updatedSharedWithGroups.length &&
        !updatedSharedWithUsers.length &&
        preferencesState.autoshare
          ? false
          : preferencesState.autoshare;

      const requestBody = {
        sharingPreferences: {
          autoshare: updatedAutoshare,
          sharedWithUsers: updatedSharedWithUsers,
          sharedWithGroups: updatedSharedWithGroups
        }
      };

      const result = await UserApi.setUserPreferences(requestBody);

      if (result.status) {
        preferencesDispatch({
          type: UserPreferencesActions.updateAutoSharedWith,
          autoshare: updatedAutoshare,
          sharedWithUsers: updatedSharedWithUsers,
          sharedWithGroups: updatedSharedWithGroups
        });
      }
      return result;
    },
    [UserApi, preferencesState, updateShareEntryPermission]
  );

  const toggleAutoshare = useCallback(async () => {
    const modifiedPreferences = {
      sharingPreferences: {
        autoshare: !preferencesState.autoshare,
        sharedWithUsers: preferencesState.sharedWithUsers,
        sharedWithGroups: preferencesState.sharedWithGroups
      }
    };

    // Toggle on/off for immediate user feedback (but reverse if fetch fails)
    preferencesDispatch({
      type: UserPreferencesActions.updateAutoShareEnabled,
      autoshare: modifiedPreferences.sharingPreferences.autoshare
    });

    const { status } = await UserApi.setUserPreferences(modifiedPreferences);

    if (!status) {
      preferencesDispatch({
        type: UserPreferencesActions.updateAutoShareEnabled,
        autoshare: !modifiedPreferences.sharingPreferences.autoshare
      });
    }
  }, [UserApi, preferencesState]);

  const addUserShareEntries = useCallback(
    (userShareEntries: SharedWithUser[]) => {
      const storedUserShareEntriesCopy = preferencesState.sharedWithUsers.map(
        shareEntry => ({ ...shareEntry })
      );

      const userShareEntriesToAdd = userShareEntries.reduce<SharedWithUser[]>(
        (arr, shareEntry) => {
          // If the entry already exists, then just modify the permission
          const existingIndex = storedUserShareEntriesCopy.findIndex(
            storedShareEntry => storedShareEntry.userId === shareEntry.userId
          );

          if (existingIndex > -1) {
            storedUserShareEntriesCopy[existingIndex].permission =
              shareEntry.permission;
          } else {
            return [...arr, shareEntry];
          }

          return arr;
        },
        []
      );

      return [...storedUserShareEntriesCopy, ...userShareEntriesToAdd];
    },
    [preferencesState.sharedWithUsers]
  );

  const addGroupShareEntries = useCallback(
    (groupShareEntries: SharedWithGroup[]) => {
      const storedGroupShareEntriesCopy = preferencesState.sharedWithGroups.map(
        shareEntry => ({ ...shareEntry })
      );

      const groupShareEntriesToAdd = groupShareEntries.reduce<
        SharedWithGroup[]
      >((arr, shareEntry) => {
        // If the entry already exists, then just modify the permission
        const existingIndex = storedGroupShareEntriesCopy.findIndex(
          storedShareEntry => storedShareEntry.groupId === shareEntry.groupId
        );

        if (existingIndex > -1) {
          storedGroupShareEntriesCopy[existingIndex].permission =
            shareEntry.permission;
        } else {
          return [...arr, shareEntry];
        }

        return arr;
      }, []);

      return [...storedGroupShareEntriesCopy, ...groupShareEntriesToAdd];
    },
    [preferencesState]
  );

  const addShareEntries = useCallback(
    async (shareEntries: (SharedWithItem & { type: SearchItemType })[]) => {
      // Split share entries based on type
      const userShareEntries: SharedWithUser[] = [];
      const groupShareEntries: SharedWithGroup[] = [];

      shareEntries.forEach(shareEntry => {
        if (shareEntry.type === SearchItemType.User) {
          userShareEntries.push({ ...shareEntry } as SharedWithUser);
        } else {
          groupShareEntries.push({ ...shareEntry } as SharedWithGroup);
        }
      });

      const updatedUserShareEntries = addUserShareEntries(userShareEntries);
      const updatedGroupShareEntries = addGroupShareEntries(groupShareEntries);

      const modifiedPreferences = {
        sharingPreferences: {
          // Auto-toggle auto-share when user adds first share
          autoshare:
            !preferencesState.sharedWithUsers.length &&
            !preferencesState.sharedWithGroups.length &&
            !preferencesState.autoshare
              ? true
              : preferencesState.autoshare,
          sharedWithUsers: updatedUserShareEntries,
          sharedWithGroups: updatedGroupShareEntries
        }
      };

      const setResult = await UserApi.setUserPreferences(modifiedPreferences);

      if (setResult.status) {
        // Update to latest results (we need to do this because we need the other meta data to display - name, email, jobtitle, etc.)
        const {
          status,
          message,
          response: updatedPreferences
        } = await UserApi.getUserPreferences();

        if (status) {
          preferencesDispatch({
            type: UserPreferencesActions.updateAutoSharedWith,
            // Auto-toggle auto-share when user adds first share
            autoshare:
              !preferencesState.sharedWithUsers.length &&
              !preferencesState.sharedWithGroups.length &&
              !preferencesState.autoshare
                ? true
                : preferencesState.autoshare,
            sharedWithUsers:
              updatedPreferences?.sharingPreferences.sharedWithUsers ??
              preferencesState.sharedWithUsers,
            sharedWithGroups:
              updatedPreferences?.sharingPreferences.sharedWithGroups ??
              preferencesState.sharedWithGroups
          });
        }
        return { status, message };
      }

      return setResult;
    },
    [
      UserApi,
      addGroupShareEntries,
      addUserShareEntries,
      preferencesState.autoshare,
      preferencesState.sharedWithGroups,
      preferencesState.sharedWithUsers
    ]
  );

  const providerValue = useMemo(
    () => ({
      state: { ...preferencesState, sharedWith },
      toggleAutoshare,
      updateSharePermission,
      addShareEntries
    }),
    [
      preferencesState,
      sharedWith,
      toggleAutoshare,
      updateSharePermission,
      addShareEntries
    ]
  );

  return (
    <UserPreferencesContext.Provider value={providerValue}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export { UserPreferencesContextProvider };
