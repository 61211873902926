import { styled } from "@linaria/react";

import ButtonNew from "components/atoms/ButtonNew";

import { fontSize } from "styles/fonts";
import { red, standardColors, grey } from "styles/colors";

const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Explainer = styled.div`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const FooterContent = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const SecondaryButton = styled(ButtonNew)`
  flex: 1;
`;

const PrimaryButton = styled(SecondaryButton)`
  &:not(:hover, :focus, :disabled) {
    background-color: ${red.directRiskFill};
    color: ${standardColors.white};
  }
`;

const S = {
  BodyContent,
  Explainer,
  FooterContent,
  PrimaryButton,
  SecondaryButton
};

export default S;
