// These flags should always be "top-level"; not a child of another flag
const topLevelFlags = [
  "Other Official Lists",
  "Sanctions Lists",
  "Sanctions Control & Ownership",
  "Exclusion Lists",
  "Other Exclusion Lists",
  "Enhanced Country Risk",
  "Politically Exposed Person (PEP)",
  "Relative or Close Associate (RCA)",
  "Board Member",
  "State Owned Company (SOC)",
  "High Risk Factors",
  "Adverse Media"
];

const buildStateOwnedFlagLabel = flagLocations => {
  let stateOwnedLocationLabel = "";
  if (
    flagLocations?.length > 0 &&
    flagLocations?.length < 2 &&
    flagLocations[0]?.countryName
  ) {
    stateOwnedLocationLabel = `– ${flagLocations[0].countryName}`;
  } else if (flagLocations?.length > 1) {
    stateOwnedLocationLabel = `– Multiple States`;
  }

  return stateOwnedLocationLabel;
};

export const mapFlagNamesAndOrder = (flags, flagLocations) => {
  const mappingAndOrdering = {
    "Sanctions Lists": { sortOrder: 1 },
    "Politically Exposed Person (PEP)": {
      label: "Politically Exposed Person",
      sortOrder: 2
    },
    "Other Official Lists": { label: "Watchlists", sortOrder: 3 },
    "Sanctions Control & Ownership": {
      label: "Related to Sanctioned Party",
      sortOrder: 4
    },
    "State Owned Company (SOC)": {
      label: `State Owned ${buildStateOwnedFlagLabel(flagLocations)}`,
      sortOrder: 5
    },
    "Enhanced Country List": { sortOrder: 6 },
    "High Risk Factors": { sortOrder: 7 },
    "Special Interest Person (SIP)": {
      label: "Special Interest Person",
      sortOrder: 8
    },
    "Special Interest Entity (SIE)": {
      label: "Special Interest Entity",
      sortOrder: 9
    },
    AdverseMedia: { label: "Adverse Media", sortOrder: 10 },
    "Relative or Close Associate (RCA)": {
      label: "Relative or Close Associate",
      sortOrder: 11
    },
    "Board Member": { sortOrder: 12 },
    "Exclusion Lists": { sortOrder: 13 },
    "Other Exclusion Lists": { sortOrder: 14 }
  };

  // Sort
  const sortedFlags = flags?.sort((a, b) => {
    return (
      mappingAndOrdering[a.type]?.sortOrder -
      mappingAndOrdering[b.type]?.sortOrder
    );
  });

  // Replace flag identifiers with mapping
  flags?.forEach(
    flag => (flag.type = mappingAndOrdering[flag.type]?.label ?? flag.type)
  );

  return sortedFlags;
};

export const processFlagHierarchy = flag => {
  const flags = [];

  const isActive = flag.isActive;

  // Special case for an Adverse media or High Risk Factors flag - we want all level 2 flags to be a child of Adverse media
  if (flag.type === "AdverseMedia" || flag.type === "HighRiskFactors") {
    const flagObj = { type: flag.type, isActive: flag.isActive, children: [] };

    flag.categoryHierarchy?.forEach(root => {
      root.subContentTypes?.forEach(level2 => {
        flagObj.children.push(level2.description);
      });
    });

    flags.push(flagObj);
  } else {
    flag.categoryHierarchy?.forEach(level1 => {
      // If the level 1 flag is a "top level flag", then add it to the result
      if (topLevelFlags.find(type => type === level1.description)) {
        flags.push({
          type: level1.description,
          isActive: isActive,
          children: []
        });
      }
      // Move to level 2 flags
      level1.subContentTypes?.forEach(level2 => {
        // If the level 2 flag is a "top level flag", then add it to the result
        if (topLevelFlags.find(type => type === level2.description)) {
          // Special case for Other Exclusion Lists - we are merging this with Other Official Lists/Watchlists, so skip this flag
          if (level2.description !== "Other Exclusion Lists") {
            flags.push({ type: level2.description, isActive: isActive });
          }
        } else {
          // Else, this flag is not classed as a "top level flag" and must be a child of a level 1 flag
          let level1FlagIndex = flags.findIndex(
            flagObj => flagObj.type === level1.description
          );
          // If we haven't create a level 1 object in the result, then create it with the necessary children array
          if (level1FlagIndex === -1) {
            level1FlagIndex =
              flags.push({
                type: level1.description,
                isActive: isActive,
                children: []
              }) - 1;
          }
          flags[level1FlagIndex].children.push(level2.description);
        }
      });
    });
  }
  return flags;
};
