import { styled } from "@linaria/react";

import { blue, grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

import { ReactComponent as BuildingSvg } from "img/building.svg";

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${grey.faint};
  padding-top: 1rem;
`;

const Explainer = styled.p`
  margin: 0;
  padding-top: 0.25rem;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const BuildingIcon = styled(BuildingSvg)`
  transform: scale(0.7);
  path {
    fill: ${standardColors.white};
  }
`;

const PillsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  background-color: ${blue.panel};
  border-radius: 0.375rem;
  padding: 0.5rem;
  width: 100%;
`;

const Controls = styled.div`
  display: flex;
  gap: 0.75rem;
  width: 100%;

  & > button {
    flex: 1;
  }
`;

const PersonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${standardColors.white};
  padding: 2px;
`;

const S = {
  ModalContent,
  Explainer,
  BuildingIcon,
  PillsContainer,
  Controls,
  PersonIcon
};

export default S;
