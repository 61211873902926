import React, { FC, ReactNode } from "react";

import S from "./styles";

interface Props {
  children?: ReactNode;
}

const GeneratingText: FC<Props> = ({ children }) => {
  return <S.GeneratingText>{children}</S.GeneratingText>;
};

export default GeneratingText;
