import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const TransferControl = styled.div`
  border-top: 1px solid ${grey.mid};
  padding-top: 1.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const Explainer = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.375rem;
  color: ${grey.ghost};
`;
const ModalControls = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Button = styled(ButtonNew)`
  flex: 1;
`;

const S = {
  TransferControl,
  Explainer,
  ModalControls,
  Button
};

export default S;
