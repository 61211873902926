import React, { Fragment, memo } from "react";

import { usePrintableScrollPosition } from "util/hooks/usePrintableScrollPosition";
import { emboldenKeyWords } from "util/emboldenKeyWords";
import { riskDirections } from "util/riskDirections";
import { specialTier2Risks } from "util/specialTier2Risks";

import S, { classNameOverrides } from "./styles";

const TagInspectionPanel = memo(props => {
  const { tag, onClose, sourceId, originalLanguage, heading, sourceProvider } =
    props;
  const snippets = ((tag && tag.snippets) || []).map(s =>
    typeof s === "string" ? { snippet: s } : s
  ); // backwards compatibility
  const elementRef = usePrintableScrollPosition(
    `${sourceId}-${tag.category.label}-${tag.id}-inspection-panel`
  );

  return (
    <div
      className={`tag-inspection-panel ${
        tag ? "" : "tag-inspection-panel-hidden"
      }`}
      ref={elementRef}
    >
      <S.HeaderWrapper>
        <h6>{heading}</h6>
        <S.CloseButton onClick={onClose}>
          <S.CloseIcon />
        </S.CloseButton>
      </S.HeaderWrapper>
      <hr />
      {snippets?.length ? (
        <div>
          {snippets.map((s, index) => {
            const startIndex =
              s.highlightOffsets?.length && s.highlightOffsets[0]?.startIndex
                ? s.highlightOffsets[0].startIndex
                : index;
            return (
              <S.Snippet key={`${s.snippet} - ${startIndex}`}>
                ...
                {emboldenKeyWords(
                  s.snippet?.trim(),
                  s.highlightOffsets,
                  s.riskDirection === riskDirections.direct
                    ? classNameOverrides.directRisk
                    : classNameOverrides.indirectRisk
                )}
                ...
                {s.translated && (
                  <S.TranslatedLabel>
                    (Translated from {originalLanguage})
                  </S.TranslatedLabel>
                )}
              </S.Snippet>
            );
          })}
        </div>
      ) : (
        <S.NoSnippet>
          {tag.id === specialTier2Risks.highRiskSites ? (
            <>{sourceProvider} is considered a high-risk site.</>
          ) : (
            "No snippet found"
          )}
        </S.NoSnippet>
      )}
    </div>
  );
});

export default TagInspectionPanel;
