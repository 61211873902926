import React, { FC, ReactNode } from "react";

import type { Report } from "api/reports";
import { ScreeningRisk } from "api/reports/types";

import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import Popover from "components/atoms/Popover";

import { grey } from "styles/colors";
import S from "./styles";

const riskIcons: Record<ScreeningRisk, ReactNode> = {
  sanctions: <S.SanctionsIcon />,
  watchlists: <S.WatchlistsIcon />,
  peps: <S.PepsIcon />,
  other: <S.OtherIcon />,
  rca: <S.RCAIcon />,
  specialInterest: <S.SpecialInterestIcon />
};

const riskExplainer: Record<ScreeningRisk, string> = {
  sanctions: "We have identified the subject on a sanction list.",
  watchlists: "We have identified the subject on a watchlist.",
  peps: "We have identified the subject as a politically exposed person (PEP).",
  other: "Adverse Media for review.",
  rca: "We have identified your subject is related or closely associated to sanctioned, political or flagged individuals.",
  specialInterest: "We have identified the subject as 'of Special Interest'."
};

const RiskIcons: FC<{ item: Report }> = ({ item }) => {
  if ("status" in item && item.status !== "ready") {
    return null;
  }

  const directRiskPresent = item.risks?.some(
    risk =>
      risk !== ScreeningRisk.RCA &&
      risk !== ScreeningRisk.SpecialInterest &&
      risk !== ScreeningRisk.Other
  );

  return (
    <S.RiskIcons>
      {item.risks?.map((risk, i) => {
        if (directRiskPresent && risk === ScreeningRisk.RCA) {
          return null;
        }

        return (
          <Popover
            content={<S.TooltipContent>{riskExplainer[risk]}</S.TooltipContent>}
            disableHideOnClip={undefined}
            className={undefined}
            style={undefined}
            key={`Risk-${item.id}-${risk}`}
          >
            <Avatar
              avatarSize={AvatarSize.Medium}
              background={grey.panel}
              hasBorder={false}
              sx={{ zIndex: i }}
            >
              {riskIcons[risk]}
            </Avatar>
          </Popover>
        );
      })}
    </S.RiskIcons>
  );
};

export default RiskIcons;
