import { styled } from "@linaria/react";

import { ReactComponent as RightChevronSvg } from "img/right-chevron.svg";

import { grey, red } from "styles/colors";
import { fontSize } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

const TableHeading = styled.th`
  padding-block: 9px 0;
  text-align: left;
  color: ${red.directRiskOutline};
  font-size: ${fontSize.md};
  font-weight: inherit;
  border-bottom: 1px solid
    ${() => {
      const { r, g, b } = hexToRgb(red.directRiskOutline);
      return `rgba(${r}, ${g}, ${b}, 0.4)`;
    }};
`;

const ExpandHeaderCountLabel = styled.span`
  color: ${grey.dark};
`;

const Chevron = styled(RightChevronSvg)`
  width: 10px;
  transform: ${({ className }) =>
    className === "isExpanded" ? "rotate(90deg)" : "rotate(0deg)"};

  path {
    fill: ${grey.dark};
  }
`;

const ConfidenceHitCount = styled.span`
  color: ${grey.dark};
  font-size: ${fontSize.lg};
  margin-right: 8px;
`;

const ExpandHeaderRightContent = styled.div<{ isDiscarded: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${props => (props.isDiscarded ? 0.2 : 1)};
`;

const InfoIconContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DisabledInfoIconContainer = styled(InfoIconContainer)`
  opacity: 0.2;
`;

const ScreeningTableWrapper = styled.div<{ isLastTable: boolean }>`
  padding: 0 16px;
  padding-bottom: ${props => (props.isLastTable ? "16px" : 0)};
`;

const ScreeningTable = styled.table`
  width: 100%;
`;

const ExpandHeader = styled.button<{ isTableEntriesEmpty: boolean }>`
  all: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  font-size: ${fontSize.md};
  color: ${grey.mid};
  height: 48px;
  background-color: ${grey.light};
  cursor: ${props =>
    props.isTableEntriesEmpty ? "default" : "pointer"} !important;

  ${ConfidenceHitCount}, ${Chevron} {
    color: ${props => (props.isTableEntriesEmpty ? grey.ghost : grey.dark)};

    path {
      fill: ${props => (props.isTableEntriesEmpty ? grey.ghost : grey.dark)};
    }
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const S = {
  TableHeading,
  InfoIconContainer,
  ExpandHeader,
  ExpandHeaderCountLabel,
  ExpandHeaderRightContent,
  Chevron,
  ScreeningTableWrapper,
  ScreeningTable,
  ConfidenceHitCount,
  DisabledInfoIconContainer
};

export default S;
