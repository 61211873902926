import React, { useMemo, memo, useEffect } from "react";

import { usePrintableReportState } from "util/hooks/usePrintableState";

import SortFilterCategories from "components/molecules/SortFilterCategories";

import S from "./styles";

const AdditionalFilters = memo(
  ({
    title,
    onOrganisationFilterSelected,
    onPeopleFilterSelected,
    onLanguagesFilterSelected,
    onLocationsFilterSelected,
    articlesByLanguage,
    articlesByOrganisations,
    articlesByPeople,
    articlesByLocation,
    peopleNameVariants,
    organisationNameVariants,
    locationNameVariants,
    selectedLanguageFilters,
    selectedOrganisationsFilters,
    selectedPeopleFilters,
    selectedLocationFilters
  }) => {
    const [organisationsToUseArray, setOrganisationsToUseArray] =
      usePrintableReportState(`organisations-to-use-${title}`, new Set());
    const organisationsToUse = useMemo(
      () => new Set(organisationsToUseArray),
      [organisationsToUseArray]
    );
    const setOrganisationsToUse = useMemo(
      () => os => setOrganisationsToUseArray([...os]),
      [setOrganisationsToUseArray]
    );

    const [peopleToUseArray, setPeopleToUseArray] = usePrintableReportState(
      `people-to-use-${title}`,
      new Set()
    );
    const peopleToUse = useMemo(
      () => new Set(peopleToUseArray),
      [peopleToUseArray]
    );
    const setPeopleToUse = useMemo(
      () => os => setPeopleToUseArray([...os]),
      [setPeopleToUseArray]
    );

    const [languagesToUseArray, setLanguagesToUseArray] =
      usePrintableReportState(`languages-to-use-${title}`, new Set());
    const languagesToUse = useMemo(
      () => new Set(languagesToUseArray),
      [languagesToUseArray]
    );
    const setLanguagesToUse = useMemo(
      () => os => setLanguagesToUseArray([...os]),
      [setLanguagesToUseArray]
    );

    const [locationsToUseArray, setLocationsToUseArray] =
      usePrintableReportState(`locations-to-use=${title}`, new Set());
    const locationsToUse = useMemo(
      () => new Set(locationsToUseArray),
      [locationsToUseArray]
    );
    const setLocationsToUse = useMemo(
      () => os => setLocationsToUseArray([...os]),
      [setLocationsToUseArray]
    );

    useEffect(() => {
      setLanguagesToUse(new Set(selectedLanguageFilters));
    }, [selectedLanguageFilters, setLanguagesToUse]);

    useEffect(() => {
      setOrganisationsToUse(new Set(selectedOrganisationsFilters));
    }, [selectedOrganisationsFilters, setOrganisationsToUse]);

    useEffect(() => {
      setPeopleToUse(new Set(selectedPeopleFilters));
    }, [selectedPeopleFilters, setPeopleToUse]);

    useEffect(() => {
      setLocationsToUse(new Set(selectedLocationFilters));
    }, [selectedLocationFilters, setLocationsToUse]);

    const locations = useMemo(() => {
      const resultsMap = new Map();
      Object.keys(articlesByLocation).forEach(locationKey => {
        const availableItems = articlesByLocation[locationKey].length;

        if (locationsToUse && !locationsToUse.has(locationKey)) {
          resultsMap.set(locationKey, {
            currentAvailable: availableItems,
            display: locationKey,
            key: locationKey,
            potentialAvailable: availableItems
          });
        }
      });
      return resultsMap;
    }, [articlesByLocation]);

    const organisations = useMemo(() => {
      const resultsMap = new Map();
      Object.keys(articlesByOrganisations).forEach(orgKey => {
        const availableItems = articlesByOrganisations[orgKey].length;

        // Filter out those items that are selected.
        if (organisationsToUse && !organisationsToUse.has(orgKey)) {
          resultsMap.set(orgKey, {
            currentAvailable: availableItems,
            display: orgKey,
            key: orgKey,
            potentialAvailable: availableItems
          });
        }
      });

      return resultsMap;
    }, [articlesByOrganisations]);
    const people = useMemo(() => {
      const resultsMap = new Map();
      Object.keys(articlesByPeople).forEach(peopleKey => {
        const availableItems = articlesByPeople[peopleKey].length;

        if (peopleToUse && !peopleToUse.has(peopleKey)) {
          resultsMap.set(peopleKey, {
            currentAvailable: availableItems,
            display: peopleKey,
            key: peopleKey,
            potentialAvailable: availableItems
          });
        }
      });

      return resultsMap;
    }, [articlesByPeople]);
    const languages = useMemo(() => {
      const resultsMap = new Map();
      Object.keys(articlesByLanguage).forEach(languageKey => {
        const availableItems = articlesByLanguage[languageKey].length;

        if (languagesToUse && !languagesToUse.has(languageKey)) {
          resultsMap.set(languageKey, {
            currentAvailable: availableItems,
            display: languageKey,
            key: languageKey,
            potentialAvailable: availableItems
          });
        }
      });

      return resultsMap;
    }, [articlesByLanguage]);

    return (
      <S.FiltersContainer>
        <S.Filter>
          <SortFilterCategories
            categories={people}
            categoriesToUse={peopleToUse}
            setCategoriesToUse={(val, selectedVal) => {
              onPeopleFilterSelected(selectedVal);
              setPeopleToUse(val);
            }}
            nameVariants={peopleNameVariants}
            isInSnapshot
            title="People"
          />
        </S.Filter>
        <S.Filter>
          <SortFilterCategories
            categories={organisations}
            categoriesToUse={organisationsToUse}
            setCategoriesToUse={(val, selectedVal) => {
              onOrganisationFilterSelected(selectedVal);
              setOrganisationsToUse(val);
            }}
            nameVariants={organisationNameVariants}
            isInSnapshot
            title="Organisations"
          />
        </S.Filter>
        <S.Filter>
          <SortFilterCategories
            categories={languages}
            categoriesToUse={languagesToUse}
            setCategoriesToUse={(val, selectedVal) => {
              onLanguagesFilterSelected(selectedVal);
              setLanguagesToUse(val);
            }}
            isInSnapshot
            title="Languages"
          />
        </S.Filter>
        <S.Filter>
          <SortFilterCategories
            categories={locations}
            categoriesToUse={locationsToUse}
            setCategoriesToUse={(val, selectedVal) => {
              onLocationsFilterSelected(selectedVal);
              setLocationsToUse(val);
            }}
            nameVariants={locationNameVariants}
            isInSnapshot
            title="Places"
          />
        </S.Filter>
      </S.FiltersContainer>
    );
  }
);

export default AdditionalFilters;
