import React, { MouseEventHandler, ReactNode } from "react";

import ReactTooltip from "components/atoms/ReactTooltip";
import RiskPill from "components/atoms/RiskPill";

import S from "./styles";

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isSelected?: boolean;
  setSelectedTag?: (tag?: string) => void;
  toolTip?: string;
  sourceId?: string;
  hierarchy?: string[];
  isKillable?: boolean;
  label?: string;
  color?: string;
  fillColor?: string;
  isClickable?: boolean;
  alwaysShowKillButton?: boolean;
  icon?: ReactNode;
  className?: string;
}

const Tag = ({
  onClick,
  isSelected,
  setSelectedTag,
  toolTip,
  sourceId,
  hierarchy,
  isKillable,
  label,
  color,
  fillColor,
  icon,
  className,
  isClickable = true,
  alwaysShowKillButton = true
}: Props) => {
  const tag = (
    <RiskPill
      key={label}
      alwaysShowKillButton={alwaysShowKillButton}
      label={label}
      isActive={isSelected ?? false}
      sourceId={sourceId}
      riskHierarchies={hierarchy ? [hierarchy] : undefined}
      onClick={onClick}
      isKillable={isKillable}
      ariaLabel={label}
      color={color}
      fillColor={fillColor}
      onRiskRemoved={() => setSelectedTag && setSelectedTag(undefined)}
      isClickable={isClickable}
      icon={icon}
      className={className}
    />
  );
  return toolTip ? (
    <ReactTooltip
      layoutPosition="fixed"
      tooltip={<S.TooltipContainer>{toolTip}</S.TooltipContainer>}
    >
      {tag}
    </ReactTooltip>
  ) : (
    tag
  );
};

export default Tag;
