import React, { FC, SVGProps, useId, useState } from "react";
import { Person } from "@mui/icons-material";
import { Spinner } from "reactstrap";

import ModalContainer from "components/molecules/ModalContainer";
import TitleCard from "components/molecules/TitleCard";
import { ReportType } from "api/reports/types";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import { SearchResult } from "api/search";
import { DropdownOption } from "components/molecules/SearchInputWithOptions";
import ErrorBanner from "components/atoms/ErrorBanner";
import { GroupReportRole } from "api/tenant/types";

import SelectOrganisation from "./SelectOrganisation";
import SelectOwner from "./SelectOwner";
import SelectGroup, { dropdownOptions } from "./SelectGroup";
import ConfirmedCopy from "./ConfirmedCopy";
import useCopyReport from "./useCopyReport";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  reportId: string;
  reportTitle: string;
  reportContext: string;
  reportType: ReportType;
}

const CopyReportModal = ({
  isOpen,
  toggleOpen,
  reportId,
  reportTitle,
  reportContext,
  reportType
}: Props) => {
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<SearchResult>();

  const [selectedUserFromOrganisation, setSelectedUserFromOrganisation] =
    useState<SearchResult>();

  const [selectedGroupFromOrganisation, setSelectedGroupFromOrganisation] =
    useState<SearchResult>();

  const [selectedGroupPermission, setSelectedGroupPermission] = useState<
    DropdownOption<GroupReportRole>
  >(dropdownOptions[0]);

  const [isConfirmedCopyModalOpen, setIsConfirmedCopyModalOpen] =
    useState(false);

  const [notifyOwner, setNotifyOwner] = useState(false);

  const notifyOwnerCheckboxId = useId();

  const { sendCopyOfReport, loading, error, errorMessage } = useCopyReport();

  const toggleOpenModal = () => {
    toggleOpen();
    setSelectedOrganisation(undefined);
    setSelectedGroupFromOrganisation(undefined);
    setSelectedUserFromOrganisation(undefined);
    setNotifyOwner(false);
  };

  // Reset selected group and user when there is no organisation selected
  if (!selectedOrganisation) {
    if (selectedUserFromOrganisation) {
      setSelectedUserFromOrganisation(undefined);
    }
    if (selectedGroupFromOrganisation) {
      setSelectedGroupFromOrganisation(undefined);
    }
  }

  const onSendCopy = async () => {
    if (selectedUserFromOrganisation && selectedOrganisation) {
      const status = await sendCopyOfReport({
        orgId: selectedOrganisation.id,
        reportId,
        newOwnerId: selectedUserFromOrganisation?.id,
        newGroupId: selectedGroupFromOrganisation?.id,
        newGroupAccessPermission: selectedGroupPermission?.id,
        notifyOwner
      });

      if (status) {
        toggleOpen();
        setIsConfirmedCopyModalOpen(true);
      }
    }
  };

  return (
    <>
      <ModalContainer
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        title="Send a copy to another organisation"
        onExitClick={toggleOpen}
      >
        <TitleCard
          title={reportTitle}
          subtitle={reportContext}
          icon={
            reportType === ReportType.Organisation ? (
              <S.BuildingIcon />
            ) : (
              <Person fontSize="large" />
            )
          }
        />
        <S.Inputs>
          <SelectOrganisation
            selectedSuggestion={selectedOrganisation}
            setSelectedSuggestion={setSelectedOrganisation}
          />
          <S.Rule />
          <SelectOwner
            selectedSuggestion={selectedUserFromOrganisation}
            setSelectedSuggestion={setSelectedUserFromOrganisation}
            chosenOrgId={selectedOrganisation?.id}
          />
          <S.NotifyOwnerOption>
            <input
              type="checkbox"
              id={notifyOwnerCheckboxId}
              checked={notifyOwner}
              onChange={() => setNotifyOwner(prev => !prev)}
              onKeyDown={e =>
                e.key === "Enter" && setNotifyOwner(prev => !prev)
              }
              disabled={!selectedUserFromOrganisation}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={notifyOwnerCheckboxId}>
              Notify the new owner by email
            </label>
          </S.NotifyOwnerOption>
          <S.Rule />
          <SelectGroup
            selectedSuggestion={selectedGroupFromOrganisation}
            setSelectedSuggestion={setSelectedGroupFromOrganisation}
            selectedGroupPermission={selectedGroupPermission}
            setSelectedGroupPermission={setSelectedGroupPermission}
            chosenOrgId={selectedOrganisation?.id}
          />
        </S.Inputs>
        <S.Actions>
          <ButtonNew
            text="Cancel"
            type={ButtonType.Outlined}
            size={ButtonSize.Medium}
            onClick={toggleOpenModal}
          />
          <ButtonNew
            text="Copy report"
            type={ButtonType.Filled}
            size={ButtonSize.Medium}
            onClick={onSendCopy}
            disabled={loading || !selectedUserFromOrganisation}
            IconTrailing={
              loading
                ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                : undefined
            }
          />
        </S.Actions>
        {error && (
          <ErrorBanner
            text={
              errorMessage ??
              "There was an issue sending this report. Try again in a moment."
            }
          />
        )}
      </ModalContainer>
      <ConfirmedCopy
        isOpen={isConfirmedCopyModalOpen}
        toggleOpen={() => setIsConfirmedCopyModalOpen(prev => !prev)}
        reportTitle={reportTitle}
        reportContext={reportContext}
        reportType={reportType}
        selectedUserFromOrganisation={selectedUserFromOrganisation}
        selectedGroupFromOrganisation={selectedGroupFromOrganisation}
        selectedGroupPermission={selectedGroupPermission}
        notifyOwner={notifyOwner}
      />
    </>
  );
};

export default CopyReportModal;
