import { createContext } from "react";

export interface InsightsFeatureContextValue {
  timelinesRiskTags: boolean;
  timelinesRiskColors: boolean;
  toggleTimelineRiskTags: () => void;
  toggleTimelineRiskColors: () => void;
}

const defaultValue: InsightsFeatureContextValue = {
  timelinesRiskTags: false,
  timelinesRiskColors: true,
  toggleTimelineRiskTags: () => {},
  toggleTimelineRiskColors: () => {}
};

const InsightsFeatureContext = createContext(defaultValue);

export default InsightsFeatureContext;
