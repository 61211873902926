import React, { useContext, memo, useMemo } from "react";
import ErrorBoundary from "util/ErrorBoundary";
import Toggle from "react-toggle";

import useObservability from "util/hooks/useObservability";
import { UserVerificationContext } from "util/hooks/useUserVerification";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import AssessmentSelectionControl from "components/molecules/AssessmentSelectionControl";
import { AssessmentOptions } from "pages/report/AssessmentOptions";
import { Features, useFeatureEnabled } from "services/features";
import ReactTooltip from "components/atoms/ReactTooltip";

import { RiskSortFilterPill } from "./RiskSortFilterPill";
import { EntitySortFilterPill } from "./EntitySortFilterPill";
import { DateSortFilterPill } from "./DateSortFilterPill";
import { LanguageSortFilterPill } from "./LanguageSortFilterPill";
import { SortFilterPill } from "./SortFilterPill";
import { SortKeys } from "./SortKeys";
import { SourceTypeSortFilterPill } from "./SourceTypeSortFilterPill";

const FilterPillErrorComponent = title => {
  return (
    <SortFilterPill {...{ title }}>
      <div>
        Sorry there was an error constructing this filter, try refreshing the
        page or using another browser
      </div>
    </SortFilterPill>
  );
};

const FilterErrorBoundary = props => {
  const { children, title } = props;
  return (
    <ErrorBoundary
      FallbackComponent={FilterPillErrorComponent(title)}
      onError={e => console.error("Error showing filter", { title, e })}
    >
      {children}
    </ErrorBoundary>
  );
};

export const SortFilterBar = memo(props => {
  const { captureError } = useObservability();
  const {
    isOnlyRisk,
    resetAll,
    canReset,
    showBroaderRisk,
    setShowBroaderRisk,
    riskCategories,
    organisations,
    organisationsToUse,
    setOrganisationsToUse,
    people,
    peopleToUse,
    setPeopleToUse,
    locations,
    locationsToUse,
    setLocationsToUse,
    dates,
    dateBounds,
    setDateBounds,
    potentialDates,
    showOnlyDatedContent,
    setShowOnlyDatedContent,
    languages,
    languagesToUse,
    setLanguagesToUse,
    potentialSourceCount,
    sortKey,
    setSortKey,
    confidencePresent,
    sourceTypes,
    sourceTypesToUse,
    setSourceTypesToUse,
    directSourcesOnly,
    setDirectSourcesOnly,
    indirectSourcesOnly,
    setIndirectSourcesOnly,
    // overallAssessment, setOverallAssessment,
    sourceGroups,
    isDisregarded,
    isNotProcessed
  } = props;

  const pressShowBroaderRiskToggle = () => {
    setShowBroaderRisk(!showBroaderRisk);
  };

  const pressSortByConfidenceToggle = () => {
    if (sortKey === SortKeys.Confidence) {
      setSortKey(SortKeys.OriginalOrder);
    } else {
      setSortKey(SortKeys.Confidence);
    }
  };
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;

  const [userVerifications, setUserVerifications] = useContext(
    UserVerificationContext
  );
  const distinctAssessments = [
    ...new Set(
      sourceGroups.flatMap(g =>
        g.sourceStacks.flatMap(stack =>
          stack.sources.map(s =>
            userVerifications && userVerifications.has(s.sourceId)
              ? userVerifications.get(s.sourceId)
              : s.userAssessment || AssessmentOptions.NoUserAssessment
          )
        )
      )
    )
  ];

  const overallAssessment =
    distinctAssessments.length === 1
      ? distinctAssessments[0]
      : AssessmentOptions.NoUserAssessment;
  const setOverallAssessment = useMemo(
    () => userAssessment => {
      const allSourceIds = sourceGroups.flatMap(g =>
        g.sourceStacks.flatMap(stack => stack.sources.map(s => s.sourceId))
      );
      const newUserVerifications = new Map([
        ...userVerifications,
        ...allSourceIds.map(id => [id, userAssessment])
      ]);
      setUserVerifications(newUserVerifications);
    },
    // TODO: include missing dependencies
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [sourceGroups]
  );

  const directSourceFiltering =
    useFeatureEnabled(Features.FilterTangentialSources) && !isDisregarded;

  if (
    !(riskCategories && riskCategories.size) &&
    !(people && people.size) &&
    !(organisations && organisations.size) &&
    !(locations && locations.size)
  ) {
    return null;
  }

  try {
    return (
      <div className="sort-filter-bar">
        <div className="sort-filter-bar-row">
          <RiskSortFilterPill {...props} pillType="risk" />
          {/* {
        !isOnlyRisk ? <div className="risk-toggle-container toggle-filter">
            Show only risk
            <Toggle
              className="risk-toggle"
              checked={showOnlyRisk}
              icons={false}
              onChange={pressShowRiskToggle}
              value="yes"
            />
          </div> : ''
        } */}
          {dates && potentialDates && potentialDates.length ? (
            <FilterErrorBoundary title="Date">
              <DateSortFilterPill
                {...{
                  dates,
                  dateBounds,
                  setDateBounds,
                  showOnlyDatedContent,
                  setShowOnlyDatedContent,
                  potentialSourceCount,
                  potentialDates
                }}
              />
            </FilterErrorBoundary>
          ) : (
            ""
          )}
          {sourceTypes && sourceTypes.children && sourceTypes.children.size ? (
            <FilterErrorBoundary title="Source">
              <SourceTypeSortFilterPill
                {...{ sourceTypes, sourceTypesToUse, setSourceTypesToUse }}
              />
            </FilterErrorBoundary>
          ) : (
            ""
          )}
          {people && people.size ? (
            <FilterErrorBoundary title="People">
              <EntitySortFilterPill
                title="People"
                entityPlural="people"
                entities={people}
                entitiesToUse={peopleToUse}
                setEntitiesToUse={setPeopleToUse}
              />
            </FilterErrorBoundary>
          ) : (
            ""
          )}
          {locations && locations.size ? (
            <FilterErrorBoundary title="Places">
              <EntitySortFilterPill
                title="Places"
                entityPlural="places"
                entities={locations}
                entitiesToUse={locationsToUse}
                setEntitiesToUse={setLocationsToUse}
              />
            </FilterErrorBoundary>
          ) : (
            ""
          )}
          {organisations && organisations.size ? (
            <FilterErrorBoundary title="Organisations">
              <EntitySortFilterPill
                title="Organisations"
                entityPlural="organisations"
                entities={organisations}
                entitiesToUse={organisationsToUse}
                setEntitiesToUse={setOrganisationsToUse}
              />
            </FilterErrorBoundary>
          ) : (
            ""
          )}
          {languages && languages.size ? (
            <FilterErrorBoundary title="Language">
              <LanguageSortFilterPill
                {...{ languages, languagesToUse, setLanguagesToUse }}
              />
            </FilterErrorBoundary>
          ) : (
            ""
          )}
          <button
            type="button"
            className="sort-filter-reset-all-button"
            onClick={resetAll}
            disabled={!canReset}
          >
            Reset all
          </button>
        </div>
        <div className="sort-filter-bar-row">
          <div className="sort-filter-bar-toggles-group">
            {isOnlyRisk ? (
              <div className="sort-filter-bar-toggle-container toggle-filter">
                <ReactTooltip tooltip="Show all articles containing risk terms or only those directly associated with the subject">
                  {/* TODO: use a button element */}
                  {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                  <a>Direct risk only</a>
                </ReactTooltip>
                <Toggle
                  className="toggle-filter-inner"
                  checked={!showBroaderRisk}
                  icons={false}
                  onChange={pressShowBroaderRiskToggle}
                  value="yes"
                />
              </div>
            ) : (
              ""
            )}
            {directSourceFiltering ? (
              <div className="sort-filter-bar-toggle-container toggle-filter">
                Must include subject
                <Toggle
                  className="toggle-filter-inner"
                  checked={directSourcesOnly}
                  icons={false}
                  onChange={() => setDirectSourcesOnly(!directSourcesOnly)}
                  value="yes"
                />
              </div>
            ) : (
              ""
            )}
            {directSourceFiltering && diagnosticsModeEnabled ? (
              <div className="sort-filter-bar-toggle-container toggle-filter">
                Must not include subject
                <Toggle
                  className="toggle-filter-inner"
                  checked={indirectSourcesOnly}
                  icons={false}
                  onChange={() => setIndirectSourcesOnly(!indirectSourcesOnly)}
                  value="yes"
                />
              </div>
            ) : (
              ""
            )}
            {!(isOnlyRisk || isNotProcessed) &&
            confidencePresent &&
            diagnosticsModeEnabled ? (
              <div className="sort-filter-bar-toggle-container toggle-filter">
                Order by confidence
                <Toggle
                  className="toggle-filter-inner"
                  checked={sortKey === SortKeys.Confidence}
                  icons={false}
                  onChange={pressSortByConfidenceToggle}
                  value="yes"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {diagnosticsModeEnabled ? (
            <AssessmentSelectionControl
              userAssessment={overallAssessment}
              onUserAssessment={setOverallAssessment}
            />
          ) : (
            false
          )}
        </div>
      </div>
    );
  } catch (e) {
    captureError(e);
    console.error("Error rendering Sort and Filter Bar", e);
    return (
      <div>Could not display sort and filter control bar due to an error</div>
    );
  }
});
