import React, { FC, ReactNode } from "react";
import { motion } from "framer-motion";

const DeleteAnimationWrapper: FC<{
  children: ReactNode;
  isReordering?: boolean;
}> = ({ children, isReordering }) => {
  return (
    <motion.div
      exit={{ opacity: 0, height: 0 }}
      transition={{
        opacity: { duration: 0.5, delay: isReordering ? 0 : 1 },
        height: { duration: 0.5, delay: isReordering ? 0.5 : 1.5 }
      }}
      initial={{ opacity: 1, height: "auto" }}
      animate={{ opacity: 1, height: "auto" }}
    >
      {children}
    </motion.div>
  );
};

export default DeleteAnimationWrapper;
