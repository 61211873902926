import React, { ReactNode, createContext, useContext } from "react";

declare const APP_VERSION: string | undefined;

// Try to get the version from Env, failing that we should have it from package.json, failing that fallback to 0.0.0
// (so we don't break the entire U.I.)
const appVersion: string =
  process.env.REACT_APP_VERSION ||
  (typeof APP_VERSION !== "undefined" ? APP_VERSION : "0.0.0");
export class Version {
  major: number;

  minor: number;

  patch: number;

  preRelease: string | null;

  buildMetadata: string | null;

  constructor(
    major: number,
    minor: number,
    patch: number,
    preRelease: string | null = null,
    buildMetadata: string | null = null
  ) {
    this.major = major;
    this.minor = minor;
    this.patch = patch;
    this.preRelease = preRelease;
    this.buildMetadata = buildMetadata;
    Object.freeze(this);
  }

  get display() {
    let version = `${this.major}.${this.minor}.${this.patch}`;
    if (this.preRelease) version += `-${this.preRelease}`;
    if (this.buildMetadata) version += `.${this.buildMetadata}`;
    return version;
  }

  compareTo(version: Version) {
    if (this.major !== version.major)
      return this.major > version.major ? 1 : -1;
    if (this.minor !== version.minor)
      return this.minor > version.minor ? 1 : -1;
    if (this.patch !== version.patch)
      return this.patch > version.patch ? 1 : -1;
    // Pre-release versions have lower precedence than the associated normal version
    if (this.preRelease && !version.preRelease) return -1;
    if (!this.preRelease && version.preRelease) return 1;
    if (this.preRelease && version.preRelease)
      return this.preRelease.localeCompare(version.preRelease);
    return 0; // Versions are equal or only differ by build metadata which does not participate in precedence
  }

  get isCurrent() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return this.compareTo(currentVersion) === 0;
  }

  toString() {
    return this.display;
  }

  isAfterOrEqualTo(b: Version) {
    return this.compareTo(b) >= 0;
  }
}

const parseVersion = (versionString: string): Version => {
  const regex =
    /^v?(\d+)\.(\d+)\.(\d+)(?:-([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?(?:\+([0-9A-Za-z-]+(?:\.[0-9A-Za-z-]+)*))?$/;

  const match = regex.exec(versionString);
  if (!match) throw new Error("Invalid version format");
  const [, major, minor, patch, preRelease, buildMetadata] = match;
  return new Version(
    parseInt(major, 10),
    parseInt(minor, 10),
    parseInt(patch, 10),
    preRelease || null,
    buildMetadata || null
  );
};

export const currentVersion = parseVersion(appVersion);

// If we can get the previous version - this could be handled gracefully with SemVer
// We have a special banner we want to show on the frontend when showing old reports, but only when the report
// is old enough to be missing a significant feature (e.g. doesn't have screening data, or doesn't have it in
// the right format). Every minor release is too often: we usually haven't actually changed the data. The key
// impact of the banner we want to avoid is the fact it forces us to immediately re-run all our demo reports.
// There might be some more sophisticated way of tracking these special versions (maybe it's a matter of
// sem-ver for the frontend?) but I'm not going to build anything too complicated right now off the back of a fag
// packet.
export const significantChangeVersions = [new Version(2, 0, 0)];

const VersionContext = createContext<Version | null>(null);

export const VersionContextProvider = ({
  version,
  children
}: {
  version: Version;
  children: ReactNode;
}) => (
  <VersionContext.Provider value={version}>{children}</VersionContext.Provider>
);

export const useVersion = () => useContext(VersionContext);
