import { styled } from "@linaria/react";
import { grey } from "styles/colors";

const DiagnosticsModeHeader = styled.div`
  position: absolute;
  z-index: 1;
  color: ${grey.dark};
`;

const S = {
  DiagnosticsModeHeader
};

export default S;
