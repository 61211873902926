import { styled } from "@linaria/react";
import { grey } from "styles/colors";
import { family, fontSize, headingLetterSpacing } from "styles/fonts";

const Wrapper = styled.div`
  display: flex;
  width: 124px;
  min-height: 60px;
  flex-direction: column;
  justify-content: center;
`;

const Label = styled.div`
  color: ${grey.dark};
  text-align: center;
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  letter-spacing: ${headingLetterSpacing.md};
`;

const SecondaryLabel = styled.div`
  color: ${grey.mid};
  text-align: center;
  font-size: ${fontSize["2xs"]};
`;

const S = {
  Wrapper,
  Label,
  SecondaryLabel
};

export default S;
