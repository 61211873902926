import React from "react";

import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

const MemberListItemSkeleton = () => {
  return (
    <S.SharePermissionItemSkeletonContainer>
      <Skeleton variant="circular" width={48} height={48} />
      <S.SharePermissionItemSkeletonContent>
        <Skeleton width={120} height={22} />
        <Skeleton width={100} height={16} />
      </S.SharePermissionItemSkeletonContent>
    </S.SharePermissionItemSkeletonContainer>
  );
};

export default MemberListItemSkeleton;
