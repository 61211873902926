import {
  Idam_Contracts_Enums_OrganisationRole,
  Idam_Contracts_Tenants_OrganisationUser
} from "api/portal";

export enum TagId {
  GroupMember = "groupMember",
  GroupRole = "groupRole",
  ResearchType = "researchType",
  SharedGroup = "sharedGroup",
  SharingStatus = "sharingStatus",
  UserRole = "userRole"
}

export interface Tag {
  id: TagId;
  name: string;
}

export interface UserPermissions {
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canViewReports: boolean;
}

export interface User {
  id: string;
  title: string;
  jobTitle?: string;
  tags: Tag[];
  role?: Idam_Contracts_Enums_OrganisationRole;
  permissions: UserPermissions;
  resource: Idam_Contracts_Tenants_OrganisationUser;
}

export enum InviteResultType {
  InviteSuccess = "inviteSuccess",
  InviteFailure = "inviteFailure"
}

export interface InviteSuccess {
  type: InviteResultType.InviteSuccess;
}

export interface InviteFailed {
  type: InviteResultType.InviteFailure;
  message?: string;
}

export type InviteResult = InviteSuccess | InviteFailed;

export enum RegistrationResultType {
  RegistrationSuccess = "registrationSuccess",
  RegistrationFailure = "registrationFailure"
}

export interface RegistrationSuccess {
  type: RegistrationResultType.RegistrationSuccess;
}

export interface RegistrationFailed {
  type: RegistrationResultType.RegistrationFailure;
  message?: string;
}

export type RegistrationResult = RegistrationSuccess | RegistrationFailed;
