import { styled } from "@linaria/react";

import { blue } from "styles/colors";
import { fontSize } from "styles/fonts";
import { Link as RouterLink } from "react-router-dom";

import BrandedModalHeader from "components/molecules/BrandedModalHeader";

const ModalTitle = styled.h1`
  color: ${blue.blueDiamond};
  font-size: ${fontSize.lg};
  font-weight: 600;
  line-height: 1.625rem;
  margin: 0;
`;

const ModalHeader = styled(BrandedModalHeader)`
  width: 100%;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const Subtitle = styled.h2`
  margin: 0;
  font-size: ${fontSize.xl};
  line-height: 2rem;
`;

const Link = styled(RouterLink)`
  color: ${blue.blueDiamond};
  text-decoration: none;
`;

const S = {
  ModalTitle,
  ModalHeader,
  ModalContent,
  Subtitle,
  Link
};

export default S;
