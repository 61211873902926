import React, { FC, SVGProps, useId, useState } from "react";
import { User as UserIcon } from "react-feather";
import { Spinner } from "reactstrap";
import { AnimatePresence } from "framer-motion";

import TitleCard from "components/molecules/TitleCard";

import { useUserActions } from "util/hooks/useUserActions";
import ModalContainer from "components/molecules/ModalContainer";
import Heading from "components/atoms/Heading";
import { RadioButton } from "components/atoms/RadioButton";
import { SearchResult } from "api/search";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import { useHubAuthentication } from "util/hooks/useHubAuthentication";
import { HubAuthenticationStatus } from "util/hooks/useHubAuthentication/types";

import SelectUserInput from "./SelectUserInput";
import SelectHubUserInput from "./SelectHubUserInput";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  userId: string;
  userName: string;
  jobTitle?: string;
  deleteSupportUser?: boolean;
}

const TRANSFER_REPORTS = "transfer-reports";
const DELETE_REPORTS = "delete-reports";

const DeleteUserModal = ({
  isOpen,
  toggleOpen,
  userId,
  userName,
  jobTitle,
  deleteSupportUser
}: Props) => {
  const { deleteUser, fetching, error, errorMessage, resetAll } =
    useUserActions();

  const transferOwnershipId = useId();
  const deleteReportsId = useId();
  const dataLossAcknowledgementId = useId();

  const [handleReportOption, setHandleReportOption] =
    useState(TRANSFER_REPORTS);
  const [selectedSuggestion, setSelectedSuggestion] = useState<
    SearchResult | undefined
  >();
  const [dataLossAcknowledged, setDataLossAcknowledged] = useState(false);

  const {
    state: { status: hubAuthenticationStatus }
  } = useHubAuthentication();
  const isHub =
    hubAuthenticationStatus === HubAuthenticationStatus.authenticated;

  const onToggleOpen = () => {
    if (isOpen) {
      resetAll();
      toggleOpen();
    }
  };

  const onDeleteUser = async (id: string, transferUserId?: string) => {
    const status = await deleteUser({
      userId: id,
      transferUserId,
      dataLossAcknowledged
    });
    if (status) {
      toggleOpen();
    }
  };

  if (deleteSupportUser) {
    return (
      <ModalContainer
        title="Delete user?"
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        onExitClick={toggleOpen}
      >
        <Heading level={6}>You are about to delete the following user</Heading>
        <TitleCard title={userName} subtitle={jobTitle} icon={<UserIcon />} />
        <div>
          <Heading level={6}>
            Warning: you are about to delete the Xapien Support User.
          </Heading>
          <S.WarningText>
            We use this account to help you manage and administer your account.
            Please feel free to delete this user but be aware that it means
            Xapien Support will no longer be able to easily assist you with your
            account.
          </S.WarningText>
        </div>
        <S.Actions>
          <ButtonNew
            text="Cancel"
            size={ButtonSize.Medium}
            type={ButtonType.Outlined}
            onClick={toggleOpen}
          />
          <ButtonNew
            text="Delete user"
            size={ButtonSize.Medium}
            type={ButtonType.Filled}
            disabled={fetching}
            IconTrailing={
              fetching
                ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                : undefined
            }
            onClick={() => onDeleteUser(userId)}
          />
        </S.Actions>
        {error && (
          <ErrorBanner
            text={
              errorMessage || "Something went wrong. Try again in a moment."
            }
          />
        )}
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      title="Delete user?"
      isOpen={isOpen}
      toggleOpen={onToggleOpen}
      onExitClick={onToggleOpen}
    >
      <Heading level={6}>You are about to delete the following user</Heading>
      <TitleCard title={userName} subtitle={jobTitle} icon={<UserIcon />} />
      <S.MainContent>
        <S.HandleReportsOptions>
          <Heading level={6}>
            Choose what happens to the reports this user owns
          </Heading>
          <S.RadioGroup>
            <RadioButton
              id={transferOwnershipId}
              value={TRANSFER_REPORTS}
              checked={TRANSFER_REPORTS === handleReportOption}
              onChange={() => {
                setDataLossAcknowledged(false);
                setHandleReportOption(TRANSFER_REPORTS);
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={transferOwnershipId}>Transfer ownership</label>
          </S.RadioGroup>
          <S.RadioGroup>
            <RadioButton
              id={deleteReportsId}
              value={DELETE_REPORTS}
              checked={DELETE_REPORTS === handleReportOption}
              onChange={() => {
                setSelectedSuggestion(undefined);
                setHandleReportOption(DELETE_REPORTS);
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={deleteReportsId}>Delete reports</label>
          </S.RadioGroup>
        </S.HandleReportsOptions>
        <AnimatePresence exitBeforeEnter initial={false}>
          {handleReportOption === TRANSFER_REPORTS ? (
            <S.TransferReports
              key="transfer-reports"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, height: 88 }}
              exit={{ opacity: 0, height: 24 }}
              transition={{
                duration: 0.3,
                height: { duration: 0.5, delay: 0.1 }
              }}
            >
              <Heading level={6}>
                Choose a user to transfer ownership to
              </Heading>
              {isHub ? (
                <SelectHubUserInput
                  selectedSuggestion={selectedSuggestion}
                  setSelectedSuggestion={setSelectedSuggestion}
                />
              ) : (
                <SelectUserInput
                  selectedSuggestion={selectedSuggestion}
                  setSelectedSuggestion={setSelectedSuggestion}
                />
              )}
            </S.TransferReports>
          ) : (
            <S.DeleteReports
              key="delete-reports"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, height: 24 }}
              exit={{ opacity: 0, height: 88 }}
              transition={{
                duration: 0.3,
                height: { duration: 0.5, delay: 0.1 }
              }}
            >
              <S.Checkbox
                type="checkbox"
                id={dataLossAcknowledgementId}
                checked={dataLossAcknowledged}
                onChange={() => setDataLossAcknowledged(prev => !prev)}
                onKeyDown={e =>
                  e.key === "Enter" && setDataLossAcknowledged(prev => !prev)
                }
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor={dataLossAcknowledgementId}>
                I understand this user&apos;s report data will be lost
              </label>
            </S.DeleteReports>
          )}
        </AnimatePresence>
        <S.FooterExplainer>
          This action cannot be undone. Users will be notified by email.
        </S.FooterExplainer>
      </S.MainContent>
      <S.Actions>
        <ButtonNew
          text="Cancel"
          size={ButtonSize.Medium}
          type={ButtonType.Outlined}
          onClick={toggleOpen}
        />
        <ButtonNew
          text="Delete user"
          size={ButtonSize.Medium}
          type={ButtonType.Filled}
          disabled={
            (!dataLossAcknowledged && handleReportOption === DELETE_REPORTS) ||
            (!selectedSuggestion && handleReportOption === TRANSFER_REPORTS) ||
            fetching
          }
          IconTrailing={
            fetching
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : undefined
          }
          onClick={() => onDeleteUser(userId, selectedSuggestion?.id)}
        />
      </S.Actions>
      {error && (
        <ErrorBanner
          text={errorMessage || "Something went wrong. Try again in a moment."}
        />
      )}
    </ModalContainer>
  );
};

export default DeleteUserModal;
