import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";

import SwitchItemComponent from "components/atoms/ContentSwitch/SwitchItem";

import { ReactComponent as RecentFirstIconSvg } from "img/icons/recent-first-icon.svg";
import { ReactComponent as RecentLastIconSvg } from "img/icons/recent-last-icon.svg";
import { Calendar } from "react-feather";

const RecentFirstIcon = styled(RecentFirstIconSvg)`
  width: 16px;
  height: 16px;
`;

const RecentLastIcon = styled(RecentLastIconSvg)`
  width: 16px;
  height: 16px;
`;

const GridViewIcon = styled(Calendar)`
  width: 12px;
  height: 12px;
`;
const ListViewIcon = styled(Calendar)`
  width: 12px;
  height: 12px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const Heading = styled.div`
  flex: 1;
`;

const CreateItem = styled.div`
  flex: 1;
`;

const SwitchItem = styled(SwitchItemComponent)`
  font-size: ${fontSize.xs};
`;

const SwitchItemContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

const S = {
  Container,
  Heading,
  CreateItem,
  SwitchItem,
  GridViewIcon,
  ListViewIcon,
  SwitchItemContent,
  RecentFirstIcon,
  RecentLastIcon
};

export default S;
