import { styled } from "@linaria/react";

import { green } from "styles/colors";

import { MoreVertical } from "react-feather";

const MainMenuContainer = styled.div`
  margin-left: auto;
`;

const MenuControl = styled(MoreVertical)`
  cursor: pointer;
  height: 26px;

  &:hover {
    color: ${green.xapienGreen};
  }
`;

const S = {
  MainMenuContainer,
  MenuControl
};

export default S;
