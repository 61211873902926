import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";
import { grey } from "styles/colors";

const Container = styled.div`
  width: 365px;
  padding: 4px;
  text-align: left;
  font-size: ${fontSize.sm};
`;

const Header = styled.div`
  border-bottom: 1px solid ${grey.rule};
  font-family: ${family.interSemiBold};
  line-height: 24px;
`;

const Content = styled.div`
  padding-top: 8px;
`;

const UncertaintyLabel = styled.div`
  color: ${grey.mid};
  padding-bottom: 6px;
  font-family: ${family.interRegular};
`;

const ActiveStatus = styled.span`
  color: ${grey.mid};
  font-family: ${family.interRegular};
`;

const S = {
  Container,
  Header,
  Content,
  UncertaintyLabel,
  ActiveStatus
};

export default S;
