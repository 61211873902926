import { SCREENING_LIST_TYPES } from "util/screeningUtils";
import { REPORT_TYPES } from "util/reportTypes";

/**
 *
 * @param {string} listType - screening risk type, e.g. sanction, watchlist, or pep
 * @param {number} count - number of screening hits
 * @param {string} entityType - organisation or person
 */
export const getConfirmedExplainerText = (listType, count, isRCA) => {
  const isPlural = count !== 1;

  if (listType === SCREENING_LIST_TYPES.sanctions) {
    if (!count) {
      return (
        <div>
          We have <strong>not</strong> identified your subject as{" "}
          <strong>sanctioned</strong>.
        </div>
      );
    }

    return (
      <span>
        We have <strong>not</strong> identified your subject as{" "}
        <strong>sanctioned</strong>, however, they are{" "}
        <strong>related or closely associated</strong> to{" "}
        <strong>
          {count} individual
          {isPlural ? "s" : ""}
        </strong>{" "}
        who {isPlural ? "are" : "is"}.
      </span>
    );
  } else if (listType === SCREENING_LIST_TYPES.watchlists) {
    if (!count) {
      return (
        <span>
          We have <strong>not</strong> identified your subject on any{" "}
          <strong>watchlists</strong>.
        </span>
      );
    }

    return (
      <span>
        We have <strong>not</strong> identified your subject on any{" "}
        <strong>watchlists</strong>, however, they are{" "}
        <strong>related or closely associated</strong> to{" "}
        <strong>
          {count} individual
          {isPlural ? "s" : ""}
        </strong>{" "}
        who {isPlural ? "are" : "is"}.
      </span>
    );
  } else if (listType === SCREENING_LIST_TYPES.peps) {
    if (!count) {
      return (
        <span>
          We have <strong>not</strong> identified your subject as a{" "}
          <strong>Politically Exposed Person (PEP)</strong>.
        </span>
      );
    }

    return (
      <span>
        We have <strong>not</strong> identified your subject as a{" "}
        <strong>Politically Exposed Person (PEP)</strong>, however, they are{" "}
        <strong>related or closely associated</strong> to{" "}
        <strong>
          {count} individual
          {isPlural ? "s" : ""}
        </strong>{" "}
        who {isPlural ? "are" : "is"}.
      </span>
    );
  } else if (listType === SCREENING_LIST_TYPES.specialInterest) {
    if (!count) {
      return (
        <span>
          We have <strong>not</strong> identified your subject as of{" "}
          <strong>Special Interest</strong>.
        </span>
      );
    }

    if (isRCA) {
      return (
        <span>
          We have <strong>not</strong> identified your subject as of{" "}
          <strong>Special Interest</strong>, however, they are{" "}
          <strong>related or closely associated</strong> to{" "}
          <strong>
            {count} individual
            {isPlural ? "s" : ""}
          </strong>{" "}
          who {isPlural ? "are" : "is"}.
        </span>
      );
    }

    return (
      <span>
        We have identified your subject as of <strong>Special Interest</strong>.
      </span>
    );
  }
};

/**
 *
 * @param {string} listType - screening risk type, e.g. sanction, watchlist, or pep
 * @param {number} count - number of screening hits
 * @param {string} entityType - organisation or person
 */
export const getForReviewExplainerText = (
  listType,
  count,
  entityType,
  hasConfirmedMatches
) => {
  const isPlural = count !== 1;

  let entityTypeString = entityType.toLowerCase();

  if (isPlural) {
    if (entityType === REPORT_TYPES.person) {
      entityTypeString = "people";
    } else if (entityType === REPORT_TYPES.organisation) {
      entityTypeString = "organisations";
    }
  }

  let prefixText = "Xapien also identified";

  // If there is no confirmed matches for the screening risk, the prefix text requires
  // this small change in structure.
  if (!hasConfirmedMatches) {
    prefixText = "However, Xapien identified";
  }

  if (listType === SCREENING_LIST_TYPES.sanctions) {
    return (
      <span>
        {prefixText}{" "}
        <strong>
          {count} sanctioned {entityTypeString}
        </strong>{" "}
        with{" "}
        <strong>{isPlural ? "names" : "a name"} similar to your subject</strong>
        , where there was{" "}
        <strong>
          insufficient information to confirm or discard them as your subject
        </strong>
        . You may wish to review these.
      </span>
    );
  } else if (listType === SCREENING_LIST_TYPES.watchlists) {
    return (
      <span>
        {prefixText}{" "}
        <strong>
          {count} {entityTypeString}
        </strong>{" "}
        with{" "}
        <strong>{isPlural ? "names" : "a name"} similar to your subject</strong>{" "}
        on watchlists, where there was{" "}
        <strong>
          insufficient information to confirm or discard them as your subject
        </strong>
        . You may wish to review these.
      </span>
    );
  } else if (listType === SCREENING_LIST_TYPES.peps) {
    return (
      <span>
        {prefixText}{" "}
        <strong>
          {count} {entityTypeString}
        </strong>{" "}
        with{" "}
        <strong>
          {isPlural ? "names" : "a name"} similar to your subject{" "}
          {isPlural ? "who are PEPs" : "who is a PEP"}
        </strong>
        , where there was{" "}
        <strong>
          insufficient information to confirm or discard them as your subject
        </strong>
        . You may wish to review these.
      </span>
    );
  } else if (listType === SCREENING_LIST_TYPES.specialInterest) {
    return (
      <span>
        {prefixText}{" "}
        <strong>
          {count} {entityTypeString}
        </strong>{" "}
        of <strong>Special Interest</strong> with{" "}
        <strong>{isPlural ? "names" : "a name"} similar to your subject</strong>
        , where there was{" "}
        <strong>
          insufficient information to confirm or discard them as your subject
        </strong>
        . You may wish to review these.
      </span>
    );
  }
};
