import React from "react";

const gridColors = [
  "rgba(222,223,227, 1.0)",
  "rgba(222, 223, 224, 0.4)",
  "rgba(255,255,255,0.1)"
];
const textColors = ["#2a2850", "white", "white"];

const StatusCell = ({ item }) => {
  const gridColorIndex = item ? (item.isDone ? 0 : item.isCurrent ? 1 : 2) : 2;

  return (
    <div
      className="status-cell"
      style={{
        backgroundColor: gridColors[gridColorIndex]
      }}
    >
      <h2
        style={{ color: item ? textColors[gridColorIndex] : "rgba(0,0,0,0.0)" }}
      >
        {item ? item.stateName : "N/A"}
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          color: textColors[gridColorIndex]
        }}
      >
        <div className="status-cell-table-inner">
          <div>In progress</div>
          <div>{item ? item.numberOfTasksInProgress : "0"}</div>
        </div>
        <div className="status-cell-table-inner">
          <div>Done</div>
          <div>{item ? item.numberOfTasksComplete : "0"}</div>
        </div>
        <div className="status-cell-table-inner">
          <div>In Error</div>
          <div>{item ? item.numberOfTasksInError : "0"}</div>
        </div>
      </div>
    </div>
  );
};

export default StatusCell;
