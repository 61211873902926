import React, { useState } from "react";

import firstSlideImage from "img/landing-carousel-image1.png";
import secondSlideImage from "img/landing-carousel-image2.png";
import thirdSlideImage from "img/landing-carousel-image3.png";
import chevron from "img/icons/carousel/chevron.svg";

import S from "./styles";

const LandingCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      slug: "whatIsIntegrityCheck",
      title: "What is Integrity Check?",
      image: firstSlideImage,
      imageAlignTop: "0%",
      imageAlignLeft: "0%",
      boxAlignTop: "58%",
      boxAlignLeft: "40%",
      description:
        "Integrity Check is an AI-powered due diligence platform that fully automates online research so you can make sound, fact-based judgements."
    },
    {
      slug: "aTirelessResearcher",
      title: "A tireless researcher",
      image: secondSlideImage,
      imageAlignTop: "0%",
      imageAlignLeft: "11%",
      boxAlignTop: "53%",
      boxAlignLeft: "0%",
      description:
        // eslint-disable-next-line max-len
        "Integrity Check examines the entire indexed web, corporate registries, screening data, media, news articles and more, in any language, to give you a detailed due diligence report in minutes."
    },
    {
      slug: "meaningfulInsights",
      title: "Meaningful insights",
      image: thirdSlideImage,
      imageAlignTop: "0%",
      imageAlignLeft: "10%",
      boxAlignTop: "50%",
      boxAlignLeft: "53%",
      description:
        "Our AI and Natural Language Processing engines understand and extract context and intent to deliver you meaningful insights."
    }
  ];

  return (
    <S.CarouselContainer>
      <S.Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        selectedItem={currentSlide}
        onChange={setCurrentSlide}
        useKeyboardArrows
        autoPlay
        interval={6000}
        renderIndicator={() => null}
      >
        {slides.map(
          ({
            slug,
            image,
            title,
            description,
            boxAlignTop,
            boxAlignLeft,
            imageAlignTop,
            imageAlignLeft
          }) => (
            <S.SlideContainer>
              <div key={`LandingCarousel-${slug}`}>
                <S.CarouselImage top={imageAlignTop} left={imageAlignLeft}>
                  <img src={image} alt={image.toString()} />
                </S.CarouselImage>
                <S.BoxContent top={boxAlignTop} left={boxAlignLeft}>
                  <S.Heading level={3}>{title}</S.Heading>
                  <S.Divider />
                  <S.Description>{description}</S.Description>
                  <S.LinkToVideo>
                    <a
                      href="https://vimeo.com/875525007?share=copy"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Watch our video to find out more
                    </a>
                  </S.LinkToVideo>
                </S.BoxContent>
              </div>
            </S.SlideContainer>
          )
        )}
      </S.Carousel>
      <S.ActionButtonContainer>
        <S.ButtonWrapper
          aria-label="button"
          type="button"
          onClick={() => setCurrentSlide(currentSlide - 1)}
        >
          <S.PreviousArrow src={chevron} alt="Chevron Left" />
        </S.ButtonWrapper>
        <S.ButtonWrapper
          aria-label="button"
          type="button"
          onClick={() => setCurrentSlide(currentSlide + 1)}
        >
          <S.NextArrow src={chevron} alt="Chevron Right" />
        </S.ButtonWrapper>
      </S.ActionButtonContainer>
    </S.CarouselContainer>
  );
};

export default LandingCarousel;
