import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const WarningBanner = styled.div`
  margin-top: 8px;
`;

const Header = styled.h1`
  background-color: ${grey.mid};
  color: ${standardColors.white};
  font-size: ${fontSize.lg};
  padding: 5px 16px;
  border-radius: 3px;
  margin-bottom: 1px;
`;

const Body = styled.p`
  background-color: ${grey.panel};
  padding: 8px 16px;
  margin-bottom: 0;
  white-space: pre-line;
`;

const S = {
  WarningBanner,
  Header,
  Body
};

export default S;
