import React from "react";
import { Popper as MuiPopper, PopperProps } from "@mui/material";

const Popper = (props: PopperProps) => {
  return (
    <MuiPopper
      {...props}
      modifiers={[
        {
          name: "offset",
          options: { offset: [0, 15] }
        }
      ]}
    />
  );
};

export default Popper;
