// TODO DJ REMOVE THIS AND FIX BELOW
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useContext, useEffect, Fragment } from "react";

import { AssessmentOptions } from "pages/report/AssessmentOptions";
import { UserVerificationContext } from "util/hooks/useUserVerification";
import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";
import { usePreviousValue } from "util/hooks/usePreviousValue.js";
import SectionFooter from "components/atoms/SectionFooter";

import ResultsWithFilters from "./ResultsWithFilters";
import S from "./styles";

const userAssessmentMapping = {
  confirmed: AssessmentOptions.RightPerson,
  discarded: AssessmentOptions.WrongPerson
};

// Format json to include original assignments
const getAssignmentsWithOriginalConfidence = data => {
  // Deep copy original data and add new field, originalSetConfidence
  const dataCopy = {
    confirmed: [
      ...data.confirmed?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.confirmed,
        currentSetConfidence: CONFIDENCE_CATEGORIES.confirmed
      }))
    ],
    unconfirmed: [
      ...data.unconfirmed?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed,
        currentSetConfidence: CONFIDENCE_CATEGORIES.unconfirmed
      }))
    ],
    discarded: [
      ...data.discarded?.map(hit => ({
        ...hit,
        originalSetConfidence: CONFIDENCE_CATEGORIES.discarded,
        currentSetConfidence: CONFIDENCE_CATEGORIES.discarded
      }))
    ]
  };

  return dataCopy;
};

const ProfessionalOverview = ({
  originalListOfProfessionalInformation,
  employmentHistory,
  isReportRegenerationOpen,
  subjectName
}) => {
  const [selectedConfidenceFilter, setSelectedConfidenceFilter] = useState(
    CONFIDENCE_CATEGORIES.confirmed
  );

  const [listOfProfessionalInformation, setListOfProfessionalInformation] =
    useState(
      getAssignmentsWithOriginalConfidence(
        originalListOfProfessionalInformation
      )
    );

  const [animate, setAnimate] = useState();

  const [userVerifications, setUserVerifications, , setUserVerificationsCount] =
    useContext(UserVerificationContext);
  const assessmentCount = Array.from(userVerifications?.values() ?? [])?.filter(
    value => value !== 0 && value !== AssessmentOptions.NoUserAssessment
  )?.length;
  const prevAssessmentCount = usePreviousValue(assessmentCount);

  const onConfidenceFilterSelected = selectedFilter => {
    setSelectedConfidenceFilter(selectedFilter);
  };

  // Reset back to the original data (before any recent user changes)
  useEffect(() => {
    // If the previous count of assessments was non-zero and is _now_ zero, then we know the
    // assessments have been cleared.
    if (
      prevAssessmentCount !== 0 &&
      prevAssessmentCount !== undefined &&
      assessmentCount === 0
    ) {
      setListOfProfessionalInformation(
        getAssignmentsWithOriginalConfidence(
          originalListOfProfessionalInformation
        )
      ); // Reset
    }
  }, [
    assessmentCount,
    originalListOfProfessionalInformation,
    prevAssessmentCount
  ]);

  // Don't perform any animations when we switch filters or mount the section.
  // Only when animate is set to true, then do we allow framer to carry out animations.
  useEffect(() => {
    if (animate) {
      // Reset
      setTimeout(() => setAnimate(undefined), 1000);
    }
  }, [animate]);

  const onConfidenceChange = (newConfidence, originalConfidence, orgIds) => {
    setAnimate(newConfidence);

    const orgToMoveIndex = listOfProfessionalInformation[
      originalConfidence
    ].findIndex(org => {
      return org.userAssessmentIds?.join() === orgIds?.join();
    });

    const orgToMove =
      listOfProfessionalInformation[originalConfidence][orgToMoveIndex];

    orgToMove.currentSetConfidence = newConfidence;

    // Invoke a re-render
    setListOfProfessionalInformation({ ...listOfProfessionalInformation });

    // Insert regen ids into map and update user changes count
    const newUserVerifications = new Map(userVerifications);
    if (newConfidence !== orgToMove.originalSetConfidence) {
      // Update the user verification for the org (represented by multiple ids) to the new confidence
      orgIds?.forEach(orgId => {
        newUserVerifications.set(orgId, userAssessmentMapping[newConfidence]);
      });
      setUserVerificationsCount(prev => prev + 1);
    } else {
      orgIds?.forEach(orgId => {
        newUserVerifications.set(orgId, 0); // Clear
      });
      setUserVerificationsCount(prev => prev - 1);
    }
    setUserVerifications(newUserVerifications);
  };

  const data = listOfProfessionalInformation[selectedConfidenceFilter];

  const orgsWithDirectorshipRoles = data.filter(profession =>
    profession.listOfRoles.some(role => role.isOfficership)
  );

  return (
    <>
      <S.Panel>
        <ResultsWithFilters
          orgsWithDirectorshipRoles={orgsWithDirectorshipRoles}
          employmentHistory={employmentHistory}
          isReportRegenerationOpen={isReportRegenerationOpen}
          subjectName={subjectName}
          listOfProfessionalInformation={data}
          selectedConfidenceFilter={selectedConfidenceFilter}
          onConfidenceChange={onConfidenceChange}
          onConfidenceFilterSelected={onConfidenceFilterSelected}
          confidenceBucketCounts={{
            confirmed:
              listOfProfessionalInformation[CONFIDENCE_CATEGORIES.confirmed]
                .length,
            unconfirmed:
              listOfProfessionalInformation[CONFIDENCE_CATEGORIES.unconfirmed]
                .length,
            discarded:
              listOfProfessionalInformation[CONFIDENCE_CATEGORIES.discarded]
                .length
          }}
        />
      </S.Panel>
      <SectionFooter />
    </>
  );
};

export default ProfessionalOverview;
