const VALID_PASSWORD_REGEX =
  /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!*@#$%^&+=]).*$/;

const INCLUDES_NUMBER_REGEX = /^.*(?=.*\d).*$/;

const INCLUDES_SYMBOL_REGEX = /^.*(?=.*[!*@#$%^&+=]).*$/;

const LONG_ENOUGH_REGEX = /^.*(?=.{8,}).*$/;

const INCLUDES_UPPERCASE_CHARACTER_REGEX = /^.*(?=.*[A-Z]).*$/;

const INCLUDES_LOWERCASE_CHARACTER_REGEX = /^.*(?=.*[a-z]).*$/;

export enum PasswordState {
  valid = "valid",
  different = "different",
  invalid = "invalid",
  tooShort = "tooShort",
  missingNumber = "missingNumber",
  missingSymbol = "missingSymbol",
  missingUppercaseCharacter = "missingUppercaseCharacter",
  missingLowercaseCharacter = "missingLowercaseCharacter"
}

interface PasswordsState {
  passwordConfirmation: string;
  password: string;
}

export const getInvalidPasswordReason = (passwordState: PasswordState) => {
  switch (passwordState) {
    case PasswordState.tooShort:
      return "Password too short. Must be at least 8 characters";
    case PasswordState.different:
      return "Passwords do not match";
    case PasswordState.missingNumber:
      return "Password is missing a number";
    case PasswordState.missingSymbol:
      return "Password is missing a symbol";
    case PasswordState.missingUppercaseCharacter:
      return "Password is missing an upper case character";
    case PasswordState.missingLowercaseCharacter:
      return "Password is missing a lower case character";
    default:
      return "Password strength: weak";
  }
};

export const getPasswordState = ({
  field,
  value,
  state
}: {
  field: string;
  value: string;
  state: PasswordsState;
}): PasswordState => {
  switch (field) {
    case "password": {
      if (!value.match(LONG_ENOUGH_REGEX)) {
        return PasswordState.tooShort;
      }

      if (!value.match(INCLUDES_NUMBER_REGEX)) {
        return PasswordState.missingNumber;
      }

      if (!value.match(INCLUDES_SYMBOL_REGEX)) {
        return PasswordState.missingSymbol;
      }

      if (!value.match(INCLUDES_UPPERCASE_CHARACTER_REGEX)) {
        return PasswordState.missingUppercaseCharacter;
      }

      if (!value.match(INCLUDES_LOWERCASE_CHARACTER_REGEX)) {
        return PasswordState.missingLowercaseCharacter;
      }

      if (!value.match(VALID_PASSWORD_REGEX)) {
        return PasswordState.invalid;
      }

      if (state.passwordConfirmation !== value) {
        return PasswordState.different;
      }

      return PasswordState.valid;
    }
    case "passwordConfirmation": {
      if (state.password !== value) {
        return PasswordState.different;
      }

      if (!value.match(LONG_ENOUGH_REGEX)) {
        return PasswordState.tooShort;
      }

      if (!value.match(INCLUDES_NUMBER_REGEX)) {
        return PasswordState.missingNumber;
      }

      if (!value.match(INCLUDES_SYMBOL_REGEX)) {
        return PasswordState.missingSymbol;
      }

      if (!value.match(INCLUDES_UPPERCASE_CHARACTER_REGEX)) {
        return PasswordState.missingUppercaseCharacter;
      }

      if (!value.match(INCLUDES_LOWERCASE_CHARACTER_REGEX)) {
        return PasswordState.missingLowercaseCharacter;
      }

      if (!value.match(VALID_PASSWORD_REGEX)) {
        return PasswordState.invalid;
      }

      return PasswordState.valid;
    }
    default: {
      return PasswordState.invalid;
    }
  }
};
