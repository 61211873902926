import React, { ReactNode } from "react";

import Skeleton from "components/atoms/Skeleton";
import Heading from "components/atoms/Heading";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import { CardVariant } from "components/molecules/Card/types";

import S from "./styles";

interface Props {
  title?: string;
  subtitle?: string;
  overview?: ReactNode;
  loading?: boolean;
  icon?: ReactNode;
  variant?: CardVariant;
}

const CardHeader = ({
  title,
  subtitle,
  overview,
  loading,
  variant = CardVariant.default,
  icon
}: Props) => {
  return loading ? (
    <S.Header>
      <S.AvatarContainer>
        <Avatar avatarSize={AvatarSize.Large} loading />
        {overview}
      </S.AvatarContainer>

      <S.TitleContainer variant={variant}>
        <Skeleton width="30%" height="40px" />
        <Skeleton width="100%" />
      </S.TitleContainer>
    </S.Header>
  ) : (
    <S.Header>
      <S.AvatarContainer>
        {icon}
        {overview}
      </S.AvatarContainer>
      <S.TitleContainer variant={variant}>
        <Heading level={5}>{title}</Heading>
        <S.Subtitle variant={variant}>{subtitle}</S.Subtitle>
      </S.TitleContainer>
    </S.Header>
  );
};

export default CardHeader;
