import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import SearchInput from "components/atoms/SearchInput";
import { grey } from "styles/colors";
import { family } from "styles/fonts";

const Input = styled(SearchInput)`
  border-radius: 0;
  background-color: ${grey.panel};
  border: none;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  min-height: 3rem;
  border-radius: 0.375rem;
`;

/** ClassNameOverrides */

const textInput = css`
  font-family: ${family.interRegular};
  vertical-align: middle !important;

  .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiInputBase-input {
    padding: 0 !important;
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
`;

const S = {
  Input
};

export const classNameOverrides = {
  textInput
};

export default S;
