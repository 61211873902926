import { styled } from "@linaria/react";

import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { ReactComponent as ArrowDownSvg } from "img/icons/arrow-down.svg";

import { fontSize } from "styles/fonts";
import { grey, standardColors } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";

const OptionsDropdownToggle = styled(DropdownToggle)`
  border: none !important;
  background-color: transparent !important;
  border-radius: 16px;
  min-width: 176px !important;
  min-height: 28px !important;
  height: 28px !important;
  font-size: ${fontSize.sm} !important;
  color: ${grey.mid} !important;
  text-align: left;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0 0 0 1px ${grey.ghost};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};

  &:hover {
    box-shadow: 0 0 0 1px ${grey.ghost},
      0 0 0 5px
        ${() => {
          const { r, g, b } = hexToRgb(grey.ghost);
          return `rgba(${r}, ${g}, ${b}, 0.33)`;
        }} !important;
  }

  &:focus {
    box-shadow: 0 0 0 1px ${standardColors.white},
      0 0 0 5px
        ${() => {
          const { r, g, b } = hexToRgb(grey.ghost);
          return `rgba(${r}, ${g}, ${b}, 0.33)`;
        }} !important;
  }

  &:active {
    box-shadow: inset 0 0 0 2px ${grey.ghost},
      0 0 0 5px
        ${() => {
          const { r, g, b } = hexToRgb(grey.ghost);
          return `rgba(${r}, ${g}, ${b}, 0.33)`;
        }} !important;
  }

  &:disabled {
    outline: none;
    border-width: 1px;
    opacity: 0.4;
  }
`;

const OptionsDropdownSelectedLabel = styled.div`
  padding-right: 10px;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const OptionsDropdownMenu = styled(DropdownMenu)`
  padding: 0;
  transform: none !important;
  top: 38px !important;
  border-radius: 6px;
  width: 100%;
  max-height: 170px;
  border: none;
  background-color: ${standardColors.white};
  overflow-y: auto;
  box-shadow: 0 0 7px rgb(0 0 0 / 25%);
`;

const ArrowDown = styled(ArrowDownSvg)`
  path {
    fill: ${grey.mid};
  }
`;

const OptionsDropdownItem = styled(DropdownItem)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  font-size: ${fontSize.sm};
`;

const S = {
  OptionsDropdownMenu,
  OptionsDropdownSelectedLabel,
  OptionsDropdownToggle,
  OptionsDropdownItem,
  ArrowDown
};

export default S;
