import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { grey, red } from "styles/colors";
import { fontSize } from "styles/fonts";

const ModalContent = styled.div`
  border-top: 0.5px solid ${grey.faint};
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Text = styled.p`
  margin: 0;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const AutoshareWith = styled.div`
  padding-top: 1rem;
`;

const SharedWithItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 1.5rem;
  max-height: 12.5rem;
  overflow: auto;
`;

const EmptyPlaceholderText = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.rule};
  padding-top: 0.5rem;
`;

/** ClassNameOverrides */

const removeOption = css`
  color: ${red.directRiskOutline};

  & p {
    color: ${red.directRiskOutline};
  }
`;

export const classNameOverrides = {
  removeOption
};

const S = {
  ModalContent,
  Text,
  AutoshareWith,
  SharedWithItemsContainer,
  EmptyPlaceholderText
};

export default S;
