import { ContextType } from "api/enquiry/types";

export enum InputToValidationStages {
  initial = 0,
  inProgress = 1,
  complete = 2
}

export interface CorrectedType {
  index: number;
  suggestedType: ContextType;
}
