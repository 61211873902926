import { useContext } from "react";

import { ShareReportContext } from "./context";

const useShareReport = () => {
  const context = useContext(ShareReportContext);

  if (!context) {
    throw new Error(
      "useShareReport must be used within a ShareReportContextProvider"
    );
  }

  return context;
};

export default useShareReport;
