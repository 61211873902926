import React, { FC, ReactNode } from "react";

import { Outlet } from "react-router-dom";

import ErrorBoundary from "util/ErrorBoundary";
import GlobalErrorScreen from "pages/GlobalErrorScreen";
import { AuthenticationContextProvider } from "util/hooks/useAuthentication";
import useObservability from "util/hooks/useObservability";

const ShellProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AuthenticationContextProvider
      loginPath="/login"
      ssoCallbackPath="/sso/callback"
    >
      {children}
    </AuthenticationContextProvider>
  );
};

const ArticleViewerShell = () => {
  const { captureError } = useObservability();
  return (
    <ErrorBoundary
      FallbackComponent={GlobalErrorScreen}
      onError={(error: unknown, errorInfo: unknown) => {
        console.error(error, errorInfo);
        captureError(error as Error);
      }}
    >
      <ShellProviders>
        <Outlet />
      </ShellProviders>
    </ErrorBoundary>
  );
};

export default ArticleViewerShell;
