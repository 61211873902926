import React from "react";
import { SortFilterPill } from "./SortFilterPill";
import SortFilterCategories from "../../../../components/molecules/SortFilterCategories";
import { DropdownItem } from "reactstrap";

export const LanguageSortFilterPill = props => {
  const { languages, languagesToUse, setLanguagesToUse } = props;
  const canReset = languagesToUse.size > 0;
  const reset = () => setLanguagesToUse(new Set());
  const active = canReset;
  return (
    <SortFilterPill title="Language" {...{ canReset, reset, active }}>
      <div className="sort-filter-dropdown-menu-header">
        <div>In this report:</div>{" "}
        <div>
          {[...(languages.keys() || [])].filter(cat => cat != null).length}{" "}
          languages
        </div>
      </div>
      <DropdownItem divider />
      <SortFilterCategories
        categories={languages}
        categoriesToUse={languagesToUse}
        setCategoriesToUse={setLanguagesToUse}
      />
    </SortFilterPill>
  );
};
