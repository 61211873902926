import { styled } from "@linaria/react";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem;
  gap: 0.5rem;

  input {
    width: 42px;
    text-align: center;
  }
`;

const Spacer = styled.div`
  width: 24px;
  height: 42px;
`;

const S = { Container, Spacer };

export default S;
