import { styled } from "@linaria/react";
import { motion } from "framer-motion";

import BaseMenu from "components/molecules/Menu";
import ButtonNew from "components/atoms/ButtonNew";
import { blue, grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Menu = styled(BaseMenu)`
  overflow-y: scroll;
  max-height: 190px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 34.375rem;
  overflow: auto;
`;

const Text = styled.p`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
  line-height: 1.375rem;
  margin: 0;
`;

const CustomButton = styled(ButtonNew)`
  align-self: flex-start;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 4.8125rem;
`;

const Field = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: ${grey.panel};
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSize.md};
  line-height: 24px;
  color: ${grey.mid};
  padding: 12px;
`;

const FieldLabel = styled.div`
  color: ${standardColors.white};
  font-size: ${fontSize.sm};
`;

const ResetPassword = styled.button`
  all: unset;
  color: ${blue.icon};
  font-size: ${fontSize.sm};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:not(:focus-visible) {
    outline: none;
  }
`;

const ResetPasswordText = styled(motion.p)`
  margin: 0;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const ResetPasswordSentText = styled(ResetPasswordText)`
  color: ${blue.xapienLight};
`;

const OptionsButton = styled.button`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: ${fontSize.sm} !important;
  line-height: 1.125rem;
  all: unset;
  white-space: nowrap;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const S = {
  ModalContent,
  Text,
  CustomButton,
  Form,
  FieldContainer,
  Field,
  FieldLabel,
  ResetPassword,
  ResetPasswordText,
  ResetPasswordSentText,
  Menu,
  OptionsButton
};

export default S;
