import React, { useEffect, useMemo, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ChevronLeft } from "react-feather";

import { CollectionSearchContextProvider } from "util/hooks/useCollectionSearch";
import {
  CollectionListActions,
  CollectionListContextProvider,
  useCollectionList
} from "util/hooks/useCollectionList";

import CollectionSearch from "components/organisms/CollectionSearch";
import CollectionList from "components/organisms/CollectionList";
import ReportCard from "components/organisms/ReportCard";
import { formatGroupName } from "api/reports/utils";
import { CollectionInputType } from "util/hooks/useCollectionList/types";
import Heading from "components/atoms/Heading";
import GroupsApi from "api/groups";
import Skeleton from "components/atoms/Skeleton";
import Api, { GROUP_REPORTS_COLLECTION_ID } from "api/groupReports";

import S from "./styles";

const GroupReportsListing = () => {
  const { state, dispatch } = useCollectionList();
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const queryGroupName = searchParams.get("name");
  const queryGroupDescription = searchParams.get("description");
  const GroupApi = useMemo(() => new GroupsApi(), []);
  const [groupName, setGroupName] = useState<string | null>(queryGroupName);
  const [groupDescription, setGroupDescription] = useState<string | null>(
    queryGroupDescription
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getGroupDetails = async () => {
      setLoading(true);
      const details = await GroupApi.getGroupDetails(groupId!);
      setGroupName(details?.title ?? "");
      setGroupDescription(details?.description ?? "");
      setLoading(false);
    };

    // If we can pull straight from the query params, this will provide us with immediate rendering, else if we can't
    // fetch from endpoint
    if (!groupName) {
      getGroupDetails();
    }
  }, [GroupApi, groupId, groupName]);

  if (groupId) {
    const collectionInputConfig = state.collections.find(
      collection => collection.id === GROUP_REPORTS_COLLECTION_ID
    );

    if (
      collectionInputConfig &&
      collectionInputConfig.input.type === CollectionInputType.list &&
      collectionInputConfig.input?.filterByGroupId === undefined
    ) {
      // Hacky... We want to delay this dispatch to prevent chasing state else,
      // on mount, the collection list provider will trigger the first fetch of items. Within the same window,
      // we'll need to update the input field of the collection (to use as a param in the query).
      // The first fetch will complete, writing the status of the collection to be "complete"
      // and therefore preventing the refetch of the items with the new input param.
      setTimeout(
        () =>
          dispatch({
            type: CollectionListActions.updateCollectionInput,
            id: GROUP_REPORTS_COLLECTION_ID,
            input: {
              ...collectionInputConfig.input,
              filterByGroupId: groupId
            }
          }),
        100
      );
    }
  }

  return (
    <AnimatePresence>
      <S.ViewContainer>
        <S.ViewInnerContainer>
          <S.Breadcrumb
            onClick={() => {
              navigate(`/groups`);
            }}
          >
            <ChevronLeft />
            Back to My groups
          </S.Breadcrumb>
          <CollectionSearch
            placeholder={`Search ${
              groupName ? formatGroupName(groupName) : ""
            }`}
            filterByGroupId={groupId}
          />
          <CollectionList
            showCount
            CardComponent={ReportCard}
            navigationComponent={
              loading ? (
                <Skeleton width={150} height={38} />
              ) : (
                <Heading level={4}>{formatGroupName(groupName)}</Heading>
              )
            }
            createItemComponent={
              loading ? (
                <Skeleton width={200} height={28} />
              ) : (
                <p>{groupDescription ?? ""}</p>
              )
            }
            emptyCollectionText="No reports"
          />
        </S.ViewInnerContainer>
      </S.ViewContainer>
    </AnimatePresence>
  );
};

const GroupReports = () => {
  return (
    <CollectionSearchContextProvider Api={Api}>
      <CollectionListContextProvider Api={Api}>
        <GroupReportsListing />
      </CollectionListContextProvider>
    </CollectionSearchContextProvider>
  );
};

export default GroupReports;
