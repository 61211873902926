import React, { FC, ReactNode, useEffect, useMemo, useReducer } from "react";
import Organisations from "api/organisations";
import { useAuthentication } from "util/hooks/useAuthentication";
import useHubAdminRole from "util/hooks/useHubAdminRole";

import {
  UserOrganisationContext,
  initialState,
  userOrganisationReducer
} from "./context";
import { UserOrganisationActions } from "./types";
import { AuthenticationStatus } from "../useAuthentication/types";

interface Props {
  children: ReactNode;
}

export const UserOrganisationProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(userOrganisationReducer, initialState);

  // Use this to reset the organisation details when someone "enters" an organisation
  const { isAuthenticatedAsHubAdmin } = useHubAdminRole();

  const { descriptionToSave, saving } = state;

  const {
    state: { status: authStatus }
  } = useAuthentication();

  const api = useMemo(() => new Organisations(), []);

  useEffect(() => {
    dispatch({ type: UserOrganisationActions.resetOrganisationDetails });
  }, [authStatus]);

  // initial details fetch
  useEffect(() => {
    const fetch = async () => {
      const result = await api.getUserOrganisationDetails();
      const users = await api.getOrganisationUsers();
      dispatch({
        type: UserOrganisationActions.detailsLoaded,
        details: result,
        users
      });
    };

    if (authStatus === AuthenticationStatus.authenticated) {
      fetch();
    }
  }, [dispatch, api, isAuthenticatedAsHubAdmin, authStatus]);

  useEffect(() => {
    const saveDescription = async () => {
      const { status, message } = await api.setUserOrganisationDetails({
        description: descriptionToSave ?? ""
      });

      if (!status) {
        dispatch({
          type: UserOrganisationActions.updateFailed,
          message:
            message ??
            "There was an issue updating the description. Try again in a moment."
        });
      } else {
        dispatch({
          type: UserOrganisationActions.detailsSaved,
          description: descriptionToSave ?? ""
        });
      }
    };

    if (descriptionToSave !== undefined) {
      saveDescription();
    }
  }, [api, descriptionToSave, saving]);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <UserOrganisationContext.Provider value={contextValue}>
      {children}
    </UserOrganisationContext.Provider>
  );
};
