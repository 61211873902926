import React from "react";
import LoginModal from "components/molecules/LoginModal";
import { AnimatePresence, motion } from "framer-motion";
import LandingPage from "components/molecules/LandingPage";

const Login = () => {
  const isDX = process.env.REACT_APP_TENANT === "projectdx";
  return (
    <>
      {!isDX ? (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoginModal isOpen />
          </motion.div>
        </AnimatePresence>
      ) : (
        <LandingPage />
      )}
    </>
  );
};

export default Login;
