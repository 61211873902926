import { styled } from "@linaria/react";

import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const ViewERButtonContainer = styled.div`
  height: 100%;
  width: 28px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ViewERButton = styled.span`
  width: auto;
  white-space: nowrap;
  justify-content: space-between;
  font-size: ${fontSize.sm};
  opacity: 1 !important;
  height: 28px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 3px;
  border: 1px solid ${grey.rule};
  border-radius: 2px;

  svg {
    height: 20px;
    width: 20px;
  }
`;

const S = {
  ViewERButton,
  ViewERButtonContainer
};

export default S;
