import {
  CollectionListState,
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType
} from "util/hooks/useCollectionList/types";
import { CollectionListView } from "components/molecules/CollectionListControls";
import { IdentityTenantApiService } from "api/portal";
import { apm } from "@elastic/apm-rum";

import type { Organisation } from "./types";

export type { Organisation };

export default class Organisations {
  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: "organisations",
          title: "Organisations",
          limit: COLLECTION_ITEMS_PER_PAGE,
          totalItemCount: 0,
          offset: 0,
          order: "desc",
          view: CollectionListView.list,
          pollingEnabled: true,
          items: [],
          status: CollectionStatus.stale,
          hidden: false,
          hiddenIfEmpty: false,
          input: {
            type: CollectionInputType.list
          }
        }
      ]
    };
  }

  async list({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    limit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    offset
  }: {
    id: string;
    limit: number;
    offset: number;
  }): Promise<{ items: Organisation[]; totalItemCount: number }> {
    try {
      const response = await IdentityTenantApiService.getTenantsOrganisations();

      const items = response.map(
        ({ organisationId, description, name, reportsRunPast30Days }) => ({
          organisationId: organisationId ?? "unknown",
          id: organisationId ?? "unknown",
          icon: "",
          tags: [],
          permissions: { canView: true, canEdit: true, canDelete: true },
          title: name ?? "unknown",
          name: name ?? "unknown",
          description: description ?? "",
          usage: {
            reportsRunPast30Days: reportsRunPast30Days ?? 0,
            // TODO: These aren't coming back from the endpoint yet, but we don't need them in the UI
            reportsRunPast7Days: 0,
            reportsRunPastYear: 0,
            reportsRunSinceOrgCreated: 0
          }
        })
      );

      return {
        items,
        totalItemCount: items.length
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async search() {
    return { items: [], totalItemCount: 0 };
  }
}
