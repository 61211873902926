import React, { useState, useEffect, FC, ReactEventHandler } from "react";

import useIntersect from "util/hooks/useIntersect";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { useImageKeyLookup } from "util/hooks/useImageKeyLookup";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import { doesTenantProhibitImages } from "util/doesTenantProhibitImages";
import { FrontendImage } from "api/report/report-types";

import S from "./styles";

export interface ImageProps {
  image: FrontendImage | string;
  fallbackImage?: string;
  className?: string;
  alt?: string;
  onError?: ReactEventHandler<HTMLImageElement>;
  small?: boolean;
  imageSizeToUse?: ImageSizeToUse;
  lazyLoad?: boolean;
  onImageLoaded?: () => void;
  width?: string;
  height?: string;
  blockImage?: boolean;
}

const Image: FC<ImageProps> = ({
  image,
  fallbackImage,
  className,
  alt,
  onError,
  small,
  imageSizeToUse = ImageSizeToUse.Original,
  lazyLoad = true,
  onImageLoaded = () => {},
  width,
  height,
  blockImage
}) => {
  const { imageKey, src } = (image as FrontendImage) || {};
  const printModeEnabled = usePrintModeEnabled();
  const [showImage, updateShowImage] = useState(false);
  const [ref, entry] = useIntersect({
    threshold: Array.from(Array(100).keys(), i => i / 100)
  });
  const [hasImageLoaded, setHasImageLoaded] = useState(false);
  const [hasImageErrored, setHasImageErrored] = useState(false);

  const show =
    (entry?.intersectionRatio ?? 0) > 0 || printModeEnabled || !lazyLoad; // show for any intersection

  useEffect(() => {
    // we must load the image before the signed url becomes invalid - something like a day from load until that happens
    // loads the image if not yet loaded and time has passed
    // a random aspect to the time to avoid them all loading at once
    // always well within the signed url lifetime
    const backGroundLoadWait = (10 + 100 * Math.random()) * 1000;
    setTimeout(() => {
      updateShowImage(true);
    }, backGroundLoadWait);
  }, []);

  useEffect(() => {
    if (show && !showImage) {
      updateShowImage(true);
    }
  }, [show, showImage]);

  const { getSrc } = useImageKeyLookup();
  let imageSrc;
  if (!image) {
    imageSrc = fallbackImage;
  } else if (src && src.startsWith("data:")) {
    imageSrc = getSrc(src);
  } else {
    imageSrc = showImage
      ? getSrc({
          src,
          imageKey,
          imageSizeToUse:
            imageSizeToUse && imageSizeToUse !== ImageSizeToUse.Original
              ? imageSizeToUse
              : small && ImageSizeToUse.Small
        })
      : fallbackImage;
  }

  return (
    <S.ImageContainer width={width} height={height}>
      <S.ImageInnerContainer hasImageLoaded={hasImageLoaded}>
        <img
          className={className}
          alt={alt}
          ref={ref}
          src={blockImage ? "" : imageSrc} // Force the fetch to fail if we're blocking images
          onError={(e: any) => {
            if (hasImageErrored) {
              // If we've errored whilst trying to fetch
              // the fallback image then give up. Avoids an
              // infinite loop.
              return;
            }

            e.target.onerror = null;
            setHasImageErrored(true);
            if (fallbackImage && fallbackImage.length) {
              e.target.src = fallbackImage;
            }
            if (onError) {
              onError(e);
            }
          }}
          onLoad={() => {
            setHasImageLoaded(true);
            onImageLoaded();
          }}
        />
      </S.ImageInnerContainer>
      {!hasImageLoaded && !hasImageErrored && (
        <S.SpinnerContainer>
          <S.Spinner />
        </S.SpinnerContainer>
      )}
    </S.ImageContainer>
  );
};

export const DiImage = (props: ImageProps & { isCopyrighted?: boolean }) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (doesTenantProhibitImages() && !props.isCopyrighted) {
    return <Image {...props} blockImage />;
  }

  return <Image {...props} />;
};
