import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { usePrintableReportState } from "util/hooks/usePrintableState";
import { red, grey, orange } from "styles/colors";

import S from "./styles";

const RISK_OVER_TIME_CHART_ID = "risk-overtime-chart-element";
const UNDATED_RISK_CHART_ID = "undated-risk-chart-element";

const DATE_GRANULARITY_LEVELS = {
  year: "year",
  month: "month",
  day: "day",
  hour: "hour"
};

const MIN_INTERVAL_PADDING_THRESHOLD = 7;

const RiskOverTimeChart = ({
  data = [],
  undatedData,
  onDateRangeChange = () => {},
  isFilterActive
}) => {
  const riskOverTimeChartRef = useRef(null);
  const riskOverTimeDateAxisRef = useRef(null);
  const riskUndatedChartRef = useRef(null);
  const [cursorRange, setCursorRange] = useState({});
  const [dateGranularity, setDateGranularity] = usePrintableReportState(
    "web-and-media-risk-over-time-granularity",
    DATE_GRANULARITY_LEVELS.year
  );
  const [isBrushActive, setIsBrushActive] = useState(false);

  // Required to avoid amchart's callbacks using stale state.
  const dateGranularityRef = useRef();
  dateGranularityRef.current = dateGranularity;

  const isYearGranularity = granularity => {
    return granularity === DATE_GRANULARITY_LEVELS.year;
  };

  const isMonthGranularity = granularity => {
    return granularity === DATE_GRANULARITY_LEVELS.month;
  };

  const isDayGranularity = granularity => {
    return granularity === DATE_GRANULARITY_LEVELS.day;
  };

  const getNextGranularity = currentGranularity => {
    switch (currentGranularity) {
      case DATE_GRANULARITY_LEVELS.year:
        return DATE_GRANULARITY_LEVELS.month;
      case DATE_GRANULARITY_LEVELS.month:
        return DATE_GRANULARITY_LEVELS.day;
      case DATE_GRANULARITY_LEVELS.day:
        return DATE_GRANULARITY_LEVELS.day;
      default:
        return DATE_GRANULARITY_LEVELS.year;
    }
  };

  const getGranularity = (startDate, endDate) => {
    const isAxisDisplayingYears = isYearGranularity(dateGranularity);
    const isAxisDisplayingMonths = isMonthGranularity(dateGranularity);
    const isAxisDisplayingDays = isDayGranularity(dateGranularity);

    if (isAxisDisplayingMonths) {
      const differenceInTime = Math.abs(startDate - endDate);
      const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

      // Only go down to days if there are no more than 120
      return differenceInDays <= 120
        ? DATE_GRANULARITY_LEVELS.day
        : DATE_GRANULARITY_LEVELS.month;
    }

    if (isAxisDisplayingYears) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      const differenceInMonths = Math.abs(endYear - startYear) * 12;

      // Only go down to months if there are no more than 48
      return differenceInMonths <= 48
        ? DATE_GRANULARITY_LEVELS.month
        : DATE_GRANULARITY_LEVELS.year;
    }

    if (isAxisDisplayingDays) {
      return DATE_GRANULARITY_LEVELS.day;
    }

    return undefined;
  };

  const createRange = (from, to, label) => {
    const range = riskOverTimeDateAxisRef.current.axisRanges.create();
    range.date = from;
    range.endDate = to;
    range.label.text = label;
    range.label.paddingTop = 35;

    range.label.location = 0.5;
    range.label.horizontalCenter = "middle";
    range.grid.disabled = true;
  };

  const computeNumberOfBars = chartData => {
    const isAxisDisplayingYears = isYearGranularity(dateGranularity);
    const isAxisDisplayingMonths = isMonthGranularity(dateGranularity);
    const isAxisDisplayingDays = isDayGranularity(dateGranularity);
    const numberOfGranularityIntervals = new Set();

    chartData.forEach(d => {
      if (isAxisDisplayingYears) {
        numberOfGranularityIntervals.add(d.date.getFullYear());
      } else if (isAxisDisplayingMonths) {
        numberOfGranularityIntervals.add(
          `${d.date.getMonth()}-${d.date.getFullYear()}`
        );
      } else if (isAxisDisplayingDays) {
        numberOfGranularityIntervals.add(
          `${d.date.getMonth()}-${d.date.getFullYear()}-${d.date.getDate()}`
        );
      }
    });

    return numberOfGranularityIntervals;
  };

  /**
   * Pads the provided data array in proportion to the current granularity.
   * @param {object[]} dataToPad an array of data that will be padded
   * @returns padded array of data items
   */
  const padDataRange = dataToPad => {
    const paddedData = [...dataToPad];
    const isAxisDisplayingYears = isYearGranularity(dateGranularity);
    const isAxisDisplayingMonths = isMonthGranularity(dateGranularity);
    const isAxisDisplayingDays = isDayGranularity(dateGranularity);

    // If we're showing months and it's possible to pad between
    // the start and end values then do so. This results in a more
    // natural padding as it maintains the data's boundaries.
    if (isAxisDisplayingMonths && paddedData.length > 1) {
      const dateTo = paddedData[paddedData.length - 1].date;
      const dateFrom = paddedData[0].date;
      const differenceInMonths =
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear()) -
        1;

      const nextMonth = new Date(dateFrom);

      if (differenceInMonths > 0) {
        for (let i = 0; i < differenceInMonths; i += 1) {
          nextMonth.setMonth(nextMonth.getMonth() + 1);
          paddedData.push({
            directRisk: 0,
            indirectRisk: 0,
            noRisk: 0,
            date: new Date(nextMonth)
          });
        }

        paddedData.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }

          return 1;
        });
      }
    } else if (isAxisDisplayingDays && paddedData.length > 1) {
      const dateTo = paddedData[paddedData.length - 1].date;
      const dateFrom = paddedData[0].date;
      const differenceInTime = Math.abs(dateFrom - dateTo);
      const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24) - 1;

      const nextDay = new Date(dateFrom);

      if (differenceInDays > 0) {
        for (let i = 0; i < differenceInDays; i += 1) {
          nextDay.setDate(nextDay.getDate() + 1);
          paddedData.push({
            directRisk: 0,
            indirectRisk: 0,
            noRisk: 0,
            date: new Date(nextDay)
          });
        }

        paddedData.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }

          return 1;
        });
      }
    }

    // We need to work out how many bars will be displayed. It's not enough
    // to just count the number of data items as grouping may be applied.
    const numberOfGranularityIntervals = computeNumberOfBars(paddedData);

    const numberToPadBeforeSeries = Math.ceil(
      (10 - numberOfGranularityIntervals.size) / 2
    );
    const numberToPadAfterSeries = Math.floor(
      (10 - numberOfGranularityIntervals.size) / 2
    );

    // If even after attempting to pad in between the data, we're still
    // below the threshold of bars then pad both sides of the data.
    if (numberOfGranularityIntervals.size < MIN_INTERVAL_PADDING_THRESHOLD) {
      let date;
      let copyOfDate;
      for (let i = 0; i < numberToPadBeforeSeries; i += 1) {
        date = paddedData[0].date;
        copyOfDate = new Date(date.getTime());
        if (isAxisDisplayingYears) {
          copyOfDate.setFullYear(copyOfDate.getFullYear() - 1);
        } else if (isAxisDisplayingMonths) {
          copyOfDate.setMonth(copyOfDate.getMonth() - 1);
        } else if (isAxisDisplayingDays) {
          copyOfDate.setDate(copyOfDate.getDate() - 1);
        }
        paddedData.unshift({
          directRisk: 0,
          indirectRisk: 0,
          noRisk: 0,
          date: copyOfDate
        });
      }

      for (let i = 0; i < numberToPadAfterSeries; i += 1) {
        date = paddedData[paddedData.length - 1].date;
        copyOfDate = new Date(date.getTime());
        if (isAxisDisplayingYears) {
          copyOfDate.setFullYear(copyOfDate.getFullYear() + 1);
        } else if (isAxisDisplayingMonths) {
          copyOfDate.setMonth(copyOfDate.getMonth() + 1);
        } else if (isAxisDisplayingDays) {
          copyOfDate.setDate(copyOfDate.getDate() + 1);
        }
        paddedData.push({
          directRisk: 0,
          indirectRisk: 0,
          noRisk: 0,
          date: copyOfDate
        });
      }
    }

    return paddedData;
  };

  const computeDateRangeChange = useRef();
  computeDateRangeChange.current = () => {
    const isAxisDisplayingDays = isDayGranularity(dateGranularity);
    const isAxisDisplayingMonths = isMonthGranularity(dateGranularity);
    const isAxisDisplayingYears = isYearGranularity(dateGranularity);

    const bounds = { ...cursorRange };
    const startDate = bounds.start;
    const endDate = bounds.end;
    let snappedStartDate = bounds.start;
    let snappedEndDate = bounds.end;
    const granularity = getGranularity(startDate, endDate);

    if (isAxisDisplayingYears) {
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();

      snappedStartDate = new Date(startYear, 0, 1);
      snappedEndDate = new Date(endYear, 11, 31, 23, 59, 59);
    } else if (isAxisDisplayingMonths) {
      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();
      const daysForEndMonth = new Date(endYear, endMonth + 1, 0).getDate();

      snappedStartDate = new Date(startYear, startMonth, 0);
      snappedEndDate = new Date(endYear, endMonth, daysForEndMonth, 23, 59, 59);
    } else if (isAxisDisplayingDays) {
      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();
      const endYear = endDate.getFullYear();
      const endMonth = endDate.getMonth();
      const startDay = startDate.getDate();
      const endDay = endDate.getDate();

      snappedStartDate = new Date(startYear, startMonth, startDay);
      snappedEndDate = new Date(endYear, endMonth, endDay, 23, 59, 59);
    }

    setDateGranularity(granularity);
    setIsBrushActive(true);
    onDateRangeChange({
      startDate: snappedStartDate,
      endDate: snappedEndDate
    });
  };

  const onUndatedSeriesClick = e => {
    const category = e.target?.dataItem?.component?.dataFields?.valueY;
    onDateRangeChange({
      riskDirection: category
    });
  };

  const onTimeSeriesClick = e => {
    const date = e.target?.dataItem?.dateX;

    let minDate;
    let maxDate;

    const nextGranularity = getNextGranularity(dateGranularityRef.current);
    const willAxisDisplayMonthsNext = isMonthGranularity(nextGranularity);
    const willAxisDisplayDaysNext = isDayGranularity(nextGranularity);
    const isAxisCurrentlyDisplayingDays = isDayGranularity(
      dateGranularityRef.current
    );

    if (isAxisCurrentlyDisplayingDays) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      minDate = new Date(year, month, day, 0);
      maxDate = new Date(year, month, day, 23, 59, 59);
    } else if (willAxisDisplayMonthsNext) {
      const year = date.getFullYear();
      minDate = new Date(year, 0, 1);
      maxDate = new Date(year, 11, 31);
    } else if (willAxisDisplayDaysNext) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const daysForSelectedMonth = new Date(year, month + 1, 0).getDate();
      minDate = new Date(year, month, 1);
      maxDate = new Date(year, month, daysForSelectedMonth);
    }

    setDateGranularity(nextGranularity);
    setIsBrushActive(false);
    onDateRangeChange({
      startDate: minDate,
      endDate: maxDate
    });
  };

  const createSeries = (
    field,
    name,
    color,
    onSeriesClick,
    isUndated = false
  ) => {
    // Set up series
    const chartRef = isUndated ? riskUndatedChartRef : riskOverTimeChartRef;
    const series = new am4charts.ColumnSeries();
    series.name = name;
    series.dataFields.valueY = field;
    series.dataFields.dateX = "date";
    series.dataFields.categoryX = "year";
    series.sequencedInterpolation = true;
    series.groupFields.valueY = "sum";
    series.columns.template.events.on("hit", onSeriesClick);

    series.tooltip.label.adapter.add("text", (text, target) => {
      const results = {};

      let hoveredColumnIndex = -1;

      // Find the index of the hovered column
      chartRef.current.series.values.some(chartRefSeries => {
        const hoveredItemIndex = chartRefSeries.dataItems.values.findIndex(
          value => value?.column?.isHover
        );

        hoveredColumnIndex = hoveredItemIndex;

        return hoveredItemIndex > -1;
      });

      // Using the hovered column index, extract the values for that column
      // for each series.
      chartRef.current.series.values.forEach(chartRefSeries => {
        const chartRefField = chartRefSeries?.dataFields?.valueY;
        const valueY =
          chartRefSeries.dataItems.values[hoveredColumnIndex]?.valueY;

        results[chartRefField] = valueY;
      });

      const date = target.dataItem.dateX;
      const isAxisDisplayingYears = isYearGranularity(
        dateGranularityRef.current
      );
      const isAxisDisplayingMonths = isMonthGranularity(
        dateGranularityRef.current
      );
      const isAxisDisplayingDays = isDayGranularity(dateGranularityRef.current);
      let dateForGranularityLevel;

      if (date) {
        const month = date.toLocaleString("default", {
          month: "short"
        });
        const year = date.getFullYear();
        const day = date.getDate();
        if (isAxisDisplayingDays) {
          dateForGranularityLevel = `${day} ${month} ${year}`;
        } else if (isAxisDisplayingMonths) {
          dateForGranularityLevel = `${month} ${year}`;
        } else if (isAxisDisplayingYears) {
          dateForGranularityLevel = year;
        }
      }

      return `[font-size:13px fill:${grey.mid}]${
        isUndated ? "No date" : dateForGranularityLevel
      }\n[font-size:13px fill:${grey.ghost}]No risk: ${
        results.noRisk
      }\n[font-size:13px fill:${orange.indirectRiskFill}]Indirect risk: ${
        results.indirectRisk
      }\n[font-size:13px fill:${red.directRiskFill}]Direct risk: ${
        results.directRisk
      }`;
    });

    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    // Make it stacked
    series.stacked = true;

    // Configure columns
    series.columns.template.width = am4core.percent(95);
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#FFFFFF");
    series.tooltip.autoTextColor = false;
    series.columns.template.tooltipText = "[font-size:13px]{name} | {valueY}";
    series.tooltip.background.fillOpacity = 1;
    series.columns.template.fill = am4core.color(color);
    series.strokeWidth = 0;
    series.columns.template.tooltipY = am4core.percent(30);

    if (isUndated) {
      series.tooltip.pointerOrientation = "left";
    }

    return series;
  };

  const createMonthRanges = transformedData => {
    const intervals = [];
    transformedData.forEach(transformedDataItem => {
      const itemDate = transformedDataItem.date;
      const year = itemDate.getFullYear();

      if (!intervals.includes(year)) {
        intervals.push(year);
      }
    });

    const results = [];
    intervals.forEach((interval, index, array) => {
      results.push(interval);
      if (index < array.length - 1) {
        const firstItemYear = interval;
        const secondItemYear = array[index + 1];
        const differenceInYears = secondItemYear - firstItemYear - 1;
        const nextYear = new Date(firstItemYear, 0, 1);

        if (differenceInYears > 0) {
          for (let i = 0; i < differenceInYears; i += 1) {
            nextYear.setFullYear(nextYear.getFullYear() + 1);
            results.push(nextYear.getFullYear());
          }
        }
      }
    });

    const ranges = {};
    results.forEach(r => {
      const itemYear = r;
      const relevantItems = transformedData.filter(transformedDataItem => {
        const itemDate = transformedDataItem.date;
        const year = itemDate.getFullYear();

        return Number(itemYear) === year;
      });

      if (relevantItems.length) {
        relevantItems.forEach(item => {
          const itemDate = item.date;
          const month = itemDate.getMonth();
          const year = itemDate.getFullYear();

          if (!ranges[r]) {
            ranges[r] = [new Date(year, month, 1), new Date(year, month, 1)];
          } else {
            ranges[r][1] = new Date(year, month, 31);
          }
        });
      } else {
        ranges[r] = [new Date(itemYear, 0, 1), new Date(itemYear, 11, 31)];
      }
    });

    Object.keys(ranges).forEach(r => {
      const itemYear = r;
      createRange(ranges[r][0], ranges[r][1], itemYear);
    });
  };

  const createYearRanges = transformedData => {
    const intervals = [];
    transformedData.forEach(transformedDataItem => {
      const itemDate = transformedDataItem.date;
      const month = itemDate.getMonth();
      const year = itemDate.getFullYear();

      if (!intervals.includes(`${year}:${month}`)) {
        intervals.push(`${year}:${month}`);
      }
    });

    const results = [];
    intervals.forEach((interval, index, array) => {
      results.push(interval);
      if (index < array.length - 1) {
        const [firstItemYear, firstItemMonth] = interval.split(":");
        const [secondItemYear, secondItemMonth] = array[index + 1].split(":");

        const dateFrom = new Date(firstItemYear, firstItemMonth);
        const dateTo = new Date(secondItemYear, secondItemMonth);
        const differenceInMonths =
          dateTo.getMonth() -
          dateFrom.getMonth() +
          12 * (dateTo.getFullYear() - dateFrom.getFullYear()) -
          1;
        const nextMonth = new Date(firstItemYear, firstItemMonth, 1);

        if (differenceInMonths > 0) {
          // We have missing values
          // Find the difference in months
          for (let i = 0; i < differenceInMonths; i += 1) {
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            results.push(`${nextMonth.getFullYear()}:${nextMonth.getMonth()}`);
          }
        }
      }
    });

    const ranges = {};
    results.forEach(r => {
      const [itemYear, itemMonth] = r.split(":");
      const relevantItems = transformedData.filter(transformedDataItem => {
        const itemDate = transformedDataItem.date;
        const month = itemDate.getMonth();
        const year = itemDate.getFullYear();

        return Number(itemYear) === year && Number(itemMonth) === month;
      });

      if (relevantItems.length) {
        relevantItems.forEach(item => {
          const itemDate = item.date;
          const month = itemDate.getMonth();
          const year = itemDate.getFullYear();
          const day = itemDate.getDate();

          if (!ranges[r]) {
            ranges[r] = [
              new Date(year, month, day),
              new Date(year, month, day)
            ];
          } else {
            ranges[r][1] = new Date(year, month, day);
          }
        });
      } else {
        // Otherwise assume full expanse?
        ranges[r] = [
          new Date(itemYear, itemMonth, 1),
          new Date(itemYear, itemMonth, 31)
        ];
      }
    });

    Object.keys(ranges).forEach((r, index, array) => {
      const [itemYear, itemMonth] = r.split(":");
      const date = new Date(itemYear, itemMonth);

      // Is there anything either side of me? If so then
      // this range can be its full length. Otherwise,
      // constrain it via the data.
      createRange(
        array[index - 1] ? new Date(itemYear, itemMonth, 1) : ranges[r][0],
        array[index + 1] ? new Date(itemYear, itemMonth, 31) : ranges[r][1],
        `${date.toLocaleString("default", {
          month: "short"
        })} ${date.getFullYear()}`
      );
    });
  };

  const transformData = () => {
    if (data.length === 0) {
      return data;
    }

    const mappedData = data.map(d => ({
      directRisk: d.directRisk,
      indirectRisk: d.indirectRisk,
      noRisk: d.noRisk,
      date: d.year
    }));

    const emptyDataObject = {
      directRisk: 0,
      indirectRisk: 0,
      noRisk: 0
    };
    const isAxisDisplayingYears = isYearGranularity(dateGranularity);
    const isAxisDisplayingMonths = isMonthGranularity(dateGranularity);
    const isAxisDisplayingDays = isDayGranularity(dateGranularity);

    if (!isBrushActive && !isAxisDisplayingYears) {
      let minDate;
      let maxDate;
      if (isAxisDisplayingMonths) {
        // If we're displaying months then be sure to extend the data's bounds
        // to a full calendar year. That way you'll see Jan through to Dec.
        // This of course, doesn't apply to brushing or if we're displaying years
        // as years are unbounded.
        const year = mappedData[0].date.getFullYear();
        minDate = new Date(year, 0, 1);
        maxDate = new Date(year, 11, 31, 23, 59, 59);
      } else if (isAxisDisplayingDays) {
        const year = mappedData[0].date.getFullYear();
        const month = mappedData[0].date.getMonth();
        minDate = new Date(year, month, 1);
        maxDate = new Date(year, month + 1, 0);
      }
      mappedData.unshift({
        ...emptyDataObject,
        date: minDate
      });

      mappedData.push({
        ...emptyDataObject,
        date: maxDate
      });
    }

    const numberOfGranularityIntervals = computeNumberOfBars(mappedData);

    if (numberOfGranularityIntervals.size < MIN_INTERVAL_PADDING_THRESHOLD) {
      const paddedData = padDataRange(mappedData);
      return paddedData;
    }

    return mappedData;
  };

  const constructRiskOverTimeChart = () => {
    // Create chart instance
    const chart = am4core.create(RISK_OVER_TIME_CHART_ID, am4charts.XYChart);
    chart.padding(0, 15, 0, 15);
    // Add data
    const transformedData = transformData();

    chart.data = transformedData;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
    chart.cursor.snapToSeries = [];
    chart.cursor.maxTooltipDistance = -1;
    chart.zoomOutButton.disabled = true;

    let selectInitiatedPoint;

    chart.cursorOverStyle = am4core.MouseCursorStyle.horizontalResize;

    // Create axes
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dataFields.dateX = "date";
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.renderer.labels.template.fontSize = 11;
    dateAxis.renderer.labels.template.fontFamily = "Inter-Light";
    dateAxis.renderer.labels.template.stroke = grey.ghost;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.tooltip.disabled = true;
    dateAxis.groupData = true;
    dateAxis.groupCount = 70;
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");
    dateAxis.periodChangeDateFormats.setKey("day", "dt");
    dateAxis.periodChangeDateFormats.setKey("week", "dt");
    dateAxis.dateFormats.setKey("day", "dt");
    dateAxis.dateFormats.setKey("week", "dt");
    dateAxis.dateFormats.setKey("month", "MMM");

    chart.cursor.events.on("zoomstarted", e => {
      selectInitiatedPoint = dateAxis.positionToDate(
        dateAxis.toAxisPosition(e.target.xPosition)
      );
    });
    chart.cursor.events.on("zoomended", () => {
      selectInitiatedPoint = undefined;
      computeDateRangeChange.current();
    });
    chart.cursor.events.on("cursorpositionchanged", e => {
      if (selectInitiatedPoint) {
        const cursorDate = dateAxis.positionToDate(
          dateAxis.toAxisPosition(Math.min(Math.max(0, e.target.xPosition), 1))
        );
        const cursorDateTime = cursorDate.getTime();
        const initiatedDateTime = selectInitiatedPoint.getTime();
        const start =
          cursorDateTime > initiatedDateTime
            ? selectInitiatedPoint
            : cursorDate;
        const end =
          cursorDateTime > initiatedDateTime
            ? cursorDate
            : selectInitiatedPoint;
        setCursorRange({ start, end });
      }
    });

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.strokeWidth = 0;

    chart.series.push(
      createSeries(
        "directRisk",
        "Direct risk",
        red.directRiskFill,
        onTimeSeriesClick
      )
    );
    chart.series.push(
      createSeries(
        "indirectRisk",
        "Indirect risk",
        orange.indirectRiskFill,
        onTimeSeriesClick
      )
    );
    chart.series.push(
      createSeries("noRisk", "No risk", grey.ghost, onTimeSeriesClick)
    );

    return [chart, dateAxis];
  };

  const constructUndatedRiskChart = () => {
    // Create chart instance
    const chart = am4core.create(UNDATED_RISK_CHART_ID, am4charts.XYChart);
    chart.padding(0, 15, 0, 15);
    // Add data
    const transformedData = undatedData;

    chart.data = transformedData;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeWidth = 0;
    categoryAxis.renderer.labels.template.fontSize = 13;
    categoryAxis.renderer.labels.template.fontFamily = "Inter-Light";
    categoryAxis.renderer.labels.template.stroke = grey.ghost;
    categoryAxis.tooltip.disabled = true;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.strokeWidth = 0;

    chart.series.push(
      createSeries(
        "directRisk",
        "Direct risk",
        red.directRiskFill,
        onUndatedSeriesClick,
        true
      )
    );
    chart.series.push(
      createSeries(
        "indirectRisk",
        "Indirect risk",
        orange.indirectRiskFill,
        onUndatedSeriesClick,
        true
      )
    );
    chart.series.push(
      createSeries("noRisk", "No risk", grey.ghost, onUndatedSeriesClick, true)
    );

    return chart;
  };

  useLayoutEffect(
    () => {
      const [riskOverTimeChart, dateAxis] = constructRiskOverTimeChart();
      const riskUndatedChart = constructUndatedRiskChart();

      riskOverTimeChartRef.current = riskOverTimeChart;
      riskOverTimeDateAxisRef.current = dateAxis;
      riskUndatedChartRef.current = riskUndatedChart;

      return () => {
        riskOverTimeChart.dispose();
        riskUndatedChart.dispose();
      };
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    []
  );

  useLayoutEffect(() => {
    const transformedData = transformData();
    const isAxisDisplayingYears = isYearGranularity(dateGranularity);
    const isAxisDisplayingMonths = isMonthGranularity(dateGranularity);
    const isAxisDisplayingDays = isDayGranularity(dateGranularity);

    riskOverTimeChartRef.current.data = transformedData;
    riskOverTimeDateAxisRef.current.groupIntervals.setAll([
      { timeUnit: DATE_GRANULARITY_LEVELS[dateGranularity], count: 1 }
    ]);

    if (isAxisDisplayingMonths) {
      riskOverTimeDateAxisRef.current.axisRanges.clear();
      riskOverTimeDateAxisRef.current.renderer.labels.template.fontSize = 13;
      riskOverTimeDateAxisRef.current.baseInterval = {
        timeUnit: DATE_GRANULARITY_LEVELS.day,
        count: 1
      };

      createMonthRanges(transformedData);
    } else if (isAxisDisplayingDays) {
      riskOverTimeDateAxisRef.current.axisRanges.clear();
      riskOverTimeDateAxisRef.current.renderer.labels.template.fontSize = 11;
      riskOverTimeDateAxisRef.current.baseInterval = {
        timeUnit: DATE_GRANULARITY_LEVELS.hour,
        count: 1
      };

      createYearRanges(transformedData);
    } else if (isAxisDisplayingYears) {
      riskOverTimeDateAxisRef.current.axisRanges.clear();
      riskOverTimeDateAxisRef.current.renderer.labels.template.fontSize = 13;
      riskOverTimeDateAxisRef.current.baseInterval = {
        timeUnit: DATE_GRANULARITY_LEVELS.month,
        count: 1
      };
    }
    // TODO: Needed to add this when changing fonts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useLayoutEffect(() => {
    riskUndatedChartRef.current.data = undatedData;
  }, [undatedData]);

  useEffect(() => {
    if (!isFilterActive) {
      setDateGranularity(DATE_GRANULARITY_LEVELS.year);
    }
    // TODO: Needed to add this when changing fonts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterActive]);

  return (
    <S.ChartsContainer>
      <S.RiskOverTimeChart
        id={RISK_OVER_TIME_CHART_ID}
        isFullWidth={undatedData.length === 0}
      />
      {undatedData.length > 0 && <S.Divider />}
      <S.UndatedRiskChart
        id={UNDATED_RISK_CHART_ID}
        isHidden={undatedData.length === 0}
      />
    </S.ChartsContainer>
  );
};

export default RiskOverTimeChart;
