import { useContext } from "react";
import RiskSummariesContext from "./context";
import RiskSummariesProvider from "./provider";
import { RiskSummariesStatus } from "./types";

const useRiskSummaries = () => {
  const context = useContext(RiskSummariesContext);
  if (!context) {
    throw new Error("Can't use risk summaries hook outside of the provider");
  }

  return context;
};

export { RiskSummariesProvider, RiskSummariesStatus };
export default useRiskSummaries;
