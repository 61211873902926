import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Heading from "components/atoms/Heading";
import { CardVariant } from "components/molecules/Card/types";
import ReportPersonaCard from "components/organisms/ReportPersonaCard";
import CollectionList from "components/organisms/CollectionList";
import { useParams } from "react-router-dom";
import {
  CollectionInputType,
  CollectionStatus
} from "util/hooks/useCollectionList/types";
import Api, {
  REPORT_PERSONAS_COLLECTION
} from "api/reportPersonas/ReportPersonas";
import {
  CollectionListActions,
  CollectionListContextProvider,
  useCollectionList
} from "util/hooks/useCollectionList";
import S from "./styles";

const ReportPersonaList = () => {
  const { state, dispatch } = useCollectionList();
  const { enquiryId } = useParams();

  useEffect(() => {
    if (enquiryId) {
      const collectionInputConfig = state.collections.find(
        collection => collection.id === REPORT_PERSONAS_COLLECTION
      );

      if (
        collectionInputConfig &&
        collectionInputConfig.status === CollectionStatus.stale &&
        collectionInputConfig.input.type === CollectionInputType.list &&
        collectionInputConfig.input?.filterByEnquiryId === undefined
      ) {
        setTimeout(
          () =>
            dispatch({
              type: CollectionListActions.updateCollectionInput,
              id: REPORT_PERSONAS_COLLECTION,
              input: {
                ...collectionInputConfig.input,
                filterByEnquiryId: enquiryId
              }
            }),
          100
        );
      }
    }
  }, [enquiryId, state.collections, dispatch]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <S.ViewContainer>
          <S.ViewInnerContainer>
            <CollectionList
              CardComponent={ReportPersonaCard}
              cardVariant={CardVariant.dark}
              emptyCollectionText="No Personas"
              navigationComponent={
                <Heading level={5}>Click on a persona to view</Heading>
              }
            />
          </S.ViewInnerContainer>
        </S.ViewContainer>
      </motion.div>
    </AnimatePresence>
  );
};

const ReportPersonas = () => {
  return (
    <CollectionListContextProvider Api={Api}>
      <ReportPersonaList />
    </CollectionListContextProvider>
  );
};

export default ReportPersonas;
