import React, { Fragment, useContext, useState } from "react";
import {
  ControlledMenu as AssertableMenu,
  MenuItem,
  useMenuState
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import Assertion from "components/atoms/Assertion";
import AssertionIcon from "components/atoms/Assertion/AssertionIcon";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import { AssertionsContext } from "util/hooks/useAssertionsContext";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import WithInspector from "components/organisms/WithInspector";
import { AssertionModeContext } from "util/context/AssertionModeContext";

import S from "./styles";

const Assertable = ({ children, template, userComment }) => {
  const assertionModeEnabled = useContext(AssertionModeContext).enabled;
  const enquiryId = useEnquiryId();
  const userId = "ea871632-1192-11ed-923c-0a0d00173269";
  // userId is hard-coded for now (to the id for smoke.tests@xapien.com) until we have implemented proper auth.
  // When an Assertion is made, one of the database columns is "UserId", such that in the future we can filter
  // Assertions by user.
  const { assertions, assertionsStore } = useContext(AssertionsContext);
  const [menuProps, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const testCaseId = assertionsStore.testCaseId;

  if (!template) {
    return <Fragment>{children}</Fragment>;
  }

  const handleContextMenu = e => {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  };

  const optionOnClick = include => async () => {
    const assertion = template.buildAssertion(
      include,
      true,
      testCaseId,
      userId,
      userComment
    );
    await assertionsStore.insertAssertion(assertion, enquiryId);
  };
  const shownAssertions = assertions.filter(assertion =>
    template.recogniseAssertion(assertion.assertion)
  );

  return assertionModeEnabled ? (
    <Fragment>
      <WithInspector
        customContent={shownAssertions.map(assertion => (
          <Assertion assertion={assertion}>
            {assertion.AssertionHumanReadable}
          </Assertion>
        ))}
        dataFormat={INSPECTOR_DATA_FORMATS.custom}
        popoverTitle={"Assertion Viewer"}
        display={"inline"}
      >
        {shownAssertions.map(assertion => (
          <AssertionIcon assertion={assertion} />
        ))}
      </WithInspector>
      <S.Assertable onContextMenu={handleContextMenu}>{children}</S.Assertable>
      <AssertableMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
        portal
      >
        <MenuItem onClick={optionOnClick(true)}>Include</MenuItem>
        <MenuItem onClick={optionOnClick(false)}>Exclude</MenuItem>
      </AssertableMenu>
    </Fragment>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default Assertable;
