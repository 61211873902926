import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { isPDX } from "static-config";
import PdxDiagnosticsModeContext, {
  PdxDiagnosticsModeContextValue
} from "./context";

const PdxDiagnosticsModeProvider: React.FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const [enabled, setEnabled] = useState(false);
  const [primed, setPrimed] = useState(false);

  useEffect(() => {
    if (!isPDX) {
      return;
    }

    const handler = (e: KeyboardEvent) => {
      if (!e.ctrlKey) {
        return;
      }

      if (e.key === "i") {
        setPrimed(true);
      } else if (primed && e.key === "c") {
        setEnabled(true);
      }
    };

    window.addEventListener("keydown", handler);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [primed]);

  const value: PdxDiagnosticsModeContextValue = useMemo(
    () => ({
      enabled
    }),
    [enabled]
  );

  return (
    <PdxDiagnosticsModeContext.Provider value={value}>
      {children}
    </PdxDiagnosticsModeContext.Provider>
  );
};

export default PdxDiagnosticsModeProvider;
