import { styled } from "@linaria/react";

import { purple, grey } from "styles/colors";

const Link = styled.a<{ disabled?: boolean }>`
  color: ${grey.dark};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  text-decoration: none;

  &:hover {
    color: ${purple.mid};
  }
`;

const ConfirmationString = styled.div`
  padding-top: 6px;
`;

const UrlsContainer = styled.ul`
  padding-left: 24px;
  padding-top: 8px;
  margin-bottom: 0;
`;

const UrlItem = styled.li`
  &::marker {
    color: ${grey.mid};
  }
`;

const S = {
  Link,
  ConfirmationString,
  UrlsContainer,
  UrlItem
};

export default S;
