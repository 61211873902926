import React, { useState, useContext } from "react";

import {
  PRINTABLE_STATE_TYPES,
  usePrintableReportState
} from "util/hooks/usePrintableState";
import SanctionedEntityCardContent from "themes/projectdx/ThemedScreening/SanctionedEntityCardContent";
import {
  FILTER_TYPES,
  getEntityTypeImage,
  RELATIVE_OR_CLOSE_ASSOCIATE,
  dJToolTipText
} from "themes/projectdx/ThemedScreening/util";
import EntityCard from "components/molecules/EntityCard";
import DetailedModalView from "themes/projectdx/ThemedScreening/DetailedModalView";
import {
  processFlagHierarchy,
  mapFlagNamesAndOrder
} from "themes/projectdx/ThemedScreening/processFlagHierarchy";
import FlagPill from "themes/projectdx/ThemedScreening/FlagPill";
import { ENTITY_TYPE } from "pages/define/utils";
import InfoIcon from "components/atoms/InfoIcon";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import usePdxDiagnosticsMode from "util/hooks/usePdxDiagnosticsMode";
import { CONFIDENCE_CATEGORIES } from "util/confidenceCategories";

import S, { classNameOverrides } from "./styles";

const flagMapping = {
  Pep: "Politically Exposed Person (PEP)",
  Sanction: "Sanctions Lists",
  SanctionControl: "Sanctions Control & Ownership",
  Watchlist: "Other Official Lists",
  MarijuanaBusiness: "High Risk Factors",
  MoneyServicesBusiness: "High Risk Factors",
  LocationRisk: "Enhanced Country Risk",
  SpecialInterest: "Special Interest",
  StateOwned: "State Owned Company (SOC)",
  RelatedToSanctionedParty: "Sanctions Control & Ownership",
  AdverseMedia: "Adverse Media",
  CloseAssociateOfRiskyEntity: "Relative or Close Associate (RCA)"
};

const EntityList = ({
  entities = [],
  isHoverable = false,
  filterBucketType,
  reportSubjectType,
  reportSubjectName,
  disableNewReportButton,
  associatedEntityProfiles,
  onConfidenceChange
}) => {
  const [isModalOpen, setIsModalOpen] = usePrintableReportState(
    "screening-modal-open",
    false
  );

  const [selectedEntityData, setSelectedEntityData] = useState();

  const [isExpandRequested, setIsExpanded] = usePrintableReportState(
    `screening-${filterBucketType}-expanded}`,
    filterBucketType === FILTER_TYPES.confirmed && entities?.length,
    PRINTABLE_STATE_TYPES.sectionExpand
  );
  const isExpanded =
    isExpandRequested && filterBucketType !== CONFIDENCE_CATEGORIES.discarded;

  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const { enabled: pdxDiagnosticsEnabled } = usePdxDiagnosticsMode();

  const isExpandHeaderDisabled =
    filterBucketType === FILTER_TYPES.disregarded &&
    !diagnosticsModeEnabled &&
    !pdxDiagnosticsEnabled;

  const onCardClick = entity => {
    setSelectedEntityData(entity);
    setIsModalOpen(true);
  };

  // The type of the supplied entities will match that of the report subject's type.
  // Also there is no type on these entity objects, so we have to use this
  // approach.
  const typeOfEntitiesInSuppliedList = reportSubjectType;

  const renderFlagHierarchyPills = ({
    entityFlags,
    isCloseAssociate,
    flagContainerStyles,
    shouldRenderChildFlags = false,
    entityType = ENTITY_TYPE.Organisation
  }) => {
    let flags;

    // TODO: This is temporary before Sam passes me the full flag hierarchy for associated entities
    // Once we get the hierarchy, we can unify this logic.
    if (isCloseAssociate) {
      // TODO: Temporarily filter out adverse media flags until Sam provides the data for the search result cards
      const processedFlags = entityFlags?.filter(
        flag => flag !== "AdverseMedia"
      );
      flags = processedFlags?.map(flag => {
        let flagType = flagMapping[flag];
        // Special Interest comes in two variants depending on the subject type - Entity or Person.
        if (flagType === flagMapping.SpecialInterest) {
          const entityTypeSuffix =
            entityType === ENTITY_TYPE.Person
              ? " Person (SIP)"
              : " Entity (SIE)";
          flagType += entityTypeSuffix;
        }
        return { type: flagType };
      });
    } else {
      flags = entityFlags?.reduce((flagsArr, flag) => {
        const processedFlagHierarchy = processFlagHierarchy(flag);
        // TODO DJ
        // eslint-disable-next-line no-param-reassign
        flagsArr = [...flagsArr, ...processedFlagHierarchy];
        return flagsArr;
      }, []);
    }

    if (!flags?.length) {
      return null;
    }

    // For labelling the state ownership pill. We need to get
    // the current ownership location.
    const stateOwnedLocations = entityFlags
      ?.find(flag => flag.type === "State Owned Company")
      ?.locations?.filter(
        location => location.relationship === "Current Owner"
      );

    const flagsOrdered = mapFlagNamesAndOrder(flags, stateOwnedLocations);

    const flagHierarchy = flagsOrdered
      ?.sort((a, b) => b.isActive - a.isActive)
      ?.map(flag => {
        const isFlagActive = flag?.isActive;
        if (flag.children?.length && shouldRenderChildFlags) {
          return (
            <div key={flag.type}>
              <FlagPill
                type={flag.type}
                isActive={isFlagActive}
                isCloseAssociate={isCloseAssociate}
              />
              <S.RightChevron isActive={isFlagActive} />
              {flag.children.map(child => (
                <S.ChildFlagPill
                  key={child}
                  type={child}
                  isActive={isFlagActive}
                  interactive={false}
                  isCloseAssociate={isCloseAssociate}
                />
              ))}
            </div>
          );
        }

        return (
          <div key={flag.type}>
            <FlagPill
              type={flag.type}
              isActive={isFlagActive}
              isCloseAssociate={isCloseAssociate}
            />
          </div>
        );
      });

    return (
      <S.FlagPillsContainer className={flagContainerStyles}>
        {flagHierarchy}
      </S.FlagPillsContainer>
    );
  };

  const renderExpandHeader = () => {
    let headerText;
    if (filterBucketType === FILTER_TYPES.confirmed) {
      headerText = (
        <div>
          <S.InfoIconContainer>
            <S.ExpandHeaderCountLabel>
              {dJToolTipText.confimed.title}:
            </S.ExpandHeaderCountLabel>{" "}
            {dJToolTipText.confimed.subheading}
            <InfoIcon
              infoContent={
                <>
                  <strong>{dJToolTipText.confimed.title}: </strong>
                  {dJToolTipText.confimed.text}
                </>
              }
            />
          </S.InfoIconContainer>
        </div>
      );
    } else if (filterBucketType === FILTER_TYPES.unconfirmed) {
      headerText = (
        <div>
          <S.InfoIconContainer>
            <S.ExpandHeaderCountLabel>
              {dJToolTipText.unconfirmed.title}:
            </S.ExpandHeaderCountLabel>{" "}
            {dJToolTipText.unconfirmed.subheading}
            <InfoIcon
              infoContent={
                <>
                  <strong>{dJToolTipText.unconfirmed.title}: </strong>
                  {dJToolTipText.unconfirmed.text}
                </>
              }
            />
          </S.InfoIconContainer>
        </div>
      );
    } else if (filterBucketType === FILTER_TYPES.disregarded) {
      headerText = (
        <div>
          <S.DisabledInfoIconContainer>
            <S.ExpandHeaderCountLabel>
              {dJToolTipText.discarded.title}:
            </S.ExpandHeaderCountLabel>{" "}
            {dJToolTipText.discarded.subheading}
            <InfoIcon
              infoContent={
                <>
                  <strong>{dJToolTipText.discarded.title}: </strong>
                  {dJToolTipText.discarded.text}
                </>
              }
            />
          </S.DisabledInfoIconContainer>
        </div>
      );
    } else {
      headerText = (
        <div>
          <S.DisabledInfoIconContainer>
            <S.ExpandHeaderCountLabel>
              {dJToolTipText.userDiscarded.title}:
            </S.ExpandHeaderCountLabel>{" "}
            {dJToolTipText.userDiscarded.subheading}
            <InfoIcon
              infoContent={
                <>
                  <strong>{dJToolTipText.userDiscarded.title}: </strong>
                  {dJToolTipText.userDiscarded.text}
                </>
              }
            />
          </S.DisabledInfoIconContainer>
        </div>
      );
    }

    return (
      <S.ExpandHeader
        isTableEntriesEmpty={
          !entities?.length || filterBucketType === FILTER_TYPES.disregarded
        }
        aria-label={`${
          isExpanded ? "Collapse" : "Expand"
        } ${filterBucketType} entries`}
        aria-expanded={isExpanded}
        aria-controls={`${filterBucketType}-entries`}
        isExpanded={isExpanded}
        onClick={() => entities?.length && setIsExpanded(prev => !prev)}
        disabled={!entities?.length || isExpandHeaderDisabled}
      >
        {headerText}
        <S.ExpandHeaderRightContent
          isDiscarded={filterBucketType === FILTER_TYPES.disregarded}
        >
          <S.ConfidenceHitCount>{entities?.length ?? 0}</S.ConfidenceHitCount>
          <S.Chevron isExpanded={isExpanded} />
        </S.ExpandHeaderRightContent>
      </S.ExpandHeader>
    );
  };

  const renderEntityCards = () => {
    return (
      <>
        {renderExpandHeader()}
        <div hidden={!isExpanded} id={`${filterBucketType}-entries`}>
          {entities.map((entity, index) => {
            return (
              <S.EntityCardContainer
                tabIndex="0"
                key={`${index}_${entity.profileId}`}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    onCardClick(entity);
                  }
                }}
                onClick={() => {
                  onCardClick(entity);
                }}
              >
                <EntityCard
                  customContent={
                    <SanctionedEntityCardContent
                      entity={entity}
                      isListResult
                      renderFlagHierarchyPills={renderFlagHierarchyPills}
                      entityType={typeOfEntitiesInSuppliedList}
                      isRiskViaRelativesOrCloseAssociates={entity?.isRCA}
                      originalSetConfidence={entity.originalSetConfidence}
                      currentSetConfidence={entity.currentSetConfidence}
                      userAssessmentId={entity.userAssessmentId}
                      userAssessment={entity.userAssessment}
                      onConfidenceChange={onConfidenceChange}
                    />
                  }
                  disableInspectorInteraction
                  minHeight={0}
                  imageComponent={
                    <S.EntityImage filterBucketType={filterBucketType}>
                      {getEntityTypeImage(
                        entity?.isRCA
                          ? RELATIVE_OR_CLOSE_ASSOCIATE
                          : typeOfEntitiesInSuppliedList
                      )}
                    </S.EntityImage>
                  }
                  isHoverable={isHoverable}
                  cardStyles={
                    entity.originalSetConfidence !== entity.currentSetConfidence
                      ? classNameOverrides.cardStylesNullified
                      : classNameOverrides.cardStyles
                  }
                  nameToSendToNewReport={entity.entityName}
                  contextToUse={reportSubjectName}
                  name={entity.entityName}
                  reportSubjectType={reportSubjectType}
                  entityType={typeOfEntitiesInSuppliedList}
                  disableNewReportButton={disableNewReportButton}
                />
              </S.EntityCardContainer>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {renderEntityCards()}
      {isModalOpen && (
        <DetailedModalView
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          entityData={selectedEntityData}
          entityType={typeOfEntitiesInSuppliedList}
          filterBucketType={filterBucketType}
          renderFlagHierarchyPills={renderFlagHierarchyPills}
          reportSubjectName={reportSubjectName}
          reportSubjectType={reportSubjectType}
          disableNewReportButton={disableNewReportButton}
          associatedEntityProfiles={associatedEntityProfiles}
        />
      )}
    </>
  );
};

export default EntityList;
