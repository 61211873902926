import React, { ReactNode, useState, useEffect } from "react";

import useInsightsAma from "util/hooks/useInsightsAma";
import { useInsightReport } from "util/hooks/useInsightReport";
import {
  useInsightsTimeline,
  InsightsTimelineStatus
} from "util/hooks/useInsightsTimeline";
import { InsightsAmaStatus } from "util/hooks/useInsightsAma/types";
import { InsightReportStatus } from "util/hooks/useInsightReport/types";

import CloudUploadingGif from "img/icons/status/cloud-uploading.gif";

import S from "./styles";

const SavingIcon = () => (
  <S.SavingIconWrapper>
    <img src={CloudUploadingGif} alt="Saving changes" />
  </S.SavingIconWrapper>
);

interface StatusSetting {
  status: InsightsAmaStatus | InsightReportStatus | string;
  isError: boolean;
  message: string;
  icon: ReactNode;
}

const statusSettings: Record<string, StatusSetting> = {
  savingChanges: {
    status: "savingChanges",
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  errorSaving: {
    status: "errorSaving",
    isError: true,
    message: "Error saving changes",
    icon: <S.ErrorIcon />
  },
  changesSaved: {
    status: "changesSaved",
    isError: false,
    message: "Changes saved",
    icon: <S.SuccessIcon />
  },
  [InsightsTimelineStatus.deletingEvent]: {
    status: InsightsAmaStatus.savingMessage,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  [InsightsTimelineStatus.deletingEventSuccess]: {
    status: InsightsAmaStatus.messageSaved,
    isError: false,
    message: "Changes saved",
    icon: <S.SuccessIcon />
  },
  [InsightsAmaStatus.errorAskingQuestion]: {
    status: InsightsAmaStatus.errorAskingQuestion,
    isError: true,
    message: "Error asking question",
    icon: <S.ErrorIcon />
  },
  [InsightsAmaStatus.errorSavingMessage]: {
    status: InsightsAmaStatus.errorSavingMessage,
    isError: true,
    message: "Error saving changes",
    icon: <S.ErrorIcon />
  },
  [InsightsAmaStatus.savingMessage]: {
    status: InsightsAmaStatus.savingMessage,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  [InsightsAmaStatus.messageSaved]: {
    status: InsightsAmaStatus.messageSaved,
    isError: false,
    message: "Changes saved",
    icon: <S.SuccessIcon />
  },
  [InsightReportStatus.addingNewSection]: {
    status: InsightReportStatus.addingNewSection,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  [InsightReportStatus.errorAddingNewSection]: {
    status: InsightReportStatus.errorAddingNewSection,
    isError: true,
    message: "Error saving changes",
    icon: <S.ErrorIcon />
  },
  [InsightReportStatus.errorAddingToSection]: {
    status: InsightReportStatus.errorAddingToSection,
    isError: true,
    message: "Error saving changes",
    icon: <S.ErrorIcon />
  },
  [InsightReportStatus.errorMovingSubSection]: {
    status: InsightReportStatus.errorMovingSubSection,
    isError: true,
    message: "Error saving changes",
    icon: <S.ErrorIcon />
  },
  [InsightReportStatus.errorRemovingReportSection]: {
    status: InsightReportStatus.errorRemovingReportSection,
    isError: true,
    message: "Error saving changes",
    icon: <S.ErrorIcon />
  },
  [InsightReportStatus.errorReOrderingSubSection]: {
    status: InsightReportStatus.errorReOrderingSubSection,
    isError: true,
    message: "Error saving changes",
    icon: <S.ErrorIcon />
  },
  [InsightReportStatus.movingSubSection]: {
    status: InsightReportStatus.movingSubSection,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  [InsightReportStatus.movingSubsectionSuccess]: {
    status: InsightReportStatus.movingSubsectionSuccess,
    isError: false,
    message: "Changes saved",
    icon: <S.SuccessIcon />
  },
  [InsightReportStatus.removingReportElement]: {
    status: InsightReportStatus.removingReportElement,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  [InsightReportStatus.removingReportSection]: {
    status: InsightReportStatus.removingReportSection,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  [InsightReportStatus.removingReportSubSection]: {
    status: InsightReportStatus.removingReportSubSection,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  },
  [InsightReportStatus.removingReportSectionSuccess]: {
    status: InsightReportStatus.removingReportSectionSuccess,
    isError: false,
    message: "Changes saved",
    icon: <S.SuccessIcon />
  },
  [InsightReportStatus.reorderingSubSectionsSuccess]: {
    status: InsightReportStatus.reorderingSubSectionsSuccess,
    isError: false,
    message: "Changes saved",
    icon: <S.SuccessIcon />
  },
  [InsightReportStatus.savingTitle]: {
    status: InsightReportStatus.savingTitle,
    isError: false,
    message: "Saving changes",
    icon: <SavingIcon />
  }
};

const StatusNotification = () => {
  const [settings, setSettings] = useState<StatusSetting | undefined>(
    undefined
  );
  const {
    state: { status: insightsStatus }
  } = useInsightReport();
  const {
    state: { status: amaStatus }
  } = useInsightsAma();
  const {
    state: { status: timelineStatus }
  } = useInsightsTimeline();

  useEffect(() => {
    if (settings && settings.status === InsightsAmaStatus.messageSaved) {
      return;
    }

    if (
      settings &&
      settings.status === InsightsAmaStatus.savingMessage &&
      amaStatus === InsightsAmaStatus.idle
    ) {
      const statusSetting = statusSettings[InsightsAmaStatus.messageSaved];
      if (statusSetting) {
        setSettings(statusSetting);
      }

      setTimeout(() => {
        setSettings(undefined);
      }, 3000);

      return;
    }

    if (amaStatus === InsightsAmaStatus.idle) return;

    const statusSetting = statusSettings[amaStatus];
    if (statusSetting) {
      setSettings(statusSetting);
    }

    if (statusSetting && !statusSetting.isError) {
      setTimeout(() => {
        setSettings(undefined);
      }, 3000);
    }
  }, [amaStatus, settings]);

  useEffect(() => {
    if (
      settings &&
      settings.status === InsightReportStatus.savingTitle &&
      insightsStatus === InsightReportStatus.idle
    ) {
      const statusSetting = statusSettings.changesSaved;

      setSettings(statusSetting);

      setTimeout(() => {
        setSettings(undefined);
      }, 3000);
      return;
    }

    if (insightsStatus === InsightReportStatus.idle) return;

    const statusSetting = statusSettings[insightsStatus];
    if (statusSetting) {
      setSettings(statusSetting);
    }

    if (statusSetting && !statusSetting.isError) {
      setTimeout(() => {
        setSettings(undefined);
      }, 3000);
    }
  }, [insightsStatus, settings]);

  useEffect(() => {
    if (timelineStatus === InsightsTimelineStatus.idle) return;

    const statusSetting = statusSettings[timelineStatus];
    if (statusSetting) {
      setSettings(statusSetting);
    }

    if (statusSetting && !statusSetting.isError) {
      setTimeout(() => {
        setSettings(undefined);
      }, 3000);
    }
  }, [timelineStatus, settings]);

  if (!settings) {
    return (
      <S.Container>
        <S.DefaultIcon />
      </S.Container>
    );
  }

  return (
    <S.Container isError={settings.isError}>
      <span>{settings.message}</span>
      {settings.icon}
    </S.Container>
  );
};

export default StatusNotification;
