import React from "react";
import { inject, observer } from "mobx-react";

import { NAME_ONLY_SEARCH_ROUTE } from "util/nameOnlySearchRoute";
import XapienLogo from "img/logo.svg";
import DefineStore from "state/DefineStore";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  defineStore: DefineStore;
}

const Footer = ({ defineStore }: Props) => {
  const currentYear = new Date().getFullYear();
  const { nameOnlySearch } = defineStore;

  return (
    <div>
      <S.ReportBanner>
        <S.ReportEnds>
          <S.Banner>Report ends</S.Banner>
        </S.ReportEnds>
        <S.BannerSearch backgroundColor={theme.primaryColor}>
          <S.SearchButton
            href={nameOnlySearch ? NAME_ONLY_SEARCH_ROUTE : "/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            New search...
          </S.SearchButton>
        </S.BannerSearch>
      </S.ReportBanner>
      {theme.reportFooter?.logo ?? (
        <a href="/" target="_blank" rel="noopener noreferrer">
          <S.BottomLogo src={XapienLogo} height="40" width="200" alt="Logo" />
        </a>
      )}
      <S.CopyrightReportMessage>
        {" "}
        © Digital Insight {currentYear} All Rights Reserved{" "}
      </S.CopyrightReportMessage>
      <S.PrintCopyrightReportMessage>
        <hr />© Digital Insight Technologies Ltd {currentYear}
        <br />
        This report has been generated using an automated online research
        platform provided by Digital Insight Technologies Ltd (DI). The
        information in this report has been collected from external sources and
        assembled automatically. This report has been made available to a
        registered customer of Digital Insight for informational research
        purposes only and should not be used as a safety or security critical
        tool. The information provided in this report is without warranty and
        Digital Insight makes no assertion about the accuracy or completeness of
        the information presented.
        <hr />
      </S.PrintCopyrightReportMessage>
    </div>
  );
};

export default inject("defineStore")(observer(Footer));
