import { DefaultService, FeAnswer } from "api/old-insights";
import { useCallback, useMemo, useState } from "react";
import { getErrorMessage } from "util/error-utils";

import { useEnquiryId } from "util/hooks/useEnquiryId";
import { useSubjectName } from "util/hooks/useSubjectName";

export const useInsightsSources = ({ answer }: { answer: FeAnswer }) => {
  const [error, setError] = useState<string>();
  const [sourcedFragments, setSourcedFragments] = useState(
    answer.cited_fragments
  );
  const [loading, setLoading] = useState(false);
  const enquiryId = useEnquiryId();
  const subjectName = useSubjectName();
  const fetchSources = useCallback(async () => {
    if (enquiryId && subjectName) {
      setLoading(true);
      setError(undefined);
      try {
        const result =
          await DefaultService.addSourcingToAnswerEnquiryEnquiryIdAnswerAnswerIdPost(
            {
              answerId: answer.id,
              enquiryId,
              subjectName
            }
          );
        setSourcedFragments(result.cited_fragments);
      } catch (e) {
        console.error("Error fetching sources", e);
        setError(getErrorMessage(e));
        setSourcedFragments([]);
      }
      setLoading(false);
    } else {
      console.error("Source fetching not properly configured");
      setSourcedFragments([]);
    }
  }, [setSourcedFragments, answer.id, enquiryId, subjectName, setLoading]);
  const value = useMemo(
    () => ({ sourcedFragments, fetchSources, error, loading }),
    [sourcedFragments, fetchSources, error, loading]
  );
  return value;
};
