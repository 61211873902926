import { styled } from "@linaria/react";

import { purple } from "styles/colors";

const RadioButton = styled.input`
  appearance: none;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 1px solid ${purple.mid};
  border-radius: 50%;
  margin-right: 5px;
  transform: translateY(3px);
  display: grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: ${purple.mid};
  }

  &:checked::before {
    transform: scale(1);
  }
`;

const S = {
  RadioButton
};

export default S;
