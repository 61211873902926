import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";
import { family, size } from "styles/fonts";
import { zIndex } from "styles/zIndex";

export interface TooltipProps {
  isRight: boolean;
  above: boolean;
}

export interface TooltipColorBoxProps {
  backgroundColor: string;
}

const Tooltip = styled.div<TooltipProps>`
  bottom: ${({ above }) => (above ? "calc(100% + 15px)" : "auto")};
  top: ${({ above }) => (above ? "auto" : "40px")};
  position: absolute;
  right: ${({ isRight }) => (isRight ? "auto" : "50px")};
  left: ${({ isRight }) => (!isRight ? "auto" : "50px")};
  text-align: left;
  max-width: 175px;
  z-index: ${zIndex.l1};
`;

const TooltipContent = styled.div`
  border-radius: 6px;
  background-color: ${standardColors.white};
  padding: 12px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 152px;
`;
const TooltipHeader = styled.div`
  display: flex;
  gap: 10px;
`;

const TooltipColorBox = styled.div<TooltipColorBoxProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 2px;
  width: 16px;
  height: 16px;
`;

const TooltipTitle = styled.h5`
  font-family: ${family.interDisplay};
  font-size: ${size.sm};
  margin: 0;
  line-height: 16px;
`;

const TooltipDescription = styled.p`
  font-family: ${family.interDisplayMedium};
  font-size: ${size.sm};
  color: ${grey.darkish};
  margin: 0;
  line-height: 16px;
`;

const TooltipPoint = styled.div<TooltipProps>`
  background-color: ${standardColors.white};
  width: 15px;
  height: 15px;
  transform: rotate(0.125turn);
  position: absolute;
  top: ${({ above }) => (above ? "calc(100% - 10px)" : "auto")};
  bottom: ${({ above }) => (above ? "auto" : "calc(100% - 8px)")};
  left: ${({ isRight }) => (isRight ? "10px" : "calc(100% - 30px)")};
`;

const S = {
  Tooltip,
  TooltipContent,
  TooltipHeader,
  TooltipColorBox,
  TooltipTitle,
  TooltipDescription,
  TooltipPoint
};

export default S;
