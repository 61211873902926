import React, { useState } from "react";
import { MoreVertical, Settings, Trash2, Users } from "react-feather";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import DeleteGroupModal from "components/organisms/DeleteGroupModal";
import TitleCard from "components/molecules/TitleCard";
import ManageGroupModal from "components/organisms/ManageGroupModal";
import useManageGroup from "util/hooks/useManageGroup";
import LeaveGroupModal from "components/organisms/LeaveGroupModal";
import { ReactComponent as RemoveUser } from "img/icons/remove-user-icon.svg";
import useUserSettings from "util/hooks/useUserSettings";
import type { Group } from "api/groups";

import S from "./styles";

interface Props {
  groupName: string;
  groupDescription?: string;
  groupId: string;
  groupMemberCount: number;
  permissions: Group["permissions"];
}

const GroupCardMenu = ({
  groupName,
  groupDescription,
  groupId,
  groupMemberCount,
  permissions
}: Props) => {
  const {
    state: {
      userDetails: { groups: userGroups }
    }
  } = useUserSettings();
  const [isLeaveGroupModalOpen, setIsLeaveGroupModalOpen] = useState(false);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false);

  const { isManageGroupModalOpen, toggleManageGroupModal } = useManageGroup();

  const isGroupAdmin = userGroups.some(
    group => group.groupId === groupId && group.role === "Admin"
  );

  const isLastMember = groupMemberCount <= 1;

  return (
    // Stop click event from opening report
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={e => e.stopPropagation()}>
      <Popover
        disabled={isDeleteGroupModalOpen || isManageGroupModalOpen}
        hideArrow
        alignment="bottom-end"
        trigger="click"
        content={
          <Menu>
            {/* <MenuItem
                IconLeading={Eye}
                text="View groups's reports"
                disabled
                showSeparator
                /> */}
            <MenuItem
              IconLeading={Settings}
              text={isGroupAdmin ? "Manage group" : "View members"}
              onMenuItemClick={toggleManageGroupModal}
            />
            <MenuItem
              isDeleteAction
              IconLeading={RemoveUser}
              text="Leave this group"
              onMenuItemClick={() => setIsLeaveGroupModalOpen(true)}
              disabled={!permissions.canLeave}
            />
            <MenuItem
              isDeleteAction
              IconLeading={Trash2}
              text="Delete group"
              onMenuItemClick={() => setIsDeleteGroupModalOpen(true)}
              disabled={!permissions.canDelete}
            />
          </Menu>
        }
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.MenuButton>
          <MoreVertical />
        </S.MenuButton>
      </Popover>
      <ManageGroupModal
        isOpen={isManageGroupModalOpen}
        toggleOpen={toggleManageGroupModal}
        groupName={groupName}
        groupDescription={groupDescription}
        groupId={groupId}
        canEditMembers={permissions.canEditMembers && permissions.canEditAdmins}
        canEditGroup={permissions.canEdit}
      />
      <LeaveGroupModal
        isOpen={isLeaveGroupModalOpen}
        toggleOpen={() => setIsLeaveGroupModalOpen(prev => !prev)}
        groupId={groupId}
        isLastMember={isLastMember}
      >
        <S.TitleCardContainer>
          <TitleCard
            title={groupName}
            subtitle={groupDescription}
            icon={<Users />}
          />
        </S.TitleCardContainer>
      </LeaveGroupModal>
      <DeleteGroupModal
        isOpen={isDeleteGroupModalOpen}
        toggleOpen={() => setIsDeleteGroupModalOpen(prev => !prev)}
        groupId={groupId}
      >
        <S.TitleCardContainer>
          <TitleCard
            title={groupName}
            subtitle={groupDescription}
            icon={<Users />}
          />
        </S.TitleCardContainer>
      </DeleteGroupModal>
    </div>
  );
};

export default GroupCardMenu;
