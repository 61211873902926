import { styled } from "@linaria/react";

import { green, grey, standardColors } from "styles/colors";
import { family, fontSize, headingLetterSpacing } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

const ReportBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  > *:not(:last-child) {
    margin-right: 3px;
  }
`;

const Banner = styled.div`
  color: ${standardColors.black};
  text-align: left;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 3px;
  display: flex;
  margin-bottom: 2px;
  justify-content: left;
  flex: 1;
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplay};
  letter-spacing: ${headingLetterSpacing["3xs"]};

  @media print {
    display: none;
  }
`;

const ReportEnds = styled.div`
  flex-grow: 1;
`;

const BannerSearch = styled.div<{ backgroundColor: string }>`
  background-color: ${props => props.backgroundColor ?? green.xapienGreen};
  padding: 2px 10px;
  border-radius: 18px;
  display: flex;
  margin-bottom: 2px;
  justify-content: center;
  align-items: center;
  background-clip: padding-box;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 0 4px
      rgba(
        ${() => {
          const { r, g, b } = hexToRgb(green.xapienGreen);
          return `${r}, ${g}, ${b}`;
        }},
        0.33
      );
  }

  @media print {
    display: none;
  }
`;

const SearchButton = styled.a`
  color: ${standardColors.white};
  text-decoration: none;
`;

const BottomLogo = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 18px;

  @media print {
    display: none;
  }
`;

const CopyrightReportMessage = styled.div`
  display: block;
  text-align: center;
  color: ${grey.dark};
  max-width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  font-size: ${fontSize.sm};

  @media print {
    display: none;
  }
`;

const PrintCopyrightReportMessage = styled.div`
  display: block;
  text-align: center;
  color: ${grey.dark};
  max-width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  font-size: ${fontSize.sm};

  @media screen {
    display: none;
  }
`;

const S = {
  ReportBanner,
  Banner,
  ReportEnds,
  BannerSearch,
  SearchButton,
  BottomLogo,
  CopyrightReportMessage,
  PrintCopyrightReportMessage
};

export default S;
