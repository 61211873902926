import { styled } from "@linaria/react";

import { fontSize } from "styles/fonts";

export interface DateTagContainerProps {
  isRight: boolean;
}

const DateTagContainer = styled.div<DateTagContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  > svg {
    transform: ${({ isRight }) => (isRight ? "none" : "rotate(180deg)")};
  }
`;

const DateTag = styled.div`
  color: white;
  position: absolute;
  display: inline-flex;
  font-size: ${fontSize.sm};
`;

const S = {
  DateTagContainer,
  DateTag
};

export default S;
