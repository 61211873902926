import React, { FC, ReactNode } from "react";
import { X } from "react-feather";

import S from "./styles";

export interface ExportButtonTooltipProps {
  title: string;
  children: ReactNode;
  withCloseButton?: boolean;
  onClose?: () => void;
}

const ExportButtonTooltip: FC<ExportButtonTooltipProps> = ({
  title,
  children,
  onClose,
  withCloseButton = false
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <S.Tooltip withCloseButton={withCloseButton}>
      <S.TooltipContent>
        <S.TooltipTitle>{title}</S.TooltipTitle>
        <S.TooltipText>{children}</S.TooltipText>
      </S.TooltipContent>
      {withCloseButton && (
        <S.TooltipCloseButton onClick={handleClose}>
          <X />
        </S.TooltipCloseButton>
      )}
      <S.TooltipPoint />
    </S.Tooltip>
  );
};

export default ExportButtonTooltip;
