import React, { Fragment } from "react";

import { orderByDateAndStatus } from "util/orderByDateAndStatus";

import S from "./styles";

const mapping = {
  Sanction: "Sanctions lists",
  AdverseMedia: "Adverse Media",
  WatchList: "Watchlists"
};

const FlagCard = ({
  type,
  customHeader,
  customContent,
  lists,
  isFlagInactive
}) => {
  if (!lists?.length && !(customHeader || customContent)) {
    return null;
  }

  // Sort flags by date then by active status
  let sortedLists = orderByDateAndStatus(lists);

  return (
    <S.FlagCardContainer>
      {customHeader ?? (
        <S.FlagBanner isActive={!isFlagInactive}>
          <span>{mapping[type] ?? type}</span>{" "}
        </S.FlagBanner>
      )}
      <S.FlagContent isActive={!isFlagInactive}>
        {customContent ??
          sortedLists.map(list => {
            return (
              <S.ListGroup key={list.name} isActive={list.isKnownToBeOngoing}>
                <div>
                  {list.name}{" "}
                  <Fragment>
                    –{" "}
                    <S.ActiveLabel isActive={list.isKnownToBeOngoing}>
                      {list.isKnownToBeOngoing ? "Active" : "Inactive"}
                    </S.ActiveLabel>
                  </Fragment>
                </div>
                <div>{list.duration}</div>
              </S.ListGroup>
            );
          })}
      </S.FlagContent>
    </S.FlagCardContainer>
  );
};

export default FlagCard;
