import { Assertion, AssertionTemplate } from ".";
import { reportAbstract } from "./recognisers";

const exactNameSegmentAssertionTemplate = (name, nameSegment) =>
  new AssertionTemplate(
    (isValid, isMust, testCaseId, userId, userComment) => {
      // nameType can be "FirstName", "MiddleNames", "LastName", "Titles", "NameSuffix"
      // Titles is structured differently to all the other name fields in the report json
      const constKey = isValid ? "const" : "not";
      const constVal = isValid ? name : { const: name };
      const arrayKey = isValid ? "contains" : "items";

      const assertionSchema =
        nameSegment === "Titles"
          ? {
              type: "object",
              properties: {
                ReportAbstract: {
                  type: "object",
                  properties: {
                    Titles: {
                      type: "array",
                      [arrayKey]: {
                        type: "object",
                        properties: {
                          Text: {
                            type: "string",
                            [constKey]: constVal
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          : {
              type: "object",
              properties: {
                ReportAbstract: {
                  type: "object",
                  properties: {
                    [nameSegment]: {
                      type: "array",
                      [arrayKey]: {
                        type: "object",
                        properties: {
                          Values: {
                            type: "array",
                            [arrayKey]: {
                              type: "object",
                              properties: {
                                Text: {
                                  type: "string",
                                  [constKey]: constVal
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            };

      const nameTypeString = {
        FirstName: "first name",
        MiddleNames: "middle name",
        LastName: "last name",
        Titles: "title",
        NameSuffix: "name suffix"
      };

      const assertionHumanReadable = `Report ${isMust ? "must" : "should"}${
        isValid ? "" : " not"
      } contain "${name}" as a ${nameTypeString[nameSegment]}.`;
      return new Assertion(
        testCaseId,
        userId,
        userComment,
        isMust,
        assertionSchema,
        assertionHumanReadable
      );
    },
    nameSegment === "Titles"
      ? reportAbstract.specificTitle(name)
      : reportAbstract.specificNameSegment(nameSegment, name)
  );

export default exactNameSegmentAssertionTemplate;
