import {Features} from "api/feature/types";

export interface OrgSuggestion {
  logo?: string;
  url?: string;
  strapline?: string;
  description?: string;
  name: string;
  fullUrl: string;
}

interface ProposedCorrection {
  correctedForm: string;
  originalForm: { type: string; item: string };
}

export enum ValidationResponseReason {
  None,
  RightToForget,
  SystemDisabled
}

interface SearchAssistResponse {
  orgSuggestions: OrgSuggestion[];
  proposedContexts: ProposedCorrection[];
  proposedSubject: ProposedCorrection;
}

export interface ValidationResponse {
  reasonCode: ValidationResponseReason;
  queryQuality: number;
  searchAssistResponse: SearchAssistResponse;
}

interface SearchItem {
  type: string;
  item: string;
}

export interface SearchValidationRequest {
  EnquirySubject: SearchItem;
  EnquiryContext: SearchItem[];
  ExperimentalMode: boolean;
  Features: Features;
  RequestType: any;
}

export interface SystemStatus {
  message: string;
  systemError: boolean;
}
