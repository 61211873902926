import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";
import { fontSize, headingLetterSpacing, lineHeight } from "styles/fonts";

const Disclaimer = styled.section`
  background: ${standardColors.white};
  padding: 20px;
  gap: 10px;
  color: ${grey.dark};
  font-size: ${fontSize.sm};
  line-height: ${lineHeight.lg};
  letter-spacing: ${headingLetterSpacing.lg};
`;

const S = { Disclaimer };

export default S;
