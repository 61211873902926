import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";
import { ReactComponent as OrgSVG } from "img/icons/org.svg";
import { ReactComponent as PersonSVG } from "img/icons/user.svg";
import { family, fontSize, lineHeight } from "styles/fonts";

const Container = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background-color: ${standardColors.white};
  border-radius: 16px;
  border: 1px solid ${(grey.mid, standardColors.white)};
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
  width: 440px;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const HeaderIcon = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${(grey.mid, grey.panel)};
`;

const OrgIcon = styled(OrgSVG)``;
const PersonIcon = styled(PersonSVG)``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  flex: 1;
`;

const HeaderTitle = styled.div`
  color: ${standardColors.black};
  font-size: ${fontSize["2lg"]};
  line-height: ${lineHeight.sm};
`;

const HeaderSubtitle = styled.div`
  color: ${grey.mid};
  font-size: ${fontSize.xs};
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DataTitle = styled.div`
  color: ${standardColors.black};
  font-size: ${fontSize.sm};
  font-family: ${family.interSemiBold};
  line-height: ${lineHeight.md};
`;

const DataItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  font-family: ${family.interDisplay};
  line-height: ${lineHeight.md};
`;

const S = {
  Container,
  OrgIcon,
  PersonIcon,
  Header,
  HeaderIcon,
  TitleContainer,
  HeaderTitle,
  HeaderSubtitle,
  DataContainer,
  DataTitle,
  DataItems
};

export default S;
