import React from "react";

import Skeleton from "components/atoms/Skeleton";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import { CardVariant } from "components/molecules/Card/types";

import S from "./styles";

export interface Props {
  datetime?: string;
  author?: string;
  variant?: CardVariant;
  loading?: boolean;
  children?: React.ReactNode;
}

const CardFooter = ({
  datetime,
  author,
  loading,
  children,
  variant = CardVariant.default
}: Props) => {
  return loading ? (
    <S.FooterContentWrapper variant={variant}>
      <S.FooterContentLeft>
        <Avatar avatarSize={AvatarSize.Small} loading />
        <Skeleton width="50px" />
      </S.FooterContentLeft>
      <Skeleton width="40%" />
    </S.FooterContentWrapper>
  ) : (
    <S.Footer>
      <S.FooterContentWrapper variant={variant}>
        <S.FooterContentLeft>
          <Avatar avatarSize={AvatarSize.Small} />
          {author}
        </S.FooterContentLeft>
        <S.DateTimeAndChildren>
          {datetime}
          {children}
          </S.DateTimeAndChildren>
      </S.FooterContentWrapper>
    </S.Footer>
  );
};

export default CardFooter;
