import React, { FC, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import Heading from "components/atoms/Heading";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import { Mail, Info, Trash2 } from "react-feather";
import config from "config";

import S from "./styles";

interface Props {
  subject?: string;
  contextString?: string;
  enquiryId: string;
  isError: boolean;
}

interface ReportErrorProps {
  subject?: string;
  contextString?: string;
  enquiryId: string;
}

const ReportError: FC<ReportErrorProps> = ({
  subject,
  enquiryId,
  contextString
}) => {
  const emailSubject = encodeURIComponent(`Enquiry failure: ${enquiryId}`);
  const emailBody =
    subject && contextString
      ? encodeURIComponent(
          `Subject: ${subject}\r\rContext: ${contextString}\r\r`
        )
      : "";

  const onContactSupport = () => {
    window.open(
      `mailto:${config.supportEmail}?subject=${emailSubject}&body=${emailBody}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <S.ErrorContainer>
      <S.ErrorLogoContainer>
        <Info />
      </S.ErrorLogoContainer>
      <Heading level={3}>We hit a snag with your report</Heading>
      <p>
        We’ve run into some trouble generating the report for {subject}. The
        report will automatically refresh in a few minutes. However, if the
        issue persists, please reach out to the Customer Success team.
      </p>

      <S.Button
        onClick={onContactSupport}
        type={ButtonType.Filled}
        size={ButtonSize.Large}
        text="Customer Success"
        IconTrailing={Mail}
      />
    </S.ErrorContainer>
  );
};

const ReportDeleted = () => {
  const navigate = useNavigate();

  const onReturnToHome = () => {
    navigate(`/`);
  };

  return (
    <S.ErrorContainer>
      <S.ErrorLogoContainer>
        <Trash2 />
      </S.ErrorLogoContainer>

      <Heading level={3}>Report deleted</Heading>

      <p>
        This report has been deleted, either by the report owner or because it
        is older than your data retention policy allows.
      </p>

      <S.Button
        onClick={onReturnToHome}
        type={ButtonType.Filled}
        size={ButtonSize.Large}
        text="Return to home"
      />
    </S.ErrorContainer>
  );
};

const NoReport: FC<Props> = ({
  subject,
  contextString,
  enquiryId,
  isError
}) => {
  return (
    <S.Container>
      {isError ? (
        <ReportError
          subject={subject}
          contextString={contextString}
          enquiryId={enquiryId}
        />
      ) : (
        <ReportDeleted />
      )}
    </S.Container>
  );
};

export default NoReport;
