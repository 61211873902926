import {
  Idam_Contracts_Enums_ReportState,
  Idam_Contracts_Enums_ReportType,
  Idam_Contracts_Reports_GetReportResponse_ReportGroup
} from "api/portal";
import { ReportStatus, ReportType, ScreeningRisk, Tag, TagId } from "./types";

export const formatGroupName = (name?: string | null) => {
  if (!name) {
    return null;
  }

  let formattedName = name;

  if (name.charAt(0) !== "@") {
    formattedName = `@${formattedName}`;
  }

  return formattedName;
};

export const mapReportStateToStatus = (
  state?: Idam_Contracts_Enums_ReportState
): ReportStatus => {
  const reportStateToStatusMapping: Record<
    Idam_Contracts_Enums_ReportState,
    ReportStatus
  > = {
    [Idam_Contracts_Enums_ReportState.IN_PROGRESS]: ReportStatus.running,
    [Idam_Contracts_Enums_ReportState.DONE]: ReportStatus.ready,
    [Idam_Contracts_Enums_ReportState.FAILED]: ReportStatus.failed,
    [Idam_Contracts_Enums_ReportState.REGENERATING]: ReportStatus.updating,
    [Idam_Contracts_Enums_ReportState.DELETED]: ReportStatus.deleted
  };

  if (!state) return ReportStatus.ready;

  return reportStateToStatusMapping[state];
};

export const mapReportType = (
  type?: Idam_Contracts_Enums_ReportType,
  isLegalEntity: boolean = false
) => {
  const reportTypeMapping: Record<Idam_Contracts_Enums_ReportType, ReportType> =
    {
      [Idam_Contracts_Enums_ReportType.ORGANISATION]: ReportType.Organisation,
      [Idam_Contracts_Enums_ReportType.PERSON]: ReportType.Person
    };

  if (isLegalEntity) return ReportType.LegalEntity;

  if (!type) return ReportType.Person;

  return reportTypeMapping[type];
};

export const buildReportCardTags = ({
  currentUserId,
  reportOwnerUserId,
  groupsReportIsPartOf,
  reportType
}: {
  currentUserId: string;
  reportOwnerUserId: string;
  groupsReportIsPartOf: Idam_Contracts_Reports_GetReportResponse_ReportGroup[];
  reportType: ReportType;
}): Tag[] => {
  const tags = [];

  if (reportType === ReportType.LegalEntity) {
    tags.push({ id: TagId.ResearchType, name: "Legal entity" });
  } else {
    tags.push({ id: TagId.ResearchType, name: "General research" });
  }

  if (currentUserId !== reportOwnerUserId) {
    tags.push({ id: TagId.SharingStatus, name: "Shared" });
  }

  groupsReportIsPartOf?.forEach(({ name }) => {
    tags.push({
      id: TagId.SharedGroup,
      name: formatGroupName(name) ?? ""
    });
  });

  return tags;
};

export const buildReportScreeningFlags = ({
  sanctions,
  watchLists,
  pepsOrStateOwned,
  flags,
  rcas,
  sips
}: {
  sanctions: number;
  watchLists: number;
  pepsOrStateOwned: number;
  flags: number;
  rcas: number;
  sips: number;
}) => {
  return [
    sanctions && sanctions > 0 ? ScreeningRisk.Sanctions : undefined,
    watchLists && watchLists > 0 ? ScreeningRisk.Watchlists : undefined,
    pepsOrStateOwned && pepsOrStateOwned > 0 ? ScreeningRisk.Peps : undefined,
    flags && flags > 0 ? ScreeningRisk.Other : undefined,
    rcas && rcas > 0 ? ScreeningRisk.RCA : undefined,
    sips && sips > 0 ? ScreeningRisk.SpecialInterest : undefined
  ].filter(Boolean) as ScreeningRisk[];
};
