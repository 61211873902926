import React, { CSSProperties, MouseEventHandler } from "react";

import { X } from "react-feather";

import S from "./styles";

interface Props {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: CSSProperties;
}

const ErrorBanner = ({ text, onClick, className, style }: Props) => {
  return (
    <S.ErrorBanner className={className} style={style}>
      {text}
      {false && onClick && (
        <S.CloseButton onClick={onClick}>
          <X size={16} />
        </S.CloseButton>
      )}
    </S.ErrorBanner>
  );
};

export default ErrorBanner;
