import { styled } from "@linaria/react";

import ButtonNew from "components/atoms/ButtonNew";

import { grey, standardColors, blue } from "styles/colors";
import { fontSize, family } from "styles/fonts";

export interface ToggleContainerProps {
  active: boolean;
}

export interface ToggleProps {
  active: boolean;
}

const DescriptionText = styled.p`
  color: ${grey.dark};
  font-family: ${family.interRegular};
  line-height: 24px;
  font-size: ${fontSize.md};
  margin-bottom: 32px;
`;

const CtaText = styled.p`
  color: ${grey.dark};
  font-family: ${family.interDisplay};
  line-height: 24px;
  font-size: ${fontSize.md};
  margin-bottom: 12px;
`;

const Option = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const OptionTitle = styled.p`
  color: ${grey.dark};
  font-family: ${family.interDisplay};
  line-height: 24px;
  font-size: ${fontSize.md};
  letter-spacing: -0.49px;
  margin-bottom: 0;
  flex: 1;
`;

const OptionsContainer = styled.div`
  background-color: ${grey.lighterPanel};
  padding: 16px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 10px;
  margin-bottom: 32px;

  ${Option}:last-of-type {
    margin-bottom: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButton = styled(ButtonNew)`
  background-color: ${grey.panel};
  padding: 12px 16px;
  font-family: ${family.interDisplay};
`;

const ExportButton = styled(ButtonNew)`
  background-color: ${blue.link};
  color: ${standardColors.white};
  padding: 12px 24px;
  font-family: ${family.interDisplay};
`;

const ToggleContainer = styled.div<ToggleContainerProps>`
  display: block;
  width: 40px;
  height: 20px;
  padding: 2px;
  border-radius: 20px;
  background-color: ${({ active }) => (active ? blue.link : grey.ghost)};
`;

const Toggle = styled.div<ToggleProps>`
  width: 16px;
  height: 16px;
  border-radius: 20px;
  background-color: ${standardColors.white};
  transform: ${({ active }) => (active ? "translateX(20px)" : "none")};
  transition: transform ease 0.3s;
`;

const S = {
  DescriptionText,
  CtaText,
  OptionsContainer,
  Option,
  OptionTitle,
  Actions,
  CancelButton,
  ExportButton,
  ToggleContainer,
  Toggle
};

export default S;
