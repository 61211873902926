import React, { useState, FC, SVGProps, useEffect } from "react";
import { Spinner } from "reactstrap";

import {
  InsightReportActions,
  useInsightReport
} from "util/hooks/useInsightReport";

import ModalContainer, { Variant } from "components/molecules/ModalContainer";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ErrorBanner from "components/atoms/ErrorBanner";
import SelectionDropDown from "components/atoms/SelectionDropDown";

import { InsightReportStatus } from "util/hooks/useInsightReport/types";
import S from "./styles";

const EXECUTIVE_SUMMARY = "Executive Summary";

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  sectionId: string;
  subSectionId?: string;
  children: React.ReactNode;
}

export interface SelectedSection {
  id: string;
  title: string;
}

const MoveSectionModal: FC<Props> = ({
  isOpen,
  onToggle,
  children,
  sectionId,
  subSectionId
}) => {
  const { state, dispatch } = useInsightReport();
  const [selectedSection, setSelectedSection] = useState<SelectedSection>();
  const [submissionError, setSubmissionError] = useState<undefined | string>(
    undefined
  );

  const { report } = state;
  const sectionsWithOutExecutiveSummary = report?.sections.filter(
    ({ slug }) => slug !== EXECUTIVE_SUMMARY
  );

  const onDismissError = () => {
    setSubmissionError(undefined);
  };

  const onMove = () => {
    if (!selectedSection) return;

    dispatch({
      type: InsightReportActions.moveSubSection,
      sectionId,
      subSectionId: subSectionId as string,
      destinationSectionId: selectedSection.id
    });
  };

  const isMoving = state.status === InsightReportStatus.movingSubSection;

  const isMoved = state.status === InsightReportStatus.movingSubsectionSuccess;

  useEffect(() => {
    if (isMoved && isOpen) {
      setTimeout(() => {
        onToggle();
      }, 1000);
    }
  }, [isOpen, onToggle, isMoving, isMoved]);

  return (
    <>
      {isMoved ? (
        <ModalContainer
          isOpen={isOpen}
          toggleOpen={onToggle}
          onExitClick={onToggle}
          variant={Variant.LIGHT}
        >
          <S.MovedModalContainer>
            <S.IconContainer>
              <S.TickIcon />
            </S.IconContainer>
            <S.MovedTextContainer>
              <S.MovedText>Moved</S.MovedText>
              <S.MovedSubText>
                You will now find this item in{" "}
                <strong>{selectedSection?.title ?? "the report"}</strong>
              </S.MovedSubText>
            </S.MovedTextContainer>
          </S.MovedModalContainer>
        </ModalContainer>
      ) : (
        <ModalContainer
          title="Move to another section"
          isOpen={isOpen}
          toggleOpen={onToggle}
          onExitClick={onToggle}
          variant={Variant.LIGHT}
        >
          <p>Choose which Insights section to move this item to</p>
          <SelectionDropDown
            sections={sectionsWithOutExecutiveSummary}
            labelTitle="Move to"
            sectionSelected={selectedSection}
            setSectionSelected={setSelectedSection}
          />
          <S.ContextTextArea>
            <S.ContentWrapper>{children}</S.ContentWrapper>
          </S.ContextTextArea>

          {submissionError && (
            <ErrorBanner text={submissionError} onClick={onDismissError} />
          )}

          <S.Actions>
            <S.CancelButton
              onClick={onToggle}
              type={ButtonType.Filled}
              size={ButtonSize.Medium}
              disabled={isMoving}
              text="Cancel"
            />
            <S.MoveButton
              onClick={onMove}
              type={ButtonType.Filled}
              size={ButtonSize.Medium}
              disabled={!selectedSection || isMoving}
              text={isMoving ? "Moving" : "Move"}
              IconTrailing={
                isMoving
                  ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
                  : undefined
              }
            />
          </S.Actions>
        </ModalContainer>
      )}
    </>
  );
};

export default MoveSectionModal;
