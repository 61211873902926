import { styled } from "@linaria/react";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

import StickyExpandButton from "pages/report/StickyExpandButton";

const SectionContent = styled.div`
  background-color: ${standardColors.white};
  width: 100%;
  max-height: ${({ isResultsExpanded }) =>
    isResultsExpanded ? "inherit" : "670px"};
  overflow-y: auto;
`;

const ResultsTable = styled.div`
  width: 100%;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  display: flex;
  flex-direction: column;

  & > button:not(:last-of-type) {
    margin-bottom: 2px;
  }
`;

const SectionBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  width: 100%;
  background-color: ${standardColors.white};
  margin-bottom: 2px;
  padding: 0 16px;
`;

const SectionBannerInfo = styled.p`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin-bottom: 0;
`;

const Footer = styled.div`
  background-color: ${standardColors.white};
  padding: 8px 16px;
  font-size: ${fontSize.sm};
  border-top: 2px solid ${grey.panel};
  color: ${grey.mid};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom-left-radius: ${({ isShowingExpandButton }) =>
    isShowingExpandButton ? "0" : "12px"};
  border-bottom-right-radius: ${({ isShowingExpandButton }) =>
    isShowingExpandButton ? "0" : "12px"};
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  padding: 10px 16px;
  border-top: 2px solid ${standardColors.white};
`;

const S = {
  CustomStickyExpandButton,
  SectionContent,
  ResultsTable,
  SectionBanner,
  SectionBannerInfo,
  Footer
};

export default S;
