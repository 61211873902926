import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  Fragment
} from "react";

import ExtraInfoToggler from "components/organisms/ProfessionalOverview/ExtraInfoToggler";
import TruncateLength from "util/TruncateLength";
import { generateUniqueId, toTitleCase } from "util/common";
import LocationRiskTag from "components/atoms/LocationRiskTag";
import NewReportButton from "pages/report/NewReportButton";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { ENTITY_TYPE, SUBJECT_CONTEXTS } from "pages/define/utils";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import { getDurationSinceStartDate } from "util/getDurationSinceStartDate";
import ConfidenceMenu from "components/molecules/ConfidenceMenu";
import { AssessmentOptions } from "pages/report/AssessmentOptions";
import { getAgeFromDob } from "util/getAgeFromDob";
import Assertable from "util/Assertable";
import exactCorporateRecordAssertionTemplate from "util/Assertable/util/exactCorporateRecordAssertionTemplate";
import useIntersect from "util/hooks/useIntersect";

import S from "./styles";

const placeHolderImageSrc =
  "../../../img/icons/business_placeholder_simple.svg";

const ProfessionalInformation = props => {
  const {
    item,
    numberOfRoles,
    multiRolesAtSameClient,
    uboRole,
    hidden = false,
    subjectName,
    matchedPersonName,
    originalSetConfidence,
    currentSetConfidence,
    onConfidenceChange
  } = props;
  const initialTagLimit = 2; // initial tag display limit
  const printModeEnabled = usePrintModeEnabled();

  const [intersectRef, entry] = useIntersect({});

  const [shouldShow, setShouldShow] = useState(printModeEnabled);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setShouldShow(true);
    }
  }, [entry]);

  const [dataSet] = useState(item);

  const [isExpanded, setExpanded] = useState(printModeEnabled);
  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  // display the toggle icon?
  const displayToggle = useCallback(() => {
    return (
      (item.addressFullDisplay && item.addressFullDisplay.length > 0) ||
      (item.orgUrls && item.orgUrls.length > 0) ||
      (item.previousOrgNames && item.previousOrgNames.length > 0) ||
      (item.industryCategories && item.industryCategories.length > 0) ||
      (item.listOfRoles && item.listOfRoles.length > 1) ||
      (item.listOfOrgWarnings && item.listOfOrgWarnings.length > 2)
    );
  }, [item]);

  const [displayExtraInfoToggle, setDisplayExtraInfoToggle] = useState(
    displayToggle()
  );
  const [extraTagsAvailable, setExtraTagsAvailable] = useState(0);

  const isDiagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;

  const [imageErrored, setImageErrored] = useState(false);

  useEffect(() => {
    setDisplayExtraInfoToggle(displayToggle());
  }, [item, displayToggle]);

  useEffect(() => {
    const countTagsToDisplay = tags => {
      let count = 1;

      tags.map(tag => {
        if (!tag.label) return false;
        count += 1;
        return false;
      });
      return count;
    };

    setExtraTagsAvailable(
      countTagsToDisplay(dataSet.listOfOrgWarnings) > 0
        ? countTagsToDisplay(dataSet.listOfOrgWarnings) - initialTagLimit
        : 0
    );
  }, [dataSet, setExtraTagsAvailable]);

  const renderRoleInformation = roleInformation => {
    if (!roleInformation) {
      return <div className="report-section-content-inner row pr-0" />;
    }
    if (roleInformation.length < 1 && !uboRole) {
      return <div className="report-section-content-inner row pr-0" />;
    }

    const roleInfo = roleInformation.length > 1 ? roleInformation : [];

    const dateString = getDurationStringFromDates({
      startDate: roleInformation[0]?.startDate,
      endDate: roleInformation[0]?.endDate,
      isKnownToBeOngoing: roleInformation[0]?.isOngoing
    });

    const durationString = getDurationSinceStartDate({
      startDate: roleInformation[0]?.startDate,
      endDate: roleInformation[0]?.endDate
    });

    const fullDateString = (
      <>
        {dateString ?? ""}{" "}
        <S.RoleDuration>{durationString ?? ""}</S.RoleDuration>
      </>
    );

    return (
      <ExtraInfoToggler
        primaryInformation={{
          title: roleInformation[0]?.roleTitle,
          // Only show the date range for when there is only one role
          value: roleInformation?.length === 1 ? fullDateString : null,
          isOngoing: roleInformation[0]?.isOngoing
        }}
        secondaryInformation={
          multiRolesAtSameClient ? roleInfo.slice(1) : roleInfo
        }
        roleData
        isExpanded={isExpanded}
        isOngoing={roleInformation[0]?.isOngoing}
        numberOfRoles={numberOfRoles}
        uboRole={uboRole}
      />
    );
  };

  const renderConfidenceScores = confidence => {
    if (!confidence) {
      return null;
    }

    if (typeof confidence === "object") {
      return (
        <div className="confidence-scores">
          <div>Exclusion Distance: {confidence.exclusionDistance}</div>
          <div>Inclusion Distance: {confidence.inclusionDistance}</div>
        </div>
      );
    }

    return null;
  };

  const renderLocationRisk = countryInfo => {
    if (
      countryInfo &&
      countryInfo.riskFlags &&
      countryInfo.riskFlags.length > 0
    ) {
      return (
        <S.RiskFlags>
          {countryInfo.riskFlags.map((l, index) => (
            <LocationRiskTag
              key={index}
              riskAssigner={l.assigner}
              location={toTitleCase(countryInfo.countryName)}
            />
          ))}
        </S.RiskFlags>
      );
    }
    return null;
  };

  const renderInsolvencyProceedings = (insolvencyProceedings, companyUrls) => {
    if (!insolvencyProceedings || !insolvencyProceedings.length) {
      return null;
    }

    const ocUrl = companyUrls.find(url =>
      url.label.includes("opencorporates.com/companies")
    );
    let filingsUrl = "";
    if (ocUrl) {
      filingsUrl = ocUrl.label;
    }

    return (
      <S.SourceLink
        className="sourceCardTag company-status companyStatusWarning"
        key={`companyStatus_${generateUniqueId()}`}
        href={filingsUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="company-warning-tag">
          <TruncateLength text="Insolvency proceedings" length={30} />
        </div>
      </S.SourceLink>
    );
  };

  const renderCompanyStatus = (
    companyStatus,
    companyWarnings,
    insolvencyProceedings,
    orgUrls
  ) => {
    if (!companyStatus && !companyWarnings) {
      return false;
    }

    const tagType = tag => {
      if (tag.statusTag) {
        switch (tag.statusTag?.toUpperCase()) {
          case "ACTIVE":
            return "companyStatusActive";
          case "INACTIVE":
            return "companyStatusInactive";
          case "WARNING":
            return "companyStatusWarning";
          default:
            return "companyStatusUnknown";
        }
      } else {
        return "companyStatusUnknown";
      }
    };

    const renderTags = (tags, orgWarning = false) => {
      if (tags.length < 1 || !tags) return false;

      let validTagCounter = 0;
      return tags.map(tag => {
        if (tag && tag.label) {
          validTagCounter += 1;
          let tagClass = `sourceCardTag company-status ${tagType(tag)} ${
            tag.status
          } ${
            validTagCounter > initialTagLimit - 1
              ? "sourceCardTag--extra-info"
              : ""
          }`;
          if (orgWarning)
            tagClass = `sourceCardTag company-status companyStatusWarning ${
              validTagCounter > initialTagLimit - 1
                ? "sourceCardTag--extra-info"
                : ""
            }`;

          if (orgWarning) {
            return (
              <div
                className={tagClass}
                key={`companyStatus_${generateUniqueId()}`}
              >
                <div className="company-warning-tag">
                  <TruncateLength text={tag.label} length={20} />
                </div>
              </div>
            );
          }
          return (
            <div
              className={tagClass}
              key={`companyStatus_${generateUniqueId()}`}
            >
              <TruncateLength text={tag.label} length={20} />
            </div>
          );
        }
        return false;
      });
    };

    return (
      <div className="flex-row">
        <div className={`tags tags--${isExpanded ? "expanded" : "collapsed"}`}>
          <div className="company-status">{renderTags([companyStatus])}</div>
          {renderInsolvencyProceedings(insolvencyProceedings, orgUrls)}
          {renderTags(companyWarnings, true)}
          {extraTagsAvailable > 0 && !isExpanded && (
            <div>
              <S.StatusCountButton onClick={toggleExpanded}>
                +{extraTagsAvailable}
              </S.StatusCountButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderProfessionalInformation = ({
    orgName,
    orgLogo,
    addressShortDisplay,
    roleHolderName,
    roleHolderDoB,
    roleHolderNationalities,
    addressFullDisplay,
    orgRecordLink,
    orgUrls,
    orgDescription,
    countryInfo,
    previousOrgNames,
    industryCategories,
    listOfRoles,
    orgStatus,
    listOfOrgWarnings,
    insolvencyFilings,
    confidence,
    userAssessmentIds,
    userAssessment
  }) => {
    return (
      <Assertable template={exactCorporateRecordAssertionTemplate(orgName)}>
        <S.ProfessionalInformationRow
          ref={intersectRef}
          className="row expandable professional-information"
          {...{ hidden }}
          confidenceHasChanged={originalSetConfidence !== currentSetConfidence}
        >
          {shouldShow ? (
            <>
              <div className="report-section-content-outer col-sm-12 col-md-1">
                <div className="report-section-content-inner p-0">
                  <div>
                    <div className="image-container">
                      {orgLogo && !imageErrored ? (
                        <S.OrgLogo
                          image={orgLogo}
                          fallbackImage={placeHolderImageSrc}
                          onError={() => setImageErrored(true)}
                          alt={
                            orgLogo
                              ? `${orgName} Logo`
                              : "placeholder logo image"
                          }
                          lazyLoad={false}
                          width="74px"
                          height="32px"
                        />
                      ) : (
                        <S.PlaceholderContainer>
                          <S.OrgPlaceholderIcon />
                        </S.PlaceholderContainer>
                      )}

                      <S.NewReportButtonContainer isLogoImagePresent={orgLogo}>
                        <NewReportButton
                          subject={orgName}
                          contextList={[subjectName]}
                          name={orgName}
                          contextType={
                            SUBJECT_CONTEXTS?.organisation?.person?.id
                          }
                          subjectType={ENTITY_TYPE?.Organisation}
                          placeholderIcon={<S.ModalOrgPlaceholderIcon />}
                          image={orgLogo}
                          isDisabled
                        />
                      </S.NewReportButtonContainer>
                    </div>
                  </div>
                </div>
              </div>
              <S.RowSection className="report-section-content-outer col-sm-12 col-md-5">
                <ExtraInfoToggler
                  primaryInformation={{
                    title: orgName,
                    value: (
                      <div>
                        <TruncateLength>{addressShortDisplay}</TruncateLength>
                        <TruncateLength>{roleHolderName}</TruncateLength>
                        {roleHolderDoB && (
                          <div>
                            {getAgeFromDob(
                              new Date(
                                roleHolderDoB.year,
                                roleHolderDoB.month - 1,
                                roleHolderDoB.day
                              )
                            )}{" "}
                            yrs ({roleHolderDoB.monthShort} {roleHolderDoB.year}
                            )
                          </div>
                        )}
                        <TruncateLength>
                          {roleHolderNationalities?.length ? (
                            <div>{roleHolderNationalities.join(", ")}</div>
                          ) : null}
                        </TruncateLength>
                      </div>
                    )
                  }}
                  secondaryInformation={addressFullDisplay}
                  isExpanded={isExpanded}
                  toggleExpanded={toggleExpanded}
                  displayToggler={displayExtraInfoToggle}
                  link={orgRecordLink}
                  sourceLinks={orgUrls}
                  orgDescription={orgDescription}
                  country={countryInfo}
                  previousOrgNames={previousOrgNames}
                  industries={industryCategories}
                  matchedName={isDiagnosticsModeEnabled && matchedPersonName}
                />
              </S.RowSection>
              <S.RowSection className="report-section-content-outer col-sm-12 col-md-3">
                {renderRoleInformation(listOfRoles)}
              </S.RowSection>
              <div className="report-section-content-outer col-sm-12 col-md-3">
                <S.TagColumn className="report-section-content-inner">
                  <S.StatusPills>
                    {renderCompanyStatus(
                      orgStatus,
                      listOfOrgWarnings,
                      insolvencyFilings,
                      orgUrls
                    )}
                    {renderLocationRisk(countryInfo)}
                    {isDiagnosticsModeEnabled &&
                      renderConfidenceScores(confidence)}
                  </S.StatusPills>
                  <S.ConfidenceButtonContainer>
                    <ConfidenceMenu
                      subjectType="Person"
                      originalSetConfidence={originalSetConfidence}
                      currentSetConfidence={currentSetConfidence}
                      onConfidenceChange={onConfidenceChange}
                      itemIds={userAssessmentIds}
                      confidenceHasBeenSetOnRegen={
                        (userAssessment ??
                          AssessmentOptions.NoUserAssessment) !==
                        AssessmentOptions.NoUserAssessment
                      }
                    />
                  </S.ConfidenceButtonContainer>
                </S.TagColumn>
              </div>
            </>
          ) : (
            <S.LazyLoadPlaceholder />
          )}
        </S.ProfessionalInformationRow>
      </Assertable>
    );
  };
  return renderProfessionalInformation(item);
};

export default ProfessionalInformation;
