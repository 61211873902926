import { css } from "@linaria/core";
import { blue } from "styles/colors";

const dotWidth = 10;
const dotColor = blue.blueGrey;
const speed = "1.7s";

const typing = css`
  display: flex;
  min-height: ${dotWidth}px;
  gap: .3125rem;
  margin-top: 10px;

  span {
    content: "";
    animation: blink ${speed} infinite;
    animation-fill-mode: both;
    height: ${dotWidth}px;
    width: ${dotWidth}px;
    background: ${dotColor};
    border-radius: 50%;


    &:nth-child(2) {
        animation-delay: .2s;
    }

    &:nth-child(3) {
        animation-delay: .4s;
        }
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.1;
      margin-bottom: 0px;
    }
    20% {
      opacity: 1;
      margin-bottom: 5px;
    }
    100% {
      opacity: 0.1;
      margin-bottom: 0px;
    }
  }
`;

const S = {
  typing
};

export default S;
