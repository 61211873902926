import React from "react";
import Tag from "components/atoms/Tag";
import { locationRiskText } from "util/locationRiskText";
import { red } from "styles/colors";
import LocationRiskIcon from "components/atoms/LocationRiskIcon";

import { C } from "./styles";

const Risks = [
  {
    name: "TransparencyInternational",
    url: "https://www.transparency.org/en/cpi/2021/index/nzl",
    label: "Trans. Int.",
    tagClass: "location-risk-transparency",
    toolTip: locationRiskText.transparencyInternational.explainer
  },
  {
    name: "EU",
    url: "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A02016R1675-20220313",
    label: "EU",
    tagClass: "location-risk-eu",
    toolTip: locationRiskText.eu.explainer
  },
  {
    name: "HMTreasury",
    url: "https://www.legislation.gov.uk/uksi/2022/1183/regulation/2/made",
    label: "HM Treasury",
    tagClass: "location-risk-treasury",
    toolTip: locationRiskText.hmtreasury.explainer
  }
];

interface Props {
  location?: string;
  riskAssigner?: string;
  withIcon?: boolean;
}

const onClick = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const LocationRiskTag = ({
  location,
  riskAssigner,
  withIcon = false
}: Props) => {
  const Risk = Risks.find(u => u.name === riskAssigner);

  if (Risk) {
    return (
      <Tag
        toolTip={Risk.toolTip}
        label={Risk.label}
        onClick={() => onClick(Risk.url)}
        isKillable={false}
        alwaysShowKillButton={false}
        fillColor={red.directRiskFill}
        isSelected
        icon={
          withIcon &&
          riskAssigner && <LocationRiskIcon assigner={riskAssigner} />
        }
        className={C.Tag}
      />
    );
  }
  return <Tag label={location} />;
};
export default LocationRiskTag;
