import React, { CSSProperties, ReactNode, useState } from "react";

import Heading from "components/atoms/Heading";

import { ButtonType, ButtonSize } from "components/atoms/ButtonNew/types";
import { standardColors } from "styles/colors";
import S from "./styles";

interface Props {
  title: string;
  bodyText: string;
  style?: CSSProperties;
  onSecondaryActionClick: () => void;
  onPrimaryActionClick: () => void;
  primaryActionString: string;
  secondaryActionString: string;
  hideActions?: boolean;
  icon?: ReactNode;
}

const LearnMoreContent = ({
  onHideLearnMore
}: {
  onHideLearnMore: () => void;
}) => {
  return (
    <S.LearnMoreContainer>
      <Heading level={4}>Why regenerate the report?</Heading>
      <p>
        By giving us a few examples of where we misidentified or wrongly
        excluded your subject, and clicking ‘Regenerate now‘, we will be able to
        provide you with a more accurate report.
      </p>

      <p>This will take 5-10 minutes and you will not be charged.</p>
      <div>
        <S.CloseLearnMoreButton
          type={ButtonType.FilledLight}
          size={ButtonSize.Medium}
          onClick={onHideLearnMore}
          text="Got it"
        />
      </div>
    </S.LearnMoreContainer>
  );
};

const ActionFooter = ({
  title,
  bodyText,
  style,
  onSecondaryActionClick,
  onPrimaryActionClick,
  primaryActionString,
  secondaryActionString,
  hideActions,
  icon
}: Props) => {
  const [isShowingLearnMore, setIsShowingLearnMore] = useState(false);
  const variants = {
    offscreen: {
      y: -300
    },
    onscreen: {
      y: 0
    },
    exit: {
      y: -300
    }
  };

  const onShowLearnMore = () => setIsShowingLearnMore(true);
  const onHideLearnMore = () => setIsShowingLearnMore(false);

  return (
    <S.Footer
      // Classname here is important to prevent click events on the footer from closing the inspector
      className="regen-bar"
      style={style}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
        // Delay footer enter/exit animation (reduces animation lag as there is a lot going on during this action)
        delay: 0.3
      }}
      variants={variants}
      initial="offscreen"
      animate="onscreen"
      exit="exit"
    >
      {icon && <S.IconContainer>{icon}</S.IconContainer>}
      <S.Title>
        {title} <span>{bodyText}</span>
      </S.Title>
      {!hideActions && (
        <S.Actions>
          <S.SecondaryAction
            type={ButtonType.Outlined}
            size={ButtonSize.Medium}
            onClick={onSecondaryActionClick}
            text={secondaryActionString}
          />
          or
          <S.PrimaryAction
            type={ButtonType.Filled}
            size={ButtonSize.Medium}
            onClick={onPrimaryActionClick}
            text={primaryActionString}
          />
        </S.Actions>
      )}

      <S.LearnMorePopover
        isOpenOverride={isShowingLearnMore}
        onRequestClose={onHideLearnMore}
        alignment="bottom-end"
        trigger="click"
        maxWidth="470px"
        arrowColor={standardColors.white}
        arrowSize="22px"
        hideArrow
        distance={35}
        content={<LearnMoreContent onHideLearnMore={onHideLearnMore} />}
      >
        <S.LearnMoreButton
          type={ButtonType.Text}
          size={ButtonSize.Medium}
          text="Learn more"
          onClick={onShowLearnMore}
        />
      </S.LearnMorePopover>
    </S.Footer>
  );
};

export default ActionFooter;
