import React, {
  MouseEventHandler,
  FC,
  ReactElement,
  CSSProperties,
  SVGProps,
  ReactNode
} from "react";

import S, { classNameOverrides } from "./styles";

interface Props {
  onMenuItemClick?: MouseEventHandler<HTMLButtonElement>;
  text: string;
  IconLeading?: FC<SVGProps<SVGSVGElement>>;
  IconTrailing?: FC<SVGProps<SVGSVGElement>>;
  BadgeTrailing?: ReactElement;
  showSeparator?: boolean;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  href?: string;
  target?: string;
  isDeleteAction?: boolean;
}

const MenuItemCallToAction = ({
  href,
  target,
  onClick,
  disabled,
  children
}: {
  href?: string;
  target?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children: ReactNode;
}) => {
  if (href) {
    return (
      <S.MenuItemLink href={href} target={target}>
        {children}
      </S.MenuItemLink>
    );
  }
  return (
    <S.MenuItemButton onClick={onClick} disabled={disabled}>
      {children}
    </S.MenuItemButton>
  );
};

const MenuItem = ({
  onMenuItemClick = () => {},
  text,
  IconLeading,
  IconTrailing,
  BadgeTrailing,
  showSeparator,
  disabled,
  className,
  style,
  href,
  target,
  isDeleteAction = false
}: Props) => {
  return (
    <S.MenuItem
      isDeleteAction={isDeleteAction}
      className={className}
      style={style}
    >
      <MenuItemCallToAction
        onClick={onMenuItemClick}
        disabled={disabled}
        href={href}
        target={target}
      >
        {IconLeading && (
          <IconLeading
            role="presentation"
            className={classNameOverrides.menuIcons}
          />
        )}
        <S.MenuItemText isDeleteAction={isDeleteAction}>{text}</S.MenuItemText>
        {BadgeTrailing && BadgeTrailing}
        {IconTrailing && !BadgeTrailing ? (
          <IconTrailing
            role="presentation"
            className={classNameOverrides.menuIcons}
          />
        ) : null}
      </MenuItemCallToAction>
      {showSeparator && <S.Separator />}
    </S.MenuItem>
  );
};

export default MenuItem;
