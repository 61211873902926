import React, { FC } from "react";
import { Position, HandleType } from "reactflow";

import S from "./styles";

interface UBOHandleProps {
  type: HandleType;
  position: Position;
  isHidden?: boolean;
}

const UBOHandle: FC<UBOHandleProps> = ({ position, type, isHidden = false }) =>
  isHidden ? (
    <S.HiddenHandle position={position} type={type} />
  ) : (
    <S.Handle position={position} type={type} />
  );

export default UBOHandle;
