import React, { Fragment } from "react";
import angleDown from "./renderers/angle-down.svg";
import angleLeft from "./renderers/angle-left.svg";

export const InnerSectionHeader = props => {
  const { isExpanded, toggleExpanded, title, children, count } = props;
  const arrow = <img src={isExpanded ? angleDown : angleLeft} alt="Collapse" />;
  return (
    <div className="report-inner-section-header" onClick={toggleExpanded}>
      <div className="report-inner-section-header-title">{title}</div>
      <Fragment>{children}</Fragment>
      <div className="report-inner-section-header-count">{count}</div>
      {arrow}
    </div>
  );
};
