/* eslint-disable @typescript-eslint/no-unused-vars */
import { FetchResult } from "api/types";
import { ApiError } from "api/old-insights";
import {
  ReportsService,
  ShareTokenService,
  Report as RawReport,
  Section as RawSection,
  Subsection as RawSubsection,
  MoveSubsectionRequest,
  ReorderSubsectionsRequest,
  TimelineEvent,
  SectionIcon
} from "api/insights";
import { getErrorMessage } from "util/error-utils";
import { apm } from "@elastic/apm-rum";
import { isDevel } from "services/stage";
import { QuestionAndAnswer, Subsection } from "./types";

export enum GetReportErrorCodes {
  IN_PROGRESS_ERROR = "INSIGHTS_REPORT_IN_PROGRESS",
  NOT_FOUND_ERROR = "INSIGHTS_REPORT_NOT_FOUND",
  FAILED_GENERATING_ERROR = "INSIGHTS_REPORT_FAILED",
  NO_WAM_ERROR = "INSIGHTS_REPORT_NO_WAM",
  GENERIC_ERROR = "INTERNAL_SERVER_ERROR"
}

export enum GetTimelineErrorCodes {
  IN_PROGRESS_ERROR = "TIMELINE_IN_PROGRESS",
  NOT_FOUND_ERROR = "TIMELINE_NOT_FOUND",
  FAILED_ERROR = "TIMELINE_FAILED",
  GENERIC_ERROR = "INTERNAL_SERVER_ERROR"
}

export type {
  H1ContentNode,
  H2ContentNode,
  H3ContentNode,
  H4ContentNode,
  PContentNode,
  DivContentNode,
  TextContentNode,
  ContentNode,
  InsightReportSection,
  InsightReport
} from "./types";

type ErrorResponseWithBody = {
  body: {
    error: string;
  };
};

const hasErrorCode = (response: unknown): response is ErrorResponseWithBody =>
  response !== null &&
  typeof response === "object" &&
  "body" in response &&
  response.body !== null &&
  typeof response.body === "object" &&
  "error" in response.body &&
  typeof response.body.error === "string";

export default class InsightReports {
  async getSuggestedQuestions({
    id: reportId,
    personaId,
    subjectName
  }: {
    id: string;
    personaId?: string;
    subjectName: string;
  }): Promise<FetchResult<string[]>> {
    try {
      if (isDevel && personaId) {
        const response = await ReportsService.postReportsPersonasSuggestions({
          reportId,
          personaId,
          requestBody: { subject_name: subjectName }
        });
        return {
          status: true,
          response: response.suggestions
        };
      }

      const response = await ReportsService.postReportsSuggestions({
        reportId,
        requestBody: { subject_name: subjectName }
      });

      return {
        status: true,
        response: response.suggestions
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getSuggestedQuestionsWithShareToken({
    id: reportId,
    personaId,
    subjectName,
    shareToken
  }: {
    id: string;
    personaId?: string;
    subjectName: string;
    shareToken: string;
  }): Promise<FetchResult<string[]>> {
    try {
      if (isDevel && personaId) {
        const response = await ShareTokenService.postReportsPersonasSuggestions(
          {
            reportId,
            personaId,
            requestBody: { subject_name: subjectName },
            shareToken
          }
        );
        return {
          status: true,
          response: response.suggestions
        };
      }
      const response = await ShareTokenService.postReportsSuggestions({
        reportId,
        requestBody: { subject_name: subjectName },
        shareToken
      });

      return {
        status: true,
        response: response.suggestions
      };
    } catch (e) {
      apm.captureError(e as Error);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getReport({
    id: reportId,
    personaId
  }: {
    id: string;
    personaId?: string;
  }): Promise<FetchResult<RawReport>> {
    try {
      if (isDevel && personaId) {
        const response = await ReportsService.getReportsPersonas({
          reportId,
          personaId
        });
        return { status: true, response };
      }

      const response = await ReportsService.getReports({ reportId });
      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async getReportWithShareToken({
    id: reportId,
    personaId,
    shareToken
  }: {
    id: string;
    personaId?: string;
    shareToken: string;
  }): Promise<FetchResult<RawReport>> {
    try {
      if (isDevel && personaId) {
        const response = await ShareTokenService.getReportsPersonas({
          reportId,
          personaId,
          shareToken
        });
        return { status: true, response };
      }

      const response = await ShareTokenService.getReports({
        reportId,
        shareToken
      });

      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async getSources({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    subjectName,
    shareToken
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
    subsectionId: string;
    subjectName: string;
    shareToken: string | null;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      const requestBody = {
        subject_name: subjectName
      };

      if (isDevel && personaId) {
        if (shareToken) {
          const response =
            await ShareTokenService.postReportsPersonasSectionsSubsectionsSource(
              {
                reportId,
                personaId,
                sectionId,
                subsectionId,
                requestBody,
                shareToken
              }
            );
          return { status: true, response };
        }
        const response =
          await ReportsService.postReportsPersonasSectionsSubsectionsSource({
            reportId,
            personaId,
            sectionId,
            subsectionId,
            requestBody
          });
        return { status: true, response };
      }

      if (shareToken) {
        const response =
          await ShareTokenService.postReportsSectionsSubsectionsSource({
            reportId,
            sectionId,
            subsectionId,
            requestBody,
            shareToken
          });
        return { status: true, response };
      }
      const response =
        await ReportsService.postReportsSectionsSubsectionsSource({
          reportId,
          sectionId,
          subsectionId,
          requestBody
        });
      return { status: true, response };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getSourcesWithShareToken({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    subjectName,
    shareToken
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
    subsectionId: string;
    subjectName: string;
    shareToken: string;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      const requestBody = {
        subject_name: subjectName
      };

      if (isDevel && personaId) {
        const response =
          await ShareTokenService.postReportsPersonasSectionsSubsectionsSource({
            reportId,
            personaId,
            sectionId,
            subsectionId,
            requestBody,
            shareToken
          });
        return { status: true, response };
      }

      const response =
        await ShareTokenService.postReportsSectionsSubsectionsSource({
          reportId,
          sectionId,
          subsectionId,
          requestBody,
          shareToken
        });

      return { status: true, response };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getQuestionsAndAnswers({
    enquiryId
  }: {
    enquiryId: string;
  }): Promise<FetchResult<QuestionAndAnswer[]>> {
    try {
      console.warn(`Get questionsAndAnswers Not implemented: ${enquiryId}`);

      return { status: true, response: [] };
    } catch (e) {
      apm.captureError(e as Error);
      if (e instanceof ApiError && e.status === 404) {
        return {
          status: true,
          response: []
        };
      }

      return { status: false, message: getErrorMessage(e) };
    }
  }

  async askSourcedQuestion({
    enquiryId,
    personaId,
    question,
    subjectName,
    shareToken
  }: {
    enquiryId: string;
    personaId?: string;
    question: string;
    subjectName: string;
    shareToken: string | null;
  }): Promise<FetchResult<RawSubsection>> {
    try {
      if (isDevel && personaId) {
        if (shareToken) {
          const response = await ShareTokenService.postReportsPersonasQna({
            reportId: enquiryId,
            personaId,
            requestBody: { subject_name: subjectName, question },
            shareToken
          });
          return {
            status: true,
            response
          };
        }
        const response = await ReportsService.postReportsPersonasQna({
          reportId: enquiryId,
          personaId,
          requestBody: { subject_name: subjectName, question }
        });
        return {
          status: true,
          response
        };
      }

      if (shareToken) {
        const response = await ShareTokenService.postReportsQna({
          reportId: enquiryId,
          requestBody: { subject_name: subjectName, question },
          shareToken
        });
        return {
          status: true,
          response
        };
      }
      const response = await ReportsService.postReportsQna({
        reportId: enquiryId,
        requestBody: { subject_name: subjectName, question }
      });

      return {
        status: true,
        response
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async addSourcesToElement(
    _sectionId: string,
    _enquiryId: string,
    _subjectName: string
  ): Promise<FetchResult<Subsection>> {
    try {
      return {
        status: true,
        response: {
          id: "",
          title: "",
          elements: []
        }
      };
    } catch (e) {
      apm.captureError(e as Error);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async provideFeedback({
    id,
    personaId,
    subjectName,
    feedbackText,
    section,
    subsection
  }: {
    id: string;
    personaId?: string;
    subjectName: string;
    feedbackText: string;
    section?: string;
    subsection?: string;
  }): Promise<FetchResult> {
    try {
      const requestBody = {
        subject_name: subjectName,
        message: feedbackText,
        section,
        subsection
      };
      if (isDevel && personaId) {
        await ReportsService.postReportsPersonasFeedback({
          reportId: id,
          personaId,
          requestBody
        });
        return { status: true };
      }
      await ReportsService.postReportsFeedback({
        reportId: id,
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeSection({
    reportId,
    personaId,
    sectionId
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.deleteReportsPersonasSections({
          reportId,
          personaId,
          sectionId
        });
        return { status: true };
      }
      await ReportsService.deleteReportsSections({
        reportId,
        sectionId
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeSubSection({
    reportId,
    personaId,
    sectionId,
    subsectionId
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
    subsectionId: string;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.deleteReportsPersonasSectionsSubsections({
          reportId,
          personaId,
          sectionId,
          subsectionId
        });
        return { status: true };
      }
      await ReportsService.deleteReportsSectionsSubsections({
        reportId,
        sectionId,
        subsectionId
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeElement({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    elementId
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
    subsectionId: string;
    elementId: string;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.deleteReportsPersonasSectionsSubsectionsElements({
          reportId,
          personaId,
          sectionId,
          subsectionId,
          elementId
        });
        return { status: true };
      }
      await ReportsService.deleteReportsSectionsSubsectionsElements({
        reportId,
        sectionId,
        subsectionId,
        elementId
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async moveSubSection({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    requestBody
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
    subsectionId: string;
    requestBody: MoveSubsectionRequest;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.postReportsPersonasSectionsSubsectionsMove({
          reportId,
          personaId,
          sectionId,
          subsectionId,
          requestBody
        });
        return { status: true };
      }
      await ReportsService.postReportsSectionsSubsectionsMove({
        reportId,
        sectionId,
        subsectionId,
        requestBody
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async reorderSubSections({
    reportId,
    personaId,
    sectionId,
    requestBody
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
    requestBody: ReorderSubsectionsRequest;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.postReportsPersonasSectionsReorder({
          reportId,
          personaId,
          sectionId,
          requestBody
        });
        return { status: true };
      }
      await ReportsService.postReportsSectionsReorder({
        reportId,
        sectionId,
        requestBody
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async removeReport({
    reportId,
    personaId
  }: {
    reportId: string;
    personaId?: string;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.deleteReportsPersonas({ reportId, personaId });
        return { status: true };
      }
      await ReportsService.deleteReports({ reportId });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async regenerateReport({
    reportId,
    personaId
  }: {
    reportId: string;
    personaId?: string;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.postReportsPersonasRegenerate({
          reportId,
          personaId
        });
        return { status: true };
      }
      await ReportsService.postReportsRegenerate({ reportId });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async addToSection({
    reportId,
    personaId,
    answerId,
    sectionId
  }: {
    reportId: string;
    personaId?: string;
    answerId: string;
    sectionId: string;
  }): Promise<FetchResult> {
    try {
      if (isDevel && personaId) {
        await ReportsService.postReportsPersonasQnaAnswersSave({
          reportId,
          personaId,
          answerId,
          requestBody: { sectionId }
        });
        return { status: true };
      }
      await ReportsService.postReportsQnaAnswersSave({
        reportId,
        answerId,
        requestBody: { sectionId }
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async getTimeline({
    id: reportId,
    personaId
  }: {
    id: string;
    personaId?: string;
  }): Promise<FetchResult<TimelineEvent[]>> {
    try {
      if (isDevel && personaId) {
        const response = await ReportsService.getReportsPersonasTimeline({
          reportId,
          personaId
        });
        return { status: true, response };
      }

      const response = await ReportsService.getReportsTimeline({
        reportId
      });
      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async removeReportTimelineEvent({
    reportId,
    personaId,
    eventId
  }: {
    reportId: string;
    personaId?: string;
    eventId: string;
  }): Promise<FetchResult<TimelineEvent[]>> {
    try {
      if (isDevel && personaId) {
        await ReportsService.deleteReportsPersonasTimelineEvents({
          reportId,
          personaId,
          eventId
        });
        return { status: true };
      }
      await ReportsService.deleteReportsTimelineEvents({
        reportId,
        eventId
      });

      return { status: true };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetTimelineErrorCodes.GENERIC_ERROR };
    }
  }

  async getTimelineWithShareToken({
    id: reportId,
    personaId,
    shareToken
  }: {
    id: string;
    personaId?: string;
    shareToken: string;
  }): Promise<FetchResult<TimelineEvent[]>> {
    try {
      if (isDevel && personaId) {
        const response = await ShareTokenService.getReportsPersonasTimeline({
          reportId,
          personaId,
          shareToken
        });
        return { status: true, response };
      }
      const response = await ShareTokenService.getReportsTimeline({
        reportId,
        shareToken
      });

      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async updateSectionTitle({
    reportId,
    personaId,
    sectionId,
    subsectionId,
    elementId,
    title
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
    subsectionId?: string;
    elementId?: string;
    title: string;
  }): Promise<FetchResult> {
    try {
      const requestBody = {
        title
      };

      if (isDevel && personaId) {
        if (elementId && subsectionId) {
          await ReportsService.putReportsPersonasSectionsSubsectionsElements({
            reportId,
            personaId,
            sectionId,
            subsectionId,
            elementId,
            requestBody
          });
          return { status: true };
        }
        if (subsectionId) {
          await ReportsService.putReportsPersonasSectionsSubsections({
            reportId,
            personaId,
            sectionId,
            subsectionId,
            requestBody
          });
          return { status: true };
        }
        await ReportsService.putReportsPersonasSections({
          reportId,
          personaId,
          sectionId,
          requestBody
        });
        return { status: true };
      }

      if (elementId && subsectionId) {
        await ReportsService.putReportsSectionsSubsectionsElements({
          reportId,
          sectionId,
          subsectionId,
          elementId,
          requestBody
        });
        return { status: true };
      }

      if (subsectionId) {
        await ReportsService.putReportsSectionsSubsections({
          reportId,
          sectionId,
          subsectionId,
          requestBody
        });
        return { status: true };
      }

      await ReportsService.putReportsSections({
        reportId,
        sectionId,
        requestBody
      });

      return { status: true };
    } catch (e: unknown) {
      apm.captureError(e as Error);
      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }
      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }

  async addNewSection({
    reportId,
    personaId,
    sectionId
  }: {
    reportId: string;
    personaId?: string;
    sectionId: string;
  }): Promise<FetchResult<RawSection>> {
    try {
      const requestBody = { sectionId };
      if (isDevel && personaId) {
        const response = await ReportsService.postReportsPersonasSections({
          reportId,
          personaId,
          requestBody
        });
        return { status: true, response };
      }
      const response = await ReportsService.postReportsSections({
        reportId,
        requestBody
      });
      return { status: true, response };
    } catch (e: unknown) {
      apm.captureError(e as Error);

      if (hasErrorCode(e)) {
        return { status: false, message: e.body.error };
      }

      return { status: false, message: GetReportErrorCodes.GENERIC_ERROR };
    }
  }
}
