import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Nav, NavItem, NavLink } from "reactstrap";

import { withParams, withLocation, withNavigate } from "util/routerHocs";
import Button from "components/atoms/Button";

import DiagnosticOverview from "./DiagnosticsOverview";

class Diagnostics extends React.Component {
  constructor(props) {
    super(props);

    this.reportReady = this.reportReady.bind(this);
  }

  componentDidUpdate() {
    if (this.textArea) {
      this.textArea.scrollTop = 0;
    }
  }

  reportReady() {
    const { diagnosticsStore } = this.props;
    const { loaded, enquiryOverview } = diagnosticsStore;

    return loaded && enquiryOverview ? enquiryOverview.reportReady : false;
  }

  render() {
    const { diagnosticsStore, navigate, params, location } = this.props;
    const { notFound } = diagnosticsStore;
    let reportButtons;

    if (!notFound) {
      reportButtons = (
        <>
          {this.reportReady() ? (
            <Button onClick={() => navigate(`/report/${params.enquiryId}`)}>
              Go to Report
            </Button>
          ) : (
            <Button
              onClick={() => navigate(`/report/preparing/${params.enquiryId}`)}
            >
              Go to Preparing Report
            </Button>
          )}
        </>
      );
    }

    return (
      <div className="shell-outer-padded">
        <div className="shell-inner">
          <div className="simple-info">
            <h1 className="large" style={{ marginTop: 0 }}>
              Enquiry Diagnostics
            </h1>
            <DiagnosticOverview />
            <div className="toolbar">
              <div className="toolbar-left">
                <Nav pills>
                  <NavItem>
                    <NavLink
                      to={`/diagnostics/${params.enquiryId}`}
                      tag={Link}
                      active={
                        location.pathname === `/diagnostics/${params.enquiryId}`
                      }
                    >
                      Overview
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div className="toolbar-right">{reportButtons}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigate(
  withParams(withLocation(inject("diagnosticsStore")(observer(Diagnostics))))
);
