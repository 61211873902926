import { styled } from "@linaria/react";

import { ReactComponent as TickSVG } from "img/icons/tick.svg";
import { grey, purple } from "styles/colors";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";

const Textarea = styled.textarea`
  width: 100%;
  height: 162px;
  border-radius: 4px;
  border: 1px solid ${isPDX ? theme.primaryColor : purple.darker};
  padding: 1rem;

  ::placeholder {
    color: ${grey.ghost};
  }
`;

const TickIcon = styled(TickSVG)`
  color: ${purple.darker};
`;

const S = {
  Textarea,
  TickIcon
};

export default S;
