import React, { Fragment } from "react";

import { usePrintableReportState } from "util/hooks/usePrintableState";
import { TruncateLength } from "util/TruncateLength";
import { UncertainDataFlag } from "components/atoms/UncertainDataFlag";

import S from "./styles";

const PaginatedList = props => {
  const { dataToDisplay, title, subTitle, printKey, id } = props;
  const uid = printKey;
  const paginationLimit = 10;

  const [paginationStart, setPaginationStart] = usePrintableReportState(
    `paginationStart_${uid}`,
    0
  );
  const [showNext, setShowNext] = usePrintableReportState(
    `showNext_${uid}`,
    dataToDisplay.length > paginationLimit ? "inline" : "none"
  );
  const [showPrev, setShowPrev] = usePrintableReportState(
    `showPrev_${uid}`,
    "none"
  );

  const renderItem = itemData => {
    return (
      <>
        {itemData.isUncertain ? <UncertainDataFlag /> : ""}
        {itemData.url ? (
          <S.ItemLink href={itemData.url}>
            <S.BulletContainer>
              <S.Bullet />
            </S.BulletContainer>
            <TruncateLength>{itemData.text}</TruncateLength>
            &nbsp; {/* This is hack for DI-2202 */}
          </S.ItemLink>
        ) : (
          <TruncateLength>{itemData.text}</TruncateLength>
        )}
      </>
    );
  };
  const renderData = data => {
    return data
      .slice(paginationStart, paginationStart + paginationLimit)
      .map((item, index) => {
        return (
          <S.ListItem key={`extraData_${index}`}>{renderItem(item)}</S.ListItem>
        );
      });
  };

  const paginationControl = {
    direction: {
      prev: "prev",
      next: "next"
    }
  };
  Object.freeze(paginationControl.direction);
  const handleShowPaginationControls = newIndex => {
    setShowNext(
      newIndex + paginationLimit < dataToDisplay.length ? "block" : "none"
    );
    setShowPrev(newIndex > paginationLimit - 1 ? "block" : "none");
  };
  const handlePagination = type => {
    const newIndex =
      type === paginationControl.direction.next
        ? paginationStart + paginationLimit
        : paginationStart - paginationLimit;
    setPaginationStart(newIndex);
    handleShowPaginationControls(newIndex);
  };
  const handlePaginationNext = () => {
    handlePagination(paginationControl.direction.next);
  };
  const handlePaginationPrev = () => {
    handlePagination(paginationControl.direction.prev);
  };

  return (
    <S.Accordion
      // set to false to not show 0 in Business Associate cards - but I am leaving it here because I believe it will be asked for again SEE CORE-108
      displayCount={false}
      dataToDisplay={
        <S.ListContainer>
          <S.ContentContainer isExpanded>
            <S.DescriptionList>
              <S.DescriptionDetails>
                <S.List>{renderData(dataToDisplay)}</S.List>
              </S.DescriptionDetails>
            </S.DescriptionList>
            <S.Pagination
              style={{
                display:
                  showPrev !== "none" || showNext !== "none" ? "flex" : "none"
              }}
            >
              <S.PaginationPrevControl
                style={{ display: showPrev }}
                onClick={handlePaginationPrev}
              >
                Prev
              </S.PaginationPrevControl>
              <S.PaginationNextControl
                style={{ display: showNext }}
                onClick={handlePaginationNext}
              >
                Next
              </S.PaginationNextControl>
            </S.Pagination>
          </S.ContentContainer>
        </S.ListContainer>
      }
      id={id}
      type="custom"
      title={
        <>
          <S.OverlapTitle>{title}</S.OverlapTitle> {subTitle}
        </>
      }
    />
  );
};
export default PaginatedList;
