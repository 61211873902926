import React, { FC, ReactNode, useMemo } from "react";

import { InformationSource } from "api/report/report-types";
import WithInspector from "components/organisms/WithInspector";

import { SourceSupportDetails } from "api/insight-reports/types";
import S from "./styles";

export interface SourceInspectorSource extends InformationSource {
  supportDetails?: SourceSupportDetails;
}

export interface SourceInspectorProps {
  title: string;
  sources: SourceInspectorSource[];
  display: "block" | "inline";
  onOpen?: (open: boolean) => void;
  children: ReactNode;
  showXiReactToolTip?: boolean;
}

const SourceInspector: FC<SourceInspectorProps> = ({
  title,
  sources,
  display,
  onOpen,
  children,
  showXiReactToolTip = false
}) => {
  const key = useMemo(
    () => sources.map(source => source.id).join("-"),
    [sources]
  );

  const handleInspectorOpen = (open: boolean) => {
    if (onOpen) {
      onOpen(open);
    }
  };

  return (
    <WithInspector
      key={`SourceInspector-${key}`}
      display={display}
      sources={sources}
      popoverTitle={title}
      showTopSection={false}
      isInspectorOpen={handleInspectorOpen}
      withPagination
      showXiReactToolTip={showXiReactToolTip}
      renderSourceItems={(
        _sources,
        _filter,
        ref,
        page,
        paginationComponent,
        resizeHeight
      ) => {
        return (
          <S.SourceContainer ref={ref}>
            {sources[page] && (
              // @ts-ignore
              <S.MediaSourceCard
                {...sources[page]}
                // TODO: MediaSourceCard needs types
                show
                showAssessment
                key={`${sources[page].url}_${page}`}
                activeRiskDirectionality="direct"
                filterOutIndirectTags
                paginationComponent={paginationComponent}
                resizeHeight={resizeHeight}
                hideTags
              />
            )}
          </S.SourceContainer>
        );
      }}
    >
      {children}
    </WithInspector>
  );
};

export default SourceInspector;
