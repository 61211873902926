import React from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { ProductFruits } from "react-product-fruits";

import { useAuthentication } from "./hooks/useAuthentication";

const ProductFruitsTour = () => {
  const {
    state: { username, status }
  } = useAuthentication();

  // ToDo: when logging successfully for the first time, username is
  // initially undefined but it is later (asynchronously?) updated,
  // this should trigger a DOM re-render and show ProductFruits but
  // it is not
  if (status !== "authenticated") {
    return null;
  }

  return (
    <ProductFruits
      workspaceCode="eqYouRdKprdiZhIW"
      language="en"
      user={{ username: username || "unknown" }}
    />
  );
};

export default ProductFruitsTour;
