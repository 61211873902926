import React from "react";
import { ReactComponent as UncertaintyTilde } from "img/icons/uncertainty_tilde.svg";
import ReactTooltip from "components/atoms/ReactTooltip";

import S from "./styles";

export const UncertainDataFlag = () => {
  const tooltip =
    "This information should be treated as low confidence as it has not been corroborated";
  return (
    <ReactTooltip tooltip={tooltip}>
      <S.FlagContainer>
        <UncertaintyTilde />
      </S.FlagContainer>
    </ReactTooltip>
  );
};
