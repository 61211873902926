import { useEffect, useRef } from "react";

export const usePreviousValue = value => {
  const ref = useRef();

  // The function in this useEffect hook is called only _after_ the render of the component.
  // So, on the initial use, this function will return undefined. When the component renders, only then will the useEffect
  // hit and the value in the ref will update. On rerender, your component will use the value set previously etc.
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
