import { styled } from "@linaria/react";
import { CardVariant } from "components/molecules/Card/types";

import { blue, grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Header = styled.header`
  display: flex;
  flex-direction: column;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

const TitleContainer = styled.div<{ variant: CardVariant }>`
  display: flex;
  flex-direction: column;
  padding-top: 0.375rem;
  gap: 0.125rem;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? standardColors.white : blue.xapienDark};
`;

const Subtitle = styled.p<{ variant: CardVariant }>`
  margin: 0;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
  font-size: ${fontSize.xs};
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const S = {
  Header,
  AvatarContainer,
  TitleContainer,
  Subtitle
};

export default S;
