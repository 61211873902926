import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { motion } from "framer-motion";

import { ReactComponent as Logo } from "img/icons/xapien-logo-icon.svg";
import { grey, red, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Message = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: flex-start;
  font-size: ${fontSize.sm};
  white-space-collapse: preserve-breaks;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:not(:last-of-type):nth-of-type(2n) {
    border-bottom: 1px solid ${grey.rule};
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const XapienLogo = styled(Logo)`
  transform: scale(0.5);
  path {
    fill: ${standardColors.white};
  }
`;

const Error = styled.p`
  margin: 0;
  color: ${red.directRiskOutline};
`;

const FetchingImage = styled.img`
  width: 64px;
  height: 64px;
`;

const FetchingSourcesImage = styled.img`
  width: 32px;
  height: 32px;
`;

const AnswerContainer = styled.div`
  width: 100%;
`;

/* ClassNameOverrides */

const AnswerAvatar = css`
  background: ${standardColors.white} !important;

  path {
    fill: ${standardColors.black};
  }
`;

const QuestionAvatar = css`
  background: ${standardColors.black} !important;
  font-size: ${fontSize.sm} !important;
`;

export const classNameOverrides = {
  AnswerAvatar,
  QuestionAvatar
};

const S = {
  AnswerContainer,
  Message,
  XapienLogo,
  Error,
  FetchingImage,
  FetchingSourcesImage
};

export default S;
