import { styled } from "@linaria/react";

import { family, fontSize } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

const CircleContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${standardColors.white}
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${grey.ghost};

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.div<{ size: string }>`
  width: ${({ size }) => (size === "sm" ? "24px" : "34px")};
  max-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSize["2xs"]};

  img {
    margin-top: 6px;
    width: 12px;
    height: 12px;
    opacity: 0.6;
    transition: all 0.3s ease-in-out;
    margin-bottom: 6px;
    margin-top: 6px;

    &:hover {
      width: 24px;
      height: 24px;
      margin-top: 0;
    }
  }
`;

const CountryCode = styled.div`
  font-size: ${fontSize["2xs"]};
  color: ${grey.mid};
  font-family: ${family.interSemiBold};
`;

const S = {
  Container,
  CountryCode,
  CircleContainer
};

export default S;
