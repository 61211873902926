/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Idam_Contracts_Enums_GroupUserRole } from "../models/Idam_Contracts_Enums_GroupUserRole";
import type { Idam_Contracts_Enums_OrganisationRole } from "../models/Idam_Contracts_Enums_OrganisationRole";
import type { Idam_Contracts_Enums_UsagePeriod } from "../models/Idam_Contracts_Enums_UsagePeriod";
import type { Idam_Contracts_Organisations_CreateOrganisationGroupRequest } from "../models/Idam_Contracts_Organisations_CreateOrganisationGroupRequest";
import type { Idam_Contracts_Organisations_CreateOrganisationGroupResponse } from "../models/Idam_Contracts_Organisations_CreateOrganisationGroupResponse";
import type { Idam_Contracts_Organisations_CreateOrganisationUserRequest } from "../models/Idam_Contracts_Organisations_CreateOrganisationUserRequest";
import type { Idam_Contracts_Organisations_CreateOrganisationUserResponse } from "../models/Idam_Contracts_Organisations_CreateOrganisationUserResponse";
import type { Idam_Contracts_Organisations_ExportOrganisationUsageResponse } from "../models/Idam_Contracts_Organisations_ExportOrganisationUsageResponse";
import type { Idam_Contracts_Organisations_GetOrganisationGroupResponse } from "../models/Idam_Contracts_Organisations_GetOrganisationGroupResponse";
import type { Idam_Contracts_Organisations_GetOrganisationGroupsResponse } from "../models/Idam_Contracts_Organisations_GetOrganisationGroupsResponse";
import type { Idam_Contracts_Organisations_GetOrganisationPreferencesResponse } from "../models/Idam_Contracts_Organisations_GetOrganisationPreferencesResponse";
import type { Idam_Contracts_Organisations_GetOrganisationUsageResponse } from "../models/Idam_Contracts_Organisations_GetOrganisationUsageResponse";
import type { Idam_Contracts_Organisations_InviteUsersRequest } from "../models/Idam_Contracts_Organisations_InviteUsersRequest";
import type { Idam_Contracts_Organisations_OrganisationSummary } from "../models/Idam_Contracts_Organisations_OrganisationSummary";
import type { Idam_Contracts_Organisations_RequestInviteRequest } from "../models/Idam_Contracts_Organisations_RequestInviteRequest";
import type { Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest } from "../models/Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest";
import type { Idam_Contracts_Organisations_UpdateOrganisationGroupRequest } from "../models/Idam_Contracts_Organisations_UpdateOrganisationGroupRequest";
import type { Idam_Contracts_Organisations_UpdateOrganisationPreferencesRequest } from "../models/Idam_Contracts_Organisations_UpdateOrganisationPreferencesRequest";
import type { Idam_Contracts_Organisations_UpdateOrganisationRequest } from "../models/Idam_Contracts_Organisations_UpdateOrganisationRequest";
import type { Idam_Contracts_Organisations_UpdateOrganisationUserRequest } from "../models/Idam_Contracts_Organisations_UpdateOrganisationUserRequest";
import type { Idam_Contracts_Reports_SearchRequest } from "../models/Idam_Contracts_Reports_SearchRequest";
import type { Idam_Contracts_Reports_SearchResponse } from "../models/Idam_Contracts_Reports_SearchResponse";
import type { Idam_Contracts_Tenants_OrganisationUser } from "../models/Idam_Contracts_Tenants_OrganisationUser";
import type { PortalMockAPI_Models_Identity_OrganisationProjects } from "../models/PortalMockAPI_Models_Identity_OrganisationProjects";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class IdentityOrganisationApiService {
  /**
   * @returns Idam_Contracts_Organisations_OrganisationSummary Success
   * @throws ApiError
   */
  public static getOrganisations(): CancelablePromise<Idam_Contracts_Organisations_OrganisationSummary> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisations({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Organisations_UpdateOrganisationRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postOrganisationsInviteRequest({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Organisations_RequestInviteRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/invite-request",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_GetOrganisationPreferencesResponse Success
   * @throws ApiError
   */
  public static getOrganisationsPreferences(): CancelablePromise<Idam_Contracts_Organisations_GetOrganisationPreferencesResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/preferences"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisationsPreferences({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Organisations_UpdateOrganisationPreferencesRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations/preferences",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns PortalMockAPI_Models_Identity_OrganisationProjects Success
   * @throws ApiError
   */
  public static getOrganisationsProjects(): CancelablePromise<
    Array<PortalMockAPI_Models_Identity_OrganisationProjects>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/projects"
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_OrganisationUser Success
   * @throws ApiError
   */
  public static getOrganisationsUsers(): CancelablePromise<
    Array<Idam_Contracts_Tenants_OrganisationUser>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/users"
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_CreateOrganisationUserResponse Success
   * @throws ApiError
   */
  public static postOrganisationsUsers({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Organisations_CreateOrganisationUserRequest;
  }): CancelablePromise<Idam_Contracts_Organisations_CreateOrganisationUserResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/users",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Tenants_OrganisationUser Success
   * @throws ApiError
   */
  public static getOrganisationsUsers1({
    userId
  }: {
    userId: string;
  }): CancelablePromise<Idam_Contracts_Tenants_OrganisationUser> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/users/{userId}",
      path: {
        userId: userId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteOrganisationsUsers({
    userId,
    deleteReports = false,
    newReportOwnerId
  }: {
    userId: string;
    deleteReports?: boolean;
    newReportOwnerId?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organisations/users/{userId}",
      path: {
        userId: userId
      },
      query: {
        deleteReports: deleteReports,
        newReportOwnerId: newReportOwnerId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisationsUsers({
    userId,
    requestBody
  }: {
    userId: string;
    requestBody?: Idam_Contracts_Organisations_UpdateOrganisationUserRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations/users/{userId}",
      path: {
        userId: userId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisationsUsersRole({
    userId,
    role
  }: {
    userId: string;
    role: Idam_Contracts_Enums_OrganisationRole;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations/users/{userId}/role/{role}",
      path: {
        userId: userId,
        role: role
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_SearchResponse Success
   * @throws ApiError
   */
  public static postOrganisationsUsersReportsSearch({
    userId,
    requestBody
  }: {
    userId: string;
    requestBody?: Idam_Contracts_Reports_SearchRequest;
  }): CancelablePromise<Idam_Contracts_Reports_SearchResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/users/{userId}/reports/search",
      path: {
        userId: userId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postOrganisationsUsersReportsTransfer({
    userId,
    newOwnerUserId
  }: {
    userId: string;
    newOwnerUserId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/users/{userId}/reports/transfer/{newOwnerUserId}",
      path: {
        userId: userId,
        newOwnerUserId: newOwnerUserId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_GetOrganisationGroupsResponse Success
   * @throws ApiError
   */
  public static getOrganisationsGroups(): CancelablePromise<
    Array<Idam_Contracts_Organisations_GetOrganisationGroupsResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/groups"
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_CreateOrganisationGroupResponse Success
   * @throws ApiError
   */
  public static postOrganisationsGroups({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Organisations_CreateOrganisationGroupRequest;
  }): CancelablePromise<Idam_Contracts_Organisations_CreateOrganisationGroupResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/groups",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_GetOrganisationGroupResponse Success
   * @throws ApiError
   */
  public static getOrganisationsGroups1({
    groupId
  }: {
    groupId: string;
  }): CancelablePromise<Idam_Contracts_Organisations_GetOrganisationGroupResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/groups/{groupId}",
      path: {
        groupId: groupId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static deleteOrganisationsGroups({
    groupId
  }: {
    groupId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organisations/groups/{groupId}",
      path: {
        groupId: groupId
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisationsGroups({
    groupId,
    requestBody
  }: {
    groupId: string;
    requestBody?: Idam_Contracts_Organisations_UpdateOrganisationGroupRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations/groups/{groupId}",
      path: {
        groupId: groupId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisationsGroupsMembers({
    groupId,
    requestBody
  }: {
    groupId: string;
    requestBody?: Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations/groups/{groupId}/members",
      path: {
        groupId: groupId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisationsGroupsMembersRole({
    groupId,
    userId,
    role
  }: {
    groupId: string;
    userId: string;
    role: Idam_Contracts_Enums_GroupUserRole;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations/groups/{groupId}/members/{userId}/role/{role}",
      path: {
        groupId: groupId,
        userId: userId,
        role: role
      }
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static putOrganisationsGroupsAdmins({
    groupId,
    requestBody
  }: {
    groupId: string;
    requestBody?: Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/organisations/groups/{groupId}/admins",
      path: {
        groupId: groupId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postOrganisationsGroupsLeave({
    groupId
  }: {
    groupId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/groups/{groupId}/leave",
      path: {
        groupId: groupId
      }
    });
  }

  /**
   * @returns Idam_Contracts_Reports_SearchResponse Success
   * @throws ApiError
   */
  public static postOrganisationsGroupsReportsSearch({
    groupId,
    requestBody
  }: {
    groupId: string;
    requestBody?: Idam_Contracts_Reports_SearchRequest;
  }): CancelablePromise<Idam_Contracts_Reports_SearchResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/groups/{groupId}/reports/search",
      path: {
        groupId: groupId
      },
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns any Success
   * @throws ApiError
   */
  public static postOrganisationsUsersInvite({
    requestBody
  }: {
    requestBody?: Idam_Contracts_Organisations_InviteUsersRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organisations/users/invite",
      body: requestBody,
      mediaType: "application/json"
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_GetOrganisationUsageResponse Success
   * @throws ApiError
   */
  public static getOrganisationsUsage(): CancelablePromise<Idam_Contracts_Organisations_GetOrganisationUsageResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/usage"
    });
  }

  /**
   * @returns Idam_Contracts_Organisations_ExportOrganisationUsageResponse Success
   * @throws ApiError
   */
  public static getOrganisationsUsageExport({
    usagePeriod
  }: {
    usagePeriod?: Idam_Contracts_Enums_UsagePeriod;
  }): CancelablePromise<Idam_Contracts_Organisations_ExportOrganisationUsageResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organisations/usage/export",
      query: {
        usagePeriod: usagePeriod
      }
    });
  }
}
