import React, {
  useEffect,
  useReducer,
  useMemo,
  useCallback,
  ReactNode
} from "react";
import { RequestQueueContext, queueReducer, initialState } from "./context";

import type { RequestQueueAction, RequestJob, RequestJobData } from "./types";
import {
  RequestQueueStatus,
  RequestJobStatus,
  RequestQueueActions
} from "./types";

export const RequestQueueContextProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [state, requestQueueDispatch] = useReducer(queueReducer, initialState);

  const dispatch = useCallback((action: RequestQueueAction) => {
    requestQueueDispatch(action);
  }, []);

  const addJob = useCallback(
    (job: RequestJobData) =>
      dispatch({ type: RequestQueueActions.addJob, job }),
    [dispatch]
  );

  const providerValue = useMemo(
    () => ({ state, dispatch, addJob }),
    [state, dispatch, addJob]
  );

  const processJobTask = useCallback(
    (job: RequestJob) => {
      try {
        job
          .task()
          .then((taskResponse: unknown) =>
            dispatch({ type: RequestQueueActions.jobCompleted, taskResponse })
          )

          .catch((error: unknown) =>
            dispatch({ type: RequestQueueActions.jobFailed, error })
          );
      } catch (error: unknown) {
        dispatch({ type: RequestQueueActions.jobFailed, error });
      }
    },
    [dispatch]
  );

  const processCallbacks = useCallback(() => {
    state.callbackQueue.forEach(job => job.callback(job.callbackData));

    dispatch({ type: RequestQueueActions.jobCallbacksCompleted });
  }, [dispatch, state.callbackQueue]);

  useEffect(() => {
    if (state.status !== RequestQueueStatus.processingCallbacks) return;

    processCallbacks();
  }, [state.status, processCallbacks]);

  useEffect(() => {
    if (state.status !== RequestQueueStatus.idle) return;
    if (state.requestQueue.length > 0) return;
    if (state.callbackQueue.length === 0) return;

    dispatch({ type: RequestQueueActions.processCallbacks });
  }, [state.status, state.requestQueue, state.callbackQueue, dispatch]);

  useEffect(() => {
    if (state.status !== RequestQueueStatus.idle) return;

    if (state.requestQueue.length > 0) {
      const jobToProcess = state.requestQueue[0];

      if (jobToProcess.status === RequestJobStatus.error) {
        dispatch({
          type: RequestQueueActions.queueFailed,
          error: "Queue has an errored job at the top of the queue"
        });
        return;
      }

      dispatch({
        type: RequestQueueActions.processJob,
        jobId: jobToProcess.id
      });
    }
  }, [state.status, state.requestQueue, dispatch]);

  useEffect(() => {
    if (state.status !== RequestQueueStatus.processingTask) return;

    if (state.requestQueue.length === 0) {
      dispatch({ type: RequestQueueActions.resetStatus });

      return;
    }

    const jobToProcess = state.requestQueue.find(
      job => job.id === state.processingJobId
    );

    if (!jobToProcess) {
      dispatch({ type: RequestQueueActions.resetStatus });
      return;
    }

    if (jobToProcess.status === RequestJobStatus.processingTask) return;

    if (jobToProcess.status === RequestJobStatus.error) {
      dispatch({
        type: RequestQueueActions.queueFailed,
        error: "Queue has an errored job at the top of the queue"
      });

      return;
    }

    dispatch({ type: RequestQueueActions.processJobTask });

    processJobTask(jobToProcess);
  }, [
    state.status,
    state.requestQueue,
    state.processingJobId,
    dispatch,
    processJobTask
  ]);

  return (
    <RequestQueueContext.Provider value={providerValue}>
      {children}
    </RequestQueueContext.Provider>
  );
};
