import { css } from "@linaria/core";
import { styled } from "@linaria/react";

import { grey, standardColors, red } from "styles/colors";
import { fontSize } from "styles/fonts";

/** ClassNameOverrides */

const menuIcons = css`
  width: 1rem;
  height: 1rem;
`;

/** Styled components */

const MenuItem = styled.li<{ isDeleteAction: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  color: ${({ isDeleteAction }) =>
    isDeleteAction ? red.directRiskOutline : grey.dark};
`;

const MenuItemText = styled.p<{ isDeleteAction: boolean }>`
  margin: 0;
  font-size: ${fontSize.sm};
  line-height: 1.125rem;
  color: ${({ isDeleteAction }) =>
    isDeleteAction ? red.directRiskOutline : grey.dark};
  flex: 1;
`;

const MenuItemButton = styled.button`
  all: unset;
  padding: 0.75rem;
  background-color: ${standardColors.white};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s;

  &:not(:disabled) {
    cursor: pointer;
  }

  ${menuIcons} {
    path {
      stroke: ${grey.dark};
    }
  }

  &:hover,
  &:focus {
    background-color: ${grey.faint};
  }

  &:active {
    background-color: ${grey.rule};
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    background-color: ${grey.lighterPanel};
    color: ${grey.mid};
    .${menuIcons} {
      path {
        stroke: ${grey.mid};
      }
    }
    ${MenuItemText} {
      color: ${grey.mid};
    }
  }
`;

const MenuItemLink = styled.a`
  all: unset;
  padding: 0.75rem;
  background-color: ${standardColors.white};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s;

  &:not(:disabled) {
    cursor: pointer;
  }

  ${menuIcons} {
    path {
      stroke: ${grey.dark};
    }
  }

  &:hover,
  &:focus {
    background-color: ${grey.faint};
  }

  &:active {
    background-color: ${grey.rule};
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    background-color: ${grey.lighterPanel};
    color: ${grey.mid};
    .${menuIcons} {
      path {
        stroke: ${grey.mid};
      }
    }
    ${MenuItemText} {
      color: ${grey.mid};
    }
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${grey.faint};
  margin: 12px 0;
`;

const S = {
  MenuItem,
  MenuItemText,
  MenuItemButton,
  MenuItemLink,
  Separator
};

export const classNameOverrides = {
  menuIcons
};

export default S;
