import React, { useState } from "react";
import { Settings, MoreVertical, Trash2 } from "react-feather";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import DeleteUserModal from "components/organisms/DeleteUserModal";
import UserSettingsModal from "components/organisms/UserSettingsModal";

import type { User } from "api/users";
import { Idam_Contracts_Enums_UserState } from "api/portal";
import config from "config";

import S from "./styles";

interface Props {
  user: User;
}

const UserCardMenu = ({ user }: Props) => {
  const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const isEnabled =
    user.resource.state === Idam_Contracts_Enums_UserState.ENABLED;

  return (
    // Stop click event from opening report
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={e => e.stopPropagation()}>
      <Popover
        disabled={isDeleteUserModalOpen}
        hideArrow
        alignment="bottom-end"
        trigger="click"
        content={
          <Menu>
            {/* <MenuItem
                IconLeading={Eye}
                text="View user's reports"
                disabled={!user.permissions.canViewReports}
                showSeparator
                /> */}
            {/* <MenuItem
                IconLeading={UserPlus}
                text="Add to a group"
                disabled
                onMenuItemClick={() => {}}
                /> */}
            <MenuItem
              IconLeading={Settings}
              text="User settings"
              disabled={!user.permissions.canEdit || !isEnabled}
              onMenuItemClick={() => setIsUserSettingsModalOpen(true)}
            />
            <MenuItem
              isDeleteAction
              IconLeading={Trash2}
              text="Delete user"
              disabled={!user.permissions.canDelete || !isEnabled}
              onMenuItemClick={() => setIsDeleteUserModalOpen(true)}
            />
          </Menu>
        }
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.MenuButton>
          <MoreVertical />
        </S.MenuButton>
      </Popover>
      <DeleteUserModal
        isOpen={isDeleteUserModalOpen}
        toggleOpen={() => setIsDeleteUserModalOpen(prev => !prev)}
        userId={user.id}
        userName={user.title}
        jobTitle={user.jobTitle}
        deleteSupportUser={user.resource.email === config.supportEmail}
      />
      <UserSettingsModal
        user={user.resource}
        isOpen={isUserSettingsModalOpen}
        toggleOpen={() => setIsUserSettingsModalOpen(prev => !prev)}
      />
    </div>
  );
};

export default UserCardMenu;
