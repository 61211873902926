import React, { useRef, useEffect, useState } from "react";

import TruncateLength from "util/TruncateLength";

import S from "./styles";

const StackedBarWithLegends = ({ media }) => {
  const ref = useRef();
  const [currentMouseOver, setMouseOver] = useState("");
  const [barWidths, setBarWidths] = useState([]);

  const onHover = key => {
    setMouseOver(key);
  };

  const { sources } = media;
  const sortedSources = sources
    .map(s => ({
      ...s,
      countToSort: s.groupName.toLowerCase() === "other" ? 0 : s.articleNumber
    }))
    .sort((a, b) => {
      return b.countToSort - a.countToSort;
    });

  useEffect(() => {
    if (ref && ref.current && ref.current.clientWidth) {
      const total = sources
        .map(s => s.articleNumber)
        .reduce((a, b) => a + b, 0);

      const widths = sortedSources.map(
        s => (s.articleNumber / total) * ref.current.clientWidth
      );
      setBarWidths(widths);
    }
    // Intentional omission of dependencies. Mimicking componentDidMount
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const getHoverTextWidth = index => {
    if (ref && ref.current && ref.current.clientWidth) {
      if (index === 0) {
        return ref.current.clientWidth;
      }
      const previousBarsWidth = barWidths
        .slice(0, index)
        .reduce((a, b) => a + b, 0);
      return ref.current.clientWidth - previousBarsWidth;
    }
    return "100%";
  };

  return (
    <S.BarWrapper>
      <S.BarInfo ref={ref}>
        {sortedSources.map((source, i) => (
          <S.BarItem
            key={`BarItem-${source.groupName}`}
            width={barWidths[i] ?? 0}
          >
            <S.Bar
              index={i}
              onMouseOver={() => onHover(i)}
              onMouseLeave={() => onHover("")}
            />
            <S.BarValue
              index={i}
              active={currentMouseOver === i}
              width={getHoverTextWidth(i)}
            >
              {source.articleNumber}
              {source.subText && <span>&nbsp;</span>}
              {source.subText && (
                <TruncateLength>{source.subText}</TruncateLength>
              )}
            </S.BarValue>
          </S.BarItem>
        ))}
      </S.BarInfo>

      <S.LegendList>
        {sortedSources.map((source, i) => (
          <S.LegendItem
            key={`LegendItem-${source.groupName}`}
            onMouseOver={() => onHover(i)}
            onMouseLeave={() => onHover("")}
          >
            <div>
              <S.LegendIndicator index={i} />
            </div>
            <S.LegendLabel index={i} active={currentMouseOver === i}>
              {source.groupName}
            </S.LegendLabel>
          </S.LegendItem>
        ))}
      </S.LegendList>
    </S.BarWrapper>
  );
};

export default React.memo(StackedBarWithLegends);
