import React, { FC } from "react";
import { Position } from "reactflow";
import { UBOHandle, UBONodeWrapper } from "components/atoms/UBO";
import type { UboReportNode } from "api/ubo-reports";
import S from "./styles";

interface Props {
  data: UboReportNode["data"];
}

const RootNode: FC<Props> = ({ data: { label } }) => {
  return (
    <>
      <UBONodeWrapper>
        <S.RootIcon />
        <S.Label>{label}</S.Label>
        <S.SecondaryLabel>Primary entity</S.SecondaryLabel>
      </UBONodeWrapper>
      <UBOHandle isHidden type="source" position={Position.Top} />
    </>
  );
};

export default RootNode;
