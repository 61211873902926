import React, { FC, useEffect } from "react";
import UpcReportsApi from "api/upc-reports";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import useUpcDetails from "util/hooks/useUpcDetails";

import BasicDetails, {
  BasicDetailsType,
  type BasicDetail
} from "components/molecules/BasicDetails";

import ErrorScreen from "components/molecules/ErrorScreen";
import { useLocation } from "react-router-dom";

interface Props {
  title: string;
}

const ERROR_TEXT =
  // eslint-disable-next-line max-len
  "We were unable to narrow down the group or parent company from the corporate data available. We recommend that you go to the Ownership Preview section.";

const UPCDetails: FC<Props> = ({ title }) => {
  const { state: upcData, updateState: updateUpcData } = useUpcDetails();
  const enquiryId = useEnquiryId();
  const { search } = useLocation();

  useEffect(() => {
    const api = new UpcReportsApi();

    const params = new URLSearchParams(search);
    const shareToken = params.get("token");

    api
      .getDetails({ enquiryId, shareToken })
      .then(({ response }) => response && updateUpcData(response))
      .catch(() => {});
  }, [enquiryId, search, updateUpcData]);

  if (!upcData) {
    return <ErrorScreen compact errorText={ERROR_TEXT} />;
  }

  const { name, jurisdiction, anyParentStateOwned } = upcData;

  const upcDetails: BasicDetail[][] = [
    [
      {
        title: "Name",
        itemType: BasicDetailsType.text,
        items: name
          ? [
              {
                value: name.text,
                sources: name.sources
              }
            ]
          : []
      }
    ],
    [
      {
        title: "Jurisdiction",
        itemType: BasicDetailsType.country,
        items:
          jurisdiction && jurisdiction.countryCode && jurisdiction.countryName
            ? [
                {
                  code: jurisdiction.countryCode,
                  name: jurisdiction.countryName,
                  sources: jurisdiction.sources
                }
              ]
            : []
      }
    ],
    [
      {
        title: "State ownership",
        itemType: BasicDetailsType.text,
        items: anyParentStateOwned
          ? [
              {
                value: anyParentStateOwned.value ? "Yes" : "No",
                sources: anyParentStateOwned.sources
              }
            ]
          : [{ value: "Not identified", sources: [] }]
      }
    ]
  ];

  return <BasicDetails title={title} details={upcDetails} />;
};

export default UPCDetails;
