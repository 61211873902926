import React, { FC, useState, SyntheticEvent } from "react";

import ModalContainer from "components/molecules/ModalContainer";
import ErrorBanner from "components/atoms/ErrorBanner";

import { useAuthentication } from "util/hooks/useAuthentication";

import Api from "api/users";

import InviteForm from "./InviteForm";
import InvitesComplete from "./InvitesComplete";

interface Props {
  isOpen: boolean;
  onToggleOpen: () => void;
}

const getDropdownOptions = (canInviteAdmins: boolean) => {
  let options = [{ id: "User", label: "User" }];

  if (canInviteAdmins) {
    options = [...options, { id: "Admin", label: "Admin" }];
  }

  return options;
};

const InviteUserModal: FC<Props> = ({ isOpen, onToggleOpen }) => {
  const {
    state: {
      permissions: { canInviteAdmins }
    }
  } = useAuthentication();

  const dropdownOptions = getDropdownOptions(canInviteAdmins);

  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [invitesSent, setInvitesSent] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<string[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    dropdownOptions[0]
  );

  const onDeleteGroup = (id: string) => {
    setGroups(groups.filter(groupId => groupId !== id));
  };

  const onGroupClick = (id: string) => {
    setGroups([...groups, id]);
  };

  const defaultErrorMessage =
    "There has been a problem with your request. Please check and try again.";

  const onSubmit = async () => {
    setIsLoading(true);
    const api = new Api();

    api
      .invite({
        emails: value,
        groups,
        userType: selectedDropdownOption.id
      })
      .then(({ status, message }) => {
        if (status) {
          setInvitesSent(true);
          setError(undefined);
        } else {
          setError(message || defaultErrorMessage);
        }
        setIsLoading(false);
      });
  };

  const onDismissError = () => setError(undefined);

  const onInputChange = (_event: SyntheticEvent, newValue: string) => {
    setInputValue(newValue);
  };

  const onChange = (_event: SyntheticEvent, newValue: string[]) => {
    setValue(newValue);
  };

  const onDropdownOptionChange = (newValue: { id: string; label: string }) => {
    setSelectedDropdownOption(newValue);
  };

  const onClose = () => {
    if (isLoading) return;
    setInvitesSent(false);
    setInputValue("");
    setValue([]);
    setSelectedDropdownOption(dropdownOptions[0]);
    onToggleOpen();
  };

  return (
    <ModalContainer
      title="Invite new users"
      width={688}
      isOpen={isOpen}
      toggleOpen={onClose}
      onExitClick={onClose}
    >
      {error && <ErrorBanner text={error} onClick={onDismissError} />}
      {invitesSent ? (
        <InvitesComplete emails={value} onClose={onClose} />
      ) : (
        <InviteForm
          selectedGroups={groups}
          onGroupClick={onGroupClick}
          onDeleteGroup={onDeleteGroup}
          onDropdownOptionChange={onDropdownOptionChange}
          onChange={onChange}
          onInputChange={onInputChange}
          onSubmit={onSubmit}
          inputValue={inputValue}
          dropdownOptions={dropdownOptions}
          value={value}
          onSetValue={setValue}
          onToggleOpen={onClose}
          selectedDropdownOption={selectedDropdownOption}
          isLoading={isLoading}
        />
      )}
    </ModalContainer>
  );
};

export default InviteUserModal;
