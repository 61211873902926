import React from "react";
import { Users } from "react-feather";

import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import Popover from "components/atoms/Popover";

import S from "./styles";

interface Props {
  groupName: string;
  groupDescription?: string;
  onGroupNameChanged: (value: string) => void;
  onGroupDescriptionChanged: (value: string) => void;
  canEditGroup: boolean;
}

const GroupDetails = ({
  groupName,
  groupDescription,
  onGroupNameChanged,
  onGroupDescriptionChanged,
  canEditGroup
}: Props) => {
  return (
    <S.GroupDetailsContainer>
      <S.GroupDetailsContentContainer>
        <Avatar avatarSize={AvatarSize.Large} hasBorder>
          <Users />
        </Avatar>
        <S.Details>
          <S.GroupNameInput
            type="text"
            value={groupName}
            onChange={e => onGroupNameChanged(e.target.value)}
            placeholder="@Group-name"
            disabled={!canEditGroup}
          />
          <S.GroupDescriptionInput
            type="text"
            value={groupDescription}
            placeholder="Description (optional)"
            onChange={e => onGroupDescriptionChanged(e.target.value)}
            disabled={!canEditGroup}
          />
        </S.Details>
      </S.GroupDetailsContentContainer>
      {canEditGroup && (
        <Popover
          content={
            <S.EditDetailsTooltipContent>
              Click on title or description to edit
            </S.EditDetailsTooltipContent>
          }
          disableHideOnClip={undefined}
          className={undefined}
          style={undefined}
          tooltipOffset={-5}
        >
          <S.EditIcon />
        </Popover>
      )}
    </S.GroupDetailsContainer>
  );
};

export default GroupDetails;
