import { styled } from "@linaria/react";

import { motion } from "framer-motion";

import Button from "components/atoms/Button";

import { ReactComponent as ChevronSvg } from "img/icons/arrow-down.svg";

import { standardColors, grey, green } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import { fontSize } from "styles/fonts";

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${grey.dark} !important;
  border: none !important;
  margin-left: 4px;
`;

const BackButtonIconContainer = styled.div`
  border-radius: 4px;
  background-color: ${() => {
    const { r, g, b } = hexToRgb(green.xapienGreen);
    return `rgba(${r}, ${g}, ${b}, 0.7)`;
  }};
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BackButtonChevron = styled(ChevronSvg)`
  margin-left: -2px;
  transform: rotate(90deg) scale(1.2);
  path {
    fill: ${standardColors.white};
  }
`;

const BackButtonLabel = styled.div`
  color: ${grey.dark};
  font-size: ${fontSize.sm};
`;

const BackButtonLabelContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const S = {
  BackButton,
  BackButtonLabel,
  BackButtonLabelContainer,
  BackButtonIconContainer,
  BackButtonChevron
};

export default S;
