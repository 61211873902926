import React, { Fragment, useState } from "react";
import { Collapse } from "reactstrap";

import arrow from "img/icons/renders/arrow.svg";
import { ReactComponent as DownloadIcon } from "img/icons/actions/icon_open_link.svg";
import TruncateLength from "util/TruncateLength";
import { ButtonKind } from "components/atoms/Button/types";

import S from "./styles";

const TitleDescription = ({
  title,
  date,
  longName,
  subTitle,
  description,
  urls
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!isExpanded);

  const renderSourceLinks = () => {
    if (!urls) return false;

    return urls.map(item => {
      return (
        <S.SourceLink
          href={item}
          target="_blank"
          rel="noopener noreferrer"
          key={`${title}-${subTitle}-${item}`}
        >
          <DownloadIcon className="link-icon" />
          <TruncateLength text={item} length={30} />
        </S.SourceLink>
      );
    });
  };

  return (
    <>
      <div className="report-section-content-outer col title-column">
        <div className="report-section-content-inner small-right-padding">
          <div className="flex-title-row">
            <div className="left">
              <div className="awards-education--title font__color--dark">
                {title}
              </div>
              <div>{date}</div>
              <Collapse isOpen={isExpanded}>
                {urls && urls.length > 0 && renderSourceLinks()}
              </Collapse>
            </div>
            <div className="right">
              {(description || (urls && urls.length > 0)) && (
                <S.ExpandButton
                  kind={ButtonKind.tertiary}
                  onClick={toggleExpanded}
                  role="button"
                  onKeyDown={toggleExpanded}
                >
                  <img
                    src={arrow}
                    alt="Collapse"
                    className={`toggle-icon__extra-information ${
                      isExpanded
                        ? "toggle-icon__extra-information--expanded"
                        : ""
                    }`}
                  />
                </S.ExpandButton>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="report-section-content-outer col description-column">
        <div className="report-section-content-inner">
          {(longName || subTitle) && (
            <div>
              <div className="font__color--dark">{longName}</div>
              <div>{subTitle}</div>
            </div>
          )}
          <div>
            <Collapse isOpen={isExpanded}>
              <div>{description}</div>
            </Collapse>
          </div>
          {!isExpanded && (
            <div>
              <div className="description">{description}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TitleDescription;
