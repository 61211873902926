import { styled } from "@linaria/react";

import ButtonNew from "components/atoms/ButtonNew";

import { purple, standardColors, grey, red } from "styles/colors";
import { fontSize, family } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";
import { ModalVariant } from "./types";

export interface VariantProps {
  variant: ModalVariant;
}

const Actions = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
  padding-top: 2rem;
`;

const CloseButton = styled(ButtonNew)`
  background: ${grey.panel};
  padding: 12px 24px;
  font-family: ${family.interDisplay};
  font-weight: 600;

  &:hover {
    background: ${grey.panelHover};
  }
`;

const SubmitButton = styled(ButtonNew)<VariantProps>`
  background: ${({ variant }) => {
    if (variant === ModalVariant.Danger) {
      return red.directRiskFill;
    }

    return isPDX ? theme.primaryColor : purple.darker;
  }};
  color: ${standardColors.white};
  padding: 12px 24px;
  font-family: ${family.interDisplay};
  font-weight: 600;
  min-width: 80px;

  &:hover {
    background: ${({ variant }) => {
      if (variant === ModalVariant.Danger) {
        return red.directRiskFillHover;
      }

      return isPDX ? theme.primaryColor : purple.darkest;
    }};
  }
`;

const ContextTextArea = styled.div<VariantProps>`
  border: 1px solid
    ${({ variant }) => {
      switch (variant) {
        case ModalVariant.Danger:
          return red.directRiskOutline;
        case ModalVariant.Info:
          return isPDX ? theme.primaryColor : purple.darker;
        default:
          return grey.ghost;
      }
    }};
  width: 100%;
  height: 180px;
  border-radius: 4px;
  padding: 1rem;
  overflow-y: scroll;
  border-radius: 6px;
  background: ${grey.panel};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25) inset;
  color: ${grey.mid};
`;

const ContentWrapper = styled.div`
  pointer-events: none;
  & h3 {
    color: ${grey.mid};
  }
`;

const SuccessModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px 8px 8px 8px;
  height: 268px;
`;

const IconContainer = styled.div<{ color: string }>`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 8px;
  justify-content: center;
  border-radius: 48px;
  align-items: center;
  background-color: ${({ color }) => {
    const { r, g, b } = hexToRgb(color);
    return `rgba(${r}, ${g}, ${b}, 0.10)`;
  }};
`;

const SuccessText = styled.div`
  font-size: ${fontSize.lg};
  font-family: ${family.interSemiBold};
  font-weight: inherit;
`;

const SuccessTextContainer = styled.div`
  grid-gap: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessSubText = styled.div`
  font-size: ${fontSize.md};
`;

const SubTitleText = styled.div`
  font-size: ${fontSize.sm};
`;

const S = {
  Actions,
  CloseButton,
  SubmitButton,
  ContextTextArea,
  ContentWrapper,
  SuccessModalContainer,
  IconContainer,
  SuccessText,
  SuccessTextContainer,
  SuccessSubText,
  SubTitleText
};

export default S;
