import { styled } from "@linaria/react";
import { blue, grey } from "styles/colors";

const SortFilterFacet = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  cursor: default;
  padding-left: ${props =>
    props.paddingLeft ? `${20 + props.paddingLeft * 15}px` : "8px"};

  &:hover {
    background-color: ${props =>
      props.isInSnapshot ? "rgba(0, 168, 229, 0.15)" : grey.light};
    color: ${props => (props.isInSnapshot ? blue.icon : "inherit")};
    cursor: ${props => (props.isInSnapshot ? "pointer" : "default")};
  }

  opacity: ${props => (props.faded ? 0.5 : 1)};

  transition: ${props =>
    props.isInSnapshot ? "background-color 0.2s, color 0.2s" : "none"};
  border-radius: ${props => (props.isInSnapshot ? "15px" : 0)};
`;

const SortFilterFacetContents = styled.div`
  text-transform: ${props => (props.capitalize ? "capitalize" : "none")};
  font-style: ${props => (props.italics ? "italic" : "none")};
  display: flex;
  align-items: center;
  padding: 3px 0;
`;

const Arrow = styled.img`
  transition-duration: 0.2s;
  transition-property: transform;
  margin-right: 4px;

  transform: ${props => (props.expanded ? "rotate(90deg)" : "none")};
`;

const SortFilterPillLabel = styled.div`
  max-width: 180px;
  overflow: ${props => (props.isNameVariantPresent ? "hidden" : "initial")};
  text-overflow: ${props =>
    props.isNameVariantPresent ? "ellipsis" : "initial"};
  white-space: ${props => (props.isNameVariantPresent ? "nowrap" : "normal")};
`;

const S = {
  SortFilterFacet,
  SortFilterFacetContents,
  Arrow,
  SortFilterPillLabel
};

export default S;
