import React from "react";
import { inject, observer } from "mobx-react";
import qs from "qs";

import { withLocation } from "util/routerHocs";

import Define from "pages/define/Define";

class MobxDefineContainerProxy extends React.Component {
  constructor(props) {
    super(props);
    const { location, defineStore } = this.props;

    this.state = {
      // TODO: Figure out if/where this is used
      // eslint-disable-next-line react/no-unused-state
      isDiagnosticsModeEnabled: this.context
    };

    // Extract the query parameter that will set `SubjectNameWebSearches`
    // to true
    const queryParameters = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });

    if (queryParameters.NOS && queryParameters.NOS === "true") {
      defineStore.setNameOnlySearch(true);
    }
  }

  componentDidMount() {
    const { enquiryStore } = this.props;
    enquiryStore.clearCurrentEnquiry();
  }

  validateInput = async (subject, contexts, experimentalMode, features) => {
    const { defineStore } = this.props;
    const inputObject = {
      EnquirySubject: subject,
      EnquiryContext: contexts,
      ExperimentalMode: experimentalMode,
      Features: features
    };

    const response = await defineStore.searchAPIDebounced(inputObject);

    return response;
  };

  render() {
    const { featureStore, enquiryStore, defineStore } = this.props;
    const fetchFeatures = async () => {
      // eslint-disable-next-line @typescript-eslint/return-await
      return await featureStore.fetch();
    };
    const createEnquiry = enquiryStore.create;
    const { validateInput } = this;

    return (
      <Define
        {...{
          fetchFeatures,
          createEnquiry,
          validateInput,
          nameOnlySearch: defineStore.nameOnlySearch,
          getSystemStatus: defineStore.getSystemStatus
        }}
      />
    );
  }
}

export default withLocation(
  inject(
    "defineStore",
    "reportStore",
    "enquiryStore",
    "featureStore"
  )(observer(MobxDefineContainerProxy))
);
