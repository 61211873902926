import React from "react";

import { useNavigate } from "react-router-dom";

import { CollectionListView } from "components/molecules/CollectionListControls";
import { CardVariant } from "components/molecules/Card/types";
import CardSkeleton from "components/atoms/CardSkeleton";
import { collectionCardComponents } from "util/collectionCardComponents";

import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";

import { formatDate } from "util/common";

import type { Organisation } from "api/organisations/types";

import S, { classNameOverrides } from "./styles";

interface Props {
  item?: Organisation;
  view: CollectionListView;
  loading?: boolean;
  variant: CardVariant;
}

const OrganisationCard = ({ item, view, loading, variant }: Props) => {
  const navigate = useNavigate();
  const { C } = collectionCardComponents[view];

  if (loading) {
    return <CardSkeleton view={view} />;
  }

  if (!item) {
    return null;
  }

  const onCardClick = () => {
    navigate(`/hub/manage-organisation/${item.organisationId}`);
  };

  return (
    <C.Card
      onClick={onCardClick}
      variant={variant}
      className={classNameOverrides.card}
      control={
        <S.Details>
          {item.renewalDate && (
            <S.DetailItem>Renews {formatDate(item.renewalDate)}</S.DetailItem>
          )}
          <S.DetailItem>
            {item.usage.reportsRunPast30Days} reports ran in the past 30 days
          </S.DetailItem>
        </S.Details>
      }
    >
      <C.CardHeader
        variant={variant}
        title={item.name}
        subtitle={item.description}
        icon={
          <Avatar avatarSize={AvatarSize.Large} hasBorder>
            <S.BuildingIcon />
          </Avatar>
        }
      />
    </C.Card>
  );
};

export default OrganisationCard;
