import { styled } from "@linaria/react";

const ViewContainer = styled.main`
  flex-grow: 1;
`;

const ViewInnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const S = { ViewContainer, ViewInnerContainer };

export default S;
