import React from "react";

import SectionWithExpandingList from "pages/report/SectionWithExpandingList";
import { DiImage } from "components/molecules/DiImage";
import PlaceholderImage from "img/icons/business_placeholder_simple.svg";
import { formatDate, formatTimePeriod } from "util/common";

import TitleDescription from "./TitleDescription";

import S from "./styles";

const AwardEducation = React.forwardRef((props, ref) => {
  const { awardData, educationData, isExpanded, setExpanded } = props;
  const title = props.title;
  const sectionCount =
    (awardData ? awardData.length : 0) +
    (educationData ? educationData.length : 0);

  const renderContent = dataToRender => {
    const totalRowCountToDisplay = dataToRender.length;
    const awardsLength = awardData ? awardData.length : 0;
    const educationLength = educationData ? educationData.length : 0;
    const awardRowCountToDisplay =
      totalRowCountToDisplay <= awardsLength
        ? totalRowCountToDisplay
        : awardsLength;
    const educationRowCountToDisplay =
      totalRowCountToDisplay <= awardsLength
        ? 0
        : totalRowCountToDisplay - awardRowCountToDisplay;

    return (
      <div className="awards-education" ref={ref}>
        <S.SectionBanner>
          <div>{!sectionCount ? "No awards and education found" : null}</div>
          <S.SectionCount>{sectionCount}</S.SectionCount>
        </S.SectionBanner>
        {awardsLength > 0 && (
          <div className="award-education-section">
            {awardData.slice(0, awardRowCountToDisplay).map((dataItem, key) => (
              <div className="report-section-row row" key={key}>
                <div className="report-section-content-outer col logo-column">
                  <S.ImageSectionContainer className="report-section-content-inner p-0">
                    <S.ImageSection>
                      <div className="image-container">
                        <div className="image">
                          <DiImage
                            image={dataItem.awardImage}
                            fallbackImage={PlaceholderImage}
                            alt={
                              dataItem.awardName && dataItem.awardImage
                                ? `${dataItem.awardName} Logo`
                                : "placeholder logo image"
                            }
                            height="52px"
                            width="52px"
                            isCopyrighted
                          />
                        </div>
                      </div>
                    </S.ImageSection>
                  </S.ImageSectionContainer>
                </div>
                <TitleDescription
                  urls={dataItem.sourceUrls}
                  title={dataItem.awardName}
                  date={formatDate(dataItem.awardDate)}
                  longName={dataItem.awardNameLong}
                  subTitle={dataItem.awardType}
                  description={dataItem.awardDescription}
                />
                <div className="report-section-content-outer col ranking-column">
                  <div className="report-section-content-inner p-0" />
                </div>
              </div>
            ))}
          </div>
        )}
        {educationLength > 0 && (
          <div className="award-education-section">
            {educationData
              .slice(0, educationRowCountToDisplay)
              .map((dataItem, key) => (
                <div className="report-section-row row" key={key}>
                  <div className="report-section-content-outer col logo-column">
                    <S.ImageSectionContainer className="report-section-content-inner p-0">
                      <S.ImageSection>
                        <div className="image-container">
                          <div className="image">
                            <DiImage
                              image={dataItem.establishmentLogo}
                              fallbackImage={PlaceholderImage}
                              alt={
                                dataItem.establishmentName &&
                                dataItem.establishmentLogo
                                  ? `${dataItem.establishmentName} Logo`
                                  : "placeholder logo image"
                              }
                              height="52px"
                              width="52px"
                              isCopyrighted
                            />
                          </div>
                        </div>
                      </S.ImageSection>
                    </S.ImageSectionContainer>
                  </div>
                  <TitleDescription
                    urls={dataItem.sourceUrls}
                    title={dataItem.establishmentName}
                    date={formatTimePeriod(
                      dataItem.startDate,
                      dataItem.endDate,
                      dataItem.duration
                    )}
                    longName={dataItem.degreeName}
                    subTitle={dataItem.establishmentLocation}
                    description={dataItem.establishmentDescription}
                  />
                  <div className="report-section-content-outer col ranking-column">
                    <div className="report-section-content-inner">
                      {dataItem.establishmentGlobalRanking && (
                        <div className="inline-block">
                          <span>Global rank: </span>
                          <span className="font__color--dark">
                            {dataItem.establishmentGlobalRanking}
                          </span>
                        </div>
                      )}
                      {dataItem.establishmentLocalRanking && (
                        <div className="inline-block">
                          <span>National rank: </span>
                          <span className="font__color--dark">
                            {dataItem.establishmentLocalRanking}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  };
  return (
    <SectionWithExpandingList
      isExpanded={isExpanded}
      setExpanded={setExpanded}
      defaultExpanded={true}
      title={title}
      {...{ renderContent }}
      ref={ref}
      listData={[...awardData, ...educationData]}
      collapsedElementCount={4}
    />
  );
});

export default AwardEducation;
