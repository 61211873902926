import { styled } from "@linaria/react";

import SectionFooter from "components/atoms/SectionFooter";
import ShowResultsButtonComponent from "pages/report/ShowResultsButton";

import { grey } from "styles/colors";

const ShowResultsButton = styled(ShowResultsButtonComponent)`
  background-color: ${grey.panel};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 10px 13px;
`;

const ListContainer = styled.div`
  transition: max-height 0.8s ease-out;
  overflow: hidden;
`;

const CustomSectionFooter = styled(SectionFooter)`
  border-top: 0;
`;

const S = {
  ShowResultsButton,
  ListContainer,
  CustomSectionFooter
};

export default S;
