import { Subsection as RawSubsection } from "api/insights";

export enum InsightsAmaStatus {
  idle = "idle",
  askingQuestion = "askingQuestion",
  errorAskingQuestion = "errorAskingQuestion",
  errorSavingMessage = "errorSavingMessage",
  savingMessage = "savingMessage",
  messageSaved = "messageSaved"
}

export enum InsightsAmaMessageStatus {
  idle = "idle",
  loading = "loading",
  error = "error",
  saving = "saving",
  saved = "saved"
}

export enum InsightsAmaActions {
  askQuestion = "askQuestion",
  messageSaved = "messageSaved",
  questionAnswered = "questionAnswered",
  getSuggestedQuestions = "getSuggestedQuestions",
  errorAskingQuestion = "errorAskingQuestion",
  errorSavingMessage = "errorSavingMessage"
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
}

export interface InsightsAmaQuestion extends RawSubsection {
  timestamp: string;
  user: User;
}

export interface InsightsAmaAnswer extends RawSubsection {}

export interface InsightsAmaMessage {
  id: string;
  status: InsightsAmaMessageStatus;
  sectionId: string;
  question: InsightsAmaQuestion;
  answer?: InsightsAmaAnswer;
}

export interface ErrorSavingMessageAction {
  type: InsightsAmaActions.errorSavingMessage;
}

export interface MessageSavedAction {
  type: InsightsAmaActions.messageSaved;
}

export interface SaveMessageAction {
  type: InsightsAmaActions.messageSaved;
  messageId: string;
}

export interface QuestionAnsweredAction {
  type: InsightsAmaActions.questionAnswered;
  messageId: string;
  answer: InsightsAmaAnswer;
}

export interface ErrorAskingQuestionAction {
  type: InsightsAmaActions.errorAskingQuestion;
}

export interface GetSuggestedQuestionsAction {
  type: InsightsAmaActions.getSuggestedQuestions;
}

export interface AskQuestionAction {
  type: InsightsAmaActions.askQuestion;
  question: string;
  user: User;
  sectionId: string;
}

export type InsightsAmaAction =
  | AskQuestionAction
  | GetSuggestedQuestionsAction
  | QuestionAnsweredAction
  | ErrorAskingQuestionAction
  | MessageSavedAction
  | ErrorSavingMessageAction;

export interface InsightsAmaState {
  status: InsightsAmaStatus;
  messages: InsightsAmaMessage[];
  suggestedQuestions: string[];
  messageToSave?: InsightsAmaMessage;
  questionToAsk?: { messageId: string; query: string };
}
