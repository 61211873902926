import { useContext } from "react";

import { UserPreferencesContext } from "./context";

const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext);

  if (!context) {
    throw new Error(
      "useUserPreferences must be used with UserPreferencesProvider"
    );
  }

  return context;
};

export default useUserPreferences;
