import { apm, Span, Transaction } from "@elastic/apm-rum";

import { useEnquiryId } from "util/hooks/useEnquiryId";
import { useSubjectName } from "util/hooks/useSubjectName";
import { useUserOrganisation } from "util/hooks/useUserOrganisation";

export const USER_INTERACTION_TRANSACTION_TYPE = "user-interaction";

export const useObservability = () => {
  const { details: organisationDetails } = useUserOrganisation();
  const enquiryId = useEnquiryId();
  const subjectName = useSubjectName();

  const captureError = (error: Error, info?: unknown) => {
    apm.captureError(error);
    console.error(error, info);
  };

  const startTrackingClick = (name: string) => {
    const transaction = apm.startTransaction(
      name,
      USER_INTERACTION_TRANSACTION_TYPE
    );

    transaction?.addLabels({
      enquiry_id: enquiryId,
      subject_name: subjectName ?? "Unknown",
      organisation_name: organisationDetails.loaded
        ? organisationDetails.name
        : "Unknown"
    });

    const span = transaction?.startSpan(
      `${name} span`,
      USER_INTERACTION_TRANSACTION_TYPE
    );

    return { transaction, span };
  };

  const stopTrackingClick = ({
    transaction,
    span
  }: {
    transaction?: Transaction;
    span?: Span;
  }) => {
    span?.end();
    transaction?.end();
  };

  return {
    captureError,
    startTrackingClick,
    stopTrackingClick
  };
};

export default useObservability;
