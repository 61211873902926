import React, { CSSProperties, ReactNode } from "react";
import { Placement, Strategy } from "@floating-ui/react";

import ReactTooltip from "components/atoms/ReactTooltip";

import S from "./styles";

interface Props {
  infoContent: ReactNode;
  tooltipPosition?: Strategy;
  tooltipAlignment?: Placement;
  style?: CSSProperties;
  className?: string;
  tooltipContentClassName?: string;
  interactive?: boolean;
  tooltipWidth?: number;
  disabled?: boolean;
}

const InfoIcon = ({
  infoContent,
  tooltipPosition,
  tooltipAlignment,
  style,
  className,
  tooltipContentClassName,
  interactive = false,
  tooltipWidth,
  disabled
}: Props) => {
  return (
    <S.InfoIconContainer style={style} className={className}>
      <ReactTooltip
        disabled={disabled}
        layoutPosition={tooltipPosition}
        position={tooltipAlignment}
        interactive={interactive}
        tooltip={
          <S.TooltipContent
            className={tooltipContentClassName}
            width={tooltipWidth}
          >
            {infoContent}
          </S.TooltipContent>
        }
      >
        <S.InformationIcon />
      </ReactTooltip>
    </S.InfoIconContainer>
  );
};

export default InfoIcon;
