import React, {
  ReactNode,
  useMemo,
  useEffect,
  useState,
  useCallback
} from "react";

import ReportsApi from "api/reports";
import {
  ReportSharePermission,
  ReportSharePreferences,
  SharedWithGroup,
  SharedWithItem,
  SharedWithUser
} from "api/reports/types";

import { SearchItemType } from "api/search";
import { ShareReportContext } from "./context";

export const ShareReportContextProvider = ({
  children,
  reportId
}: {
  children: ReactNode;
  reportId: string;
}) => {
  const [isSharingWithUser, setIsSharingWithUser] = useState(false);
  const [sharePreferences, setSharePreferences] =
    useState<ReportSharePreferences>();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isTransferOwnerModalOpen, setIsTransferOwnerModalOpen] =
    useState(false);
  const [isDeleteShareLinkModalOpen, setIsDeleteShareLinkModalOpen] =
    useState(false);
  const [generatedLink, setGenerateLink] = useState<string | undefined>();
  const [isSharedWithLoading, setIsSharedWithLoading] = useState(false);

  const sharedWith = useMemo(
    () => [
      ...(sharePreferences?.sharedWithUsers.map(user => ({
        title: `${user.firstName} ${user.lastName}`,
        subtitle: user.email,
        type: SearchItemType.User,
        id: user.userId,
        avatarUrl: user.avatarUrl,
        permission: user.permission
      })) ?? []),
      ...(sharePreferences?.sharedWithGroups.map(group => ({
        title: group.name,
        subtitle: "",
        type: SearchItemType.Group,
        id: group.groupId,
        permission: group.permission
      })) ?? [])
    ],
    [sharePreferences]
  );

  const api = useMemo(() => new ReportsApi(), []);

  useEffect(() => {
    const getAndSetSharePreferences = async () => {
      setIsSharedWithLoading(true);
      const { response: preferences } = await api.getReportSharePreferences(
        reportId
      );

      setSharePreferences(preferences);
      setIsSharedWithLoading(false);
    };

    if (isShareModalOpen && !sharePreferences) {
      getAndSetSharePreferences();
    }
  }, [api, isShareModalOpen, reportId, sharePreferences]);

  const updateShareEntryPermission = useCallback(
    (
      shareEntries: SharedWithItem[],
      shareEntryIndex: number,
      permission: ReportSharePermission
    ) => {
      let shareEntriesCopy = shareEntries.map(shareItem => ({ ...shareItem }));
      // Delete
      if (permission === ReportSharePermission.Delete) {
        shareEntriesCopy = [
          ...shareEntriesCopy.slice(0, shareEntryIndex),
          ...shareEntriesCopy.slice(shareEntryIndex + 1)
        ];
      } else {
        // Change permission
        shareEntriesCopy[shareEntryIndex].permission = permission;
      }

      return shareEntriesCopy;
    },
    []
  );

  const updateSharePermission = useCallback(
    async (shareEntryId: string, permission: ReportSharePermission) => {
      let updatedSharedWithUsers = sharePreferences?.sharedWithUsers ?? [];
      let updatedSharedWithGroups = sharePreferences?.sharedWithGroups ?? [];

      const itemIndexWithinUsers = updatedSharedWithUsers.findIndex(
        share => share.userId === shareEntryId
      );
      const itemIndexWithinGroups = updatedSharedWithGroups.findIndex(
        share => share.groupId === shareEntryId
      );

      if (itemIndexWithinUsers > -1) {
        updatedSharedWithUsers = updateShareEntryPermission(
          updatedSharedWithUsers,
          itemIndexWithinUsers,
          permission
        ) as SharedWithUser[];
      } else if (itemIndexWithinGroups > -1) {
        updatedSharedWithGroups = updateShareEntryPermission(
          updatedSharedWithGroups,
          itemIndexWithinGroups,
          permission
        ) as SharedWithGroup[];
      }

      const newPreferences = {
        sharedWithUsers: updatedSharedWithUsers,
        sharedWithGroups: updatedSharedWithGroups
      };

      const result = await api.setReportSharePreferences(
        reportId,
        newPreferences
      );

      if (result.status) {
        setSharePreferences(newPreferences);
      }
      return result;
    },
    [sharePreferences, api, reportId, updateShareEntryPermission]
  );

  const addUserShareEntries = useCallback(
    (userShareEntries: SharedWithUser[]) => {
      const storedUserShareEntriesCopy =
        sharePreferences?.sharedWithUsers.map(shareEntry => ({
          ...shareEntry
        })) ?? [];

      const userShareEntriesToAdd = userShareEntries.reduce<SharedWithUser[]>(
        (arr, shareEntry) => {
          // If the entry already exists, then just modify the permission
          const existingIndex = storedUserShareEntriesCopy.findIndex(
            storedShareEntry => storedShareEntry.userId === shareEntry.userId
          );

          if (existingIndex > -1) {
            storedUserShareEntriesCopy[existingIndex].permission =
              shareEntry.permission;
          } else {
            return [...arr, shareEntry];
          }

          return arr;
        },
        []
      );

      return [...storedUserShareEntriesCopy, ...userShareEntriesToAdd];
    },
    [sharePreferences]
  );

  const addGroupShareEntries = useCallback(
    (groupShareEntries: SharedWithGroup[]) => {
      const storedGroupShareEntriesCopy =
        sharePreferences?.sharedWithGroups.map(shareEntry => ({
          ...shareEntry
        })) ?? [];

      const groupShareEntriesToAdd = groupShareEntries.reduce<
        SharedWithGroup[]
      >((arr, shareEntry) => {
        // If the entry already exists, then just modify the permission
        const existingIndex = storedGroupShareEntriesCopy.findIndex(
          storedShareEntry => storedShareEntry.groupId === shareEntry.groupId
        );

        if (existingIndex > -1) {
          storedGroupShareEntriesCopy[existingIndex].permission =
            shareEntry.permission;
        } else {
          return [...arr, shareEntry];
        }

        return arr;
      }, []);

      return [...storedGroupShareEntriesCopy, ...groupShareEntriesToAdd];
    },
    [sharePreferences]
  );

  const addShareEntries = useCallback(
    async (shareEntries: (SharedWithItem & { type: SearchItemType })[]) => {
      // Split share entries based on type
      const userShareEntries: SharedWithUser[] = [];
      const groupShareEntries: SharedWithGroup[] = [];

      shareEntries.forEach(shareEntry => {
        if (shareEntry.type === SearchItemType.User) {
          userShareEntries.push({ ...shareEntry } as SharedWithUser);
        } else {
          groupShareEntries.push({ ...shareEntry } as SharedWithGroup);
        }
      });

      const updatedUserShareEntries = addUserShareEntries(userShareEntries);
      const updatedGroupShareEntries = addGroupShareEntries(groupShareEntries);
      const updatedShareEntries = {
        sharedWithUsers: updatedUserShareEntries,
        sharedWithGroups: updatedGroupShareEntries
      };

      const setResult = await api.setReportSharePreferences(
        reportId,
        updatedShareEntries
      );

      if (setResult.status) {
        // Update to latest data
        const {
          status,
          message,
          response: updatedSharePreferences
        } = await api.getReportSharePreferences(reportId);

        setSharePreferences(updatedSharePreferences);
        return { status, message };
      }
      return setResult;
    },
    [addGroupShareEntries, addUserShareEntries, api, reportId]
  );

  const generateShareLink = useCallback(async () => {
    const {
      status,
      message,
      response: shareToken
    } = await api.generateShareToken(reportId);

    if (status && shareToken) {
      setGenerateLink(
        `https://${window.location.hostname}/share/${reportId}?token=${shareToken.token}`
      );
    }
    return { status, message };
  }, [api, reportId]);

  const getShareLink = useCallback(async () => {
    const { status, response: shareToken } = await api.getShareToken(reportId);

    if (status && shareToken) {
      setGenerateLink(
        `https://${window.location.hostname}/share/${reportId}?token=${shareToken.token}`
      );
    }
  }, [api, reportId]);

  const deleteShareLink = useCallback(async () => {
    const result = await api.deleteShareToken(reportId);

    if (result.status) {
      setGenerateLink(undefined);
    }

    return result;
  }, [api, reportId]);

  const transferOwnership = useCallback(
    async (
      reportToTransferId: string,
      userId: string,
      accessLevelToRetain:
        | ReportSharePermission.Write
        | ReportSharePermission.Read
    ) => {
      const result = await api.transferReportOwnership(
        reportToTransferId,
        userId,
        accessLevelToRetain
      );

      return result;
    },
    [api]
  );

  const toggleShareModal = () => {
    setIsShareModalOpen(prev => !prev);
  };

  const toggleTransferOwnerModal = () => {
    setIsTransferOwnerModalOpen(prev => !prev);
  };

  const toggleDeleteShareLinkModal = () => {
    setIsDeleteShareLinkModalOpen(prev => !prev);
  };

  const providerValue = useMemo(
    () => ({
      isSharedWithLoading,
      isShareModalOpen,
      isTransferOwnerModalOpen,
      isDeleteShareLinkModalOpen,
      sharedWith,
      generatedLink,
      addShareEntries,
      updateSharePermission,
      generateShareLink,
      getShareLink,
      deleteShareLink,
      toggleShareModal,
      toggleTransferOwnerModal,
      transferOwnership,
      toggleDeleteShareLinkModal,
      isSharingWithUser,
      setIsSharingWithUser
    }),
    [
      isSharedWithLoading,
      isShareModalOpen,
      isTransferOwnerModalOpen,
      isDeleteShareLinkModalOpen,
      sharedWith,
      generatedLink,
      addShareEntries,
      updateSharePermission,
      generateShareLink,
      getShareLink,
      deleteShareLink,
      transferOwnership,
      isSharingWithUser,
      setIsSharingWithUser
    ]
  );

  return (
    <ShareReportContext.Provider value={providerValue}>
      {children}
    </ShareReportContext.Provider>
  );
};
