import { Assertion, AssertionTemplate } from ".";
import { reportAbstract } from "./recognisers";

const exactDateOfBirthAssertionTemplate = dateOfBirth =>
  new AssertionTemplate((isValid, isMust, testCaseId, userId, userComment) => {
    const assertionSchema = {
      type: "object",
      properties: {
        ReportAbstract: {
          type: "object",
          properties: {
            DateOfBirth: {
              type: "object",
              properties: {}
            }
          }
        }
      }
    };

    let dateValues =
      assertionSchema.properties.ReportAbstract.properties.DateOfBirth
        .properties;

    let dobReadable = {};

    if (dateOfBirth.year) {
      dateValues.Year = { type: "number" };
      if (isValid) {
        dateValues.Year.const = dateOfBirth.year;
      } else {
        dateValues.Year.not = { const: dateOfBirth.year };
      }
      dobReadable.year = dateOfBirth.year;
    } else {
      dobReadable.year = "xx";
    }

    if (dateOfBirth.month) {
      dateValues.Month = { type: "number" };
      if (isValid) {
        dateValues.Month.const = dateOfBirth.month;
      } else {
        dateValues.Month.not = { const: dateOfBirth.month };
      }
      dobReadable.month = dateOfBirth.month;
    } else {
      dobReadable.month = "xx";
    }

    if (dateOfBirth.day) {
      dateValues.Day = { type: "number" };
      if (isValid) {
        dateValues.Day.const = dateOfBirth.day;
      } else {
        dateValues.Day.not = { const: dateOfBirth.day };
      }
      dobReadable.day = dateOfBirth.day;
    } else {
      dobReadable.day = "xx";
    }

    const assertionHumanReadable =
      `Date of birth ${isMust ? "must" : "should"}${
        isValid ? "" : " not"
      } match ` +
      `${dobReadable.day}-${dobReadable.month}-${dobReadable.year}.`;
    return new Assertion(
      testCaseId,
      userId,
      userComment,
      isMust,
      assertionSchema,
      assertionHumanReadable
    );
  }, reportAbstract.dateOfBirth);

export default exactDateOfBirthAssertionTemplate;
