import React, { FC, ReactNode } from "react";

import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import TruncateLength from "util/TruncateLength";
import RiskPill from "components/atoms/RiskPill";
import S from "./styles";

export interface InspectorTopContentProps {
  dataFormat: any;
  filterItems: any;
  filterSources: any;
  filterPillColor?: string;
  selectedFilter: any;
  onFilterSelected: any;
  pillClassName?: string;
  totalSources: number;
  isSingleSourceOrSingleFilterFilterable: boolean;
  fallbackElement?: ReactNode;
}

const InspectorTopContent: FC<InspectorTopContentProps> = ({
  dataFormat,
  filterItems,
  filterSources = () => {},
  filterPillColor,
  selectedFilter,
  onFilterSelected = () => {},
  pillClassName,
  totalSources,
  isSingleSourceOrSingleFilterFilterable,
  fallbackElement
}) => {
  const getCustomPillElement = (element: any, id: string) => {
    switch (element.type) {
      case "risk":
        return (
          <RiskPill
            count={element.count}
            label={element.label}
            removesAllInstancesOfRiskCategory={
              element.removesAllInstancesOfRiskCategory
            }
            riskHierarchies={[element.riskHierarchy]}
            onClick={
              ((e: Event) => {
                e.stopPropagation();

                if (id === selectedFilter) {
                  filterSources(undefined);
                  onFilterSelected(undefined);
                } else {
                  filterSources(id);
                  onFilterSelected(id);
                }
              }) as any
            }
            key={id}
            isActive={selectedFilter === id}
          />
        );
      default:
        return null;
    }
  };

  const renderFilterableListView = () => {
    if (
      (filterItems.length === 1 || totalSources === 1) &&
      // AND inspector requires at least one source or one filter
      !isSingleSourceOrSingleFilterFilterable
    ) {
      // If we only have one filter item then there's not
      // much value in making it a filter. Render it in the
      // default manner.
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return renderDefaultView(fallbackElement);
    }

    return (
      <S.SelectablePillsContainer
        onClick={() => {
          filterSources(undefined);
          onFilterSelected(undefined);
        }}
      >
        {filterItems.map((item: any) => {
          const element = item.topSectionElement;

          if (typeof element === "object" && !React.isValidElement(element)) {
            return getCustomPillElement(element, item.id);
          }
          return (
            <S.SelectablePill
              filterPillColor={filterPillColor}
              onClick={e => {
                e.stopPropagation();
                if (item.id === selectedFilter) {
                  filterSources(undefined);
                  onFilterSelected(undefined);
                } else {
                  filterSources(item.id ?? element);
                  onFilterSelected(item.id);
                }
              }}
              key={item.id}
              isFilterSelected={selectedFilter === item.id}
              className={pillClassName}
              ariaLabel={`Inspector filter: ${element}`}
              ariaChecked={selectedFilter === item.id}
            >
              <TruncateLength>{element}</TruncateLength>
            </S.SelectablePill>
          );
        })}
      </S.SelectablePillsContainer>
    );
  };

  const renderDefaultView = (dataToRender = fallbackElement) => {
    return <S.DefaultContainer>{dataToRender}</S.DefaultContainer>;
  };

  switch (dataFormat) {
    case INSPECTOR_DATA_FORMATS.filterableList:
      return renderFilterableListView();
    case INSPECTOR_DATA_FORMATS.default:
      return renderDefaultView();
    default:
      console.error(
        `Unrecognised 'dataFormat' property: ${dataFormat}. 'dataFormat' must be one of: ${Object.values(
          INSPECTOR_DATA_FORMATS
        ).join(", ")}`
      );
      return null;
  }
};

export default InspectorTopContent;
