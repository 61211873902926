import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import {
  Modal as ReactstrapModal,
  ModalBody as ReactstrapModalBody
} from "reactstrap";

import LoadingComponent from "components/atoms/Loading";

import { ReactComponent as CrossInCircle } from "img/icons/cross-in-circle.svg";
import { standardColors, grey, blue, green } from "styles/colors";
import { lineHeight, fontSize, family } from "styles/fonts";

const MAX_REPORT_WIDTH = 1080;

const LoadingContainer = styled.div`
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReportScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  /* don't need the gap for print (PDF) */
  @media screen {
    margin-top: 84px;
  }
`;

const ReportOuterContainer = styled.div`
  --border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 20px;
  width: 100%;
  max-width: ${MAX_REPORT_WIDTH}px; // This is referenced in Section.js, make sure its same
  background-color: ${grey.panel};
  min-height: -webkit-max-content; /*Needed for Safari to show the background at the right length*/
  margin-bottom: 35px;
  border-radius: var(--border-radius);
  border-top-right-radius: ${({ isInViewerMode }) =>
    isInViewerMode ? 0 : "var(--border-radius)"};
  border-top-left-radius: ${({ isInViewerMode }) =>
    isInViewerMode ? 0 : "var(--border-radius)"};

  > * {
    width: 100%;
  }
  .report-inner-container {
    flex-grow: 1;
    padding: 0 25px;
  }
`;

const ViewerModeHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${green.xapienGreen};
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  padding-left: 1rem;
  height: 3rem;
  width: 100%;
  max-width: ${MAX_REPORT_WIDTH}px;
`;

const ViewerModeHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSize.lg};
`;

const ViewerModeHeaderLabel = styled.span`
  padding-left: 7px;

  /* Compensates for the 'eye' icon with its rounded edges */
  padding-bottom: 1px;
`;

const PlaceholderSection = styled.div`
  height: 300px;
  padding-left: 10px;
`;

const Loading = styled(LoadingComponent)`
  path {
    fill: ${props => props.color};
  }
`;

const InfoContainer = styled.div`
  background-color: ${standardColors.white};
  color: ${grey.dark};
  max-width: 1080px;
  padding-bottom: 20px;
  border-radius: 6px;
`;

const InnerInfoContainer = styled.div`
  padding: 25px 5vw;
`;

const Info = styled.div`
  text-align: center;
  margin: 32px auto 0;
  max-width: 700px;
  font-size: ${fontSize.sm};
`;

const CloseModalAlertIcon = styled(CrossInCircle)`
  height: 21px;
  width: 21px;

  circle {
    stroke: transparent;
  }

  path {
    fill: ${standardColors.white};
  }
`;

const CloseModalAlertButton = styled.button`
  all: unset;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;

  &:hover,
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

const Modal = styled(ReactstrapModal)`
  max-width: 860px;
  margin-top: 350px;
  font-size: ${fontSize.md};
`;

const ModalBody = styled(ReactstrapModalBody)`
  display: flex;
  gap: 12px;
  background-color: #355672;
  font-size: ${fontSize.sm};
  padding: 24px;
  border-radius: 12px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
`;

const NoTraceRightContent = styled.div`
  flex: 1;
`;

const MainContent = styled.div`
  margin-bottom: 24px;
`;

const NoReportContext = styled.ul`
  text-align: left;
`;

const Header = styled.h1`
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplay};
  line-height: ${lineHeight.xl};
`;

const SubHeader = styled.h2`
  font-family: ${family.interDisplay};
  font-size: ${fontSize.md};
  line-height: ${lineHeight.md};
  margin: 16px 0;
`;

const ExtraGuidanceText = styled.p`
  margin: 0;
  margin-top: 16px;
  color: ${grey.light};
`;

const PrimaryBannerButton = styled.button`
  all: unset;
  border-radius: 100px;
  padding: 6px 12px;
  border: 1px solid ${standardColors.white};
  background-color: ${standardColors.white};
  color: ${blue.xapienDark};

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0 0 0 4px rgba(40, 239, 250, 0.33);
  }

  &:focus-visible {
    outline: 2px solid blue;
  }
`;

const SecondaryBannerButton = styled.button`
  all: unset;
  padding: 6px 12px;
  border-radius: 100px;
  border: 1px solid ${standardColors.white};

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0 0 0 4px rgba(40, 239, 250, 0.33);
  }

  &:focus-visible {
    outline: 2px solid blue;
  }
`;

/* ClassNameOverrides */

const modalContent = css`
  border-radius: 12px;
  border: none;
`;

const S = {
  ReportScrollContainer,
  ReportOuterContainer,
  ViewerModeHeader,
  ViewerModeHeaderTitle,
  ViewerModeHeaderLabel,
  PlaceholderSection,
  Loading,
  InfoContainer,
  InnerInfoContainer,
  Info,
  CloseModalAlertIcon,
  CloseModalAlertButton,
  Modal,
  ModalBody,
  ActionButtons,
  NoTraceRightContent,
  MainContent,
  NoReportContext,
  Header,
  SubHeader,
  ExtraGuidanceText,
  PrimaryBannerButton,
  SecondaryBannerButton,
  LoadingContainer
};

export const classNameOverrides = {
  modalContent
};

export default S;
