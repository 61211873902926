import React, { useMemo, memo, forwardRef } from "react";
import ErrorBoundary from "util/ErrorBoundary";

import { Section } from "pages/report/Section";
import { SourceGroup } from "pages/report/sections/WebAndMedia/SourceGroup";
import MediaSourceCard from "components/molecules/MediaSourceCard";
import { SourceStack } from "pages/report/sections/WebAndMedia/SourceStack";

const onSourceError = e => {
  console.error("Error showing source", { e });
};

const SourceErrorComponent = () => {
  return <div>There was an error displaying this source.</div>;
};

const sum = xs => xs.reduce((a, b) => a + b, 0);

const MediaResults = memo(
  forwardRef((props, ref) => {
    const {
      sourceGroups,
      isOnlyRisk,
      expandable,
      description,
      noResultsText = "No results found",
      showHeader = true,
      activeRiskCategoryFilters,
      activeRiskDirectionality,
      isDisregarded
    } = props;

    const nonNullSources = useMemo(
      () => sourceGroups.filter(s => s),
      [sourceGroups]
    );

    const sourceGroupsToUse = useMemo(() => {
      const result = sourceGroups.map(source => {
        const updatedSource = { ...source, show: source };

        const sourcesInGroup = [updatedSource];
        return {
          sourceStacks: [
            {
              sources: sourcesInGroup,
              show: true
            }
          ],
          sources: sourcesInGroup,
          show: true
        };
      });

      return result;
    }, [sourceGroups]);

    const sectionCount = sum(
      sourceGroupsToUse.map(g =>
        g.sourceStacks
          ? sum(
              // eslint-disable-next-line @typescript-eslint/no-shadow
              g.sourceStacks.map(s => s.sources.filter(s => s && s.show).length)
            )
          : g.sources.length
      )
    );
    const potentialSectionCount = nonNullSources.length;

    const renderData = () =>
      (sourceGroupsToUse || []).map(group => {
        if (
          group.sourceStacks
            ? group.sourceStacks.length > 1
            : group.sources.length > 1
        ) {
          const sourceIds = (
            group.sourceStacks
              ? group.sourceStacks.flatMap(stack => stack.sources)
              : group.sources
          ).map(source => source.sourceId);

          return (
            <ErrorBoundary
              key={sourceIds.join("+")}
              FallbackComponent={SourceErrorComponent}
              onError={onSourceError}
            >
              <SourceGroup
                {...group}
                isDisregarded={isDisregarded}
                showAssessment={!isOnlyRisk}
                isInSnapshot
                activeRiskCategoryFilters={activeRiskCategoryFilters}
                activeRiskDirectionality={activeRiskDirectionality}
              />
            </ErrorBoundary>
          );
        }
        if (group.sourceStacks && group.sourceStacks.length) {
          const sourceStack = group.sourceStacks[0];
          const sources = [];

          if (
            sourceStack.sources[0] &&
            sourceStack.sources[0].storySources &&
            sourceStack.sources[0].storySources.length
          ) {
            sources.push(...sourceStack.sources[0].storySources);
          }

          const sourceIds = sourceStack.sources
            .filter(s => s && s.sourceId)
            .map(s => s.sourceId);
          return (
            <ErrorBoundary
              key={sourceIds.join("+")}
              FallbackComponent={SourceErrorComponent}
              onError={onSourceError}
            >
              <SourceStack
                isDisregarded={isDisregarded}
                sources={sources}
                show={sourceStack.show}
                showAssessment={!isOnlyRisk}
                isInSnapshot
                activeRiskCategoryFilters={activeRiskCategoryFilters}
                activeRiskDirectionality={activeRiskDirectionality}
              />
            </ErrorBoundary>
          );
        }
        if (group.sources && group.sources.length) {
          const source = group.sources[0];
          const key =
            group.sources &&
            group.sources
              .filter(s => s)
              .map(s => s.sourceId)
              .join("+");
          return (
            <ErrorBoundary
              key={key}
              FallbackComponent={SourceErrorComponent}
              onError={onSourceError}
            >
              <MediaSourceCard
                {...source}
                showAssessment={!isOnlyRisk}
                isInSnapshot
                activeRiskCategoryFilters={activeRiskCategoryFilters}
                activeRiskDirectionality={activeRiskDirectionality}
              />
            </ErrorBoundary>
          );
        }
        console.error(
          `Empty sources for group ${group.title} - will be ignored`
        );
        return null;
      });

    const renderNoResultsSection = () => {
      if (potentialSectionCount <= 0) {
        return noResultsText;
      }
      if (sectionCount <= 0) {
        return "No results - try removing some filters";
      }

      return null;
    };

    return (
      <Section
        {...{ sectionCount, potentialSectionCount }}
        {...props}
        expandable={expandable}
        ref={ref}
        className="source-section media-group"
        showHeader={showHeader}
      >
        {description && (
          <p className="report-section-description sourcing-section-description">
            {description}
          </p>
        )}
        {renderData()}
        {potentialSectionCount <= 0 || sectionCount <= 0 ? (
          <div className="sourcing-section-no-results">
            {renderNoResultsSection()}
          </div>
        ) : null}
      </Section>
    );
  })
);

export default MediaResults;
