import React, { Dispatch, SetStateAction } from "react";

import withMountTransition from "pages/define/withMountTransition";
import SubjectSuggestionItem from "pages/define/SubjectSuggestionItem";
import { ButtonKind } from "components/atoms/Button/types";
import { OrgSuggestion } from "api/define/types";
import { Context, ContextType } from "api/enquiry/types";
import { SUBJECT_CONTEXTS } from "pages/define/utils";

// @ts-ignore
import theme from "theme";

import S from "./styles";

interface Props {
  orgSuggestions?: OrgSuggestion[];
  onSuggestionSelected: () => void;
  setSubjectNameOverride: Dispatch<SetStateAction<string | undefined>>;
  setContextOverride: Dispatch<
    SetStateAction<Record<string, Context> | string | undefined>
  >;
  setContextTypeOverride: Dispatch<SetStateAction<ContextType | undefined>>;
  subjectName: string;
  contextItem?: string | null;
  onStageCancelled: () => void;
}

const SubjectSelectionStage = ({
  orgSuggestions = [],
  onSuggestionSelected,
  setSubjectNameOverride,
  setContextOverride,
  setContextTypeOverride,
  subjectName,
  contextItem,
  onStageCancelled
}: Props) => {
  const onSearchSuggestionConfirmed = (
    suggestionSubject: string,
    suggestionContext: string
  ) => {
    setSubjectNameOverride(suggestionSubject);
    setContextOverride(suggestionContext);
    setContextTypeOverride(SUBJECT_CONTEXTS.organisation.webAddress.type);
    onSuggestionSelected();
  };

  return (
    <S.StageContainer>
      <S.StageHeading>
        {orgSuggestions?.length > 0
          ? "Is this what you're looking for?"
          : "No results found"}
      </S.StageHeading>
      <S.SearchTermsHeading>
        You searched for <strong>{subjectName}</strong> with the context{" "}
        <strong>{contextItem}</strong>.
      </S.SearchTermsHeading>
      {orgSuggestions?.length > 0 ? (
        <S.OrgSuggestions>
          {orgSuggestions.map(suggestion => {
            return (
              <SubjectSuggestionItem
                key={`${suggestion.url} - ${suggestion.name}`}
                suggestionObject={suggestion}
                onSearchSuggestionSelected={onSearchSuggestionConfirmed}
                orgSuggestions={orgSuggestions}
              />
            );
          })}
        </S.OrgSuggestions>
      ) : (
        <S.NoResultsExplainer>
          We were unable to locate a website for the company. You can either go
          &apos;back&apos; to try again with different context, or use
          &apos;Advanced Search&apos; to provide a specific identifier.
        </S.NoResultsExplainer>
      )}
      <S.AlternativeSearchButton
        kind={ButtonKind.secondary}
        onClick={onStageCancelled}
        borderColor={theme.alternativePrimaryColor}
      >
        Try Advanced Search...
      </S.AlternativeSearchButton>
    </S.StageContainer>
  );
};

export default withMountTransition(SubjectSelectionStage);
