import { css } from "@linaria/core";

export const globals = css`
  /* Old legacy code from a long, long time ago. This is essentially modified Bootstrap 4.
  * If you find yourself reaching for these styles to add to or modify, please take this time
  * to make what you're building a Linaria styled component, or if the styles would benefit
  * from being global, a more robust global system than hacked Bootstrap.
  */
  :global() {
    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -1px;
      margin-left: -1px;
    }

    .col-md,
    .col-md-5,
    .col-md-3,
    .col-md-1,
    .col-sm,
    .col-sm-12,
    .col,
    .col-auto,
    .col-12,
    .col-11,
    .col-8,
    .col-4,
    .col-1 {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 1px;
      padding-left: 1px;
    }

    .col {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .col-1 {
      flex: 0 0 8.3333333333%;
      max-width: 8.3333333333%;
    }

    .col-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    .col-8 {
      flex: 0 0 66.6666666667%;
      max-width: 66.6666666667%;
    }

    .col-11 {
      flex: 0 0 91.6666666667%;
      max-width: 91.6666666667%;
    }

    .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media (min-width: 576px) {
      .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    @media (min-width: 768px) {
      .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
      }

      .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
      }
    }
  }
`;
