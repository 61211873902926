import { styled } from "@linaria/react";
import { blue } from "styles/colors";
import { family, fontSize } from "styles/fonts";
import { ReactComponent as InformationSvgIcon } from "img/icons/color-selectable-info-icon.svg";

const DJDisclaimer = styled.div`
  font-size: ${fontSize["2xs"]};
  display: grid;
  gap: 6px;
`;

const DJDisclaimerContainer = styled.div`
  display: grid;
`;

const DJDisclaimerTitle = styled.div`
  font-family: ${family.interSemiBold};
`;

const InformationIcon = styled(InformationSvgIcon)`
  height: 16px;
  width: 16px;
`;

const Link = styled.a`
  color: ${blue.link};
`;

const S = {
  DJDisclaimer,
  DJDisclaimerTitle,
  Link,
  DJDisclaimerContainer,
  InformationIcon
};

export default S;
