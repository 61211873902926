import React, { useContext, Fragment, useState } from "react";
import {
  ControlledMenu as AssertionMenu,
  MenuItem,
  useMenuState
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import { AssertionsContext } from "util/hooks/useAssertionsContext";
import { AssertionModeContext } from "util/context/AssertionModeContext";

import S from "./styles";

const Assertion = ({ assertion }) => {
  const assertionModeEnabled = useContext(AssertionModeContext).enabled;

  const assertionId = assertion.assertionId;
  const { assertionsStore } = useContext(AssertionsContext);
  const [menuProps, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  const handleContextMenu = e => {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  };

  const optionOnClick = assertionId => async () => {
    await assertionsStore.deleteAssertion(assertionId);
  };

  return assertionModeEnabled ? (
    <Fragment>
      <S.Assertion
        onContextMenu={handleContextMenu}
        data-state={assertion.state}
      >
        {assertion.assertion.AssertionHumanReadable}
      </S.Assertion>
      <AssertionMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
      >
        <MenuItem onClick={optionOnClick(assertionId)}>Delete</MenuItem>
      </AssertionMenu>
    </Fragment>
  ) : null;
};

export default Assertion;
