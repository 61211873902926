import { v4 as uuid } from "uuid";
import { xapienInsightsBaseUrl } from "static-config";
import { Reducer, useCallback, useEffect, useReducer } from "react";
import { DefaultService, FeAnswer, OpenAPI } from "api/old-insights";
import { useSubjectName } from "util/hooks/useSubjectName";
import { useEnquiryId } from "util/hooks/useEnquiryId";
import useUserSettings from "util/hooks/useUserSettings";
import { useUserOrganisation } from "util/hooks/useUserOrganisation";

OpenAPI.BASE = xapienInsightsBaseUrl || "";

type QuestionAndAnswerState = NothingAsked | Asking | Answered | Errored;
enum QuestionAndAnswerStateType {
  NothingAsked = "nothingAsked",
  Asking = "asking",
  Answered = "answered",
  Errored = "errored"
}
interface QuestionAndAnswerStateBase {
  type: QuestionAndAnswerStateType;
  requestId?: string;
  question?: string;
}
interface NothingAsked extends QuestionAndAnswerStateBase {
  type: QuestionAndAnswerStateType.NothingAsked;
}
interface Asking extends QuestionAndAnswerStateBase {
  type: QuestionAndAnswerStateType.Asking;
  requestId: string;
  question: string;
}
interface Answered extends QuestionAndAnswerStateBase {
  type: QuestionAndAnswerStateType.Answered;
  question: string;
  requestId: string;
  answer: FeAnswer;
}
interface Errored extends QuestionAndAnswerStateBase {
  type: QuestionAndAnswerStateType.Errored;
  question?: string;
  error?: string;
}

type Action = Ask | Answer | Error | Clear;
enum ActionType {
  Ask = "ask",
  Answer = "answer",
  Error = "error",
  Clear = "clear"
}
interface Ask {
  type: ActionType.Ask;
  question: string;
}
interface Answer {
  type: ActionType.Answer;
  requestId: string;
  answer: FeAnswer;
}
interface Error {
  type: ActionType.Error;
  requestId: string;
  error: string;
}

interface Clear {
  type: ActionType.Clear;
}

const reducer: Reducer<QuestionAndAnswerState, Action> = (
  state: QuestionAndAnswerState,
  action: Action
): QuestionAndAnswerState => {
  switch (action.type) {
    case ActionType.Ask:
      return {
        type: QuestionAndAnswerStateType.Asking,
        requestId: uuid(),
        question: action.question
      };
    case ActionType.Answer:
      if (
        state.type === QuestionAndAnswerStateType.Asking &&
        action.requestId === state.requestId
      ) {
        return {
          type: QuestionAndAnswerStateType.Answered,
          requestId: state.requestId,
          question: state.question,
          answer: action.answer
        };
      }
      return state;
    case ActionType.Error:
      if (
        state.type === QuestionAndAnswerStateType.Asking &&
        action.requestId === state.requestId
      ) {
        return {
          type: QuestionAndAnswerStateType.Errored,
          requestId: action.requestId,
          question: state.question,
          error: action.error
        };
      }
      return state;
    case ActionType.Clear:
      return { type: QuestionAndAnswerStateType.NothingAsked };
    default:
      return state;
  }
};

export const useQuestionAndAnswer = () => {
  const [state, dispatch] = useReducer(reducer, {
    type: QuestionAndAnswerStateType.NothingAsked
  });
  const subjectName = useSubjectName();
  const enquiryId = useEnquiryId();

  const {
    state: {
      userDetails: { firstName, lastName }
    }
  } = useUserSettings();
  const { details: organisationDetails } = useUserOrganisation();
  const organisationName = organisationDetails.loaded
    ? organisationDetails.name
    : undefined;
  const userName = `${firstName} ${lastName}`;
  const reportUrl = `https://${window.location.hostname}/report/${enquiryId}`;

  const questionBeingAsked =
    state.type === QuestionAndAnswerStateType.Asking && state.question;
  const { requestId } = state;

  useEffect(() => {
    if (questionBeingAsked && requestId && subjectName) {
      const promise =
        DefaultService.askSourcedQuestionEnquiryEnquiryIdAskSourcedQuestionPost(
          {
            enquiryId,
            question: questionBeingAsked,
            subjectName,
            userName,
            organisationName,
            reportUrl
          }
        );
      promise.then(
        response => {
          return dispatch({
            type: ActionType.Answer,
            answer: response,
            requestId
          });
        },
        reason => {
          if (!promise.isCancelled) {
            console.error("Error asking Xapien Insights question", { reason });
            dispatch({
              type: ActionType.Error,
              error: `Error asking question, please try again later (${reason})`,
              requestId
            });
          }
        }
      );
      return () => promise.cancel();
    }
    return undefined;
  }, [
    questionBeingAsked,
    requestId,
    enquiryId,
    subjectName,
    userName,
    organisationName,
    reportUrl
  ]);

  const ask = useCallback(
    (question: string | undefined): void => {
      if (question && question.length) {
        dispatch({ type: ActionType.Ask, question });
      }
    },
    [dispatch]
  );
  const answer =
    state.type === QuestionAndAnswerStateType.Answered
      ? state.answer
      : undefined;
  const clear = useCallback(
    () => dispatch({ type: ActionType.Clear }),
    [dispatch]
  );
  const errored = state.type === QuestionAndAnswerStateType.Errored;
  const error = errored ? state.error : undefined;

  return {
    answer,
    ask,
    asking: state.type === QuestionAndAnswerStateType.Asking,
    clear,
    error,
    errored
  };
};
