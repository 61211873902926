import { styled } from "@linaria/react";

import { Info } from "react-feather";

import ButtonNew from "components/atoms/ButtonNew";
import BaseHeading from "components/atoms/Heading";

import { blue, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";
import { zIndex } from "styles/zIndex";

// @ts-ignore
import theme from "theme";

const WarningContainer = styled.div`
  @media (max-width: 767px) {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
`;

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.aboveTop};
  width: 100vw;
  height: 100vh;
  background: ${theme.backgroundColor};
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 767px) {
    display: flex;
  }
`;

const InnerContainer = styled.div`
  max-width: 375px;
  padding: 31px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const Heading = styled(BaseHeading)`
  font-size: ${fontSize.lg};
`;

const Description = styled.p`
  color: ${standardColors.white};
  font-size: ${fontSize.sm};
  text-align: center;
`;

const Highlight = styled.span`
  font-family: ${family.interSemiBold};
  color: ${theme.alternativePrimaryColor ?? blue.blueDiamond};
`;

const IconContainer = styled.div`
  background: ${() => {
    const { r, g, b } = hexToRgb(
      theme.alternativePrimaryColor ?? blue.xapienLight
    );
    return `rgba(${r}, ${g}, ${b}, 0.15)`;
  }};
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(Info)`
  width: 42px;
  height: 42px;
`;

const ContactButton = styled(ButtonNew)`
  &:not(:hover, :focus) {
    background-color: ${theme.alternativePrimaryColor ?? blue.xapienLight};
    color: ${theme.button.primary.color};

    path {
      stroke: ${theme.button.primary.color};
    }
  }
`;

const S = {
  WarningContainer,
  OuterContainer,
  InnerContainer,
  Heading,
  Description,
  IconContainer,
  Icon,
  Highlight,
  Content,
  ContactButton
};

export default S;
