import React, { memo } from "react";

import { formatDate } from "util/common";

import { StyledCardText } from "pages/report/cards/StyledCardText";
import SourceLink from "components/atoms/SourceLink";

import { ImageSizeToUse } from "util/ImageSizeToUse";
import { isFactivaUrl } from "util/factiva";

import S from "./styles";

interface Props {
  image?: string;
  url: {
    fullUrl: string;
  };
  heading: string;
  detectedLanguage?: string;
  publicationDate?: string;
  publicationDateFull?: string;
  provider: string;
  originalLanguage?: string;
}

export const CardHeader = memo(
  ({
    image,
    url,
    heading,
    detectedLanguage,
    publicationDate,
    publicationDateFull,
    provider,
    originalLanguage
  }: Props) => {
    const isTranslated =
      originalLanguage &&
      detectedLanguage &&
      detectedLanguage.toLowerCase() !== originalLanguage.toLowerCase();
    const nonEnglish =
      detectedLanguage && detectedLanguage.toLowerCase() !== "english";

    const showFactivaImage = isFactivaUrl(url.fullUrl);

    const sourceHref = isTranslated
      ? `https://translate.google.com/translate?u=${encodeURIComponent(
          url.fullUrl
        )}`
      : url.fullUrl;

    return (
      <div className="di-card-header">
        <div className="di-card-headings">
          <span>
            <S.TitleContainer>
              <SourceLink truncateLink={false} href={sourceHref}>
                <StyledCardText textLabel="text">{heading}</StyledCardText>
              </SourceLink>
            </S.TitleContainer>{" "}
            {isTranslated ? (
              <S.SmallText>
                ({`Translated from ${originalLanguage}`})
              </S.SmallText>
            ) : nonEnglish ? (
              <S.SmallText>{detectedLanguage}</S.SmallText>
            ) : (
              ""
            )}
          </span>
          <div className="di-card-header-title-box">
            <S.ProviderText>
              <SourceLink truncateLink={false} href={sourceHref}>
                <S.SourceLinkIcon />
                <strong>{provider}</strong>
              </SourceLink>
            </S.ProviderText>
          </div>
          <div className="di-card-header-title-box">
            <S.PublicationDate>
              {publicationDateFull
                ? formatDate(publicationDateFull)
                : publicationDate || ""}
            </S.PublicationDate>
          </div>
        </div>
        {showFactivaImage ? (
          <S.FactivaLogoContainer>
            <S.FactivaLogo />
          </S.FactivaLogoContainer>
        ) : image ? (
          // @ts-ignore
          <S.SourceImage
            image={image}
            width="64px"
            height="64px"
            lazyLoad={false}
            imageSizeToUse={ImageSizeToUse.Small}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
);
