import { useContext } from "react";
import endpoints from "services/endpoints";

import { ImageStoreContext } from "state/ImageStore";
import { ImageSizeToUse } from "util/ImageSizeToUse";
import { isDevel } from "services/stage";
import { EnquiryIdContext } from "./useEnquiryId";
import { PersonaIdContext } from "./usePersonaId";

function tripleUriEncode(imageKey) {
  return encodeURIComponent(encodeURIComponent(encodeURIComponent(imageKey)));
}

export const useImageKeyLookup = () => {
  const imageStore = useContext(ImageStoreContext);
  const enquiryId = useContext(EnquiryIdContext);
  const personaId = useContext(PersonaIdContext);

  const getSrc = input => {
    const { imageKey, src, imageSizeToUse } =
      typeof input === "string" ? { src: input } : input;
    const keyToUse = imageKey || (src && src.split("").reverse().join(""));

    if (src && src.startsWith("data:")) {
      return src;
    }
    if (src && src.startsWith("/images/")) {
      return window.location.origin + src;
    }
    if (keyToUse && imageStore.has(keyToUse)) {
      const imageStuff = imageStore.get(keyToUse);
      const resizedVersionUrls = new Map(
        Object.entries((imageStuff && imageStuff.ResizedVersionUrls) || {})
      );
      const signedUrl =
        imageSizeToUse && resizedVersionUrls.has(imageSizeToUse)
          ? resizedVersionUrls.get(imageSizeToUse)
          : imageStuff.Url;

      return signedUrl;
    }
    if (!imageStore.has(keyToUse) && imageStore.size > 0) {
      return "";
    }
    if (!keyToUse || !keyToUse.length) {
      console.error("No key or url for image", input);
      return "";
    }
    if (imageStore.size === 0 && imageKey && imageKey.length) {
      // for some strange reason API gateway needs to have it triple encoded to successfully
      // pass all the way through and reach my lambda just uri encoded once - otherwise you
      // get 400 errors (and 500 errors when double-encoded)
      // https://tech.forums.softwareag.com/t/api-gateway-is-not-handling-character-in-the-url/238643

      if (isDevel && personaId) {
        return `${
          endpoints.servePersonaReportImage
        }/${enquiryId}/images/${imageKey}${
          imageSizeToUse &&
          imageSizeToUse.length &&
          imageSizeToUse !== ImageSizeToUse.Original
            ? `?size=${imageSizeToUse}`
            : ""
        }`;
      }

      const encodedKey = tripleUriEncode(imageKey);
      const encodedEnquiryId = tripleUriEncode(enquiryId);

      return `${endpoints.serveImage}/${encodedEnquiryId}/${encodedKey}${
        imageSizeToUse &&
        imageSizeToUse.length &&
        imageSizeToUse !== ImageSizeToUse.Original
          ? `?size=${imageSizeToUse}`
          : ""
      }`;
      // reports using this are SO far out of date - it's fine if their images are now not showing
      /* console.error(
        "The image store doesn't have the key we were looking for, and is empty, so will use original (for backward compatibility)",
        input
      );
      // THIS SHOULD NOT BE A PERMANENT THING, ONLY AS FIRST PASS TODO
      return src; */
    }
    return null;
  };

  return { getSrc };
};
