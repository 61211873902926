import React from "react";

const SectionErrorContents = () => {
  return (
    <div className="section-error-contents">
      There was an error rendering this section, please contact support.
    </div>
  );
};

export default SectionErrorContents;
