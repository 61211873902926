import React from "react";

import { AvatarSize } from "components/atoms/Avatar/types";
import Avatar from "components/atoms/Avatar";
import { CardVariant } from "components/molecules/Card/types";
import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

interface Props {
  datetime?: string;
  author?: string;
  loading?: boolean;
  children?: React.ReactNode;
  variant?: CardVariant;
}

const ListCardFooter = ({
  author,
  datetime,
  loading,
  children,
  variant = CardVariant.default
}: Props) => {
  return loading ? (
    <S.Footer>
      <S.FooterTopContent>
        <Skeleton width="100px" />
        <Avatar avatarSize={AvatarSize.Small} loading />
      </S.FooterTopContent>
      <Skeleton width="100px" />
    </S.Footer>
  ) : (
    <S.Footer variant={variant}>
      <S.FooterTopContent>{author}</S.FooterTopContent>
      <S.DateTimeAndChildren>
        {datetime}
        {children}
      </S.DateTimeAndChildren>
    </S.Footer>
  );
};

export default ListCardFooter;
