import React from "react";
import { formatDate } from "util/common";
import SourceLink from "components/atoms/SourceLink";
import WithInspector from "components/organisms/WithInspector";

import S from "./styles";

const SingleImage = ({ image, sources }) => {
  const renderFullImage = () => (
    <S.SingleImageContainer>
      <S.ImageColumn width={image.width} height={image.height}>
        {image.originUrl ? (
          <SourceLink href={image.originUrl}>
            <S.FullImage
              alt="Related photograph"
              image={image}
              lazyLoad={false}
              width="auto"
              height="220px"
            />
          </SourceLink>
        ) : (
          <S.FullImage
            alt="Related photograph"
            image={image}
            lazyLoad={false}
            width="auto"
            height="220px"
          />
        )}
      </S.ImageColumn>

      <S.InfoColumn>
        {image.containingDocumentTitle && (
          <S.ImageDocumentTitle>
            {image.containingDocumentTitle}
          </S.ImageDocumentTitle>
        )}
        {image.altText && <S.ImageAltText>{image.altText}</S.ImageAltText>}
        {image.date && <S.ImageDate>{formatDate(image.date)}</S.ImageDate>}
        {image.originUrl && (
          <S.ImageOriginUrl>
            <SourceLink href={image.originUrl}>{image.originUrl}</SourceLink>
          </S.ImageOriginUrl>
        )}
        {!image.originUrl && image.thirdPartyContent ? (
          <S.ImageThirdParty>
            Image source: third party data provider
          </S.ImageThirdParty>
        ) : (
          false
        )}
        {sources.length && sources.length > 1 && (
          <S.ImageSources>
            <WithInspector showTopSection={false} sources={sources}>
              {`${sources.length} sources (see all)`}
            </WithInspector>
          </S.ImageSources>
        )}
      </S.InfoColumn>
    </S.SingleImageContainer>
  );

  return renderFullImage();
};

export default React.memo(SingleImage);
