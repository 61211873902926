import { styled } from "@linaria/react";

import { AlertCircle } from "react-feather";
import { ReactComponent as CheckCircle } from "img/insights-icons/check-circle.svg";

import {
  fontSize,
  family,
  headingLetterSpacing,
  lineHeight
} from "styles/fonts";
import { blue, green, grey, red, purple } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";
import InspectorStyles from "components/organisms/WithInspector/styles";

// @ts-ignore;
import theme from "theme";
import { isPDX } from "static-config";

const Heading1 = styled.h1`
  display: inline-block;
  font-family: ${family.interDisplay};
  font-size: ${fontSize.xl};
  font-weight: inherit;
  letter-spacing: ${headingLetterSpacing.xl};
  line-height: ${lineHeight.md};
  margin: 0;
  margin-bottom: 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &[contenteditable="true"] {
    cursor: text;
    color: ${() => {
      const { r, g, b } = hexToRgb(purple.darker);
      return `rgba(${r}, ${g}, ${b}, 0.7)`;
    }};
    border-bottom: 2px solid
      ${() => {
        const { r, g, b } = hexToRgb(purple.darker);
        return `rgba(${r}, ${g}, ${b}, 0.7)`;
      }};
  }

  ${InspectorStyles.InspectorInnerContainer} {
    color: unset;
  }
`;

const Heading2 = styled.h2`
  display: inline-block;
  font-family: ${family.interDisplay};
  font-size: ${fontSize.lg};
  font-weight: inherit;
  letter-spacing: ${headingLetterSpacing.lg};
  line-height: ${lineHeight.lg};
  margin-bottom: 8px;
  cursor: pointer;

  border-bottom: 2px solid transparent;

  &:focus {
    outline: none;
  }

  &[contenteditable="true"] {
    cursor: text;
    color: ${() => {
      const { r, g, b } = hexToRgb(isPDX ? theme.primaryColor : purple.darker);
      return `rgba(${r}, ${g}, ${b}, 0.7)`;
    }};
    border-bottom: 2px solid
      ${() => {
        const { r, g, b } = hexToRgb(
          isPDX ? theme.primaryColor : purple.darker
        );
        return `rgba(${r}, ${g}, ${b}, 0.7)`;
      }};
  }

  ${InspectorStyles.InspectorInnerContainer} {
    color: unset;
  }
`;

const Heading3 = styled.h3`
  display: inline-block;
  font-family: ${family.interDisplay};
  font-size: ${fontSize.md};
  font-weight: inherit;
  letter-spacing: ${headingLetterSpacing.md};
  line-height: ${lineHeight.xl};
  margin-bottom: 4px;
  color: ${blue.xapienSecondaryLight};
  cursor: pointer;

  border-bottom: 2px solid transparent;

  &:focus {
    outline: none;
  }

  &[contenteditable="true"] {
    cursor: text;
    color: ${() => {
      const { r, g, b } = hexToRgb(purple.darker);
      return `rgba(${r}, ${g}, ${b}, 0.7)`;
    }};
    border-bottom: 2px solid
      ${() => {
        const { r, g, b } = hexToRgb(purple.darker);
        return `rgba(${r}, ${g}, ${b}, 0.7)`;
      }};
  }

  ${InspectorStyles.InspectorInnerContainer} {
    color: unset;
  }
`;

const Heading4 = styled.h4`
  font-family: ${family.interDisplay};
  font-size: ${fontSize.sm};
  font-weight: inherit;
  letter-spacing: ${headingLetterSpacing.sm};
  margin-bottom: 0.5rem;

  ${InspectorStyles.InspectorInnerContainer} {
    color: unset;
  }
`;

const Heading5 = styled.h4`
  font-family: ${family.interLight};
  font-size: ${fontSize["2xs"]};
  font-weight: inherit;
  text-transform: uppercase;
  color: ${grey.dark};
  letter-spacing: ${headingLetterSpacing.sm};
  margin-bottom: 0.75rem;
  margin-top: -0.25rem;

  ${InspectorStyles.InspectorInnerContainer} {
    color: unset;
  }
`;

const Paragraph = styled.div`
  font-size: ${fontSize.sm};
  margin: 0;
  padding-right: 25px;
  padding-bottom: 20px;
  white-space-collapse: preserve-breaks;

  ${InspectorStyles.InspectorInnerContainer} {
    color: unset;
  }
`;

const Division = styled.div`
  font-size: ${fontSize.sm};
  margin: 0;
`;

const TickCircle = styled.img`
  padding-right: 4px;
  height: 38px;
  width: 38px;
`;

const ThreeBalls = styled.img`
  max-height: 38px;
  max-width: 38px;
`;

const ThreeBallsContainer = styled.div`
  position: absolute;
  top: 0;
  left: -44px;
`;

const SourcesFetchedSuccessIcon = styled(CheckCircle)`
  color: ${green.xapienGreen};
  height: 16px;
  width: 16px;
`;

const SourcesFetchedSuccessIconContainer = styled.div`
  position: absolute;
  top: 2px;
  left: -30px;
`;

const SourcesFetchedErrorIcon = styled(AlertCircle)`
  color: ${red.badgeRed};
`;

const SourcesFetchedErrorIconContainer = styled.div`
  position: absolute;
  top: 2px;
  left: -39px;
`;

const ParagraphContainer = styled.div`
  position: relative;
`;

const Placeholder = styled.div<{ isHidingPlaceholder: boolean }>`
  position: relative;
  min-width: 160px;
  height: 34px;

  &:before {
    content: "Add section title";
    display: ${({ isHidingPlaceholder }) =>
      isHidingPlaceholder ? "none" : "block"};
    color: ${() => {
      const { r, g, b } = hexToRgb(purple.darker);
      return `rgba(${r}, ${g}, ${b}, 0.2)`;
    }};
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const S = {
  Placeholder,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Paragraph,
  Division,
  ParagraphContainer,
  ThreeBalls,
  ThreeBallsContainer,
  TickCircle,
  SourcesFetchedSuccessIcon,
  SourcesFetchedSuccessIconContainer,
  SourcesFetchedErrorIcon,
  SourcesFetchedErrorIconContainer
};

export default S;
