import React from "react";
import S from "./styles";

const XapienDisclaimer = () => {
  return (
    <S.Disclaimer>
      <p>
        This report has been generated by proprietary AI based on available
        third-party information. As such, the information in our reports is
        provided on an “as-is basis” as we cannot guarantee that it is accurate
        or up-to-date. You rely on this at your sole risk and are solely
        responsible for any decisions you make arising from this information.
      </p>
      <p>
        {" "}
        <a
          href="https://xapien.com/disclaimer"
          target="_blank"
          rel="noreferrer"
        >
          Please review our full disclaimer
        </a>
      </p>
    </S.Disclaimer>
  );
};

export default XapienDisclaimer;
