import React from "react";

import TruncateLength from "util/TruncateLength";

import S from "./styles";

const InfoSummaryRow = ({
  icon,
  heading,
  subheading,
  content,
  interactive,
  onClick = () => {},
  className,
  style
}) => {
  return (
    <S.InfoSummaryRow
      interactive={interactive}
      onClick={interactive ? onClick : null}
      className={className}
      style={style}
    >
      {icon}
      <S.RightContent>
        <S.Headings>
          <S.Heading>
            <TruncateLength>{heading}</TruncateLength>
          </S.Heading>
          <S.SubHeading>{subheading}</S.SubHeading>
        </S.Headings>
        {content}
      </S.RightContent>
    </S.InfoSummaryRow>
  );
};

export default InfoSummaryRow;
