import { styled } from "@linaria/react";

import ContextSwitchComponent from "components/atoms/ContentSwitch";
import { ReactComponent as InfoSvgIcon } from "img/icons/information-icon.svg";

import { standardColors, grey } from "styles/colors";
import { fontSize, family } from "styles/fonts";

import TextInputComponent from "components/atoms/TextInput";

const StageContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  transition: opacity 0.2s;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
`;

const StageHeading = styled.p<{ disabled?: boolean }>`
  font-size: ${fontSize["2xl"]};
  text-align: center;
  cursor: default;
  font-family: ${family.interLight};
  margin-bottom: 29px;
  transition: opacity 0.2s;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
`;

const ContentSwitchContainer = styled.div<{ isDisabledFeature?: boolean }>`
  position: relative;

  &:hover {
    cursor: ${props => (props.isDisabledFeature ? "pointer" : "default")};
  }
`;

const ContentSwitch = styled(ContextSwitchComponent)`
  margin: 20px 0;
`;

const TextInput = styled(TextInputComponent)`
  margin: 20px !important;
  width: 387px !important;
`;

const TooltipContent = styled.div`
  text-align: left;
  padding: 8px;
  overflow-y: auto;
  width: 365px;
  padding-right: 5px;
  font-size: ${fontSize.sm};
`;

const TooltipHeader = styled.div`
  border-bottom: 1px solid ${grey.rule};
  padding-bottom: 6px;
`;

const TooltipBody = styled.div`
  padding: 8px 0;
  max-height: 300px;
  overflow-y: auto;
`;

const InfoIcon = styled(InfoSvgIcon)`
  width: 20px;
  height: 20px;

  path {
    fill: ${standardColors.white};
  }

  &:hover {
    cursor: pointer;
  }
`;

const BetaTabContainer = styled.div`
  position: absolute;
  top: -4px;
  right: 1px;
`;

const S = {
  StageContainer,
  StageHeading,
  ContentSwitchContainer,
  ContentSwitch,
  TextInput,
  TooltipContent,
  TooltipHeader,
  TooltipBody,
  InfoIcon,
  BetaTabContainer
};

export default S;
