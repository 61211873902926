import { createContext } from "react";

import { AutoSharePermission, SharedWithItem } from "api/user/types";
import { SearchItemType } from "api/search";
import { FetchResult } from "api/types";

import {
  UserPreferencesState,
  UserPreferencesActions,
  UserPreferencesAction
} from "./types";

export const initialState: UserPreferencesState = {
  autoshare: false,
  sharedWithUsers: [],
  sharedWithGroups: [],
  sharedWith: []
};

export const UserPreferencesContext = createContext({
  state: initialState,
  toggleAutoshare: () => {
    console.error("toggleAutoshare does not have any implementation");
  },
  updateSharePermission: async (
    _id: string,
    _permission: AutoSharePermission
  ): Promise<FetchResult> => {
    console.error("updateSharePermission does not have any implementation");
    return { status: false };
  },
  addShareEntries: async (
    _shareEntries: (SharedWithItem & { type: SearchItemType })[]
  ): Promise<FetchResult> => {
    console.error("addShareEntries does not have any implementation");
    return { status: false };
  }
});

export const userPreferencesReducer = (
  state: UserPreferencesState,
  action: UserPreferencesAction
): UserPreferencesState => {
  switch (action.type) {
    case UserPreferencesActions.updateAutoSharedWith:
      return {
        ...state,
        sharedWithUsers: action.sharedWithUsers,
        sharedWithGroups: action.sharedWithGroups,
        autoshare:
          action.autoshare !== undefined ? action.autoshare : state.autoshare
      };
    case UserPreferencesActions.updateAutoShareEnabled:
      return { ...state, autoshare: action.autoshare };
    case UserPreferencesActions.resetUserPreferences:
      return initialState;
    default:
      return state;
  }
};
