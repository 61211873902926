import { observable, action, runInAction, makeObservable } from "mobx";
import Enquiry from "../api/enquiry/Enquiry";

class EnquiryStore {
  constructor(sharedUserInterface, enquiryApi) {
    this.currentEnquiry = observable.object({});
    this.sharedUserInterface = sharedUserInterface;
    this.enquiryApi =
      enquiryApi ||
      new Enquiry(state =>
        runInAction(() => {
          this.sharedUserInterface.apiCallInProgress = state;
        })
      );
    // this is tracked across the system when pages are entered from URLs.
    // it helps deal with page refreshes and other disjoint operations
    this.currentEnquiryId = null;
    this.isCreatingEnquiry = false;

    makeObservable(this, {
      create: action,
      clearCurrentEnquiry: action,
      currentEnquiry: observable,
      currentEnquiryId: observable,
      setCurrentEnquiryId: action,
      isCreatingEnquiry: observable
    });
  }

  create = async enquiryPayload => {
    // if (projectReference) {
    //   payload.projectReference = projectReference;
    // }
    this.isCreatingEnquiry = true;

    const enquiry = await this.enquiryApi.create(enquiryPayload);

    this.currentEnquiry = enquiry;
    this.isCreatingEnquiry = false;

    return enquiry;
  };

  status(enquiryId) {
    return new Promise((resolve, reject) => {
      this.enquiryApi.status(enquiryId).then(resolve, reject);
    });
  }

  clearCurrentEnquiry() {
    this.currentEnquiry = null;
  }

  setCurrentEnquiryId(enquiryId) {
    this.currentEnquiryId = enquiryId;
  }
}

export default EnquiryStore;
