import { styled } from "@linaria/react";

import { ReactComponent as BusinessIconSimple } from "img/icons/business_placeholder_simple.svg";

import { blue, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

import Button from "components/atoms/Button";

const GoButton = styled(Button)<{
  isHighlighted?: boolean;
  backgroundColor?: string;
}>`
  min-width: 64px !important;
  opacity: ${props => (props.isHighlighted ? 1 : 0.3)};
  background-color: ${props =>
    props.backgroundColor ?? blue.xapienLight} !important;
`;

const SuggestionItem = styled.div`
  padding: 11px 16px 11px 0;
  margin-bottom: 2px;
  background-color: rgba(255, 255, 255, 0.12);

  transition: 0.2s background-color;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);

    ${GoButton} {
      opacity: 1;
    }
  }
`;

const SuggestionUrl = styled.p`
  font-size: ${fontSize.lg};
  margin-bottom: 0;
`;

const SuggestionStraplineAndDescription = styled.p`
  font-size: ${fontSize.sm};
  opacity: 0.75;
  font-family: ${family.interLight};
  margin-bottom: 0;
  word-break: break-word;
`;

const SuggestionStrapline = styled.p`
  font-family: ${family.interSemiBold};
  margin: 0;
  display: inline;
`;

const SuggestionDescription = styled.p`
  margin: 0;
  display: inline;
`;

const LogoContainer = styled.div`
  width: 85px;
  padding-left: 16px;
  text-align: center;
`;

const SuggestionBodyContainer = styled.div`
  padding: 0 16px;
  flex: 1;
`;

const OrgPlaceholderIcon = styled(BusinessIconSimple)`
  opacity: 0.7;
  height: 40px;
  width: 40px;

  path {
    fill: ${standardColors.white};
  }
`;

const OrgLogo = styled.img`
  object-fit: contain;
`;

const S = {
  SuggestionUrl,
  SuggestionStraplineAndDescription,
  SuggestionStrapline,
  SuggestionDescription,
  SuggestionItem,
  LogoContainer,
  SuggestionBodyContainer,
  GoButton,
  OrgPlaceholderIcon,
  OrgLogo
};

export default S;
