import { styled } from "@linaria/react";
import { grey } from "styles/colors";

import { fontSize } from "styles/fonts";

const ListItemWithOptions = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const ListItemWithOptionsContent = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const TitleAndSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

const Title = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.375rem;
`;

const Subtitle = styled.div`
  color: ${grey.rule};
  font-size: ${fontSize.xs};
  line-height: 1.5rem;
`;

const S = {
  ListItemWithOptions,
  ListItemWithOptionsContent,
  TitleAndSubtitle,
  Title,
  Subtitle
};

export default S;
