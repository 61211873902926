import { styled } from "@linaria/react";
import { blue, grey } from "styles/colors";
import { fontSize } from "styles/fonts";

import Heading from "components/atoms/Heading";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.75rem;
  background-color: ${blue.xapienDark};
  width: 100%;
  padding: 0.75rem;
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const SubHeading = styled(Heading)`
  font-size: ${fontSize.md} !important;
  line-height: 1.5rem !important;
  color: ${grey.rule} !important;
`;

const RightIcon = styled.div`
  padding-right: 1rem;
`;

const SkeletonContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const SkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const S = {
  TitleCard,
  TopContent,
  TitleContainer,
  SubHeading,
  Container,
  RightIcon,
  SkeletonContainer,
  SkeletonContent
};

export default S;
