import { createContext } from "react";
import { ReportSharePermission, SharedWithItem } from "api/reports/types";
import { SearchItemType } from "api/search";
import { FetchResult } from "api/types";

interface ShareItem {
  title: string;
  subtitle: string;
  type: SearchItemType;
  id: string;
  permission: ReportSharePermission;
}

export const ShareReportContext = createContext({
  sharedWith: [] as ShareItem[],
  isSharingWithUser: false,
  setIsSharingWithUser: (_value: boolean) => {
    console.error("setIsSharingWithUser does not have any implementation");
  },
  isShareModalOpen: false,
  isTransferOwnerModalOpen: false,
  isDeleteShareLinkModalOpen: false,
  generatedLink: undefined as string | undefined,
  isSharedWithLoading: false,
  toggleShareModal: () => {},
  toggleTransferOwnerModal: () => {},
  toggleDeleteShareLinkModal: () => {},
  updateSharePermission: async (
    _shareEntryId: string,
    _permission: ReportSharePermission
  ): Promise<FetchResult> => {
    console.error("updateSharedPermission does not have any implementation");
    return { status: false };
  },
  addShareEntries: async (
    _shareEntries: (SharedWithItem & { type: SearchItemType })[]
  ): Promise<FetchResult> => {
    console.error("addShareEntries does not have any implementation");
    return { status: false };
  },
  generateShareLink: async (): Promise<FetchResult> => {
    console.error("generateShareLink does not have any implementation");
    return { status: false };
  },
  getShareLink: async () => {
    console.error("getShareLink does not have any implementation");
  },
  deleteShareLink: async (): Promise<FetchResult> => {
    console.error("deleteShareLink does not have any implementation");
    return { status: false };
  },
  transferOwnership: async (
    _reportId: string,
    _userId: string,
    _accessLevelToRetain:
      | ReportSharePermission.Write
      | ReportSharePermission.Read
  ): Promise<FetchResult> => {
    console.error("transferOwnership does not have any implementation");
    return { status: false };
  }
});
