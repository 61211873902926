import { styled } from "@linaria/react";

import { grey } from "styles/colors";

const Toolbar = styled.div`
  padding-bottom: 10px;
`;

const ToolbarButton = styled.button`
  outline: none;
  border-radius: 6px;
  background-color: ${props =>
    props.isActive ? "rgba(0, 0, 0, 0.1)" : "transparent"};
  border: none;
  transition: background-color 0.2s;
  margin-right: 2px;

  & > svg {
    path {
      fill: ${grey.dark};
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
  }
`;

const ColorPicker = styled.div`
  display: flex;
  padding: 8px;
  flex-wrap: wrap;
  gap: 8px;
  width: 120px;
`;

const ColorOption = styled.button`
  background-color: ${props => props.color};
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: none;
  outline: none;
`;

const S = {
  Toolbar,
  ToolbarButton,
  ColorPicker,
  ColorOption
};

export default S;
