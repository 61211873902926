import React, { useEffect } from "react";
import GettingStartedModal from "components/molecules/GettingStartedModal";
import { RegistrationContextProvider } from "util/hooks/useRegistration";
import { useAuthentication } from "util/hooks/useAuthentication";
import { useNavigate } from "react-router-dom";

import { AuthenticationStatus } from "util/hooks/useAuthentication/types";
import S from "./styles";

const GettingStarted = () => {
  const {
    state: { status }
  } = useAuthentication();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");

  useEffect(() => {
    if (status === AuthenticationStatus.authenticated) {
      navigate("/");
    }
  }, [status, navigate]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  if (!token) {
    navigate("/login");
    return null;
  }

  return (
    <RegistrationContextProvider token={token}>
      <S.Container>
        <GettingStartedModal />
      </S.Container>
    </RegistrationContextProvider>
  );
};

export default GettingStarted;
