export enum RequestQueueActions {
  addJob = "addJob",
  jobCompleted = "jobCompleted",
  jobCallbacksCompleted = "jobCallbacksCompleted",
  jobFailed = "jobFailed",
  queueFailed = "queueFailed",
  processJob = "processJob",
  processJobTask = "processJobTask",
  processCallbacks = "processCallbacks",
  resetStatus = "resetStatus"
}

export enum RequestQueueStatus {
  idle = "idle", // Nothing in the queue
  processingTask = "processingTask", // A job task is being processed
  processingCallbacks = "processingCallbacks", // The callbacks are being processed
  error = "error" // There is an error with a job
}

export enum RequestJobStatus {
  idle = "idle",
  processingTask = "processingTask",
  error = "error"
}

export interface RequestJobData {
  task: () => Promise<unknown>;
  callback: (callbackData?: any) => void;
}

export type RequestJob = RequestJobData & {
  id: string;
  status: RequestJobStatus;
  callbackData?: any;
  error?: unknown;
};

export interface JobCallbacksCompletedAction {
  type: RequestQueueActions.jobCallbacksCompleted;
}

export interface JobCompletedAction {
  type: RequestQueueActions.jobCompleted;
  taskResponse?: unknown;
}

export interface JobFailedAction {
  type: RequestQueueActions.jobFailed;
  error: unknown;
}

export interface QueueFailedAction {
  type: RequestQueueActions.queueFailed;
  error: string;
}

export interface ResetStatusAction {
  type: RequestQueueActions.resetStatus;
}

export interface ProcessJobAction {
  type: RequestQueueActions.processJob;
  jobId: string;
}

export interface ProcessJobTaskAction {
  type: RequestQueueActions.processJobTask;
}

export interface ProcessCallbacksAction {
  type: RequestQueueActions.processCallbacks;
}

export interface AddJobAction {
  type: RequestQueueActions.addJob;
  job: RequestJobData;
}

export type RequestQueueAction =
  | AddJobAction
  | ProcessJobAction
  | ProcessJobTaskAction
  | ProcessCallbacksAction
  | QueueFailedAction
  | ResetStatusAction
  | JobCallbacksCompletedAction
  | JobCompletedAction
  | JobFailedAction;

export interface RequestQueueState {
  status: RequestQueueStatus;
  processingJobId?: string;
  error?: unknown;
  requestQueue: RequestJob[];
  callbackQueue: RequestJob[];
}
