import {
  InsightReportStatus,
  InsightReportState
} from "util/hooks/useInsightReport/types";

const getHasBeenDeleted = ({
  state,
  parentIds,
  id
}: {
  state: InsightReportState;
  parentIds: string[];
  id: string;
}) => {
  if (state.status !== InsightReportStatus.removingReportSectionSuccess)
    return false;

  const [sectionId, subsectionId] = parentIds;

  if (!sectionId && !subsectionId) {
    // We are a section

    if (
      id === state.sectionIdToRemove &&
      !state.subsectionIdToRemove &&
      !state.elementIdToRemove
    ) {
      return true;
    }
  }

  if (!subsectionId) {
    // We are a subsection

    if (
      id === state.subsectionIdToRemove &&
      state.sectionIdToRemove === sectionId &&
      !state.elementIdToRemove
    ) {
      return true;
    }
  }

  // We are an element
  if (
    id === state.elementIdToRemove &&
    state.subsectionIdToRemove === subsectionId &&
    state.sectionIdToRemove === sectionId
  ) {
    return true;
  }

  return false;
};

const getHasBeenMoved = ({
  state,
  id
}: {
  state: InsightReportState;
  id: string;
}) => {
  if (state.status !== InsightReportStatus.movingSubsectionSuccess)
    return false;

  if (state.currentSubSectionId !== id) return false;

  return true;
};

export const getHasBeenDeletedOrMoved = ({
  nodeType,
  state,
  parentIds,
  id
}: {
  nodeType: string;
  state: InsightReportState;
  parentIds: string[];
  id: string;
}) => {
  if (nodeType !== "div") return false;

  return (
    getHasBeenMoved({ state, id }) ||
    getHasBeenDeleted({ state, parentIds, id })
  );
};
