import { styled } from "@linaria/react";
import { grey } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const InsightReportSection = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Heading = styled.h1`
  margin-bottom: 10px;
  font-size: ${fontSize.sm};
  color: ${grey.dark};
  font-family: ${family.interDisplay};
`;

const S = {
  InsightReportSection,
  Heading
};

export default S;
