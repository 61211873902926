import { styled } from "@linaria/react";
import { fontSize } from "styles/fonts";

export interface RiskCategoryPillProps {
  backgroundColor: string;
}

const RiskCategoryPill = styled.div<RiskCategoryPillProps>`
  border-radius: 16px;
  font-size: ${fontSize["2xs"]};
  display: inline-block;
  padding: 2px 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  text-transform: capitalize;
  background-color: ${({ backgroundColor }) =>
    `${backgroundColor}18`} !important;
  color: ${({ backgroundColor }) => backgroundColor} !important;
  }};
  min-height: 20px !important;
  min-width: auto !important;
  box-shadow: none !important;

  &:hover {
    box-shadow: 0 0 0 4px rgba(239, 114, 115, 0.25) !important;
    cursor: pointer;
  }
`;

const S = {
  RiskCategoryPill
};

export default S;
