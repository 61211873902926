import React from "react";
import { family } from "styles/fonts";

/**
 * Applies emboldening to phrases in a string
 * @param {string} string to which to apply emboldening
 * @param {object[]} offsets array containing index and lengths of each keyword substring in string
 * @param {string} emboldenClassOverride classname to apply styles to embolened string
 * @returns {object[]} array containing tags around the emboldened phrases
 */
export const emboldenKeyWords = (string, offsets, emboldenClassOverride) => {
  const originalString = string;
  let newString = originalString;

  offsets?.slice()?.sort((a, b) => {
    return a.startIndex - b.startIndex;
  });

  // Insert tags
  let offset = 0; // Take into account the newly added bold tags
  offsets?.forEach(keyTerm => {
    newString = `${newString.slice(
      0,
      keyTerm.startIndex + offset
    )}<b>${newString.slice(
      keyTerm.startIndex + offset,
      keyTerm.startIndex + keyTerm.textLength + offset
    )}</b>${newString.slice(keyTerm.startIndex + keyTerm.textLength + offset)}`;
    offset += 7; // Open and ending bold tags is of length 7
  });

  // Split string at both beginning and end tags
  const stringsArray = newString
    .split(/(<b>)/gi)
    .flatMap(text => text.split(/(<\/b>)/gi));

  let foundTag = false;
  const emboldenedStringsArray = stringsArray.map((text, index) => {
    if (!text.length) {
      return null;
    }

    if (text === "<b>" || text === "</b>") {
      if (text === "<b>") {
        foundTag = true;
      }
      return null;
    }
    if (foundTag) {
      foundTag = false;
      return (
        <span
          key={index}
          className={emboldenClassOverride}
          style={{ fontFamily: family.interSemiBold }}
        >
          {text}
        </span>
      );
    }
    return <span key={index}>{text}</span>;
  });

  return emboldenedStringsArray.filter(Boolean);
};
