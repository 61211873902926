import { useReducer } from "react";

export enum RequestActions {
  Reset = "RESET",
  ResetError = "RESET_ERROR",
  SetError = "SET_ERROR",
  SendRequest = "SEND_REQUEST",
  SetSuccess = "SUCCESS"
}

type RequestAction =
  | { type: RequestActions.Reset }
  | { type: RequestActions.ResetError }
  | { type: RequestActions.SetError; errorMessage?: string }
  | { type: RequestActions.SendRequest }
  | { type: RequestActions.SetSuccess };

interface RequestState {
  fetching: boolean;
  error: boolean;
  success: boolean;
  errorMessage?: string;
}

const requestReducer = (state: RequestState, action: RequestAction) => {
  switch (action.type) {
    case RequestActions.SendRequest:
      return {
        ...state,
        fetching: true,
        error: false,
        success: false
      };
    case RequestActions.SetError:
      return {
        ...state,
        fetching: false,
        error: true,
        errorMessage: action.errorMessage,
        success: false
      };
    case RequestActions.Reset:
      return {
        ...state,
        fetching: false,
        error: false,
        success: false
      };
    case RequestActions.SetSuccess:
      return {
        ...state,
        fetching: false,
        error: false,
        success: true
      };
    case RequestActions.ResetError:
      return {
        ...state,
        error: false
      };
    default:
      return state;
  }
};

const useFetchReducer = () => {
  return useReducer(requestReducer, {
    fetching: false,
    error: false,
    success: false
  });
};

export default useFetchReducer;
