import React from "react";

import { grey } from "styles/colors";

import { CardVariant } from "components/molecules/Card/types";
import { ChipVariant } from "./types";
import type { Props } from "./types";

import S from "./styles";

const Chip = ({
  size,
  onDelete,
  label,
  variant = ChipVariant.Outlined,
  colorVariant = CardVariant.default
}: Props) => {
  return (
    <S.Chip size={size} variant={variant} colorVariant={colorVariant}>
      {label}
      {onDelete ? (
        <S.XIcon onClick={onDelete} size={16} color={grey.dark} tabIndex={0} />
      ) : null}
    </S.Chip>
  );
};

export default Chip;
