import { styled } from "@linaria/react";

import { ReactComponent as Logo } from "img/icons/xapien-logo-icon.svg";

import { blue } from "styles/colors";

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const XapienLogo = styled(Logo)`
  width: 3rem;
  height: 3rem;
  path {
    fill: ${blue.xapienLight};
  }
`;

const TitleContainer = styled.div`
  margin-top: 1.5rem;
`;

const S = {
  Header,
  XapienLogo,
  TitleContainer
};

export default S;
