/* eslint-disable no-unsafe-optional-chaining */
import React, { Fragment, useState, useRef, useMemo, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Resizable } from "re-resizable";
import { observer } from "mobx-react";

import ErrorBoundary from "util/ErrorBoundary";
import ImageGallery from "components/molecules/ImageGallery";
import Datalist from "components/atoms/Datalist";
import { formatForDisplay } from "components/atoms/Datalist/util";
import { Section } from "pages/report/Section";
import SectionErrorContents from "pages/report/Error";
import {
  sanitiseDisplayData,
  formatDate,
  scrollToTopOfComponent,
  mutateToLowerCamelCase
} from "util/common";
import { doesTenantProhibitImages } from "util/doesTenantProhibitImages";
import WithInspector from "components/organisms/WithInspector";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import { getAgeFromDob } from "util/getAgeFromDob";
import TruncateLength from "util/TruncateLength";
import InfoGraphic from "components/molecules/InfoGraphic";
import InfoIcon from "components/atoms/InfoIcon";
import { transformWebAndMediaSourcesForInspector } from "components/organisms/WithInspector/util";
import exactNameSegmentAssertionTemplate from "util/Assertable/util/exactNameSegmentAssertionTemplate";
import exactNationalityAssertionTemplate from "util/Assertable/util/exactNationalityAssertionTemplate";
import exactDateOfBirthAssertionTemplate from "util/Assertable/util/exactDateOfBirthAssertionTemplate";
import blankPlaceholderTemplate from "util/Assertable/util/blankPlaceholderTemplate";
import { personReportSectionTitles } from "util/reportSectionTitles";
import { ButtonKind } from "components/atoms/Button/types";
import Pages, { DIRECTION } from "components/molecules/Pages";
import BackButton from "components/atoms/BackButton";
import OrgInfoSummaryRow from "components/molecules/OrgInfoSummaryRow";
import InfoSummaryRow from "components/molecules/InfoSummaryRow";
import LocationRiskTag from "components/atoms/LocationRiskTag";
import { InfographicStateContext } from "util/context/InfographicStateContext";
import ReactTooltip from "components/atoms/ReactTooltip";
import { REPORT_TYPES } from "util/reportTypes";
import { useReports } from "util/hooks/useReports";
import { showInsightReport } from "util/insightReportHelpers";
import { usePrintModeEnabled } from "util/hooks/useIsPrintModeEnabled";
import { grey, green } from "styles/colors";
import useRiskSummaries from "util/hooks/useRiskSummaries";
import RiskSummaryInfoGraphic from "components/molecules/RiskSummaryInfoGraphic";
import { isDevel } from "services/stage";

import theme from "theme";
import ThemedScreeningInfographics from "ThemedScreeningInfographics";

import { useInsightsEnabled } from "util/hooks/useInsightsEnabled";
import { RiskSummaryTopic } from "api/insights";
import ContactInformation from "./ContactInformation";

import S, { classNameOverrides } from "./styles";

const animateProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 }
};

const TextSummaries = ({
  summary,
  experimentalSummaries,
  getSourcesFromIdsMap
}) => {
  const renderSummary = () => {
    if (!summary) {
      return null;
    }
    if (typeof summary === "string") {
      return <>Error generating summary: {summary}</>;
    }
    const { summaryStatements, warning } = summary;
    const warningMessage = warning && `Summary warning: ${warning}`; // dirty - should make a neat message when productionised
    if (!summaryStatements || !summaryStatements.length) {
      return <>{warningMessage}</>;
    }
    return (
      <>
        {warningMessage && <div>{warningMessage}</div>}
        {summaryStatements.flatMap(s => (
          <>
            <WithInspector
              display="inline"
              sources={getSourcesFromIdsMap(s.sourceIds, s.sourceSnippets)}
            >
              {s.text}
            </WithInspector>{" "}
          </>
        ))}
      </>
    );
  };

  const renderExperimentalSummaries = () => {
    if (!experimentalSummaries || !experimentalSummaries.length) {
      return null;
    }
    const renderExperimentalSummary = experimentalSummary => {
      const { title, textSummary } = experimentalSummary;
      if (!textSummary) {
        return null;
      }
      if (typeof textSummary === "string") {
        return (
          <>
            <h3>{title}</h3>Error generating summary: {experimentalSummary}
          </>
        );
      }
      const { summaryStatements, warning } = textSummary;
      const warningMessage = warning && `Summary warning: ${warning}`; // dirty - should make a neat message when productionised
      if (!summaryStatements || !summaryStatements.length) {
        return (
          <>
            <h3>{title}</h3>
            {warningMessage}
          </>
        );
      }
      return (
        <>
          <h3>{title}</h3>
          {warningMessage && <div>{warningMessage}</div>}
          {summaryStatements.flatMap(s => (
            <>
              <WithInspector
                display="inline"
                sources={getSourcesFromIdsMap(s.sourceIds, s.sourceSnippets)}
              >
                {s.text}
              </WithInspector>{" "}
            </>
          ))}
        </>
      );
    };

    return (
      <div>
        {experimentalSummaries.map(experimentalSummary => {
          return (
            <Fragment key={`ExperimentalSummary-${experimentalSummary.title}`}>
              {renderExperimentalSummary(experimentalSummary)}
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <S.DescriptionContainer>
      <div>Xapien&apos;s Generated Summary</div>
      <div>{renderSummary()}</div>
      {renderExperimentalSummaries()}
    </S.DescriptionContainer>
  );
};

const PersonOverview = React.forwardRef(
  (
    {
      title,
      reportAbstract,
      professionalRolesMap,
      sectionRefs,
      displaySubjectImage = true,
      infographicLayoutTemplate
    },
    ref
  ) => {
    const {
      dateOfBirth,
      imagesOfPerson,
      titles,
      firstName,
      lastName,
      middleNames,
      nameSuffix,
      onlineProfilesWithUncertainty,
      biography,
      netWorth,
      placeOfBirth,
      nationality,
      awards,
      gender,
      phoneNumbers,
      emailAddresses,
      significantWebsites,
      screeningSummary,
      socialMediaReach,
      relatedCountries,
      confirmedOfficershipIds,
      isDeceased,
      sourceMapping,
      textualSummaries
    } = reportAbstract;

    const reportStore = useReports();
    const isPrintMode = usePrintModeEnabled();

    const { report } = reportStore;
    const isShowingInsightReport = report
      ? showInsightReport(report.preparedAtUtc, isPrintMode)
      : false;

    const { riskData, webAndMediaData } = reportStore;

    const webAndMediaSources = useMemo(() => {
      const sources = {};
      webAndMediaData.forEach(source => {
        sources[source.sourceId] = source;
      });
      return sources;
    }, [webAndMediaData]);

    const formattedDateOfBirth = formatDate(dateOfBirth);
    const ageToDisplay = dateOfBirth?.year
      ? `${getAgeFromDob(
          new Date(dateOfBirth?.year, dateOfBirth?.month - 1, dateOfBirth?.day)
        )} yrs`
      : null;

    const [
      officershipsInspectorCurrentPage,
      setOfficershipsInspectorCurrentPage
    ] = useState(0);

    const [selectedOfficershipData, setSelectedOfficershipData] = useState();

    const [relatedCountriesCurrentPage, setRelatedCountriesCurrentPage] =
      useState(0);

    const [selectedRelatedCountryData, setSelectedRelatedCountryData] =
      useState();

    const [selectedRelatedCountryOrgData, setSelectedRelatedCountryOrgData] =
      useState();

    const [isScaleIconInspectorOpen, setIsScaleIconInspectorOpen] =
      useState(false);

    const pagesCompRef = useRef();
    const relatedCountriesPagesRef = useRef();

    const finCrimeData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.finCrime?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.finCrime?.directRiskCategories, webAndMediaSources]
    );

    const esgData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.eSG?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.eSG?.directRiskCategories, webAndMediaSources]
    );

    const proceedingsData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.proceedings?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.proceedings?.directRiskCategories, webAndMediaSources]
    );

    const otherFlagsData = useMemo(
      () =>
        transformWebAndMediaSourcesForInspector(
          riskData?.otherFlags?.directRiskCategories,
          webAndMediaSources
        ),
      [riskData?.otherFlags?.directRiskCategories, webAndMediaSources]
    );

    const { setDisplayAdditionalLabel, isAnyInfographicInspectorOpen } =
      useContext(InfographicStateContext);

    const { hasRiskSummaries, getSummary } = useRiskSummaries();

    const setterPropsForDisplayingAdditionalLabel = {
      onMouseOver: () => {
        setDisplayAdditionalLabel(true);
      },
      onMouseOut: () => {
        if (!isAnyInfographicInspectorOpen) {
          setDisplayAdditionalLabel(false);
        }
      }
    };

    const getSourcesFromIdsMap = (ids, sourceSnippets) => {
      const sourceSnippetsMap =
        sourceSnippets && new Map(Object.entries(sourceSnippets));
      const sourcesFromMap = ids
        ?.map(
          id =>
            mutateToLowerCamelCase(sourceMapping[id]) ||
            (sourceSnippets &&
              sourceSnippetsMap.has(id) && {
                title: "Error - Unknown source",
                id
              })
        )
        ?.filter(Boolean);

      if (sourceSnippets) {
        if (sourceSnippetsMap.size) {
          return sourcesFromMap.map(source => {
            const sourceId = source.id;
            const snippets = sourceSnippetsMap.get(sourceId);
            if (snippets) {
              return { ...source, snippets };
            }
            console.warn("Missing snippet for source", { source });
            return source;
          });
        }
      }

      return sourcesFromMap;
    };

    const renderNameField = ({
      namesArray,
      assertionTemplate,
      arrayedValues = false,
      shouldPipeSeparate,
      nameType
    }) => {
      return (
        <WithInspector
          dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
          popoverTitle={nameType}
          filterableSources={namesArray.reduce((acc, name) => {
            // Backwards compat with fields containing the "values" property
            // eslint-disable-next-line no-prototype-builtins
            const names = name?.hasOwnProperty("values") ? name.values : name;
            if (Array.isArray(names)) {
              names.forEach(obj => {
                acc.push({
                  topSectionElement: obj.text,
                  sources: getSourcesFromIdsMap(obj.sourceIds)
                });
              });
            } else {
              acc.push({
                topSectionElement: names.text,
                sources: getSourcesFromIdsMap(names.sourceIds)
              });
            }
            return acc;
          }, [])}
        >
          <TruncateLength>
            {formatForDisplay(
              namesArray,
              assertionTemplate,
              arrayedValues,
              shouldPipeSeparate
            )}
          </TruncateLength>
        </WithInspector>
      );
    };

    const renderScreeningRiskIcons = () => {
      return (
        <ThemedScreeningInfographics
          screeningData={screeningSummary}
          reportSectionRefs={sectionRefs}
          reportType={REPORT_TYPES.person}
        />
      );
    };

    const renderRiskIcons = () => {
      // We only want to render risk icons for Person reports when the report
      // has risk summaries and only if we're in dev.
      if (hasRiskSummaries && isDevel) {
        return (
          <>
            <RiskSummaryInfoGraphic
              topic={RiskSummaryTopic.FINANCIAL_CRIME}
              summary={getSummary(RiskSummaryTopic.FINANCIAL_CRIME)?.summary}
            />
            <RiskSummaryInfoGraphic
              topic={RiskSummaryTopic.ESG}
              summary={getSummary(RiskSummaryTopic.ESG)?.summary}
            />
            <RiskSummaryInfoGraphic
              topic={RiskSummaryTopic.PROCEEDINGS}
              summary={getSummary(RiskSummaryTopic.PROCEEDINGS)?.summary}
            />
            <RiskSummaryInfoGraphic
              topic={RiskSummaryTopic.OTHER_FLAGS}
              summary={getSummary(RiskSummaryTopic.OTHER_FLAGS)?.summary}
            />
          </>
        );
      }

      const indirectFinCrimeCount =
        riskData?.finCrime?.indirectRiskCategories?.length ?? 0;
      const indirectESGCount =
        riskData?.eSG?.indirectRiskCategories?.length ?? 0;
      const indirectProceedingsCount =
        riskData?.proceedings?.indirectRiskCategories?.length ?? 0;
      const indirectOtherFlagsCount =
        riskData?.otherFlags?.indirectRiskCategories?.length ?? 0;

      const directFinCrimeCount = finCrimeData?.filter(
        data => data.sources?.length
      )?.length;
      const directEsgCount = esgData?.filter(
        data => data.sources?.length
      )?.length;
      const directProceedingsCount = proceedingsData?.filter(
        data => data.sources?.length
      )?.length;
      const directOtherFlagsCount = otherFlagsData?.filter(
        data => data.sources?.length
      )?.length;

      const directFinCrimeCountExplainer = (
        <span>
          <strong>{directFinCrimeCount} directly</strong> linked{" "}
          <strong>{directFinCrimeCount !== 1 ? "risks" : "risk"}</strong>{" "}
          relating to potential <strong>financial crimes</strong>.
        </span>
      );

      const directEsgCountExplainer = (
        <span>
          <strong>{directEsgCount} directly</strong> linked{" "}
          <strong>{directEsgCount !== 1 ? "risks" : "risk"}</strong> relating to{" "}
          <strong>ESG (Environmental, Social and Governance)</strong>.
        </span>
      );

      const directProceedingsCountExplainer = (
        <span>
          <strong>{directProceedingsCount} directly</strong> linked{" "}
          <strong>{directProceedingsCount !== 1 ? "risks" : "risk"}</strong>{" "}
          relating to <strong>accusations, legal proceedings</strong>, and{" "}
          <strong>convictions</strong>.
        </span>
      );

      const directOtherFlagsCountExplainer = (
        <span>
          <strong>{directOtherFlagsCount} directly</strong> linked{" "}
          <strong>{directOtherFlagsCount !== 1 ? "risks" : "risk"}</strong>{" "}
          relating to <strong>controversy</strong> or{" "}
          <strong>potential criminal activity</strong>.
        </span>
      );

      return (
        <>
          <InfoGraphic
            RiskIcon={S.FinCrimeRiskFlagIcon}
            riskCount={directFinCrimeCount}
            title="Financial crime"
            inspectorData={finCrimeData}
            inspectorHeader={`${directFinCrimeCount} financial crime ${
              directFinCrimeCount === 1 ? "category" : "categories"
            }`}
            inspectorSubtext={directFinCrimeCountExplainer}
            tooltipExplainerText={
              <div>
                {directFinCrimeCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectFinCrimeCount} indirect</strong>{" "}
                  <strong>
                    {indirectFinCrimeCount !== 1 ? "risks" : "risk"}
                  </strong>{" "}
                  that {indirectFinCrimeCount !== 1 ? "are" : "is"} not directly
                  connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectFinCrimeCount} indirect</strong>{" "}
                <strong>
                  {indirectFinCrimeCount !== 1 ? "risks" : "risk"}
                </strong>{" "}
                that {indirectFinCrimeCount !== 1 ? "are" : "is"} not directly
                connected to your subject.
              </div>
            }
            sectionToJumpToRef={
              sectionRefs &&
              sectionRefs[personReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectFinCrimeCount ?? 0} indirect`}
          />
          <InfoGraphic
            RiskIcon={S.ESGIcon}
            riskCount={directEsgCount}
            title="ESG"
            inspectorData={esgData}
            inspectorHeader={`${directEsgCount} ESG risk ${
              directEsgCount === 1 ? "category" : "categories"
            }`}
            inspectorSubtext={directEsgCountExplainer}
            tooltipExplainerText={
              <div>
                {directEsgCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectESGCount} indirect</strong>{" "}
                  <strong>{indirectESGCount !== 1 ? "risks" : "risk"}</strong>{" "}
                  that {indirectESGCount !== 1 ? "are" : "is"} not directly
                  connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectESGCount} indirect</strong>{" "}
                <strong>{indirectESGCount !== 1 ? "risks" : "risk"}</strong>{" "}
                that {indirectESGCount !== 1 ? "are" : "is"} not directly
                connected to your subject.
              </div>
            }
            sectionToJumpToRef={
              sectionRefs &&
              sectionRefs[personReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectESGCount ?? 0} indirect`}
          />
          <InfoGraphic
            RiskIcon={S.MajorCrimesIcon}
            riskCount={directProceedingsCount}
            title="Proceedings"
            inspectorData={proceedingsData}
            inspectorHeader={`${directProceedingsCount} proceedings ${
              directProceedingsCount === 1 ? "category" : "categories"
            }`}
            inspectorSubtext={directProceedingsCountExplainer}
            tooltipExplainerText={
              <div>
                {directProceedingsCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectProceedingsCount} indirect</strong>{" "}
                  <strong>
                    {indirectProceedingsCount !== 1 ? "risks" : "risk"}
                  </strong>{" "}
                  that {indirectProceedingsCount !== 1 ? "are" : "is"} not
                  directly connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectProceedingsCount} indirect</strong>{" "}
                <strong>
                  {indirectProceedingsCount !== 1 ? "risks" : "risk"}
                </strong>{" "}
                that {indirectProceedingsCount !== 1 ? "are" : "is"} not
                directly connected to your subject.
              </div>
            }
            sectionToJumpToRef={
              sectionRefs &&
              sectionRefs[personReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectProceedingsCount ?? 0} indirect`}
          />
          <InfoGraphic
            RiskIcon={S.RiskFlagIcon}
            riskCount={directOtherFlagsCount}
            title="Other flags"
            inspectorSubtext={directOtherFlagsCountExplainer}
            tooltipExplainerText={
              <div>
                {directOtherFlagsCountExplainer}
                <S.IndirectExplainer>
                  <strong>{indirectOtherFlagsCount} indirect</strong>{" "}
                  <strong>
                    {indirectOtherFlagsCount !== 1 ? "risks" : "risk"}
                  </strong>{" "}
                  that {indirectOtherFlagsCount !== 1 ? "are" : "is"} not
                  directly connected to your subject.
                </S.IndirectExplainer>
              </div>
            }
            explainerText={
              <div>
                <strong>{indirectOtherFlagsCount} indirect</strong>{" "}
                <strong>
                  {indirectOtherFlagsCount !== 1 ? "risks" : "risk"}
                </strong>{" "}
                that {indirectOtherFlagsCount !== 1 ? "are" : "is"} not directly
                connected to your subject.
              </div>
            }
            inspectorData={otherFlagsData}
            inspectorHeader={`${directOtherFlagsCount} other flag ${
              directOtherFlagsCount === 1 ? "category" : "categories"
            }`}
            sectionToJumpToRef={
              sectionRefs &&
              sectionRefs[personReportSectionTitles.WEB_AND_MEDIA]
            }
            additionalLabel={`+${indirectOtherFlagsCount ?? 0} indirect`}
          />
        </>
      );
    };

    const renderPersonImage = () => {
      return (
        <ImageGallery
          images={imagesOfPerson}
          blockImages={doesTenantProhibitImages()}
          imageHeight={hasRiskSummaries ? 150 : undefined}
          imageWidth={hasRiskSummaries ? 128 : undefined}
        />
      );
    };

    const renderDetails1 = () => {
      return (
        <Datalist
          termClassName={classNameOverrides.termClassName}
          detailClassName={classNameOverrides.detailClassName}
          shouldDataValuesWrap
          fieldList={[
            {
              title: "Title",
              value: titles?.length ? (
                <WithInspector
                  dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
                  popoverTitle="Title"
                  filterableSources={titles.map(({ text, sourceIds }) => {
                    return {
                      topSectionElement: text,
                      sources: getSourcesFromIdsMap(sourceIds)
                    };
                  })}
                >
                  <TruncateLength>
                    {formatForDisplay(
                      titles,
                      value =>
                        exactNameSegmentAssertionTemplate(value, "Titles"),
                      true
                    )}
                  </TruncateLength>
                </WithInspector>
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              ),
              length: 99,
              assertionTemplate: value =>
                exactNameSegmentAssertionTemplate(value, "Titles"),
              doesStringValueContainMultipleAssertableValues: true
            },
            {
              title: "First name",
              value: firstName?.length ? (
                renderNameField({
                  namesArray: firstName,
                  assertionTemplate: value =>
                    exactNameSegmentAssertionTemplate(value, "FirstName"),
                  arrayedValues: true,
                  shouldPipeSeparate: true,
                  nameType: "First name"
                })
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              ),
              length: 99,
              assertionTemplate: value =>
                exactNameSegmentAssertionTemplate(value, "FirstName"),
              doesStringValueContainMultipleAssertableValues: true
            },
            {
              title: "Middle names",
              value: middleNames?.length ? (
                renderNameField({
                  namesArray: middleNames,
                  assertionTemplate: value =>
                    exactNameSegmentAssertionTemplate(value, "MiddleNames"),
                  arrayedValues: true,
                  shouldPipeSeparate: false,
                  nameType: "Middle names"
                })
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              ),
              length: 99,
              assertionTemplate: value =>
                exactNameSegmentAssertionTemplate(value, "MiddleNames"),
              doesStringValueContainMultipleAssertableValues: true
            },
            {
              title: "Surname",
              value: lastName?.length ? (
                renderNameField({
                  namesArray: lastName,
                  assertionTemplate: value =>
                    exactNameSegmentAssertionTemplate(value, "LastName"),
                  arrayedValues: true,
                  shouldPipeSeparate: true,
                  nameType: "Surname"
                })
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              ),
              length: 99,
              assertionTemplate: value =>
                exactNameSegmentAssertionTemplate(value, "LastName"),
              doesStringValueContainMultipleAssertableValues: true
            },
            {
              title: "Post-nominal letters",
              value: nameSuffix?.length ? (
                <WithInspector
                  dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
                  popoverTitle="Post-nominal letters"
                  filterableSources={nameSuffix.map(suffix => {
                    return {
                      topSectionElement: suffix.text,
                      sources: getSourcesFromIdsMap(suffix.sourceIds)
                    };
                  })}
                >
                  <TruncateLength>
                    {formatForDisplay(
                      nameSuffix,
                      value =>
                        exactNameSegmentAssertionTemplate(value, "NameSuffix"),
                      true
                    )}
                  </TruncateLength>
                </WithInspector>
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              ),
              length: 99,
              assertionTemplate: value =>
                exactNameSegmentAssertionTemplate(value, "NameSuffix"),
              doesStringValueContainMultipleAssertableValues: true
            },
            {
              title: "Gender",
              value: gender ? (
                <WithInspector
                  sources={getSourcesFromIdsMap(gender.sourceIds)}
                  popoverTitle="Gender"
                  display="inline-flex"
                >
                  {formatForDisplay(gender.text ?? gender)}
                </WithInspector>
              ) : null,
              length: 99,
              assertionTemplate: blankPlaceholderTemplate()
            }
          ]}
        />
      );
    };

    const renderDetails2 = () => {
      return (
        <Datalist
          termClassName={classNameOverrides.termClassName}
          detailClassName={classNameOverrides.detailClassName}
          shouldDataValuesWrap
          fieldList={[
            {
              title: "Born",
              value: formattedDateOfBirth ? (
                <WithInspector
                  sources={getSourcesFromIdsMap(dateOfBirth.sourceIds)}
                  popoverTitle="Born"
                >
                  {formattedDateOfBirth}{" "}
                  {!isDeceased ? `(${ageToDisplay})` : ""}
                </WithInspector>
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              ),
              length: 99,
              assertionTemplate: exactDateOfBirthAssertionTemplate(dateOfBirth)
            },
            {
              title: "Nationality",
              value: nationality?.length ? (
                <WithInspector
                  dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
                  popoverTitle="Nationality"
                  filterableSources={nationality.map(country => {
                    return {
                      topSectionElement: country.text,
                      sources: getSourcesFromIdsMap(country.sourceIds)
                    };
                  })}
                >
                  <TruncateLength>
                    {formatForDisplay(
                      nationality,
                      value => exactNationalityAssertionTemplate(value),
                      true
                    )}
                  </TruncateLength>
                </WithInspector>
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              ),
              assertionTemplate: value =>
                exactNationalityAssertionTemplate(value),
              doesStringValueContainMultipleAssertableValues: true
            },
            {
              title: "Place of birth",
              value: placeOfBirth ? (
                <WithInspector
                  sources={getSourcesFromIdsMap(placeOfBirth.sourceIds)}
                  popoverTitle="Place of birth"
                >
                  <TruncateLength>
                    {formatForDisplay(placeOfBirth.text ?? placeOfBirth)}
                  </TruncateLength>
                </WithInspector>
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              )
            },
            {
              title: "Awards",
              value: awards?.length ? (
                <WithInspector
                  dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
                  popoverTitle="Awards"
                  filterableSources={awards.map(award => {
                    return {
                      topSectionElement: award.text,
                      sources: getSourcesFromIdsMap(award.sourceIds)
                    };
                  })}
                >
                  <TruncateLength>{formatForDisplay(awards)}</TruncateLength>
                </WithInspector>
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              )
            },
            {
              title: "Net worth",
              value: netWorth ? (
                <WithInspector
                  sources={getSourcesFromIdsMap(netWorth.sourceIds)}
                  popoverTitle="Net worth"
                >
                  {formatForDisplay(netWorth.text ?? netWorth)}
                </WithInspector>
              ) : (
                <S.NoneIdentified>None identified</S.NoneIdentified>
              )
            }
          ]}
        />
      );
    };

    const renderOnlinePresence = () => {
      const processSources = data => {
        return data?.map(item => {
          return {
            ...item,
            sources: getSourcesFromIdsMap(item.sourceIds)
          };
        });
      };

      const formattedOnlineProfiles = onlineProfilesWithUncertainty?.map(
        item => {
          // Backwards compat
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty("data")) {
            return {
              ...item,
              data: {
                ...item.data,
                sources: getSourcesFromIdsMap(item.sourceIds)
              }
            };
          }
          return {
            data: {
              ...item,
              sources: getSourcesFromIdsMap(item.sourceIds)
            }
          };
        }
      );

      return (
        <ContactInformation
          onlineProfilesWithUncertainty={formattedOnlineProfiles}
          significantWebsites={processSources(significantWebsites)}
          emailAddresses={processSources(emailAddresses)}
          phoneNumbers={processSources(phoneNumbers)}
        />
      );
    };

    const buildStatusTags = officership => {
      const statusTags = [];

      if (officership?.insolvencyFilings?.length) {
        statusTags.push("Insolvency proceedings");
      }

      officership?.listOfOrgWarnings?.forEach(warning => {
        statusTags.push(warning.label);
      });

      return statusTags;
    };

    const renderOfficershipsInspectorContent = () => {
      // Build officerships array
      const officerships = confirmedOfficershipIds?.map(id => {
        return professionalRolesMap[id];
      });

      // Bring orgs that have risk to the top
      officerships?.sort(a => {
        if (
          a.orgStatus?.statusTag === "Warning" ||
          a.listOfOrgWarnings?.length ||
          a.insolvencyFilings?.length
        ) {
          return -1;
        }
        return 0;
      });

      return (
        <Resizable
          handleStyles={{ bottom: { bottom: -25 } }}
          bounds="window"
          minHeight={275}
          defaultSize={{ height: 240 }}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >
          <Pages
            ref={pagesCompRef}
            onPageChange={currentPage =>
              setOfficershipsInspectorCurrentPage(currentPage)
            }
            pages={[
              <S.Officerships>
                {officerships?.map(officership => {
                  return (
                    <OrgInfoSummaryRow
                      key={officership.id}
                      orgName={officership?.orgName}
                      previousNames={officership?.previousOrgNames}
                      industry={officership?.industryCategories[0]}
                      companyStatus={officership?.orgStatus}
                      sources={officership?.roleSources}
                      reasonsForRisk={buildStatusTags(officership)}
                      companyLocation={officership?.countryInfo}
                      addressFullDisplay={officership?.addressFullDisplay}
                      addressShortDisplay={officership?.addressShortDisplay}
                      listOfRoles={officership?.listOfRoles}
                      roleHolderName={officership?.roleHolderName}
                      onClick={() => {
                        setSelectedOfficershipData(officership);
                        pagesCompRef.current?.switchPageByDirection(
                          DIRECTION.forward
                        );
                      }}
                    />
                  );
                })}
              </S.Officerships>,
              <S.CustomSourceList
                sources={selectedOfficershipData?.roleSources}
                hideSourceCount
              />
            ]}
          />
        </Resizable>
      );
    };

    const renderOfficershipsInfoGraphic = () => {
      const numOfficerships = confirmedOfficershipIds?.length;
      const officershipsWithRisk = confirmedOfficershipIds?.filter(
        officershipId => {
          const officership = professionalRolesMap[officershipId];
          return (
            officership?.orgStatus?.statusTag === "Warning" ||
            officership.insolvencyFilings?.length ||
            officership.listOfOrgWarnings?.length
          );
        }
      );
      const numOfficershipsWithRisk = officershipsWithRisk?.length;

      const formattedNumOfficershipsWithRisk =
        numOfficershipsWithRisk > 99 ? "99+" : numOfficershipsWithRisk;

      const officershipsRoleSourcesLength = [
        ...new Set(
          selectedOfficershipData?.roleSources?.map(source => source.id)
        )
      ]?.length;

      return (
        <WithInspector
          isInspectorOpen={value => {
            setIsScaleIconInspectorOpen(value);
            if (!value) setOfficershipsInspectorCurrentPage(0);
          }}
          dataFormat={INSPECTOR_DATA_FORMATS.custom}
          customContent={renderOfficershipsInspectorContent()}
          popoverTitle={
            <AnimatePresence initial={false} exitBeforeEnter>
              {
                [
                  <motion.div {...animateProps} key="officership summaries">
                    {numOfficerships} officership{" "}
                    {numOfficerships > 1 ? "summaries" : "summary"}
                  </motion.div>,
                  <motion.div {...animateProps} key="sourcing">
                    Sourcing
                  </motion.div>
                ][officershipsInspectorCurrentPage]
              }
            </AnimatePresence>
          }
          disabled={!numOfficerships}
          popoverSubtext={
            <AnimatePresence initial={false} exitBeforeEnter>
              {
                [
                  <motion.div {...animateProps} key="officership summaries">
                    We have identified {numOfficerships}{" "}
                    {numOfficerships > 1 ? "officerships" : "officership"} for
                    your subject.{" "}
                    <S.SectionLinkText>
                      For full details, go to the{" "}
                      <S.SectionLinkButton
                        kind={ButtonKind.tertiary}
                        onClick={() =>
                          scrollToTopOfComponent(
                            sectionRefs &&
                              sectionRefs[
                                personReportSectionTitles.PROFESSIONAL_OVERVIEW
                              ],
                            true
                          )
                        }
                      >
                        Professional Overview section
                      </S.SectionLinkButton>
                      .{" "}
                    </S.SectionLinkText>
                  </motion.div>,
                  <BackButton
                    animationProps={animateProps}
                    onClick={() =>
                      pagesCompRef.current.switchPageByDirection(
                        DIRECTION.backward
                      )
                    }
                    label={
                      <>
                        <b>
                          Showing {officershipsRoleSourcesLength}{" "}
                          {officershipsRoleSourcesLength > 1
                            ? "sources"
                            : "source"}{" "}
                        </b>
                        for roles held at {selectedOfficershipData?.orgName}
                      </>
                    }
                  />
                ][officershipsInspectorCurrentPage]
              }
            </AnimatePresence>
          }
        >
          <ReactTooltip
            disabled={isScaleIconInspectorOpen}
            delay={600}
            maxWidth="300px"
            tooltip={
              <div>Number of officer roles held, e.g. director or manager</div>
            }
          >
            <S.ScaleItem>
              <S.ScaleIconContainer aria-label="Open officerships summary">
                <S.DirectorshipsIcon
                  color={
                    numOfficerships
                      ? theme.primaryColor ?? green.xapienGreen
                      : grey.ghost
                  }
                />
                {numOfficershipsWithRisk > 0 && (
                  <S.RiskCountIcon>
                    {formattedNumOfficershipsWithRisk}
                  </S.RiskCountIcon>
                )}
              </S.ScaleIconContainer>
              <S.ScaleDetail color={numOfficerships ? "inherit" : grey.mid}>
                <span>{numOfficerships >= 0 ? numOfficerships : "-"}</span>
                Officerships
              </S.ScaleDetail>
            </S.ScaleItem>
          </ReactTooltip>
        </WithInspector>
      );
    };

    const renderSocialReachInfoGraphic = () => {
      const isCountPresent = socialMediaReach?.level?.length > 0;

      const socialReachExplainer = (
        <>
          {!isCountPresent && (
            <S.NoSocialPresenceLabel>
              No social presence identified.
            </S.NoSocialPresenceLabel>
          )}
          Ranked by the volume of the person&apos;s social media followers:{" "}
          <br />
          <S.SocialReachTooltipList>
            <li>Low &lt;1k</li>
            <li>Medium 1k–5k</li>
            <li>High &gt;5k</li>
          </S.SocialReachTooltipList>
        </>
      );

      const getSocialReachElements = () => {
        return (
          <>
            <S.SocialReachIcon
              color={
                isCountPresent
                  ? theme.primaryColor ?? green.xapienGreen
                  : grey.ghost
              }
            />
            <S.ScaleDetail color={grey.mid}>
              <span>{socialMediaReach?.level ?? "-"}</span>
              Social reach
            </S.ScaleDetail>
          </>
        );
      };

      return (
        <WithInspector
          isInspectorOpen={value => setIsScaleIconInspectorOpen(value)}
          disabled={!socialMediaReach?.level?.length}
          sources={getSourcesFromIdsMap(socialMediaReach?.sourceIds)}
          topSectionElement={
            <S.SmallSocialReachIconInfographic>
              {getSocialReachElements(false)}
            </S.SmallSocialReachIconInfographic>
          }
          popoverSubtext={socialReachExplainer}
        >
          <ReactTooltip
            disabled={isScaleIconInspectorOpen}
            tooltip={socialReachExplainer}
            maxWidth="300px"
            delay={600}
          >
            <S.ScaleItem>{getSocialReachElements()}</S.ScaleItem>
          </ReactTooltip>
        </WithInspector>
      );
    };

    const renderRelatedCountriesInspectorContent = () => {
      if (!relatedCountries?.length) {
        return null;
      }
      // Bring locations that have risk to the top
      // Spreading to a new array to prevent 2 adjacent cards with existing
      // risk from swapping everytime a rerender occurs.
      const sortedRelatedCountries = [...relatedCountries]?.sort(a => {
        if (a.riskFlags?.length) {
          return -1;
        }
        return 0;
      });

      return (
        <Resizable
          handleStyles={{ bottom: { bottom: -25 } }}
          bounds="window"
          minHeight={275}
          defaultSize={{ height: 240 }}
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >
          <Pages
            ref={relatedCountriesPagesRef}
            onPageChange={currentPage =>
              setRelatedCountriesCurrentPage(currentPage)
            }
            pages={[
              <>
                {sortedRelatedCountries?.map(country => {
                  // Referencing professional overview entries
                  const groupedCompanies = country.referenceIds;
                  return (
                    <InfoSummaryRow
                      key={country.countryName}
                      onClick={() => {
                        setSelectedRelatedCountryData(country);
                        relatedCountriesPagesRef.current?.switchPageByDirection(
                          DIRECTION.forward
                        );
                      }}
                      interactive={groupedCompanies?.length}
                      icon={
                        <S.CountryFlag
                          code={country.countryCode}
                          showCodeString={false}
                        />
                      }
                      heading={
                        <S.RelatedCountryHeader>
                          {country.countryName}{" "}
                        </S.RelatedCountryHeader>
                      }
                      subheading={
                        groupedCompanies?.length > 0 && (
                          <>
                            {groupedCompanies?.length}{" "}
                            {groupedCompanies?.length > 1
                              ? "companies"
                              : "company"}
                          </>
                        )
                      }
                      content={
                        <div>
                          {country.riskFlags?.length ? (
                            <S.RiskAssigners>
                              {country.riskFlags?.map(risk => (
                                <LocationRiskTag
                                  key={risk.assigner}
                                  riskAssigner={risk.assigner}
                                  className={classNameOverrides.locationRiskTag}
                                />
                              ))}
                            </S.RiskAssigners>
                          ) : null}
                          {country.referenceIds?.length > 0 && (
                            <S.List>
                              {country.referenceIds?.map(id => {
                                const org = professionalRolesMap[id];
                                return (
                                  <S.ListItem key={org.id}>
                                    <b>{org.orgName}</b>
                                    <S.CompanyLocation>
                                      {org.addressShortDisplay}
                                    </S.CompanyLocation>
                                  </S.ListItem>
                                );
                              })}
                            </S.List>
                          )}
                        </div>
                      }
                    />
                  );
                })}
              </>,
              <div>
                {selectedRelatedCountryData?.referenceIds?.map(id => {
                  const org = professionalRolesMap[id];
                  return (
                    <OrgInfoSummaryRow
                      key={org.id}
                      onClick={() => {
                        setSelectedRelatedCountryOrgData(org);
                        relatedCountriesPagesRef.current?.switchPageByDirection(
                          DIRECTION.forward
                        );
                      }}
                      orgName={org.orgName}
                      previousNames={org.previousOrgNames}
                      industry={org.industryCategories[0]}
                      companyStatus={org.orgStatus}
                      sources={org.roleSources}
                      reasonsForRisk={buildStatusTags(org)}
                      companyLocation={org.countryInfo}
                      addressFullDisplay={org.addressFullDisplay}
                      addressShortDisplay={org.addressShortDisplay}
                      listOfRoles={org?.listOfRoles}
                      roleHolderName={org?.roleHolderName}
                    />
                  );
                })}
              </div>,
              <S.CustomSourceList
                sources={selectedRelatedCountryOrgData?.roleSources}
                hideSourceCount
              />
            ]}
          />
        </Resizable>
      );
    };

    const renderCountriesInfoGraphic = () => {
      const countriesLength = relatedCountries?.length;
      const relatedCountriesWithRisk = relatedCountries?.filter(
        relatedCountry => {
          return relatedCountry.riskFlags?.length > 0;
        }
      );
      const numRelatedCountriesWithRisk = relatedCountriesWithRisk?.length;

      const formattedNumCountriesWithRisk =
        numRelatedCountriesWithRisk > 99 ? "99+" : numRelatedCountriesWithRisk;

      const countriesString = countriesLength > 1 ? "countries" : "country";

      const relatedCountriesOrgSourcesLength = [
        ...new Set(
          selectedRelatedCountryOrgData?.roleSources?.map(source => source.id)
        )
      ]?.length;

      return (
        <WithInspector
          isInspectorOpen={value => {
            setIsScaleIconInspectorOpen(value);
            if (!value) setRelatedCountriesCurrentPage(0);
          }}
          popoverTitle={
            <AnimatePresence initial={false} exitBeforeEnter>
              {
                [
                  <motion.div
                    {...animateProps}
                    key="related countries summaries"
                  >
                    {countriesLength} related{" "}
                    {countriesLength > 1 ? "countries" : "country"}
                  </motion.div>,
                  <motion.div {...animateProps} key="country companies">
                    {selectedRelatedCountryData?.countryName}
                  </motion.div>,
                  <motion.div {...animateProps} key="company sources">
                    Sources
                  </motion.div>
                ][relatedCountriesCurrentPage]
              }
            </AnimatePresence>
          }
          disabled={!countriesLength}
          dataFormat={INSPECTOR_DATA_FORMATS.custom}
          customContent={renderRelatedCountriesInspectorContent()}
          popoverSubtext={
            <AnimatePresence initial={false} exitBeforeEnter>
              {
                [
                  <motion.div
                    {...animateProps}
                    key="related countries summaries"
                  >
                    We have identified companies related to your subject in{" "}
                    {countriesLength} {countriesString}.{" "}
                    <S.SectionLinkText>
                      For full details, go to the{" "}
                      <S.SectionLinkButton
                        kind={ButtonKind.tertiary}
                        onClick={() =>
                          scrollToTopOfComponent(
                            sectionRefs &&
                              sectionRefs[personReportSectionTitles.LOCATIONS],
                            true
                          )
                        }
                      >
                        Locations section
                      </S.SectionLinkButton>
                      .
                    </S.SectionLinkText>
                  </motion.div>,
                  <BackButton
                    key="company summaries"
                    animationProps={animateProps}
                    onClick={() => {
                      relatedCountriesPagesRef.current?.switchPageByDirection(
                        DIRECTION.backward
                      );
                    }}
                    label={
                      <>
                        <b>
                          Showing{" "}
                          {selectedRelatedCountryData?.referenceIds?.length}{" "}
                          {selectedRelatedCountryData?.referenceIds?.length > 1
                            ? "organisations"
                            : "organisation"}
                        </b>{" "}
                        in {selectedRelatedCountryData?.countryName}
                      </>
                    }
                  />,
                  <BackButton
                    key="sourcing"
                    animationProps={animateProps}
                    onClick={() => {
                      relatedCountriesPagesRef.current?.switchPageByDirection(
                        DIRECTION.backward
                      );
                    }}
                    label={
                      <>
                        <b>
                          Showing {relatedCountriesOrgSourcesLength}{" "}
                          {relatedCountriesOrgSourcesLength > 1
                            ? "sources"
                            : "source"}
                        </b>{" "}
                        for roles held at{" "}
                        {selectedRelatedCountryOrgData?.orgName}
                      </>
                    }
                  />
                ][relatedCountriesCurrentPage]
              }
            </AnimatePresence>
          }
        >
          <ReactTooltip
            disabled={isScaleIconInspectorOpen}
            maxWidth="300px"
            delay={600}
            tooltip={
              <div>
                Known countries Xapien has found that are related to your
                subject.
              </div>
            }
          >
            <S.ScaleItem>
              <S.ScaleIconContainer>
                <S.CountriesIcon
                  color={
                    countriesLength
                      ? theme.primaryColor ?? green.xapienGreen
                      : grey.ghost
                  }
                />
                {numRelatedCountriesWithRisk > 0 && (
                  <S.RiskCountIcon
                    isMaxTextLength={numRelatedCountriesWithRisk > 99}
                  >
                    {formattedNumCountriesWithRisk}
                  </S.RiskCountIcon>
                )}
              </S.ScaleIconContainer>
              <S.ScaleDetail color={countriesLength ? "inherit" : grey.mid}>
                <span>
                  {countriesLength >= 0 ? relatedCountries?.length : "-"}
                </span>
                {relatedCountries?.length > 1 ? "Countries" : "Country"}
              </S.ScaleDetail>
            </S.ScaleItem>
          </ReactTooltip>
        </WithInspector>
      );
    };

    const renderReach = () => {
      return (
        <>
          <S.ReachTitle>Reach</S.ReachTitle>
          {renderOfficershipsInfoGraphic()}
          {renderCountriesInfoGraphic()}
          {renderSocialReachInfoGraphic()}
        </>
      );
    };

    const insightsEnabled = useInsightsEnabled();
    const renderHowTheyAreDescribed = () => {
      if (insightsEnabled) {
        return undefined;
      }

      const titleString = "How they are described";
      const sectionTitle = (
        <S.DescriptionTitleContainer>
          <S.DescriptionTitle>{titleString}</S.DescriptionTitle>
          <InfoIcon
            infoContent="This section summarises how other sources and
              publications describe this person."
          />
        </S.DescriptionTitleContainer>
      );

      if (!biography?.text?.length) {
        return (
          <div>
            {sectionTitle} <S.NoneIdentified>None identified</S.NoneIdentified>
          </div>
        );
      }

      return (
        <S.DescriptionContainer>
          <div>
            <S.QuotesIcon color={theme.primaryColor} />
            {sectionTitle}
            <br />
            <div>
              <WithInspector
                disabled={!biography?.sources?.length}
                popoverTitle="How they are described"
                sources={biography?.sources}
                display="inline"
                highlightPadding="1px"
                popoverAlignment="top"
              >
                <S.BioText>{sanitiseDisplayData(biography?.text)}</S.BioText>
              </WithInspector>
            </div>
          </div>
        </S.DescriptionContainer>
      );
    };

    const isImagePresent =
      imagesOfPerson?.length && !doesTenantProhibitImages();

    return (
      <ErrorBoundary
        FallbackComponent={SectionErrorContents}
        onError={e =>
          console.error("Error rendering section", e, "Personal Details")
        }
      >
        <Section isExpanded title={title} isContractable={false} ref={ref}>
          <S.PersonalDetailsContainer
            infographicLayoutTemplate={infographicLayoutTemplate}
          >
            <S.ScreeningRiskHeader {...setterPropsForDisplayingAdditionalLabel}>
              <S.SectionTitle>Screening summary</S.SectionTitle>
            </S.ScreeningRiskHeader>
            <S.ScreeningRiskIcons
              {...setterPropsForDisplayingAdditionalLabel}
              hasRiskSummaries={hasRiskSummaries}
            >
              {renderScreeningRiskIcons()}
            </S.ScreeningRiskIcons>
            <S.RiskHeader {...setterPropsForDisplayingAdditionalLabel}>
              <S.SectionTitle>Media for review</S.SectionTitle>
            </S.RiskHeader>
            <S.RiskIcons
              {...setterPropsForDisplayingAdditionalLabel}
              hasRiskSummaries={hasRiskSummaries}
            >
              {renderRiskIcons()}
            </S.RiskIcons>
            {displaySubjectImage && (
              <S.PersonImage
                isImagePresent={isImagePresent}
                hasRiskSummaries={hasRiskSummaries}
              >
                {renderPersonImage()}
              </S.PersonImage>
            )}
            <S.Details1>{renderDetails1()}</S.Details1>
            <S.Details2>{renderDetails2()}</S.Details2>
            <S.OnlinePresence>{renderOnlinePresence()}</S.OnlinePresence>
            <S.Reach>{renderReach()}</S.Reach>
            {!isShowingInsightReport && (
              <S.PersonDescription>
                {renderHowTheyAreDescribed()}
              </S.PersonDescription>
            )}
            {textualSummaries && !isShowingInsightReport && (
              <S.PersonDescription>
                <TextSummaries
                  {...textualSummaries}
                  getSourcesFromIdsMap={getSourcesFromIdsMap}
                />
              </S.PersonDescription>
            )}
          </S.PersonalDetailsContainer>
          <S.CustomSectionFooter />
        </Section>
      </ErrorBoundary>
    );
  }
);

export default observer(PersonOverview);
