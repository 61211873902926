import { useMemo } from "react";

import { UserManagementApi } from "api/types";
import Users from "api/users";
import Tenants from "api/tenant";
import { useHubAuthentication } from "util/hooks/useHubAuthentication";
import { HubAuthenticationStatus } from "util/hooks/useHubAuthentication/types";

const useUserManagementApi = (): UserManagementApi => {
  const {
    state: { status: hubAuthenticationStatus }
  } = useHubAuthentication();

  return useMemo<UserManagementApi>(() => {
    if (hubAuthenticationStatus === HubAuthenticationStatus.authenticated) {
      return new Tenants();
    }
    return new Users();
  }, [hubAuthenticationStatus]);
};

export default useUserManagementApi;
