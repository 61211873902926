import { useMemo, useState } from "react";

import { SearchItemType, SearchResult } from "api/search";
import useDebounce from "util/hooks/useDebounce";
import Api from "api/tenant";
import { Group } from "api/tenant/types";

const containsQuery = (value: string, query: string): boolean => {
  return value?.toLowerCase()?.includes(query.toLowerCase());
};

const useGroupSearch = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupSuggestions, setGroupSuggestions] = useState<SearchResult[]>([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [currentOrgId, setCurrentOrgId] = useState<string | undefined>();

  const { debounce } = useDebounce();

  const TenantApi = useMemo(() => new Api(), []);

  const getGroupSuggestions = async (query: string, orgId: string) => {
    const suggestions: SearchResult[] = [];
    let groupsToFilter = groups;

    // If the organisation that was selected has changed, then update the stored set of groups
    if ((orgId && !groups.length) || (orgId && currentOrgId !== orgId)) {
      const { status, response } = await TenantApi.getGroupsFromOrganisation(
        orgId
      );
      if (status && response) {
        groupsToFilter = response;
        setGroups(response);
      } else {
        setGroups([]);
      }
      setCurrentOrgId(orgId);
    }

    groupsToFilter.forEach(group => {
      if (
        containsQuery(group.title, query) ||
        containsQuery(group.description ?? "", query)
      ) {
        suggestions.push({
          id: group.groupId,
          title: group.title,
          description: group.description,
          queryType: SearchItemType.Group
        });
      }
    });

    return suggestions;
  };

  const debounceQuerySearch = debounce(async (query: string, orgId) => {
    setIsFetchingSuggestions(true);
    const userResults: SearchResult[] = await getGroupSuggestions(query, orgId);

    setGroupSuggestions(userResults);
    setIsFetchingSuggestions(false);
  }, 300);

  const onSearchUpdate = (query: string, orgId: string) => {
    debounceQuerySearch(query, orgId);
  };

  const clearGroups = () => {
    setGroups([]);
    setGroupSuggestions([]);
  };

  return {
    groupSuggestions,
    onSearchUpdate,
    isFetchingSuggestions,
    clearGroups
  };
};

export default useGroupSearch;
