import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { ReactComponent as ExitIcon } from "img/icons/cross-in-circle.svg";

import {
  Modal as ReactstrapModal,
  ModalBody as ReactstrapModalBody,
  ModalFooter as ReactstrapModalFooter,
  ModalHeader as ReactstrapModalHeader
} from "reactstrap";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";
import Button from "components/atoms/Button";

const Modal = styled(ReactstrapModal)`
  max-width: 1024px !important;
`;

const ModalBody = styled(ReactstrapModalBody)`
  padding: 16px;
`;

const ModalFooter = styled(ReactstrapModalFooter)`
  color: ${grey.dark};
  justify-content: space-between !important;
  font-size: ${fontSize.sm};
  margin: 0 16px;
  padding-left: 0;
  padding-right: 0;
  border-top: 1px solid ${grey.rule};
`;

const ModalHeader = styled(ReactstrapModalHeader)`
  border-bottom: none !important;
  padding: 0 !important;
`;

const ExitContainer = styled(Button)`
  position: absolute;
  min-width: 0 !important;
  min-height: 0 !important;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
  border-radius: 50%;
  background-color: ${standardColors.white} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 17px;
  top: 17px;

  &:focus,
  &:active {
    box-shadow: 0 0 0 4px rgb(255 255 255 / 33%) !important;
  }
`;

const ExitModalIcon = styled(ExitIcon)`
  height: 20px;
  width: 20px;
  &:hover {
    cursor: pointer;
  }
  circle {
    stroke: transparent;
  }
  path {
    fill: ${grey.ghost};
  }
`;

/** ClassNameOverrides */

const modalContent = css`
  border-radius: 11px;
  border: none;
`;

export const classNameOverrides = {
  modalContent
};

const S = {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ExitContainer,
  ExitModalIcon
};

export default S;
