import React, { useState } from "react";
import { ReactComponent as TickIcon } from "img/icons/tick.svg";
import { ReactComponent as ArrowIcon } from "img/icons/arrow-down.svg";
import { InsightReportSection } from "api/insight-reports";
import { SelectedSection } from "components/molecules/ActionToolBar/MoveSectionModal";
import S from "./styles";

interface SelectionDropDownProps {
  sections: InsightReportSection[] | undefined;
  labelTitle: string;
  sectionSelected?: SelectedSection | null;
  setSectionSelected: (selectedSection: SelectedSection) => void;
}

const SelectionDropDown: React.FC<SelectionDropDownProps> = ({
  sections,
  labelTitle,
  sectionSelected: sectionSelect,
  setSectionSelected: setSectionSelect
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleSectionSelect = (id: string, title: string) => {
    setSectionSelect({ id, title });
    setIsDropdownOpen(false);
  };

  return (
    <S.SectionLabel>
      {labelTitle}
      <S.SectionSelect>
        <S.SectionSelectSelectedItem
          open={isDropdownOpen}
          onClick={() => setIsDropdownOpen(prev => !prev)}
        >
          {sectionSelect?.title ?? "Select..."}
          <ArrowIcon />
        </S.SectionSelectSelectedItem>
        {isDropdownOpen && (
          <S.SectionDropdown>
            {sections?.map(({ id, title }) => (
              <S.SectionDropdownItem
                key={`SectionSelect-option-${id}`}
                onClick={() => handleSectionSelect(id, title || "")}
                active={id === sectionSelect?.id}
              >
                {title}
                {sectionSelect?.id === id && <TickIcon />}
              </S.SectionDropdownItem>
            ))}
          </S.SectionDropdown>
        )}
      </S.SectionSelect>
    </S.SectionLabel>
  );
};

export default SelectionDropDown;
