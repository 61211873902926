import React, { useState, useEffect, Fragment } from "react";

import S from "./styles";

const StackedBar = ({
  total,
  bars,
  title,
  showZeroCountBars = false,
  onBarSectionClick = () => {},
  isBarNotSelected,
  selectedBarId,
  hasFixedHeight,
  isInteractable = true,
  rightAlignLastPill = false
}) => {
  const [hoveredSegmentId, setHoveredSegmentId] = useState();

  useEffect(() => {
    setHoveredSegmentId(undefined);
  }, [bars]);

  const activeCount = bars.reduce((acc, b) => acc + b.count, 0);

  const getBarWidth = current => `${(current / total) * 100}%`;

  const onSegmentHover = barId => {
    if (barId) {
      setHoveredSegmentId(barId);
    } else {
      setHoveredSegmentId(undefined);
    }
  };

  const onMouseLeaveSegment = () => {
    setHoveredSegmentId(undefined);
  };

  const filterAndMapBarData = () => {
    return bars.reduce((acc, bar, i) => {
      if (!showZeroCountBars && bar.count <= 0) {
        return acc;
      }

      return [
        ...acc,
        <S.BarSegmentContainer
          key={i}
          barWidth={getBarWidth(bar.count)}
          barMargin={`${bar.count && "2px"}`}
        >
          <S.BarSegment
            onClick={() => {
              if (bar.count > 0) {
                onBarSectionClick(bar);
              }
            }}
            onMouseOver={() => onSegmentHover(bar.id)}
            onMouseLeave={() => onMouseLeaveSegment(bar.id)}
            background={bar.color}
            opacity={bar.opacity}
            isActive={selectedBarId === bar.id}
            isInteractable={bar.count > 0 && isInteractable}
            isBarHovered={hoveredSegmentId === bar.id}
            isAnyBarHovered={hoveredSegmentId !== undefined}
            isBarSegmentObscured={bar.isObscured}
          />
        </S.BarSegmentContainer>
      ];
    }, []);
  };

  const filterAndMapBarLabelData = barsToDisplay => {
    return barsToDisplay.reduce((acc, bar) => {
      if (!showZeroCountBars && bar.count <= 0) {
        return acc;
      }

      return [
        ...acc,
        <S.BarSegmentLabel
          role="button"
          aria-label={`Filter by ${bar.label}`}
          key={bar.id}
          color={bar.labelColor ?? bar.color}
          fillColor={bar.color}
          opacity={bar.opacity}
          onClick={() => {
            if (bar.count > 0) {
              onBarSectionClick(bar);
            }
          }}
          onMouseOver={() => onSegmentHover(bar.id)}
          onMouseLeave={() => onMouseLeaveSegment(bar.id)}
          isCountZero={bar.count === 0}
          isActive={selectedBarId === bar.id}
          isInteractable={bar.count > 0 && isInteractable}
          isCorrespondingBarHovered={hoveredSegmentId === bar.id}
          isAnyBarHovered={hoveredSegmentId !== undefined}
          isBarSegmentObscured={bar.isObscured}
        >
          {bar.label} | {bar.count}
        </S.BarSegmentLabel>
      ];
    }, []);
  };

  return (
    <S.BarsContainer
      hasFixedHeight={hasFixedHeight}
      isBarNotSelected={isBarNotSelected}
    >
      {title && <S.BarTitle>{title}</S.BarTitle>}
      <S.BarInfo>
        {activeCount > 0 || showZeroCountBars ? filterAndMapBarData() : null}
      </S.BarInfo>
      <S.BarInfoContainer>
        <S.BarInfo rightAlignLastPill={rightAlignLastPill} shouldWrap>
          {rightAlignLastPill ? (
            <>
              <S.FirstBarLabels>
                {filterAndMapBarLabelData(bars.slice(0, bars.length - 1))}
              </S.FirstBarLabels>
              <div>{filterAndMapBarLabelData(bars.slice(bars.length - 1))}</div>
            </>
          ) : (
            filterAndMapBarLabelData(bars)
          )}
        </S.BarInfo>
      </S.BarInfoContainer>
    </S.BarsContainer>
  );
};

export default React.memo(StackedBar);
