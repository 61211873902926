import React from "react";

import Heading from "components/atoms/Heading";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

interface Props {
  toggleModal: () => void;
}

const Confirmation = ({ toggleModal }: Props) => {
  return (
    <S.Confirmation
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Heading level={4}>Account requested</Heading>
      <S.ConfirmationText>
        An email requesting an invitation to join Xapien has been sent.
      </S.ConfirmationText>
      <ButtonNew
        text="Done"
        size={ButtonSize.Medium}
        type={ButtonType.Filled}
        onClick={toggleModal}
      />
    </S.Confirmation>
  );
};

export default Confirmation;
