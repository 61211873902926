import { useContext } from "react";

import { UserActionsContext } from "./context";
import { UserActionsProvider } from "./provider";

const useUserActions = () => {
  const context = useContext(UserActionsContext);

  if (!context) {
    throw new Error("useUserActions must be used within a UserActionsProvider");
  }

  return context;
};

export { useUserActions, UserActionsProvider };
