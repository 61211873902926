import { styled } from "@linaria/react";

import { ReactComponent as HMTreasury } from "img/hm-treasury-enclosed.svg";
import { ReactComponent as TransparencyIcon } from "img/transparency.svg";
import { ReactComponent as EuropeanIcon } from "img/european.svg";
import { red } from "styles/colors";

const European = styled(EuropeanIcon)`
  width: 20px;
  height: 20px;
  margin-right: 4px;

  path {
    fill: ${red.directRisk};
  }
`;

const Transparency = styled(TransparencyIcon)`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  margin-top: 1px;

  path {
    fill: ${red.directRisk};
  }
`;

const Treasury = styled(HMTreasury)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

const S = {
  Treasury,
  European,
  Transparency
};

export default S;
