import { styled } from "@linaria/react";
import { blue } from "styles/colors";
import { fontSize } from "styles/fonts";

// @ts-ignore
import theme from "theme";

const Shell = styled.div<{ tenant?: string }>`
  display: flex;
  flex-direction: column;

  min-height: 100vh;

  /* emulate position: fixed on the old raster background*/
  background-size: 125% 100%;
  @media screen and (min-width: 1280px) {
    background-size: 100%;
  }

  background-repeat: no-repeat;
  background-color: ${theme.backgroundColor};
  background-image: none;
  background-attachment: fixed;
`;

const Header = styled.header`
  grid-column: 1/3;
`;

const Main = styled.main`
  z-index: 1;
  margin: 0 1em;
`;

const InfoBanner = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${blue.xapienLight};
  font-size: ${fontSize.sm};
  color: ${blue.xapienDark};
  padding: 0.25rem 0;
  position: relative;
`;

const BackLink = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
`;

const S = {
  BackLink,
  Shell,
  Header,
  Main,
  InfoBanner
};

export default S;
