import React, { CSSProperties, FC, SVGProps, useState } from "react";
import { Spinner } from "reactstrap";
import { ChevronDown, Download } from "react-feather";

import ModalSection from "components/atoms/ModalSection";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import ButtonNew from "components/atoms/ButtonNew";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import Popover from "components/atoms/Popover";
import Heading from "components/atoms/Heading";

import S from "./styles";

interface Props {
  usage: number;
  isDownloadingUsage: boolean;
  onDownloadUsage: () => void;
  usageOptions: Record<string, number>;
  selectedUsageOption: string;
  onUsageOptionSelect: (value: string) => void;
  style?: CSSProperties;
  className?: string;
}

const ReportUsagePanel = ({
  usage,
  isDownloadingUsage,
  onDownloadUsage,
  usageOptions,
  selectedUsageOption,
  onUsageOptionSelect,
  style,
  className
}: Props) => {
  const [isUsageOptionDropdownMenuOpen, setIsUsageOptionDropdownMenuOpen] =
    useState(false);

  const renderUsageSelect = () => {
    const dropdownMenu = (
      <Menu>
        {Object.keys(usageOptions).map((optionKey, index) => (
          <MenuItem
            key={optionKey}
            text={optionKey}
            onMenuItemClick={() => {
              onUsageOptionSelect(optionKey);
              setIsUsageOptionDropdownMenuOpen(false);
            }}
            disabled={optionKey === selectedUsageOption}
            showSeparator={index === Object.keys(usageOptions).length - 2}
          />
        ))}
      </Menu>
    );
    return (
      <Popover
        // @ts-ignore
        isOpenOverride={isUsageOptionDropdownMenuOpen}
        // @ts-ignore
        onRequestClose={() => setIsUsageOptionDropdownMenuOpen(false)}
        trigger="click"
        hideArrow
        alignment="bottom-end"
        content={dropdownMenu}
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.OptionsButton
          type="button"
          onClick={() => setIsUsageOptionDropdownMenuOpen(prev => !prev)}
        >
          {selectedUsageOption}{" "}
          <ChevronDown size={16} style={{ marginBottom: 4 }} />
        </S.OptionsButton>
      </Popover>
    );
  };

  return (
    <ModalSection style={style} className={className}>
      <Heading level={5}>Usage</Heading>
      <S.ReportsRunContent>
        <div>
          <S.InlineHeading level={5}>{usage}</S.InlineHeading>{" "}
          <S.ReportsRunLabel>reports run</S.ReportsRunLabel>
        </div>
        {renderUsageSelect()}
      </S.ReportsRunContent>

      <S.DownloadReports>
        <span>Download full usage log for the account (.csv)</span>
        <ButtonNew
          type={ButtonType.Outlined}
          size={ButtonSize.Medium}
          text={isDownloadingUsage ? "Downloading" : "Download"}
          IconTrailing={
            isDownloadingUsage
              ? (Spinner as unknown as FC<SVGProps<SVGSVGElement>>)
              : Download
          }
          onClick={onDownloadUsage}
          disabled={isDownloadingUsage}
        />
      </S.DownloadReports>
    </ModalSection>
  );
};

export default ReportUsagePanel;
