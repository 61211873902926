import { runInAction, makeObservable } from "mobx";
import Report from "../api/report/Report";

export class UserAssessmentStore {
  constructor(sharedUserInterface, reportStore) {
    makeObservable(this, {});
    this.sharedUserInterface = sharedUserInterface;
    this.reportStore = reportStore;
    this.reportApi = new Report(state =>
      runInAction(() => (this.sharedUserInterface.apiCallInProgress = state))
    );
  }

  applyAssessments = (enquiryId, assessments) => {
    const obj = Object.fromEntries(assessments);
    return this.reportApi.regenerate(enquiryId, obj); // todo add in what they were before, as belt and braces
  };
}
