import { createContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { titleizeString } from "util/titleizeString";
import dayjs from "dayjs";
import {
  InsightsAmaAction,
  InsightsAmaActions,
  InsightsAmaMessage,
  InsightsAmaMessageStatus,
  InsightsAmaState,
  InsightsAmaStatus,
  User
} from "./types";

export const initialState: InsightsAmaState = {
  status: InsightsAmaStatus.idle,
  messages: [],
  suggestedQuestions: []
};

export const InsightsAmaContext = createContext({
  state: initialState,
  dispatch: (_action: InsightsAmaAction) => {},
  askQuestion: (_question: string, _sectionId: string) => {},
  getSuggestedQuestions: () => {},
  getMessages: (_sectionId: string): InsightsAmaMessage[] => []
});

const generateBlankRawSubsection = (
  question: string,
  user: User,
  timestamp: string
) => {
  return {
    id: "",
    title: "",
    timestamp,
    elements: [
      {
        id: "",
        title: "",
        body: titleizeString(question),
        segments: []
      }
    ],
    text: "",
    user,
    is_unknown: true,
    created_by: `${user.firstName} ${user.lastName}`,
    date_created: timestamp
  };
};

export const insightsAmaReducer = (
  state: InsightsAmaState,
  action: InsightsAmaAction
) => {
  switch (action.type) {
    case InsightsAmaActions.askQuestion: {
      const messageId = uuidv4();
      const timestamp = dayjs(new Date()).toISOString();
      const newMessage = {
        id: messageId,
        sectionId: action.sectionId,
        status: InsightsAmaMessageStatus.loading,
        question: generateBlankRawSubsection(
          action.question,
          {
            ...action.user,
            firstName: action.user.firstName.length
              ? action.user.firstName
              : "You"
          },
          timestamp
        )
      };

      const questionToAsk = {
        messageId,
        query: action.question
      };

      return {
        ...state,
        questionToAsk,
        messages: [...state.messages, newMessage],
        status: InsightsAmaStatus.askingQuestion
      };
    }
    case InsightsAmaActions.questionAnswered: {
      const messageId = state.questionToAsk?.messageId;
      const updatedMessages = state.messages.map(message =>
        message.id === messageId
          ? {
              ...message,
              answer: action.answer,
              status: InsightsAmaMessageStatus.saving
            }
          : message
      );

      return {
        ...state,
        messages: updatedMessages,
        questionToAsk: undefined,
        messageToSave: updatedMessages.find(
          message => message.id === messageId
        ),
        status: InsightsAmaStatus.savingMessage
      };
    }
    case InsightsAmaActions.getSuggestedQuestions: {
      return state;
    }
    case InsightsAmaActions.errorAskingQuestion: {
      const messageId = state.questionToAsk?.messageId;
      const updatedMessages = state.messages.map(message =>
        message.id === messageId
          ? { ...message, status: InsightsAmaMessageStatus.error }
          : message
      );

      return {
        ...state,
        status: InsightsAmaStatus.errorAskingQuestion,
        messages: updatedMessages
      };
    }
    case InsightsAmaActions.errorSavingMessage: {
      const messageId = state.questionToAsk?.messageId;
      const updatedMessages = state.messages.map(message =>
        message.id === messageId
          ? { ...message, status: InsightsAmaMessageStatus.error }
          : message
      );

      return {
        ...state,
        status: InsightsAmaStatus.errorSavingMessage,
        messages: updatedMessages
      };
    }

    case InsightsAmaActions.messageSaved: {
      const messageId = state.questionToAsk?.messageId;
      const updatedMessages = state.messages.map(message =>
        message.id === messageId
          ? { ...message, status: InsightsAmaMessageStatus.saved }
          : message
      );

      return {
        ...state,
        status: InsightsAmaStatus.idle,
        messages: updatedMessages
      };
    }

    default: {
      return state;
    }
  }
};
