import React from "react";
import Heading from "components/atoms/Heading";
import S from "./styles";

const DowJonesDisclaimer = () => {
  return (
    <S.Disclaimer>
      <Heading level={3} size={6}>
        Confidentiality Statement
      </Heading>
      <p>
        This report is strictly confidential. It is provided for the exclusive
        use of Dow Jones&apos;s client to perform customer or counterparty due
        diligence and other screening and risk management activities carried out
        to comply with legal or regulatory obligations to which Dow Jones&apos;s
        client is subject, in particular “know your customer and counterparty”
        requirements under anti-money laundering, anti-bribery, corruption and
        economic sanctions regulation which apply to Dow Jones&apos;s client.
      </p>
      <hr />
      <Heading level={3} size={6}>
        Disclaimer
      </Heading>
      <p>
        Some content included in this report are from publicly available
        material and some content may have been machine-generated event
        summaries relating to your subject using Xapien&apos;s FluenciTM
        artificial intelligence technology. This is not an exhaustive source of
        information, and this report may not reflect all developments in a
        particular matter. It is not possible for Dow Jones to establish the
        veracity or timeliness of the summaries or the underlying content used
        to generate the summaries. You should refer to the original source used
        to create the machine-generated event summary. Dow Jones accepts no
        liability for any loss or damage caused by inaccurate or out of date
        information that may be contained in the report or from sources used in
        the report. Dow Jones does not imply any negative inferences about
        individuals or entities included in this report. Users are advised to
        beware of false positives -- similar or matching names in the report may
        refer to different individuals who are not connected to the information
        in this report -- and to carry out further review of their results to
        eliminate all false positives. Users are advised to consider the profile
        information in connection with the details found in the source material
        and to conduct further research outside the database before making any
        decisions.
      </p>
      <p>
        The report allows you to generate translation from the original language
        of a source into another language using automated translation software.
        This feature is no substitute for a professional human translator. Dow
        Jones does not take responsibility for the accuracy, reliability or
        completeness of this translation and the results of this translation are
        “as is.” Dow Jones disclaims all warranties related to the translations,
        express or implied, including any warranties of accuracy, reliability,
        any any implied warranties of merchantability, fitness for a particular
        purpose and non-infringement, and disclaims any liability, loss or
        damages suffered based on the user&apos;s reliance of any kind or for
        any purpose with respect to the content of this translation.Dow Jones is
        not a consumer reporting agency and this report does not constitute a
        “consumer report” as such term is defined in the federal Fair Credit
        Reporting Act (FCRA), 15 U.S.C.A. sec 1681 et seq. Accordingly, this
        report should not be usedL (i) as a factor in determining an
        individual&apos;s eligibility for credit, insurance, employment or for
        any other eligibility determination purposes that would qualify the
        service as a consumer report under the FCR; and/or (ii) to take adverse
        decision regarding the application(s) made by an individual based on
        this report.
      </p>
    </S.Disclaimer>
  );
};

export default DowJonesDisclaimer;
