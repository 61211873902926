import React, { useState, useLayoutEffect, useRef, Fragment } from "react";
import clamp from "clamp-js";

import { getDurationSinceStartDate } from "util/getDurationSinceStartDate";
import { toTitleCase } from "util/common";
import SocialMediaIcons from "components/molecules/SocialMediaIcons";
import { INSPECTOR_DATA_FORMATS } from "util/inspectorDataFormat";
import { getDurationStringFromDates } from "util/getDurationStringFromDates";
import WithInspector from "components/organisms/WithInspector";
import TruncateLength from "util/TruncateLength";

import S, { classNameOverrides } from "./styles";

// For ordering
const SPECIAL_ROLES = {
  UBO: {
    sortOrder: 1,
    label: "Ultimate beneficial owner"
  },
  PSC: {
    sortOrder: 2,
    label: "Person of significant control"
  },
  Founder: {
    sortOrder: 3,
    label: "Founder"
  },
  Officer: { sortOrder: 4, label: "Officer" },
  Secretary: { sortOrder: 5, label: "Secretary" }
};

const SignificantPeopleCardContent = ({
  name,
  roles = [],
  structuredDataRoles = [],
  onlineProfiles,
  roleAddresses,
  bio,
  missingProtectedData,
  knownToBeInactive,
  onClick = () => {}
}) => {
  const [selectedRoleFilter, setSelectedRoleFilter] = useState();
  const [selectedSocialMediaIcon, setSelectedSocialMediaIcon] = useState();

  const bioRef = useRef(null);
  const nameRef = useRef(null);
  const roleTitleRef = useRef(null);

  useLayoutEffect(() => {
    if (bioRef?.current) {
      clamp(bioRef.current, {
        clamp: 3
      });
    }

    if (nameRef?.current) {
      clamp(nameRef.current, { clamp: 1 });
    }

    if (roleTitleRef?.current) {
      clamp(roleTitleRef.current, { clamp: 2 });
    }

    onClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bioRef?.current?.offsetHeight]);

  const profileData = onlineProfiles?.map(profile => ({
    data: profile
  }));

  // eslint-disable-next-line consistent-return
  const getEmboldenedBio = () => {
    if (bioRef?.current?.textContent?.length) {
      // We have to estimate the visible bio text in the card.
      // Start at the 90th character, which is about 3 line's worth
      // of bio, i.e. what we show in the card, and keep counting
      // until you encounter the next space. This avoids emboldening
      // partial words.
      let charsUntilSpace = 0;
      // eslint-disable-next-line no-plusplus
      for (let i = 89; i < bio.bio.length; i++) {
        if (bio.bio.charAt(i) === " ") {
          break;
        }
        charsUntilSpace += 1;
      }

      const emboldenedCharacterCount = 90 + charsUntilSpace;
      const emboldenedSentence = bio.bio.substring(0, emboldenedCharacterCount);
      const restOfBio = bio.bio.substring(emboldenedCharacterCount);

      return (
        <span>
          <strong>{emboldenedSentence}</strong>
          {restOfBio}
        </span>
      );
    }
  };

  // Remaining roles after the first that has been displayed
  const renderRoles = () => {
    const latestRole = roles[0];
    const latestRoleEmployer = latestRole?.employerToDisplay
      ? `(${latestRole.employerToDisplay})`
      : "";
    const latestRoleStartDate = latestRole?.dates?.startDate;
    const latestRoleEndDate = latestRole?.dates?.endDate;

    // Get all the start dates for each role
    const startDatesForEachRole = roles.reduce((acc, role) => {
      if (role.dates?.startDate?.year) {
        acc.push(role.dates?.startDate?.year);
      }

      return acc;
    }, []);
    let earliestStartDate;

    if (startDatesForEachRole.length) {
      // Find the earliest start date. .apply lets us supply an array
      // of values.
      // eslint-disable-next-line prefer-spread
      earliestStartDate = Math.min.apply(Math, startDatesForEachRole);
    }

    const transformedRoles = roles.map(role => {
      const value = getDurationStringFromDates({
        startDate: role?.dates?.startDate,
        endDate: role?.dates?.endDate,
        isKnownToBeOngoing: role?.dates?.isKnownToBeOngoing,
        lastSeen: role?.dates?.lastSeen
      });

      return {
        topSectionElement: (
          <S.DurationPills>
            <TruncateLength>
              {role.roleTitle} {latestRoleEmployer}
            </TruncateLength>
            <S.RoleDuration isSelected={selectedRoleFilter === role.roleTitle}>
              <TruncateLength>
                {value ?? <S.NoDate>Unknown date</S.NoDate>}
              </TruncateLength>
            </S.RoleDuration>
          </S.DurationPills>
        ),
        sources: role.sources,
        id: role.roleTitle
      };
    });

    return (
      <WithInspector
        dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
        filterableSources={transformedRoles}
        topSectionElement={transformedRoles.map((role, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <S.RoleContainer key={index}>
              {role.topSectionElement}
            </S.RoleContainer>
          );
        })}
        onFilterSelected={setSelectedRoleFilter}
        isInspectorOpen={isOpen => {
          if (!isOpen) {
            setSelectedRoleFilter(null);
          }
        }}
        display="inline-flex"
        highlightPadding="2px"
      >
        <S.RoleTitle>
          <div ref={roleTitleRef}>
            {latestRole.roleTitle} {latestRoleEmployer}
          </div>
          <div>
            {latestRoleStartDate?.year && (
              <S.LatestRoleDuration>
                {getDurationSinceStartDate({
                  startDate: latestRoleStartDate,
                  endDate: latestRoleEndDate
                })}
              </S.LatestRoleDuration>
            )}{" "}
            {roles.length > 1 && (
              <S.RolesSinceStartLabel>
                +{roles.length - 1} {roles.length - 1 === 1 ? "role" : "roles"}{" "}
                {earliestStartDate ? `since ${earliestStartDate}` : "(No date)"}
              </S.RolesSinceStartLabel>
            )}
          </div>
        </S.RoleTitle>
      </WithInspector>
    );
  };

  const renderPills = () => {
    const pills = [];
    const roleCount = structuredDataRoles?.length;
    const MAX_RENDER = 3;

    if (structuredDataRoles === null || roleCount === 0) {
      return null;
    }

    const sortedDataRoles = [...structuredDataRoles];

    // Sort by role type
    sortedDataRoles.sort((a, b) => {
      return (
        // eslint-disable-next-line no-unsafe-optional-chaining
        SPECIAL_ROLES[a.roleType]?.sortOrder -
        // eslint-disable-next-line no-unsafe-optional-chaining
        SPECIAL_ROLES[b.roleType]?.sortOrder
      );
    });
    let dataRole;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < roleCount; i++) {
      dataRole = sortedDataRoles[i];
      pills.push(
        <S.RolePill
          key={dataRole.roleType}
          roleType={dataRole.roleType}
          hasRoleEnded={
            dataRole?.dates?.endDate && !dataRole?.dates?.isKnownToBeOngoing
          }
          isKnownToBeInactive={knownToBeInactive}
        >
          {dataRole.roleType}
        </S.RolePill>
      );
    }

    return (
      <S.StyledWithInspector
        dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
        filterableSources={sortedDataRoles.map(role => {
          return {
            topSectionElement: role.roleType,
            sources: role.sources
          };
        })}
        display="inline-flex"
        topSectionElement={<S.PillsContainer>{pills}</S.PillsContainer>}
      >
        <S.PillsContainer>
          {pills.slice(0, MAX_RENDER)}{" "}
          {roleCount > MAX_RENDER && (
            <S.PillsInfo>
              <span>{`+${roleCount - MAX_RENDER}`}</span>
            </S.PillsInfo>
          )}
        </S.PillsContainer>
      </S.StyledWithInspector>
    );
  };

  const renderOnlineProfiles = () => {
    const MAX_RENDER = 2;

    const profileCount = profileData?.length;
    let truncatedProfileData = profileData;

    if (truncatedProfileData === null || profileCount === 0) {
      return null;
    }

    if (profileCount > MAX_RENDER) {
      truncatedProfileData = profileData.slice(0, MAX_RENDER);
    }

    return (
      profileCount > 0 && (
        <S.SocialMediaWithInspector
          dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
          filterableSources={truncatedProfileData.map(profile => {
            return {
              topSectionElement: (
                <S.InspectorSocialMediaIcons
                  isSelected={selectedSocialMediaIcon === profile.data.link}
                  profileData={[profile]}
                  disabled
                />
              ),
              sources: profile.data.sources,
              id: profile.data.link
            };
          })}
          onFilterSelected={setSelectedSocialMediaIcon}
          display="inline-flex"
          pillClassName={classNameOverrides.pillClassName}
          isInspectorOpen={isOpen => {
            if (!isOpen) {
              setSelectedSocialMediaIcon(null);
            }
          }}
          topSectionElement={truncatedProfileData.map(profile => {
            return (
              <S.MediaHandlerContainer key={profile.data.link}>
                <S.InspectorSocialMediaIcons
                  isSelected={selectedSocialMediaIcon === profile.data.link}
                  profileData={[profile]}
                  // We don't want the links to be selectable in the Inspector.
                  // That's what the sources are for.
                  disabled
                />
              </S.MediaHandlerContainer>
            );
          })}
        >
          <S.SocialMediaIconsContainer>
            <SocialMediaIcons
              showLabels={false}
              profileData={truncatedProfileData}
              arrangeIconsInRow
              // We don't want the links to be selectable in the Inspector.
              // That's what the sources are for.
              disabled
            />
            {profileCount > MAX_RENDER && (
              <S.OnlineProfilesInfo>
                {`+${profileCount - MAX_RENDER}`}
              </S.OnlineProfilesInfo>
            )}
          </S.SocialMediaIconsContainer>
        </S.SocialMediaWithInspector>
      )
    );
  };

  const renderBio = () => {
    let text = "";
    if (bio && bio.bio) {
      text = (
        <WithInspector
          topSectionElement={getEmboldenedBio()}
          sources={bio?.sources}
        >
          <S.DescriptionSection ref={bioRef}>{bio.bio}</S.DescriptionSection>
        </WithInspector>
      );
    } else if (missingProtectedData) {
      text = (
        <S.DescriptionSection ref={bioRef}>
          <S.MissingData>
            Some LinkedIn data is hidden by the individual&apos;s privacy
            settings
          </S.MissingData>
        </S.DescriptionSection>
      );
    } else {
      return null;
    }
    return (
      <S.DescriptionContainer>
        <S.Rule />
        {text}
      </S.DescriptionContainer>
    );
  };

  const renderLocations = () => {
    if (!roleAddresses?.length) {
      return null;
    }

    const dedupedAddresses = {};
    roleAddresses.forEach(address => {
      const shortAddressString = address.shortAddress
        .filter(Boolean)
        .join(", ");
      if (dedupedAddresses[shortAddressString]) {
        dedupedAddresses[shortAddressString].sources = [
          ...dedupedAddresses[shortAddressString].sources
        ];
      } else {
        dedupedAddresses[shortAddressString] = {
          sources: address.sources,
          string: shortAddressString
        };
      }
    });

    const dedupedAddressesKeys = Object.keys(dedupedAddresses);

    return (
      <WithInspector
        dataFormat={INSPECTOR_DATA_FORMATS.filterableList}
        filterableSources={dedupedAddressesKeys.map(address => {
          return {
            topSectionElement: dedupedAddresses[address].string,
            sources: dedupedAddresses[address].sources
          };
        })}
        display="inline-flex"
        topSectionElement={
          <S.LocationText>
            {Object.keys(dedupedAddresses).length > 0 ? (
              <span>
                {dedupedAddressesKeys.map(k => (
                  <Fragment key={k}>
                    {k}
                    <br />
                  </Fragment>
                ))}
              </span>
            ) : null}
          </S.LocationText>
        }
      >
        <S.LocationText>
          {Object.keys(dedupedAddresses).length > 0 ? (
            <>
              <S.LocationName>{dedupedAddressesKeys[0]} </S.LocationName>
              {dedupedAddressesKeys.length > 1 && (
                <span>{`+${dedupedAddressesKeys.length - 1}`}</span>
              )}
            </>
          ) : null}
        </S.LocationText>
      </WithInspector>
    );
  };

  const renderName = () => {
    if (typeof name === "string") {
      return name;
    }

    const nameString = name?.name;
    return nameString && nameString === nameString.toUpperCase()
      ? toTitleCase(nameString)
      : nameString;
  };

  return (
    <S.ContentContainer isKnownToBeInactive={knownToBeInactive}>
      <S.CardTopSection>
        <WithInspector sources={name?.sources} display="inline-flex">
          <S.NameField ref={nameRef}>{renderName()}</S.NameField>
        </WithInspector>
      </S.CardTopSection>
      {knownToBeInactive && <S.NoActiveRoles>No active roles</S.NoActiveRoles>}
      {roles?.length ? renderRoles() : null}
      {renderOnlineProfiles()}
      <S.CardSummary>{renderBio()}</S.CardSummary>
      {roleAddresses?.length || structuredDataRoles?.length ? <S.Rule /> : null}
      {renderLocations()}
      {renderPills()}
    </S.ContentContainer>
  );
};

export default SignificantPeopleCardContent;
