import React from "react";
import Define from "pages/define";
import { useAuthentication } from "util/hooks/useAuthentication";
import PageTitle from "components/atoms/PageTitle";

const Search = () => {
  const {
    state: {
      permissions: { canStartReports }
    }
  } = useAuthentication();

  if (!canStartReports) {
    // TODO: What should we tell the user when they get to this point? Redirect somewhere?
    // HA THIS SHOULD PROBABLY BE LOOKED AT
    return null;
  }

  return (
    <>
      <PageTitle title="Xapien" />
      <Define />
    </>
  );
};

export default Search;
