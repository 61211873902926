import { styled } from "@linaria/react";
import { grey, standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Question = styled.p`
  margin: 0;
  font-size: ${fontSize.lg};
  font-family: ${family.interDisplay};
  font-weight: inherit;
  line-height: 1.625rem;
  letter-spacing: -0.4px;
  color: ${standardColors.black};
`;

const QuestionDate = styled.p`
  margin: 0;
  font-size: ${fontSize["2xs"]};
  color: ${grey.dark};
  text-transform: uppercase;
`;

const S = {
  QuestionContainer,
  Question,
  QuestionDate
};

export default S;
