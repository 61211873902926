import { styled } from "@linaria/react";

import ReactTooltip from "components/atoms/ReactTooltip";
import { ReactComponent as UncertaintyTildeSvg } from "img/icons/uncertainty_tilde.svg";

import { red, grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const Pill = styled.div`
  padding: 0px 8px;
  border: 1px solid ${props => (props.isActive ? red.directRisk : grey.mid)};
  border-radius: 16px;
  font-size: ${fontSize.sm};
  color: ${props => (props.isActive ? red.directRisk : grey.mid)};
  cursor: ${props => (props.isInteractive ? "pointer" : "default")};
  display: inline;
`;

const UncertaintyTilde = styled(UncertaintyTildeSvg)`
  width: 12px;
  margin-left: -4px;
  margin-right: 2px;
  margin-top: -2px;

  path {
    fill: ${red.directRisk};
  }
`;

const Tooltip = styled(ReactTooltip)`
  display: inline;
`;

const S = {
  Pill,
  UncertaintyTilde,
  Tooltip
};

export default S;
