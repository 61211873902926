import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";

import Api from "api/groups";

const useLeaveGroup = () => {
  const [{ fetching, error, success, errorMessage }, dispatch] =
    useFetchReducer();

  const GroupsApi = new Api();

  const leaveGroup = async (
    groupId: string,
    isLastMember: boolean
  ): Promise<boolean> => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = isLastMember
      ? await GroupsApi.deleteGroup(groupId)
      : await GroupsApi.leaveGroup(groupId);

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return false;
    }

    dispatch({ type: RequestActions.SetSuccess });
    return true;
  };

  const reset = () => {
    dispatch({ type: RequestActions.Reset });
  };

  const resetError = () => {
    dispatch({ type: RequestActions.ResetError });
  };

  return {
    fetching,
    error,
    errorMessage,
    success,
    reset,
    resetError,
    leaveGroup
  };
};

export default useLeaveGroup;
