import React, { Fragment } from "react";

import { ENTITY_TYPE } from "pages/define/utils";
import { getAgeFromDob } from "util/getAgeFromDob";
import RelationshipsHeader from "themes/projectdx/ThemedScreening/RelationshipsHeader";
import { AssessmentOptions } from "pages/report/AssessmentOptions";
import ConfidenceMenu from "components/molecules/ConfidenceMenu";
import { formatDate } from "util/common";

import { getAssociateScreeningRiskSummary } from "./utils";
import S from "./styles";

const SanctionedEntityCardContent = ({
  isRiskViaRelativesOrCloseAssociates,
  isCloseAssociate,
  entity,
  entityType,
  renderFlagHierarchyPills = () => {},
  originalSetConfidence,
  currentSetConfidence,
  onConfidenceChange,
  userAssessmentId,
  userAssessment,
  isListResult
}) => {
  const hasRCARisk =
    isRiskViaRelativesOrCloseAssociates &&
    (entity?.specialInterestAssociatesCount ||
      entity?.sanctionedAssociatesCount ||
      entity?.pepAssociatesCount ||
      entity?.watchlistAssociatesCount);

  // Weird special case for one associate that is on a watchlist (or multiple)
  // This is so we can cover the plural and singular form of being "watchlisted"
  let watchlistCountForSingleAssociate = 0;
  if (entity?.associatedEntities?.length === 1) {
    const watchlists = entity.associatedEntities[0]?.listReferences?.find(
      list => list.listGroup?.toLowerCase() === "watchlist"
    );

    watchlistCountForSingleAssociate = watchlists?.entries?.length;
  }

  const renderEntityInfo = () => {
    let birthDeath;
    let altNames;
    let prevNames;

    const akasTitle =
      entity.alternativeNames?.allNames?.length > 1 ? "AKAs: " : "AKA: ";

    const previousNamesTitle =
      entity.previousNames?.allNames?.length > 1
        ? "Previous names: "
        : "Previous name: ";

    if (entityType === ENTITY_TYPE.Person) {
      if (entity?.isDeceased) {
        birthDeath = <S.DeceasedLabel>Deceased</S.DeceasedLabel>;
      } else if (entity?.birthDate?.length) {
        birthDeath = (
          <S.BirthDateField>
            Age:{" "}
            <S.Age>
              {getAgeFromDob(
                new Date(
                  entity.birthDate[0]?.year,
                  (entity.birthDate[0]?.month ?? 0) - 1,
                  entity.birthDate[0]?.day
                )
              )}{" "}
            </S.Age>
            <S.Date>{formatDate(entity.birthDate[0])}</S.Date>
          </S.BirthDateField>
        );
      }
    }

    const renderMatch = (match, title) => {
      return (
        <div>
          <S.Akas>
            <S.SubTitle>{title}</S.SubTitle>
            <b>{match.namesMatchingSubject[0]}</b>{" "}
            {match.allNames?.length > 1 ? (
              <S.AdditionalCount>
                {" "}
                +{match.allNames.length - 1}
              </S.AdditionalCount>
            ) : null}
          </S.Akas>
        </div>
      );
    };

    const { previousNames, alternativeNames: akas } = entity;
    if (akas?.namesMatchingSubject?.length) {
      altNames = renderMatch(akas, akasTitle);
    }
    if (previousNames?.namesMatchingSubject?.length) {
      prevNames = renderMatch(previousNames, previousNamesTitle);
    }

    if (!altNames && !prevNames && !birthDeath && !entity.isSubsidiary) {
      return null;
    }

    return (
      <S.AkasAndAgeField>
        {entity.isSubsidiary ? (
          <S.SubsidiaryField>Subsidiary</S.SubsidiaryField>
        ) : null}
        {altNames}
        {prevNames}
        {birthDeath}
      </S.AkasAndAgeField>
    );
  };

  return (
    <div>
      <S.TopRow>
        <S.NameField
          class={`confidence-opacity__${
            originalSetConfidence !== currentSetConfidence
              ? "transparent"
              : "opaque"
          }`}
        >
          {entity.entityName}
        </S.NameField>
        {isListResult && (
          <ConfidenceMenu
            originalSetConfidence={originalSetConfidence}
            currentSetConfidence={currentSetConfidence}
            onConfidenceChange={onConfidenceChange}
            itemIds={userAssessmentId}
            confidenceHasBeenSetOnRegen={
              (userAssessment ?? AssessmentOptions.NoUserAssessment) !==
              AssessmentOptions.NoUserAssessment
            }
          />
        )}
      </S.TopRow>
      <div
        className={`confidence-opacity__${
          originalSetConfidence !== currentSetConfidence
            ? "transparent"
            : "opaque"
        }`}
      >
        {isCloseAssociate ? (
          <>
            <S.RelationshipType>{entity.relationship}</S.RelationshipType>
            <S.Rule />
          </>
        ) : null}
        {entity?.entityLocation?.countryName ||
        entity?.birthplace?.[0]?.countryName ||
        entity?.citizenship?.[0]?.countryName ? (
          <S.CountryField>
            <S.CustomFlag
              code={
                entity?.entityLocation?.countryCode ??
                entity?.birthplace?.[0]?.countryCode ??
                entity?.citizenship?.[0]?.countryCode
              }
              showCodeString={false}
            />
            <S.CountryName>
              {entity.entityLocation?.countryName ??
                entity?.birthplace?.[0]?.countryName ??
                entity?.citizenship?.[0]?.countryName}
            </S.CountryName>
          </S.CountryField>
        ) : null}
        {renderEntityInfo()}
        {hasRCARisk ? (
          <S.AssociateRiskCounts>
            <S.AssociatesWithRiskLabel>
              {getAssociateScreeningRiskSummary({
                totalAssociatedEntities: entity.associatedEntities?.length,
                sanctionedAssociatesCount: entity?.sanctionedAssociatesCount,
                pepAssociatesCount: entity?.pepAssociatesCount,
                watchlistAssociatesCount: entity?.watchlistAssociatesCount,
                specialInterestAssociatesCount:
                  entity?.specialInterestAssociatesCount,
                watchlistCountForSingleAssociate
              })}
            </S.AssociatesWithRiskLabel>
          </S.AssociateRiskCounts>
        ) : (
          renderFlagHierarchyPills({
            entityFlags: entity?.flags,
            isCloseAssociate,
            entityType: entity.entityType?.toLowerCase()
          })
        )}
        {entity.associatedEntities?.length ? <S.DividingLine /> : null}
        <RelationshipsHeader entities={entity.associatedEntities} />
      </div>
    </div>
  );
};

export default SanctionedEntityCardContent;
