import { useState, useEffect, useMemo } from "react";

import { SearchItemType, SearchResult } from "api/search";
import useDebounce from "util/hooks/useDebounce";
import Groups from "api/groups";
import Users from "api/users";
import {
  Idam_Contracts_Organisations_GetOrganisationGroupsResponse,
  Idam_Contracts_Users_GetUserGroupsResponse,
  Idam_Contracts_Tenants_OrganisationUser
} from "api/portal";

interface Props {
  usersOnly?: boolean;
  groupsOnly?: boolean;
}

const useUserAndGroupSearch = ({
  usersOnly = false,
  groupsOnly = false
}: Props) => {
  const [allUsers, setAllUsers] = useState<
    undefined | Idam_Contracts_Tenants_OrganisationUser[]
  >(undefined);
  const [allOrganisationGroups, setAllOrganisationGroups] = useState<
    undefined | Idam_Contracts_Organisations_GetOrganisationGroupsResponse[]
  >(undefined);
  const [allUserGroups, setAllUserGroups] = useState<
    undefined | Idam_Contracts_Users_GetUserGroupsResponse[]
  >(undefined);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(true);
  const [suggestions, setSuggestions] = useState<SearchResult[]>([]);
  const { debounce } = useDebounce();

  const UsersApi = useMemo(() => new Users(), []);
  const GroupsApi = useMemo(() => new Groups(), []);

  if (usersOnly && groupsOnly) {
    throw new Error("Either usersOnly or groupsOnly must be false");
  }

  useEffect(() => {
    if (allUsers === undefined && !groupsOnly) {
      UsersApi.getAllUsers()
        .then(users => {
          setAllUsers(users);
        })
        .catch(() => setAllUsers([]));
    }
  }, [UsersApi, allUsers, groupsOnly]);

  useEffect(() => {
    if (allOrganisationGroups === undefined && !usersOnly) {
      GroupsApi.getAllOrganisationGroups()
        .then(groups => {
          setAllOrganisationGroups(groups);
        })
        .catch(() => setAllOrganisationGroups([]));
    }
  }, [GroupsApi, allOrganisationGroups, usersOnly]);

  useEffect(() => {
    if (allUserGroups === undefined && !usersOnly) {
      GroupsApi.getAllUserGroups()
        .then(groups => {
          setAllUserGroups(groups);
        })
        .catch(() => {
          setAllUserGroups([]);
        });
    }
  }, [GroupsApi, allUserGroups, usersOnly]);

  const debounceQuerySearch = debounce(async (query: string) => {
    setIsFetchingSuggestions(true);
    const userResults: SearchResult[] = !groupsOnly
      ? await UsersApi.getSearchSuggestions({ query, users: allUsers })
      : [];
    const groupResults: SearchResult[] = !usersOnly
      ? await GroupsApi.getSearchSuggestions({
          query,
          userGroups: allUserGroups,
          organisationGroups: allOrganisationGroups
        })
      : [];

    setSuggestions([
      ...userResults.map(user => ({ ...user, queryType: SearchItemType.User })),
      ...groupResults.map(group => ({
        ...group,
        queryType: SearchItemType.Group
      }))
    ]);
    setIsFetchingSuggestions(false);
  }, 300);

  const onSearchUpdate = (query: string) => {
    debounceQuerySearch(query);
  };

  return { suggestions, isFetchingSuggestions, onSearchUpdate };
};

export default useUserAndGroupSearch;
