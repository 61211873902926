import React, { FC } from "react";

import type { Article } from "api/articles";

import ArticleContent from "./ArticleContent";
import ArticleError from "./ArticleError";
import LoadingArticle from "./LoadingArticle";

import S from "./styles";

interface Props {
  errorMessage?: string;
  article?: Article;
  articleId?: string;
}

const FactivaArticleViewer: FC<Props> = ({
  articleId,
  article,
  errorMessage
}) => {
  return (
    <S.Main>
      <S.Viewer>
        <S.Header>
          <S.HeaderTitle>Dow Jones Factiva</S.HeaderTitle>
          <S.HeaderSubtitle>Article Viewer</S.HeaderSubtitle>
        </S.Header>

        {errorMessage ? (
          <ArticleError articleId={articleId} message={errorMessage} />
        ) : article ? (
          <ArticleContent {...article} />
        ) : (
          <LoadingArticle />
        )}
      </S.Viewer>
    </S.Main>
  );
};

export default FactivaArticleViewer;
