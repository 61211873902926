import { styled } from "@linaria/react";
import { CardVariant } from "components/molecules/Card/types";

import { grey, blue, standardColors } from "styles/colors";

const Card = styled.article<{ variant: CardVariant }>`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.75rem;
  width: 20.75rem;
  background-color: ${({ variant }) =>
    variant === CardVariant.dark ? blue.panel : grey.panel};
  padding: 1rem;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? standardColors.white : blue.xapienDark};
  border-radius: 0.375rem;
  justify-content: space-between;
`;

const ControlContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

const Skeleton = styled.article`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 0.75rem;
  width: 21rem;
  padding: 1rem;
`;

const S = {
  Card,
  ControlContainer,
  Skeleton
};

export default S;
