import React, { FC } from "react";

import { AnimatePresence } from "framer-motion";

import useInsightsAma from "util/hooks/useInsightsAma";
import InsightsMessage from "components/organisms/InsightsChat/InsightsMessage";

import S from "./styles";

interface Props {
  sectionId: string;
}

const InsightsMessages: FC<Props> = ({ sectionId }) => {
  const { getMessages } = useInsightsAma();

  const messages = getMessages(sectionId);

  if (messages.length === 0) {
    return null;
  }

  return (
    <S.Container>
      <AnimatePresence>
        {messages.map(message => (
          <S.MessageContainer key={message.id}>
            <InsightsMessage message={message} />
          </S.MessageContainer>
        ))}
      </AnimatePresence>
    </S.Container>
  );
};

export default InsightsMessages;
