import { styled } from "@linaria/react";

import { DiImage } from "components/molecules/DiImage";

import { fontSize, family } from "styles/fonts";
import { grey } from "styles/colors";

import { ReactComponent as SourceLinkIconSvg } from "img/source-link-icon.svg";
import { ReactComponent as FactivaLogoSvg } from "img/factiva-logo.svg";

// @ts-ignore
const SourceImage = styled(DiImage)`
  object-fit: cover;
  height: 60px;
  width: 60px;
  border-radius: 8px;
  margin-top: 10px;
`;

const TitleContainer = styled.div`
  font-size: ${fontSize.md};
  font-family: ${family.interSemiBold};

  a {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const SourceLinkIcon = styled(SourceLinkIconSvg)`
  margin-right: 0.25rem;
`;

const PublicationDate = styled.div`
  color: ${grey.mid};
`;

const ProviderText = styled.div`
  color: ${grey.dark};

  a {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const FactivaLogo = styled(FactivaLogoSvg)`
  height: 38px;
  width: 25px;
`;

const FactivaLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  min-width: 64px;
  min-height: 64px;
`;

const SmallText = styled.span`
  font-size: ${fontSize.xs};
`;

const S = {
  SourceImage,
  TitleContainer,
  SourceLinkIcon,
  PublicationDate,
  ProviderText,
  FactivaLogoContainer,
  FactivaLogo,
  SmallText
};

export default S;
