import { styled } from "@linaria/react";

import { hexToRgb } from "styles/hexToRgb";
import { standardColors, grey, blue, green } from "styles/colors";
import { ReactComponent as ErrorIcon } from "img/error-potion.svg";
import ButtonNew from "components/atoms/ButtonNew";

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;

  p {
    margin: 0;
    text-align: center;
  }

  a {
    color: ${blue.icon};
  }
`;

const ErrorLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(
    ${() => {
      const { r, g, b } = hexToRgb(green.xapienGreen);
      return `${r}, ${g}, ${b}`;
    }},
    0.15
  );
  width: 100px;
  height: 100px;
`;

const ErrorLogo = styled(ErrorIcon)`
  width: 56px;
  height: 35px;
`;

const RegenerateButton = styled(ButtonNew)`
  margin-top: 1rem;
  color: ${grey.dark} !important;
  border-color: ${grey.dark} !important;
  svg path {
    stroke: ${grey.dark} !important;
  }
  &:hover {
    color: ${standardColors.white} !important;
    svg path {
      stroke: ${standardColors.white} !important;
    }
  }
`;

const S = {
  RegenerateButton,
  ErrorLogo,
  ErrorLogoContainer,
  ErrorContainer
};

export default S;
