import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";
import { grey, standardColors, blue } from "styles/colors";
import { hexToRgb } from "styles/hexToRgb";

import { DropdownItem } from "reactstrap";

const ConfidencebucketCount = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.dark} !important;
  padding-right: 4px;
`;

const TooltipContent = styled.div<{ width: string }>`
  text-align: left;
  padding: 2px;
  width: ${props => props.width ?? 275}px;
  max-width: 275px;
  max-height: 325px;
  overflow-y: auto;
`;

const OptionsDropdownMenuItem = styled(DropdownItem)`
  padding: 8px;
  color: ${grey.dark};
  font-size: ${fontSize.sm} !important;
  font-family: ${family.interRegular};
  font-weight: 200;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover,
  &:focus {
    background-color: ${() => {
      const { r, g, b } = hexToRgb(blue.icon);
      return `rgba(${r}, ${g}, ${b}, 0.15)`;
    }};
  }
  &:disabled {
    opacity: 0.3;
    color: ${standardColors.white};
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: ${grey.dark};
  }
`;

const DisabledOptionsDropdownMenuItem = styled(OptionsDropdownMenuItem)`
  opacity: 0.3;
`;

const S = {
  ConfidencebucketCount,
  TooltipContent,
  DisabledOptionsDropdownMenuItem,
  OptionsDropdownMenuItem
};

export default S;
