import { useMemo } from "react";

import useFetchReducer, { RequestActions } from "util/hooks/useFetchReducer";
import ReportsApi from "api/reports";

const useUpdateMonitoring = () => {
  const api = useMemo(() => new ReportsApi(), []);
  const [{ fetching, error, errorMessage, success }, dispatch] =
    useFetchReducer();

  const changeMonitoringPermission = async (
    reportId: string,
    adverseMedia: boolean,
    sanctions: boolean
  ): Promise<boolean> => {
    dispatch({ type: RequestActions.SendRequest });

    const { status, message } = await api.changeMonitoringPermission({
      reportId,
      adverseMedia,
      sanctions
    });

    if (!status) {
      dispatch({ type: RequestActions.SetError, errorMessage: message });
      return false;
    }

    dispatch({ type: RequestActions.SetSuccess });
    return true;
  };

  const reset = () => {
    setTimeout(() => {
      dispatch({ type: RequestActions.Reset });
    }, 1000);
  };

  const resetError = () => {
    dispatch({ type: RequestActions.ResetError });
  };

  return {
    fetching,
    error,
    success,
    errorMessage,
    resetError,
    reset,
    changeMonitoringPermission
  };
};

export default useUpdateMonitoring;
