import { styled } from "@linaria/react";
import { blue } from "styles/colors";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.75rem;
  background-color: ${blue.xapienDark};
  padding: 0.75rem 1rem;
`;

const S = {
  Section
};

export default S;
