import React, {
  CSSProperties,
  FC,
  SyntheticEvent,
  ReactNode,
  useCallback,
  useRef,
  useEffect,
  MutableRefObject
} from "react";
import { Autocomplete, AutocompleteRenderInputParams } from "@mui/material";

import type { SearchResult } from "api/search";
import Popper from "./Popper";
import InputTextField from "./InputTextField";
import InputOption from "./InputOption";
import InputTags from "./InputTags";
import S from "./styles";

const styles = { width: "100%" };

export interface Props {
  query: string;
  options: SearchResult[];
  searchTags: SearchResult[];
  placeholder?: string;
  onInputChange: (query: string) => void;
  onChange: (searchTags: SearchResult[]) => void;
  onFocus?: () => void;
  renderInput?: (
    placeholder: string | undefined,
    params: AutocompleteRenderInputParams,
    ref: MutableRefObject<HTMLElement | undefined>
  ) => ReactNode;
  className?: string;
  style?: CSSProperties;
  onSubmit: () => void;
  onClear: () => void;
  disabledSuggestions?: boolean;
  loadingText?: string;
  disabled?: boolean;
}

const SearchInput: FC<Props> = ({
  query,
  options = [],
  searchTags,
  placeholder,
  onInputChange,
  onChange,
  onFocus,
  renderInput,
  className,
  style,
  onSubmit,
  onClear,
  disabledSuggestions,
  loadingText = "Loading...",
  disabled
}) => {
  const ref = useRef<HTMLElement>();

  const handleOnInputChange = (event: SyntheticEvent, value: string) => {
    onInputChange(value);
  };
  const handleOnChange = (event: SyntheticEvent, value: SearchResult[]) => {
    onChange(value);
  };
  const handleOnSubmit = () =>
    query && query.trim().length ? onSubmit() : onClear();

  const handleOnClear = useCallback(() => {
    onInputChange("");
    onClear();
  }, [onInputChange, onClear]);

  const queryNotEmpty = !!(query && query.trim().length);
  const searchTagsNotEmpty = !!(searchTags && searchTags.length);
  const showClearButton = queryNotEmpty || searchTagsNotEmpty;

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute("data-lpignore", "true");
      ref.current.id += " report-search";
    }
  }, [ref]);

  return (
    <S.InputContainer className={className} style={style}>
      <Autocomplete
        loading
        loadingText={loadingText}
        inputValue={query}
        open={!disabledSuggestions && query?.length > 0}
        filterOptions={x => x}
        onChange={handleOnChange}
        onInputChange={handleOnInputChange}
        onFocus={onFocus}
        multiple
        options={options}
        value={searchTags}
        getOptionLabel={option => option.id}
        PopperComponent={Popper}
        renderOption={InputOption}
        renderTags={InputTags}
        renderInput={params =>
          renderInput ? (
            renderInput(placeholder, params, ref)
          ) : (
            <InputTextField
              placeholder={placeholder}
              onSubmit={handleOnSubmit}
              onClear={handleOnClear}
              showClearButton={showClearButton}
              inputRef={ref}
              {...params}
            />
          )
        }
        sx={styles}
        clearOnBlur={false}
        disabled={disabled}
      />
    </S.InputContainer>
  );
};

export default SearchInput;
