import { CategoryHierarchy } from "api/report/report-types";

export const findParentOfLowerThreshold = (
  categoryHierarchy?: CategoryHierarchy[]
): CategoryHierarchy[] => {
  if (!categoryHierarchy) {
    return [];
  }

  return categoryHierarchy
    .flatMap(data => {
      if (
        data.subContentTypes.some(sub => sub.description === "Lower Threshold")
      ) {
        return [data];
      }
      if (data.subContentTypes.length > 0) {
        const result = findParentOfLowerThreshold(data.subContentTypes);
        if (result) {
          return result;
        }
      }
      return [];
    })
    .filter(Boolean);
};
