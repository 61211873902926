import { useContext } from "react";
import InsightsFeatureContext from "./context";
import InsightsFeatureProvider from "./provider";

const useInsightsFeatures = () => {
  const value = useContext(InsightsFeatureContext);

  return value;
};

export default useInsightsFeatures;
export { InsightsFeatureProvider };
