import { styled } from "@linaria/react";
// @ts-ignore
import theme from "theme";

import { blue, standardColors } from "styles/colors";
import ButtonNew from "components/atoms/ButtonNew";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
`;

const Link = styled.div`
  color: ${theme.alternativePrimaryColor ?? blue.blueDiamond};
  text-decoration: none;
  cursor: pointer;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  width: 300px;

  input {
    height: 40px;
  }
`;

const RequestAccessButton = styled(ButtonNew)`
  background: ${theme.alternativePrimaryColor ?? blue.xapienLight};
  color: ${standardColors.white};
  height: 30px;
  width: 200px;
`;

const S = {
  RequestAccessButton,
  Container,
  Inputs,
  Link
};

export default S;
