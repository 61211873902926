import React from "react";

import Heading from "components/atoms/Heading";
import ModalContainer from "components/molecules/ModalContainer";
import { useUserOrganisation } from "util/hooks/useUserOrganisation";
import TitleCard from "components/molecules/TitleCard";
import { Idam_Contracts_Enums_OrganisationRole } from "api/portal";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import TitleCardLoadingSkeleton from "components/molecules/TitleCard/TitleCardLoadingSkeleton";

import S from "./styles";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
}

const ContactAdminModal = ({ isOpen, toggleOpen }: Props) => {
  const { details, users } = useUserOrganisation();

  const admins = users.filter(
    user => user.role === Idam_Contracts_Enums_OrganisationRole.ADMIN
  );

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onExitClick={toggleOpen}
    >
      <Heading level={4}>Contact an admin</Heading>
      <S.ModalContent>
        The {"name" in details ? details.name : "organisation's"} account
        administrators are
      </S.ModalContent>
      <S.Admins>
        {admins.length === 0 && <TitleCardLoadingSkeleton />}
        {admins.map(admin => (
          <S.TitleCardContainer
            href={`mailto:${admin.email}`}
            key={admin.userId}
          >
            <TitleCard
              key={admin.userId}
              title={`${admin.firstName ?? ""} ${admin.lastName ?? ""}`}
              subtitle={admin.email}
            />
            <S.MailIcon />
          </S.TitleCardContainer>
        ))}
      </S.Admins>
      <ButtonNew
        text="Done"
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={toggleOpen}
      />
    </ModalContainer>
  );
};

export default ContactAdminModal;
