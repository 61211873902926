import type { TimelineEvent } from "api/insights";

export enum InsightsTimelineActions {
  fetchTimeline = "fetchTimeline",
  updateTimeline = "updateTimeline",
  errorFetchingTimeline = "errorFetchingTimeline",
  errorDeletingTimelineEvent = "errorDeletingTimelineEvent",
  deleteTimeLineEvent = "deleteTimeLineEvent",
  deleteTimelineEventSuccess = "deleteTimelineEventSuccess"
}

export enum InsightsTimelineStatus {
  fetching = "fetching",
  idle = "idle",
  error = "error",
  deletingEvent = "deletingEvent",
  deletingEventSuccess = "deletingEventSuccess",
  noTimeline = "noTimeline"
}

interface FetchTimelineAction {
  type: InsightsTimelineActions.fetchTimeline;
}

interface ErrorFetchingTimelineAction {
  type: InsightsTimelineActions.errorFetchingTimeline;
  errorMessage?: string;
}

interface ErrorDeletingTimelineEventAction {
  type: InsightsTimelineActions.errorDeletingTimelineEvent;
  errorMessage?: string;
}

interface UpdateTimelineAction {
  type: InsightsTimelineActions.updateTimeline;
  timeline?: TimelineEvent[];
}

interface DeleteTimeLineEventAction {
  type: InsightsTimelineActions.deleteTimeLineEvent;
  eventId: string;
}

interface DeleteTimelineEventSuccessAction {
  type: InsightsTimelineActions.deleteTimelineEventSuccess;
}

export type InsightsTimelineAction =
  | UpdateTimelineAction
  | FetchTimelineAction
  | ErrorDeletingTimelineEventAction
  | DeleteTimeLineEventAction
  | DeleteTimelineEventSuccessAction
  | ErrorFetchingTimelineAction;

export interface InsightsTimelineState {
  errorMessage?: string;
  timeline?: TimelineEvent[];
  status: InsightsTimelineStatus;
  eventId?: string;
}
