import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import BaseHeading from "components/atoms/Heading";

import { standardColors, grey } from "styles/colors";
import { fontSize, family, lineHeight } from "styles/fonts";

// @ts-ignore
import theme from "theme";

const Main = styled.main`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  color: ${grey.dark};
  font-size: ${fontSize.sm};
`;

const Viewer = styled.div`
  background: ${standardColors.white};
  width: 1024px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 640px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LoadingContainer = styled.div`
  width: 640px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .MuiSkeleton-text {
    transform: none;
  }
`;

const LoadingSection = styled.div`
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  width: 100%;
  background: ${theme.primaryColor};
  border-radius: 12px 12px 0 0;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${fontSize.lg};
`;

const HeaderTitle = styled.span`
  color: ${standardColors.white};
`;

const HeaderSubtitle = styled.span`
  color: ${standardColors.white};
  opacity: 0.7;
`;

const CopyrightContainer = styled.div`
  border-top: 1px solid ${grey.rule};
  border-bottom: 1px solid ${grey.rule};
  padding: 12px 0;
  font-size: ${fontSize.xs};
  color: ${grey.mid};
`;

const BodyContainer = styled.div`
  font-family: ${family.interLight};

  a,
  a:visited {
    color: ${theme.primaryColor};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Paragraph = styled.div`
  margin-bottom: 1rem;
`;

const InfoContainer = styled.div`
  border-top: 1px solid ${grey.rule};
  border-bottom: 1px solid ${grey.rule};
  padding: 12px 0;
`;

const ProviderAndDate = styled.div`
  color: ${grey.mid};
`;

const Author = styled.div`
  font-family: ${family.interSemiBold};
`;

const TimeToRead = styled.div`
  font-size: ${fontSize.xs};
  color: ${grey.mid};
`;

const Heading = styled(BaseHeading)`
  line-height: ${lineHeight.sm};
`;

const sourceLinkPopover = css`
  display: inline;
`;

const S = {
  Main,
  Viewer,
  Header,
  HeaderTitle,
  HeaderSubtitle,
  Content,
  LoadingContainer,
  LoadingSection,
  CopyrightContainer,
  BodyContainer,
  InfoContainer,
  ProviderAndDate,
  Author,
  TimeToRead,
  Heading,
  Paragraph
};

export const classNameOverrides = {
  sourceLinkPopover
};

export default S;
