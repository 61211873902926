/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CacheContents } from "../models/CacheContents";
import type { FeAnswer } from "../models/FeAnswer";
import type { FeEnquiry } from "../models/FeEnquiry";
import type { FeOrgReport } from "../models/FeOrgReport";
import type { FePersonReport } from "../models/FePersonReport";
import type { FeSimpleAnswer } from "../models/FeSimpleAnswer";
import type { SubjectType } from "../models/SubjectType";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class DefaultService {
  /**
   * Get Root
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getRootGet({
    cacheBust
  }: {
    cacheBust?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/",
      query: {
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Get Enquiries
   * @returns CacheContents Successful Response
   * @throws ApiError
   */
  public static getEnquiriesEnquiryGet({
    cacheBust
  }: {
    cacheBust?: any;
  }): CancelablePromise<CacheContents> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/enquiry/",
      query: {
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Import Enquiry
   * @returns FeEnquiry Successful Response
   * @throws ApiError
   */
  public static importEnquiryEnquiryImportFromS3Post({
    name,
    enquiryId,
    cacheBust
  }: {
    name: string;
    enquiryId: string;
    cacheBust?: any;
  }): CancelablePromise<FeEnquiry> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/enquiry/import-from-s3/",
      query: {
        name: name,
        enquiry_id: enquiryId,
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Enquiry Check Imported
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static enquiryCheckImportedEnquiryEnquiryIdCheckImportedGet({
    enquiryId,
    cacheBust
  }: {
    enquiryId: string;
    cacheBust?: any;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/enquiry/{enquiry_id}/check_imported",
      path: {
        enquiry_id: enquiryId
      },
      query: {
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Ask Question
   * @returns FeSimpleAnswer Successful Response
   * @throws ApiError
   */
  public static askQuestionEnquiryEnquiryIdAskQuestionPost({
    enquiryId,
    question,
    subjectName,
    userName,
    organisationName,
    reportUrl,
    cacheBust
  }: {
    enquiryId: string;
    question: string;
    subjectName: string;
    userName?: string;
    organisationName?: string;
    reportUrl?: string;
    cacheBust?: any;
  }): CancelablePromise<FeSimpleAnswer> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/enquiry/{enquiry_id}/ask-question/",
      path: {
        enquiry_id: enquiryId
      },
      query: {
        question: question,
        subject_name: subjectName,
        user_name: userName,
        organisation_name: organisationName,
        report_url: reportUrl,
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Ask Sourced Question
   * @returns FeAnswer Successful Response
   * @throws ApiError
   */
  public static askSourcedQuestionEnquiryEnquiryIdAskSourcedQuestionPost({
    enquiryId,
    question,
    subjectName,
    userName,
    organisationName,
    reportUrl,
    cacheBust
  }: {
    enquiryId: string;
    question: string;
    subjectName: string;
    userName?: string;
    organisationName?: string;
    reportUrl?: string;
    cacheBust?: any;
  }): CancelablePromise<FeAnswer> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/enquiry/{enquiry_id}/ask-sourced-question/",
      path: {
        enquiry_id: enquiryId
      },
      query: {
        question: question,
        subject_name: subjectName,
        user_name: userName,
        organisation_name: organisationName,
        report_url: reportUrl,
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Fetch Insight Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static fetchInsightReportEnquiryEnquiryIdInsightReportGet({
    enquiryId,
    subjectType,
    cacheBust
  }: {
    enquiryId: string;
    subjectType: SubjectType;
    cacheBust?: any;
  }): CancelablePromise<FePersonReport | FeOrgReport> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/enquiry/{enquiry_id}/insight-report/",
      path: {
        enquiry_id: enquiryId
      },
      query: {
        subject_type: subjectType,
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Generate Insight Report
   * @returns any Successful Response
   * @throws ApiError
   */
  public static generateInsightReportEnquiryEnquiryIdInsightReportPost({
    enquiryId,
    subjectName,
    withSourcing = false,
    subjectType,
    cacheBust
  }: {
    enquiryId: string;
    subjectName: string;
    withSourcing?: boolean;
    subjectType?: SubjectType;
    cacheBust?: any;
  }): CancelablePromise<FePersonReport | FeOrgReport> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/enquiry/{enquiry_id}/insight-report/",
      path: {
        enquiry_id: enquiryId
      },
      query: {
        subject_name: subjectName,
        with_sourcing: withSourcing,
        subject_type: subjectType,
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }

  /**
   * Add Sourcing To Answer
   * @returns FeAnswer Successful Response
   * @throws ApiError
   */
  public static addSourcingToAnswerEnquiryEnquiryIdAnswerAnswerIdPost({
    enquiryId,
    answerId,
    subjectName,
    cacheBust
  }: {
    enquiryId: string;
    answerId: string;
    subjectName: string;
    cacheBust?: any;
  }): CancelablePromise<FeAnswer> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/enquiry/{enquiry_id}/answer/{answer_id}/",
      path: {
        enquiry_id: enquiryId,
        answer_id: answerId
      },
      query: {
        subject_name: subjectName,
        cache_bust: cacheBust
      },
      errors: {
        422: `Validation Error`
      }
    });
  }
}
