import React, { useContext } from "react";
import { Features, useFeatureEnabled } from "services/features";
import { isPDX } from "static-config";
import { DiagnosticsModeContext } from "util/context/DiagnosticsModeContext";
import { SourceSection } from "./SourceSection";

const NotProcessedSection = React.forwardRef((props, ref) => {
  const diagnosticsModeEnabled = useContext(DiagnosticsModeContext).enabled;
  const notProcessedFeature = useFeatureEnabled(Features.NotProcessedSection);

  if (isPDX && !diagnosticsModeEnabled) {
    return null;
  }

  return notProcessedFeature ? (
    <SourceSection
      {...props}
      ref={ref}
      isDisregarded
      isNotProcessed
      description="Information that appeared in searches but which Xapien could not find any clear ties to your subject."
    />
  ) : null;
});

export default NotProcessedSection;
