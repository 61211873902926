import { styled } from "@linaria/react";
import ButtonNew from "components/atoms/ButtonNew";
import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const CreateOrganisation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  /* 85px = height of nav bar */
  height: calc(100vh - 85px);
  justify-content: space-between;
`;

const CreateOrganisationInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 20px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Rule = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${grey.faint};
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InputExplainer = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
  color: ${grey.ghost};
  padding-top: 0.25rem;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  width: 100%;
`;

const Button = styled(ButtonNew)`
  min-width: 12.5rem;
`;

const S = {
  CreateOrganisation,
  CreateOrganisationInputs,
  Section,
  Rule,
  InputGroup,
  InputExplainer,
  Controls,
  Button
};

export default S;
