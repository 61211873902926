import { styled } from "@linaria/react";

import Heading from "components/atoms/Heading";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled(Heading)`
  color: ${standardColors.white};
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  button {
    flex: 1;
  }
`;

const Divider = styled.hr`
  border-top: 1px solid ${standardColors.white};
  marign: 0;
`;

const Subtext = styled.div`
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const S = {
  Container,
  Title,
  Buttons,
  Divider,
  Subtext
};

export default S;
