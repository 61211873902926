import { createContext, useContext } from "react";
import { reportStore } from "state/Store";

const ReportStoreContext = createContext({});

const ReportStoreContextProvider = ({ children }) => {
  return (
    <ReportStoreContext.Provider value={reportStore}>
      {children}
    </ReportStoreContext.Provider>
  );
};

const useReports = () => {
  const context = useContext(ReportStoreContext);

  if (!context) {
    throw new Error(
      "useReports must be used within a ReportStoreContextProvider"
    );
  }
  return context;
};

export { ReportStoreContext, ReportStoreContextProvider, useReports };
