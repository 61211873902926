import { useContext } from "react";
import PdxDiagnosticsModeContext from "./context";
import PdxDiagnosticsModeProvider from "./provider";

const usePdxDiagnosticsMode = () => {
  const value = useContext(PdxDiagnosticsModeContext);

  return value;
};

export default usePdxDiagnosticsMode;
export { PdxDiagnosticsModeProvider };
