import { styled } from "@linaria/react";
import { standardColors } from "styles/colors";
import { family, fontSize } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

const BarsContainer = styled.div<{
  isBarNotSelected: boolean;
  hasFixedHeight: boolean;
}>`
  width: 100%;
  font-size: ${fontSize.sm};
  margin-right: 5px;
  opacity: ${props => (props.isBarNotSelected ? 0.3 : 1)};
  transition: opacity 0.2s;
  height: ${props => (props.hasFixedHeight ? "120px" : "auto")};
  padding-bottom: 6px;
`;
const BarInfoContainer = styled.div`
  padding: 5px 4px 0;
`;

const BarInfo = styled.div<{
  shouldWrap: boolean;
  rightAlignLastPill: boolean;
}>`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-bottom: 10px;
  flex-flow: ${props => (props.shouldWrap ? "wrap" : "initial")};
  justify-content: ${props =>
    props.rightAlignLastPill ? "space-between" : "flex-start"};
`;
const BarTitle = styled.div`
  font-family: ${family.interSemiBold};
  padding-bottom: 10px;
`;
const RemainingBarSegmentContainer = styled.div`
  flex-grow: 1;
`;

const BarSegment = styled.div<{
  background: string;
  isAnyBarHovered: boolean;
  isBarHovered: boolean;
  isActive: boolean;
  isBarSegmentObscured: boolean;
  isInteractable: boolean;
  opacity: string;
}>`
  margin-right: 0;
  height: 10px;
  background: ${({ background }) => background ?? "initial"};
  opacity: ${({
    isAnyBarHovered,
    isBarHovered,
    isActive,
    isBarSegmentObscured,
    opacity
  }) =>
    (isAnyBarHovered && !isBarHovered && !isActive) || isBarSegmentObscured
      ? 0.4
      : opacity ?? 1};
  transition: opacity 0.2s;
  &:hover {
    cursor: ${({ isInteractable }) => (isInteractable ? "pointer" : "default")};
  }
`;

const BarSegmentLabel = styled.div<{
  color: string;
  isAnyBarHovered: boolean;
  isCorrespondingBarHovered: boolean;
  isActive: boolean;
  isBarSegmentObscured: boolean;
  opacity: string;
  isCountZero: boolean;
  isInteractable: boolean;
  fillColor: string;
}>`
  text-align: left;
  color: ${({ color }) => color ?? "initial"};
  opacity: ${({
    isAnyBarHovered,
    isCorrespondingBarHovered,
    isActive,
    isBarSegmentObscured,
    opacity
  }) =>
    (isAnyBarHovered && !isCorrespondingBarHovered && !isActive) ||
    isBarSegmentObscured
      ? 0.4
      : opacity ?? 1};
  padding: 0px 8px;
  border: 1px solid ${({ color }) => color ?? "initial"};
  border-radius: 16px;
  margin-right: 6px;
  margin-bottom: 6px;
  transition: box-shadow 0.2s, opacity 0.2s;
  font-size: ${fontSize.sm};
  background-color: ${({ isActive, fillColor }) =>
    isActive ? fillColor : "transparent"};
  color: ${({ isActive, color }) => (isActive ? standardColors.white : color)};
  box-shadow: ${({ isAnyBarHovered, isCorrespondingBarHovered, color }) => {
    if (isAnyBarHovered && isCorrespondingBarHovered) {
      const { r, g, b } = hexToRgb(color);
      return `0 0 0 4px rgba(${r}, ${g}, ${b}, 0.25)`;
    }
    return "none";
  }};
  &:hover {
    box-shadow: 0 0 0 4px
      rgba(
        ${({ isCountZero, isInteractable, color }) => {
          if (!isCountZero && isInteractable) {
            const { r, g, b } = hexToRgb(color);
            return `${r}, ${g}, ${b}`;
          }
          return "";
        }},
        0.25
      );
    cursor: ${({ isCountZero, isInteractable }) =>
      isCountZero || !isInteractable ? "default" : "pointer"};
  }
  &:last-child {
    margin-right: 0;
  }
`;

const BarSegmentContainer = styled.div<{
  barWidth: string | number;
  barMargin: string;
}>`
  width: ${({ barWidth }) => barWidth};
  margin-right: ${({ barMargin }) => barMargin};
`;

const NoBarDataLabel = styled.div<{ color: string }>`
  position: absolute;
  top: 10px;
  color: ${({ color }) => color};
  opacity: 0.6;
`;
const FirstBarLabels = styled.div`
  display: flex;
`;
const S = {
  BarsContainer,
  BarInfoContainer,
  BarInfo,
  BarTitle,
  RemainingBarSegmentContainer,
  BarSegment,
  BarSegmentLabel,
  BarSegmentContainer,
  NoBarDataLabel,
  FirstBarLabels
};

export default S;
