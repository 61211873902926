import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronRight } from "react-feather";

import {
  CollectionListActions,
  CollectionListContextProvider,
  useCollectionList
} from "util/hooks/useCollectionList";
import {
  CollectionInputType,
  CollectionStatus
} from "util/hooks/useCollectionList/types";

import { CollectionListView } from "components/molecules/CollectionListControls";
import CollectionNavigation from "components/molecules/CollectionNavigation";
import CollectionList from "components/organisms/CollectionList";
import { CardVariant } from "components/molecules/Card/types";
import UserCard from "components/organisms/UserCard";

import Api, { ORGANISATION_USERS_COLLECTION } from "api/hub/users";
import type { User } from "api/users/types";

import S from "./styles";

const CardComponent = ({
  item,
  view,
  loading,
  variant
}: {
  item?: User;
  view: CollectionListView;
  loading?: boolean;
  variant: CardVariant;
}) => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get("orgName") ?? "Organisation";

  const onClick = () => {
    if (!item) return;

    const path = `/hub/manage-organisation/${orgId}/users/${item.id}/reports?orgName=${orgName}&userName=${item.title}`;
    navigate(path);
  };

  return (
    <UserCard
      item={item}
      view={view}
      loading={loading}
      variant={variant}
      onClick={onClick}
    />
  );
};

const UsersListing = () => {
  const { state, dispatch } = useCollectionList();
  const { orgId } = useParams();
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get("orgName");
  const [showAdminsOnly, setShowAdminsOnly] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (orgId) {
      const collectionInputConfig = state.collections.find(
        collection => collection.id === ORGANISATION_USERS_COLLECTION
      );

      if (
        collectionInputConfig &&
        collectionInputConfig.status === CollectionStatus.stale &&
        collectionInputConfig.input.type === CollectionInputType.list &&
        collectionInputConfig.input?.filterByOrgId === undefined
      ) {
        setTimeout(
          () =>
            dispatch({
              type: CollectionListActions.updateCollectionInput,
              id: ORGANISATION_USERS_COLLECTION,
              input: {
                ...collectionInputConfig.input,
                filterByOrgId: orgId
              }
            }),
          100
        );
      }
    }
  }, [orgId, state.collections, dispatch]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <S.ViewContainer>
          <S.ViewInnerContainer>
            <S.Breadcrumbs>
              <S.Breadcrumb
                onClick={() => {
                  navigate(`/hub/manage-organisation/${orgId}`);
                }}
              >
                {orgName}
              </S.Breadcrumb>
              <ChevronRight />
              <S.Breadcrumb
                onClick={() => {
                  navigate(
                    `/hub/manage-organisation/${orgId}/users?orgName=${orgName}`
                  );
                }}
              >
                Users
              </S.Breadcrumb>
            </S.Breadcrumbs>
            <CollectionList
              filterItemsFn={
                showAdminsOnly
                  ? item => item.tags.some(tag => tag.name === "Admin")
                  : undefined
              }
              showCount
              cardVariant={CardVariant.dark}
              navigationComponent={
                <CollectionNavigation
                  items={[
                    {
                      title: "All users",
                      onClick: () => showAdminsOnly && setShowAdminsOnly(false),
                      isActive: !showAdminsOnly
                    },
                    {
                      title: "Admins",
                      onClick: () => !showAdminsOnly && setShowAdminsOnly(true),
                      isActive: showAdminsOnly
                    }
                  ]}
                />
              }
              CardComponent={CardComponent}
            />
          </S.ViewInnerContainer>
        </S.ViewContainer>
      </motion.div>
    </AnimatePresence>
  );
};

const Users = () => {
  return (
    <CollectionListContextProvider Api={Api}>
      <UsersListing />
    </CollectionListContextProvider>
  );
};

export default Users;
