import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { grey, purple, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

import ShowResultsButton from "pages/report/ShowResultsButton";
import { ReactComponent as InformationIconSvg } from "img/icons/information-icon.svg";
import { DiImage } from "components/molecules/DiImage";
import StickyExpandButton from "pages/report/StickyExpandButton";

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${grey.rule};
  background-color: ${standardColors.white};
  padding-bottom: 16px;
  align-items: center;
  height: 36px;
`;

const LocationsSectionContainer = styled.div`
  display: flex;
  padding: 16px 0;
  background-color: ${standardColors.white};
`;

const ResultsSection = styled.div`
  visibility: ${props => (props.isResultsShown ? "visible" : "hidden")};
  max-height: ${props => (props.isResultsShown ? "fit-content" : "0")};
`;

const MasonrySection = styled.div`
  max-height: ${props => {
    if (props.isResultsExpanded) {
      return "inherit";
    }

    if (props.isResultsShown) {
      return "700px";
    }

    return 0;
  }};
  overflow-y: auto;
`;

const SectionTotal = styled.h1`
  font-weight: inherit;
  margin: 0;
`;

const FilteredSectionCount = styled.div`
  font-size: ${fontSize.lg};
`;

const SectionCountAdditional = styled.div`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  text-align: right;
  padding-top: 2px;
`;

const Dates = styled.span`
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const FilterButtonsContainer = styled.div`
  display: flex;
  padding-left: 32px;
  padding-top: 8px;
  padding-bottom: 10px;
`;

const FilterByLabel = styled.span`
  margin-right: 4px;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const FiltersSection = styled.div`
  flex: 1;
`;

const ResetFiltersButton = styled.button`
  color: ${grey.mid};
  border: none;
  background: none;
  margin-left: 32px;
  padding: 0;
  border-bottom: 1px solid;
  border-color: transparent;
  font-size: ${fontSize.sm};

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    border-color: ${grey.mid};

    &:focus {
      border-color: ${grey.mid};
    }
  }

  &:focus {
    outline: none;
    color: ${grey.mid};
  }
`;

const FilterButtonContainer = styled.div`
  margin-right: 8px;
`;

const OrderByLabel = styled.span`
  margin-right: 16px;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
`;

const NoResults = styled.div`
  color: ${grey.mid};
`;

const CustomShowResultsButton = styled(ShowResultsButton)`
  background-color: ${standardColors.white};
`;

const InformationIcon = styled(InformationIconSvg)`
  height: 15px;
  margin-left: 1px;
  &:hover {
    cursor: pointer;
    path {
      fill: ${purple.mid};
    }
  }
`;

const RoleLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font-size: ${fontSize.sm};
  color: ${grey.dark};

  &:hover {
    color: ${purple.mid};
  }

  &:focus {
    outline: none;
    color: ${purple.mid};
  }
`;

const Image = styled(DiImage)`
  width: 100%;
  object-fit: cover;
  height: 258px;
  border-radius: 3px;
`;

const Rule = styled.div`
  border-bottom: 1px solid ${grey.rule};
  position: relative;
  left: 26px;
  width: 95%;
  margin-bottom: 11px;
  margin-top: 3px;
`;

const LocationsTopSectionContainer = styled.div`
  padding: 16px;
  background-color: ${standardColors.white};
  margin-bottom: ${props => (props.isLocationResultsShown ? 2 : 0)}px;
`;

const CustomStickyExpandButton = styled(StickyExpandButton)`
  padding-left: 16px;
  padding-right: 16px;
  border-top: 2px solid ${grey.panel};
`;

/* ClassNameOverrides */

const masonryGrid = css`
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
`;

const masonryColumn = css`
  background-clip: padding-box;
  padding-left: 10px;

  & > div {
    margin-bottom: 10px;
    padding: 16px;
  }

  & > div:last-child {
    margin-bottom: 0px;
  }
`;

const infoIconTooltipContent = css`
  width: auto;
`;

const S = {
  Heading,
  LocationsSectionContainer,
  ResultsSection,
  MasonrySection,
  SectionTotal,
  FilteredSectionCount,
  SectionCountAdditional,
  Dates,
  FilterButtonsContainer,
  FilterByLabel,
  FiltersSection,
  ResetFiltersButton,
  FilterButtonContainer,
  OrderByLabel,
  NoResults,
  CustomShowResultsButton,
  InformationIcon,
  RoleLinkButton,
  Image,
  Rule,
  LocationsTopSectionContainer,
  CustomStickyExpandButton
};

export const classNameOverrides = {
  masonryGrid,
  masonryColumn,
  infoIconTooltipContent
};

export default S;
