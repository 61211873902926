import React from "react";

import { getExplainerText } from "themes/projectdx/ThemedScreening/util";

import S from "./styles";

const FlagExplainer = ({ type, isActive }) => {
  let activeStatus;

  if (typeof isActive === "boolean") {
    activeStatus = isActive ? " (Active)" : " (Inactive)";
  }

  return (
    <S.Container>
      <S.Header>
        {type}
        {activeStatus ? <S.ActiveStatus>{activeStatus}</S.ActiveStatus> : null}
      </S.Header>
      <S.Content>{getExplainerText(type)}</S.Content>
    </S.Container>
  );
};

export default FlagExplainer;
