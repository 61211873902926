import React from "react";
import Loading from "components/atoms/Loading";

import S from "./styles";

const LoadingPage = () => {
  return (
    <S.Container>
      <Loading />
    </S.Container>
  );
};

export default LoadingPage;
