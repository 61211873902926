import { styled } from "@linaria/react";

import { grey, red } from "styles/colors";

const ErrorContainer = styled.main`
  padding: 2rem;
  margin: 2rem;
  color: ${red.dark};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${grey.panel};
  border-radius: 5px;
  max-width: 95%;
`;

const S = {
  ErrorContainer
};

export default S;
