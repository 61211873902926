import Feature from "../api/feature/Feature";
import { runInAction, observable, makeObservable } from "mobx";
class FeatureStore {
  constructor(sharedUserInterface) {
    this.sharedUserInterface = sharedUserInterface;
    this.featuresApi = new Feature();
    this.features = {};
    this.fetch = this.fetch.bind(this);

    makeObservable(this, { features: observable });
  }

  async fetch() {
    return await this.featuresApi.get();
  }

  async list() {
    const features = await this.featuresApi.get();
    runInAction(() => (this.features = features));
  }

  select(name) {
    runInAction(() => {
      this.features[name] = true;
    });
  }

  deselect(name) {
    runInAction(() => {
      this.features[name] = false;
    });
  }
}

export default FeatureStore;
