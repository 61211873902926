import { useContext } from "react";
import { UserOrganisationAction, UserOrganisationState } from "./types";
import { UserOrganisationContext } from "./context";
import { UserOrganisationProvider } from "./provider";

const useUserOrganisation = (): UserOrganisationState & {
  dispatch: (action: UserOrganisationAction) => void;
} => {
  const { state, dispatch } = useContext(UserOrganisationContext);
  return { ...state, dispatch };
};

export { useUserOrganisation, UserOrganisationProvider };
