import React, { ReactNode, FC, useState } from "react";

import { Dropdown } from "reactstrap";

import S from "./styles";

interface Props {
  children: ReactNode;
  label: string;
  disabled?: boolean;
}

const DropdownButton: FC<Props> = ({ children, label, disabled = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown
      direction="start"
      isOpen={isDropdownOpen}
      toggle={() => setIsDropdownOpen(prevState => !prevState)}
    >
      <S.OptionsDropdownToggle disabled={disabled}>
        <S.OptionsDropdownSelectedLabel>{label}</S.OptionsDropdownSelectedLabel>
        <S.ArrowDown />
      </S.OptionsDropdownToggle>
      <S.OptionsDropdownMenu>{children}</S.OptionsDropdownMenu>
    </Dropdown>
  );
};

export const DropdownButtonItem = S.OptionsDropdownItem;

export default DropdownButton;
