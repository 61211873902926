import { styled } from "@linaria/react";

import { ReactComponent as ArrowDown } from "img/icons/arrow-down.svg";

import { grey, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

import Button from "components/atoms/Button";

const DetailButtonDividerLine = styled.div`
  height: 1px;
  background-color: ${grey.rule};
  flex-grow: 0.5;
`;

const ExpandButtonContainer = styled.div<{ isReportRegenerationOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: ${props => (props.isReportRegenerationOpen ? 119 : 0)}px;
  padding: 10px 0;
  background-color: ${standardColors.white};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const ExpandResultsButton = styled(Button)<{ additionalText: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${standardColors.white} !important;
  min-height: 27px !important;
  min-width: 120px !important;
  font-size: ${fontSize.sm} !important;
  margin: 0 12px;
  gap: ${props => (props.additionalText ? 4 : 0)}px;
  align-items: baseline;
`;

const ChevronDown = styled(ArrowDown)`
  path {
    fill: ${standardColors.white};
  }

  transform: rotate(
    ${({ className }) => (className === "isExpanded" ? 180 : 0)}deg
  );
`;

const S = {
  DetailButtonDividerLine,
  ExpandButtonContainer,
  ExpandResultsButton,
  ChevronDown
};

export default S;
