import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { observe } from "mobx";

import PreparingReport from "pages/report/PreparingReport";
import { useNavigate, useParams } from "react-router-dom";
import { useInsightReport } from "util/hooks/useInsightReport";
import { GetReportErrorCodes } from "api/insight-reports";

const PreparingReportContainer = props => {
  const { enquiryStore, diagnosticsStore } = props;

  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { errorMessage, report: insightsReport }
  } = useInsightReport();

  const isLoadingInsights =
    !insightsReport && errorMessage === GetReportErrorCodes.IN_PROGRESS_ERROR;

  useEffect(() => {
    enquiryStore.setCurrentEnquiryId(params.enquiryId);

    const dispose = observe(diagnosticsStore, change => {
      if (change.name === "enquiryOverview") {
        if (
          change.newValue &&
          change.newValue.reportReady &&
          !isLoadingInsights
        ) {
          navigate(`/report/${params.enquiryId}`);
        }
      }
    });

    window.scrollTo(0, 0);

    return dispose;
  }, [enquiryStore, diagnosticsStore, navigate, params, isLoadingInsights]);

  return (
    <PreparingReport
      messages={diagnosticsStore.messages}
      enquiryId={params.enquiryId}
      isLoadingInsights={isLoadingInsights}
    />
  );
};

export default inject(
  "enquiryStore",
  "diagnosticsStore"
)(observer(PreparingReportContainer));
