import React, { CSSProperties } from "react";

import S from "./styles";

const Menu = ({
  children,
  style,
  className
}: {
  style?: CSSProperties;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <S.Menu style={style} className={className}>
      {children}
    </S.Menu>
  );
};

export default Menu;
