import { useContext } from "react";

import { GetTimelineErrorCodes } from "api/insight-reports";
import { InsightsTimelineContext } from "./context";
import InsightsTimelineProvider from "./provider";
import { InsightsTimelineActions, InsightsTimelineState } from "./types";

export { InsightsTimelineStatus } from "./types";

const useInsightsTimeline = () => {
  const context = useContext(InsightsTimelineContext);

  return context;
};

const shouldShowTimeline = (
  state: InsightsTimelineState,
  isPrintMode: boolean
) => {
  if (isPrintMode && !state.timeline?.length) {
    return false;
  }

  return (
    state.timeline ||
    state.errorMessage === GetTimelineErrorCodes.IN_PROGRESS_ERROR ||
    state.errorMessage === GetTimelineErrorCodes.FAILED_ERROR
  );
};

export {
  useInsightsTimeline,
  InsightsTimelineContext,
  InsightsTimelineProvider,
  InsightsTimelineActions,
  shouldShowTimeline
};
