import { styled } from "@linaria/react";

const ContentSwitchContainer = styled.div<{ disabled: boolean; width: number }>`
  width: ${props => props.width}px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  display: flex;
  text-align: center;
  align-items: center;
  position: relative;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  transition: opacity 0.2s;
`;

const SelectionPill = styled.div<{
  isLeftItemSelected: boolean;
  pillPosition: number;
  width: number;
}>`
  height: 30px;
  width: ${props => props.width}px;
  background-color: white;
  position: absolute;
  border-radius: 15px;
  transform: translateX(${props => props.pillPosition}px);
  transition: transform 0.2s ease-in;
  will-change: transform;
`;

const S = {
  ContentSwitchContainer,
  SelectionPill
};

export default S;
