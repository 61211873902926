import React, { ReactNode } from "react";
import Skeleton from "components/atoms/Skeleton";

import Chip from "components/atoms/Chip";
import { ChipVariant, ChipSize } from "components/atoms/Chip/types";
import { CardVariant } from "components/molecules/Card/types";
import ReactTooltip from "components/atoms/ReactTooltip";

import S from "./styles";

const TAGS_LIMIT = 3;

interface Tag {
  name: string;
  helpText: string;
  variant?: ChipVariant;
}

interface Props {
  title?: string;
  subtitle?: ReactNode;
  tags?: Tag[];
  loading?: boolean;
  variant?: CardVariant;
}

const CardBody = ({
  tags,
  title,
  subtitle,
  loading,
  variant = CardVariant.default
}: Props) => {
  return loading ? (
    <S.Body>
      <S.Title>
        <Skeleton width="50%" />
      </S.Title>
      <S.Tags>
        <Skeleton width="20%" />
        <Skeleton width="20%" />
        <Skeleton width="20%" />
        <Skeleton width="20%" />
      </S.Tags>
    </S.Body>
  ) : (
    <S.Body>
      {title && <S.Title variant={variant}>{title}</S.Title>}
      {subtitle && <S.Subtitle variant={variant}>{subtitle}</S.Subtitle>}
      {(tags?.length ?? 0) > 0 && (
        <S.Tags>
          {tags
            ?.slice(0, TAGS_LIMIT)
            ?.map(({ name, helpText, variant: chipVariant }) => (
              <ReactTooltip
                key={name}
                disabled={false}
                layoutPosition={undefined}
                position={undefined}
                interactive
                tooltip={helpText}
                open={undefined}
                className={undefined}
                style={undefined}
              >
                <Chip
                  label={name}
                  variant={chipVariant || ChipVariant.Outlined}
                  colorVariant={variant}
                  size={ChipSize.Small}
                />
              </ReactTooltip>
            ))}
          {(tags?.length ?? 0) > TAGS_LIMIT ? (
            <Chip
              label={`+${(tags?.length ?? 0) - TAGS_LIMIT} others`}
              variant={ChipVariant.Outlined}
              colorVariant={variant}
              size={ChipSize.Small}
            />
          ) : null}
        </S.Tags>
      )}
    </S.Body>
  );
};

export default CardBody;
