import { styled } from "@linaria/react";

const colours = [
  { border: "#ffc107", background: "#f0e9d5" },
  { border: "#28a745", background: "#d8eddd" },
  { border: "#dc3545", background: "#f0dadc" },
  { border: "#ffc107", background: "#f0e9d5" }
];

const AssertionIcon = styled.div`
  display: inline;
  & > svg {
    path {
      fill: ${props => colours[props["data-state"]].border};
    }
  }
`;

const S = {
  AssertionIcon
};

export default S;
