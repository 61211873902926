import React from "react";
import type { CardVariant } from "components/molecules/Card/types";

export enum ChipSize {
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE"
}

export enum ChipVariant {
  Outlined = "OUTLINED",
  FilledDark = "FILLED_DARK",
  FilledLight = "FILLED_LIGHT"
}

export interface Props {
  size: ChipSize;
  onDelete?: React.MouseEventHandler<SVGElement>;
  label: string;
  variant: ChipVariant;
  colorVariant?: CardVariant;
}
