import { styled } from "@linaria/react";

import SourceLink from "components/atoms/SourceLink";
import AccordionComponent from "components/molecules/Accordion";

import { blue, grey, standardColors } from "styles/colors";
import { fontSize, family } from "styles/fonts";

const ItemLink = styled(SourceLink)`
  display: flex;
  align-items: center;

  &:hover {
    color: ${blue.hover};
  }
`;

const BulletContainer = styled.div`
  display: flex;
`;

const Bullet = styled.span`
  height: 6px;
  width: 6px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  background: ${grey.dark};
`;

const ListItem = styled.li`
  font-size: ${fontSize.sm};
  margin-bottom: 4px;
  padding: 0;
  justify-content: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListContainer = styled.div`
  position: relative;
  height: auto !important;
  line-height: 1;
  margin: 0;
  padding: 4px 0;
`;

const ListHeading = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${props => (props.isExpanded ? "20px" : "auto")};
`;

const ListTitle = styled.span`
  margin-right: 4px;
  color: ${grey.mid42};
  font-size: ${fontSize.xl};
  font-family: ${family.interDisplay};
`;

const ListSubTitle = styled.span`
  color: ${grey.mid};
`;

const ShowAllToggle = styled.div`
  cursor: pointer;
  align-self: flex-end;
  right: 0;
  top: 0;
  color: ${grey.mid};
  font-size: ${fontSize.sm};

  & > button {
    font-size: ${fontSize.md};
    line-height: 0.8;
  }

  &:hover {
    color: ${standardColors.black};
  }
`;

const ContentContainer = styled.div`
  height: ${props => (props.isExpanded ? "78%" : "0%")};
  position: relative;
  width: 100%;
`;

const DescriptionList = styled.dl`
  margin-bottom: 0;
`;

const DescriptionDetails = styled.dd`
  margin-bottom: 0;
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  margin-left: 16px;
  padding-left: 0;
`;

const Pagination = styled.div`
  width: 100%;
  bottom: 0;
  display: flex;
  align-self: baseline;
  position: relative;
  margin-top: 8px;
  height: 16px;
`;

const PaginationControl = styled.span`
  display: block;
  width: auto;
  cursor: pointer;
  color: ${grey.mid};
  position: absolute;
  top: 0;

  &:hover {
    color: ${standardColors.black};
  }
`;

const PaginationPrevControl = styled(PaginationControl)`
  left: 0;
`;

const PaginationNextControl = styled(PaginationControl)`
  right: 0;
`;

const Accordion = styled(AccordionComponent)`
  margin-top: 10px;
  border-top: 1px solid ${grey.rule};
  padding: 3px 0;
`;

const OverlapTitle = styled.span`
  font-size: ${fontSize.xl};
`;

const S = {
  ItemLink,
  BulletContainer,
  Bullet,
  ListItem,
  ListContainer,
  ListHeading,
  ListTitle,
  ListSubTitle,
  ShowAllToggle,
  ContentContainer,
  DescriptionList,
  DescriptionDetails,
  List,
  Pagination,
  PaginationPrevControl,
  PaginationNextControl,
  Accordion,
  OverlapTitle
};

export default S;
