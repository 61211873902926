import { css } from "@linaria/core";

export const globals = css`
  /* Old legacy code from a long, long time ago. This is essentially modified Bootstrap 4.
  * If you find yourself reaching for these styles to add to or modify, please take this time
  * to make what you're building a Linaria styled component, or if the styles would benefit
  * from being global, a more robust global system than hacked Bootstrap.
  */
  :global() {
    .dropup,
    .dropright,
    .dropdown,
    .dropleft {
      position: relative;
    }

    .dropdown-toggle::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
    .dropdown-toggle:empty::after {
      margin-left: 0;
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 10rem;
      padding: 0.5rem 0;
      margin: 0.125rem 0 0;
      font-size: $font_size_md;
      color: #474952;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
    }

    .dropdown-menu-right {
      right: 0;
      left: auto;
    }

    .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: 0.125rem;
    }
    .dropup .dropdown-toggle::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
    }
    .dropup .dropdown-toggle:empty::after {
      margin-left: 0;
    }

    .dropright .dropdown-menu {
      top: 0;
      right: auto;
      left: 100%;
      margin-top: 0;
      margin-left: 0.125rem;
    }
    .dropright .dropdown-toggle::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
    }
    .dropright .dropdown-toggle:empty::after {
      margin-left: 0;
    }
    .dropright .dropdown-toggle::after {
      vertical-align: 0;
    }

    .dropleft .dropdown-menu {
      top: 0;
      right: 100%;
      left: auto;
      margin-top: 0;
      margin-right: 0.125rem;
    }
    .dropleft .dropdown-toggle::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
    }
    .dropleft .dropdown-toggle::after {
      display: none;
    }
    .dropleft .dropdown-toggle::before {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid;
      border-bottom: 0.3em solid transparent;
    }
    .dropleft .dropdown-toggle:empty::after {
      margin-left: 0;
    }
    .dropleft .dropdown-toggle::before {
      vertical-align: 0;
    }

    .dropdown-menu[x-placement^="top"],
    .dropdown-menu[x-placement^="right"],
    .dropdown-menu[x-placement^="bottom"],
    .dropdown-menu[x-placement^="left"] {
      right: auto;
      bottom: auto;
    }

    .dropdown-divider {
      height: 0;
      margin: 0.5rem 0;
      overflow: hidden;
      border-top: 1px solid #e9ecef;
    }

    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.25rem 1.5rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #007bff;
    }
    .dropdown-item.disabled,
    .dropdown-item:disabled {
      color: #6c757d;
      background-color: transparent;
    }

    .dropdown-menu.show {
      display: block;
    }

    .dropdown-header {
      display: block;
      padding: 0.5rem 1.5rem;
      margin-bottom: 0;
      font-size: $font_size_sm;
      color: #6c757d;
      white-space: nowrap;
    }

    .dropdown-item-text {
      display: block;
      padding: 0.25rem 1.5rem;
      color: #212529;
    }

    .toggle-filter {
      display: flex;
      align-items: center;
      padding-bottom: 2px;
      padding-top: 2px;
    }
    .toggle-filter-inner {
      margin: 5px;
    }
    .toggle-filter-label {
      flex-grow: 1;
    }
    .sort-filter-dropdown-menu-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: $Inter_Regular;
    }
    .sort-filter-dropdown-menu {
      min-width: 25rem;
      font-size: $font_size_sm;
      border-radius: 10px;
      padding-bottom: 22px;
      padding-top: 22px;
    }

    .sort-filter-dropdown-menu > *:not(.sort-filter-dropdown-menu-section),
    .sort-filter-dropdown-menu-section > *:not(.sort-filter-categories) {
      padding-left: 22px;
      padding-right: 22px;
    }
  }
`;
