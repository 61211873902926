import { extendObservable } from "mobx";
import Match from "../api/define/Match";
import { fetchSystemStatus } from "api/define/systemStatus";
import AwesomeDebouncePromise from "awesome-debounce-promise";
export const emptySearchValidationMessage =
  "Enter a person's name and company name in the search boxes above";

export default class DefineStore {
  constructor(sharedUserInterface, api) {
    this.sharedUserInterface = sharedUserInterface;
    this.searchInputValidationResponse = "null";
    this.api =
      api ||
      new Match(state => (this.sharedUserInterface.apiCallInProgress = state));
    extendObservable(this, {
      matches: [],
      selectedDefinition: null
    });
    this.queuedRequest = null;
    this.resolveQueuedRequest = null;
    this.requestsMade = 0;
    this.requestLimit = 5;
    this.nameOnlySearch = false;
  }

  searchAPI = async request => {
    if (
      request &&
      request.EnquirySubject &&
      request.EnquirySubject.item.length &&
      request.EnquiryContext &&
      request.EnquiryContext.length
    ) {
      if (this.requestsMade >= this.requestLimit) {
        this.lastFullHeat = Date.now();
        this.queuedRequest = request;
        this.resolveQueuedRequest = null;
        const responseAfterQueuing = await new Promise(resolve => {
          this.resolveQueuedRequest = resolve;
        });
        return responseAfterQueuing;
      } else {
        this.requestsMade++;
        const responseTask = this.validateInput(request);
        const response = await responseTask;
        this.requestsMade--;
        if (this.queuedRequest && this.resolveQueuedRequest) {
          const nextRequest = this.queuedRequest;
          const nextRequestResolve = this.resolveQueuedRequest;
          this.queuedRequest = null;
          this.resolveQueuedRequest = null;
          this.searchAPI(nextRequest).then(nextResponse => {
            nextRequestResolve(nextResponse);
          });
        }
        return response;
      }
    } else {
      this.queuedRequest = null;
      this.resolveQueuedRequest = null;
      return {
        queryQuality: 0,
        userMessage: emptySearchValidationMessage
      };
    }
  };
  searchAPIDebounced = AwesomeDebouncePromise(this.searchAPI, 150, {
    onlyResolvesLast: true
  });

  reset() {
    this.matches = [];
  }

  fetch(criteria) {
    return new Promise((resolve, reject) => {
      this.api.get(criteria).then(matches => {
        this.matches = matches;
        resolve(matches);
      }, reject);
    });
  }

  setNameOnlySearch(value) {
    this.nameOnlySearch = value;
  }

  async validateInput(request) {
    const response = await this.api.searchInputValidation(request);
    return response;
  }

  async getSystemStatus() {
    try {
      const response = await fetchSystemStatus();

      if (response.systemError) {
        return response.message?.replace(/\\n/g, "");
      }
    } catch (error) {
      console.error(
        `Failed to get system status. ${error.status}: ${error.statusText}`
      );
    }
  }
}
