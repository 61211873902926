/* eslint-disable @typescript-eslint/no-unused-vars */
import type { CollectionListState } from "util/hooks/useCollectionList/types";
import type { CollectionSearchState } from "util/hooks/useCollectionSearch/types";
import {
  CollectionStatus,
  COLLECTION_ITEMS_PER_PAGE,
  CollectionInputType
} from "util/hooks/useCollectionList/types";
import { CollectionListView } from "components/molecules/CollectionListControls";

import {
  Idam_Contracts_Enums_GroupUserRole,
  Idam_Contracts_Organisations_CreateOrganisationGroupRequest,
  Idam_Contracts_Organisations_GetOrganisationGroupsResponse,
  Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest,
  Idam_Contracts_Organisations_UpdateOrganisationGroupRequest,
  Idam_Contracts_Users_GetUserGroupsResponse,
  IdentityOrganisationApiService,
  IdentityUserApiService
} from "api/portal";
import type { SearchResult } from "api/search";
import { TagId } from "api/reports/types";
import { FetchResult } from "api/types";
import { getErrorMessage } from "api/util";
import { apm } from "@elastic/apm-rum";

import { GroupUser, type Group, GroupUserRole } from "./types";

export type { Group };

export const stripGroupName = (name: string) => {
  let strippedGroupName = name;
  if (name.charAt(0) === "@") {
    strippedGroupName = strippedGroupName.slice(1);
  }
  return strippedGroupName;
};

const mapFromGroupUserRoleContract = (
  groupRole?: Idam_Contracts_Enums_GroupUserRole
) => {
  const map: Record<Idam_Contracts_Enums_GroupUserRole, GroupUserRole> = {
    [Idam_Contracts_Enums_GroupUserRole.ADMIN]: GroupUserRole.Admin,
    [Idam_Contracts_Enums_GroupUserRole.STANDARD]: GroupUserRole.Standard
  };

  if (!groupRole) {
    return GroupUserRole.Standard;
  }

  return map[groupRole];
};

const mapToGroupUserRoleContract = (groupRole?: GroupUserRole) => {
  const map: Record<GroupUserRole, Idam_Contracts_Enums_GroupUserRole> = {
    [GroupUserRole.Admin]: Idam_Contracts_Enums_GroupUserRole.ADMIN,
    [GroupUserRole.Standard]: Idam_Contracts_Enums_GroupUserRole.STANDARD
  };

  if (!groupRole) {
    return Idam_Contracts_Enums_GroupUserRole.STANDARD;
  }

  return map[groupRole];
};

export default class Groups {
  getEmptySearchState(): CollectionSearchState {
    return {
      query: "",
      results: [],
      searchTags: []
    };
  }

  getEmptyCollections(): CollectionListState {
    return {
      collections: [
        {
          id: "groups",
          title: "Groups",
          limit: COLLECTION_ITEMS_PER_PAGE,
          offset: 0,
          order: "desc",
          view: CollectionListView.grid,
          pollingEnabled: true,
          items: [],
          totalItemCount: 0,
          status: CollectionStatus.stale,
          hidden: false,
          hiddenIfEmpty: false,
          input: { type: CollectionInputType.list }
        }
      ]
    };
  }

  getGroupTags(
    userGroups: Map<string, Idam_Contracts_Users_GetUserGroupsResponse>,
    groupId: string
  ) {
    const tags = [];

    const group: Idam_Contracts_Users_GetUserGroupsResponse | undefined =
      userGroups.get(groupId);
    if (group) {
      if (group.role === Idam_Contracts_Enums_GroupUserRole.ADMIN) {
        tags.push({ id: TagId.GroupRole, name: "Admin" });
      } else {
        tags.push({ id: TagId.GroupRole, name: "I am a member" });
      }
    }

    return tags;
  }

  async search({
    query,
    offset,
    limit,
    userGroups,
    organisationGroups
  }: {
    query: string;
    offset: number;
    limit: number;
    userGroups?: Idam_Contracts_Users_GetUserGroupsResponse[];
    organisationGroups?: Idam_Contracts_Organisations_GetOrganisationGroupsResponse[];
  }): Promise<{ items: Group[]; totalItemCount: number }> {
    try {
      const [allUserGroups, allGroupsInOrg] = await Promise.all([
        userGroups || this.getAllUserGroups(),
        organisationGroups || this.getAllOrganisationGroups()
      ]);

      const userGroupsMap = allUserGroups.reduce((acc, group) => {
        return acc.set(group.groupId, group);
      }, new Map());

      const containsQuery = (value: string | undefined | null): unknown => {
        return value?.toLowerCase()?.includes(query.toLowerCase());
      };

      const filtered = allGroupsInOrg.filter(
        g => containsQuery(g.name) || containsQuery(g.description)
      );

      const groups = filtered.map(
        ({ groupId, description, users, name, permissions }) => {
          const group: Group = {
            id: groupId!,
            icon: "Icon missing",
            title: name!,
            context: description ?? undefined,
            description: `${users ?? 0} members`,
            memberCount: users ?? 0,
            permissions: {
              canView: !!permissions?.canView,
              canEdit: !!permissions?.canEdit,
              canViewMembers: !!permissions?.canViewMembers,
              canEditMembers: !!permissions?.canEditMembers,
              canEditAdmins: !!permissions?.canEditAdmins,
              canLeave: !!permissions?.canLeave,
              canViewReports: !!permissions?.canViewReports,
              canDelete: !!permissions?.canDelete
            },
            tags: this.getGroupTags(userGroupsMap, groupId!)
          };
          // TODO - establish with backend tht id and name are non-optional, add to JSONSpec and remove "!" notation here
          return group;
        }
      );

      return { items: groups, totalItemCount: groups.length };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async getAllUserGroups(): Promise<
    Idam_Contracts_Users_GetUserGroupsResponse[]
  > {
    try {
      return await IdentityUserApiService.getUsersGroups();
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);

      return [];
    }
  }

  async getAllOrganisationGroups(): Promise<
    Idam_Contracts_Organisations_GetOrganisationGroupsResponse[]
  > {
    try {
      return await IdentityOrganisationApiService.getOrganisationsGroups();
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return [];
    }
  }

  async getSearchSuggestions({
    query,
    userGroups,
    organisationGroups
  }: {
    query: string;
    userGroups?: Idam_Contracts_Users_GetUserGroupsResponse[];
    organisationGroups?: Idam_Contracts_Organisations_GetOrganisationGroupsResponse[];
  }): Promise<SearchResult[]> {
    // TODO - not in portal MVP
    const { items } = await this.search({
      query,
      offset: 0,
      limit: 20,
      userGroups,
      organisationGroups
    });
    return items;
  }

  async list({
    id,
    limit,
    offset,
    filters
  }: {
    id: string;
    limit: number;
    offset: number;
    filters?: string[];
  }): Promise<{ items: Group[]; totalItemCount: number }> {
    try {
      const [userGroups, response] = await Promise.all([
        this.getAllUserGroups(),
        this.getAllOrganisationGroups()
      ]);

      const userGroupsMap = userGroups.reduce((acc, group) => {
        return acc.set(group.groupId, group);
      }, new Map());

      const items = response.map(
        ({ groupId, name, description, users, permissions }) => ({
          id: groupId ?? "unknown",
          icon: "group",
          title: name ?? "unknown",
          context: description ?? undefined,
          description: `${users ?? 0} members`,
          memberCount: users ?? 0,
          permissions: {
            canView: !!permissions?.canView,
            canEdit: !!permissions?.canEdit,
            canViewMembers: !!permissions?.canViewMembers,
            canEditMembers: !!permissions?.canEditMembers,
            canEditAdmins: !!permissions?.canEditAdmins,
            canLeave: !!permissions?.canLeave,
            canViewReports: !!permissions?.canViewReports,
            canDelete: !!permissions?.canDelete
          },
          tags: this.getGroupTags(userGroupsMap, groupId!)
        })
      );

      return {
        items,
        totalItemCount: items.length
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { items: [], totalItemCount: 0 };
    }
  }

  async deleteGroup(groupId: string): Promise<FetchResult> {
    try {
      await IdentityOrganisationApiService.deleteOrganisationsGroups({
        groupId
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async leaveGroup(groupId: string): Promise<FetchResult> {
    try {
      await IdentityOrganisationApiService.postOrganisationsGroupsLeave({
        groupId
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async updateGroup(
    groupId: string,
    name: string,
    description?: string
  ): Promise<FetchResult> {
    try {
      const requestBody: Idam_Contracts_Organisations_UpdateOrganisationGroupRequest =
        {
          name: stripGroupName(name),
          description
        };

      await IdentityOrganisationApiService.putOrganisationsGroups({
        groupId,
        requestBody
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false };
    }
  }

  async createGroup(
    name: string,
    description: string | undefined
  ): Promise<FetchResult<string>> {
    const requestBody: Idam_Contracts_Organisations_CreateOrganisationGroupRequest =
      {
        name: stripGroupName(name),
        description
      };

    try {
      const { groupId } =
        await IdentityOrganisationApiService.postOrganisationsGroups({
          requestBody
        });

      if (groupId) {
        return { status: true, response: groupId };
      }
      return { status: false };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getGroupMembers(groupId: string): Promise<GroupUser[] | undefined> {
    try {
      const group =
        await IdentityOrganisationApiService.getOrganisationsGroups1({
          groupId
        });

      const groupUsers =
        group.users?.map(user => ({
          id: user.userId ?? "",
          firstName: user.firstName ?? "",
          lastName: user.lastName ?? "",
          email: user.email ?? "",
          jobTitle: user.jobTitle ?? "",
          groupRole: mapFromGroupUserRoleContract(user.groupRole)
        })) ?? [];

      return groupUsers;
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return undefined;
    }
  }

  async addMembersToGroup(
    userIds: string[],
    groupId: string
  ): Promise<FetchResult> {
    const requestBody: Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest =
      {
        addUsers: userIds,
        removeUsers: []
      };

    try {
      await IdentityOrganisationApiService.putOrganisationsGroupsMembers({
        groupId,
        requestBody
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async removeMembersFromGroup(
    userIds: string[],
    groupId: string
  ): Promise<FetchResult> {
    const requestBody: Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest =
      {
        addUsers: [],
        removeUsers: userIds
      };

    try {
      await IdentityOrganisationApiService.putOrganisationsGroupsMembers({
        groupId,
        requestBody
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async addAdminsToGroup(
    userIds: string[],
    groupId: string
  ): Promise<FetchResult> {
    const requestBody: Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest =
      {
        addUsers: userIds,
        removeUsers: []
      };

    try {
      await IdentityOrganisationApiService.putOrganisationsGroupsAdmins({
        groupId,
        requestBody
      });
      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async removeAdminsFromGroup(
    userIds: string[],
    groupId: string
  ): Promise<FetchResult> {
    const requestBody: Idam_Contracts_Organisations_UpdateOrganisationGroupMembersRequest =
      {
        addUsers: [],
        removeUsers: userIds
      };

    try {
      await IdentityOrganisationApiService.putOrganisationsGroupsAdmins({
        groupId,
        requestBody
      });
      return {
        status: true
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return {
        status: false,
        message: getErrorMessage(e)
      };
    }
  }

  async changeMemberGroupRole(
    userId: string,
    groupId: string,
    role: GroupUserRole
  ): Promise<FetchResult> {
    try {
      await IdentityOrganisationApiService.putOrganisationsGroupsMembersRole({
        groupId,
        userId,
        role: mapToGroupUserRoleContract(role)
      });

      return { status: true };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return { status: false, message: getErrorMessage(e) };
    }
  }

  async getGroupDetails(groupId: string): Promise<Partial<Group> | null> {
    try {
      const groupDetails =
        await IdentityOrganisationApiService.getOrganisationsGroups1({
          groupId
        });

      return {
        id: groupDetails.groupId!,
        title: groupDetails.name!,
        description: groupDetails.description ?? ""
      };
    } catch (e) {
      apm.captureError(e as Error);
      console.error(e);
      return null;
    }
  }
}
