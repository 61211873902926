import React, { ReactNode } from "react";

import RemoveConfirmationModal from "components/molecules/RemoveConfirmationModal";

import useDeleteGroup from "./useDeleteGroup";

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  groupId: string;
  children?: ReactNode;
}

const DeleteGroupModal = ({ isOpen, toggleOpen, groupId, children }: Props) => {
  const { deleteGroup, fetching, error, errorMessage, reset } =
    useDeleteGroup();

  const onToggleOpen = () => {
    if (isOpen) {
      reset();
      toggleOpen();
    }
  };

  return (
    <RemoveConfirmationModal
      isOpen={isOpen}
      toggleOpen={onToggleOpen}
      heading="Delete group?"
      onRemove={() => deleteGroup(groupId)}
      onReset={reset}
      error={error}
      errorMessage={errorMessage}
      loading={fetching}
      primaryActionText="Delete group"
      description="Members will be notified by email"
    >
      {children}
    </RemoveConfirmationModal>
  );
};

export default DeleteGroupModal;
