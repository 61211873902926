import React, { FC, ReactNode } from "react";
import S from "./styles";

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

const ModalSection: FC<Props> = ({ children, style, className }) => {
  return (
    <S.Section style={style} className={className}>
      {children}
    </S.Section>
  );
};

export default ModalSection;
