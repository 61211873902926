import type { Report as RawReport } from "api/insights";

export const removeSectionFromReportBySectionId = (
  report: RawReport,
  sectionId: string
): RawReport => {
  const updatedSections = report.sections.filter(
    section => section.id !== sectionId
  );

  return {
    ...report,
    sections: updatedSections
  };
};
