import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingPage from "components/molecules/LoadingPage";

const SsoCallback = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.has("error_code") || !params.has("code")) {
      navigate("/login");
    }
  }, [navigate, search]);

  return <LoadingPage />;
};

export default SsoCallback;
