import { useContext } from "react";

import { UserSettingsContext } from "./context";

const useUserSettings = () => {
  const context = useContext(UserSettingsContext);

  if (!context) {
    throw new Error(
      "useUserSettings must be used within a UserSettingsContextProvider"
    );
  }

  return context;
};

export default useUserSettings;
