import {
  OrganisationContextType,
  PersonContextType,
  EntityType,
  ContextConfig
} from "api/enquiry/types";

export const ENTITY_TYPE = Object.freeze({
  Organisation: "organisation",
  Person: "person"
});

export const SUBJECT_CONTEXTS: Record<
  EntityType,
  Record<string, ContextConfig>
> = {
  person: {
    person: {
      id: "person",
      name: "Person",
      isEnabled: true,
      hintText: "Enter related person",
      isAvailableInAdvancedMode: true,
      type: PersonContextType.Person
    },
    organisation: {
      id: "organisation",
      name: "Organisation",
      isEnabled: true,
      hintText: "Enter related organisation",
      isAvailableInAdvancedMode: true,
      type: PersonContextType.Organisation
    },
    socialProfile: {
      id: "socialProfile",
      name: "LinkedIn profile",
      hintText: "Enter LinkedIn profile",
      isEnabled: true,
      isAvailableInAdvancedMode: true,
      type: PersonContextType.SocialProfile
    },
    location: {
      id: "location",
      name: "Location",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: PersonContextType.Location
    },
    keywords: {
      id: "keywords",
      name: "Keywords",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: PersonContextType.Keywords
    },
    aliases: {
      id: "aliases",
      name: "Aliases",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: PersonContextType.Aliases
    },
    email: {
      id: "email",
      name: "Email",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: PersonContextType.Email
    },
    webAddress: {
      id: "webAddress",
      name: "Homepage",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: PersonContextType.WebAddress
    },
    dateOfBirth: {
      id: "dateOfBirth",
      name: "Date of birth",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: PersonContextType.DateOfBirth
    }
  },
  organisation: {
    webAddress: {
      id: "webAddress",
      name: "Homepage",
      isEnabled: true,
      hintText: "www.example.com",
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.WebAddress
    },
    socialProfile: {
      id: "socialProfile",
      name: "LinkedIn profile",
      hintText: "Enter LinkedIn profile",
      isEnabled: true,
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.SocialProfile
    },
    person: {
      id: "person",
      name: "Person",
      isEnabled: true,
      hintText: "Enter a director or officer",
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.Person
    },
    freeText: {
      id: "freeText",
      name: "Free text",
      isEnabled: true,
      hintText: "Location, sector, homepage, person etc.",
      isAvailableInAdvancedMode: false,
      type: OrganisationContextType.FreeText
    },
    location: {
      id: "location",
      name: "Location",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.Location
    },
    organisation: {
      id: "organisation",
      name: "Organisation",
      isEnabled: false,
      hintText: "Identifiable context",
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.Organisation
    },
    keywords: {
      id: "keywords",
      name: "Keywords",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.Keywords
    },
    aliases: {
      id: "aliases",
      name: "Aliases",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.Aliases
    },
    email: {
      id: "email",
      name: "Email",
      isEnabled: false,
      isAvailableInAdvancedMode: true,
      type: OrganisationContextType.Email
    }
  }
};

export const DEFAULT_CONTEXT_PER_SUBJECT_TYPE = {
  person: "organisation",
  organisation: "person"
};

export const DEFINE_STATE = Object.freeze({
  InvalidOrEmpty: "InvalidOrEmpty",
  CreatingEnquiry: "CreatingEnquiry"
});

/**
 * Strips out any HTML tags or related characters from a given string
 * @param {string} str string to sanitise of any HTML
 * @returns string
 */
export const unescapeHtmlString = (str: string) => {
  return new DOMParser()
    ?.parseFromString(str, "text/html")
    ?.querySelector("html")?.textContent;
};
