import React from "react";

import { Switch } from "@mui/material";

import useUserSettings from "util/hooks/useUserSettings";
import useUserPreferences from "util/hooks/useUserPreferences";
import Heading from "components/atoms/Heading";

import S, { classNameOverrides } from "./styles";

enum ToggleStates {
  On = "on",
  Off = "off",
  Default = "default"
}

const tooltipText: Record<ToggleStates, string> = {
  [ToggleStates.On]:
    "When switched on, Auto-share will automatically share your new reports with your chosen users and groups.",
  [ToggleStates.Off]:
    "When switched off, Auto-share will not share your new reports and they will be visible only to you.",
  [ToggleStates.Default]:
    "When set-up, Auto-share will automatically share your new reports with your chosen users and groups."
};

const MAX_SHARED_WITH_DISPLAY = 2;

const AutoshareToggle = () => {
  const { toggleAutoshareModal } = useUserSettings();
  const { state, toggleAutoshare } = useUserPreferences();
  const { autoshare, sharedWith } = state;

  let toggleState = ToggleStates.Default;
  if (!autoshare && sharedWith?.length) {
    toggleState = ToggleStates.Off;
  } else if (autoshare) {
    toggleState = ToggleStates.On;
  }

  const renderTooltipContent = () => {
    return (
      <S.TooltipContent>
        <Heading level={6}>Automatically share new reports</Heading>
        <div>{tooltipText[toggleState]}</div>
        <S.TooltipAutoshareButton onClick={toggleAutoshareModal}>
          Open Auto-share settings
        </S.TooltipAutoshareButton>
      </S.TooltipContent>
    );
  };

  const renderButton = () => {
    if (toggleState === ToggleStates.On || toggleState === ToggleStates.Off) {
      return (
        <S.AutoshareToggleContainer>
          Auto-share{" "}
          <Switch
            checked={autoshare}
            onChange={toggleAutoshare}
            inputProps={{
              "aria-label": `Switch ${
                toggleState === ToggleStates.On ? "off" : "on"
              } auto-share`
            }}
            className={classNameOverrides.toggle}
          />
        </S.AutoshareToggleContainer>
      );
    }

    return (
      <S.AutoshareButton onClick={toggleAutoshareModal}>
        Set-up Auto-share
      </S.AutoshareButton>
    );
  };

  return (
    <S.AutoshareContainer>
      <S.AutoshareInnerContainer>
        {/* TODO: Convert to typescript */}
        <S.CustomInfoIcon
          interactive
          infoContent={renderTooltipContent()}
          tooltipPosition={undefined}
          tooltipAlignment={undefined}
          style={undefined}
          className={undefined}
          tooltipContentClassName={undefined}
          tooltipWidth={undefined}
          disabled={undefined}
        />
        {renderButton()}
      </S.AutoshareInnerContainer>
      {(sharedWith?.length ?? 0) > 0 && toggleState === ToggleStates.On && (
        <S.SharedWith>
          Sharing with:{" "}
          {sharedWith
            .map(shareItem => shareItem.title)
            .slice(0, MAX_SHARED_WITH_DISPLAY)
            .join(", ")}{" "}
          {sharedWith.length - MAX_SHARED_WITH_DISPLAY > 0 &&
            `+${sharedWith.length - MAX_SHARED_WITH_DISPLAY} more`}
        </S.SharedWith>
      )}
    </S.AutoshareContainer>
  );
};

export default AutoshareToggle;
