import type { Report as RawReport } from "api/insights";

interface Props {
  rawReport: RawReport;
  title: string;
  sectionId: string;
  subsectionId?: string;
  elementId?: string;
}
export const updateRawReportSectionTitle = ({
  rawReport,
  title,
  sectionId,
  subsectionId,
  elementId
}: Props) => {
  const sections = rawReport.sections.map(section => {
    if (section.id !== sectionId) {
      return section;
    }

    if (!subsectionId) {
      return { ...section, title };
    }

    const subsections = section.subsections.map(subsection => {
      if (subsection.id !== subsectionId) {
        return subsection;
      }

      if (!elementId) {
        return { ...subsection, title };
      }

      const elements = subsection.elements.map(element => {
        if (element.id !== elementId) {
          return element;
        }

        return { ...element, title };
      });

      return { ...subsection, elements };
    });

    return { ...section, subsections };
  });

  return {
    ...rawReport,
    sections
  };
};
