import { styled } from "@linaria/react";
import { green } from "styles/colors";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    color: ${green.xapienGreen};
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const S = { ModalContainer, ModalButtonsContainer };

export default S;
