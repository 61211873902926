import { styled } from "@linaria/react";

import { ReactComponent as CheckIconSvg } from "img/icons/check.svg";

import { blue, grey } from "styles/colors";
import { fontSize } from "styles/fonts";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin: 0;

  label {
    margin: 0;
  }
`;

const CheckboxElementContainer = styled.div<{
  checked: boolean;
  checkedColor: string;
}>`
  margin-top: 2px;
  margin-right: 6px;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  line-height: 1;
  border: ${props => (props.checked ? "none" : `solid ${grey.mid} 1px`)};
  background-color: ${props => (props.checked ? props.checkedColor : "")};
`;

const CheckboxElement = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 1em;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
`;

const CheckIcon = styled(CheckIconSvg)<{ checked: boolean }>`
  width: 14px;
  height: 14px;

  visibility: ${props => (props.checked ? "visible" : "hidden")};
`;

const CheckState = styled.div<{ indeterminate?: boolean }>`
  display: ${props => (props.indeterminate ? "block" : "none")};
`;

const CheckIndeterminate = styled.div`
  height: 14px;
  width: 14px;
  position: relative;
  background-color: ${blue.icon};
  border-radius: 2px;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 10px;
  width: 10px;
`;

const S = {
  CheckboxContainer,
  CheckboxElementContainer,
  CheckboxElement,
  CheckIconWrapper,
  CheckIcon,
  CheckState,
  CheckIndeterminate
};

export default S;
