import React, { ChangeEventHandler } from "react";

import S from "./styles";

interface Props {
  id?: string;
  onChange: ChangeEventHandler<HTMLElement>;
  checked: boolean;
  value: string;
}

export const RadioButton = ({ value, checked, onChange, id }: Props) => {
  return (
    <S.RadioButton
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
      id={id}
    />
  );
};
