import { styled } from "@linaria/react";

import { motion } from "framer-motion";
import { standardColors } from "styles/colors";

const PagesContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const InteractiveWrapper = styled(motion.div)`
  position: absolute;
  border-radius: 8px;
  left: 0;
  background-color: ${standardColors.white};
  height: 100%;
  width: 100%;
`;

const InteractiveWrapperContent = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: ${props => (props.isPagePreviousPage ? 0.5 : 1)};
  transition: opacity 0.5s;
`;

const S = {
  PagesContainer,
  InteractiveWrapper,
  InteractiveWrapperContent
};

export default S;
