import { styled } from "@linaria/react";

const colours = [
  { border: "#ffc107", background: "#f0e9d5" },
  { border: "#28a745", background: "#d8eddd" },
  { border: "#dc3545", background: "#f0dadc" },
  { border: "#ffc107", background: "#f0e9d5" }
];

const Assertion = styled.div`
  border-left: 4px solid ${props => colours[props["data-state"]].border};
  background-color: ${props => colours[props["data-state"]].background};
  border-radius: 4px;
  padding: 3px;
  margin: 1px 0px 1px 0px;
  white-space: normal !important;
  word-break: break-word;
`;

const S = {
  Assertion
};

export default S;
