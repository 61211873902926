import React, { FC, useState } from "react";
import Input from "components/atoms/Input";
import ButtonNew from "components/atoms/ButtonNew";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";

import S from "./styles";

type RequestAccessProps = {
  reRouteToSignIn: () => void;
};

const RequestAccess: FC<RequestAccessProps> = ({ reRouteToSignIn }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const onSubmit = () => {
    const address = "dj-integritycheck@xapien.com";
    const subjectOfEmail = `?subject=Access%20Request%20from%20${name}`;
    // eslint-disable-next-line max-len
    const accessRequestBoilerPlate = `Hi%2C%20I%20would%20like%20to%20request%20access%20to%20Dow%20Jones%20Integrity%20Check.%0A%0A%20My%20name%20is%20${name}%20and%20my%20email%20is%20${email}.%0A%0AI%20look%20forward%20to%20hearing%20from%20my%20account%20manager%20shortly.`;
    const body = `&body=${accessRequestBoilerPlate}`;
    window.location.href = `mailto:${address + subjectOfEmail + body}`;
  };

  return (
    <S.Container>
      <S.Inputs>
        <Input
          inputType="text"
          placeholder="Name"
          onChange={setName}
          value={name}
        />
        <Input
          inputType="email"
          placeholder="Email"
          onChange={setEmail}
          value={email}
          autoComplete="email"
        />
      </S.Inputs>
      <S.Link onClick={() => reRouteToSignIn()}>
        I already have an account
      </S.Link>

      <S.RequestAccessButton
        onClick={onSubmit}
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        text="Request access"
      />
    </S.Container>
  )
};

export default RequestAccess;
