import { useCallback, useContext } from "react";
import { InformationSource } from "api/report/report-types";
import { mutateToLowerCamelCase } from "util/common";
import { SourceMappingContext } from "./context";

type SourceMappingFunc = (id: string) => InformationSource | undefined;

export const useSourceMapping = (): SourceMappingFunc => {
  const mapping = useContext(SourceMappingContext);
  const map = useCallback(
    (id: string) => {
      if (mapping && id && id in mapping) {
        const source = mapping[id];
        if (source) {
          // have to do another camel casing thing here because the
          // initial conversion of the report object doesn't seem to
          // handle the sources
          return mutateToLowerCamelCase(source);
        }
      }
      return undefined;
    },
    [mapping]
  );
  return map;
};
