import React, { FC, SVGProps, useState } from "react";
import {
  MoreVertical,
  Trash2,
  UserPlus,
  ExternalLink,
  Copy
} from "react-feather";

import { ReactComponent as CircleFilled } from "img/icons/circle-filled.svg";
import { ReactComponent as CircleHollow } from "img/icons/circle-hollow.svg";

import Popover from "components/atoms/Popover";
import Menu from "components/molecules/Menu";
import MenuItem from "components/molecules/Menu/MenuItem";
import DeleteReportModal from "components/organisms/DeleteReportModal";
import CopyReportModal from "components/organisms/CopyReportModal";
import ShareReportModal from "components/organisms/ShareReportModal";
import TitleCard from "components/molecules/TitleCard";
import useShareReport from "util/hooks/useShareReport";

import { useHubAuthentication } from "util/hooks/useHubAuthentication";
import { HubAuthenticationStatus } from "util/hooks/useHubAuthentication/types";

import type { Report } from "api/reports";

import { ReportType } from "api/reports/types";
import S from "./styles";

interface Props {
  reportType: ReportType;
  permissions: Report["permissions"];
  subject: string;
  context: string;
  id: string;
  imageSrc?: string;
  isUnread: boolean;
  authorId: string;
  authorName: string;
  authorEmail: string;
  viewerModeUrl?: string;
  onMarkRead: () => void;
  onMarkUnread: () => void;
  onOpenInViewerMode: () => void;
  monitoringIcon: FC<SVGProps<SVGSVGElement>>;
  onMonitoringClick: () => void;
}

const ReportCardMenu = ({
  reportType,
  subject,
  permissions,
  context,
  imageSrc,
  id,
  isUnread,
  authorId,
  authorName,
  authorEmail,
  viewerModeUrl,
  monitoringIcon,
  onMonitoringClick,
  onMarkRead,
  onMarkUnread,
  onOpenInViewerMode: _onOpenInViewerMode
}: Props) => {
  const {
    state: { status: hubAuthenticationStatus }
  } = useHubAuthentication();
  const [isDeleteReportModalOpen, setIsDeleteReportModalOpen] = useState(false);
  const [isCopyReportModalOpen, setIsCopyReportModalOpen] = useState(false);
  const { isShareModalOpen, toggleShareModal } = useShareReport();

  const allowShareStuff = permissions.canEditSharingPreferences;

  const onOpenInViewerMode = () => {
    if (!viewerModeUrl) return;
    window.open(viewerModeUrl, "_blank", "noopener,noreferrer");
  };

  const getPortalMenu = () => {
    return (
      <Menu>
        <MenuItem
          IconLeading={UserPlus}
          text="Share..."
          onMenuItemClick={() => toggleShareModal()}
          disabled={!allowShareStuff}
        />
        {/* <MenuItem
                IconLeading={S.RerunIcon}
                text="Re-run report"
                disabled={!permissions.canEdit}
                /> */}
        <MenuItem
          IconLeading={isUnread ? CircleHollow : CircleFilled}
          text={`${isUnread ? "Move to recent reports" : "Move to unread"}`}
          showSeparator
          onMenuItemClick={() => (isUnread ? onMarkRead() : onMarkUnread())}
        />
        {/* <MenuItem
                IconLeading={DownloadCloud}
                text="Download as PDF"
                disabled={!permissions.canExport}
                /> */}
        {/* TODO: THIS NEEDS TO BE SWITCHED BACK */}
        {(permissions.canEditAdverseMediaMonitoring ||
          permissions.canEditSanctionMonitoring) && (
          <MenuItem
            IconLeading={monitoringIcon}
            text="Xapien Monitoring"
            onMenuItemClick={onMonitoringClick}
            // disabled={!permissions.canDelete}
          />
        )}
        <MenuItem
          isDeleteAction
          IconLeading={Trash2}
          text="Delete report"
          onMenuItemClick={() => setIsDeleteReportModalOpen(true)}
          disabled={!permissions.canDelete}
        />
      </Menu>
    );
  };

  const getHubMenu = () => {
    return (
      <Menu>
        {viewerModeUrl && (
          <MenuItem
            IconLeading={ExternalLink}
            text="Open in viewer mode"
            onMenuItemClick={() => onOpenInViewerMode()}
          />
        )}
        <MenuItem
          IconLeading={Copy}
          text="Send a copy"
          onMenuItemClick={() => setIsCopyReportModalOpen(true)}
        />
        {/* <MenuItem
            isDeleteAction
            IconLeading={Trash2}
            text="Delete report"
            onMenuItemClick={() => setIsDeleteReportModalOpen(true)}
            disabled={!permissions.canDelete}
            /> */}
      </Menu>
    );
  };

  const showHubContextMenu =
    hubAuthenticationStatus === HubAuthenticationStatus.authenticated;

  return (
    // Stop click event from opening report
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div data-cy="report-card-menu" onClick={e => e.stopPropagation()}>
      <Popover
        disabled={isDeleteReportModalOpen || isShareModalOpen}
        hideArrow
        alignment="bottom-end"
        trigger="click"
        content={showHubContextMenu ? getHubMenu() : getPortalMenu()}
        disableHideOnClip={undefined}
        className={undefined}
        style={undefined}
      >
        <S.MenuButton>
          <MoreVertical />
        </S.MenuButton>
      </Popover>

      <DeleteReportModal
        isOpen={isDeleteReportModalOpen}
        toggleOpen={() => setIsDeleteReportModalOpen(prev => !prev)}
        reportId={id}
      >
        <S.TitleCardContainer>
          <TitleCard title={subject} imageSrc={imageSrc} subtitle={context} />
        </S.TitleCardContainer>
      </DeleteReportModal>

      <CopyReportModal
        isOpen={isCopyReportModalOpen}
        toggleOpen={() => setIsCopyReportModalOpen(prev => !prev)}
        reportId={id}
        reportTitle={subject}
        reportContext={context}
        reportType={reportType}
      />

      <ShareReportModal
        isOpen={isShareModalOpen}
        toggleOpen={toggleShareModal}
        authorId={authorId}
        authorName={authorName}
        authorEmail={authorEmail}
        reportId={id}
        reportSubject={subject}
        reportContext={context}
        reportImageSrc={imageSrc}
        permissions={permissions}
      />
    </div>
  );
};

export default ReportCardMenu;
