import { styled } from "@linaria/react";
import { Spinner } from "reactstrap";

import { blue } from "styles/colors";
import { fontSize } from "styles/fonts";

const VerifyingFooterElements = styled.div`
  transition: opacity 0.2s;
  opacity: 1;
  visibility: visible;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerifyingSpinner = styled(Spinner)`
  color: ${props => props.color ?? blue.xapienLight};
  height: 36px;
  width: 36px;
`;

const CheckingEnquiryText = styled.p`
  font-size: ${fontSize.md};
  color: ${props => props.color ?? blue.xapienLight};
  background: none;
  border: none;
  padding: 0;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s;
`;

const S = {
  VerifyingFooterElements,
  VerifyingSpinner,
  CheckingEnquiryText
};

export default S;
