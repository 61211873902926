import { styled } from "@linaria/react";
import { motion } from "framer-motion";
import { blue, grey, red, standardColors } from "styles/colors";
import { fontSize } from "styles/fonts";

const TitleCardContainer = styled.div`
  border: 1px solid ${red.directRiskOutline};
  border-radius: 0.75rem;
`;

const MainContent = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const HandleReportsOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
  border-top: 1px solid ${grey.mid};
  border-bottom: 1px solid ${grey.mid};
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 0.1875rem;

  & > label {
    margin: 0;
  }

  & > input {
    margin-top: 1px;
    border-color: ${blue.xapienLight};

    &::before {
      background-color: ${blue.xapienLight};
    }
  }
`;

const TransferReports = styled(motion.div)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const DeleteReports = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  & > label {
    margin: 0;
  }
`;

const FooterExplainer = styled.p`
  margin: 0;
  font-size: ${fontSize.sm};
  color: ${grey.ghost};
`;

const Actions = styled.div`
  display: flex;
  gap: 0.75rem;

  & > button {
    flex: 1;
  }

  & > button:nth-of-type(2):not(:hover, :disabled) {
    background-color: ${red.directRiskFill};
    color: ${standardColors.white};
  }
`;

const Checkbox = styled.input`
  width: 1.125rem;
  height: 1.125rem;

  &:checked {
    accent-color: ${blue.xapienLight};
  }
`;

const WarningText = styled.p`
  margin: 0;
  color: ${grey.ghost};
  font-size: ${fontSize.sm};
`;

const S = {
  TitleCardContainer,
  HandleReportsOptions,
  RadioGroup,
  TransferReports,
  DeleteReports,
  FooterExplainer,
  Actions,
  MainContent,
  Checkbox,
  WarningText
};

export default S;
