import { InsightReportSection } from "api/insight-reports";

const getHasSubsections = (section?: InsightReportSection) => {
  return (
    section &&
    section.content.some(({ content }) => content && content.length > 1)
  );
};

export { getHasSubsections };
