import { styled } from "@linaria/react";

import Heading from "components/atoms/Heading";

import { fontSize } from "styles/fonts";

const ReportsRunLabel = styled.span`
  font-size: ${fontSize.sm};
`;

const ReportsRunContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OptionsButton = styled.button`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: ${fontSize.sm} !important;
  line-height: 1.125rem;
  all: unset;
  white-space: nowrap;
  cursor: pointer;

  &:not(:focus-visible) {
    outline: none;
  }
`;

const DownloadReports = styled.div`
  border-top: 1px solid white;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  align-items: center;
`;

const InlineHeading = styled(Heading)`
  display: inline;
`;

const S = {
  ReportsRunContent,
  ReportsRunLabel,
  OptionsButton,
  DownloadReports,
  InlineHeading
};

export default S;
