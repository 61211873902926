import type { ContentNode } from "api/insight-reports";
import { parseListString } from "./parseListString";

export const renderAsPlainText = ({
  nodeType,
  value,
  content
}: ContentNode): string => {
  if (nodeType === "text") {
    return value;
  }

  const textContent =
    content?.map(contentNode => renderAsPlainText(contentNode)).join("") ?? "";

  return `${parseListString(textContent)}\n`;
};
