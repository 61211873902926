import { styled } from "@linaria/react";

import { fontSize, family } from "styles/fonts";

const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StageHeading = styled.div`
  padding: 15px 0;
  font-size: ${fontSize["2xl"]};
  font-family: ${family.interLight};
  margin-bottom: 25px;
`;

const DetailText = styled.h2`
  max-width: 700px;
  font-family: ${family.interLight};
  padding-bottom: 25px;
`;

const S = {
  StageContainer,
  StageHeading,
  DetailText
};

export default S;
