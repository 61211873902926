import { useContext } from "react";

import { UpcDetailsContext } from "./context";

export { UpcDetailsContextProvider } from "./provider";

const useUpcDetails = () => {
  const context = useContext(UpcDetailsContext);

  if (!context) {
    throw new Error(
      "useUpcDetails must be used within a UpcDetailsContextProvider"
    );
  }

  return context;
};

export default useUpcDetails;
