import { styled } from "@linaria/react";
import { DiImage } from "components/molecules/DiImage";
import { grey } from "styles/colors";
import { fontSize } from "styles/fonts";
import { ReactComponent as MissingStreetViewSvg } from "img/icons/missing-street-view.svg";

const LocationCard = styled.div`
  background-color: ${grey.panel};
  border-radius: 3px;
  width: 100%;
`;

const LocationCardContent = styled.div`
  display: grid;
  grid-template-columns: 34% 66%;
  gap: 1rem;
  padding-top: 15px;
`;

const AddressColumn = styled.div`
  margin-bottom: 0.5rem;
`;

const AddressContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const RolesColumn = styled.div``;

// @ts-ignore;
const Image = styled(DiImage)`
  width: 100%;
  object-fit: cover;
  height: 258px;
  border-radius: 3px;
`;

const MissingStreetViewImageContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${grey.mid};
  font-size: ${fontSize.sm};
  height: 97px;
  background: ${grey.light};
  border-radius: 3px;
  padding-top: 15px;

  svg {
    width: 34px;
    height: 40px;
  }
`;

const MissingStreetViewIcon = styled(MissingStreetViewSvg)``;

const NoStreetviewInfo = styled.p`
  font-size: ${fontSize.sm};
  margin: 13px 0;
`;

const LocationRiskFlagsContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  padding: 4px 0 3px 0;
  width: 160px;
  gap: 4px;
`;

const S = {
  LocationCard,
  LocationCardContent,
  AddressColumn,
  AddressContainer,
  RolesColumn,
  Image,
  MissingStreetViewImageContainer,
  MissingStreetViewIcon,
  NoStreetviewInfo,
  LocationRiskFlagsContainer
};

export default S;
