import React, {
  ChangeEvent,
  KeyboardEvent,
  Ref,
  useEffect,
  useState
} from "react";
import { Dropdown } from "reactstrap";

import { motion } from "framer-motion";

import { ReactComponent as PlusInCircle } from "img/icons/plus-in-circle.svg";
import { ReactComponent as CrossInCircle } from "img/icons/cross-in-circle.svg";
import Popover from "components/atoms/Popover";

// @ts-ignore
import theme from "theme";

import { Props, Option } from "./types";
import S from "./styles";

const TextInputWithOptions = <T extends Option>({
  onEnterPressedInTextInput = () => {},
  disabled,
  canRemoveRow,
  canAddRow,
  canSelectOption,
  rowId,
  options,
  defaultSelection,
  onRemoveRowClick = () => {},
  onAddRowClick = () => {},
  onOptionSelect = () => {},
  onInputChange = () => {},
  customDropdownFn,
  initialTextInputValue,
  customAddRowPrompt,
  displayAddRowPrompt,
  showInfoIcon,
  helpText,
  ref
}: Props<T> & { ref: Ref<HTMLDivElement> }) => {
  // We record this because we focus the whole row when we focus the input field
  const [isTextInputFocused, setIsTextInputFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelection);
  const [textInputValue, setTextInputValue] = useState(
    initialTextInputValue ?? ""
  );

  useEffect(() => {
    setTextInputValue(initialTextInputValue ?? "");
    setSelectedOption(defaultSelection);
  }, [initialTextInputValue, defaultSelection]);

  const onOptionItemSelected = (optionId: string) => {
    setSelectedOption(optionId);
    onOptionSelect(rowId, optionId);
  };

  const onKeyDownOnTextInput = (event: KeyboardEvent<HTMLInputElement>) => {
    const keyPressed = event.key;
    if (keyPressed === "Enter") {
      setIsTextInputFocused(false);
      onEnterPressedInTextInput();
    }
  };

  const renderAddRowControl = () => {
    if (canAddRow) {
      return (
        <S.RowControlButton
          onClick={onAddRowClick}
          disabled={disabled}
          aria-label="Add row"
        >
          <Popover
            content={
              <S.PromptContainer>{customAddRowPrompt}</S.PromptContainer>
            }
            // @ts-ignore
            isOpenOverride={displayAddRowPrompt}
            trigger="click"
            alignment="right"
          >
            <PlusInCircle />
          </Popover>
        </S.RowControlButton>
      );
    }

    return <S.RowControlPlaceholder />;
  };

  const renderRemoveRowControl = () => {
    if (canRemoveRow) {
      return (
        <S.RowControlButton
          onClick={onRemoveRowClick}
          disabled={disabled}
          aria-label="Remove row"
        >
          <CrossInCircle />
        </S.RowControlButton>
      );
    }

    return <S.RowControlPlaceholder />;
  };

  const renderOptionsDropdown = () => {
    if (customDropdownFn) {
      return customDropdownFn();
    }

    return (
      <Dropdown
        isOpen={isDropdownOpen}
        toggle={() => setIsDropdownOpen(prevState => !prevState)}
      >
        <S.OptionsDropdownToggle
          disabled={disabled}
          aria-label="Select option"
          boxShadowColor={theme.button?.alternativeBoxShadowColor}
        >
          <S.OptionsDropdownSelectedLabel>
            {options[selectedOption ?? ""]?.name || "Select"}
          </S.OptionsDropdownSelectedLabel>
          <S.ArrowDown />
        </S.OptionsDropdownToggle>
        <S.OptionsDropdownMenu menuColor={theme.dropdownColor} flip={false}>
          {Object.keys(options).map(optionKey => {
            const optionObj = options[optionKey];
            // This is to stop unenabled options from being rendered but still keep the type correct if it happens
            // to be used elsewhere in the codebase.
            if (!optionObj.isEnabled) {
              return null;
            }
            return (
              <S.OptionsDropdownMenuItem
                key={optionKey}
                onClick={() => onOptionItemSelected(optionObj.id)}
                aria-label={`Select option ${optionObj.name}`}
              >
                {optionObj.name}
              </S.OptionsDropdownMenuItem>
            );
          })}
        </S.OptionsDropdownMenu>
      </Dropdown>
    );
  };

  const renderInfoIcon = () => {
    return (
      // @ts-ignore TODO: convert info component to tsc
      <S.InfoIcon infoContent={helpText} tooltipAlignment="right" interactive />
    );
  };

  const onTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextInputValue(event.target.value);
    onInputChange(event, rowId);
  };

  return (
    <S.RowContainer
      key={rowId}
      disabled={disabled}
      data-testid="input-row"
      ref={ref}
    >
      {renderRemoveRowControl()}
      <S.InputElementsContainer
        isFocused={isTextInputFocused && !disabled}
        boxShadowColor={theme.button?.alternativeBoxShadowColor}
      >
        <S.TextInput
          placeholder={options[selectedOption ?? ""]?.hintText}
          type="text"
          onChange={onTextInputChange}
          onKeyDown={onKeyDownOnTextInput}
          disabled={disabled}
          value={textInputValue}
          onFocus={() => setIsTextInputFocused(true)}
          onBlur={() => setIsTextInputFocused(false)}
        />
        {canSelectOption && renderOptionsDropdown()}
        {showInfoIcon && renderInfoIcon()}
      </S.InputElementsContainer>
      {renderAddRowControl()}
    </S.RowContainer>
  );
};

export default motion(TextInputWithOptions);
