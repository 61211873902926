import React from "react";

import Skeleton from "components/atoms/Skeleton";

import S from "./styles";

const TitleCardLoadingSkeleton = () => {
  return (
    <S.SkeletonContainer>
      <Skeleton variant="circular" width={48} height={48} />
      <S.SkeletonContent>
        <Skeleton width={120} height={22} />
        <Skeleton width={100} height={16} />
      </S.SkeletonContent>
    </S.SkeletonContainer>
  );
};

export default TitleCardLoadingSkeleton;
