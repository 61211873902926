import React, { FC } from "react";
import { ButtonSize, ButtonType } from "components/atoms/ButtonNew/types";
import Heading from "components/atoms/Heading";

// @ts-ignore
import sourcingDemoVideo from "img/sourcing-demo.mp4";

import S from "./styles";

interface SourcingExplainerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SourcingExplainer: FC<SourcingExplainerProps> = ({
  isOpen,
  onClose
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <S.ContentWrapper>
      <S.VideoWrapper>
        <video autoPlay muted loop>
          <source src={sourcingDemoVideo} type="video/mp4" />
        </video>
      </S.VideoWrapper>
      <div>
        <Heading level={5}>How to check the sourcing</Heading>
        <p>
          Click on any text to show the sources. This will include information
          to help you verify the content&rsquo;s origins.
        </p>
      </div>

      <S.CloseButton
        type={ButtonType.Filled}
        size={ButtonSize.Medium}
        onClick={onClose}
        text="Got it"
      />
    </S.ContentWrapper>
  );
};

export default SourcingExplainer;
