import { styled } from "@linaria/react";

import { ReactComponent as EnterIconSvg } from "img/insights-icons/enter.svg";
import { ReactComponent as AskXapienButtonIconSvg } from "img/insights-icons/ask-xapien-large.svg";

import { isPDX } from "static-config";
import { grey, purple, standardColors, yellow } from "styles/colors";
import { fontSize, family, headingLetterSpacing } from "styles/fonts";
import { hexToRgb } from "styles/hexToRgb";

// @ts-ignore
import theme from "theme";

export interface AskXapienButtonProps {
  disabled: boolean;
}

const InsightsChat = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  position: relative;
`;

const EnterIcon = styled(EnterIconSvg)`
  height: 12px;
  width: 12px;
`;

const AskInputContainer = styled.div`
  padding: 8px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: static;
  bottom: auto;

  .Mui-disabled:before {
    border-bottom: 1px solid ${grey.mid} !important;
  }

  .MuiInputBase-root {
    color: ${grey.mid};

    padding-right: 4px;
    font-size: ${fontSize.md};

    &:after {
      border-bottom: 0;
    }
    &:hover:before {
      border-bottom: 1px solid ${grey.mid} !important;
    }

    input {
      font-family: ${family.interDisplayMedium};
      color: ${standardColors.black};
      font-size: ${fontSize.md};
      letter-spacing: ${headingLetterSpacing.sm};

      &::placeholder {
        color: ${grey.mid};
        opacity: 1;
      }
      outline: none;
      padding: 8px 0;
    }
  }
`;

const AskInputHelpTextButton = styled.button`
  border-radius: 6px;
  border: none;
  white-space: nowrap;
  font-family: ${family.interDisplayMedium};
  font-size: ${fontSize.md};
  letter-spacing: ${headingLetterSpacing["4xl"]};

  display: flex;
  gap: 10px;
  align-items: center;
  color: ${grey.mid};
  background-color: ${standardColors.white};

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${standardColors.black};
    background-color: ${grey.panel};
  }

  &:disabled {
    color: ${grey.mid};
    background-color: ${standardColors.white};
  }
`;

const AskXapienButton = styled.button<AskXapienButtonProps>`
  margin-bottom: 2rem;
  border: 0;
  border-radius: 100px;
  padding: 8px 16px 8px 10px;
  gap: 0.3rem;
  color: ${({ disabled }) => (disabled ? grey.mid : standardColors.black)};
  font-size: ${fontSize.sm};
  font-family: ${family.interDisplay};
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: ${({ disabled }) => {
    if (disabled) {
      const { r, g, b } = hexToRgb(grey.disabled);
      return `rgba(${r}, ${g}, ${b}, 0.50)`;
    }

    const { r, g, b } = hexToRgb(isPDX ? theme.primaryColor : purple.dark);
    return `rgba(${r}, ${g}, ${b}, 0.1)`;
  }};

  &:hover {
    background: ${({ disabled }) => {
      if (disabled) {
        const { r, g, b } = hexToRgb(grey.disabled);
        return `rgba(${r}, ${g}, ${b}, 0.50)`;
      }

      const { r, g, b } = hexToRgb(isPDX ? theme.primaryColor : purple.dark);
      return `rgba(${r}, ${g}, ${b}, 0.2)`;
    }};
  }

  &:focus {
    outline: none;
  }

  span {
    color: ${({ disabled }) => {
      if (disabled) {
        return grey.dark;
      }

      return isPDX ? theme.primaryColor : purple.darker;
    }};
    text-transform: capitalize;
  }
`;

const AskXapienButtonIcon = styled(
  AskXapienButtonIconSvg
)<AskXapienButtonProps>`
  background: ${({ disabled }) =>
    disabled
      ? "linear-gradient(197deg, #9D9D9D -14.5%, #686868 68.01%)"
      : "none"};
  border-radius: 50%;

  rect {
    fill: ${({ disabled }) => {
      if (disabled) {
        return "none";
      }

      return isPDX ? theme.primaryColor : purple.darker;
    }};
  }

  path {
    fill: ${({ disabled }) => (disabled ? grey.lighterPanel : yellow.amaStars)};
  }
`;

const S = {
  AskXapienButton,
  AskXapienButtonIcon,
  AskInputHelpTextButton,
  AskInputContainer,
  InsightsChat,
  EnterIcon
};

export default S;
